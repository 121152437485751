import React, { useState } from 'react';
import { Button } from '@/components/ui/Button';
import { TableCell } from '@/components/ui/table';
import { FiTrash2 } from 'react-icons/fi';
import { EksisRawanBencana } from '@/types/perumahan';
import EIdentifikasiRwnBencana from '@/components/Forms/RumahBencana/EIdentifikasiRwnBencana';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
} from '@/components/ui/dialog';

interface ActionButtonIdentifikasiRwnBencanaProps {
	rowData: EksisRawanBencana;
	onEdit: (data: EksisRawanBencana) => void;
	onDelete: (data: EksisRawanBencana) => void;
}

const ActionButtonRawanBencana: React.FC<
	ActionButtonIdentifikasiRwnBencanaProps
> = ({ rowData, onEdit, onDelete }) => {
	const [showActions, setShowActions] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const handleActionClick = () => {
		setShowActions(!showActions);
	};

	const handleEditClick = () => {
		onEdit(rowData);
	};

	const handleDeleteClick = () => {
		setIsDialogOpen(true);
	};

	const handleConfirmDelete = () => {
		onDelete(rowData);
		setIsDialogOpen(false);
	};

	const handleCancelDelete = () => {
		setIsDialogOpen(false);
	};

	return (
		<TableCell>
			<div className="flex justify-center gap-4">
				{!showActions ? (
					<Button
						size={'sm'}
						className="inline-flex items-center justify-start gap-1 rounded-lg bg-[#fab23d] px-4 py-2 text-center font-medium text-black hover:bg-yellow-600"
						onClick={handleActionClick}
					>
						Aksi
					</Button>
				) : (
					<>
						<EIdentifikasiRwnBencana rowData={rowData} />
						<Button
							size={'sm'}
							className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600"
							onClick={handleDeleteClick}
						>
							<FiTrash2 />
						</Button>
					</>
				)}
			</div>
			<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Konfirmasi Penghapusan</DialogTitle>
						<DialogDescription>
							Apakah Anda yakin ingin menghapus data ini?
						</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<div className="mt-5 flex w-full justify-between">
							<Button
								size={'sm'}
								className="bg-red-500 text-white"
								onClick={handleCancelDelete}
							>
								Batal
							</Button>
							<Button
								size={'sm'}
								className="ml-auto bg-green-500 text-white hover:bg-green-600"
								onClick={handleConfirmDelete}
							>
								Hapus Data
							</Button>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</TableCell>
	);
};

export default ActionButtonRawanBencana;
