import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import {
	useEditDataCapaianKumulatifAMCurahProv,
	useGetDataCapaianKumulatifAMCurahProv,
	usePostDataCapaianKumulatifAMCurahProv,
} from '@/hooks/airMinum/useDataCapaianAM';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { FiArrowLeft, FiDownload, FiPlusCircle, FiSave } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import { utils, writeFile } from 'xlsx-js-style';

type TableDataAirMinumCurahProps = {
	tahun: number;
};

const TableDataAirMinumCurahProv = ({ tahun }: TableDataAirMinumCurahProps) => {
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_provinsi_dux = useSelector(
		(state: RootState) => state.location.kd_provinsi_dux,
	);
	const nama_provinsi_dux = useSelector(
		(state: RootState) => state.location.nama_provinsi,
	);
	const nama_provinsi = useSelector(
		(state: RootState) => state.auth.wilayah.nama_provinsi,
	);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const userId = useSelector((state: RootState) => state.auth.id_user);
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);

	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const nama_provinsi_to_use =
		privilege === 10 ? nama_provinsi_dux : nama_provinsi;

	const [total, setTotal] = useState({
		target: 0,
		realisasi: 0,
	});
	const [capaian, setCapaian] = useState(0);
	const [uploadDataLoading, setUploadDataLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editableData, setEditableData] = useState([]);
	const [allDataAMCurah, setAllDataAMCurah] = useState<any>([]);
	const queryClient = useQueryClient();

	const { getKabKota, isLoading } = useGetKabKota(kd_provinsi);
	const {
		getDataCapaianKumulatifAMCurahProv,
		isLoading: isLoadingAMCurah,
		isRefetching,
	} = useGetDataCapaianKumulatifAMCurahProv(
		Number(kd_provinsi_to_use),
		Number(tahun),
	);
	const { asyncPostCapaianKumulatifAMCurahProv } =
		usePostDataCapaianKumulatifAMCurahProv();
	const { asyncEditCapaianKumulatifAMCurahProv } =
		useEditDataCapaianKumulatifAMCurahProv();

	const cardRef = useRef(null);

	const formatDataLokasi = () => {
		const newObj = (obj: any): any => {
			return obj.map((item: any) => ({
				user_id: userId,
				menu_id: menuId,
				tahun_pelaporan: tahun,
				id_eksis_am_prov: item.id_eksis_am_prov,
				kd_provinsi,
				kd_kabkota: item.kd_kabkota,
				nama_kabkota: item.nama_kabkota,
				tgt_curah: 0,
				real_curah: 0,
			}));
		};
		const data = newObj(getKabKota);
		return data;
	};

	let rowCounter = 0;

	const handleMasukanData = async () => {
		if (getDataCapaianKumulatifAMCurahProv.data.length === 0) {
			setUploadDataLoading(true);
			try {
				const data = formatDataLokasi();
				const postPromises = data.map((data: any) =>
					asyncPostCapaianKumulatifAMCurahProv(data),
				);
				await Promise.all(postPromises);
				toast.success('Berhasil menambahkan data capaian Air Minum Curah');
			} catch (error) {
				toast.error('Gagal menambahkan data capaian Air Minum Curah');
			} finally {
				queryClient.refetchQueries({
					queryKey: ['eksis-air-minum-curah-prov'],
				});
				setUploadDataLoading(false);
				setEditMode(true);
			}
		} else {
			setEditMode(true);
		}
	};

	const handleSimpanData = async () => {
		setUploadDataLoading(true);
		try {
			const editPromises = editableData.map((data: any) =>
				asyncEditCapaianKumulatifAMCurahProv(data),
			);
			await Promise.all(editPromises);
			toast.success('Berhasil edit data capaian Air Minum Curah');
		} catch (error) {
			toast.error('Gagal edit data capaian Air Minum Curah');
		} finally {
			queryClient.refetchQueries({ queryKey: ['eksis-air-minum-curah-prov'] });
			setUploadDataLoading(false);
			setEditMode(false);
		}
	};

	const handleChange = (key: number, name: string, value: string) => {
		const data: any = [...editableData];
		data[key][name] = Number(value);
		setEditableData(data);
	};

	const handleDownload = () => {
		const transformedData = allDataAMCurah.map((item: any, index: any) => [
			{
				v: index + 1,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.nama_kabkota,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.tgt_curah,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.real_curah,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		]);

		const header = [
			{
				v: 'No',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Kabupaten/Kota',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Target',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Realisasi',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const header1 = [
			{
				v: 'Kebutuhan Pelayanan Air Minum Curah',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Air Minum Curah yang Telah Tersedia',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const header2 = [
			{
				v: '(Liter/Detik)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: '(Liter/Detik)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const totalRow = [
			{
				v: total.target,
				t: 'n',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: total.realisasi,
				t: 'n',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'DATA AWAL',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A1' },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'SPM PERKERJAAN UMUM',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A2' },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: `PEMENUHAN KEBUTUHAN AIR MINUM CURAH LINTAS KABUPATEN/KOTA TAHUN ${tahun}`,
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A3' },
		);

		utils.sheet_add_aoa(ws, [header], { origin: 'A4' });
		utils.sheet_add_aoa(ws, [header1], { origin: 'C5' });
		utils.sheet_add_aoa(ws, [header2], { origin: 'C6' });
		utils.sheet_add_aoa(ws, transformedData, { origin: 'A7' });

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Total',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `A${transformedData.length + 7}` },
		);
		utils.sheet_add_aoa(ws, [totalRow], {
			origin: `C${transformedData.length + 7}`,
		});

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Capaian SPM',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `A${transformedData.length + 8}` },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: capaian,
						t: 'n',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `D${transformedData.length + 8}` },
		);

		ws['!cols'] = [{ wpx: 50 }, { wpx: 166 }, { wpx: 241 }, { wpx: 227 }];

		ws['!merges'] = [
			// COLSPANS
			utils.decode_range('A1:D1'),
			utils.decode_range('A2:D2'),
			utils.decode_range('A3:D3'),
			utils.decode_range(
				`A${transformedData.length + 7}:B${transformedData.length + 7}`,
			),
			utils.decode_range(
				`A${transformedData.length + 8}:C${transformedData.length + 8}`,
			),

			// ROWSPANS
			utils.decode_range('A4:A6'),
			utils.decode_range('B4:B6'),
		];
		utils.book_append_sheet(wb, ws, 'Kumulatif Air Minum Provinsi');
		writeFile(
			wb,
			`Data Capaian Kumulatif Air Minum ${nama_provinsi_to_use}  - ${tahun}.xlsx`,
		);
	};

	useEffect(() => {
		if (!isLoadingAMCurah) {
			const totalTarget = getDataCapaianKumulatifAMCurahProv.data.reduce(
				(acc: any, curr: any) => acc + curr['tgt_curah'],
				0,
			);
			const totalRealisasi = getDataCapaianKumulatifAMCurahProv.data.reduce(
				(acc: any, curr: any) => acc + curr['real_curah'],
				0,
			);

			const capaian = (totalRealisasi / totalTarget) * 100;

			setTotal((prevState) => ({
				...prevState,
				target: totalTarget,
				realisasi: totalRealisasi,
			}));
			setCapaian(capaian);
		}
	}, [isLoadingAMCurah, getDataCapaianKumulatifAMCurahProv, tahun, menuId]);

	useEffect(() => {
		if (!isLoadingAMCurah) {
			setEditableData(getDataCapaianKumulatifAMCurahProv.data);
		}
	}, [isLoadingAMCurah, isRefetching]);

	useEffect(() => {
		if (!isLoadingAMCurah) {
			const dataAMCurah = getDataCapaianKumulatifAMCurahProv.data;
			setAllDataAMCurah(dataAMCurah);
		}
	}, [isLoadingAMCurah]);

	if (isLoading || isLoadingAMCurah || uploadDataLoading) return <CompLoader />;

	return (
		<>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader cardRef={cardRef} isLoading={isLoading} minusWidth={32}>
						DATA AIR MINUM CURAH <br />
						SPM PERKERJAAN UMUM <br />
						PEMENUHAN KEBUTUHAN AIR MINUM CURAH LINTAS KABUPATEN/KOTA TAHUN{' '}
						{tahun}
					</JudulHeader>
					<Table className="w-full table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									rowSpan={3}
									className="thead-text border-l text-center"
								>
									No
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Kabupaten / Kota
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Target
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Realisasi
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-b-0 text-center">
									Kebutuhan Pelayanan Air Minum Curah
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Air Minum Curah yang Telah Tersedia
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text text-center">
									(Liter/Detik)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Liter/Detik)
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{editableData.map((item: any, key) => {
								rowCounter++;
								return (
									<TableRow
										key={key}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										{editMode ? (
											<>
												<TableCell className="tbody-text border-b border-l border-r">
													{rowCounter}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.nama_kabkota}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													<input
														type="number"
														value={String(item.tgt_curah)}
														onChange={(e) =>
															handleChange(key, 'tgt_curah', e.target.value)
														}
														className="w-full rounded-md border-[1px] p-1 placeholder:text-black"
													/>
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													<input
														type="number"
														value={String(item.real_curah)}
														onChange={(e) =>
															handleChange(key, 'real_curah', e.target.value)
														}
														className="w-full rounded-md border-[1px] p-1 placeholder:text-black"
													/>
												</TableCell>
											</>
										) : (
											<>
												<TableCell className="tbody-text border-b border-l border-r">
													{rowCounter}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.nama_kabkota}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.tgt_curah}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.real_curah}
												</TableCell>
											</>
										)}
									</TableRow>
								);
							})}
						</TableBody>
						<TableFooter>
							<TableRow className="bg-black/10 dark:bg-white/10">
								<TableCell
									colSpan={2}
									className="tfooter-text border-b border-l border-r"
								>
									Total
								</TableCell>
								<TableCell className="tfooter-text border-b border-r">
									{total.target}
								</TableCell>
								<TableCell className="tfooter-text border-b border-r">
									{total.realisasi}
								</TableCell>
							</TableRow>
							<TableRow className="bg-black/10 dark:bg-white/10">
								<TableCell
									colSpan={3}
									className="tfooter-text border-b border-l border-r"
								>
									Capaian SPM
								</TableCell>
								<TableCell className="tfooter-text border-b border-r">
									{isNaN(capaian) ? 0 : capaian.toFixed(2)}%
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};

export default TableDataAirMinumCurahProv;
