import { useEffect, useState } from 'react';
import { DataWilayahAM } from '@/types/dataWilayah';
import EditDataWilayahAM from '@/components/Forms/AirMinum/DataDasar/EditDataWilayahAM';
import { ScrollArea } from '@/components/ui/scroll-area';

const DataWilayah: DataWilayahAM[] = [
	{
		id: 1,
		provinsi: 'provinsi-a',
		kabKota: 'city-a',
		jmlKecamatan: 10,
		jmlKelurahan: 50,
		luasWilayah: 1000,
		jmlRumahTangga: 2000,
		jmlKK: 1800,
		jmlJiwa: 5000,
		umpUmk: 3003000,
	},
	{
		id: 2,
		provinsi: 'provinsi-b',
		kabKota: 'city-b',
		jmlKecamatan: 10,
		jmlKelurahan: 50,
		luasWilayah: 1000,
		jmlRumahTangga: 2000,
		jmlKK: 1800,
		jmlJiwa: 5000,
		umpUmk: 3000090,
	},
	{
		id: 3,
		provinsi: 'provinsi-c',
		kabKota: 'city-c',
		jmlKecamatan: 10,
		jmlKelurahan: 50,
		luasWilayah: 1000,
		jmlRumahTangga: 2000,
		jmlKK: 1800,
		jmlJiwa: 5000,
		umpUmk: 3900000,
	},
];
const TableDataWilayahAM = () => {
	const [prov, setProv] = useState('');
	const [kab, setKab] = useState('');
	const [showEdit, setShowEdit] = useState(false);
	const [editId, setEditId] = useState(null);
	const [selectedData, setSelectedData] = useState<DataWilayahAM | null>(null);
	const [isButtonClicked, setIsButtonClicked] = useState(false);

	const handleProvChange = (selectedProv: string) => {
		setProv(selectedProv);
	};

	const handleKabChange = (selectedKab: string) => {
		setKab(selectedKab);
	};
	const handleButtonClick = () => {
		setIsButtonClicked((prevState) => !prevState);
	};

	useEffect(() => {
		if (isButtonClicked) {
			const data = DataWilayah.find(
				(item) => item.provinsi === prov && item.kabKota === kab,
			);
			setSelectedData(data || null);
		}
	}, [prov, kab, isButtonClicked]);

	const handleEditClick = (id: any) => {
		setShowEdit(true);
		setEditId(id);
	};

	const handleAddSuccess = (): void => {
		// alert('Upload successful');
		// setShowAlert(true);
		// setShowForm(false);
		// setTimeout(() => {
		// 	setShowAlert(false);
		// }, 5000);
	};

	return (
		<>
			{showEdit ? (
				<EditDataWilayahAM
					onEditSuccess={handleAddSuccess}
					data={selectedData}
				/>
			) : (
				<div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden">
					<div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
						<h3 className="font-medium text-[#364878] text-2xl dark:text-white">
							Data Wilayah
						</h3>
					</div>
					<ScrollArea>
						<div className="flex flex-col gap-5.5 p-6.5 ">
							<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
								<div className="flex items-end">
									<label className="mb-3 block font-medium text-slate dark:text-white">
										Jumlah Kecamatan
									</label>
								</div>
								<div className="text-right">
									<span className="font-bold">
										{selectedData ? selectedData.jmlKecamatan : '-'}
									</span>
								</div>
							</div>
							<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
								<div className="flex items-end">
									<label className="mb-3 block font-medium text-slate dark:text-white">
										Jumlah Kelurahan / Desa
									</label>
								</div>
								<div className="text-right">
									<span className="font-bold">
										{selectedData ? selectedData.jmlKelurahan : '-'}
									</span>
								</div>
							</div>
							<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
								<div className="flex items-end">
									<label className="mb-3 block font-medium text-slate dark:text-white">
										Luas Wilayah (km²)
									</label>
								</div>
								<div className="text-right">
									<span className="font-bold">
										{selectedData ? selectedData.luasWilayah : '-'}
									</span>
								</div>
							</div>
							<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
								<div className="flex items-end">
									<label className="mb-3 block font-medium text-slate dark:text-white">
										Jumlah Rumah Tangga
									</label>
								</div>
								<div className="text-right">
									<span className="font-bold">
										{selectedData ? selectedData.jmlRumahTangga : '-'}
									</span>
								</div>
							</div>
							<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
								<div className="flex items-end">
									<label className="mb-3 block font-medium text-slate dark:text-white">
										Jumlah KK
									</label>
								</div>
								<div className="text-right">
									<span className="font-bold">
										{selectedData ? selectedData.jmlKK : '-'}
									</span>
								</div>
							</div>
							<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
								<div className="flex items-end">
									<label className="mb-3 block font-medium text-slate dark:text-white">
										Jumlah Jiwa
									</label>
								</div>
								<div className="text-right">
									<span className="font-bold">
										{selectedData ? selectedData.jmlJiwa : '-'}
									</span>
								</div>
							</div>
							<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
								<div className="flex-col ">
									<label className=" block font-medium text-slate dark:text-white">
										UMP / UMK
									</label>
									<p className="text-[10px] text-red-500">
										*ump untuk akun <strong>Provinsi</strong> dan umk untuk akun{' '}
										<strong>Kabupaten</strong>
									</p>
									<div className=""></div>
								</div>
								<div className="text-right">
									<span className="font-bold">
										{selectedData
											? new Intl.NumberFormat('id-ID', {
												style: 'currency',
												currency: 'IDR',
											}).format(selectedData.umpUmk)
											: '-'}
									</span>
								</div>
							</div>
							<div className="flex justify-between gap-5 mt-5">
								<div className=" w-full">
									<button
										className="min-w-full inline-flex items-center justify-center rounded-md bg-[#3C6AE0] py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
										onClick={() => handleEditClick(selectedData?.id)}
									>
										Edit Data
									</button>
								</div>
							</div>
						</div>
					</ScrollArea>
				</div>
			)}
		</>
	);
};
export default TableDataWilayahAM;
