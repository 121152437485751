import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FormEvent, useState } from 'react';
import { FiEdit, FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import DesaSelectUI from '@/components/ui/SelectDesaUI';
import {useEditIdentifikasiLahanPotensialBencana, usePostIdentifikasiLahanPotensial} from '@/hooks/perumahan/useRumahBencana';
import { EksisLahanPotensialRumahBencana } from '@/types/perumahan';
import EditKecamatanSelectUI from '@/components/ui/SelectEditKecamatan';
import EditDesaSelectUI from '@/components/ui/SelectEditDesa';
import { useQueryClient } from '@tanstack/react-query';
import EditKabKotaSelectUI from '@/components/ui/SelectEditKabupatenKotaUI';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';

interface EIdentifikasiLahanBencanaProps {
    rowData: EksisLahanPotensialRumahBencana
}

const EIdentifikasiLahanBencana: React.FC<EIdentifikasiLahanBencanaProps> = ({
    rowData
}) => {
	const user = useSelector((state: RootState) => state.auth);
    const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kabkota, setKd_kabkota] = useState<number>(rowData.kd_kabkota);
    const [kd_kec, setKd_kec] = useState<number>(rowData.kd_kec);
    const [kd_desa, setKd_desa] = useState<number>(rowData.kd_desa);

	//get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
    const { getKecamatan } = useGetKecamatan(user.kd_provinsi, kd_kabkota);
    const { getDesa, refetch: fetchDesa } = useGetDesa(user.kd_provinsi, kd_kabkota, kd_kec);
    const { editIdentifikasiLahanPotensialBencana } = useEditIdentifikasiLahanPotensialBencana();

	//dataform
	const [luasLahan, setLuasLahan] = useState(
		(rowData.luas_lhn ?? 0).toString(),
	);
	const [pemilik, setPemilik] = useState(rowData.pemilik ?? '');
	const [keterangan, setKeterangan] = useState(rowData.keterangan ?? '');

	//additionals
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	
	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		
		const data = {
			id_lhn_potensial:rowData.id_lhn_potensial,
			kd_kabkota: kd_kabkota,
			kd_kec: kd_kec,
			kd_desa: kd_desa,
			luas_lhn: parseInt(luasLahan),
			pemilik: pemilik,
			keterangan: keterangan
		}

        editIdentifikasiLahanPotensialBencana(data, {
			onSuccess: () => {
				queryClient.refetchQueries({queryKey:['eksis-lahan-potensial']});
				setModalOpen(false);
			},
			onError: (error) => {
				console.log(error);
			},
		});

        // console.log(data);
	};

	console.log(tahun);
	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger asChild>
                    <Button
                        size={'sm'} 
                        className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'>
                        <FiEdit />
                    </Button>
                </DialogTrigger>
				<DialogContent className="mx-auto max-w-screen-xl z-99999">
					<DialogHeader>
						<DialogTitle>
							Edit Data Identifikasi Lahan Potensial 
						</DialogTitle>
						<DialogDescription>
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>
					
					<form action='post' onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							{ menuId === 7 && (
                                <div className="flex flex-col">
                                <Label htmlFor="Kabupaten" className="mb-2">
                                    Kabupaten
                                </Label>
                                <EditKabKotaSelectUI
                                    getKabKota={getKabKota}
                                    setKabKota={setKd_kabkota}
                                    selectedValue={kd_kabkota}
                                    disabledSelect={false}
                                />
                            </div>
                            )}
							<div className="flex flex-col">
								<Label htmlFor="Kecamatan" className="mb-2">
									Kecamatan
								</Label>
								<EditKecamatanSelectUI
                                    getKecamatan={getKecamatan}
                                    setKd_kec={setKd_kec}
                                    selectedValue={kd_kec}
									disabledSelect={false}
                                />
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kelurahan/Desa" className="mb-2">
									Kelurahan/Desa
								</Label>
								<EditDesaSelectUI
                                    getDesa={getDesa}
                                    setKd_desa={setKd_desa}
                                    selectedValue={kd_desa}
                                    kd_kec={kd_kec}
									disabledSelect={false}
                                />
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Luas Lahan" className="mb-2">
									Luas Lahan (Ha)
								</Label>
								<Input
                                    id="Luas Lahan"
                                    type="number"
                                    className="col-span-1"
                                    value={luasLahan}
                                    onChange={(e) => setLuasLahan(e.target.value)}
									placeholder='Masukkan Luas Lahan (Ha)'
                                />
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Pemilik" className="mb-2">
									Pemilik
								</Label>
								<Input
									id="Pemilik"
									type="text"
									className="col-span-1"
									value={pemilik}
									onChange={(e) => setPemilik(e.target.value)} 
									placeholder='Masukkan Pemilik'
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Keterangan" className="mb-2">
									Keterangan
								</Label>
								<Input
									id="Keterangan"
									type="text"
									className="col-span-1"
									value={keterangan}
									onChange={(e) => setKeterangan(e.target.value)}
									placeholder='Masukkan Keterangan'
								/>
							</div>		
						</div>
						
						<DialogFooter>
							<div className="flex w-full justify-between mt-5">
								<DialogClose asChild>
									<Button
										className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
									>
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
					
				</DialogContent>
			</Dialog>
		</>
	);
};
export default EIdentifikasiLahanBencana;
