import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import ActionButtonPerumahanPB from '@/components/ActionButton/RumahRelokasi/PengumpulanData/ABPerumahanPB';
import FIdentifikasiPerumahanPB from '@/components/Forms/RumahRelokasi/PengumpulanData/Input/FIdentifikasiPerumahanPB';
import GIdentifikasiRumahPB from '@/components/Guides/RumahRelokasi/PengumpulanData/GIdentifikasiRumahPB';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useMenuRumahRelokasi } from '@/hooks/menu/useMenu';
import {
	useDeletePerumahanPB,
	useGetPerumahanPB,
} from '@/hooks/perumahan/useRumahRelokasi';
import { usePrivilegeRumahRelokasi } from '@/hooks/privilege/usePrivilege';
import { IdentifikasiDataPerumahanPB } from '@/types/perumahan';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

const TIdentifikasiPerumahanPBRelokasi = () => {
	const selectedTahun = useSelector((state: RootState) => state.tahun.value);
	const tahun = Number(selectedTahun);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const QueryClient = useQueryClient();

	//hook previlege
	const {
		isPrevilegeViewRumahRelokasi,
		kd_kabkota_to_use,
		kd_provinsi_to_use,
	} = usePrivilegeRumahRelokasi();

	//hook menu
	const { isMenuRumahRelokasiProv, isMenuRumahRelokasiKab } =
		useMenuRumahRelokasi();

	//get
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetPerumahanPB(kd_provinsi_to_use, kd_kabkota_to_use, tahun, menuId);

	const { ref, inView } = useInView({
		threshold: 0,
	});

	//delete
	const { deletePerumahanPB } = useDeletePerumahanPB();

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	// Access the data
	const pages = data?.pages || [];

	const handleEdit = (rowData: IdentifikasiDataPerumahanPB) => {
		console.log('Edit data:', rowData);
	};

	const handleDelete = async (rowData: IdentifikasiDataPerumahanPB) => {
		try {
			await deletePerumahanPB({
				id_idn_perumahan_pb: rowData.id_idn_perumahan_pb,
			});
			QueryClient.refetchQueries({
				queryKey: ['identifikasi-perumahan-pb'],
			});
			toast.success('Berhasil menghapus data');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus data');
		}
	};

	const cardRef = useRef(null);
	let rowCounter = 0;
	if (isLoading) return <CompLoader />;
	return (
		<>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				{isPrevilegeViewRumahRelokasi ? (
					<></>
				) : (
					<div className="my-2 mr-4 flex justify-end gap-4">
						<GIdentifikasiRumahPB />
						<FIdentifikasiPerumahanPB />
					</div>
				)}
				<ScrollArea className="h-full p-4">
					<JudulHeader cardRef={cardRef}>
						IDENTIFIKASI PERUMAHAN DI LOKASI YANG MENIMBULKAN BAHAYA
						<br />
						SPM PERUMAHAN RAKYAT
						<br />
						{isMenuRumahRelokasiProv ? (
							<>
								Fasilitas Penyediaan Rumah Yang Layak Huni Bagi Masyarakat Yang
								Terkena Relokasi Program Pemerintah Daerah Provinsi
							</>
						) : (
							<>
								Fasilitas Penyediaan Rumah Yang Layak Huni Bagi Masyarakat Yang
								Terkena Relokasi Program Pemerintah Daerah Kabupaten/Kota
							</>
						)}
					</JudulHeader>
					<Table className="w-full min-w-[1800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead className="thead-text border-l" rowSpan={2}>
									No
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Jenis Lokasi yang Berpotensi Menimbulkan Bahaya
								</TableHead>
								<TableHead className="thead-text text-center" colSpan={2}>
									Lokasi
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Luas Perumahan (Ha)
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Jumlah Rumah (Unit Rumah)
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Jumlah KK
								</TableHead>
								<TableHead className="thead-text text-center" colSpan={2}>
									Status Kepemilikan Tanah (Unit Rumah)
								</TableHead>
								{isPrevilegeViewRumahRelokasi ? (
									<></>
								) : (
									<TableHead className="thead-text text-center" rowSpan={2}>
										Aksi (Edit/Hapus)
									</TableHead>
								)}
							</TableRow>
							<TableRow>
								{isMenuRumahRelokasiProv ? (
									<>
										<TableHead className="thead-text border-t-0 text-center">
											Kabupaten/Kota
										</TableHead>
										<TableHead className="thead-text border-t-0 text-center">
											Kecamatan
										</TableHead>
									</>
								) : (
									<>
										<TableHead className="thead-text border-t-0 text-center">
											Kecamatan
										</TableHead>
										<TableHead className="thead-text border-t-0 text-center">
											Kelurahan/Desa
										</TableHead>
									</>
								)}
								<TableHead className="thead-text border-t-0 text-center">
									Legal
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Ilegal
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{pages.map((page) =>
								page.data.map((item: IdentifikasiDataPerumahanPB) => (
									<TableRow
										key={item.id_idn_perumahan_pb}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="tbody-text border-b border-l border-r text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jenis_potensi_bhy.nama_jenis_potensi_bahaya || '-'}
										</TableCell>
										{isMenuRumahRelokasiProv ? (
											<>
												<TableCell className="tbody-text border-b border-r text-center">
													{item.wilayah.nama_kabkota || '-'}
												</TableCell>
												<TableCell className="tbody-text border-b border-r text-center">
													{item.wilayah.nama_kecamatan || '-'}
												</TableCell>
											</>
										) : (
											<>
												<TableCell className="tbody-text border-b border-r text-center">
													{item.wilayah.nama_kecamatan || '-'}
												</TableCell>
												<TableCell className="tbody-text border-b border-r text-center">
													{item.wilayah.nama_desa || '-'}
												</TableCell>
											</>
										)}
										<TableCell className="tbody-text border-b border-r text-center">
											{item.luas_perumahan || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_rumah || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_kk || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_legal || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_illegal || '-'}
										</TableCell>
										{isPrevilegeViewRumahRelokasi ? (
											<></>
										) : (
											<TableCell className="tbody-text flex justify-center border-b border-r text-center">
												<ActionButtonPerumahanPB
													rowData={item}
													onEdit={handleEdit}
													onDelete={() => handleDelete(item)}
												/>
											</TableCell>
										)}
									</TableRow>
								)),
							)}
						</TableBody>
					</Table>
					<ScrollBar orientation="horizontal" />
					{/* <div className="text-center" ref={ref}>
					{isFetchingNextPage
						? 'Muat Lebih Banyak Data...'
						: hasNextPage
							? 'Muat Lebih Banyak Data...'
							: 'Tidak Ada Data Lagi'}
					</div> */}
				</ScrollArea>
			</div>
		</>
	);
};

export default TIdentifikasiPerumahanPBRelokasi;
