import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { FormEvent, useEffect, useState } from 'react';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { usePostIdentifikasiPerumahan } from '@/hooks/perumahan/useRumahBencana';
import { useGetJenisBencana } from '@/hooks/useJenisBencana';
import { useQueryClient } from '@tanstack/react-query';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import KabKotaSelectUI from '@/components/ui/SelectKabKotaUI';

const FKebutuhanAirMinum = () => {
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kec, setKd_kec] = useState('0');
	const [kd_desa, setKd_desa] = useState('0');
	const [kd_kabkota, setKd_kabkota] = useState(user.kd_kabkota.toString());

	//get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
	const { getKecamatan, refetch: fetchKecamatan } = useGetKecamatan(
		user.kd_provinsi,
		parseInt(kd_kabkota),
	);
	const { getDesa, refetch: fetchDesa } = useGetDesa(
		user.kd_provinsi,
		parseInt(kd_kabkota),
		parseInt(kd_kec),
	);
	const { postIdentifikasiPerumahan } = usePostIdentifikasiPerumahan();
	const { getJenisBencana } = useGetJenisBencana();

	//dataform
	const [totalRumahTangga, setTotalRumahTangga] = useState('0');
	const [terlayaniAkses, setTerlayaniAkses] = useState('');
	const [tidakTerlayaniAkses, setTidakTerlayaniAkses] = useState('');
	const [kapasitasPelayananAM, setKapasitasPelayananAM] = useState('');
	const [unitPelayananAM, setUnitPelayananAM] = useState('');
	const [sumberAirBaku, setSumberAirBaku] = useState('');
	const [kapasitasAirBaku, setKapasitasAirBaku] = useState('');
	const [kapasitasPenyerapanAM, setKapasitasPenyerapanAM] = useState('');
	const [unitPenyerapanAM, setUnitPenyerapanAM] = useState('');
	const [namaPrasarana, setNamaPrasarana] = useState('');
	const [kapasitasPrasarana, setKapasitasPrasarana] = useState('');
	const [lokasiSPAM, setLokasiSPAM] = useState('');

	//additionals
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	//resetForm
	const resetForm = () => {
		setTotalRumahTangga('0');
		setTerlayaniAkses('');
		setTidakTerlayaniAkses('');
		setKapasitasPelayananAM('');
		setUnitPelayananAM('');
		setSumberAirBaku('');
		setKapasitasAirBaku('');
		setKapasitasPenyerapanAM('');
		setUnitPenyerapanAM('');
		setNamaPrasarana('');
		setKapasitasPrasarana('');
		setLokasiSPAM('');
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		const data = {
			tahun_pelaporan: tahun,
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: kd_kabkota,
			total_rumah_tangga: parseInt(totalRumahTangga),
			terlayani_akses: terlayaniAkses,
			tidak_terlayani_akses: tidakTerlayaniAkses,
			kapasitas_pelayanan_am: kapasitasPelayananAM,
			unit_pelayanan_am: unitPelayananAM,
			sumber_air_baku: sumberAirBaku,
			kapasitas_air_baku: kapasitasAirBaku,
			kapasitas_penyerapan_am: kapasitasPenyerapanAM,
			unit_penyerapan_am: unitPenyerapanAM,
			nama_prasarana: namaPrasarana,
			kapasitas_prasarana: kapasitasPrasarana,
			lokasi_spam: lokasiSPAM,
		};

		console.log(data);

		// postIdentifikasiPerumahan(data, {
		// 	onSuccess: () => {
		// 		setModalOpen(false);
		// 		queryClient.refetchQueries({ queryKey: ['eksis-rawan-bencana'] });
		// 		resetForm();
		// 	},
		// 	onError: (error) => {
		// 		console.log(error);
		// 	},
		// });
	};

	useEffect(() => {
		fetchDesa();
		fetchKecamatan();
	}, [kd_kec, kd_kabkota]);

	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
						<FiPlusCircle />
						Input Data
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 max-h-[85%] max-w-[70%] overflow-auto">
					<DialogHeader>
						<DialogTitle>Tambah Kondisi SPAM Regional</DialogTitle>
						<DialogDescription className="my-1">
							Jika Belum Memiliki Data, Silahkan Isi Dengan Angka 0 Agar Bisa Di
							Simpan
							<br />
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>
					<form action="post" onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Kabupaten" className="mb-2">
									Kabupaten
								</Label>
								<KabKotaSelectUI
									getKabKota={getKabKota}
									setKabKota={setKd_kabkota}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Total Rumah Tangga" className="mb-2">
									Total Rumah Tangga
								</Label>
								<Input
									id="Total Rumah Tangga"
									type="number"
									className="col-span-1"
									value={totalRumahTangga}
									onChange={(e) => setTotalRumahTangga(e.target.value)}
									placeholder="Masukkan Total Rumah Tangga"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Terlayani Akses Air Minum" className="mb-2">
									Terlayani Akses Air Minum
								</Label>
								<Input
									id="Terlayani Akses Air Minum"
									type="number"
									className="col-span-1"
									value={terlayaniAkses}
									onChange={(e) => setTerlayaniAkses(e.target.value)}
									placeholder="Masukkan Terlayani Akses Air Minum"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label
									htmlFor="Tidak Terlayani Akses Air Minum"
									className="mb-2"
								>
									Tidak Terlayani Akses Air Minum
								</Label>
								<Input
									id="Tidak Terlayani Akses Air Minum"
									type="number"
									className="col-span-1"
									value={tidakTerlayaniAkses}
									onChange={(e) => setTidakTerlayaniAkses(e.target.value)}
									placeholder="Masukkan Tidak Terlayani Akses Air Minum"
									required={true}
								/>
							</div>
						</div>
						{/* Kebutuhan Pelayanan Air Minum Curah */}
						<h1 className="text-l mt-4 font-bold">
							Kebutuhan Pelayanan Air Minum Curah
						</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas" className="mb-2">
									Kapasitas (Liter/Detik)
								</Label>
								<Input
									id="Kapasitas"
									type="text"
									className="col-span-1"
									value={kapasitasPelayananAM}
									onChange={(e) => setKapasitasPelayananAM(e.target.value)}
									placeholder="Masukkan Kapasitas"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Unit" className="mb-2">
									Unit (Rumah Tangga)
								</Label>
								<Input
									id="Unit"
									type="number"
									className="col-span-1"
									value={unitPelayananAM}
									onChange={(e) => setUnitPelayananAM(e.target.value)}
									placeholder="Masukkan Unit"
									required={true}
								/>
							</div>
						</div>
						{/* Potensi Air Baku untuk SPAM Lintas Kabupaten/Kota */}
						<h1 className="text-l mt-4 font-bold">
							Potensi Air Baku untuk SPAM Lintas Kabupaten/Kota
						</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Sumber Air Baku" className="mb-2">
									Sumber Air Baku
								</Label>
								<Input
									id="Sumber Air Baku"
									type="number"
									className="col-span-1"
									value={sumberAirBaku}
									onChange={(e) => setSumberAirBaku(e.target.value)}
									placeholder="Masukkan Jumlah Sumber Air Baku"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas" className="mb-2">
									Kapasitas (Liter/Detik)
								</Label>
								<Input
									id="Kapasitas"
									type="number"
									className="col-span-1"
									value={kapasitasAirBaku}
									onChange={(e) => setKapasitasAirBaku(e.target.value)}
									placeholder="Masukkan Jumlah Kapasitas Air Baku"
									required={true}
								/>
							</div>
						</div>
						{/* Kemampuan Penyerapan Akses Pelayanan Air Minum */}
						<h1 className="text-l mt-4 font-bold">
							Kemampuan Penyerapan Akses Pelayanan Air Minum
						</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas" className="mb-2">
									Kapasitas (Liter/Detik)
								</Label>
								<Input
									id="Kapasitas"
									type="text"
									className="col-span-1"
									value={kapasitasPenyerapanAM}
									onChange={(e) => setKapasitasPenyerapanAM(e.target.value)}
									placeholder="Masukkan Kapasitas Penyerapan Air Minum"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Unit" className="mb-2">
									Unit (Rumah Tangga)
								</Label>
								<Input
									id="Unit"
									type="number"
									className="col-span-1"
									value={unitPenyerapanAM}
									onChange={(e) => setUnitPenyerapanAM(e.target.value)}
									placeholder="Masukkan Unit"
									required={true}
								/>
							</div>
						</div>
						{/* Prasarana SPAM Lintas Kabupaten/Kota */}
						<h1 className="text-l mt-4 font-bold">
							Prasarana SPAM Lintas Kabupaten/Kota
						</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Nama Parasarana" className="mb-2">
									Nama Parasarana
								</Label>
								<Input
									id="Nama Parasarana"
									type="text"
									className="col-span-1"
									value={namaPrasarana}
									onChange={(e) => setNamaPrasarana(e.target.value)}
									placeholder="Masukkan Nama Prasarana"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas" className="mb-2">
									Kapasitas (Liter/Detik)
								</Label>
								<Input
									id="Kapasitas"
									type="text"
									className="col-span-1"
									value={kapasitasPrasarana}
									onChange={(e) => setKapasitasPrasarana(e.target.value)}
									placeholder="Masukkan Kapasitas Penyerapan Air Minum"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Lokasi SPAM" className="mb-2">
									Lokasi SPAM
								</Label>
								<Input
									id="Lokasi SPAM"
									type="text"
									className="col-span-1"
									value={lokasiSPAM}
									onChange={(e) => setLokasiSPAM(e.target.value)}
									placeholder="Masukkan Lokasi SPAM"
									required={true}
								/>
							</div>
						</div>
						<DialogFooter>
							<div className="mt-5 flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default FKebutuhanAirMinum;
