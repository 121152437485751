import { useQuery } from '@tanstack/react-query';
import { API } from '../libs/API';

export function useSatuan(komponen_id: number) {
	const {
		data: getSatuan,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['satuan', komponen_id],
		queryFn: async () => {
			const { data } = await API.get(`/satuan/?komponen_id=${komponen_id}`);
			return data.data;
		},
	});
	return {
		getSatuan,
		refetch,
		isLoading,
	};
}
