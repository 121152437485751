import { API } from '../libs/API';
import { useQuery } from '@tanstack/react-query';

export function useGetPeriode() {
	const {
		data: getPeriode,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['periode'],
		queryFn: async () => {
			const { data } = await API.get(`/periode-tanggal`);
			return data.data;
		},
	});
	return { getPeriode, refetch, isLoading };
}
