import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import JenisBencanaSelectUI from '@/components/ui/SelectJenisBencanaUI';
import { useGetJenisBencana } from '@/hooks/useJenisBencana';
import { FormEvent, useEffect, useState } from 'react';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import DesaSelectUI from '@/components/ui/SelectDesaUI';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { usePostPengumpulanDataRumah } from '@/hooks/perumahan/useRumahBencana';
import { toast } from 'sonner';
import { useQueryClient } from '@tanstack/react-query';
import KabKotaSelectUI from '@/components/ui/SelectKabKotaUI';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';

const FPengumpulanDataRumahBencana = () => {
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kec, setKd_kec] = useState('0');
	const [kd_desa, setKd_desa] = useState('0');
	const [kd_kabkota, setKd_kabkota] = useState(user.kd_kabkota.toString());

	//get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
	const { getJenisBencana } = useGetJenisBencana();
	const { getKecamatan, refetch: fetchKecamatan } = useGetKecamatan(
		user.kd_provinsi,
		parseInt(kd_kabkota),
	);
	const { getDesa, refetch: fetchDesa } = useGetDesa(
		user.kd_provinsi,
		parseInt(kd_kabkota),
		parseInt(kd_kec),
	);
	const { postPengumpulanDataRumah } = usePostPengumpulanDataRumah();

	//dataForm
	const [jenisBencana, setJenisBencana] = useState('0');
	const [tahunTerjadiBencana, setTahunTerjadiBencana] = useState('');
	const [jumlahRumahRusak, setJumlahRumahRusak] = useState('');
	const [rusakRingan, setRusakRingan] = useState('');
	const [rusakSedang, setRusakSedang] = useState('');
	const [rusakBerat, setRusakBerat] = useState('');
	const [statusHakMilik, setStatusHakMilik] = useState('');
	const [statusSewa, setStatusSewa] = useState('');
	const [statusLainnya, setStatusLainnya] = useState('');

	//additionals
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	//resetForm
	const resetForm = () => {
		setJenisBencana('0');
		setTahunTerjadiBencana('');
		setJumlahRumahRusak('');
		setRusakRingan('');
		setRusakSedang('');
		setRusakBerat('');
		setStatusHakMilik('');
		setStatusSewa('');
		setStatusLainnya('');
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		const data = {
			tahun_pelaporan: tahun,
			jenis_bencana_id: parseInt(jenisBencana),
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: kd_kabkota,
			kd_kec: kd_kec,
			kd_desa: kd_desa,
			tahun_kejadian: parseInt(tahunTerjadiBencana),
			jml_rmh_rusak: parseInt(jumlahRumahRusak),
			rusak_ringan: parseInt(rusakRingan),
			rusak_sedang: parseInt(rusakSedang),
			rusak_berat: parseInt(rusakBerat),
			status_milik: parseInt(statusHakMilik),
			status_sewa: parseInt(statusSewa),
			status_lainnya: parseInt(statusLainnya),
		};
		postPengumpulanDataRumah(data, {
			onSuccess: () => {
				setModalOpen(false);
				queryClient.refetchQueries({ queryKey: ['eksis-bencana-pengumpulan'] });
				resetForm();
			},
			onError: (error) => {
				console.log(error);
			},
		});
	};

	useEffect(() => {
		fetchDesa();
		fetchKecamatan();
	}, [kd_kec, kd_kabkota]);

	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
						<FiPlusCircle />
						Input Data
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 mx-auto max-w-screen-xl">
					<DialogHeader>
						<DialogTitle>Tambah Data Rumah Yang Terkena Bencana</DialogTitle>
						<DialogDescription className="my-1">
							Jika Belum Memiliki Data, Silahkan Isi Dengan Angka 0 Agar Bisa Di
							Simpan
							<br />
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>

					<form action="post" onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Jenis Bencana" className="mb-2">
									Jenis Bencana
								</Label>
								<JenisBencanaSelectUI
									getJenisBencana={getJenisBencana}
									setJenisBencana={setJenisBencana}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Tahun Terjadinya Bencana" className="mb-2">
									Tahun Terjadinya Bencana
								</Label>
								<Input
									id="Tahun Terjadinya Bencana"
									type="number"
									className="col-span-1"
									value={tahunTerjadiBencana}
									onChange={(e) => setTahunTerjadiBencana(e.target.value)}
									placeholder="Masukkan Tahun Terjadinya Bencana"
									required={true}
								/>
							</div>
							{menuId === 7 && (
								<div className="flex flex-col">
									<Label htmlFor="Kabupaten" className="mb-2">
										Kabupaten
									</Label>
									<KabKotaSelectUI
										getKabKota={getKabKota}
										setKabKota={setKd_kabkota}
									/>
								</div>
							)}
							<div className="flex flex-col">
								<Label htmlFor="Kecamatan" className="mb-2">
									Kecamatan
								</Label>
								<KecamatanSelectUI
									getKecamatan={getKecamatan}
									setKd_kec={setKd_kec}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kelurahan/Desa" className="mb-2">
									Kelurahan/Desa
								</Label>
								<DesaSelectUI setKd_desa={setKd_desa} getDesa={getDesa} />
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah Rusak" className="mb-2">
									Jumlah Rumah Rusak
								</Label>
								<Input
									id="Jumlah Rumah Rusak"
									type="text"
									className="col-span-1"
									value={jumlahRumahRusak}
									onChange={(e) => setJumlahRumahRusak(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Rusak"
									required={true}
								/>
							</div>
						</div>
						{/* Tingkat Kerusakan Rumah */}
						<h1 className="text-l mt-4 font-bold">Tingkat Kerusakan Rumah</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Rusak Ringan" className="mb-2">
									Rusak Ringan
								</Label>
								<Input
									id="Rusak Ringan"
									type="text"
									className="col-span-1"
									value={rusakRingan}
									onChange={(e) => setRusakRingan(e.target.value)}
									required={true}
									placeholder="Masukkan Jumlah Rumah Rusak Ringan"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Rusak Sedang" className="mb-2">
									Rusak Sedang
								</Label>
								<Input
									id="Rusak Sedang"
									type="text"
									className="col-span-1"
									value={rusakSedang}
									onChange={(e) => setRusakSedang(e.target.value)}
									required={true}
									placeholder="Masukkan Jumlah Rumah Rusak Sedang"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Rusak Berat" className="mb-2">
									Rusak Berat
								</Label>
								<Input
									id="Rusak Berat"
									type="text"
									className="col-span-1"
									value={rusakBerat}
									onChange={(e) => setRusakBerat(e.target.value)}
									required={true}
									placeholder="Masukkan Jumlah Rumah Rusak Berat"
								/>
							</div>
						</div>
						{/* Status Kepemilikan Rumah */}
						<h1 className="text-l mt-4 font-bold">Status Kepemilikan Rumah</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Milik" className="mb-2">
									Milik
								</Label>
								<Input
									id="Milik"
									type="text"
									className="col-span-1"
									value={statusHakMilik}
									onChange={(e) => setStatusHakMilik(e.target.value)}
									required={true}
									placeholder="Masukkan Jumlah Status Hak Milik"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Sewa" className="mb-2">
									Sewa
								</Label>
								<Input
									id="Sewa"
									type="text"
									className="col-span-1"
									value={statusSewa}
									onChange={(e) => setStatusSewa(e.target.value)}
									required={true}
									placeholder="Masukkan Jumlah Status Sewa"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Lainnya" className="mb-2">
									Lainnya
								</Label>
								<Input
									id="Lainnya"
									type="text"
									className="col-span-1"
									value={statusLainnya}
									onChange={(e) => setStatusLainnya(e.target.value)}
									required={true}
									placeholder="Masukkan Jumlah Status Lainnya"
								/>
							</div>
						</div>
						<DialogFooter>
							<div className="mt-5 flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};
export default FPengumpulanDataRumahBencana;
