import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import LogoPupr from '../../images/logo/logo-pupr.png';
import LogoSicalmers from '../../images/logo/logo-sicalmers.png';
import { FiEye, FiUsers, FiEyeOff } from 'react-icons/fi';
import useColorMode from '../../hooks/useColorMode';
import { useLogin } from '@/hooks/useLogin';
import CardInfo from '@/components/Cards/CardsInfo';

const Login: React.FC = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [colorMode] = useColorMode();
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const isDark = colorMode === 'dark'; // Check if the current color mode is dark
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const { userLogin } = useLogin();

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		userLogin({ username, password });
	};

	return (
		<>
			{/* Elemen pembungkus yang hanya ditampilkan pada layar besar */}
			<div
				className={`hidden h-screen flex-col content-center items-center justify-center bg-[#364878] md:flex xl:mx-auto ${
					isDark ? 'dark-mode-class' : 'light-mode-class'
				}`}
			>
				<div className="self-center rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark sm:w-full xl:w-3/5">
					<div className="flex flex-wrap items-center">
						<div className="hidden w-full xl:block xl:w-1/2">
							<div className="px-26 py-17.5 text-center">
								<Link className="mb-5.5 inline-block" to="/">
									<img className="hidden" src={LogoPupr} alt="Logo" />
									<img src={LogoSicalmers} alt="Logo" />
								</Link>

								<p className="text-slate dark:text-white 2xl:px-2">
									Selamat datang di Sistem Informasi Capaian Air Limbah, Air
									Minum, Perumahan dan Persampahan
								</p>
							</div>
						</div>

						<div className="w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
							<div className="w-full p-4 sm:p-10 xl:p-17.5">
								<span className="mb-1.5 block font-medium dark:text-white">
									Silahkan isi data diri anda
								</span>
								<h2 className="mb-9 text-2xl font-bold text-[#364878] dark:text-white sm:text-title-xl2">
									Masuk ke SICALMERS
								</h2>

								<form onSubmit={handleSubmit}>
									<div className="mb-4">
										<label className="text-slate mb-2.5 block font-medium dark:text-white">
											User ID
										</label>
										<div className="relative">
											<input
												value={username}
												name="username"
												onChange={(e) => setUsername(e.target.value)}
												placeholder="Masukkan username anda"
												autoComplete="username"
												className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
											/>
											<span className="absolute right-4 top-5">
												<FiUsers className="text-slate-400" />
											</span>
										</div>
									</div>

									<div className="mb-10">
										<label className="text-slate mb-2.5 block font-medium dark:text-white">
											Password
										</label>
										<div className="relative">
											<input
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												type={showPassword ? 'text' : 'password'} // Toggle input type
												placeholder="Masukkan password anda"
												className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
											/>
											<span
												className="absolute right-4 top-5 cursor-pointer"
												onClick={togglePasswordVisibility} // Toggle password visibility
											>
												{showPassword ? (
													<FiEye className="text-slate-400" />
												) : (
													<FiEyeOff className="text-slate-400" />
												)}
											</span>
										</div>
									</div>

									<div className="mb-5">
										<button
											type="submit"
											className="w-full cursor-pointer rounded-lg border border-primary bg-[#3C6AE0] p-4 text-white transition hover:bg-opacity-90"
										>
											Masuk
										</button>
									</div>

									<div className="mt-7 text-center">
										<p>Lupa password atau daftar ulang</p>
										<Link
											to="/auth/forgot-password"
											className="text-center font-bold text-[#364878] dark:text-white"
										>
											reset
										</Link>
									</div>
									<div className="mt-7">
										<a
											href="https://bit.ly/PanduanSICALMERS"
											className="text-blue-600 text-inherit no-underline hover:text-blue-800"
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="border-gray-300 relative flex transform items-center justify-center overflow-hidden rounded-lg border bg-white p-3 shadow-lg transition-transform hover:scale-105 dark:border-strokedark dark:bg-gradient-to-r dark:from-[#2d3748] dark:to-[#4a5568]">
												<div className="w-full text-center">
													<p className="font-bold text-[#364878] dark:text-white">
														Panduan Penggunaan Aplikasi
													</p>
												</div>
											</div>
										</a>
									</div>
									<div className="mt-6 text-center dark:text-white">
										<br />
										<br />
										<p>Layanan Informasi</p>
										<a
											href="mailto:perkim.pfid@pu.go.id"
											className="font-bold text-[#364878] dark:text-white"
										>
											perkim.pfid@pu.go.id
										</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Isi dari elemen pembungkus yang ditampilkan pada layar kecil */}
			<div className="md:hidden">
				<div className="self-center rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark sm:w-full xl:w-3/5">
					<div className="flex h-screen flex-wrap items-center">
						<div className="w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
							<div className="w-full p-4 sm:p-10 xl:p-17.5">
								<span className="mb-1.5 block font-medium dark:text-white">
									Silahkan isi data diri anda
								</span>
								<h2 className="mb-9 text-2xl font-bold text-[#364878] dark:text-white sm:text-title-xl2">
									Masuk ke SICALMERS
								</h2>

								<form onSubmit={handleSubmit}>
									<div className="mb-4">
										<label className="text-slate mb-2.5 block font-medium dark:text-white">
											User ID
										</label>
										<div className="relative">
											<input
												value={username}
												name="username"
												onChange={(e) => setUsername(e.target.value)}
												placeholder="Masukkan username anda"
												autoComplete="username"
												className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
											/>
											<span className="absolute right-4 top-5">
												<FiUsers className="text-slate-400" />
											</span>
										</div>
									</div>

									<div className="mb-10">
										<label className="text-slate mb-2.5 block font-medium dark:text-white">
											Password
										</label>
										<div className="relative">
											<input
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												type={showPassword ? 'text' : 'password'} // Toggle input type
												placeholder="Masukkan password anda"
												className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
											/>
											<span
												className="absolute right-4 top-5 cursor-pointer"
												onClick={togglePasswordVisibility} // Toggle password visibility
											>
												{showPassword ? (
													<FiEye className="text-slate-400" />
												) : (
													<FiEyeOff className="text-slate-400" />
												)}
											</span>
										</div>
									</div>

									<div className="mb-5">
										<button
											type="submit"
											className="w-full cursor-pointer rounded-lg border border-primary bg-[#3C6AE0] p-4 text-white transition hover:bg-opacity-90"
										>
											Masuk
										</button>
									</div>

									<div className="mt-7 text-center">
										<p>Lupa password atau daftar ulang</p>
										<Link
											to="/auth/forgot-password"
											className="text-center font-bold text-[#364878] dark:text-white"
										>
											reset
										</Link>
									</div>
									<div className="mt-7">
										<a
											href="https://bit.ly/PanduanSICALMERS"
											className="text-blue-600 text-inherit no-underline hover:text-blue-800"
											target="_blank"
											rel="noopener noreferrer"
										>
											<div className="border-gray-300 relative flex transform items-center justify-center overflow-hidden rounded-lg border bg-white p-3 shadow-lg transition-transform hover:scale-105 dark:border-strokedark dark:bg-gradient-to-r dark:from-[#2d3748] dark:to-[#4a5568]">
												<div className="w-full text-center">
													<p className="font-bold text-[#364878] dark:text-white">
														Panduan Penggunaan Aplikasi
													</p>
												</div>
											</div>
										</a>
									</div>
									<div className="mt-6 text-center dark:text-white">
										<br />
										<br />
										<p>Layanan Informasi</p>
										<a
											href="mailto:perkim.pfid@pu.go.id"
											className="font-bold text-[#364878] dark:text-white"
										>
											perkim.pfid@pu.go.id
										</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
