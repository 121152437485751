import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FormEvent, useEffect, useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import DesaSelectUI from '@/components/ui/SelectDesaUI';
import {usePostIdentifikasiLahanPotensial} from '@/hooks/perumahan/useRumahBencana';
import { useQueryClient } from '@tanstack/react-query';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import KabKotaSelectUI from '@/components/ui/SelectKabKotaUI';

const FIdentifikasiLahanBencana = () => {
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kec, setKd_kec] = useState('0');
	const [kd_desa, setKd_desa] = useState('0');
	const [kd_kabkota, setKd_kabkota] = useState(user.kd_kabkota.toString());
	//get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
	const { getKecamatan, refetch: fetchKecamatan } = useGetKecamatan(
		user.kd_provinsi,
		parseInt(kd_kabkota),
	);
	const { getDesa, refetch: fetchDesa } = useGetDesa(
		user.kd_provinsi,
		parseInt(kd_kabkota),
		parseInt(kd_kec),
	);
	const { postIdentifikasiLahanPotensial } =
		usePostIdentifikasiLahanPotensial();

	//dataform
	const [luasLahan, setLuasLahan] = useState('');
	const [pemilik, setPemilik] = useState('');
	const [keterangan, setKeterangan] = useState('');

	//additionals
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	//resetForm
	const resetForm = () => {
		setLuasLahan('');
		setPemilik('');
		setKeterangan('');
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		const data = {
			tahun_pelaporan: tahun,
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: kd_kabkota,
			kd_kec: parseInt(kd_kec),
			kd_desa: parseInt(kd_desa),
			luas_lhn: parseInt(luasLahan),
			pemilik: pemilik,
			keterangan: keterangan,
		};

		postIdentifikasiLahanPotensial(data, {
			onSuccess: () => {
				queryClient.refetchQueries({ queryKey: ['eksis-lahan-potensial'] });
				resetForm();
				setModalOpen(false);
			},
			onError: (error) => {
				console.log(error);
			},
		});
	};

	useEffect(() => {
		fetchDesa();
		fetchKecamatan();
	}, [kd_kec, kd_kabkota]);
	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
						<FiPlusCircle />
						Input Data
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 mx-auto max-w-screen-xl">
					<DialogHeader>
						<DialogTitle>Tambah Data Identifikasi Lahan Potensial</DialogTitle>
						<DialogDescription className="my-1">
							Jika Belum Memiliki Data, Silahkan Isi Dengan Angka 0 Agar Bisa Di
							Simpan
							<br />
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>

					<form action="post" onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							{menuId === 7 && (
								<div className="flex flex-col">
									<Label htmlFor="Kabupaten" className="mb-2">
										Kabupaten
									</Label>
									<KabKotaSelectUI
										getKabKota={getKabKota}
										setKabKota={setKd_kabkota}
									/>
								</div>
							)}
							<div className="flex flex-col">
								<Label htmlFor="Kecamatan" className="mb-2">
									Kecamatan
								</Label>
								<KecamatanSelectUI
									getKecamatan={getKecamatan}
									setKd_kec={setKd_kec}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kelurahan/Desa" className="mb-2">
									Kelurahan/Desa
								</Label>
								<DesaSelectUI getDesa={getDesa} setKd_desa={setKd_desa} />
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Luas Lahan" className="mb-2">
									Luas Lahan (Ha)
								</Label>
								<Input
									id="Luas Lahan"
									type="number"
									className="col-span-1"
									value={luasLahan}
									onChange={(e) => setLuasLahan(e.target.value)}
									placeholder="Masukkan Luas Lahan (Ha)"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Pemilik" className="mb-2">
									Pemilik
								</Label>
								<Input
									id="Pemilik"
									type="text"
									className="col-span-1"
									value={pemilik}
									onChange={(e) => setPemilik(e.target.value)}
									placeholder="Masukkan Pemilik"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Keterangan" className="mb-2">
									Keterangan
								</Label>
								<Input
									id="Keterangan"
									type="text"
									className="col-span-1"
									value={keterangan}
									onChange={(e) => setKeterangan(e.target.value)}
									placeholder="Masukkan Keterangan"
								/>
							</div>
						</div>

						<DialogFooter>
							<div className="mt-5 flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};
export default FIdentifikasiLahanBencana;
