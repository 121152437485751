import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import {
	useDeleteIdentifikasiRumahRelokasi,
	useGetPerumahanTerkenaRelokasi,
} from '@/hooks/perumahan/useRumahRelokasi';
import { IdentifikasiPerumahanRelokasi } from '@/types/perumahan';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import JudulHeader from '@/components/Header/JudulHeader';
import FIdentifikasiRumahRelokasi from '@/components/Forms/RumahRelokasi/PengumpulanData/Input/FIdentifikasiRumahRelokasi';
import ActionButtonRumahRelokasi from '@/components/ActionButton/RumahRelokasi/PengumpulanData/ABIdentifikasiRumahRelokasi';
import GIdentifikasiRumahRelokasi from '@/components/Guides/RumahRelokasi/PengumpulanData/GIdentifikasiRumahRelokasi';
import { usePrivilegeRumahRelokasi } from '@/hooks/privilege/usePrivilege';
import { useMenuRumahRelokasi } from '@/hooks/menu/useMenu';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

const TIdentifikasiRumahRelokasi = () => {
	const selectedTahun = useSelector((state: RootState) => state.tahun.value);
	const tahun = Number(selectedTahun);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const QueryClient = useQueryClient();

	//hook previlege
	const {
		isPrevilegeViewRumahRelokasi,
		kd_kabkota_to_use,
		kd_provinsi_to_use,
		privilege,
	} = usePrivilegeRumahRelokasi();

	//hook menu
	const { isMenuRumahRelokasiProv, isMenuRumahRelokasiKab } =
		useMenuRumahRelokasi();

	// console.log(
	// 	'kd_provinsi_to_use',
	// 	kd_provinsi_to_use,
	// 	'kd_kabkota_to_use',
	// 	kd_kabkota_to_use,
	// 	'tahun',
	// 	tahun,
	// 	'menu',
	// 	menuId,
	// 	'previlege',
	// 	privilege,
	// );

	//getData
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetPerumahanTerkenaRelokasi(
			kd_provinsi_to_use,
			kd_kabkota_to_use,
			tahun,
			menuId,
		);

	//Delete
	const { deleteIdentifikasiRumahRelokasi, isPending, isSuccess } =
		useDeleteIdentifikasiRumahRelokasi();

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	// Access the data
	const pages = data?.pages || [];

	const handleEdit = (rowData: IdentifikasiPerumahanRelokasi) => {
		console.log('Edit data:', rowData);
	};

	const handleDelete = async (rowData: IdentifikasiPerumahanRelokasi) => {
		try {
			await deleteIdentifikasiRumahRelokasi({
				id_idn_perumahan_relokasi: rowData.id_idn_perumahan_relokasi,
			});
			QueryClient.refetchQueries({
				queryKey: ['identifikasi-perumahan-relokasi'],
			});
			toast.success('Berhasil menghapus data');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus data');
		}
	};

	useEffect(() => {
		console.log(pages);
	}, [pages, kd_provinsi_to_use, kd_kabkota_to_use, tahun, menuId]);

	const cardRef = useRef(null);
	let rowCounter = 0;
	if (isLoading) return <CompLoader />;
	return (
		<>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				{isPrevilegeViewRumahRelokasi ? (
					<></>
				) : (
					<div className="my-2 mr-4 flex justify-end gap-4">
						<GIdentifikasiRumahRelokasi />
						<FIdentifikasiRumahRelokasi />
					</div>
				)}
				<ScrollArea className="relative h-full p-4">
					<JudulHeader isLoading={isLoading} cardRef={cardRef}>
						IDENTIFIKASI PERUMAHAN YANG TERKENA RELOKASI PROGRAM PEMERINTAH
						DAERAH KABUPATEN/KOTA
						<br />
						SPM PERUMAHAN RAKYAT
						<br />
						{isMenuRumahRelokasiProv ? (
							<>
								Fasilitas Penyediaan Rumah Yang Layak Huni Bagi Masyarakat Yang
								Terkena Relokasi Program Pemerintah Daerah Provinsi
							</>
						) : (
							<>
								Fasilitas Penyediaan Rumah Yang Layak Huni Bagi Masyarakat Yang
								Terkena Relokasi Program Pemerintah Daerah Kabupaten/Kota
							</>
						)}
					</JudulHeader>
					<Table className="w-full min-w-[1800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead className="thead-text border-l" rowSpan={2}>
									No
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Jenis Program Relokasi
								</TableHead>
								{isMenuRumahRelokasiProv && (
									<TableHead className="thead-text text-center" rowSpan={2}>
										Kabupaten/Kota
									</TableHead>
								)}
								<TableHead className="thead-text text-center" rowSpan={2}>
									Kecamatan
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Jumlah Rumah (Unit)
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Jumlah KK
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Jumlah Jiwa
								</TableHead>
								<TableHead className="thead-text text-center" colSpan={2}>
									Status Kepemilikan Tanah (Unit Rumah)
								</TableHead>
								<TableHead className="thead-text text-center" colSpan={4}>
									Sudah Terlayani (Unit Rumah)
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Belum Terlayani (Unit Rumah)
								</TableHead>
								{isPrevilegeViewRumahRelokasi ? (
									<></>
								) : (
									<TableHead className="thead-text text-center" rowSpan={2}>
										Aksi (Edit/Hapus)
									</TableHead>
								)}
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-t-0 text-center">
									Legal
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Ilegal
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Penggantian Uang
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Pambangunan Kembali
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Subsidi Uang Sewa
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Akses Tinggal Rumah Sewa
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{pages.map((page) =>
								page.data.map((item: IdentifikasiPerumahanRelokasi) => (
									<TableRow
										key={item.id_idn_perumahan_relokasi}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="tbody-text border-b border-l border-r text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jenis_relokasi.nama_jenis_relokasi}
										</TableCell>
										{menuId === 8 && (
											<TableCell className="tbody-text border-b border-r text-center">
												{item.wilayah.nama_kabkota}
											</TableCell>
										)}
										<TableCell className="tbody-text border-b border-r text-center">
											{item.wilayah.nama_kecamatan}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_rmh}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_legal}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_illegal}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.tlyn_ganti_uang}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.tlyn_pembangunan_kembali}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.tlyn_subsidi_sewa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.tlyn_aks_rumah_sewa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.blm_terlayani}
										</TableCell>
										{isPrevilegeViewRumahRelokasi ? (
											<></>
										) : (
											<TableCell className="tbody-text flex justify-center border-b border-r text-center">
												<ActionButtonRumahRelokasi
													rowData={item}
													onEdit={handleEdit}
													onDelete={() => handleDelete(item)}
												/>
											</TableCell>
										)}
									</TableRow>
								)),
							)}
						</TableBody>
					</Table>
					<ScrollBar orientation="horizontal" />
					<div className="my-2 text-center" ref={ref}>
						{isFetchingNextPage
							? 'Muat Lebih Banyak Data...'
							: hasNextPage
								? 'Muat Lebih Banyak Data...'
								: 'Tidak Ada Data Lagi'}
					</div>
				</ScrollArea>
			</div>
		</>
	);
};

export default TIdentifikasiRumahRelokasi;
