import { RootState } from "@/app/store"
import { CompLoader } from "@/common/Loader"
import JudulHeader from "@/components/Header/JudulHeader"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useGetDataCapaian } from "@/hooks/perumahan/useRumahBencana"
import { hitungTotalData } from "@/libs/utils"
import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { useSelector } from "react-redux"
import PenetapanTidakAdaBencana from './PenetapanTidakAdaBencana';
import { utils, writeFile } from "xlsx-js-style"
import { Button } from "@/components/ui/Button"
import { FiDownload } from "react-icons/fi"

const TableDataCapaianKumulatifRumahBencanaBappedaProv = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menu_id = useSelector((state: RootState) => state.menuId.value);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const nama_provinsi = useSelector((state: RootState) => state.auth.wilayah.nama_provinsi)
	const nama_kabkota = useSelector((state: RootState) => state.auth.wilayah.nama_kabkota)
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux, nama_kabkota: nama_kabkota_dux, nama_provinsi: nama_provinsi_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const [dataRB, setDataRB] = useState<any>([])
	const [penetapanBencana, setPenetapanBencana] = useState<any>(null);

	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi
	const kd_kabkota_to_use = (privilege === 10 || privilege === 4 || privilege === 8) ? kd_kabkota_dux : kd_kabkota
	const nama_kabkota_to_use = (privilege === 10 || privilege === 4 || privilege === 8) ? nama_kabkota_dux : nama_kabkota;
	const nama_provinsi_to_use = privilege === 10 ? nama_provinsi_dux : nama_provinsi;

	const [subTotal, setSubTotal] = useState({
		jml_rmh_rusak: 0,
		tingkat_rusak_ringan: 0,
		tingkat_rusak_sedang: 0,
		tingkat_rusak_berat: 0,
		tgt_rehabilitasi: 0,
		tgt_pembangunan: 0,
		tgt_relokasi: 0,
		tgt_sewa: 0,
		tgt_akses_tinggal: 0,
		real_rehabilitasi: 0,
		real_pembangunan: 0,
		real_relokasi: 0,
		real_sewa: 0,
		real_akses_tinggal: 0,
	});

	const [total, setTotal] = useState({
		jml_rmh_rusak: 0,
		tingkat_kerusakan_rumah: 0,
		target: 0,
		realisasi: 0,
		capaian: 0,
	});

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetDataCapaian(
			kd_provinsi_to_use,
			kd_kabkota_to_use,
			Number(tahun),
			menu_id,
		);

	const { ref, inView } = useInView({
		threshold: 0,
	});

	const cardRef = useRef(null);

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	useEffect(() => {
		if (!isLoading) {
			if (!hasNextPage) {
				const subTotalJumlahRumahRusak = hitungTotalData(
					pages,
					'jml_rmh_rusak',
				);
				const subTotalTingkatRusakRingan = hitungTotalData(
					pages,
					'tingkat_rusak_ringan',
				);
				const subTotalTingkatRusakSedang = hitungTotalData(
					pages,
					'tingkat_rusak_sedang',
				);
				const subTotalTingkatRusakBerat = hitungTotalData(
					pages,
					'tingkat_rusak_berat',
				);
				const subTotalTargetRehabilitasi = hitungTotalData(
					pages,
					'tgt_rehabilitasi',
				);
				const subTotalTargetPembangunan = hitungTotalData(
					pages,
					'tgt_pembangunan',
				);
				const subTotalTargetRelokasi = hitungTotalData(pages, 'tgt_relokasi');
				const subTotalTargetSewa = hitungTotalData(pages, 'tgt_sewa');
				const subTotalTargetAksesTinggal = hitungTotalData(
					pages,
					'tgt_akses_tinggal',
				);
				const subTotalRealisasiRehabilitasi = hitungTotalData(
					pages,
					'real_rehabilitasi',
				);
				const subTotalRealisasiPembangunan = hitungTotalData(
					pages,
					'real_pembangunan',
				);
				const subTotalRealisasiRelokasi = hitungTotalData(
					pages,
					'real_relokasi',
				);
				const subTotalRealisasiSewa = hitungTotalData(pages, 'real_sewa');
				const subTotalRealisasiAksesTinggal = hitungTotalData(
					pages,
					'real_akses_tinggal',
				);

				const totalJumlahRumahRusak = subTotalJumlahRumahRusak;
				const totalTingkatanKerusakanRumah =
					subTotalTingkatRusakRingan +
					subTotalTingkatRusakSedang +
					subTotalTingkatRusakBerat;
				const totalRealisasi =
					subTotalRealisasiRehabilitasi +
					subTotalRealisasiPembangunan +
					subTotalRealisasiRelokasi +
					subTotalRealisasiSewa +
					subTotalRealisasiAksesTinggal;
				const totalTarget =
					subTotalTargetRehabilitasi +
					subTotalTargetPembangunan +
					subTotalTargetRelokasi +
					subTotalTargetSewa +
					subTotalTargetAksesTinggal;

				const capaianSPM = (totalRealisasi / totalTarget) * 100;

				setSubTotal((prevState: any) => ({
					...prevState,
					jml_rmh_rusak: subTotalJumlahRumahRusak,
					tingkat_rusak_ringan: subTotalTingkatRusakRingan,
					tingkat_rusak_sedang: subTotalTingkatRusakSedang,
					tingkat_rusak_berat: subTotalTingkatRusakBerat,
					tgt_rehabilitasi: subTotalTargetRehabilitasi,
					tgt_pembangunan: subTotalTargetPembangunan,
					tgt_relokasi: subTotalTargetRelokasi,
					tgt_sewa: subTotalTargetSewa,
					tgt_akses_tinggal: subTotalTargetAksesTinggal,
					real_rehabilitasi: subTotalRealisasiRehabilitasi,
					real_pembangunan: subTotalRealisasiPembangunan,
					real_relokasi: subTotalRealisasiRelokasi,
					real_sewa: subTotalRealisasiSewa,
					real_akses_tinggal: subTotalRealisasiAksesTinggal,
				}));

				setTotal((prevState: any) => ({
					...prevState,
					jml_rmh_rusak: totalJumlahRumahRusak,
					tingkat_kerusakan_rumah: totalTingkatanKerusakanRumah,
					target: totalTarget,
					realisasi: totalRealisasi,
					capaian: capaianSPM.toFixed(2),
				}));
			}
		}
	}, [isLoading, hasNextPage, tahun]);

	const handleDownload = () => {
		const transformedData = dataRB.map((item: any, index: any) => ([
			{ v: index + 1, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.jenis_bencana.nama_jenis_bencana, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tahun_kejadian, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.wilayah.nama_kecamatan, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.wilayah.nama_desa, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.jml_rmh_rusak, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tingkat_rusak_ringan, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tingkat_rusak_sedang, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tingkat_rusak_berat, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_rehabilitasi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_pembangunan, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_relokasi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_sewa, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_akses_tinggal, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_rehabilitasi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_pembangunan, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_relokasi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_sewa, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_akses_tinggal, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
		]));

		const header = [
			{ v: "No", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Jenis Bencana", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Tahun Terjadinya Bencana", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Kecamatan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Kelurahan/Desa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Jumlah Rumah Rusak", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Tingkat Rusak Rumah", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
		];

		const header1 = [
			{ v: "Tingkat Rusak Ringan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Tingkat Rusak Sedang", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Tingkat Rusak Berat", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Rehabilitasi Rumah", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Pembangunan Kembali", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Pemukiman Kembali", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Subsidi Uang Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Tinggal", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Rehabilitasi Rumah", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Pembangunan Kembali", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Pemukiman Kembali", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Subsidi Uang Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Tinggal", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
		];

		const header2 = [
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Unit)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
		];

		const subTotalRow = [
			{ v: subTotal.jml_rmh_rusak, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tingkat_rusak_ringan, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tingkat_rusak_sedang, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tingkat_rusak_berat, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_rehabilitasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_pembangunan, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_relokasi, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_sewa, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_akses_tinggal, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_rehabilitasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_pembangunan, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_relokasi, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_sewa, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_akses_tinggal, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(ws, [[{ v: "DATA CAPAIAN", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A1" })
		utils.sheet_add_aoa(ws, [[{ v: "SPM PERUMAHAN RAKYAT", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A2" })
		utils.sheet_add_aoa(ws, [[{ v: `PENYEDIAAN DAN REHABILITASI RUMAH YANG LAYAK HUNI`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A3" })
		utils.sheet_add_aoa(ws, [[{ v: `BAGI KORBAN BENCANA KABUPATEN / KOTA ${tahun}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A4" })

		utils.sheet_add_aoa(ws, [header], { origin: "A5" })
		utils.sheet_add_aoa(ws, [[{ v: "Target", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "J5" })
		utils.sheet_add_aoa(ws, [[{ v: "Realisasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "O5" })
		utils.sheet_add_aoa(ws, [header1], { origin: "G6" })
		utils.sheet_add_aoa(ws, [header2], { origin: "G7" })
		utils.sheet_add_aoa(ws, transformedData, { origin: "A8" })

		utils.sheet_add_aoa(ws, [[{ v: "Sub Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 8}` })
		utils.sheet_add_aoa(ws, [subTotalRow], { origin: `F${transformedData.length + 8}` })

		utils.sheet_add_aoa(ws, [[{ v: "Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [[{ v: total.jml_rmh_rusak, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `F${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [[{ v: total.tingkat_kerusakan_rumah, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `G${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [[{ v: total.target, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `J${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [[{ v: total.realisasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `O${transformedData.length + 9}` })



		utils.sheet_add_aoa(ws, [[{ v: "Capaian SPM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 10}` })
		utils.sheet_add_aoa(ws, [[{ v: `${isNaN(total.capaian) ? 0 : total.capaian}%`, t: "", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `O${transformedData.length + 10}` })

		ws['!cols'] = [
			{ wpx: 50 },
			{ wpx: 321 },
			{ wpx: 188 },
			{ wpx: 118 },
			{ wpx: 133 },
			{ wpx: 134 },
			{ wpx: 136 },
			{ wpx: 140 },
			{ wpx: 121 },
			{ wpx: 144 },
			{ wpx: 129 },
			{ wpx: 118 },
			{ wpx: 87 },
			{ wpx: 121 },
			{ wpx: 144 },
			{ wpx: 129 },
			{ wpx: 118 },
			{ wpx: 87 },
		]

		ws['!merges'] = [
			// COLSPANS
			utils.decode_range("A1:S1"),
			utils.decode_range("A2:S2"),
			utils.decode_range("A3:S3"),
			utils.decode_range("A4:S4"),
			utils.decode_range("G5:I5"),
			utils.decode_range("J5:N5"),
			utils.decode_range("O5:S5"),
			utils.decode_range(`A${transformedData.length + 8}:E${transformedData.length + 8}`),
			utils.decode_range(`A${transformedData.length + 9}:E${transformedData.length + 9}`),
			utils.decode_range(`G${transformedData.length + 9}:I${transformedData.length + 9}`),
			utils.decode_range(`J${transformedData.length + 9}:N${transformedData.length + 9}`),
			utils.decode_range(`O${transformedData.length + 9}:S${transformedData.length + 9}`),
			utils.decode_range(`A${transformedData.length + 10}:N${transformedData.length + 10}`),
			utils.decode_range(`O${transformedData.length + 10}:S${transformedData.length + 10}`),

			// ROWSPANS
			utils.decode_range("A5:A7"),
			utils.decode_range("B5:B7"),
			utils.decode_range("C5:C7"),
			utils.decode_range("D5:D7"),
			utils.decode_range("E5:E7"),
			utils.decode_range("F5:F7"),
		]
		utils.book_append_sheet(wb, ws, "Kumulatif Rumah Bencana")
		writeFile(wb, `Data Capaian Kumulatif Rumah Bencana ${nama_provinsi_to_use} - ${nama_kabkota_to_use} - ${tahun}.xlsx`)
	}

	// CheckPenetapanBencana
	const checkPenetapan = () => {
		let penetapanBencanaExists = false;
		for (const page of pages) {
			for (const jenis_bencana of page.data) {
				if (jenis_bencana.jenis_bencana_id === 10) {
					penetapanBencanaExists = true;
					break;
				}
			}
			if (penetapanBencanaExists) {
				break;
			}
		}
		setPenetapanBencana(
			!(pages.length === 0 || penetapanBencanaExists === false),
		);
		console.log(
			`penetapanBencana: ${!!(pages.length === 0 || penetapanBencanaExists === false)}`,
		);
	};

	const pages = data?.pages || [];

	useEffect(() => {
		checkPenetapan();
	}, [pages]);

	useEffect(() => {
		if (!isLoading) {
			const allDataRB = pages.flatMap((page) => page.data)
			setDataRB(allDataRB)
		}
	}, [isLoading, data])

	let rowCounter = 0;

	if (penetapanBencana) return <PenetapanTidakAdaBencana />;

	if (isLoading) return <CompLoader />;

	return (
		<>
			<div className="flex justify-end pb-4">
				{dataRB.length !== 0 &&
					<Button
						className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
						onClick={() => handleDownload()}
					>
						<FiDownload />
						Download Data
					</Button>
				}
			</div>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader isLoading={isLoading} cardRef={cardRef} minusWidth={32}>
						DATA CAPAIAN <br />
						SPM PERUMAHAN RAKYAT <br />
						PENYEDIAAN DAN REHABILITASI RUMAH YANG LAYAK HUNI <br />
						BAGI KORBAN BENCANA KABUPATEN / KOTA {Number(tahun)}
					</JudulHeader>
					<Table className="w-full min-w-[3300px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									rowSpan={3}
									className="thead-text border-l text-center"
								>
									No
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Jenis Bencana
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Tahun Terjadinya Bencana
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Kecamatan
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Kelurahan / Desa
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Jumlah Rumah Rusak
								</TableHead>
								<TableHead
									colSpan={3}
									className="thead-text border-b-0 text-center"
								>
									Tingkat Kerusakan Rumah
								</TableHead>
								<TableHead
									colSpan={5}
									className="thead-text border-b-0 text-center"
								>
									Target
								</TableHead>
								<TableHead
									colSpan={5}
									className="thead-text border-b-0 text-center"
								>
									Realisasi
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-b-0 text-center">
									Rusak Ringan
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Rusak Sedang
								</TableHead>
								<TableHead className="thead-text border-b-0 border-r text-center">
									Rusak Berat
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Rehabilitasi Rumah
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Pembangunan Kembali
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Pemukiman Kembali
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Subsidi Uang Sewa
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Akses Tinggal
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Rehabilitasi Rumah
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Pembangunan Kembali
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Pemukiman Kembali
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Subsidi Uang Sewa
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Akses Tinggal
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
								<TableHead className="thead-text text-center">(Unit)</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{pages.map((page, i) => {
								return (
									<React.Fragment key={i}>
										{page.data.map((item: any, key: number) => {
											rowCounter++;
											return (
												<TableRow
													key={key}
													className={
														rowCounter % 2 === 0
															? 'bg-gray-2 dark:bg-meta-4'
															: 'bg-white dark:bg-boxdark'
													}
												>
													<TableCell className="tbody-text border-b border-l border-r">
														{rowCounter}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.jenis_bencana?.nama_jenis_bencana ?? "-"}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tahun_kejadian}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.wilayah?.nama_kecamatan ?? "-"}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.wilayah?.nama_desa ?? "-"}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.jml_rmh_rusak}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tingkat_rusak_ringan}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tingkat_rusak_sedang}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tingkat_rusak_berat}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_rehabilitasi}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_pembangunan}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_relokasi}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_sewa}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_akses_tinggal}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_rehabilitasi}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_pembangunan}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_relokasi}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_sewa}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_akses_tinggal}
													</TableCell>
												</TableRow>
											);
										})}
									</React.Fragment>
								);
							})}
							{!hasNextPage && (
								<>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={5}
										>
											Sub Total
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.jml_rmh_rusak}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tingkat_rusak_ringan}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tingkat_rusak_sedang}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tingkat_rusak_berat}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_rehabilitasi}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_pembangunan}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_relokasi}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_sewa}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_akses_tinggal}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_rehabilitasi}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_pembangunan}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_relokasi}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_sewa}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_akses_tinggal}
										</TableCell>
									</TableRow>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={5}
										>
											Total
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{total.jml_rmh_rusak}
										</TableCell>
										<TableCell
											className="tfooter-text border-b border-r text-lg"
											colSpan={3}
										>
											{total.tingkat_kerusakan_rumah}
										</TableCell>
										<TableCell
											className="tfooter-text border-b border-r text-lg"
											colSpan={5}
										>
											{total.target}
										</TableCell>
										<TableCell
											className="tfooter-text border-b border-r text-lg"
											colSpan={5}
										>
											{total.realisasi}
										</TableCell>
									</TableRow>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={14}
										>
											Capaian SPM
										</TableCell>
										<TableCell
											className="tfooter-text border-b border-r text-lg"
											colSpan={5}
										>
											{total.capaian}%
										</TableCell>
									</TableRow>
								</>
							)}
						</TableBody>
					</Table>
					<div className="h-1" ref={ref}>
						{isFetchingNextPage && (
							<div className="flex items-center justify-center p-4">
								<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
							</div>
						)}
					</div>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};

export default TableDataCapaianKumulatifRumahBencanaBappedaProv