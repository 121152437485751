import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import {
	useGetNewRencanaPemenuhan,
	useTambahRealisasi,
} from '@/hooks/rencanaPelaksanaan/useRencanaPemenuhan';
import { formatRupiah } from '@/libs/utils';
import { useQueryClient } from '@tanstack/react-query';

const TablePelaksanaan = ({
	setShowForm,
	setEditableId,
}: {
	setShowForm: any;
	setEditableId: any;
}) => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const bidang = useSelector((state: RootState) => state.bidang.value);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const kd_kabkota_dux = useSelector(
		(state: RootState) => state.location.kd_kabkota_dux,
	);
	const kd_provinsi_dux = useSelector(
		(state: RootState) => state.location.kd_provinsi_dux,
	);
	const menu_id = useSelector((state: RootState) => state.menuId.value);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const wilayah = useSelector((state: RootState) => state.wilayah.value);
	const queryClient = useQueryClient();

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [isConfirmDelete, setIsConfirmDelete] = useState(false);
	const [uploadData, setUploadData] = useState(false);
	const [rpp_id, setRPP_ID] = useState(0);

	const kd_kabkota_to_use =
		privilege === 4 || privilege === 10 || privilege === 8
			? kd_kabkota_dux
			: kd_kabkota;
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;

	const {
		getRencanaPemenuhan,
		isLoading: loadRencanaPemenuhan,
		refetch,
	} = useGetNewRencanaPemenuhan(
		Number(tahun),
		bidang,
		kd_provinsi_to_use,
		kd_kabkota_to_use,
		menu_id,
		wilayah,
	);
	const { asyncTambahRealisasi } = useTambahRealisasi();

	useEffect(() => {
		refetch();
	}, [kd_kabkota_to_use, bidang, refetch]);

	let rowCounter = 0;
	const cardRef = useRef(null);

	if (loadRencanaPemenuhan || uploadData) {
		return <CompLoader />;
	}

	// console.log(rowCounter);

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				{bidang === 'air-minum' && (
					<JudulHeader
						isLoading={loadRencanaPemenuhan}
						cardRef={cardRef}
						minusWidth={32}
					>
						SPM PEKERJAAN UMUM <br />
						{wilayah === 'kabupaten'
							? 'RENCANA PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI-HARI'
							: 'PEMENUHAN KEBUTUHAN AIR MINUM CURAH LINTAS KABUPATEN/KOTA'}
					</JudulHeader>
				)}
				{bidang === 'air-limbah' && (
					<JudulHeader
						isLoading={loadRencanaPemenuhan}
						cardRef={cardRef}
						minusWidth={32}
					>
						SPM PEKERJAAN UMUM <br />
						{wilayah === 'kabupaten'
							? 'PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK'
							: 'PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK REGIONAL LINTAS KABUPATEN/KOTA'}
					</JudulHeader>
				)}
				{bidang === 'rumah-bencana' && (
					<JudulHeader
						isLoading={loadRencanaPemenuhan}
						cardRef={cardRef}
						minusWidth={32}
					>
						SPM PERUMAHAN RAKYAT <br />
						{wilayah === 'kabupaten'
							? 'PENYEDIAAN DAN REHABILITASI RUMAH YANG LAYAK HUNI BAGI KORBAN BENCANA KABUPATEN/KOTA '
							: 'PENYEDIAAN DAN REHABILITASI RUMAH YANG LAYAK HUNI BAGI KORBAN BENCANA'}
					</JudulHeader>
				)}
				{bidang === 'rumah-relokasi' && (
					<JudulHeader
						isLoading={loadRencanaPemenuhan}
						cardRef={cardRef}
						minusWidth={32}
					>
						SPM PERUMAHAN RAKYAT <br />
						{wilayah === 'kabupaten'
							? 'FASILITASI PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA RELOKASI PROGRAM PEMERINTAH DAERAH KABUPATEN/KOTA'
							: 'FASILITASI PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA RELOKASI PROGRAM PEMERINTAH DAERAH PROVINSI'}
					</JudulHeader>
				)}
				<Table className="w-full min-w-[2000px] table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead
								rowSpan={2}
								className="thead-text border-l text-center"
							>
								No
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Nomenklatur Urusan Kabupaten / Kota
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Jenis Kegiatan
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Anggaran
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Sumber Anggaran
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Nama Kegiatan
							</TableHead>
							<TableHead
								colSpan={wilayah === 'kabupaten' ? 2 : 3}
								className="thead-text border-b-0 text-center"
							>
								Lokasi
							</TableHead>
							<TableHead
								colSpan={4}
								className="thead-text border-b-0 text-center"
							>
								Output
							</TableHead>
							{privilege !== 3 &&
								privilege !== 4 &&
								privilege !== 8 &&
								privilege !== 10 && (
									<TableHead rowSpan={2} className="thead-text text-center">
										Aksi
									</TableHead>
								)}
						</TableRow>
						<TableRow>
							{wilayah === 'provinsi' && (
								<TableHead className="thead-text text-center">
									Kabupaten/Kota
								</TableHead>
							)}
							<TableHead className="thead-text text-center">
								Kecamatan
							</TableHead>
							<TableHead className="thead-text text-center">
								Kelurahan
							</TableHead>
							<TableHead className="thead-text text-center">Komponen</TableHead>
							<TableHead className="thead-text text-center">Satuan</TableHead>
							<TableHead className="thead-text text-center">Volume</TableHead>
							<TableHead className="thead-text text-center">
								Realisasi
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{getRencanaPemenuhan
							.sort(
								(a: any, b: any) =>
									new Date(b.created_at).getTime() -
									new Date(a.created_at).getTime(),
							)
							.map((item: any, key: any) => {
								rowCounter++;
								const hasNonZeroRealisasi = item.rencana_komponen.some(
									(komponen: any) => komponen.realisasi !== '0.00',
								);
								return (
									<TableRow
										key={key}
										className={
											rowCounter % 2 === 0
												? 'bg-white dark:bg-boxdark'
												: 'bg-gray-2 dark:bg-meta-4'
										}
									>
										<TableCell className="tbody-text border-b border-l border-r">
											{rowCounter}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{item.nomenklatur.nama_nomenklatur}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{item.nomenklatur.jenis_kegiatan}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{formatRupiah(item.rencana_anggaran)}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{item.sumber_anggaran.sumber_anggaran}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{item.nama_kegiatan}
										</TableCell>
										{wilayah === 'provinsi' && (
											<TableCell className="tbody-text border-b border-r">
												{item.wilayah.nama_kabkota}
											</TableCell>
										)}
										<TableCell className="tbody-text border-b border-r">
											{item.wilayah.nama_kecamatan}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{item.wilayah.nama_desa}
										</TableCell>
										<TableCell
											style={{ padding: 0 }}
											className="border-b border-r"
										>
											<table className="w-full">
												<tbody className="w-full">
													{item.rencana_komponen.length !== 0 ? (
														item.rencana_komponen.map(
															(item: any, index: any) => (
																<TableRow
																	key={index}
																	className="flex items-center justify-center"
																>
																	<TableCell
																		key={index}
																		className="tbody-text flex items-center justify-center"
																	>
																		{item.komponen.komponen_name}
																	</TableCell>
																</TableRow>
															),
														)
													) : (
														<TableRow className="flex items-center justify-center">
															<TableCell className="flex items-center justify-center">
																-
															</TableCell>
														</TableRow>
													)}
												</tbody>
											</table>
										</TableCell>
										<TableCell
											style={{ padding: 0 }}
											className="border-b border-r"
										>
											<table className="w-full">
												<tbody className="w-full">
													{item.rencana_komponen.length !== 0 ? (
														item.rencana_komponen.map(
															(item: any, index: any) => (
																<TableRow
																	key={index}
																	className="flex flex-col items-center justify-center"
																>
																	<TableCell
																		key={index}
																		className="tbody-text flex items-center justify-center"
																	>
																		{item.satuan?.satuan_name ?? '-'}
																	</TableCell>
																</TableRow>
															),
														)
													) : (
														<TableRow className="flex flex-col items-center justify-center">
															<TableCell className="flex items-center justify-center">
																-
															</TableCell>
														</TableRow>
													)}
												</tbody>
											</table>
										</TableCell>
										<TableCell
											style={{ padding: 0 }}
											className="border-b border-r"
										>
											<table className="w-full">
												<tbody className="w-full">
													{item.rencana_komponen.length !== 0 ? (
														item.rencana_komponen.map(
															(item: any, index: any) => (
																<TableRow
																	key={index}
																	className="flex items-center justify-center"
																>
																	<TableCell
																		key={index}
																		className="tbody-text flex items-center justify-center"
																	>
																		{item.volume}
																	</TableCell>
																</TableRow>
															),
														)
													) : (
														<TableRow className="flex items-center justify-center">
															<TableCell className="flex items-center justify-center">
																-
															</TableCell>
														</TableRow>
													)}
												</tbody>
											</table>
										</TableCell>
										<TableCell
											style={{ padding: 0 }}
											className="border-b border-r"
										>
											<table className="w-full">
												<tbody className="w-full">
													{item.rencana_komponen.length !== 0 ? (
														item.rencana_komponen.map(
															(item: any, index: any) => (
																<TableRow
																	key={index}
																	className="flex flex-col items-center justify-center"
																>
																	<TableCell
																		key={index}
																		className="tbody-text flex items-center justify-center"
																	>
																		{item.realisasi}
																	</TableCell>
																</TableRow>
															),
														)
													) : (
														<TableRow className="flex flex-col items-center justify-center">
															<TableCell className="flex items-center justify-center">
																-
															</TableCell>
														</TableRow>
													)}
												</tbody>
											</table>
										</TableCell>
										{Number(item.verifikasi) !== 1 ? (
											privilege !== 3 &&
											privilege !== 4 &&
											privilege !== 8 &&
											privilege !== 10 && (
												<TableCell className="tbody-text border-b border-r">
													{item.rencana_komponen.length !== 0 ? (
														hasNonZeroRealisasi ? (
															<Button
																size={'sm'}
																className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600"
																onClick={() => {
																	setIsConfirmDelete(true);
																	setRPP_ID(item.id_rpp);
																}}
															>
																Hapus Realisasi
															</Button>
														) : (
															<Button
																size={'sm'}
																className="inline-flex items-center justify-start gap-1 rounded-lg bg-green-500 px-4 py-2 text-center font-medium text-white hover:bg-green-600"
																onClick={() => {
																	setShowForm('Tambah Realisasi');
																	setEditableId(item.id_rpp);
																}}
															>
																Tambah Realisasi
															</Button>
														)
													) : (
														<table className="w-full">
															<tbody className="w-full">
																<TableRow className="flex flex-col items-center justify-center">
																	<TableCell className="flex items-center justify-center">
																		-
																	</TableCell>
																</TableRow>
															</tbody>
														</table>
													)}
												</TableCell>
											)
										) : (
											<TableCell className="border-b border-r">
												<table className="w-full">
													<tbody className="w-full">
														<TableRow className="flex flex-col items-center justify-center">
															<TableCell className="flex items-center justify-center">
																-
															</TableCell>
														</TableRow>
													</tbody>
												</table>
											</TableCell>
										)}
										<ConfirmationDialog
											isDialogOpen={isConfirmDelete}
											setIsDialogOpen={setIsConfirmDelete}
											confirmAction={() => {
												const rpp = getRencanaPemenuhan.find(
													(item: any) => item.id_rpp === rpp_id,
												);
												const handleDelete = async () => {
													const tambahRealisasi = rpp.rencana_komponen.map(
														(item: any) => ({
															id_rencana_komponen: item.id_rencana_komponen,
															realisasi: 0,
														}),
													);
													const tambahRealisasiPromise = tambahRealisasi.map(
														(item: any) => asyncTambahRealisasi(item),
													);
													setUploadData(true);
													try {
														await Promise.all(tambahRealisasiPromise);
														toast.success('Berhasil menghapus realisasi!');
													} catch (error) {
														toast.error('Gagal menghapus realisasi!');
													} finally {
														setUploadData(false);
														queryClient.invalidateQueries({
															queryKey: ['rencana-pemenuhan'],
														});
														setIsConfirmDelete(false);
													}
												};
												handleDelete();
											}}
											title="Tunggu Dulu"
											description="Apakah Anda yakin mau menghapus Realisasi?"
											confirmButtonColor="bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-400 dark:hover:bg-blue-600"
										/>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
			<ConfirmationDialog
				isDialogOpen={isConfirmOpen}
				setIsDialogOpen={setIsConfirmOpen}
				confirmAction={() => setShowForm('Tambah Realisasi')}
				title="Tunggu Dulu"
				description="Apakah Anda yakin ingin menambahkan Realisasi?"
				confirmButtonColor="bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-400 dark:hover:bg-blue-600"
			/>
		</div>
	);
};

export default TablePelaksanaan;
