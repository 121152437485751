import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { API } from '../../libs/API';
import { AxiosResponse } from 'axios';

// Definisikan tipe data untuk email
interface EmailData {
	username: string;
}

// Fungsi untuk melakukan post email dan mengembalikan data
const postEmailForOtp = async (emailData: EmailData): Promise<any> => {
	try {
		const data: AxiosResponse = await API.post('/send-otp', emailData);
		// console.log(data);
		return data;
	} catch (error) {
		// console.error('Error in postEmailForOtp:', error);
		// Tangani kesalahan di sini
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message);
		} else {
			throw new Error('Gagal mengirim OTP via email.');
		}
		return null;
	}
};

// Hook untuk menggunakan mutasi post email untuk OTP
export const usePostEmailForOtp = (): UseMutationResult<
	any,
	unknown,
	EmailData,
	unknown
> => {
	// Gunakan useMutation langsung dengan argumen yang disederhanakan
	return useMutation({
		mutationFn: postEmailForOtp,
		onError: (error: any) => {
			// console.error(error.message);
			console.error('Error:', error);
		},
		onSuccess: (data) => {
			// Lakukan sesuatu dengan respons dari server, misalnya menyimpan token
			// Capture the token from the header
			const token = data.headers['authorization'].split(' ')[1];
			// console.log(token);
			if (token) {
				localStorage.setItem('reset-password', token);
			}
		},
	});
};
