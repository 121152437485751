import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { FormEvent, useEffect, useState } from 'react';
import DesaSelectUI from '@/components/ui/SelectDesaUI';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useQueryClient } from '@tanstack/react-query';
import { usePostPerumahanKawasanKumuh } from '@/hooks/perumahan/useRumahRelokasi';
import KabKotaSelectUI from '@/components/ui/SelectKabKotaUI';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';


const FIdentifikasiPerumahanKumuh = () => {
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kec, setKd_kec] = useState('0');
	const [kd_desa, setKd_desa] = useState('0');
	const [kd_kabkota, setKd_kabkota] = useState(user.kd_kabkota.toString());

	//Get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
	const { getKecamatan } = useGetKecamatan(
		user.kd_provinsi,
		parseInt(kd_kabkota),
	);
	const { getDesa, refetch: fetchDesa } = useGetDesa(
		user.kd_provinsi,
		parseInt(kd_kabkota),
		parseInt(kd_kec),
	);
	const { postPerumahanKawasanKumuh } = usePostPerumahanKawasanKumuh();

	//formData
	const [luasLahan, setLuasLahan] = useState('');
	const [jumlahRumah, setJumlahRumah] = useState('');
	const [jumlahKK, setJumlahKK] = useState('');
	const [tingkatKekumuhan, setTingkatKekumuhan] = useState('');
	const [keterangan, setKeterangan] = useState('');

	const tingkatKekumuhanData: { [key: string]: number } = {
		Ringan: 1,
		Sedang: 2,
		Berat: 3,
	};

	//additional
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	//ResetForm
	const resetForm = () => {
		setLuasLahan('');
		setJumlahRumah('');
		setJumlahKK('');
		setTingkatKekumuhan('');
		setKeterangan('');
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		const data = {
			tahun_pelaporan: parseInt(tahun),
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: parseInt(kd_kabkota),
			kd_kec: parseInt(kd_kec),
			kd_desa: parseInt(kd_desa),
			luas_lahan: luasLahan,
			jml_rumah: parseInt(jumlahRumah),
			jml_kk: parseInt(jumlahKK),
			tingkat_kumuh_id: tingkatKekumuhanData[tingkatKekumuhan],
			keterangan: keterangan,
		};
		postPerumahanKawasanKumuh(data, {
			onSuccess: () => {
				setModalOpen(false);
				queryClient.refetchQueries({
					queryKey: ['identifikasi-perumahan-kumuh'],
				});
				resetForm();
			},
			onError: (error) => {
				console.log(error);
			},
		});

		console.log(data);
	};

	useEffect(() => {
		fetchDesa();
	}, [kd_kec]);

	console.log(tahun);
	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
						<FiPlusCircle />
						Input Data
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 mx-auto max-w-screen-xl">
					<DialogHeader>
						<DialogTitle>Tambah Identifikasi Perumahan Kumuh</DialogTitle>
						<DialogDescription className="my-1">
							Jika Belum Memiliki Data, Silahkan Isi Dengan Angka 0 Agar Bisa Di
							Simpan
							<br />
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>
					<form action="post" onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							{menuId === 8 ? (
								<>
									<div className="flex flex-col">
										<Label htmlFor="Kabupaten/Kota" className="mb-2">
											Kabupaten/Kota
										</Label>
										<KabKotaSelectUI
											getKabKota={getKabKota}
											setKabKota={setKd_kabkota}
										/>
									</div>
									<div className="flex flex-col">
										<Label htmlFor="Kecamatan" className="mb-2">
											Kecamatan
										</Label>
										<KecamatanSelectUI
											getKecamatan={getKecamatan}
											setKd_kec={setKd_kec}
										/>
									</div>
								</>
							) : (
								<>
									<div className="flex flex-col">
										<Label htmlFor="Kecamatan" className="mb-2">
											Kecamatan
										</Label>
										<KecamatanSelectUI
											getKecamatan={getKecamatan}
											setKd_kec={setKd_kec}
										/>
									</div>
									<div className="flex flex-col">
										<Label htmlFor="Kelurahan/Desa" className="mb-2">
											Kelurahan/Desa
										</Label>
										<DesaSelectUI getDesa={getDesa} setKd_desa={setKd_desa} />
									</div>
								</>
							)}

							<div className="flex flex-col">
								<Label htmlFor="Luas Lahan" className="mb-2">
									Luas Lahan (Ha)
								</Label>
								<Input
									id="Luas Lahan"
									type="number"
									className="col-span-1"
									value={luasLahan}
									onChange={(e) => setLuasLahan(e.target.value)}
									placeholder="Masukkan Luas Lahan"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah" className="mb-2">
									Jumlah Rumah (Unit)
								</Label>
								<Input
									id="Jumlah Rumah"
									type="number"
									className="col-span-1"
									value={jumlahRumah}
									onChange={(e) => setJumlahRumah(e.target.value)}
									placeholder="Masukkan Jumlah Rumah"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Jumlah KK" className="mb-2">
									Jumlah KK
								</Label>
								<Input
									id="Jumlah KK"
									type="number"
									className="col-span-1"
									value={jumlahKK}
									onChange={(e) => setJumlahKK(e.target.value)}
									placeholder="Masukkan Jumlah KK"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Tingkat Kekumuhan" className="mb-2">
									Tingkat Kekumuhan
								</Label>
								<Select onValueChange={(value) => setTingkatKekumuhan(value)}>
									<SelectTrigger>
										<SelectValue placeholder="Pilih Tingkat Kekumuhan" />
									</SelectTrigger>
									<SelectContent className="z-99999">
										<SelectItem value="Ringan">Ringan</SelectItem>
										<SelectItem value="Sedang">Sedang</SelectItem>
										<SelectItem value="Berat">Berat</SelectItem>
									</SelectContent>
								</Select>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Keterangan" className="mb-2">
									Keterangan
								</Label>
								<Input
									id="Keterangan"
									type="text"
									className="col-span-1"
									value={keterangan}
									onChange={(e) => setKeterangan(e.target.value)}
									placeholder="Masukkan Keterangan"
								/>
							</div>
						</div>
						<DialogFooter>
							<div className="mt-5 flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default FIdentifikasiPerumahanKumuh;

