import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import { Button } from '@/components/ui/Button';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
	useDeletePenetapanRelokasi,
	useGetDataCapaianPenetapanRelokasi,
	usePostPenetapanRelokasi,
} from '@/hooks/perumahan/useRumahRelokasi';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, FormEvent, useRef, useState, useEffect } from 'react';
import {
	FiAlertCircle,
	FiCheckCircle,
	FiTrash,
	FiUpload,
} from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import TBerkasRelokasi from './TBerkasRumahRelokasi';
import { useGetFile, usePostFile } from '@/hooks/perumahan/useRumahBencana';
import { usePrivilegeRumahRelokasi } from '@/hooks/privilege/usePrivilege';
import PenetapanTidakAdaRelokasi from '../DataCapaian/Kumulatif/PenetapanTidakAdaRelokasi';

type Props = {
	dokumenVerifikasi?: any;
	setDokumenVerifikasi?: any;
	adaRelokasi?: any;
	setAdaRelokasi?: any;
	setActiveTab?: any;
	penetapanRelokasi?: any;
	setPenetapanRelokasi?: any;
};

const PenetapanRelokasi = ({
	adaRelokasi,
	setAdaRelokasi,
	setActiveTab,
	penetapanRelokasi,
	setPenetapanRelokasi,
}: Props) => {
	const inputDokumenRef = useRef<HTMLInputElement>(null);
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const [rowData, setRowData] = useState<any>(null);
	const [loading, setLoading] = useState(false);
	const [adaBerkas, setAdaBerkas] = useState(false);
	const [uploadDokumen, setUploadDokumen] = useState<File | null>(null);
	const jenis_up_id = 4;
	const [simpanButton, setSimpanButton] = useState(true);

	const [terdampak, setTerdampak] = useState<any>(false);
	const [sk_Relokasi, setSK_Relokasi] = useState<any>(false);
	const [uploadSK, setUploadSK] = useState<any>(false);
	const [showUploadSKRelokasi, setShowUploadSKRelokasi] = useState<any>(false);
	const [idPenetapanRelokasi, setIdPenetapanRelokasi] = useState('0');

	const handleSimpan = () => {
		if (terdampak && sk_Relokasi && uploadSK) {
			setShowUploadSKRelokasi(true);
		}

		if (!terdampak || !sk_Relokasi || !uploadSK) {
			handleSubmitPenetapanRelokasi();
		}
	};

	//privilege
	const {
		isPrevilegeViewRumahRelokasi,
		kd_kabkota_to_use,
		kd_provinsi_to_use,
	} = usePrivilegeRumahRelokasi();
	const { postFile } = usePostFile();


	//additonalsUstate
	const cardRef = useRef(null);
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState(false);

	//getPenetapanRelokasi

	//getfile
	const { data: dataBerkas, isLoading: loadingBerkas } = useGetFile(
		kd_provinsi_to_use,
		kd_kabkota_to_use,
		Number(tahun),
		menuId,
		jenis_up_id,
	);

	//data
	const pagesBerkas = dataBerkas?.pages || [];

	//post & delete
	const { postPenetapanRelokasi } = usePostPenetapanRelokasi();
	const { deletePenetapanRelokasi } = useDeletePenetapanRelokasi();

	const { data: dataPenetapanRelokasi, isLoading: loadPenetapanRelokasi } =
		useGetDataCapaianPenetapanRelokasi(
			menuId,
			user.kd_provinsi,
			user.kd_kabkota,
			Number(tahun),
		);

	//kondisi mencari berkas
	const findBerkas = () => {
		const hasBerkas = pagesBerkas.some((page) => page.data.length > 0);
		setAdaBerkas(hasBerkas);
		// console.log('ada berkas?', hasBerkas);
		// console.log('penetapanRelokasi', penetapanRelokasi);
	};

	const handleOpenDokumen = () => {
		if (inputDokumenRef.current) {
			inputDokumenRef.current.click();
		}
	};

	const handleDeleteDokumen = () => {
		setUploadDokumen(null);
		inputDokumenRef.current!.value = '';
	};

	const handleOnChangeOpenDokumen = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			// Check file size and type
			if (file.size > 50 * 1024 * 1024) {
				alert('File size must be less than 50MB');
				return;
			}
			if (
				file.type !== 'application/pdf' &&
				file.type !==
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			) {
				alert('File must be a PDF or Excel');
				return;
			}
			setUploadDokumen(file);
			e.target.value = '';
		}
	};

	//post penetapan relokasi
	const handleSubmitPenetapanRelokasi = () => {
		setLoading(true);

		const data = {
			menu_id: Number(menuId),
			kd_provinsi: Number(user.kd_provinsi),
			kd_kabkota: Number(user.kd_kabkota),
			tahun_pelaporan: Number(tahun),
		};

		// Post
		postPenetapanRelokasi(data, {
			onSuccess: () => {
				console.log('Data submitted successfully:', data);
				queryClient.invalidateQueries({ queryKey: ['eksis-relokasi'] });
				setLoading(false);
				setActiveTab('IdentifikasiPerumahan');
				setPenetapanRelokasi(true);
			},
			onError: (error) => {
				console.error('Error submitting data:', error);
				setLoading(false); // Set loading to false
			},
		});
	};

	//upload sk penetapan
	const handleUploadFileSKRelokasi = (event: FormEvent) => {
		event.preventDefault();

		if (!uploadDokumen) {
			alert('Please select a file');
			return;
		}

		setIsLoading(true); // Set isLoading to true

		const data = {
			tahun_upload: tahun,
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: user.kd_kabkota,
			jenis_up_id: jenis_up_id,
			file_upload: uploadDokumen,
		};

		postFile(data, {
			onSuccess: () => {
				console.log('File uploaded successfully');
				// setShowUploadButton(false);
				queryClient.invalidateQueries({ queryKey: ['get-file-bnba'] });
				setIsLoading(false);
				setAdaBerkas(true);
			},
			onError: (error) => {
				console.log(error);
				setIsLoading(false);
			},
		});
	};

	const handleBatalkanPenetapanRelokasi = async () => {
		setLoading(true);
		try {
			await deletePenetapanRelokasi({
				id_eksis_relokasi: idPenetapanRelokasi,
			});
			queryClient.refetchQueries({ queryKey: ['eksis-rawan-bencana'] });
			toast.success('Berhasil melakukan penetapan bencana');
			queryClient.invalidateQueries({ queryKey: ['eksis-rawan-bencana'] });
			setLoading(false);
			setPenetapanRelokasi(false);
			setActiveTab('IdentifikasiPerumahan');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal melakukan penetapan bencana');
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!loadPenetapanRelokasi) {
			const data = dataPenetapanRelokasi!.pages.flatMap((page) => page.data);
			setIdPenetapanRelokasi(data[0]?.id_eksis_relokasi ?? '0');
		}
	}, [loadPenetapanRelokasi, penetapanRelokasi]);

	useEffect(() => {
		if (!loadingBerkas) {
			findBerkas();
		}
	}, [loadingBerkas, dataBerkas]);

	if (loading) return <CompLoader />;

	if (isPrevilegeViewRumahRelokasi) {
		return (
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea>
					{adaBerkas ? (
						<>
							<Label className="flex items-center justify-center text-2xl font-semibold text-[#364878] dark:text-white">
								Dokumen Dibawah Ini Merupakan SK Penetapan Relokasi
							</Label>
							<TBerkasRelokasi jenis_up_id={jenis_up_id} />
						</>
					) : !penetapanRelokasi ? (
						<>
							<Label
								className="flex items-center justify-center text-center text-2xl font-semibold text-[#364878] dark:text-white"
								htmlFor="dokumen-verifikasi"
							>
								Belum Ada Penetapan Relokasi <br /> Pada Tahun {Number(tahun)}
							</Label>
						</>
					) : (
						<>
							<PenetapanTidakAdaRelokasi />
						</>
					)}
				</ScrollArea>
			</div>
		);
	}

	if (penetapanRelokasi) {
		return (
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea>
					{!isPrevilegeViewRumahRelokasi && (
						<>
							<div className="flex flex-col items-center justify-center gap-10">
								<Label
									className="flex items-center justify-center text-2xl font-semibold text-[#364878] dark:text-white"
									htmlFor="dokumen-verifikasi"
								>
									Tidak ada relokasi pada tahun {Number(tahun)}
								</Label>
								<Button
									className="flex items-center justify-center bg-red-500 text-white dark:text-white"
									onClick={handleBatalkanPenetapanRelokasi}
								>
									Batalkan Penetapan
								</Button>
							</div>
						</>
					)}
				</ScrollArea>
			</div>
		);
	}

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea>
				<div className="flex items-center justify-start text-center">
					<div className="inline-flex w-full items-center gap-2 rounded-md bg-slate-50 p-2 text-start font-bold">
						<div className="mx-4 flex items-center gap-2">
							<FiAlertCircle className="text-3xl text-red-500" /> Catatan
						</div>
						YA : Jika ada relokasi pada tahun {Number(tahun) - 5} - {tahun} maka
						Diwajibkan untuk melakukan Upload SK Penetapan relokasi
						<br />
						Tidak : Jika tidak ada relokasi pada tahun {Number(tahun) -
							5} - {tahun} maka Capaian Pemenuhan SPM Menjadi 100%
					</div>
				</div>

				{/* Upload SK Relokasi */}
				{adaBerkas ? (
					<>
						<>
							<Label className="flex items-center justify-center text-2xl font-semibold text-[#364878] dark:text-white">
								Dokumen Dibawah Ini Merupakan SK Penetapan Relokasi
							</Label>
							<TBerkasRelokasi jenis_up_id={jenis_up_id} />
						</>
					</>
				) : (
					<>
						{showUploadSKRelokasi ? (
							<div className="mt-15 flex flex-col items-center justify-center gap-8">
								<Label
									className="text-2xl font-semibold text-[#364878] dark:text-white"
									htmlFor="dokumen-verifikasi"
								>
									Upload Dokumen SK Penetapan Relokasi
								</Label>
								<div className="flex items-center gap-6">
									{uploadDokumen ? (
										<>
											<FiCheckCircle className="text-2xl text-green-500" />
											<span className="text-xl font-medium text-black">
												{uploadDokumen.name}
											</span>
											<FiTrash
												className="cursor-pointer text-2xl text-red-500"
												onClick={handleDeleteDokumen}
											/>
											<br />
											<Button
												className="inline-flex h-14 w-30 items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center text-lg font-medium text-white hover:bg-opacity-90"
												onClick={handleUploadFileSKRelokasi}
												disabled={isLoading}
											>
												<FiUpload className="text-2xl text-white" /> Upload
											</Button>
										</>
									) : (
										<Button
											className="inline-flex h-14 w-30 items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center text-lg font-medium text-white hover:bg-opacity-90"
											onClick={handleOpenDokumen}
										>
											<FiUpload className="text-2xl text-white" /> Upload
										</Button>
									)}
								</div>
								<input
									ref={inputDokumenRef}
									onChange={handleOnChangeOpenDokumen}
									type="file"
									className="hidden"
									accept=".pdf, .xlsx"
								/>
								<div className="">
									<p className="text-center text-sm font-black text-red-500">
										*Maksimal 50 MB
									</p>
									<p className="text-center text-sm font-black text-red-500">
										*Harus Berekstensi .pdf atau .xlsx (File PDF atau Excel)
									</p>
									<div className="my-10 flex justify-center gap-10">
										{!uploadDokumen && (
											<Button
												className="w-[20%] bg-red-500 text-white hover:bg-red-700"
												onClick={() => setShowUploadSKRelokasi(false)}
											>
												Batal
											</Button>
										)}
									</div>
								</div>
							</div>
						) : (
							<>
								{/* Quetions-1 */}
								<h1 className="py-5 text-center text-3xl font-semibold text-[#364878] dark:text-white">
									APAKAH ADA RELOKASI DARI TAHUN {Number(tahun) - 5} - {tahun}?
								</h1>

								<div className="my-5 flex justify-center gap-10">
									<Button
										className={`${terdampak ? 'bg-blue-800' : 'bg-green-500'} w-[70px] text-white hover:bg-green-700`}
										onClick={() => setTerdampak(true)}
									>
										YA
									</Button>
									<Button
										className={`${!terdampak ? 'bg-blue-800' : 'bg-red-500'} w-[70px] text-white hover:bg-red-700`}
										onClick={() => setTerdampak(false)}
									>
										Tidak
									</Button>
								</div>

								{/* Quetions-2 */}
								{terdampak && (
									<>
										<h1 className="py-5 text-center text-3xl font-semibold text-[#364878] dark:text-white">
											APAKAH ADA RUMAH TERDAMPAK RELOKASI?
										</h1>
										<div className="my-5 flex justify-center gap-10">
											<Button
												className={`${sk_Relokasi ? 'bg-blue-800' : 'bg-green-500'} w-[70px] text-white hover:bg-green-700`}
												onClick={() => setSK_Relokasi(true)}
											>
												YA
											</Button>
											<Button
												className={`${!sk_Relokasi ? 'bg-blue-800' : 'bg-red-500'} w-[70px] text-white hover:bg-red-700`}
												onClick={() => setSK_Relokasi(false)}
											>
												Tidak
											</Button>
										</div>
									</>
								)}

								{/* Quetions-3 */}
								{sk_Relokasi && terdampak && (
									<>
										<h1 className="pt-5 text-center text-3xl font-semibold text-[#364878] dark:text-white">
											APAKAH ADA SK RELOKASI?
										</h1>
										<div className="flex justify-center gap-10 text-center">
											* SK Relokasi yang dimaksud merupakan <br /> SK Relokasi
											yang dikeluarkan oleh Kepala Daerah setempat.
										</div>
										<div className="my-5 flex justify-center gap-10">
											<Button
												className={`${uploadSK ? 'bg-blue-800' : 'bg-green-500'} w-[70px] text-white hover:bg-green-700`}
												onClick={() => setUploadSK(true)}
											>
												YA
											</Button>
											<Button
												className={`${!uploadSK ? 'bg-blue-800' : 'bg-red-500'} w-[70px] text-white hover:bg-red-700`}
												onClick={() => setUploadSK(false)}
											>
												Tidak
											</Button>
										</div>
									</>
								)}

								{/* simpan button */}
								<div className="my-10 flex justify-center gap-10">
									<Button
										className="w-[10%] bg-blue-500 text-white hover:bg-blue-700"
										onClick={handleSimpan}
									>
										Simpan
									</Button>
								</div>
							</>
						)}
					</>
				)}
			</ScrollArea>
		</div>
	);
};

export default PenetapanRelokasi;
