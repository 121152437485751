import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setTahun } from '../../app/tahun/tahunSlice';
import { useEffect } from 'react';

const TahunSelection = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!localStorage.getItem('selectedTahun')) {
			dispatch(setTahun('2024'));
		}
	}, [dispatch]);

	return (
		<div className="mt-4 flex flex-grow justify-end rounded text-lg font-bold text-[#364878] md:mt-0">
			<span className="my-auto mr-1 align-bottom text-lg font-bold dark:text-white">
				TAHUN:
			</span>
			<select
				className=" rounded-md bg-slate-200 p-2 pl-2 text-[#364878] shadow-default hover:bg-slate-300 dark:bg-boxdark-2 dark:text-white"
				value={tahun}
				onChange={(e) => dispatch(setTahun(e.target.value))}
			>
				<option value="2021">2021</option>
				<option value="2022">2022</option>
				<option value="2023">2023</option>
				<option value="2024">2024</option>
			</select>
		</div>
	);
};
export default TahunSelection;
