const FormDataDasarAM = () => {
	return (
		<div>
			<div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
				<div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
					<h3 className="font-medium text-[#364878] text-2xl dark:text-white">
						Data Dasar
					</h3>
				</div>
				<div className="flex flex-col gap-5.5 p-6.5 ">
					<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
						<div className="flex items-end">
							<label className="mb-3 block font-medium text-slate dark:text-white">
								Tahun
							</label>
						</div>
						<div className="flex justify-end">
							<span className="text-right font-bold">-</span>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
						<div className="flex items-end">
							<label className="mb-3 block font-medium text-slate dark:text-white">
								Paket Pekerjaan
							</label>
						</div>
						<div className="flex justify-end">
							<span className="text-right font-bold">-</span>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
						<div className="flex items-end">
							<label className="mb-3 block font-medium text-slate dark:text-white">
								Rincian Pekerjaan
							</label>
						</div>
						<div className="flex justify-end">
							<span className="text-right font-bold">-</span>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5 ">
						<div className="flex items-end">
							<label className="mb-3 block font-medium text-slate dark:text-white">
								Kecamatan
							</label>
						</div>
						<div className="flex justify-end">
							<span className="text-right font-bold">-</span>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
						<div className="flex items-end">
							<label className="mb-3 block font-medium text-slate dark:text-white">
								Kelurahan / Desa
							</label>
						</div>
						<div className="flex justify-end">
							<span className="text-right font-bold">-</span>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
						<div className="flex items-end">
							<label className="mb-3 block font-medium text-slate dark:text-white">
								Detail
							</label>
						</div>
						<div className="flex justify-end">
							<span className="text-right font-bold">-</span>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
						<div className="flex items-end">
							<label className="mb-3 block font-medium text-slate dark:text-white">
								Anggaran
							</label>
						</div>
						<div className="flex justify-end">
							<span className="text-right font-bold">-</span>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
						<div className="flex items-end">
							<label className="mb-3 block font-medium text-slate dark:text-white">
								Sumber Anggaran
							</label>
						</div>
						<div className="flex justify-end">
							<span className="text-right font-bold">-</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default FormDataDasarAM;
