import { useKomponenNomen } from "@/hooks/useKomponen";

export default function KomponenSelect({ nomenklatur_id, kumpulanKomponen, setKumpulanKomponen, index, disabled }: any) {
  const { getKomponenNomen, isLoading: loadKomponen } = useKomponenNomen(nomenklatur_id);

  if (loadKomponen) return <></>

  return (
    <select
      className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
      disabled={disabled}
      defaultValue={kumpulanKomponen.komponen_id}
      onChange={(e) => {
        const newKomponenId = Number(e.target.value);
        setKumpulanKomponen((prevState: any) => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            komponen_id: newKomponenId
          };
          return newState;
        });
      }}
    >
      <option value="0" disabled hidden>
        Pilih Komponen
      </option>
      {!loadKomponen && getKomponenNomen.map((komponen: any, key: any) => (
        <option key={key} value={komponen.id_komponen}>
          {komponen.komponen_name}
        </option>
      ))}
    </select>
  )
}