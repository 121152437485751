import { RootState } from "@/app/store"
import { Button } from "@/components/ui/Button"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useMenuRumahRelokasi } from '@/hooks/menu/useMenu';
import {
	useDownloadFileBNBA,
	usePostFile,
} from '@/hooks/perumahan/useRumahBencana';
import { usePrivilegeRumahRelokasi } from '@/hooks/privilege/usePrivilege';
import { useQueryClient } from '@tanstack/react-query';
import { FileDownIcon } from 'lucide-react';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
	FiAlertCircle,
	FiCheckCircle,
	FiTrash,
	FiUpload,
} from 'react-icons/fi';
import { useSelector } from 'react-redux';
import TBerkasRelokasi from './TBerkasRumahRelokasi';

const BNBARumahRelokasi = () => {
	const cardRef = useRef(null);
	const inputDokumenRef = useRef<HTMLInputElement>(null);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const user = useSelector((state: RootState) => state.auth);
	const [uploadDokumen, setUploadDokumen] = useState<File | null>(null);
	const {
		isPrevilegeViewRumahRelokasi,
		kd_kabkota_to_use,
		kd_provinsi_to_use,
	} = usePrivilegeRumahRelokasi();
	const { isMenuRumahRelokasiProv } = useMenuRumahRelokasi();
	const [fileName, setFileName] = useState('');
	const { wilayah } = useSelector((state: RootState) => state.auth);
	const { postFile } = usePostFile();
	const jenis_bencana_id = 1;

	const { data: downloadFile } = useDownloadFileBNBA(
		kd_provinsi_to_use,
		kd_kabkota_to_use,
		Number(tahun),
		menuId,
		user.id_user,
		fileName,
	);

	const QueryClient = useQueryClient();
	const [showUploadButton, setShowUploadButton] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	let rowCounter = 0;

	const handleOpenDokumen = () => {
		if (inputDokumenRef.current) {
			inputDokumenRef.current.click();
		}
	};

	const handleDeleteDokumen = () => {
		setUploadDokumen(null);
	};

	const handleOnChangeOpenDokumen = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			// Check file size and type
			if (file.size > 5 * 1024 * 1024) {
				alert('File size must be less than 5MB');
				return;
			}
			if (
				file.type !== 'application/pdf' &&
				file.type !==
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			) {
				alert('File must be a PDF or Excel');
				return;
			}
			setUploadDokumen(file);
			e.target.value = '';
		}
	};

	const handleUploadFileBNBA = (event: FormEvent) => {
		event.preventDefault();

		if (!uploadDokumen) {
			alert('Please select a file');
			return;
		}

		setIsLoading(true); // Set isLoading to true

		const data = {
			tahun_upload: tahun,
			menu_id: menuId,
			kd_provinsi: kd_provinsi_to_use,
			kd_kabkota: kd_kabkota_to_use,
			jenis_up_id: jenis_bencana_id,
			file_upload: uploadDokumen,
		};

		postFile(data, {
			onSuccess: () => {
				console.log('File uploaded successfully');
				setShowUploadButton(false);
				QueryClient.invalidateQueries({ queryKey: ['get-file-bnba'] });
				setIsLoading(false); // Set isLoading to false
			},
			onError: (error) => {
				console.log(error);
				setIsLoading(false); // Set isLoading to false
			},
		});
	};

	const handleShowUpload = () => {
		setShowUploadButton(true);
	};

	const downloadTemplate = () => {
		const linkTemplateProv =
			'https://docs.google.com/spreadsheets/d/1rW9RlQn7AOUU0JVZE25iYh1pwDLIofax4Y80UvX1Kxo/edit?usp=sharing';

		const linkTemplateKab =
			'https://docs.google.com/spreadsheets/d/1URkxCc2MIJU2jgUOrBs3ekY3K93U0ofcMIujJL9O9m8/edit?usp=sharing';

		const link = document.createElement('a');
		link.target = '_blank';
		link.rel = 'noopener noreferrer';
		link.href = isMenuRumahRelokasiProv ? linkTemplateProv : linkTemplateKab;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea>
				{isPrevilegeViewRumahRelokasi ? (
					<>
						<Label
							className="flex items-center justify-center text-2xl font-semibold text-[#364878] dark:text-white"
							htmlFor="dokumen-verifikasi"
						>
							List Dokumen BNBA {wilayah.nama_provinsi}
						</Label>
						{!showUploadButton && (
							<TBerkasRelokasi jenis_up_id={jenis_bencana_id} />
						)}
					</>
				) : (
					<>
						<div className="flex items-center justify-start py-4 text-center">
							<div className="inline-flex items-center gap-2 text-center font-bold">
								<FiAlertCircle className="text-3xl text-red-500" /> Catatan :
								Silahkan Download Template Dokumen BNBA terlebih dahulu.
							</div>
						</div>
						<div className="flex items-center justify-start gap-4">
							<Button onClick={downloadTemplate} className="gap-2 text-white">
								<FileDownIcon className="text-2xl text-white" /> Download
								Template
							</Button>
							<Button
								onClick={handleShowUpload}
								className="gap-2 bg-green-500 text-white"
							>
								<FileDownIcon className="text-2xl text-white" />
								Upload Dokumen BNBA
							</Button>
						</div>
						{!showUploadButton && (
							<>
								<TBerkasRelokasi jenis_up_id={jenis_bencana_id} />
							</>
						)}
						{showUploadButton && (
							<form
								onSubmit={handleUploadFileBNBA}
								className="mt-15 flex flex-col items-center justify-center gap-8"
							>
								<Label
									className="text-2xl font-semibold text-[#364878] dark:text-white"
									htmlFor="dokumen-verifikasi"
								>
									Upload Dokumen BNBA
								</Label>
								<div className="flex items-center gap-6">
									{uploadDokumen && (
										<FiCheckCircle className="text-2xl text-green-500" />
									)}
									{uploadDokumen && (
										<span className="text-xl font-medium text-black">
											{uploadDokumen.name}
										</span>
									)}
									{uploadDokumen ? (
										<>
											<FiTrash
												className="cursor-pointer text-2xl text-red-500"
												onClick={handleDeleteDokumen}
											/>
											<br />
											<Button
												className="inline-flex h-14 w-30 items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center text-lg font-medium text-white hover:bg-opacity-90"
												onSubmit={handleUploadFileBNBA}
												disabled={isLoading} // Disable button if isLoading
											>
												{isLoading ? (
													<span>Loading...</span> // Show loading text when uploading
												) : (
													<>
														<FiUpload className="text-2xl text-white" /> Upload
													</>
												)}
											</Button>
										</>
									) : (
										<Button
											className="inline-flex h-14 w-30 items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center text-lg font-medium text-white hover:bg-opacity-90"
											onClick={handleOpenDokumen}
										>
											<FiUpload className="text-2xl text-white" /> Upload
										</Button>
									)}
								</div>
								<input
									ref={inputDokumenRef}
									onChange={handleOnChangeOpenDokumen}
									type="file"
									className="hidden"
									accept=".pdf, .xlsx"
								/>
								<div className="">
									<p className="text-center text-sm font-black text-red-500">
										*Maksimal 50 MB
									</p>
									<p className="text-center text-sm font-black text-red-500">
										*Harus Berekstensi .pdf atau .xlsx (File PDF atau Excel)
									</p>
								</div>
							</form>
						)}
					</>
				)}
			</ScrollArea>
		</div>
	);
};

export default BNBARumahRelokasi;
