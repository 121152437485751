import React, { useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface JenisPotensiBahayaUIProps {
    getJenisPotensiBahaya: any[];
    setJenisPotensiBahaya: (id_jenis_potensi_bahaya: string) => void;
}


const JenisPotensiBahayaUI: React.FC<JenisPotensiBahayaUIProps> = ({
    getJenisPotensiBahaya,
    setJenisPotensiBahaya
}) => {
    const [selectedJenisPotensi, setSelectedJenisPotensi] = useState('');

    return(
        <Select
            onValueChange={(value) => {
                    if (value !== '') {
                        const selectedJenisPotensi = getJenisPotensiBahaya.find((jenisPotensi: any) => jenisPotensi.id_jenis_potensi_bahaya === value);
                        if(selectedJenisPotensi){
                            console.log("Jenis Jenis Potensi yang dipilih:", selectedJenisPotensi.nama_jenis_potensi_bahaya);
                            setSelectedJenisPotensi(selectedJenisPotensi.nama_jenis_potensi_bahaya || "");
                            setJenisPotensiBahaya(value);
                        }
                    }
                }}
            >
                <SelectTrigger>
                    <SelectValue placeholder="Pilih Jenis Jenis Potensi">
                        {selectedJenisPotensi || 'Pilih Jenis Jenis Potensi'}
                    </SelectValue>
                </SelectTrigger>
                <SelectContent className='z-99999'>
                    {getJenisPotensiBahaya.map((jenisPotensi: any, index: any) => (
                        <SelectItem key={index} value={jenisPotensi.id_jenis_potensi_bahaya}>
                            {jenisPotensi.nama_jenis_potensi_bahaya}
                        </SelectItem>
                    ))}
                </SelectContent>
        </Select>
    )
}

export default JenisPotensiBahayaUI;


