import { RootState } from "@/app/store";
import { CompLoader } from "@/common/Loader";
import JudulHeader from "@/components/Header/JudulHeader";
import { Button } from "@/components/ui/Button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useGetDataCapaianRumahRelokasi } from "@/hooks/perumahan/useRumahBencana";
import { hitungTotalData } from "@/libs/utils";
import React, { useEffect, useRef, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { utils, writeFile } from "xlsx-js-style";

const TableDataCapaianKumulatifRumahRelokasiBappedaProv = () => {
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
  const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
  const nama_provinsi = useSelector((state: RootState) => state.auth.wilayah.nama_provinsi)
  const nama_kabkota = useSelector((state: RootState) => state.auth.wilayah.nama_kabkota)
  const privilege = useSelector((state: RootState) => state.auth.privilege_id);
  const { kd_provinsi_dux, kd_kabkota_dux, nama_kabkota: nama_kabkota_dux, nama_provinsi: nama_provinsi_dux } = useSelector(
    (state: RootState) => state.location,
  );
  const menu_id = useSelector((state: RootState) => state.menuId.value)
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const [allDataRLProv, setAllDataRLProv] = useState<any>([])

  const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi
  const kd_kabkota_to_use = (privilege === 10 || privilege === 4 || privilege == 8) ? kd_kabkota_dux : kd_kabkota
  const nama_kabkota_to_use = (privilege === 10 || privilege === 4 || privilege === 8) ? nama_kabkota_dux : nama_kabkota;
  const nama_provinsi_to_use = privilege === 10 ? nama_provinsi_dux : nama_provinsi;

  const [subTotal, setSubTotal] = useState({
    jml_rmh_relokasi: 0,
    tgt_ganti_uang: 0,
    tgt_bgn_kembali: 0,
    tgt_subsidi: 0,
    tgt_akses_rmh: 0,
    real_ganti_uang: 0,
    real_bgn_kembali: 0,
    real_subsidi: 0,
    real_akses_rmh: 0,
  })

  const [total, setTotal] = useState({
    jml_rmh_relokasi: 0,
    target: 0,
    realisasi: 0,
    capaian: 0
  })

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading
  } = useGetDataCapaianRumahRelokasi({ menu_id, kd_provinsi: kd_provinsi_to_use, kd_kabkota: kd_kabkota_to_use, tahun })

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const cardRef = useRef(null)

  useEffect(() => {
    if (!isLoading) {
      if (!hasNextPage) {
        const subTotalJumlahRumahRelokasi = hitungTotalData(pages, "jml_rmh_relokasi")
        const subTotalTargetGantiUang = hitungTotalData(pages, "tgt_ganti_uang")
        const subTotalTargetPembangunanKembali = hitungTotalData(pages, "tgt_bgn_kembali")
        const subTotalTargetSubsidi = hitungTotalData(pages, "tgt_subsidi")
        const subTotalTargetAksesRumah = hitungTotalData(pages, "tgt_akses_rmh")
        const subTotalRealisasiGantiUang = hitungTotalData(pages, "real_ganti_uang")
        const subTotalRealisasiPembangunanKembali = hitungTotalData(pages, "real_bgn_kembali")
        const subTotalRealisasiSubsidi = hitungTotalData(pages, "real_subsidi")
        const subTotalRealisasiAksesRumah = hitungTotalData(pages, "real_akses_rmh")

        const totalJumlahRumahRelokasi = subTotalJumlahRumahRelokasi
        const totalTarget = subTotalTargetGantiUang + subTotalTargetPembangunanKembali + subTotalTargetSubsidi + subTotalTargetAksesRumah
        const totalRealisasi = subTotalRealisasiGantiUang + subTotalRealisasiPembangunanKembali + subTotalRealisasiSubsidi + subTotalRealisasiAksesRumah
        const totalCapaian = (totalRealisasi / totalTarget) * 100

        setSubTotal((prevState: any) => ({ ...prevState, jml_rmh_relokasi: subTotalJumlahRumahRelokasi, tgt_ganti_uang: subTotalTargetGantiUang, tgt_bgn_kembali: subTotalTargetPembangunanKembali, tgt_subsidi: subTotalTargetSubsidi, tgt_akses_rmh: subTotalTargetAksesRumah, real_ganti_uang: subTotalRealisasiGantiUang, real_bgn_kembali: subTotalRealisasiPembangunanKembali, real_subsidi: subTotalRealisasiSubsidi, real_akses_rmh: subTotalRealisasiAksesRumah }))

        setTotal((prevState: any) => ({ ...prevState, jml_rmh_relokasi: totalJumlahRumahRelokasi, target: totalTarget, realisasi: totalRealisasi, capaian: totalCapaian }))
      }
    }
  }, [hasNextPage, isLoading, tahun])

  const handleDownload = () => {
    const transformedData = allDataRLProv.map((item: any, index: any) => ([
      { v: index + 1, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.jenis_relokasi.nama_jenis_relokasi, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.wilayah.nama_kabkota, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.wilayah.nama_kecamatan ?? "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.jml_rmh_relokasi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.tgt_ganti_uang, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.tgt_bgn_kembali, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.tgt_subsidi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.tgt_akses_rmh, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.real_ganti_uang, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.real_bgn_kembali, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.real_subsidi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.real_akses_rmh, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
    ]));

    const header = [
      { v: "No", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Jenis Program Relokasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Kabupaten/Kota", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Jumlah Rumah Terkena Relokasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
    ];

    const header1 = [
      { v: "Penggantian Uang", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Pembangunan Kembali", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Subsidi Uang Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Akses Tinggal Rumah Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Penggantian Uang", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Pembangunan Kembali", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Subsidi Uang Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Akses Tinggal Rumah Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
    ];

    const header2 = [
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
    ];

    const subTotalRow = [
      { v: subTotal.jml_rmh_relokasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: subTotal.tgt_ganti_uang, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: subTotal.tgt_bgn_kembali, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: subTotal.tgt_subsidi, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: subTotal.tgt_akses_rmh, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: subTotal.real_ganti_uang, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: subTotal.real_bgn_kembali, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: subTotal.real_subsidi, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: subTotal.real_akses_rmh, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
    ];

    let wb = utils.book_new(),
      ws = utils.aoa_to_sheet([]);

    utils.sheet_add_aoa(ws, [[{ v: "DATA CAPAIAN", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A1" })
    utils.sheet_add_aoa(ws, [[{ v: "SPM PERUMAHAN RAKYAT", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A2" })
    utils.sheet_add_aoa(ws, [[{ v: `PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A3" })
    utils.sheet_add_aoa(ws, [[{ v: `RELOKASI PROGRAM PEMERINTAH DAERAH KABUPATEN/KOTA ${Number(tahun)}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A4" })

    utils.sheet_add_aoa(ws, [header], { origin: "A5" })
    utils.sheet_add_aoa(ws, [[{ v: "Target", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "F5" })
    utils.sheet_add_aoa(ws, [[{ v: "Realisasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "J5" })
    utils.sheet_add_aoa(ws, [header1], { origin: "F6" })
    utils.sheet_add_aoa(ws, [header2], { origin: "E7" })
    utils.sheet_add_aoa(ws, transformedData, { origin: "A8" })

    utils.sheet_add_aoa(ws, [[{ v: "Sub Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 8}` })
    utils.sheet_add_aoa(ws, [subTotalRow], { origin: `E${transformedData.length + 8}` })

    utils.sheet_add_aoa(ws, [[{ v: "Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 9}` })
    utils.sheet_add_aoa(ws, [[{ v: total.jml_rmh_relokasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `E${transformedData.length + 9}` })
    utils.sheet_add_aoa(ws, [[{ v: total.target, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `F${transformedData.length + 9}` })
    utils.sheet_add_aoa(ws, [[{ v: total.realisasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `J${transformedData.length + 9}` })



    utils.sheet_add_aoa(ws, [[{ v: "Capaian SPM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 10}` })
    utils.sheet_add_aoa(ws, [[{ v: `${isNaN(total.capaian) ? 0 : total.capaian}%`, t: "", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `J${transformedData.length + 10}` })

    ws['!cols'] = [
      { wpx: 50 },
      { wpx: 576 },
      { wpx: 104 },
      { wpx: 144 },
      { wpx: 197 },
      { wpx: 115 },
      { wpx: 144 },
      { wpx: 118 },
      { wpx: 167 },
      { wpx: 115 },
      { wpx: 144 },
      { wpx: 118 },
      { wpx: 167 },
    ]

    ws['!merges'] = [
      // 	// COLSPANS
      utils.decode_range("A1:M1"),
      utils.decode_range("A2:M2"),
      utils.decode_range("A3:M3"),
      utils.decode_range("A4:M4"),
      utils.decode_range("F5:I5"),
      utils.decode_range("J5:M5"),
      utils.decode_range(`A${transformedData.length + 8}:D${transformedData.length + 8}`),
      utils.decode_range(`A${transformedData.length + 9}:D${transformedData.length + 9}`),
      utils.decode_range(`F${transformedData.length + 9}:I${transformedData.length + 9}`),
      utils.decode_range(`J${transformedData.length + 9}:M${transformedData.length + 9}`),
      utils.decode_range(`A${transformedData.length + 10}:I${transformedData.length + 10}`),
      utils.decode_range(`J${transformedData.length + 10}:M${transformedData.length + 10}`),

      // ROWSPANS
      utils.decode_range("A5:A7"),
      utils.decode_range("B5:B7"),
      utils.decode_range("C5:C7"),
      utils.decode_range("D5:D7"),
      utils.decode_range("E5:E6"),
    ]

    utils.book_append_sheet(wb, ws, "Kumulatif Rumah Relokasi")
    writeFile(wb, `Data Capaian Kumulatif Rumah Relokasi ${nama_provinsi_to_use} - ${nama_kabkota_to_use} ${tahun}.xlsx`)
  }

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (!isLoading) {
      const allDataRL = pages.flatMap((page) => page.data)
      setAllDataRLProv(allDataRL)
    }
  }, [isLoading, data])

  if (isLoading) return <CompLoader />

  const pages = data?.pages || [];

  let rowCounter = 0

  return (
    <>
      <div className="flex justify-end pb-4">
        {allDataRLProv.length !== 0 &&
          <Button
            className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
            onClick={() => handleDownload()}
          >
            <FiDownload />
            Download Data
          </Button>
        }
      </div>
      <div ref={cardRef} className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col flex-1 overflow-hidden">
        <ScrollArea className='h-full p-4 relative'>
          <JudulHeader isLoading={isLoading} cardRef={cardRef} minusWidth={32}>
            DATA CAPAIAN <br />
            SPM PERUMAHAN RAKYAT <br />
            PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA <br />
            RELOKASI PROGRAM PEMERINTAH DAERAH KABUPATEN/KOTA {Number(tahun)}
          </JudulHeader>
          <Table className="min-w-[2800px] w-full table-auto">
            <TableHeader className=" bg-gray-2 dark:bg-meta-4 sticky top-0">
              <TableRow>
                <TableHead rowSpan={3} className="border-l thead-text text-center">No</TableHead>
                <TableHead rowSpan={3} className="thead-text text-center">Jenis Program Relokasi</TableHead>
                <TableHead rowSpan={3} className="thead-text text-center">Kecamatan</TableHead>
                <TableHead rowSpan={3} className="thead-text text-center">Kelurahan / Desa</TableHead>
                <TableHead rowSpan={2} className="border-b-0 thead-text text-center">Jumlah Rumah Terkena Relokasi</TableHead>
                <TableHead colSpan={4} className="border-b-0 thead-text text-center">Target</TableHead>
                <TableHead colSpan={4} className="border-b-0 thead-text text-center">Realisasi</TableHead>
              </TableRow>
              <TableRow>
                <TableHead className="border-b-0 thead-text text-center">Penggantian Uang</TableHead>
                <TableHead className="border-b-0 thead-text text-center">Pembangunan Kembali</TableHead>
                <TableHead className="border-b-0 thead-text text-center">Subsidi Uang Sewa</TableHead>
                <TableHead className="border-b-0 thead-text text-center">Akses Tinggal Rumah Sewa</TableHead>
                <TableHead className="border-b-0 thead-text text-center">Penggantian Uang</TableHead>
                <TableHead className="border-b-0 thead-text text-center">Pembangunan Kembali</TableHead>
                <TableHead className="border-b-0 thead-text text-center">Subsidi Uang Sewa</TableHead>
                <TableHead className="border-b-0 thead-text text-center">Akses Tinggal Rumah Sewa</TableHead>
              </TableRow>
              <TableRow>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
                <TableHead className="thead-text text-center">(Rumah)</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {pages.map((page, i) => {
                return (
                  <React.Fragment key={i}>
                    {page.data.map((item: any, key: number) => {
                      rowCounter++;
                      return (
                        <TableRow key={key} className={
                          rowCounter % 2 === 0
                            ? 'bg-gray-2 dark:bg-meta-4'
                            : 'bg-white dark:bg-boxdark'
                        }>
                          <TableCell className='tbody-text border-l border-b border-r'>{rowCounter}</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.jenis_relokasi?.nama_jenis_relokasi ?? "-"}</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.wilayah?.nama_kecamatan ?? "-"}</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.wilayah?.nama_desa ?? "-"}</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.jml_rmh_relokasi}</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.tgt_ganti_uang
                          }</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.tgt_bgn_kembali
                          }</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.tgt_subsidi
                          }</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.tgt_akses_rmh
                          }</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.real_ganti_uang
                          }</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.real_bgn_kembali
                          }</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.real_subsidi
                          }</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.real_akses_rmh
                          }</TableCell>
                        </TableRow>
                      )
                    })}
                  </React.Fragment>
                )
              })}
              {!hasNextPage &&
                <>
                  <TableRow className='bg-black/10 dark:bg-white/10'>
                    <TableCell className='text-lg tfooter-text border-l border-b border-r' colSpan={4}>Sub Total</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.jml_rmh_relokasi}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.tgt_ganti_uang}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.tgt_bgn_kembali}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.tgt_subsidi}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.tgt_akses_rmh}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.real_ganti_uang}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.real_bgn_kembali}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.real_subsidi}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{subTotal.real_akses_rmh}</TableCell>
                  </TableRow>
                  <TableRow className='bg-black/10 dark:bg-white/10'>
                    <TableCell className='text-lg tfooter-text border-l border-b border-r' colSpan={4}>Total</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r'>{total.jml_rmh_relokasi}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r' colSpan={4}>{total.target}</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r' colSpan={4}>{total.realisasi}</TableCell>
                  </TableRow>
                  <TableRow className='bg-black/10 dark:bg-white/10'>
                    <TableCell className='text-lg tfooter-text border-l border-b border-r' colSpan={12}>Capaian SPM</TableCell>
                    <TableCell className='text-lg tfooter-text border-b border-r' colSpan={5}>{total.capaian.toFixed(2)}%</TableCell>
                  </TableRow>
                </>
              }
            </TableBody>
          </Table>
          <div className='h-1' ref={ref}>
            {isFetchingNextPage &&
              <div className="flex justify-center items-center p-4">
                <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
              </div>
            }
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </>
  )
}

export default TableDataCapaianKumulatifRumahRelokasiBappedaProv