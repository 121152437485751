import { TableDataKumulatifAlKab } from '@/components/Tables/AirLimbah/TableDataKumulatifAlKab';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../../layout/DefaultLayout';

const CapaianAlKab = () => {
	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead="Rekapitulasi Capaian SPM Air Limbah"
				pageName="Data Kumulatif"
			/>
			<TableDataKumulatifAlKab />
		</DefaultLayout>
	);
};
export default CapaianAlKab;
