import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { FiInfo } from 'react-icons/fi';

const GTargetPenyelesaian = () => {
    let rowCounter = 0;

    const panduanData = [
        {
            id: 1,
            title: "Jenis Pelayanan",
            description: "Diisi jenis pelayanan yang akan diberikan",
        },
        {
            id: 2,
            title: "Jumlah Rumah Tangga",
            description: "Diisi jumlah rumah tangga yang akan ditangani dari masing-masing jenis layanan"
        },
        {
            id: 3,
            title: "Kebutuhan Biaya",
            description: "Diisi kebutuhan biaya dari masing-masing jenis layanan"
        },
        {
            id: 4,
            title: "Jumlah Rumah Tangga Tahun 1",
            description: "Diisi jumlah rumah tangga yang akan ditangani dari masing-masing jenis layanan pada tahun ke-1"
        },
        {
            id: 5,
            title: "Kebutuhan Biaya Tahun 1",
            description: "Diisi kebutuhan biaya dari masing-masing jenis layanan pada tahun ke-1"
        },
        {
            id: 6,
            title: "Sumber Pembiayaan Tahun 1",
            description: "Diisi sumber pembiayaan yang digunakan pada tahun ke-1",
        },
        {
            id: 7,
            title: "Jumlah Rumah Tangga Tahun 2",
            description: "Diisi jumlah rumah tangga yang akan ditangani dari masing-masing jenis layanan pada tahun ke-2"
        },
        {
            id: 8,
            title: "Kebutuhan Biaya Tahun 2",
            description: "Diisi kebutuhan biaya dari masing-masing jenis layanan pada tahun ke-2"
        },
        {
            id: 9,
            title: "Sumber Pembiayaan Tahun 2",
            description: "Diisi sumber pembiayaan yang digunakan pada tahun ke-2",
        },
        {
            id: 10,
            title: "Jumlah Rumah Tangga Tahun 3",
            description: "Diisi jumlah rumah tangga yang akan ditangani dari masing-masing jenis layanan pada tahun ke-3"
        },
        {
            id: 11,
            title: "Kebutuhan Biaya Tahun 3",
            description: "Diisi kebutuhan biaya dari masing-masing jenis layanan pada tahun ke-3"
        },
        {
            id: 12,
            title: "Sumber Pembiayaan Tahun 3",
            description: "Diisi sumber pembiayaan yang digunakan pada tahun ke-3",
        }
    ];
    

    return (
			<>
				<Dialog>
					<DialogTrigger asChild>
						<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-green-600 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiInfo />
							Panduan
						</Button>
					</DialogTrigger>
					<DialogContent className="z-99999 max-h-[85%] max-w-[65%] overflow-auto rounded-lg">
						<DialogHeader>
							<DialogTitle>
								Panduan Pengisian Form Target Penyelesaian Rumah Korban Bencana
							</DialogTitle>
							<DialogDescription>
								Silahkan ikuti panduan dibawah ini untuk melakukan pengisian
								form atau input data
							</DialogDescription>
						</DialogHeader>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="border-l text-center">No</TableHead>
									<TableHead className="text-center">Kolom</TableHead>
									<TableHead className="text-center">Keterangan</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{panduanData.map((data) => (
									<TableRow
										key={data.id}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="text-start">{data.title}</TableCell>
										<TableCell className="text-start">
											{data.description}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<DialogFooter>
							<div className="mt-5 flex w-full items-center justify-end">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Tutup
									</Button>
								</DialogClose>
							</div>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</>
		);
};

export default GTargetPenyelesaian;
