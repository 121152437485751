import { formatRupiah } from "@/libs/utils"
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "../ui/table"
import { useDeleteBantuanKeuangan } from "@/hooks/useBantuanKeuangan"
import { useSelector } from "react-redux"
import { RootState } from "@/app/store"
import { MiniLoader } from "@/common/Loader"
import { useEffect, useState } from "react"
import { FiEdit, FiTrash } from "react-icons/fi"
import ConfirmationDialog from "../ui/ConfirmationDialog"
import { useQueryClient } from "@tanstack/react-query"
import { useGetAPBDPerProv } from "@/hooks/useAPBDPerProv"

type TableBantuanKeuanganReal = {
  setShowForm: any,
  setIdBantuanKeuangan: any
  isLoadGetBantuanKeuangan: any
  getBantuanKeuangan: any
}

const TableBantuanKeuanganReal = ({ setShowForm, setIdBantuanKeuangan, isLoadGetBantuanKeuangan, getBantuanKeuangan }: TableBantuanKeuanganReal) => {
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
  const menu_id = useSelector((state: RootState) => state.menuId.value)
  const tahun = useSelector((state: RootState) => state.tahun.value)

  const { deleteBantuanKeuangan } = useDeleteBantuanKeuangan()
  const { getAPBDPerProv, isLoading: loadAPBDProv } = useGetAPBDPerProv(Number(tahun), kd_provinsi, menu_id)

  const queryClient = useQueryClient()
  const [apbd, setAPBD] = useState(0)
  const [total, setTotal] = useState(0)
  const [capaian, setCapaian] = useState(0)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [deletedIdBk, setDeletedIdBk] = useState(0)

  const handleToEditForm = (id: number) => {
    setShowForm("Edit Bantuan Keuangan")
    setIdBantuanKeuangan(id)
  }

  const handleDelete = async (id: number) => {
    try {
      await deleteBantuanKeuangan(id)
      queryClient.invalidateQueries({ queryKey: ["bantuan-keuangan"] })
      setIsConfirmDelete(false)
    } catch (error) {
      console.error(error)
    }
  }

  let rowCounter = 0

  useEffect(() => {
    if (!isLoadGetBantuanKeuangan && !loadAPBDProv) {
      if (getAPBDPerProv.length !== 0) {
        const apbd = getAPBDPerProv.find((item: any) => item.tahun_pelaporan === Number(tahun) && item.kd_provinsi === kd_provinsi)
        const totalAnggaran = getBantuanKeuangan.reduce((acc: any, curr: any) => acc + Number(curr["anggaran_bk"]), 0)

        const capaian = Math.min((totalAnggaran / Number(apbd.total_apbd_prov)) * 100)

        console.log(capaian)

        setTotal(totalAnggaran)
        setCapaian(capaian)
        setAPBD(Number(apbd.total_apbd_prov))
      }
    }
  }, [loadAPBDProv, getAPBDPerProv, getBantuanKeuangan, isLoadGetBantuanKeuangan])

  if (isLoadGetBantuanKeuangan || loadAPBDProv) return <MiniLoader />

  return (
    <Table className="min-w-[1500px] w-full table-auto">
      <TableHeader className=" bg-gray-2 dark:bg-meta-4 sticky top-0">
        <TableRow>
          <TableHead rowSpan={2} className="border-l thead-text text-center">No</TableHead>
          <TableHead rowSpan={2} className="thead-text text-center">Tahun</TableHead>
          <TableHead colSpan={2} className="thead-text text-center border-b-0">Bantuan Keuangan
            <br /> (Rupiah)</TableHead>
          <TableHead rowSpan={2} className="thead-text text-center">Jenis Bantuan Keuangan</TableHead>
          <TableHead colSpan={3} className="thead-text text-center border-b-0">Output</TableHead>
          {privilege !== 4 && privilege !== 8 && privilege !== 10 &&
            <TableHead rowSpan={2} className="thead-text text-center">Aksi</TableHead>
          }
        </TableRow>
        <TableRow>
          <TableHead className="thead-text text-center">Anggaran</TableHead>
          <TableHead className="thead-text text-center">Total Alokasi APBD Provinsi <br /> untuk Bidang Air Minum</TableHead>
          <TableHead className="thead-text text-center">Komponen</TableHead>
          <TableHead className="thead-text text-center">Satuan</TableHead>
          <TableHead className="thead-text text-center">Volume</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {getBantuanKeuangan.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map((item: any, key: any) => {
          rowCounter++
          return (
            <TableRow key={key} className={
              rowCounter % 2 === 0
                ? 'bg-gray-2 dark:bg-meta-4'
                : 'bg-white dark:bg-boxdark'
            }>
              <TableCell className='tbody-text border-l border-b border-r'>{rowCounter}</TableCell>
              <TableCell className='tbody-text border-b border-r'>{item.tahun_pelaporan}</TableCell>
              <TableCell className='tbody-text border-b border-r'>{formatRupiah(item.anggaran_bk)}</TableCell>
              <TableCell className='tbody-text border-b border-r'>{formatRupiah(Number(item.bkApbdProvinsi.total_apbd_prov))}</TableCell>
              <TableCell className='tbody-text border-b border-r'>{item.jenis_bantuan_keuangan}</TableCell>
              <TableCell className='tbody-text border-b border-r'>{item.komponen.komponen_name}</TableCell>
              <TableCell className='tbody-text border-b border-r'>{item.satuan.satuan_name}</TableCell>
              <TableCell className='tbody-text border-b border-r'>{item.volume}</TableCell>
              {privilege !== 4 && privilege !== 8 && privilege !== 10 &&
                <TableCell className='border-b border-r'>
                  <div className="flex gap-4 items-center justify-center">
                    <FiEdit className="text-green-500 text-xl" onClick={() => handleToEditForm(item.id_bk)} />
                    <FiTrash className="text-red-500 text-xl" onClick={() => {
                      setIsConfirmDelete(true)
                      setDeletedIdBk(item.id_bk)
                    }} />
                  </div>
                </TableCell>
              }

            </TableRow>
          )
        })}
      </TableBody>
      <TableFooter>
        <TableRow className='bg-black/10 dark:bg-white/10'>
          <TableCell colSpan={2} className="tfooter-text border-l border-b border-r">Total Anggaran</TableCell>
          <TableCell className="tfooter-text border-b border-r">{formatRupiah(total)}</TableCell>
          <TableCell className="tfooter-text border-b border-r">{formatRupiah(apbd)}</TableCell>
        </TableRow>
        <TableRow className='bg-black/10 dark:bg-white/10'>
          <TableCell colSpan={3} className="tfooter-text border-l border-b border-r">Capaian Kinerja Bantuan Keuangan Provinsi (%)</TableCell>
          <TableCell className="tfooter-text border-b border-r">{isNaN(capaian) ? 0 : capaian.toFixed(2)}%</TableCell>
        </TableRow>
      </TableFooter>
      <ConfirmationDialog
        isDialogOpen={isConfirmDelete}
        setIsDialogOpen={setIsConfirmDelete}
        confirmAction={() => handleDelete(deletedIdBk)}
        title="Tunggu Dulu"
        description="Apakah Anda yakin mau menghapus Bantuan Keuangan?"
        confirmButtonColor="bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-400 dark:hover:bg-blue-600" />
    </Table>
  )
}

export default TableBantuanKeuanganReal