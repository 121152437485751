import { RootState } from "@/app/store";
import Breadcrumb from "@/components/Breadcrumbs/Breadcrumb";
import EditFormDataCapaianKumulatifRumahRelokasiProv from "@/components/Forms/RumahRelokasi/EditFormCapaianKumulatifRumahRelokasiProv";
import FormDataCapaianKumulatifRumahRelokasiProv from "@/components/Forms/RumahRelokasi/FormDataCapaianKumulatifRumahRelokasiProv";
import TableDataCapaianKumulatifRumahRelokasiBappedaProv from "@/components/Tables/RumahRelokasi/DataCapaian/Kumulatif/TableDataCapaianKumulatifRumahRelokasiBappedaProv";
import TableDataCapaianKumulatifRumahRelokasiProv from "@/components/Tables/RumahRelokasi/DataCapaian/Kumulatif/TableDataCapaianKumulatifRumahRelokasiProv";
import DefaultLayout from "@/layout/DefaultLayout";
import { useState } from "react";
import { useSelector } from "react-redux";

const DataCapaianKumulatifRRProv = () => {
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
  const wilayah = useSelector((state: RootState) => state.wilayah.value)

  const [showForm, setShowForm] = useState("Lihat Data")
  const [editableId, setEditableId] = useState(0)

  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead={`Data Capaian Kumulatif Rumah Relokasi ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
        pageName={`Data Capaian Kumulatif Rumah Relokasi ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
      />
      {showForm === "Lihat Data" ? privilege === 4 || privilege === 8 || privilege === 10 ? wilayah === "provinsi" ? <TableDataCapaianKumulatifRumahRelokasiProv setEditableId={setEditableId} setShowForm={setShowForm} /> : <TableDataCapaianKumulatifRumahRelokasiBappedaProv /> : <></> : <></>}
      {showForm === "Lihat Data" ? privilege === 2 && <TableDataCapaianKumulatifRumahRelokasiProv setEditableId={setEditableId} setShowForm={setShowForm} /> : <></>}
      {showForm === "Input Data" && <FormDataCapaianKumulatifRumahRelokasiProv setShowForm={setShowForm} />}
      {showForm === 'Edit Data' && (
        <EditFormDataCapaianKumulatifRumahRelokasiProv
          editableId={editableId}
          setShowForm={setShowForm}
        />
      )}
    </DefaultLayout>
  );
};

export default DataCapaianKumulatifRRProv;