import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import TableDataKumulatifAmKab from '../../components/Tables/AirMinum/TableDataKumulatifAmKab';
import DefaultLayout from '../../layout/DefaultLayout';

const CapaianAmKab = () => {
  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead="Rekapitulasi Capaian SPM Air Minum"
        pageName="Data Kumulatif"
      />
      <TableDataKumulatifAmKab />
    </DefaultLayout>
  );
};
export default CapaianAmKab;
