import { Link } from 'react-router-dom';
// import DropdownMessage from './DropdownMessage';
// import DropdownNotification from './DropdownNotification';
import DropdownUser from './DropdownUser';
import LogoIcon from '../../images/logo/logo-sicalmers.svg';
import LogoDark from '../../images/logo/logo-sicalmers-dark.svg';
import DarkModeSwitcher from './DarkModeSwitcher';
import TahunSelection from './TahunSelection';
import WilayahSelection from './WilayahSelection';
import BidangSelection from './BidangSelection';
import LokasiSelection from './LokasiSelection';
import { RootState } from '@/app/store';
import { useSelector } from 'react-redux';

const Header = (props: {
	sidebarOpen: string | boolean | undefined;
	setSidebarOpen: (arg0: boolean) => void;
}) => {

	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	return (
		<header className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
			<div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
				<div className="flex items-center gap-2 sm:gap-4">
					{!props.sidebarOpen && (
						<Link className="block flex-shrink-0 xl:-ml-6 xl:pr-10" to="/">
							<img src={LogoDark} className="dark:hidden" alt="Logo" />
							<img src={LogoIcon} className="hidden dark:block" alt="Logo" />
						</Link>
					)}
					{/* <!-- Hamburger Toggle BTN --> */}
					<button
						aria-controls="sidebar"
						onClick={(e) => {
							e.stopPropagation();
							props.setSidebarOpen(!props.sidebarOpen);
						}}
						className="z-99998 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark"
					>
						<span className="relative block h-5.5 w-5.5 cursor-pointer">
							<span className="du-block absolute right-0 h-full w-full">
								<span
									className={`delay-&lsqb;0&rsqb relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${
										!props.sidebarOpen && '!w-full delay-300'
									}`}
								></span>
								<span
									className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${
										!props.sidebarOpen && 'delay-400 !w-full'
									}`}
								></span>
								<span
									className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${
										!props.sidebarOpen && '!w-full delay-500'
									}`}
								></span>
							</span>
							<span className="absolute right-0 h-full w-full rotate-45">
								<span
									className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${
										!props.sidebarOpen && '!delay-&lsqb;0&rsqb !h-0'
									}`}
								></span>
								<span
									className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${
										!props.sidebarOpen && '!h-0 !delay-200'
									}`}
								></span>
							</span>
						</span>
					</button>
					{/* <!-- Hamburger Toggle BTN --> */}
					<div className="hidden sm:block">
						<ul className="flex items-center gap-2 2xsm:gap-4">
							<li>
								<WilayahSelection />
							</li>
							{privilege === 10 || privilege === 4 || privilege === 8 ? (
								<li>
									<LokasiSelection />
								</li>
							) : (
								<></>
							)}
							<li>
								<BidangSelection />
							</li>
							<li>
								<TahunSelection />
							</li>
						</ul>
					</div>
				</div>

				<div className="flex items-center gap-3 2xsm:gap-7">
					<ul className="flex items-center gap-2 2xsm:gap-4">
						{/* <!-- Dark Mode Toggler --> */}
						<DarkModeSwitcher />
						{/* <!-- Dark Mode Toggler --> */}

						{/* <!-- Notification Menu Area --> */}
						{/* <DropdownNotification /> */}
						{/* <!-- Notification Menu Area --> */}

						{/* <!-- Chat Notification Area --> */}
						{/* <DropdownMessage /> */}
						{/* <!-- Chat Notification Area --> */}
					</ul>

					{/* <!-- User Area --> */}
					<DropdownUser />
					{/* <!-- User Area --> */}
				</div>
			</div>
		</header>
	);
};

export default Header;
