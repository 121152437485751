import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useMenuAirLimbah } from '@/hooks/menu/useMenu';
import { IPLTData } from '@/types/airLimbah';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TInvIPLTProv } from './TInvIPLTProv';

const TInventarisasiIPLT = () => {
	const cardRef = useRef(null);
	const [data, setData] = useState<IPLTData[] | null>(null);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;
	const formatted_kd_kabkota =
		kd_kabkota_to_use < 10 ? `0${kd_kabkota_to_use}` : kd_kabkota_to_use;
	let rowCounter = 0;
	const kd_wilayah = `${kd_provinsi_to_use}${formatted_kd_kabkota}`;
	const { isMenuAirLimbahProv } = useMenuAirLimbah();
	// console.log(kd_wilayah);

	useEffect(() => {
		fetch(`http://localhost:3000/api/v1/si-insan/iplt`)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				const filteredData = _.filter(data.data.data, (item: IPLTData) => {
					return (
						item.provinsi === String(kd_provinsi_to_use) &&
						item.kabkota === String(kd_wilayah)
					);
				});
				setData(filteredData);
				// console.log(filteredData);
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
	}, [kd_kabkota_to_use, kd_provinsi_to_use]);

	if (isMenuAirLimbahProv) {
		return <TInvIPLTProv />;
	}

	if (data === null) {
		return <CompLoader />;
	}

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader cardRef={cardRef} isLoading={false}>
					KONDISI IPLT JARINGAN PERPIPAAN
					<br />
					Pemenuhan Kebutuhan Pokok Air Minum Sehari-Hari
				</JudulHeader>
				<div className="sticky top-0 flex items-center justify-start py-4 text-center">
					<div className="inline-flex items-center gap-2 text-center font-bold">
						<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
						pada table dibawah ini didapat dari SIINSAN
					</div>
				</div>
				<Table className="w-full table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead
								rowSpan={3}
								className="thead-text border-l text-center"
							>
								No
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Nama IPLT
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Lokasi IPLT <br />
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Kondisi IPLT <br />
								(Beroperasi / Tidak Beroperasi)
							</TableHead>
							<TableHead
								colSpan={2}
								className="thead-text border-b-0 text-center"
							>
								Wilayah Pelayanan
							</TableHead>
							<TableHead
								colSpan={4}
								className="thead-text border-b-0 text-center"
							>
								Akses
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead rowSpan={2} className="thead-text text-center">
								Kecamatan
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Kelurahan / Desa
							</TableHead>
							<TableHead
								colSpan={2}
								className="thead-text border-b-0 text-center"
							>
								Tersedia
							</TableHead>
							<TableHead
								colSpan={2}
								className="thead-text border-b-0 text-center"
							>
								Termanfaatkan
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text text-center">
								(M³/hari)
							</TableHead>
							<TableHead className="thead-text text-center">
								Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								(M³/hari)
							</TableHead>
							<TableHead className="thead-text text-center">
								Unit Rumah
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{data?.map((item: IPLTData, index: number) => {
							rowCounter++;
							return (
								<TableRow key={index}>
									<TableCell className="tbody-text border-b border-l border-r text-center">
										{rowCounter++}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.namaiplt}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.namakabupatenkota}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.status_keberfungsian}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.namakecamatan}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.namakelurahan}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.kapasitas}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.jumlah_pemanfaat_KK} (KK)
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.kapasitas_terpakai}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{/* <Button className="w-full bg-red-400"></Button> */}-
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};

export default TInventarisasiIPLT;
