import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import CardKontak from '@/components/Cards/CardsKontak';
import DefaultLayout from '@/layout/DefaultLayout';
import { FiAlertCircle } from 'react-icons/fi';
const Kontak = () => {
	return (
		<DefaultLayout>
			<Breadcrumb pageHead="Kontak" pageName="Kontak" />
			<div className="sticky top-0 flex items-center justify-start py-4 text-center">
				<div className="inline-flex items-center gap-2 text-center font-bold">
					<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Klik
					nama untuk menghubungi
				</div>
			</div>
			<div className="flex justify-center">
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-3 xl:grid-cols-3 2xl:gap-4">
					<CardKontak
						nama="Leviana Okvianty (Ana)"
						keterangan="PIC Sicalmers"
						href="https://wa.me/6281299192381"
					/>
					<CardKontak
						nama="Fachriah Wibowo (Riri)"
						keterangan="Bidang Air Minum, Sanitasi & perumahan"
						href="https://wa.me/6285715175333"
					/>
					<CardKontak
						nama="Handari Probo Siwi (Siwi)"
						keterangan="Bidang Air Minum, Sanitasi & perumahan"
						href="https://wa.me/6281219193615"
					/>
					<CardKontak
						nama="Pujiati Rahayu (Ayu)"
						keterangan="Bidang Air Minum, Sanitasi & perumahan"
						href="https://wa.me/6281219596919"
					/>
					<CardKontak
						nama="Roubil Ridlo (Obil)"
						keterangan="Terkait Aplikasi"
						href="https://wa.me/6285231113485"
					/>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default Kontak;
