import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

export const useMenuRumahBencana = () => {
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//list menu
	const menuRumahBencanaProv = 7;
	const menuRumahBencanaKab = 3;

	//kondisional menu provinsi
	const isMenuRumahBencanaProv = menuId === menuRumahBencanaProv;

	//kondisional menu kab/kota
	const isMenuRumahBencanaKab = menuId === menuRumahBencanaKab;

	return { isMenuRumahBencanaProv, isMenuRumahBencanaKab };
};

export const useMenuRumahRelokasi = () => {
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//list menu
	const menuRumahRelokasiProv = 8;
	const menuRumahRelokasiKab = 4;

	//kondisional menu provinsi
	const isMenuRumahRelokasiProv = menuId === menuRumahRelokasiProv;

	//kondisional menu kab/kota
	const isMenuRumahRelokasiKab = menuId === menuRumahRelokasiKab;

	return { isMenuRumahRelokasiProv, isMenuRumahRelokasiKab };
};

export const useMenuAirLimbah = () => {
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//list menu
	const menuAirLimbahProv = 6;
	const menuAirLimbahKab = 2;

	//kondisional menu provinsi
	const isMenuAirLimbahProv = menuId === menuAirLimbahProv;

	//kondisional menu kab/kota
	const isMenuAirLimbahKab = menuId === menuAirLimbahKab;

	return { isMenuAirLimbahProv, isMenuAirLimbahKab };
};

export const useMenuAirMinum = () => {
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//list menu
	const menuAirMinumProv = 5;
	const menuAirMinumKab = 1;

	//kondisional menu provinsi
	const isMenuAirMinumProv = menuId === menuAirMinumProv;

	//kondisional menu kab/kota
	const isMenuAirMinumKab = menuId === menuAirMinumKab;

	return { isMenuAirMinumProv, isMenuAirMinumKab };
};
