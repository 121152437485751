import { RootState } from '@/app/store';
import FormDataCapaianALProvinsi from '@/components/Forms/AirLimbah/DataCapaian/FormDataCapaianALProvinsi';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Table, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { useRef, useState } from 'react';
import { FiAlertCircle, FiPlusCircle, FiSave } from 'react-icons/fi';
import { useSelector } from 'react-redux';

type TableCapaianSPALDRegionalProps = {
	// tabId: number;
	// handleTabChange: (e: number) => void;
	tahun: number;
};

const TableCapaianSPALDRegionalRekapitulasi = ({
	// tabId,
	// handleTabChange,
	tahun,
}: TableCapaianSPALDRegionalProps) => {
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);
	const [editMode, setEditMode] = useState(false);
	const cardRef = useRef(null);

	const handleEditMode = () => {
		setEditMode(true);
	};

	// if (editMode)
	// 	return (
	// 		<FormDataCapaianALProvinsi
	// 			tabId={tabId}
	// 			handleTabChange={handleTabChange}
	// 		/>
	// 	);

	return (
		<>
			{/* {privilege !== 4 && privilege !== 8 && (
				<div className="flex justify-end pb-4">
					<div className="flex gap-4">
						{editMode ? (
							<Button
								className="inline-flex items-center justify-center gap-2 rounded-lg bg-green-500 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
								// onClick={handleSimpanData}
							>
								<FiSave />
								Simpan Data Capaian
							</Button>
						) : (
							<Button
								className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
								onClick={handleEditMode}
							>
								<FiPlusCircle />
								Masukan Data Capaian
							</Button>
						)}
					</div>
				</div>
			)} */}
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				{/* <div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 sm:grid-cols-3">
					<div
						className={`py-4 text-center ${
							tabId === 1 ? 'bg-[#fab23d]' : 'bg-[#364878]'
						} flex cursor-pointer flex-col items-center justify-center rounded-md text-white hover:bg-graydark`}
						onClick={() => handleTabChange(1)}
					>
						<p>Capaian SPALD</p>
						<p>Regional</p>
					</div>
					<div
						className={`py-4 text-center ${
							tabId === 2 ? 'bg-[#fab23d]' : 'bg-[#364878]'
						} cursor-pointer rounded-md text-white hover:bg-graydark`}
						onClick={() => handleTabChange(2)}
					>
						Rekapitulasi Capaian SPM <br />
						Kabupaten / Kota
					</div>
					<div
						className={`py-4 text-center ${
							tabId === 3 ? 'bg-[#fab23d]' : 'bg-[#364878]'
						} flex cursor-pointer items-center justify-center rounded-md text-white hover:bg-graydark`}
						onClick={() => handleTabChange(3)}
					>
						<p>Bantuan Keuangan</p>
					</div>
				</div> */}
				<ScrollArea className="relative h-full p-4">
					<JudulHeader cardRef={cardRef} isLoading={false}>
						REKAPITULASI AKSES PELAYANAN AIR LIMBAH <br />
						SPM PERKERJAAN UMUM <br />
						PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK TAHUN{' '}
						{Number(tahun)}
						<br />
						REGIONAL LINTAS KABUPATEN / KOTA
					</JudulHeader>
					<div className="sticky top-0 flex items-center justify-start py-4 text-center">
						<div className="inline-flex items-center gap-2 text-center font-bold">
							<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
							pada table dibawah ini merupakan data dari tahun sebelumnya.
						</div>
					</div>
					<Table className="w-full min-w-[1500px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									rowSpan={3}
									className="thead-text border-l text-center"
								>
									No
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Nama SPALD Regional
								</TableHead>
								<TableHead
									colSpan={3}
									className="thead-text border-b-0 text-center"
								>
									Wilayah Pelayanan
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Target
								</TableHead>
								<TableHead
									colSpan={2}
									className="thead-text border-b-0 text-center"
								>
									Realisasi
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead rowSpan={2} className="thead-text text-center">
									Kabupaten / Kota
								</TableHead>
								<TableHead rowSpan={2} className="thead-text text-center">
									Kecamatan
								</TableHead>
								<TableHead rowSpan={2} className="thead-text text-center">
									Desa
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Total
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Sudah Terlayani
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Belum Terlayani
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text text-center">
									(Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah Tangga)
								</TableHead>
							</TableRow>
						</TableHeader>
					</Table>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};

export default TableCapaianSPALDRegionalRekapitulasi;
