import { useForm } from "react-hook-form";
import { ScrollArea } from "../ui/scroll-area"
import { useGetKabKota, useGetProvinsi } from "@/hooks/wilayah/useLokasi";
import { CompLoader, MiniLoader } from "@/common/Loader";
import { useEffect, useState } from "react";
import { useCreateUser } from "@/hooks/useUser";
import { toast } from "sonner";
import { Eye } from "lucide-react";
import { Button } from "../ui/Button";

interface IFormValues {
  menu_id: number
  bidang_id: number
  unit_id: number
  privilege_id: number
  kd_provinsi: number
  kd_kabkota: number
  username: string
  password: string
  fullname: string
  email: string
  no_hp: string
  nama_opd: string
}

const BikinUser = () => {
  const { watch, handleSubmit, setValue, reset } = useForm<IFormValues>({
    defaultValues: {
      menu_id: 0,
      bidang_id: 0,
      unit_id: 0,
      privilege_id: 0,
      kd_provinsi: 0,
      kd_kabkota: 0,
      username: "",
      password: "",
      fullname: "",
      email: "",
      no_hp: "",
      nama_opd: ""
    }
  })

  const { asyncCreateUser, isPending: pendingCreateUser } = useCreateUser()

  const [lihatPassword, setLihatPassword] = useState(false)

  const menus = [
    {
      menu_id: 1,
      kd_menu: 1,
      nama_menu: "Air Minum",
      jenis_menu: "OPD Teknis Bidang Air Minum"
    },
    {
      menu_id: 2,
      kd_menu: 2,
      nama_menu: "Air Limbah",
      jenis_menu: "OPD Teknis Bidang Sanitasi"
    },
    {
      menu_id: 3,
      kd_menu: 3,
      nama_menu: "Perumahan Bencana",
      jenis_menu: "OPD Teknis Bidang Perumahan"
    },
    {
      menu_id: 4,
      kd_menu: 4,
      nama_menu: "Perumahan Relokasi",
      jenis_menu: ""
    },
    {
      menu_id: 9,
      kd_menu: 0,
      nama_menu: "Perkim Pfid",
      jenis_menu: ""
    },
    {
      menu_id: 10,
      kd_menu: 11,
      nama_menu: "Bappeda Kabupaten",
      jenis_menu: "Bappeda Kab/Kota"
    },
    {
      menu_id: 11,
      kd_menu: 10,
      nama_menu: "Bappeda Provinsi",
      jenis_menu: "Bappeda Provinsi"
    },
    {
      menu_id: 16,
      kd_menu: 30,
      nama_menu: "BPPW",
      jenis_menu: ""
    },
    {
      menu_id: 17,
      kd_menu: 31,
      nama_menu: "BP2P",
      jenis_menu: ""
    },
    {
      menu_id: 333,
      kd_menu: 333,
      nama_menu: "Super Admin",
      jenis_menu: "One For All"
    },
  ]
  const privileges = [
    {
      privilege_id: 1,
      nama_privilege: "Kabupaten Kota",
      kd_privilege: 1
    },
    {
      privilege_id: 2,
      nama_privilege: "Provinsi",
      kd_privilege: 2
    },
    {
      privilege_id: 3,
      nama_privilege: "Bapeda Kabupaten/Kota",
      kd_privilege: 3
    },
    {
      privilege_id: 4,
      nama_privilege: "Bapeda Provinsi",
      kd_privilege: 4
    },
    {
      privilege_id: 5,
      nama_privilege: "Pusat",
      kd_privilege: 5
    },
    {
      privilege_id: 6,
      nama_privilege: "Admin",
      kd_privilege: 6
    },
    {
      privilege_id: 7,
      nama_privilege: "Biro Tata Pemerintah",
      kd_privilege: 7
    },
    {
      privilege_id: 8,
      nama_privilege: "BPPW/BP2P",
      kd_privilege: 7
    },
    {
      privilege_id: 10,
      nama_privilege: "Super",
      kd_privilege: 10
    },
  ]
  const { getProvinsi, isLoading: loadProvinsi } = useGetProvinsi()
  const { getKabKota, isLoading: loadKabKota } = useGetKabKota(watch("kd_provinsi"))

  const onSubmit = async (data: any) => {
    try {
      await asyncCreateUser(data)
      toast.success("Berhasil membuat user")
      reset()
    } catch (error) {
      toast.error("Gagal membuat user")
    }
  }

  const handleShowPassword = () => {
    setLihatPassword(!lihatPassword)
  }

  useEffect(() => {
    setValue("kd_kabkota", 0)
  }, [watch("kd_provinsi")])

  useEffect(() => {
    if (!loadProvinsi) {
      getProvinsi.unshift({ kd_provinsi: 0, nama_provinsi: "" })
    }
  }, [loadProvinsi])

  if (pendingCreateUser) return <CompLoader />

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden">
      <div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
        <h3 className="text-2xl font-medium text-[#364878] dark:text-white">
          Bikin User
        </h3>
      </div>
      <ScrollArea>
        <div className="flex flex-col gap-5.5 p-6.5">
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="provinsi"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Provinsi
              </label>
            </div>
            <div className="text-right">
              <select
                name="provinsi"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                value={String(watch("kd_provinsi"))}
                onChange={(e) => {
                  const selected = e.target.value;
                  setValue("kd_provinsi", Number(selected))
                }}
              >
                <option value="0" disabled hidden>
                  Pilih Provinsi
                </option>
                {!loadProvinsi && [{ kd_provinsi: 0, nama_provinsi: "" }, ...getProvinsi].map((provinsi: any, index: any) => (
                  <option key={index} value={provinsi.kd_provinsi}>
                    {provinsi.nama_provinsi}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="kabupaten"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Kabupaten / Kota
              </label>
            </div>
            <div className="text-right">
              {loadProvinsi ?
                <MiniLoader />
                :
                <select
                  name="kabupaten"
                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                  value={String(watch("kd_kabkota"))}
                  onChange={(e) => {
                    const selected = e.target.value;
                    setValue("kd_kabkota", Number(selected))
                  }}
                >
                  <option value="0" disabled hidden>
                    Pilih Kabupaten / Kota
                  </option>
                  {!loadKabKota && getKabKota.map((kabkota: any, index: any) => (
                    <option key={index} value={kabkota.kd_kabkota}>
                      {kabkota.nama_kabkota}
                    </option>
                  ))}
                </select>
              }
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
            <div className="flex items-end">
              <label
                htmlFor="username"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Username
              </label>
            </div>
            <div className="text-right">
              <input
                id="username"
                name="username"
                value={watch("username")}
                onChange={(e) => setValue("username", e.target.value)}
                type="text"
                placeholder="Masukkan Username"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
            <div className="flex items-end">
              <label
                htmlFor="password"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Password
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                id="password"
                name="password"
                value={watch("password")}
                onChange={(e) => setValue("password", e.target.value)}
                type={lihatPassword ? "text" : "password"}
                placeholder="Masukkan Password"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
              <Button
                className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                onClick={handleShowPassword}
              >
                {lihatPassword ? "Hide Password" : "Lihat Password"}
              </Button>
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
            <div className="flex items-end">
              <label
                htmlFor="full-name"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Nama Lengkap
              </label>
            </div>
            <div className="text-right">
              <input
                id="full-name"
                name="full-name"
                value={watch("fullname")}
                onChange={(e) => setValue("fullname", e.target.value)}
                type="text"
                placeholder="Masukkan Nama Lengkap"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
            <div className="flex items-end">
              <label
                htmlFor="email"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Email
              </label>
            </div>
            <div className="text-right">
              <input
                id="email"
                name="email"
                value={watch("email")}
                onChange={(e) => setValue("email", e.target.value)}
                type="text"
                placeholder="Masukkan Email"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
            <div className="flex items-end">
              <label
                htmlFor="no-hp"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Nomor HP
              </label>
            </div>
            <div className="text-right">
              <input
                id="no-hp"
                name="no-hp"
                value={watch("no_hp")}
                onChange={(e) => setValue("no_hp", e.target.value)}
                type="number"
                placeholder="Masukkan Nomor HP"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
            <div className="flex items-end">
              <label
                htmlFor="nama-opd"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Nama OPD
              </label>
            </div>
            <div className="text-right">
              <input
                id="nama-opd"
                name="nama-opd"
                value={watch("nama_opd")}
                onChange={(e) => setValue("nama_opd", e.target.value)}
                type="text"
                placeholder="Masukkan Nama OPD"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="provinsi"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Pilih Bidang
              </label>
            </div>
            <div className="text-right">
              <select
                name="bidang"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                value={String(watch("menu_id"))}
                onChange={(e) => {
                  const selected = e.target.value;
                  setValue("menu_id", Number(selected))
                }}
              >
                <option value="0" disabled hidden>
                  Pilih Bidang
                </option>
                {menus.map((menu: any, index: any) => (
                  <option key={index} value={menu.menu_id}>
                    {menu.nama_menu}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="privilege"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Pilih Privilege
              </label>
            </div>
            <div className="text-right">
              <select
                name="privilege"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                value={String(watch("privilege_id"))}
                onChange={(e) => {
                  const selected = e.target.value;
                  setValue("privilege_id", Number(selected))
                }}
              >
                <option value="0" disabled hidden>
                  Pilih Privilege
                </option>
                {privileges.map((privilege: any, index: any) => (
                  <option key={index} value={privilege.privilege_id}>
                    {privilege.nama_privilege}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-5 flex justify-between gap-5">
            <div className="w-full">
              <button
                disabled={pendingCreateUser}
                className="inline-flex min-w-full items-center justify-center rounded-md border border-meta-3 px-10 py-4 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
                onClick={() => handleSubmit(onSubmit)()}
              >
                Simpan Data
              </button>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  )
}

export default BikinUser