import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { FiInfo } from 'react-icons/fi';

const GIdentifikasiRumahRelokasi = () => {
    let rowCounter = 0;

    const panduanData = [
        {
            id: 1,
            title: "Jenis Program Relokasi",
            description: "Diisi jenis program relokasi yang dilaksanakan"
        },
        {
            id: 2,
            title: "Kecamatan",
            description: "Diisi nama Kecamatan"
        },
        {
            id: 3,
            title: "Jumlah Rumah",
            description: "Diisi jumlah rumah terdampak di lokasi relokasi"
        },
        {
            id: 4,
            title: "Jumlah KK",
            description: "Diisi jumlah KK yang terdampak di lokasi relokasi"
        },
        {
            id: 5,
            title: "Jumlah Jiwa",
            description: "Diisi jumlah jiwa yang terdampak di lokasi relokasi"
        },
        {
            id: 6,
            title: "Legal",
            description: "Diisi jumlah rumah di atas lahan bukan fungsi permukiman yang status tanahnya legal"
        },
        {
            id: 7,
            title: "Ilegal",
            description: "Diisi jumlah rumah di atas lahan bukan fungsi permukiman yang status tanahnya ilegal"
        },
        {
            id: 8,
            title: "Penggantian Uang",
            description: "Diisi jumlah pemilik rumah yang sudah terlayani dengan 'Penggantian Hak Atas Penguasaan Tanah dan/atau Bangunan - Penggantian Uang'"
        },
        {
            id: 9,
            title: "Pembangunan Kembali",
            description: "Diisi jumlah pemilik rumah yang sudah terlayani dengan 'Penggantian Hak Atas Penguasaan Tanah dan/atau Bangunan - Pembangunan Kembali'"
        },
        {
            id: 8,
            title: "Penggantian Uang",
            description: "Diisi jumlah pemilik rumah yang sudah terlayani dengan 'Penggantian Hak Atas Penguasaan Tanah dan/atau Bangunan - Penggantian Uang'"
        },
        {
            id: 9,
            title: "Pembangunan Kembali",
            description: "Diisi jumlah pemilik rumah yang sudah terlayani dengan 'Penggantian Hak Atas Penguasaan Tanah dan/atau Bangunan - Pembangunan Kembali'"
        },
        {
            id: 10,
            title: "Subsidi Uang Sewa",
            description: "Diisi jumlah pemilik rumah yang sudah terlayani dengan 'Bantuan Akses Rumah Sewa Layak Huni - Subsidi Uang Sewa'"
        },
        {
            id: 11,
            title: "Akses Tinggal Rumah Sewa",
            description: "Diisi jumlah pemilik rumah yang sudah terlayani dengan 'Bantuan Akses Rumah Sewa Layak Huni - Akses Tinggal Rumah Sewa Layak Huni milik Pemerintah Daerah'"
        },
        {
            id: 12,
            title: "Belum Terlayani",
            description: "Diisi jumlah pemilik rumah yang belum terlayani"
        }
        ];
        
    

    return (
			<>
				<Dialog>
					<DialogTrigger asChild>
						<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-green-600 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiInfo />
							Panduan
						</Button>
					</DialogTrigger>
					<DialogContent className="z-99999 max-h-[85%] max-w-[65%] overflow-auto rounded-lg">
						<DialogHeader>
							<DialogTitle>
								Panduan Pengisian Form Identifikasi Perumahan Yang Terkena
								Relokasi
							</DialogTitle>
							<DialogDescription>
								Silahkan ikuti panduan dibawah ini untuk melakukan pengisian
								form atau input data
							</DialogDescription>
						</DialogHeader>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="border-l text-center">No</TableHead>
									<TableHead className="text-center">Kolom</TableHead>
									<TableHead className="text-center">Keterangan</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{panduanData.map((data) => (
									<TableRow
										key={data.id}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="text-start">{data.title}</TableCell>
										<TableCell className="text-start">
											{data.description}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<DialogFooter>
							<div className="mt-5 flex w-full items-center justify-end">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Tutup
									</Button>
								</DialogClose>
							</div>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</>
		);
};

export default GIdentifikasiRumahRelokasi;
