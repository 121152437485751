import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogFooter,
	DialogClose,
	Dialog,
	DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useSelector } from 'react-redux';
import { FormEvent, useEffect, useState } from 'react';
import { FiEdit, FiPlusCircle } from 'react-icons/fi';
import { useQueryClient } from '@tanstack/react-query';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import {
	useEditSPALDProv,
	usePostInvSPALDProv,
} from '@/hooks/airLimbah/useInvSarana';
import KabKotaSelectUI from '@/components/ui/SelectKabKotaUI';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import DesaSelectUI from '@/components/ui/SelectDesaUI';
import { Label } from '@/components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { FormControl } from '@/components/ui/form';
import { toast } from 'sonner';
import { useEditAPBDPerProv } from '@/hooks/useAPBDPerProv';
import EditKabKotaSelectUI from '@/components/ui/SelectEditKabupatenKotaUI';
import EditKecamatanSelectUI from '@/components/ui/SelectEditKecamatan';
import EditDesaSelectUI from '@/components/ui/SelectEditDesa';

type FInvSaranaPengangkutanProps = {
	rowData: any;
};

export const EInvSPALDProv: React.FC<FInvSaranaPengangkutanProps> = ({
	rowData,
}) => {
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kabkota, setKd_kabkota] = useState<number>(rowData.kd_kabkota);
	const [kd_kec, setKd_kec] = useState<number>(rowData.kd_kec);
	const [kd_desa, setKd_desa] = useState<number>(rowData.kd_desa);

	//get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
	const { getKecamatan } = useGetKecamatan(user.kd_provinsi, kd_kabkota);
	const { getDesa, refetch: fetchDesa } = useGetDesa(
		user.kd_provinsi,
		kd_kabkota,
		kd_kec,
	);
	const { editSPALDProv } = useEditSPALDProv();

	// data form
	const [nama_spald_regional, setNama_spald_regional] = useState(
		rowData.nama_spald_regional,
	);
	const [lokasi_spald_regional, setLokasi_spald_regional] = useState(
		rowData.lokasi_spald_regional,
	);
	const [kondisiOperasi, setKondisiOperasi] = useState(rowData.kondisi);
	const [tersedia_kapasitas, setTersediaKapasitas] = useState(
		rowData.tersedia_m3,
	);
	const [tersedia_rumah, setTersediaRumah] = useState(rowData.tersedia_rumah);
	const [termanfaatkan_kapasitas, setTermanfaatkanKapasitas] = useState(
		rowData.termanfaatkan_m3,
	);
	const [termanfaatkan_rumah, setTermanfaatkanRumah] = useState(
		rowData.termanfaatkan_rumah,
	);

	const beroperasi: { [key: string]: number } = {
		Beroperasi: 1,
		Tidak: 2,
	};

	// additionals
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		const data = {
			id_prov_spald: rowData.id_prov_spald,
			tahun_pelaporan: Number(tahun),
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: kd_kabkota,
			kd_kec: kd_kec,
			kd_desa: kd_desa,
			nama_spald_regional: nama_spald_regional,
			lokasi_spald_regional: lokasi_spald_regional,
			kondisi: kondisiOperasi,
			tersedia_m3: parseInt(tersedia_kapasitas),
			tersedia_rumah: parseInt(tersedia_rumah),
			termanfaatkan_m3: parseInt(termanfaatkan_kapasitas),
			termanfaatkan_rumah: parseInt(termanfaatkan_rumah),
		};

		editSPALDProv(data, {
			onSuccess: () => {
				queryClient.refetchQueries({ queryKey: ['inv-spald'] });
				setModalOpen(false);
			},
			onError: (error) => {
				console.log(error);
			},
		});

		// console.log('ini data edit', data);
	};

	useEffect(() => {
		fetchDesa();
		// fetchKecamatan();
	}, [kd_kec, kd_kabkota]);

	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button
						size={'sm'}
						className="inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600"
					>
						<FiEdit />
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 mx-auto max-w-screen-xl">
					<DialogHeader>
						<DialogTitle>Tambah Data Invetaris SPALD Provinsi</DialogTitle>
						<DialogDescription>
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>

					<form action="post" onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Kabupaten" className="mb-2">
									Kabupaten
								</Label>
								<EditKabKotaSelectUI
									getKabKota={getKabKota}
									setKabKota={setKd_kabkota}
									selectedValue={kd_kabkota}
									disabledSelect={false}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kecamatan" className="mb-2">
									Kecamatan
								</Label>
								<EditKecamatanSelectUI
									getKecamatan={getKecamatan}
									setKd_kec={setKd_kec}
									selectedValue={kd_kec}
									disabledSelect={false}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kelurahan/Desa" className="mb-2">
									Kelurahan/Desa
								</Label>
								<EditDesaSelectUI
									getDesa={getDesa}
									setKd_desa={setKd_desa}
									selectedValue={kd_desa}
									kd_kec={kd_kec}
									disabledSelect={false}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Nama SPALD" className="mb-2">
									Nama SPALD Regional
								</Label>
								<Input
									id="Nama SPALD"
									type="text"
									className="col-span-1"
									value={nama_spald_regional}
									onChange={(e) => setNama_spald_regional(e.target.value)}
									placeholder="Masukkan Nama SPALD"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Lokasi SPALD" className="mb-2">
									Lokasi SPALD
								</Label>
								<Input
									id="Lokasi SPALD"
									type="text"
									className="col-span-1"
									value={lokasi_spald_regional}
									onChange={(e) => setLokasi_spald_regional(e.target.value)}
									placeholder="Masukkan Lokasi SPALD"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kondisi Operasional" className="mb-2">
									Kondisi Operasional
								</Label>
								<Select onValueChange={(value) => setKondisiOperasi(value)}>
									<SelectTrigger>
										<SelectValue placeholder="Pilih Kondisi Operasional" />
									</SelectTrigger>
									<SelectContent className="z-99999">
										<SelectItem value="Beroperasi">Beroperasi</SelectItem>
										<SelectItem value="Tidak Beroperasi">
											Tidak Beroperasi
										</SelectItem>
									</SelectContent>
								</Select>
							</div>
						</div>
						{/* Akses Tersedia */}
						<h1 className="text-l mt-4 font-bold">Tersedia</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas" className="mb-2">
									Kapasitas
								</Label>
								<Input
									id="Kapasitas"
									type="text"
									className="col-span-1"
									value={tersedia_kapasitas}
									onChange={(e) => setTersediaKapasitas(e.target.value)}
									placeholder="Masukkan Status Hak Kapasitas"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Unit" className="mb-2">
									Unit
								</Label>
								<Input
									id="Unit"
									type="text"
									className="col-span-1"
									value={tersedia_rumah}
									onChange={(e) => setTersediaRumah(e.target.value)}
									placeholder="Masukkan Status Unit"
								/>
							</div>
						</div>
						{/* Akses Termanfaatkan */}
						<h1 className="text-l mt-4 font-bold">Termanfaatkan</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas" className="mb-2">
									Kapasitas
								</Label>
								<Input
									id="Kapasitas"
									type="text"
									className="col-span-1"
									value={termanfaatkan_kapasitas}
									onChange={(e) => setTermanfaatkanKapasitas(e.target.value)}
									placeholder="Masukkan Status Hak Kapasitas"
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Unit" className="mb-2">
									Unit
								</Label>
								<Input
									id="Unit"
									type="text"
									className="col-span-1"
									value={termanfaatkan_rumah}
									onChange={(e) => setTermanfaatkanRumah(e.target.value)}
									placeholder="Masukkan Status Unit"
								/>
							</div>
						</div>
						<DialogFooter>
							<div className="mt-5 flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};
