import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import ActionButtonPengumpulanDataRelokasi from '@/components/ActionButton/RumahRelokasi/PengumpulanData/ABPengumpulanDataRelokasi';
import FPengumpulanDataRumahRelokasi from '@/components/Forms/RumahRelokasi/PengumpulanData/Input/FPengumpulanDataRumahRelokasi';
import GIdentifikasiPengumpulanDataRumahRelokasi from '@/components/Guides/RumahRelokasi/PengumpulanData/GIdentifikasiPengumpulanDataRumahRelokasi';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useMenuRumahRelokasi } from '@/hooks/menu/useMenu';
import {
	useDeletePengumpulanDataRelokasi,
	usePengumpulanDataRelokasi,
} from '@/hooks/perumahan/useRumahRelokasi';
import { usePrivilegeRumahRelokasi } from '@/hooks/privilege/usePrivilege';
import { IdentifikasiDataPengumpulanRumahRelokasi } from '@/types/perumahan';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

const TPengumpulanDataRumahRelokasi = () => {
	const selectedTahun = useSelector((state: RootState) => state.tahun.value);
	const tahun = Number(selectedTahun);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const QueryClient = useQueryClient();

	//hook previlege
	const {
		isPrevilegeViewRumahRelokasi,
		kd_kabkota_to_use,
		kd_provinsi_to_use,
	} = usePrivilegeRumahRelokasi();

	//hook menu
	const { isMenuRumahRelokasiProv, isMenuRumahRelokasiKab } =
		useMenuRumahRelokasi();

	//getData
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		usePengumpulanDataRelokasi(
			kd_provinsi_to_use,
			kd_kabkota_to_use,
			tahun,
			menuId,
		);

	//delete
	const { deletePengumpulanDataRelokasi } = useDeletePengumpulanDataRelokasi();

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	// Access the data
	const pages = data?.pages || [];

	const handleEdit = (rowData: IdentifikasiDataPengumpulanRumahRelokasi) => {
		console.log('Edit data:', rowData);
	};

	const handleDelete = async (
		rowData: IdentifikasiDataPengumpulanRumahRelokasi,
	) => {
		try {
			await deletePengumpulanDataRelokasi({
				id_relokasi_pengumpulan: rowData.id_relokasi_pengumpulan,
			});
			QueryClient.refetchQueries({
				queryKey: ['relokasi-pengumpulan'],
			});
			toast.success('Berhasil menghapus data');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus data');
		}
	};

	let rowCounter = 0;
	const cardRef = useRef(null);
	if (isLoading) return <CompLoader />;
	return (
		<div
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			ref={cardRef}
		>
			{isPrevilegeViewRumahRelokasi ? (
				<></>
			) : (
				<div className="my-2 mr-4 flex justify-end gap-4">
					<GIdentifikasiPengumpulanDataRumahRelokasi />
					<FPengumpulanDataRumahRelokasi />
				</div>
			)}

			<ScrollArea className="h-full p-4">
				<JudulHeader isLoading={isLoading} cardRef={cardRef}>
					PENGUMPULAN DATA RUMAH YANG TERKENA BENCANA KABUPATEN/KOTA
					<br />
					SPM PERUMAHAN RAKYAT
					<br />
					{isMenuRumahRelokasiProv ? (
						<>
							Fasilitas Penyediaan Rumah Yang Layak Huni Bagi Masyarakat Yang
							Terkena Relokasi Program Pemerintah Daerah Provinsi
						</>
					) : (
						<>
							Fasilitas Penyediaan Rumah Yang Layak Huni Bagi Masyarakat Yang
							Terkena Relokasi Program Pemerintah Daerah Kabupaten/Kota
						</>
					)}
				</JudulHeader>
				<Table className="w-full min-w-[1800px] table-auto">
					<TableHeader className="sticky top-1 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead className="thead-text border-l" rowSpan={2}>
								No
							</TableHead>
							<TableHead className="thead-text text-center" rowSpan={2}>
								Jenis Relokasi Program
							</TableHead>
							{isMenuRumahRelokasiProv && (
								<TableHead className="thead-text text-center" rowSpan={2}>
									Kabupaten/Kota
								</TableHead>
							)}
							<TableHead className="thead-text text-center" rowSpan={2}>
								Kecamatan
							</TableHead>
							{isMenuRumahRelokasiKab && (
								<TableHead className="thead-text text-center" rowSpan={2}>
									Kelurahan/Desa
								</TableHead>
							)}
							<TableHead className="thead-text text-center" rowSpan={2}>
								Jumlah Rumah
							</TableHead>
							<TableHead className="thead-text text-center" rowSpan={2}>
								Jumlah KK
							</TableHead>
							<TableHead className="thead-text text-center" rowSpan={2}>
								Jumlah Jiwa
							</TableHead>
							<TableHead className="thead-text text-center" colSpan={2}>
								Legalitas Tanah
							</TableHead>
							<TableHead className="thead-text text-center" colSpan={3}>
								STATUS KEPEMILIKAN RUMAH
							</TableHead>
							{isPrevilegeViewRumahRelokasi ? (
								<></>
							) : (
								<TableHead className="thead-text text-center" rowSpan={2}>
									Aksi (Edit/Hapus)
								</TableHead>
							)}
						</TableRow>
						<TableRow>
							<TableHead className="thead-text border-t-0 text-center">
								Legal
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Illegal
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Milik
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Sewa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Lainnya
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{pages.map((page) =>
							page.data.map(
								(item: IdentifikasiDataPengumpulanRumahRelokasi) => (
									<TableRow
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="tbody-text border-b border-l border-r text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jenis_relokasi.nama_jenis_relokasi}
										</TableCell>
										{isMenuRumahRelokasiProv && (
											<TableCell className="tbody-text border-b border-r text-center">
												{item.wilayah.nama_kabkota}
											</TableCell>
										)}
										<TableCell className="tbody-text border-b border-r text-center">
											{item.wilayah.nama_kecamatan}
										</TableCell>
										{isMenuRumahRelokasiKab && (
											<TableCell className="tbody-text border-b border-r text-center">
												{item.wilayah.nama_desa}
											</TableCell>
										)}
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_rumah}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.legal}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.ilegal}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_milik}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_sewa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_lainnya}
										</TableCell>
										{isPrevilegeViewRumahRelokasi ? (
											<></>
										) : (
											<TableCell className="tbody-text flex justify-center border-b border-r text-center">
												<ActionButtonPengumpulanDataRelokasi
													rowData={item}
													onEdit={handleEdit}
													onDelete={() => handleDelete(item)}
												/>
											</TableCell>
										)}
									</TableRow>
								),
							),
						)}
					</TableBody>
				</Table>
				<ScrollBar orientation="horizontal" />
				{/* <div className="text-center my-2" ref={ref}>
					{isFetchingNextPage
						? 'Muat Lebih Banyak Data...'
						: hasNextPage
							? 'Muat Lebih Banyak Data...'
							: 'Tidak Ada Data Lagi'}
				</div> */}
			</ScrollArea>
		</div>
	);
};

export default TPengumpulanDataRumahRelokasi;
