import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useGetNomen } from '@/hooks/useNomenklatur';
import { usePostNewRencanaPemenuhan } from '@/hooks/rencanaPelaksanaan/useRencanaPemenuhan';
import { useSumberAnggaran } from '@/hooks/useKomponen';
import { formatRupiah } from '@/libs/utils';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import { useForm } from 'react-hook-form';
import { CompLoader } from '@/common/Loader';
import { FiAlertCircle } from 'react-icons/fi';

type FormValues = {
	tahun_rencana: number
	menu_id: number
	kd_provinsi: number
	kd_kabkota: number
	kd_kec: number
	kd_desa: number
	nomenklatur_id: number
	sumber_anggaran_id: number
	rencana_anggaran: number
	jenis_kegiatan: string
	nama_kegiatan: string
	catatan: string
}

const FormRencanaPemenuhan = ({ setShowForm }: { setShowForm: any }) => {
	const bidang = useSelector((state: RootState) => state.bidang.value);
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value)
	const menu_id = useSelector((state: RootState) => state.menuId.value);
	const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
	const kd_kabkota_dux = useSelector((state: RootState) => state.location.kd_kabkota_dux)

	const { postRencanaPemenuhan, isPending } = usePostNewRencanaPemenuhan()

	const { watch, handleSubmit, setValue, reset } = useForm<FormValues>({
		defaultValues: {
			tahun_rencana: Number(tahun),
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: user.kd_kabkota,
			kd_kec: 0,
			kd_desa: 0,
			menu_id: menu_id,
			nomenklatur_id: 0,
			sumber_anggaran_id: 0,
			rencana_anggaran: 0,
			catatan: ""
		}
	})

	const kd_kabkota_to_use = privilege === 4 ? kd_kabkota_dux : privilege === 2 ? watch("kd_kabkota") : user.kd_kabkota

	const { getKabKota, isLoading: loadKabKota } = useGetKabKota(user.kd_provinsi)

	const { getKecamatan, isLoading: loadKec } = useGetKecamatan(
		user.kd_provinsi,
		kd_kabkota_to_use
	);
	const {
		getDesa,
		isLoading: loadDesa,
	} = useGetDesa(user.kd_provinsi, kd_kabkota_to_use, watch("kd_kec"));

	const { getNomen, isLoading: loadNomen } = useGetNomen(menu_id);

	const { getSumberAnggaran, isLoading: loadSumber } = useSumberAnggaran();

	const handleAnggaranChange = (e: any) => {
		const value = e.target.value;
		const numericValue = parseFloat(value.replace(/\D/g, ''));
		setValue("rencana_anggaran", isNaN(numericValue) ? 0 : numericValue)
	};

	const onSubmit = async (data: any) => {
		try {
			await postRencanaPemenuhan(data);
		} catch (error) {
			console.log(error)
		} finally {
			reset()
			setShowForm("Lihat Data")
		}
	};

	if (isPending) return <CompLoader />

	return (
		<>
			<div className="sticky top-0 flex items-center justify-start py-4 text-center">
				<div className="inline-flex items-center gap-2 text-center font-bold">
					<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Bisa memilih Komponen setelah penambahan data Rencana Pemenuhan
				</div>
			</div>
			<div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden">
				<div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
					<h3 className="text-2xl font-medium text-[#364878] dark:text-white">
						Form Tambah Rencana Pemenuhan{' '}
						{bidang === 'air-minum'
							? 'Air Minum'
							: bidang === 'air-limbah'
								? 'Air Limbah'
								: bidang === 'rumah-bencana'
									? 'Rumah Bencana'
									: bidang === 'rumah-relokasi'
										? 'Rumah Relokasi'
										: ''}
					</h3>
				</div>
				<ScrollArea>
					<div className="flex flex-col gap-5.5 p-6.5">
						{/* <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label
									htmlFor="tahun"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Tahun
								</label>
							</div>
							<div className="text-right">
								<input
									id="tahun"
									disabled
									name="tahun_rencana"
									type="text"
									value={watch("tahun_rencana")}
									placeholder="Masukan Tahun rencana"
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div> */}
						{privilege === 2 &&
							<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
								<div className="flex items-end">
									<label
										htmlFor="kecamatan"
										className="text-slate mb-3 block font-medium dark:text-white"
									>
										Kabupaten / Kota
									</label>
								</div>
								<div className="text-right">
									<select
										className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
										value={String(watch("kd_kabkota"))}
										onChange={(e) => {
											const selected = e.target.value;
											setValue("kd_kabkota", Number(selected))
										}}
									>
										<option value="0" disabled hidden>
											Pilih Kabupaten / Kota
										</option>
										{!loadKabKota && getKabKota.map((kabkota: any, index: any) => (
											<option key={index} value={kabkota.kd_kabkota}>
												{kabkota.nama_kabkota}
											</option>
										))}
									</select>
								</div>
							</div>
						}
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label
									htmlFor="kecamatan"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Kecamatan
								</label>
							</div>
							<div className="text-right">
								<select
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
									onChange={(e) => {
										const selected = e.target.value;
										setValue("kd_kec", Number(selected));
									}}
									defaultValue={String(watch("kd_kec"))}
								>
									<option value="0" disabled hidden>
										Pilih Kecamatan
									</option>
									{!loadKec && getKecamatan.map((kecamatan: any, index: any) => (
										<option key={index} value={kecamatan.kd_kec}>
											{kecamatan.nama_kecamatan}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label
									htmlFor="desa"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Kelurahan/desa
								</label>
							</div>
							<div className="text-right">
								<select
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
									defaultValue={String(watch("kd_desa"))}
									onChange={(e) => {
										const selectedDesa = e.target.value;
										setValue("kd_desa", Number(selectedDesa))
									}}
								>
									<option value="0" disabled hidden>
										Pilih Kelurahan / Desa
									</option>
									{!loadDesa && getDesa.map((desa: any, index: any) => (
										<option key={index} value={desa.kd_desa}>
											{desa.nama_desa}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label
									htmlFor="nomenklatur"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Nomenklatur
								</label>
							</div>
							<div className="text-right">
								<select
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
									onChange={(e) => {
										const selectedNomen = getNomen.find(
											(nomen: any) =>
												String(nomen.id_nomenklatur) === e.target.value,
										);
										setValue("jenis_kegiatan", selectedNomen ? selectedNomen.jenis_kegiatan : '')
										setValue("nomenklatur_id", selectedNomen.id_nomenklatur)
									}}
									defaultValue={String(watch("nomenklatur_id"))}
								>
									<option value="0" disabled hidden>
										Pilih Nomenklatur
									</option>
									{!loadNomen && getNomen.map((nomen: any, index: any) => (
										<option key={index} value={nomen.id_nomenklatur}>
											{nomen.nama_nomenklatur}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label
									htmlFor="jenis-kegiatan"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Jenis Kegiatan
								</label>
							</div>
							<div className="text-right">
								<input
									disabled
									id="jenis-kegiatan"
									name="jenis-kegiatan"
									type="text"
									value={watch("jenis_kegiatan")}
									onChange={(e) => setValue("jenis_kegiatan", String(e.target.value))}
									placeholder="New Value"
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
							<div className="flex items-end">
								<label
									htmlFor="nama-kegiatan"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Nama Kegiatan
								</label>
							</div>
							<div className="text-right">
								<input
									id="nama-kegiatan"
									name="nama-kegiatan"
									value={watch("nama_kegiatan")}
									onChange={(e) => setValue("nama_kegiatan", e.target.value)}
									type="text"
									placeholder="Masukkan nama kegiatan"
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label
									htmlFor="sumber-anggaran"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Sumber Anggaran
								</label>
							</div>
							<div className="text-right">
								<select
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
									onChange={(e) => {
										setValue("sumber_anggaran_id", Number(e.target.value))
									}}
									defaultValue={watch("sumber_anggaran_id")}
								>
									<option value="0" disabled hidden>
										Pilih Sumber Anggaran
									</option>
									{!loadSumber && getSumberAnggaran.map((sumber: any, index: any) => (
										<option key={index} value={sumber.id_sumber_anggaran}>
											{sumber.sumber_anggaran}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label
									htmlFor="anggaran"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Anggaran
								</label>
							</div>
							<div className="text-right">
								<input
									id="anggaran"
									name="anggaran"
									value={formatRupiah(Number(watch("rencana_anggaran")))}
									onChange={handleAnggaranChange}
									type="text"
									placeholder="Masukkan jumlah anggaran"
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="mt-5 flex justify-between gap-5">
							<div className="w-full">
								<button
									className="inline-flex min-w-full items-center justify-center rounded-md border border-meta-3 px-10 py-4 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
									onClick={() => handleSubmit(onSubmit)()}
								>
									Simpan Data
								</button>
							</div>
						</div>
					</div>
				</ScrollArea>
			</div>
		</>
	);
};
export default FormRencanaPemenuhan;
