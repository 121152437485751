import React from 'react';

interface CardKontakProps {
	nama: string;
	keterangan: string;
	href: string;
}

const CardKontak: React.FC<CardKontakProps> = ({ nama, keterangan, href }) => {
	return (
		<>
			<div className="border-gray-300 relative flex h-50 transform items-center justify-center overflow-hidden rounded-lg border bg-white px-8 py-6 shadow-lg transition-transform hover:scale-105 dark:border-strokedark dark:bg-gradient-to-r dark:from-[#2d3748] dark:to-[#4a5568]">
				<div className="w-full text-left">
					<h5 className="mb-2 text-2xl font-bold text-[#364878] dark:text-white">
						Nama:
						<a
							href={href}
							className="text-blue-600 hover:text-blue-800 hover:underline"
							target="_blank"
							rel="noopener noreferrer"
						>
							{nama}
						</a>
					</h5>
					<p className="text-gray-800 dark:text-gray-300 text-base">
						{keterangan}
					</p>
				</div>
			</div>
		</>
	);
};

export default CardKontak;
