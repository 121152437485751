import { RootState } from "@/app/store"
import Breadcrumb from "@/components/Breadcrumbs/Breadcrumb"
import TableDataCapaianTahunanAllBidang from "@/components/Tables/TableDataCapaianTahunanAllBidang"
import DefaultLayout from "@/layout/DefaultLayout"
import { useSelector } from "react-redux"

const DataCapaianTahunanAL = () => {
  const wilayah = useSelector((state: RootState) => state.wilayah.value)
  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead={`Data Capaian Tahunan Air Limbah ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
        pageName={`Data Capaian Tahunan Air Limbah ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
      />
      <TableDataCapaianTahunanAllBidang />
    </DefaultLayout>
  )
}

export default DataCapaianTahunanAL