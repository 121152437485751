import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import SumberAnggaranSelectUI from '@/components/ui/SelectSumberAnggaranUI';
import { FormEvent, useState } from 'react';
import { useSumberAnggaran } from '@/hooks/useSumberAnggaran';
import JenisPelayananSelectUI from '@/components/ui/SelectJenisPelayananUI';
import { useJenisPelayanan } from '@/hooks/useJenisPelayanan';
import { usePostTargetPenyelesaian } from '@/hooks/perumahan/useRumahBencana';
import { useQueryClient } from '@tanstack/react-query';
import { formatRupiah } from '@/libs/utils';


const FTargetPenyelesaian = () => {
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//get
	const { getSumberAnggaran } = useSumberAnggaran();
	const { getJenisPelayanan } = useJenisPelayanan();
	const { postTargetPenyelesaian } = usePostTargetPenyelesaian();

	//dataform
	const [jenisPelayanan, setJenisPelayanan] = useState('');
	const [jumlahRumahTangga, setJumlahRumahTangga] = useState('');
	const [jumlahRumahTangga1, setJumlahRumahTangga1] = useState('');
	const [jumlahRumahTangga2, setJumlahRumahTangga2] = useState('');
	const [jumlahRumahTangga3, setJumlahRumahTangga3] = useState('');
	const [kebutuhanBiaya, setKebutuhanBiaya] = useState('');
	const [kebutuhanBiaya1, setKebutuhanBiaya1] = useState('');
	const [kebutuhanBiaya2, setKebutuhanBiaya2] = useState('');
	const [kebutuhanBiaya3, setKebutuhanBiaya3] = useState('');
	const [sumberAnggaran1, setSumberAnggaran1] = useState('');
	const [sumberAnggaran2, setSumberAnggaran2] = useState('');
	const [sumberAnggaran3, setSumberAnggaran3] = useState('');
	const [kebutuhanBiayaDisplay, setKebutuhanBiayaDisplay] = useState('');
	const [kebutuhanBiaya1Display, setKebutuhanBiaya1Display] = useState('');
	const [kebutuhanBiaya2Display, setKebutuhanBiaya2Display] = useState('');
	const [kebutuhanBiaya3Display, setKebutuhanBiaya3Display] = useState('');

	//additional
	const queryClient = useQueryClient();
	const [modalOpen, setModalOpen] = useState(false);

	//resetForm
	const resetForm = () => {
		setJenisPelayanan('');
		setJumlahRumahTangga('');
		setJumlahRumahTangga1('');
		setJumlahRumahTangga2('');
		setJumlahRumahTangga3('');
		setKebutuhanBiaya('');
		setKebutuhanBiaya1('');
		setKebutuhanBiaya2('');
		setKebutuhanBiaya3('');
		setSumberAnggaran1('');
		setSumberAnggaran2('');
		setSumberAnggaran3('');
		setKebutuhanBiayaDisplay('');
		setKebutuhanBiaya1Display('');
		setKebutuhanBiaya2Display('');
		setKebutuhanBiaya3Display('');
	};

	const handleKebutuhanBiayaChange =
		(setter: any, displaySetter: any) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			const numericValue = parseInt(value.replace(/[^0-9]/g, ''), 10);

			if (isNaN(numericValue)) {
				setter(0);
				displaySetter('Rp 0');
			} else {
				setter(numericValue);
				displaySetter(formatRupiah(numericValue));
			}
		};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		const data = {
			tahun_pelaporan: tahun,
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: user.kd_kabkota,
			jenis_pelayanan_id: parseInt(jenisPelayanan),
			tgt_jml_rmh: parseInt(jumlahRumahTangga),
			jml_rmh_1: parseInt(jumlahRumahTangga1),
			jml_rmh_2: parseInt(jumlahRumahTangga2),
			jml_rmh_3: parseInt(jumlahRumahTangga3),
			kebutuhan_biaya: parseInt(kebutuhanBiaya),
			kebutuhan_biaya_1: parseInt(kebutuhanBiaya1),
			kebutuhan_biaya_2: parseInt(kebutuhanBiaya2),
			kebutuhan_biaya_3: parseInt(kebutuhanBiaya3),
			sumber_dana_id_1: parseInt(sumberAnggaran1),
			sumber_dana_id_2: parseInt(sumberAnggaran2),
			sumber_dana_id_3: parseInt(sumberAnggaran3),
		};

		postTargetPenyelesaian(data, {
			onSuccess: () => {
				setModalOpen(false);
				queryClient.refetchQueries({ queryKey: ['eksis-bencana-target'] });
				resetForm();
			},
			onError: (error) => {
				console.log(error);
			},
		});

		console.log(data);
	};

	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
						<FiPlusCircle />
						Input Data
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 mx-auto my-2 max-w-screen-xl">
					<DialogHeader>
						<DialogTitle>
							Tambah Data Target Penyelesaian Rumah Korban Bencana
						</DialogTitle>
						<DialogDescription className="my-1">
							Jika Belum Memiliki Data, Silahkan Isi Dengan Angka 0 Agar Bisa Di
							Simpan
							<br />
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>
					<form action="post" onSubmit={handleSubmit}>
						<div className="grid grid-cols-3 gap-2">
							<div className="flex flex-col">
								<Label htmlFor="Jenis Pelayanan" className="mb-2">
									Jenis Pelayanan
								</Label>
								<JenisPelayananSelectUI
									getJenisPelayanan={getJenisPelayanan}
									setJenisPelayanan={setJenisPelayanan}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah Tangga" className="mb-2">
									Jumlah Rumah Tangga
								</Label>
								<Input
									id="Jumlah Rumah Tangga"
									type="number"
									className="col-span-1"
									value={jumlahRumahTangga}
									onChange={(e) => setJumlahRumahTangga(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Tangga"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kebutuhan Biaya" className="mb-2">
									Kebutuhan Biaya
								</Label>
								<Input
									id="Kebutuhan Biaya"
									type="text"
									className="col-span-1"
									value={kebutuhanBiayaDisplay}
									onChange={handleKebutuhanBiayaChange(
										setKebutuhanBiaya,
										setKebutuhanBiayaDisplay,
									)}
									placeholder="Masukkan Kebutuhan Biaya"
									required={true}
								/>
							</div>
						</div>
						{/* Target Tahun ke-1 */}
						<h1 className="text-l mt-4 font-bold">Target Tahun ke-1</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah Tangga" className="mb-2">
									Jumlah Rumah Tangga ke-1
								</Label>
								<Input
									id="Jumlah Rumah Tangga"
									type="number"
									className="col-span-1"
									value={jumlahRumahTangga1}
									onChange={(e) => setJumlahRumahTangga1(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Tangga"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kebutuhan Biaya" className="mb-2">
									Kebutuhan Biaya ke-1
								</Label>
								<Input
									id="Kebutuhan Biaya"
									type="text"
									className="col-span-1"
									value={kebutuhanBiaya1Display}
									onChange={handleKebutuhanBiayaChange(
										setKebutuhanBiaya1,
										setKebutuhanBiaya1Display,
									)}
									placeholder="Masukkan Kebutuhan Biaya"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Sumber Pembiayaan" className="mb-2">
									Sumber Pembiayaan Tahun ke-1
								</Label>
								<SumberAnggaranSelectUI
									getSumberAnggaran={getSumberAnggaran}
									setSumberAnggaran={setSumberAnggaran1}
								/>
							</div>
						</div>
						{/* Target Tahun ke-2 */}
						<h1 className="text-l mt-4 font-bold">Target Tahun ke-2</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah Tangga" className="mb-2">
									Jumlah Rumah Tangga ke-2
								</Label>
								<Input
									id="Jumlah Rumah Tangga"
									type="number"
									className="col-span-1"
									value={jumlahRumahTangga2}
									onChange={(e) => setJumlahRumahTangga2(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Tangga"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kebutuhan Biaya" className="mb-2">
									Kebutuhan Biaya ke-2
								</Label>
								<Input
									id="Kebutuhan Biaya"
									type="text"
									className="col-span-1"
									value={kebutuhanBiaya2Display}
									onChange={handleKebutuhanBiayaChange(
										setKebutuhanBiaya2,
										setKebutuhanBiaya2Display,
									)}
									placeholder="Masukkan Kebutuhan Biaya"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Sumber Pembiayaan" className="mb-2">
									Sumber Pembiayaan Tahun ke-2
								</Label>
								<SumberAnggaranSelectUI
									getSumberAnggaran={getSumberAnggaran}
									setSumberAnggaran={setSumberAnggaran2}
								/>
							</div>
						</div>
						{/* Target Tahun ke-3 */}
						<h1 className="text-l mt-4 font-bold">Target Tahun ke-3</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah Tangga" className="mb-2">
									Jumlah Rumah Tangga ke-3
								</Label>
								<Input
									id="Jumlah Rumah Tangga"
									type="number"
									className="col-span-1"
									value={jumlahRumahTangga3}
									onChange={(e) => setJumlahRumahTangga3(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Tangga"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kebutuhan Biaya" className="mb-2">
									Kebutuhan Biaya ke-3
								</Label>
								<Input
									id="Kebutuhan Biaya"
									type="text"
									className="col-span-1"
									value={kebutuhanBiaya3Display}
									onChange={handleKebutuhanBiayaChange(
										setKebutuhanBiaya3,
										setKebutuhanBiaya3Display,
									)}
									placeholder="Masukkan Kebutuhan Biaya"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Sumber Pembiayaan" className="mb-2">
									Sumber Pembiayaan Tahun ke-3
								</Label>
								<SumberAnggaranSelectUI
									getSumberAnggaran={getSumberAnggaran}
									setSumberAnggaran={setSumberAnggaran3}
								/>
							</div>
						</div>
						<DialogFooter>
							<div className="mt-5 flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};
export default FTargetPenyelesaian;
