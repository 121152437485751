import { useState } from 'react';

import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import TableDataCapaianKumulatifRumahBencana from '@/components/Tables/RumahBencana/DataCapaian/Kumulatif/TableDataCapaianKumulatifRumahBencana';
import FormDataCapaianKumulatifRumahBencana from '@/components/Forms/RumahBencana/FormDataCapaianKumulatifRumahBencana';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import DataCapaianKumulatifRBProv from './KumulatifProv';
import EditFormDataCapaianKumulatifRumahBencana from '@/components/Forms/RumahBencana/EditFormDataCapaianKumulatifRumahBencana';

const DataCapaianKumulatifRumahBencana = () => {
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);
	const [showForm, setShowForm] = useState('Pilih Data');
	const [editableId, setEditableId] = useState(0)

	if (privilege === 2 || privilege === 4 || privilege === 8 || privilege === 10) return <DataCapaianKumulatifRBProv />;

	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead="Data Capaian Kumulatif Rumah Bencana Kabupaten/Kota"
				pageName="Data Capaian Kumulatif Rumah Bencana Kabupaten/Kota"
			/>
			{showForm === 'Pilih Data' && (
				<TableDataCapaianKumulatifRumahBencana setEditableId={setEditableId} setShowForm={setShowForm} />
			)}
			{showForm === 'Form Data' && (
				<FormDataCapaianKumulatifRumahBencana setShowForm={setShowForm} />
			)}
			{showForm === 'Edit Data' && (
				<EditFormDataCapaianKumulatifRumahBencana editableId={editableId} setShowForm={setShowForm} />
			)}
		</DefaultLayout>
	);
};

export default DataCapaianKumulatifRumahBencana;
