import { setSpamBjpData } from '@/app/spambjpData/spambjpSlice';
import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useEffect, useRef } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

const TKondisiSpamBJP = () => {
	const dispatch = useDispatch();
	const data = useSelector((state: RootState) => state.spamDataBJP.data);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use = privilege === 10 || 4 ? kd_kabkota_dux : kd_kabkota;

	const formatted_kd_kabkota =
		kd_kabkota_to_use < 10 ? `0${kd_kabkota_to_use}` : kd_kabkota_to_use;
	let rowCounter = 0;
	const kd_wilayah = `${kd_provinsi_to_use}${formatted_kd_kabkota}`;

	useEffect(() => {
		fetch(
			`https://airminum.ciptakarya.pu.go.id/morphling/public/sicalmers/bjp?offset=0&kode_kabupaten=${kd_wilayah}`,
		)
			.then((response) => response.json())
			.then((data) => {
				const sortedData = data.data.sort((a: any, b: any) =>
					a.nama_kecamatan.localeCompare(b.nama_kecamatan),
				);

				sortedData.forEach((kecamatan: any) => {
					kecamatan.desa.sort((a: any, b: any) =>
						a.nama_desa.localeCompare(b.nama_desa),
					);
				});

				dispatch(setSpamBjpData(sortedData));
			});
	}, [kd_kabkota_to_use, data, dispatch]);

	const cardRef = useRef(null);

	if (data === null) {
		return <CompLoader />;
	}

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader cardRef={cardRef}>
					KONDISI SPAM BUKAN JARINGAN PERPIPAAN
					<br />
					SPM PEKERJAAN UMUM
					<br />
					PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI-HARI
				</JudulHeader>
				<div className="sticky top-0 flex items-center justify-start py-4 text-center">
					<div className="inline-flex items-center gap-2 text-center font-bold">
						<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
						pada table dibawah ini didapat dari SIMSPAM
					</div>
				</div>
				<Table className="w-full min-w-[3000px] table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead rowSpan={3} className="thead-text border-l">
								No
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Kecamatan
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Kelurahan / Desa
							</TableHead>
							<TableHead
								colSpan={10}
								className="thead-text border-b-0 text-center"
							>
								Akses Layak
							</TableHead>
							<TableHead
								colSpan={14}
								className="thead-text border-b-0 text-center"
							>
								Akses Tidak Layak
							</TableHead>
							<TableHead
								colSpan={2}
								rowSpan={2}
								className="thead-text border-b-0 text-center"
							>
								Air Kemasan Bermerk
							</TableHead>
							<TableHead
								colSpan={2}
								rowSpan={2}
								className="thead-text border-b-0 text-center"
							>
								Air Isi Ulang
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead colSpan={2} className="thead-text text-center">
								Sumur Bor Pompa Terlindungi
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Sumur Gali Terlindungi
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Mata Air Terlindungi
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Air Hujan/PAH
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Terminal Air
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Sumur Bor Pompa Terlindungi (&lt; 10 m dari Penampung)
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Sumur Gali Terlindungi (&lt; 10 m dari Penampung)
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Mata Air Terlindungi (&lt; 10 m dari Penampung)
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Sumur Tidak Terlindungi
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Mata Air Tidak Terlindungi
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Air Permukaan (Waduk/Sungai/Danau)
							</TableHead>
							<TableHead colSpan={2} className="thead-text text-center">
								Lainnya
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								KK
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Jiwa
							</TableHead>
							<TableHead className="thead-text text-center">KK</TableHead>
							<TableHead className="thead-text text-center">Jiwa</TableHead>
							<TableHead className="thead-text text-center">KK</TableHead>
							<TableHead className="thead-text text-center">Jiwa</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{data.map((data: any, kecamatanIndex: any) => {
							return data.desa.map((desa: any, desaIndex: any) => {
								rowCounter += 1;
								const aksesBJP = desa.akses_bjp.reduce(
									(acc: any, akses: any) => {
										acc[akses.jenis_akses_air_minum] = akses;
										return acc;
									},
									{},
								);
								return (
									<TableRow
										key={`row-${kecamatanIndex}-${desaIndex}`}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="tbody-text border-b border-l border-r">
											{rowCounter}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{data.nama_kecamatan}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{desa.nama_desa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Sumur Bor Pompa Terlindungi']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Sumur Bor Pompa Terlindungi']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Sumur Gali Terlindungi']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Sumur Gali Terlindungi']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Mata Air Terlindungi']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Mata Air Terlindungi']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Air Hujan/PAH']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Air Hujan/PAH']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Terminal Air']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Terminal Air']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{
												aksesBJP[
													'Sumur Bor/Pompa (< 10m dari  penampungan kotoran)'
												]?.jumlah_kk
											}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{
												aksesBJP[
													'Sumur Bor/Pompa (< 10m dari  penampungan kotoran)'
												]?.jumlah_jiwa
											}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{
												aksesBJP[
													'Sumur Gali Terlindungi (< 10m dari  penampungan kotoran)'
												]?.jumlah_kk
											}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{
												aksesBJP[
													'Sumur Gali Terlindungi (< 10m dari  penampungan kotoran)'
												]?.jumlah_jiwa
											}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{
												aksesBJP[
													'Mata Air Terlindungi (< 10m dari  penampungan kotoran)'
												]?.jumlah_kk
											}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{
												aksesBJP[
													'Mata Air Terlindungi (< 10m dari  penampungan kotoran)'
												]?.jumlah_jiwa
											}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Sumur Tidak Terlindungi']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Sumur Tidak Terlindungi']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Mata Air Tidak Terlindungi']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Mata Air Tidak Terlindungi']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{
												aksesBJP['Air Permukaan (waduk/sungai/Danau)']
													?.jumlah_kk
											}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{
												aksesBJP['Air Permukaan (waduk/sungai/Danau)']
													?.jumlah_jiwa
											}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Lainnya']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Lainnya']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Air Kemasan Bermerk']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Air Kemasan Bermerk']?.jumlah_jiwa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Air Isi Ulang']?.jumlah_kk}
										</TableCell>
										<TableCell className="tbody-text border-b border-r">
											{aksesBJP['Air Isi Ulang']?.jumlah_jiwa}
										</TableCell>
									</TableRow>
								);
							});
						})}
					</TableBody>
				</Table>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};

export default TKondisiSpamBJP;
