import Breadcrumb from "@/components/Breadcrumbs/Breadcrumb"
import TableDataCapaianTahunanAllBidang from "@/components/Tables/TableDataCapaianTahunanAllBidang"
import DefaultLayout from "@/layout/DefaultLayout"

const DataCapaianRumahBencanaTahunan = () => {
  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead="Data Capaian Tahunan Rumah Bencana"
        pageName="Data Capaian Tahunan Rumah Bencana"
      />
      <TableDataCapaianTahunanAllBidang />
    </DefaultLayout>
  )
}

export default DataCapaianRumahBencanaTahunan