import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { FiInfo } from 'react-icons/fi';

const GIdentifikasiRwnBencana = () => {
    let rowCounter = 0;

    const panduanData = [
        {
            id: 1,
            title: "Jenis Bencana",
            description: "Diisi dengan prediksi jenis bencana yang mungkin terjadi di wilayah tersebut."
        },
        {
            id: 2,
            title: "Tingkat Kerawanan Bencana",
            description: "Diisi dengan Rendah/Sedang/Tinggi sesuai dengan tingkat kerawanan bencana di wilayah tersebut."
        },
        {
            id: 3,
            title: "Kecamatan",
            description: "Diisi dengan nama Kecamatan."
        },
        {
            id: 4,
            title: "Kelurahan/Desa",
            description: "Diisi dengan nama Kelurahan/Desa."
        },
        {
            id: 5,
            title: "RT/RW",
            description: "Diisi dengan nomor RT/RW."
        },
        {
            id: 6,
            title: "Luas Perumahan",
            description: "Diisi dengan luas perumahan di wilayah rawan bencana tersebut."
        },
        {
            id: 7,
            title: "Jumlah Rumah",
            description: "Diisi dengan jumlah rumah di wilayah rawan bencana tersebut."
        },
        {
            id: 8,
            title: "Jumlah KK",
            description: "Diisi dengan jumlah KK yang ada di wilayah rawan bencana tersebut."
        },
        {
            id: 9,
            title: "Jumlah Jiwa",
            description: "Diisi dengan jumlah jiwa yang tinggal di wilayah rawan bencana tersebut."
        },
        {
            id: 10,
            title: "RLH",
            description: "Diisi dengan jumlah rumah layak huni yang ada di wilayah rawan bencana tersebut."
        },
        {
            id: 11,
            title: "RTLH",
            description: "Diisi dengan jumlah rumah tidak layak huni yang ada di wilayah rawan bencana tersebut."
        },
        {
            id: 12,
            title: "Hak Milik",
            description: "Diisi dengan jumlah rumah yang statusnya hak milik di wilayah rawan bencana tersebut."
        },
        {
            id: 13,
            title: "Sewa",
            description: "Diisi dengan jumlah rumah yang statusnya sewa di wilayah rawan bencana tersebut."
        }
    ];
    


    return (
			<>
				<Dialog>
					<DialogTrigger asChild>
						<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-green-600 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiInfo />
							Panduan
						</Button>
					</DialogTrigger>
					<DialogContent className="z-99999 max-h-[85%] max-w-[65%] overflow-auto rounded-lg">
						<DialogHeader>
							<DialogTitle>
								Panduan Pengisian Form Identifikasi Perumahan Dilokasi Rawan
								Bencana
							</DialogTitle>
							<DialogDescription>
								Silahkan ikuti panduan dibawah ini untuk melakukan pengisian
								form atau input data
							</DialogDescription>
						</DialogHeader>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="border-l text-center">No</TableHead>
									<TableHead className="text-center">Kolom</TableHead>
									<TableHead className="text-center">Keterangan</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{panduanData.map((data) => (
									<TableRow
										key={data.id}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="text-start">{data.title}</TableCell>
										<TableCell className="text-start">
											{data.description}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<DialogFooter>
							<div className="mt-5 flex w-full items-center justify-end">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Tutup
									</Button>
								</DialogClose>
							</div>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</>
		);
};

export default GIdentifikasiRwnBencana;
