// DialogContentComponent.tsx
import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogFooter,
	DialogClose,
	Dialog,
	DialogTrigger,
} from '@/components/ui/dialog'; // replace 'your-library' with the actual library you're using
import { Input } from '@/components/ui/input';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { usePostInvSarana } from '@/hooks/airLimbah/useInvSarana';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddSrnInvSchema } from '@/schema';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
} from '@/components/ui/form';
import { z } from 'zod';
import { toast } from 'sonner';
import { useEffect } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import { useQueryClient } from '@tanstack/react-query';


type FInvSaranaPengangkutanProps = {
	onAddSuccess: () => void;
};
export const FInvSaranaPengangkutan = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menu_id = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);

	//post
	const { PostInvSarana } = usePostInvSarana();

	//additional
	const QueryClient = useQueryClient();

	//form
	const form = useForm({
		resolver: zodResolver(AddSrnInvSchema),
		defaultValues: {
			jns_srn_pengangkutan: '',
			nmr_polisi: '',
			stat_kepemilikan: '',
			kondisi: '',
			beroperasi: '',
			kap_pengangkutan: '',
			tot_penyedotan: '',
			tahun_pelaporan: tahun,
			tahun_kendaraan: '',
		},
	});

	useEffect(() => {
		form.setValue('tahun_pelaporan', tahun);
	}, [tahun, form.setValue]);

	const onSubmit = async (data: z.infer<typeof AddSrnInvSchema>) => {
		const values = form.getValues();

		const requiredFields: (keyof z.infer<typeof AddSrnInvSchema>)[] = [
			'jns_srn_pengangkutan',
			'nmr_polisi',
			'stat_kepemilikan',
			'kondisi',
			'beroperasi',
			'kap_pengangkutan',
			'tot_penyedotan',
			'tahun_kendaraan',
		];

		for (const field of requiredFields) {
			if (!values[field]) {
				toast.error('Data gagal ditambahkan: semua field harus diisi');
				return;
			}
		}

		try {
			const completeData = {
				...data,
				tahun_pelaporan: Number(tahun),
				menu_id: menu_id,
				kd_provinsi: kd_provinsi,
				kd_kabkota: kd_kabkota,
				kap_pengangkutan: Number(data.kap_pengangkutan),
				tot_penyedotan: Number(data.tot_penyedotan),
			};
			await PostInvSarana(completeData);
			QueryClient.refetchQueries({
				queryKey: ['inv-sarana'],
			});
			QueryClient.invalidateQueries({ queryKey: ['inv-sarana'] });
			toast.success('Data berhasil ditambahkan');
			form.reset();
		} catch (error) {
			console.error(error);
			toast.error('Data gagal ditambahkan');
		}
	};

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
					<FiPlusCircle />
					Tambah Sarana
				</Button>
			</DialogTrigger>
			<DialogContent
				onAbortCapture={() => form.reset()}
				className="z-99999 mx-auto"
			>
				<DialogHeader>
					<DialogTitle>Tambah Sarana</DialogTitle>
					<DialogDescription>
						Silahkan isi data sarana pengangkutan lumpur tinja
					</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="grid gap-4 py-4">
							<div className="grid gap-4 py-4">
								<FormField
									control={form.control}
									name="tahun_pelaporan"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Tahun Pelaporan</FormLabel>
											<FormControl>
												<Input
													disabled
													type="text"
													className="w-64 justify-self-end text-black-2 dark:text-white"
													placeholder="shadcn"
													{...field}
													required
												/>
											</FormControl>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="jns_srn_pengangkutan"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Sarana Pengangkutan</FormLabel>
											<FormControl>
												<Input
													className="w-64 justify-self-end text-black-2"
													placeholder="Truck, Truck Tinja, dll"
													{...field}
													required
												/>
											</FormControl>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="nmr_polisi"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Nomor Polisi</FormLabel>
											<FormControl>
												<Input
													type="text"
													className="w-64 justify-self-end text-black-2"
													placeholder="B 1234 ABC"
													{...field}
												/>
											</FormControl>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="tahun_kendaraan"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Tahun Kendaraan</FormLabel>
											<FormControl>
												<Input
													type="text"
													className="w-64 justify-self-end text-black-2"
													placeholder="2010"
													{...field}
												/>
											</FormControl>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="stat_kepemilikan"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Status Kepemilikan</FormLabel>
											<Select
												onValueChange={field.onChange}
												defaultValue={field.value}
											>
												<FormControl>
													<SelectTrigger className="w-64 justify-self-end text-black-2">
														<SelectValue placeholder="Pilih Kepemilikan" />
													</SelectTrigger>
												</FormControl>
												<SelectContent className="z-99999">
													<SelectItem value="Pemda">Pemda</SelectItem>
													<SelectItem value="Swasta">Swasta</SelectItem>
												</SelectContent>
											</Select>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="kondisi"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Kondisi</FormLabel>
											<Select
												onValueChange={field.onChange}
												defaultValue={field.value}
											>
												<FormControl>
													<SelectTrigger className="w-64 justify-self-end text-black-2">
														<SelectValue placeholder="Pilih Kondisi" />
													</SelectTrigger>
												</FormControl>
												<SelectContent className="z-99999">
													<SelectItem value="Baik">Baik</SelectItem>
													<SelectItem value="Rusak">Rusak</SelectItem>
												</SelectContent>
											</Select>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="beroperasi"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Beroperasi</FormLabel>
											<Select
												onValueChange={field.onChange}
												defaultValue={field.value}
											>
												<FormControl>
													<SelectTrigger className="w-64 justify-self-end text-black-2">
														<SelectValue placeholder="Beroperasi?" />
													</SelectTrigger>
												</FormControl>
												<SelectContent className="z-99999">
													<SelectItem value="Ya">Ya</SelectItem>
													<SelectItem value="Tidak">Tidak</SelectItem>
												</SelectContent>
											</Select>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="kap_pengangkutan"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Kapasitas Pengangkutan (M3)</FormLabel>
											<FormControl>
												<Input
													className="w-64 justify-self-end text-black-2"
													placeholder="satuan meter kubik"
													{...field}
												/>
											</FormControl>
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="tot_penyedotan"
									render={({ field }) => (
										<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
											<FormLabel>Total Penyedotan per Minggu</FormLabel>
											<FormControl>
												<Input
													className="w-64 justify-self-end text-black-2"
													placeholder="satuan unit rumah"
													{...field}
												/>
											</FormControl>
										</FormItem>
									)}
								/>
							</div>
						</div>
						<DialogFooter>
							<div className="flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Cancel
									</Button>
								</DialogClose>
								<DialogClose asChild>
									<Button
										type="submit"
										className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									>
										Tambah
									</Button>
								</DialogClose>
							</div>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};
