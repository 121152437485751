import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { FormEvent, useEffect, useState } from 'react';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useEditIdentifikasiPerumahanRawanBencana } from '@/hooks/perumahan/useRumahBencana';
import { useGetJenisBencana } from '@/hooks/useJenisBencana';
import { EksisRawanBencana } from '@/types/perumahan';
import EditJenisBencanaSelectUI from '@/components/ui/SelectEditJenisBencana';
import EditDesaSelectUI from '@/components/ui/SelectEditDesa';
import EditKecamatanSelectUI from '@/components/ui/SelectEditKecamatan';
import { useQueryClient } from '@tanstack/react-query';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import EditKabKotaSelectUI from '@/components/ui/SelectEditKabupatenKotaUI';

interface EditIdentifikasiRawanBencanaProps {
    rowData: EksisRawanBencana;
}

const EIdentifikasiRwnBencana: React.FC<EditIdentifikasiRawanBencanaProps> = ({
    rowData,
}) => {
    const user = useSelector((state: RootState) => state.auth);
    const menuId = useSelector((state: RootState) => state.menuId.value);

    //wilayah
    const [kd_kabkota, setKd_kabkota] = useState<number>(rowData.kd_kabkota);
    const [kd_kec, setKd_kec] = useState<number>(rowData.kd_kec);
    const [kd_desa, setKd_desa] = useState<number>(rowData.kd_desa);

    //get
    const { getKabKota } = useGetKabKota(user.kd_provinsi)
    const { getKecamatan, refetch: fetchKec } = useGetKecamatan(user.kd_provinsi, kd_kabkota);
    const { getDesa, refetch: fetchDesa } = useGetDesa(user.kd_provinsi, kd_kabkota, kd_kec);
    const { getJenisBencana } = useGetJenisBencana();
    const { editIdentifikasiPerumahanRawanBencana } = useEditIdentifikasiPerumahanRawanBencana();


    //dataform
    const [jenisBencana, setJenisBencana] = useState<number>(rowData.jenis_bencana_id);
    const [tingkatKerawananBencana, setTingkatKerawananBencana] = useState(rowData.tingkat_rawan.nama_tingkat_rawan || '0');
    const [rt, setRt] = useState((rowData.no_rt ?? 0).toString());
		const [rw, setRw] = useState((rowData.no_rw ?? 0).toString());
		const [luasPerumahan, setLuasPerumahan] = useState(
			(rowData.luas_perumahan ?? 0).toString(),
		);
		const [jumlahPerumahan, setJumlahPerumahan] = useState(
			(rowData.jml_rumah ?? 0).toString(),
		);
		const [jumlahKK, setJumlahKK] = useState((rowData.jml_kk ?? 0).toString());
		const [jumlahJiwa, setJumlahJiwa] = useState(
			(rowData.jml_jiwa ?? 0).toString(),
		);
		const [rlh, setRLH] = useState((rowData.fisik_rlh ?? 0).toString());
		const [rtlh, setRTLH] = useState((rowData.fisik_rtlh ?? 0).toString());
		const [hakMilik, setHakMilik] = useState(
			(rowData.status_hak_milik ?? 0).toString(),
		);
		const [sewa, setSewa] = useState((rowData.status_sewa ?? 0).toString());

    //additionals
    const [modalOpen, setModalOpen] = useState(false);
    const queryClient = useQueryClient();

    const tingkatKerawananMapping: { [key: string]: number } = {
        'Tinggi': 1,
        'Sedang': 2,
        'Rendah': 3,
        "Kosong": 4
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        const data = {
            id_rawan_bencana: rowData.id_rawan_bencana,
            jenis_bencana_id: jenisBencana,
            tingkat_rawan_id: tingkatKerawananMapping[tingkatKerawananBencana],
            kd_kabkota: kd_kabkota,
            kd_kec: kd_kec,
            kd_desa: kd_desa,
            no_rt: parseInt(rt),
            no_rw: parseInt(rw),
            luas_perumahan: parseFloat(luasPerumahan),
            jml_rumah: parseInt(jumlahPerumahan),
            jml_kk: parseInt(jumlahKK),
            jml_jiwa: parseInt(jumlahJiwa),
            fisik_rlh: parseInt(rlh),
            fisik_rtlh: parseInt(rtlh),
            status_hak_milik: parseInt(hakMilik),
            status_sewa: parseInt(sewa)
        };

        editIdentifikasiPerumahanRawanBencana(data, {
            onSuccess: () => {
                queryClient.refetchQueries({ queryKey: ['eksis-rawan-bencana'] });
                setModalOpen(false);
            },
            onError: (error) => {
                console.log(error);
            },
        });

    };

    useEffect(() => {
        fetchDesa();
        fetchKec();
    }, [kd_kec, kd_kabkota]);

    useEffect(() => {
        setJenisBencana(rowData.jenis_bencana_id);
        setTingkatKerawananBencana(rowData.tingkat_rawan.nama_tingkat_rawan);
        setKd_kec(rowData.kd_kec);
        setKd_desa(rowData.kd_desa);
    }, [rowData]);

    return (
        <>
            <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger asChild>
                    <Button
                        size={'sm'}
                        className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'>
                        <FiEdit />
                    </Button>
                </DialogTrigger>
                <DialogContent className="mx-auto max-w-screen-xl z-99999">
                    <DialogHeader>
                        <DialogTitle>
                            Edit Identifikasi Perumahan di Lokasi Rawan Bencana
                        </DialogTitle>
                        <DialogDescription>
                            Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
                        </DialogDescription>
                    </DialogHeader>
                    <form action='post' onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Jenis Bencana" className="mb-2">
                                    Jenis Bencana
                                </Label>
                                <EditJenisBencanaSelectUI
                                    getJenisBencana={getJenisBencana}
                                    setJenisBencana={setJenisBencana}
                                    selectedValue={jenisBencana}
                                    disableSelect={false}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Tingkat Kerawanan Bencana" className="mb-2">
                                    Tingkat Kerawanan Bencana
                                </Label>
                                <Select
                                    onValueChange={(value) => setTingkatKerawananBencana(value)}
                                    value={tingkatKerawananBencana}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Pilih Tingkat Kerawanan Bencana" />
                                    </SelectTrigger>
                                    <SelectContent className='z-99999'>
                                        <SelectItem value="Kosong">-</SelectItem>
                                        <SelectItem value="Tinggi">Tinggi</SelectItem>
                                        <SelectItem value="Sedang">Sedang</SelectItem>
                                        <SelectItem value="Rendah">Rendah</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            {menuId === 7 && (
                                <div className="flex flex-col">
                                    <Label htmlFor="Kabupaten" className="mb-2">
                                        Kabupaten
                                    </Label>
                                    <EditKabKotaSelectUI
                                        getKabKota={getKabKota}
                                        setKabKota={setKd_kabkota}
                                        selectedValue={kd_kabkota}
                                        disabledSelect={false}
                                    />
                                </div>
                            )}
                            <div className="flex flex-col">
                                <Label htmlFor="Kecamatan" className="mb-2">
                                    Kecamatan
                                </Label>
                                <EditKecamatanSelectUI
                                    getKecamatan={getKecamatan}
                                    setKd_kec={setKd_kec}
                                    selectedValue={kd_kec}
                                    disabledSelect={false}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Kelurahan/Desa" className="mb-2">
                                    Kelurahan/Desa
                                </Label>
                                <EditDesaSelectUI
                                    getDesa={getDesa}
                                    setKd_desa={setKd_desa}
                                    selectedValue={kd_desa}
                                    kd_kec={kd_kec}
                                    disabledSelect={false}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="RT" className="mb-2">
                                    RT
                                </Label>
                                <Input
                                    id="RT"
                                    type="text"
                                    value={rt}
                                    onChange={(e) => setRt(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="RW" className="mb-2">
                                    RW
                                </Label>
                                <Input
                                    id="RW"
                                    type="text"
                                    value={rw}
                                    onChange={(e) => setRw(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Luas Perumahan" className="mb-2">
                                    Luas Perumahan
                                </Label>
                                <Input
                                    id="Luas Perumahan"
                                    type="text"
                                    value={luasPerumahan}
                                    onChange={(e) => setLuasPerumahan(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah Perumahan" className="mb-2">
                                    Jumlah Perumahan
                                </Label>
                                <Input
                                    id="Jumlah Perumahan"
                                    type="text"
                                    value={jumlahPerumahan}
                                    onChange={(e) => setJumlahPerumahan(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah KK" className="mb-2">
                                    Jumlah KK
                                </Label>
                                <Input
                                    id="Jumlah KK"
                                    type="text"
                                    value={jumlahKK}
                                    onChange={(e) => setJumlahKK(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah Jiwa" className="mb-2">
                                    Jumlah Jiwa
                                </Label>
                                <Input
                                    id="Jumlah Jiwa"
                                    type="text"
                                    value={jumlahJiwa}
                                    onChange={(e) => setJumlahJiwa(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="RLH" className="mb-2">
                                    RLH
                                </Label>
                                <Input
                                    id="RLH"
                                    type="text"
                                    value={rlh}
                                    onChange={(e) => setRLH(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="RTLH" className="mb-2">
                                    RTLH
                                </Label>
                                <Input
                                    id="RTLH"
                                    type="text"
                                    value={rtlh}
                                    onChange={(e) => setRTLH(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Hak Milik" className="mb-2">
                                    Hak Milik
                                </Label>
                                <Input
                                    id="Hak Milik"
                                    type="text"
                                    value={hakMilik}
                                    onChange={(e) => setHakMilik(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Sewa" className="mb-2">
                                    Sewa
                                </Label>
                                <Input
                                    id="Sewa"
                                    type="text"
                                    value={sewa}
                                    onChange={(e) => setSewa(e.target.value)}
                                />
                            </div>
                        </div>
                        <DialogFooter>
                            <div className="flex w-full justify-between mt-5">
                                <DialogClose asChild>
                                    <Button
                                        className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
                                    >
                                        Batal
                                    </Button>
                                </DialogClose>
                                <Button
                                    className="inline-flex items-center justify-center gap-1 rounded-lg bg-green-600 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                                    type="submit"
                                >
                                    Simpan
                                </Button>
                            </div>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EIdentifikasiRwnBencana;
