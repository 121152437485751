import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface JenisLokasiTidakSesuaiProps {
  getJenisLokasi: any[];
  setJenisLokasi: (id: number) => void;
  selectedValue: number
  disabledSelect: boolean
}


const EditJenisLokasiTdkSesuaiUI: React.FC<JenisLokasiTidakSesuaiProps> = ({
  getJenisLokasi,
  setJenisLokasi,
  selectedValue,
  disabledSelect
}) => {

  const [selectedJenisLokasi, setSelectedJenisLokasi] = useState('');

    useEffect(() => {
        const selectedJenis = getJenisLokasi.find((jenisLokasi: any) => jenisLokasi.id === selectedValue);
        if (selectedJenis) {
            setSelectedJenisLokasi(selectedJenis.nama_jenis_lks_tdk_sesuai);
        }
    }, [selectedValue, getJenisLokasi]);

    return (
        <Select
            onValueChange={(value) => {
                if (value !== '') {
                    const id = parseInt(value, 10);
                    const selectedJenisLokasi = getJenisLokasi.find((jenisLokasi: any) => jenisLokasi.id === parseInt(value));
                    if (selectedJenisLokasi) {
                        console.log("Jenis Relokasi yang dipilih:", selectedJenisLokasi.nama_jenis_lks_tdk_sesuai);
                        setSelectedJenisLokasi(selectedJenisLokasi.nama_jenis_lks_tdk_sesuai || "");
                        setJenisLokasi(id); // Konversi ke number sebelum diteruskan
                    }
                }
            }}
            value={selectedValue.toString()} // Konversi ke string untuk Select
            disabled={disabledSelect}
        >
            <SelectTrigger>
                <SelectValue placeholder="Pilih Jenis Relokasi">
                    {selectedJenisLokasi || 'Pilih Jenis Relokasi'}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getJenisLokasi.map((jenisLokasi: any, index: any) => (
                    <SelectItem 
                        key={index} 
                        value={jenisLokasi.id.toString()}
                        >
                        {jenisLokasi.nama_jenis_lks_tdk_sesuai}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}

export default EditJenisLokasiTdkSesuaiUI;


