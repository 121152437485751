import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
// import { DataTable } from '@/components/payments/data-table';
import DefaultLayout from '@/layout/DefaultLayout';

import { SelectKabGlobal } from '@/components/ui/SelectKabGlobal';
import { SelectProvGlobal } from '@/components/ui/SelectProvGlobal';
import useExportAM from '@/hooks/airMinum/useExportAM';
import { Button } from '@/components/ui/Button';
import { FiDownload } from 'react-icons/fi';
import { RootState } from '@/app/store';
import { useSelector } from 'react-redux';

const Developments = () => {
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);

	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);

	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;

	const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;

	const { exportAMTemplate, isLoading: LoadExp } = useExportAM();

	const handleDownloadTemplate = () => {
		exportAMTemplate(kd_provinsi_to_use, kd_kabkota_to_use);
	};
	return (
		<DefaultLayout>
			<Breadcrumb pageHead="Download" pageName="Download" />
			{/* <div className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"> */}
			{/* <div className="flex flex-col flex-1 justify-center items-center gap-10">
          <h1 className="text-3xl font-semibold text-[#364878] dark:text-white">Download</h1>
          <Button className="inline-flex items-center justify-center rounded-lg bg-primary px-4 py-2 h-14 w-30 text-center text-lg font-medium text-white hover:bg-opacity-90">Download</Button>
          </div> */}
			{/* </div> */}
			{/* <DataTable columns={columns} data={payments} /> */}
			<SelectProvGlobal />
			<SelectKabGlobal />
			<Button
				className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
				onClick={handleDownloadTemplate}
				disabled={LoadExp}
			>
				<FiDownload />
				{LoadExp ? 'Downloading...' : 'Download Template'}
			</Button>
		</DefaultLayout>
	);
};

export default Developments;
