import {
	useGetNewRencanaPemenuhan,
	useDeleteNewRencanaPemenuhan,
} from '@/hooks/rencanaPelaksanaan/useRencanaPemenuhan';
import { useEffect, useRef, useState } from 'react';
import { CompLoader } from '@/common/Loader';
import { formatRupiah } from '@/libs/utils';
import { RootState } from '@/app/store';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import JudulHeader from '@/components/Header/JudulHeader';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { FiEdit, FiEye, FiTrash2 } from 'react-icons/fi';
import { Button } from '@/components/ui/Button';
import { toast } from 'sonner';
import { useQueryClient } from '@tanstack/react-query';

type TableRencanaProps = {
	setShowForm?: any
	setEditableID?: any
}

const TableRencana = ({ setShowForm, setEditableID }: TableRencanaProps) => {
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota)
	const kd_kabkota_dux = useSelector((state: RootState) => state.location.kd_kabkota_dux)
	const kd_provinsi_dux = useSelector((state: RootState) => state.location.kd_provinsi_dux)
	const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
	const menu_id = useSelector((state: RootState) => state.menuId.value)
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const bidang = useSelector((state: RootState) => state.bidang.value);
	const wilayah = useSelector((state: RootState) => state.wilayah.value)
	const cardRef = useRef(null)

	const kd_kabkota_to_use = (privilege === 4 || privilege === 10 || privilege === 8)
		? (wilayah === "provinsi" ? kd_kabkota : kd_kabkota_dux)
		: kd_kabkota;
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [deleteRowId, setDeleteRowId] = useState(0)
	const queryClient = useQueryClient()

	const confirmDelete = async (id: number) => {
		try {
			await deleteNewRencanaPemenuhan(id);
			toast.success("Berhasil menghapus rencana pemenuhan!")
		} catch (error) {
			toast.error("Gagal menghapus rencana pemenuhan!")
		} finally {
			queryClient.invalidateQueries({ queryKey: ["rencana-pemenuhan"] })
			setIsDialogOpen(false);
		}
	};
	const { getRencanaPemenuhan, isLoading, refetch } =
		useGetNewRencanaPemenuhan(Number(tahun), bidang, kd_provinsi_to_use, kd_kabkota_to_use, menu_id, wilayah);
	const { deleteNewRencanaPemenuhan, isPending } = useDeleteNewRencanaPemenuhan()

	useEffect(() => {
		refetch();
	}, [bidang, refetch, kd_kabkota_to_use]);

	const handleEdit = (id: number) => {
		setEditableID(id);
		setShowForm("Edit Form")
	}

	const handleTambahKomponen = (id: number) => {
		setEditableID(id)
		setShowForm("Tambah Komponen")
	}

	const handleDetail = (id: number) => {
		setEditableID(id)
		setShowForm("Detail Rencana")
	}

	if (isLoading || isPending) {
		return <CompLoader />;
	}


	let rowCounter = 0

	return (
		<div ref={cardRef} className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden">
			<ScrollArea className='h-full p-4 relative'>
				{bidang === 'air-minum' && (
					<JudulHeader isLoading={isLoading} cardRef={cardRef} minusWidth={32}>
						SPM PEKERJAAN UMUM <br />
						{wilayah === "kabupaten" ? "RENCANA PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI-HARI" : "PEMENUHAN KEBUTUHAN AIR MINUM CURAH LINTAS KABUPATEN/KOTA"}
					</JudulHeader>
				)}
				{bidang === 'air-limbah' && (
					<JudulHeader isLoading={isLoading} cardRef={cardRef} minusWidth={32}>
						SPM PEKERJAAN UMUM <br />
						{wilayah === "kabupaten" ? "PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK" : "PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK REGIONAL LINTAS KABUPATEN/KOTA"}
					</JudulHeader>
				)}
				{bidang === 'rumah-bencana' && (
					<JudulHeader isLoading={isLoading} cardRef={cardRef} minusWidth={32}>
						SPM PERUMAHAN RAKYAT <br />
						{wilayah === "kabupaten" ? "PENYEDIAAN DAN REHABILITASI RUMAH YANG LAYAK HUNI BAGI KORBAN BENCANA KABUPATEN/KOTA " : "PENYEDIAAN DAN REHABILITASI RUMAH YANG LAYAK HUNI BAGI KORBAN BENCANA"}
					</JudulHeader>
				)}
				{bidang === 'rumah-relokasi' && (
					<JudulHeader isLoading={isLoading} cardRef={cardRef} minusWidth={32}>
						SPM PERUMAHAN RAKYAT <br />
						{wilayah === "kabupaten" ? "FASILITASI PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA RELOKASI PROGRAM PEMERINTAH DAERAH KABUPATEN/KOTA" : "FASILITASI PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA RELOKASI PROGRAM PEMERINTAH DAERAH PROVINSI"}
					</JudulHeader>
				)}
				<Table className='min-w-[2000px] w-full table-auto'>
					<TableHeader className="bg-gray-2 dark:bg-meta-4 sticky top-0">
						<TableRow>
							<TableHead rowSpan={2} className='thead-text text-center border-l'>No</TableHead>
							<TableHead rowSpan={2} className='thead-text text-center'>Nomenklatur Urusan Kabupaten / Kota</TableHead>
							<TableHead rowSpan={2} className='thead-text text-center'>Jenis Kegiatan</TableHead>
							<TableHead rowSpan={2} className='thead-text text-center'>Anggaran</TableHead>
							<TableHead rowSpan={2} className='thead-text text-center'>Sumber Anggaran</TableHead>
							<TableHead rowSpan={2} className='thead-text text-center'>Nama Kegiatan</TableHead>
							<TableHead colSpan={wilayah === "kabupaten" ? 2 : 3} className='thead-text text-center border-b-0'>Lokasi</TableHead>
							<TableHead colSpan={3} className='thead-text text-center border-b-0'>Output</TableHead>
							{privilege !== 3 && privilege !== 4 && privilege !== 8 && privilege !== 10 && <TableHead rowSpan={2} className='thead-text text-center'>Aksi</TableHead>}
						</TableRow>
						<TableRow>
							{wilayah === "provinsi" && <TableHead className='thead-text text-center'>Kabupaten/Kota</TableHead>}
							<TableHead className='thead-text text-center'>Kecamatan</TableHead>
							<TableHead className='thead-text text-center'>Kelurahan</TableHead>
							<TableHead className='thead-text text-center'>Komponen</TableHead>
							<TableHead className='thead-text text-center'>Satuan</TableHead>
							<TableHead className='thead-text text-center'>Volume</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{getRencanaPemenuhan.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map((item: any, key: any) => {
							rowCounter++
							return (
								<TableRow
									key={key}
									className={
										rowCounter % 2 === 0
											? 'bg-white dark:bg-boxdark'
											: 'bg-gray-2 dark:bg-meta-4'
									}
								>
									<TableCell className="tbody-text border-b border-l border-r">
										{rowCounter}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{item.nomenklatur?.nama_nomenklatur ?? "-"}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{item.nomenklatur?.jenis_kegiatan ?? "-"}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{formatRupiah(item.rencana_anggaran)}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{item.sumber_anggaran?.sumber_anggaran ?? "-"}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{item.nama_kegiatan}
									</TableCell>
									{wilayah === 'provinsi' && (
										<TableCell className="tbody-text border-b border-r">
											{item.wilayah?.nama_kabkota ?? "-"}
										</TableCell>
									)}
									<TableCell className="tbody-text border-b border-r">
										{item.wilayah?.nama_kecamatan ?? "-"}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{item.wilayah?.nama_desa ?? "-"}
									</TableCell>
									<TableCell
										style={{ padding: 0 }}
										className="border-b border-r"
									>
										<table className="w-full">
											<tbody className="w-full">
												{item.rencana_komponen.length !== 0 ? (
													item.rencana_komponen.map((item: any, index: any) => (
														<TableRow
															key={index}
															className="flex items-center justify-center"
														>
															<TableCell
																key={index}
																className="tbody-text flex items-center justify-center"
															>
																{item.komponen.komponen_name}
															</TableCell>
														</TableRow>
													))
												) : (
													privilege !== 3 && privilege !== 4 ?
														<TableRow className="flex items-center justify-center">
															<TableCell className="flex items-center justify-center">
																<Button
																	className="inline-flex items-center justify-start gap-1 rounded-lg bg-green-500 px-4 py-2 text-center font-medium text-white hover:bg-green-600"
																	onClick={() =>
																		handleTambahKomponen(item.id_rpp)
																	}
																>
																	Tambah Komponen
																</Button>
															</TableCell>
														</TableRow>
														:
														<TableRow className="flex flex-col items-center justify-center">
															<TableCell className="flex items-center justify-center">
																-
															</TableCell>
														</TableRow>
												)}
											</tbody>
										</table>
									</TableCell>
									<TableCell
										style={{ padding: 0 }}
										className="border-b border-r"
									>
										<table className="w-full">
											<tbody className="w-full">
												{item.rencana_komponen.length !== 0 ? (
													item.rencana_komponen.map((item: any, index: any) => (
														<TableRow
															key={index}
															className="flex flex-col items-center justify-center"
														>
															<TableCell
																key={index}
																className="tbody-text flex items-center justify-center"
															>
																{item.satuan && item.satuan.satuan_name
																	? item.satuan.satuan_name
																	: '-'}
															</TableCell>
														</TableRow>
													))
												) : (
													<TableRow className="flex flex-col items-center justify-center">
														<TableCell className="flex items-center justify-center">
															-
														</TableCell>
													</TableRow>
												)}
											</tbody>
										</table>
									</TableCell>
									<TableCell
										style={{ padding: 0 }}
										className="border-b border-r"
									>
										<table className="w-full">
											<tbody className="w-full">
												{item.rencana_komponen.length !== 0 ? (
													item.rencana_komponen.map((item: any, index: any) => (
														<TableRow
															key={index}
															className="flex items-center justify-center"
														>
															<TableCell
																key={index}
																className="tbody-text flex items-center justify-center"
															>
																{item.volume}
															</TableCell>
														</TableRow>
													))
												) : (
													<TableRow className="flex items-center justify-center">
														<TableCell className="flex items-center justify-center">
															-
														</TableCell>
													</TableRow>
												)}
											</tbody>
										</table>
									</TableCell>
									{/* <TableCell
										style={{ padding: 0 }}
										className="border-b border-r"
									>
										<table className="w-full">
											<tbody className="w-full">
												{item.rencana_komponen.length !== 0 ? (
													item.rencana_komponen.map((item: any, index: any) => (
														<TableRow
															key={index}
															className="flex flex-col items-center justify-center"
														>
															<TableCell
																key={index}
																className="tbody-text flex items-center justify-center"
															>
																{item.realisasi}
															</TableCell>
														</TableRow>
													))
												) : (
													<TableRow className="flex flex-col items-center justify-center">
														<TableCell className="flex items-center justify-center">
															-
														</TableCell>
													</TableRow>
												)}
											</tbody>
										</table>
									</TableCell> */}
									{privilege !== 3 &&
										privilege !== 4 &&
										privilege !== 8 &&
										privilege !== 10 && (
											<TableCell className="tbody-text border-b border-r">
												<div className="flex flex-col items-center justify-center gap-4">
													<Button
														size={'sm'}
														className="inline-flex items-center justify-start gap-1 self-center rounded-lg bg-[#fab23d] px-4 py-2 text-center font-medium text-white hover:bg-[#fab23d]"
														onClick={() => handleDetail(item.id_rpp)}
													>
														<FiEye />
													</Button>
													{Number(item.verifikasi) !== 1 && (
														<div className="flex gap-4">
															<Button
																size={'sm'}
																className="inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600"
																onClick={() => handleEdit(item.id_rpp)}
															>
																<FiEdit />
															</Button>
															<Button
																size={'sm'}
																className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600"
																onClick={() => {
																	setIsDialogOpen(true);
																	setDeleteRowId(item.id_rpp);
																}}
															>
																<FiTrash2 />
															</Button>
														</div>
													)}
												</div>
											</TableCell>
										)}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				<ScrollBar orientation='horizontal' />
			</ScrollArea>
			<ConfirmationDialog
				isDialogOpen={isDialogOpen}
				setIsDialogOpen={setIsDialogOpen}
				confirmAction={() => confirmDelete(deleteRowId)}
				title="Apakah anda yakin?"
				description="Aksi ini tidak bisa diurungkan, data rencana yang telah dihapus tidak bisa dikembalikan."
				confirmButtonColor="bg-red-500 text-white hover:bg-red-600 dark:bg-red-400 dark:hover:bg-red-600"
			/>
		</div>
	);
};

export default TableRencana;
