import { useQuery } from '@tanstack/react-query';
import { API } from '../libs/API';

export function useKomponen(menu_id: number) {
	const {
		data: getKomponen,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['komponen', menu_id],
		queryFn: async () => {
			const { data } = await API.get(`/komponen/?menu_id=${menu_id}`);
			return data.data;
		},
	});
	return {
		getKomponen,
		refetch,
		isLoading,
	};
}

export function useKomponenNomen(nomen_id: number) {
	const {
		data: getKomponenNomen,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['komponen-nomen', nomen_id],
		queryFn: async () => {
			const { data } = await API.get(`/komponen-nomen/?nomen_id=${nomen_id}`);
			return data.data;
		},
	});
	return {
		getKomponenNomen,
		refetch,
		isLoading,
	};
}

export function useSumberAnggaran() {
	const {
		data: getSumberAnggaran,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['sumber-anggaran'],
		queryFn: async () => {
			const { data } = await API.get('/sumber-anggaran');
			return data.data;
		},
	});
	return {
		getSumberAnggaran,
		refetch,
		isLoading,
	};
}
