import { setSpamData } from '@/app/spamjpData/spamjpSlice';
import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useEffect, useRef } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

const TKondisiSpamJP = () => {
	const dispatch = useDispatch();
	const data = useSelector((state: RootState) => state.spamData.data);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use = privilege === 10 || 4 ? kd_kabkota_dux : kd_kabkota;

	const formatted_kd_kabkota =
		kd_kabkota_to_use < 10 ? `0${kd_kabkota_to_use}` : kd_kabkota_to_use;
	let rowCounter = 0;
	const kd_wilayah = `${kd_provinsi_to_use}${formatted_kd_kabkota}`;

	useEffect(() => {
		fetch(
			`https://airminum.ciptakarya.pu.go.id/morphling//public/spam-kabupaten-review?limit=300&offset=0&kode_kabupaten=${kd_wilayah}`,
		)
			.then((response) => response.json())
			.then((data) => {
				const sortedData = data.data.sort((a: any, b: any) =>
					a.wilayah_layanan[0].nama_kecamatan.localeCompare(
						b.wilayah_layanan[0].nama_kecamatan,
					),
				);
				dispatch(setSpamData(sortedData));
				// console.log(data.data);
			});
	}, [kd_kabkota_to_use, data, dispatch]);

	const cardRef = useRef(null);

	if (data === null) {
		return <CompLoader />;
	}

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader cardRef={cardRef} isLoading={false}>
					KONDISI SPAM JARINGAN PERPIPAAN
					<br />
					SPM PEKERJAAN UMUM
					<br />
					PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI-HARI
				</JudulHeader>
				<div className="sticky top-0 flex items-center justify-start py-4 text-center">
					<div className="inline-flex items-center gap-2 text-center font-bold">
						<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
						pada table dibawah ini didapat dari SIMSPAM
					</div>
				</div>
				<Table className="w-full table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead rowSpan={2} className="thead-text border-l">
								No
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Kecamatan
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Kelurahan / Desa
							</TableHead>
							<TableHead
								colSpan={3}
								className="thead-text border-b-0 text-center"
							>
								Unit Air Baku
							</TableHead>
							<TableHead
								colSpan={2}
								className="thead-text border-b-0 text-center"
							>
								Unit Produksi
							</TableHead>
							<TableHead
								colSpan={3}
								className="thead-text border-b-0 text-center"
							>
								Unit Distirbusi
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Unit Pelayanan
							</TableHead>
							<TableHead
								colSpan={3}
								className="thead-text border-b-0 text-center"
							>
								Kondisi
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text text-center">
								Nama Sumber Air
							</TableHead>
							<TableHead className="thead-text text-center">
								Kapasitas Intake (L/d)
							</TableHead>
							<TableHead className="thead-text text-center">
								Kapasitas Unit Produksi (L/d)
							</TableHead>
							<TableHead className="thead-text text-center">
								Idle Capacity (L/d)
							</TableHead>
							<TableHead className="thead-text text-center">
								Jenis Pipa
							</TableHead>
							<TableHead className="thead-text text-center">
								Panjang Pipa
							</TableHead>
							<TableHead className="thead-text text-center">
								Diameter Pipa
							</TableHead>
							<TableHead className="thead-text text-center">
								Beroperasi
							</TableHead>
							<TableHead className="thead-text text-center">
								Kuantitas
							</TableHead>
							<TableHead className="thead-text text-center">Kualitas</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{data.map((spamJpData, key) =>
							spamJpData.wilayah_layanan.map((wilayah, wilayahIndex: any) =>
								spamJpData.sumber_air_baku.map((sumber, sumberIndex: any) => {
									rowCounter++;
									return (
										<TableRow
											key={key + '-' + wilayahIndex + '-' + sumberIndex}
											className={
												rowCounter % 2 === 0
													? 'bg-gray-2 dark:bg-meta-4'
													: 'bg-white dark:bg-boxdark'
											}
										>
											<TableCell className="tbody-text border-b border-l border-r">
												{rowCounter}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{wilayah.nama_kecamatan}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{wilayah.nama_desa}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{sumber.nama_sumber_air_baku}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{Number(spamJpData.kapasitas_terpasang).toFixed(2)}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{Number(spamJpData.kapasitas_produksi).toFixed(2)}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{spamJpData.kapasitas_belum_terpakai}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{spamJpData.jenis_pipa}-
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{spamJpData.panjang_pipa}-
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{spamJpData.diameter_pipa}-
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{spamJpData.status}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{spamJpData.nama_pengelola}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{spamJpData.kuantitas}-
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{spamJpData.kualitas}-
											</TableCell>

											{/* <TableCell className='tbody-text border-b border-r'>
												{spamJpData.jenis_pipa}
												<Button className="w-full bg-red-400"></Button>
											</TableCell>
											<TableCell className='tbody-text border-b border-r'>
												{spamJpData.panjang_pipa}
												<Button className="w-full bg-red-400"></Button>
											</TableCell>
											<TableCell className='tbody-text border-b border-r'>
												{spamJpData.diameter_pipa}
												<Button className="w-full bg-red-400"></Button>
											</TableCell>
											<TableCell className='tbody-text border-b border-r'>{spamJpData.status}</TableCell>
											<TableCell className='tbody-text border-b border-r'>{spamJpData.nama_pengelola}</TableCell>
											<TableCell className='tbody-text border-b border-r'>
												{spamJpData.kuantitas}
												<Button className="w-full bg-red-400"></Button>
											</TableCell>
											<TableCell className='tbody-text border-b border-r'>
												{spamJpData.kualitas}
												<Button className="w-full bg-red-400"></Button>
											</TableCell> */}
										</TableRow>
									);
								}),
							),
						)}
					</TableBody>
				</Table>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};

export default TKondisiSpamJP;
