import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import TableDataCapaianKumulatifAMProv from '@/components/Tables/AirMinum/DataCapaian/Kumulatif/TableDataCapaianKumulatifAMProv';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import TableDataCapaianKumulatifAMBappedaProv from '@/components/Tables/AirMinum/DataCapaian/Kumulatif/TableDataCapaianKumulatifAMBappedaProv';

const DataCapaianKumulatifAMProv = () => {
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
  const wilayah = useSelector((state: RootState) => state.wilayah.value)

  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead={`Data Capaian Kumulatif Air Minum ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
        pageName={`Data Capaian Kumulatif Air Minum ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
      />
      {privilege === 4 || privilege === 10 || privilege === 8 ? wilayah === "provinsi" ? <TableDataCapaianKumulatifAMProv /> : <TableDataCapaianKumulatifAMBappedaProv /> : <></>}
      {privilege === 2 && <TableDataCapaianKumulatifAMProv />}
    </DefaultLayout>
  );
};

export default DataCapaianKumulatifAMProv;
