import React, { useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface SumberAnggaranSelectUIProps {
	getSumberAnggaran: any[];
	setSumberAnggaran: (id_sumber_anggaran: string) => void;
}

const SumberAnggaranSelectUI: React.FC<SumberAnggaranSelectUIProps> = ({
    getSumberAnggaran,
    setSumberAnggaran,
}) => {
    const [selectedValue, setSelectedValue] = useState("");

    return (
        <Select onValueChange={(value) => {
            console.log("Value:", value);
            if (value !== '') {
                const selectedSumberAnggaran = getSumberAnggaran.find(sumberAnggaran => sumberAnggaran.id_sumber_anggaran === value);
                console.log("Selected Sumber Anggaran:", selectedSumberAnggaran);
                if(selectedSumberAnggaran){
                  console.log("Sumber Anggaran yang dipilih:", selectedSumberAnggaran.sumber_anggaran);
                  setSelectedValue(selectedSumberAnggaran.sumber_anggaran || "");
                  setSumberAnggaran(value);
                }
            }
        }}>
            <SelectTrigger>
                <SelectValue placeholder="Pilih Sumber Anggaran">{selectedValue || "Pilih Sumber Aggaran"}</SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getSumberAnggaran ? getSumberAnggaran.map((sumberAnggaran: any, index: any) => (
                     <SelectItem key={index} value={sumberAnggaran.id_sumber_anggaran}>
                        {sumberAnggaran.sumber_anggaran}
                    </SelectItem>
                )) : null}
            </SelectContent>
        </Select>
    );
};

export default SumberAnggaranSelectUI;


