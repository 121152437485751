import DefaultLayout from '../../layout/DefaultLayout';
import { FiPlusCircle } from 'react-icons/fi';
import FormDataWilayahAM from '../../components/Forms/AirMinum/DataDasar/FormDataWilayahAM';
import FormDataDasarAM from '../../components/Forms/AirMinum/DataDasar/FormDataDasarAM';

const DataDasar = () => {
	return (
		<DefaultLayout>
			<div className="container flex mb-5">
				<div className="flex flex-grow justify-start text-3xl font-bold text-[#364878] dark:text-white">
					<span>Data Dasar Air Minum</span>
				</div>
				<div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
					{/* <div className="flex flex-grow justify-end text-lg font-bold w-full md:w-auto">
						<button className="inline-flex items-center justify-center rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiPlusCircle />
							Input Data
						</button>
					</div> */}

					<div className="flex flex-grow justify-end text-lg font-bold w-full md:w-auto mt-2 md:mt-0">
						<button className="inline-flex items-center justify-center rounded-lg bg-[#364878] px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiPlusCircle />
							Upload SK TIM
						</button>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-1 gap-9 md:grid-cols-2">
				{/* kolom kiri */}
				<div className="flex flex-col gap-9">
					{/* <!-- Data Wilayah --> */}
					<FormDataWilayahAM />
				</div>
				{/* kolom kanan */}
				<div className="flex flex-col gap-9">
					{/* <!-- Data Dasar --> */}
					<FormDataDasarAM />
				</div>
			</div>
		</DefaultLayout>
	);
};

export default DataDasar;
