import React, { ReactNode } from 'react';

interface CardDataStatsProps {
  title: string;
  total: string;
  rate: string;
  levelUp?: boolean;
  levelDown?: boolean;
  children1: ReactNode;
  children2: ReactNode;
}

const CardDataStats: React.FC<CardDataStatsProps> = ({
  title,
  // total,
  // rate,
  // levelUp,
  // levelDown,
  children1,
  children2,
}) => {
  return (
    <div className="rounded-sm border h-50 border-stroke bg-white py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark relative overflow-hidden">
      <h4 className="text-title-md font-bold text-[#364878] dark:text-white">
        {title}
      </h4>
      <div className="mt-4 flex items-end justify-between">{children1}</div>
      <div className="flex h-25 w-25 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4 mt-auto absolute -bottom-2 -right-2 shadow-default">
        {children2}
      </div>
    </div>
  );
};

export default CardDataStats;
