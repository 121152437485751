import { RootState } from "@/app/store";
import { CompLoader } from "@/common/Loader";
import EditDataCapaianALProvinsi from "@/components/Forms/AirLimbah/DataCapaian/EditDataCapaianALProvinsi";
import FormDataCapaianALProvinsi from "@/components/Forms/AirLimbah/DataCapaian/FormDataCapaianALProvinsi";
import JudulHeader from "@/components/Header/JudulHeader";
import { Button } from "@/components/ui/Button";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useDeleteDataCapaianALProv, useEditDataCapaianALProv, useGetDataCapaianALProv } from "@/hooks/airLimbah/useDataCapaianAL";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { FiAlertCircle, FiDownload, FiEdit, FiPlusCircle, FiTrash2 } from 'react-icons/fi';
import { useSelector } from "react-redux";
import { toast } from "sonner";
import { utils, writeFile } from "xlsx-js-style";

type TableCapaianSPALDRegionalProps = {
	tabId: number;
	handleTabChange: (e: number) => void
}

const TableCapaianSPALDRegional = ({ tabId, handleTabChange }: TableCapaianSPALDRegionalProps) => {
	const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
	const tahun = useSelector((state: RootState) => state.tahun.value)
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
	const kd_provinsi_dux = useSelector((state: RootState) => state.location.kd_provinsi_dux)
	const nama_provinsi = useSelector((state: RootState) => state.auth.wilayah.nama_provinsi)
	const nama_provinsi_dux = useSelector((state: RootState) => state.location.nama_provinsi)


	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const nama_provinsi_to_use = privilege === 10 ? nama_provinsi_dux : nama_provinsi;

	const { getDataCapaianALProv, isLoading: loadGetDataCapaianALProv } = useGetDataCapaianALProv(Number(kd_provinsi_to_use), Number(tahun))

	const [isConfirmOpen, setIsConfirmOpen] = useState(false)
	const [inputMode, setInputMode] = useState(false)
	const [editMode, setEditMode] = useState(false)
	const [editId, setEditId] = useState(0)
	const [deleteRowId, setDeleteRowId] = useState(0)
	const cardRef = useRef(null)
	const queryClient = useQueryClient()

	const [total, setTotal] = useState({
		rumah: 0,
		sudah_terlayani: 0,
		belum_terlayani: 0
	})
	const [persentaseLayanan, setPersentaseLayanan] = useState({
		sudah_terlayani: 0,
		belum_terlayani: 0,
		capaian: 0
	})

	const { asyncDeleteCapaianALProv, isPending: pendingDeleteCapaianProv } = useDeleteDataCapaianALProv()

	const handleDelete = async (id: any) => {
		try {
			await asyncDeleteCapaianALProv(id)
			toast.success("Berhasil menghapus data capaian!")
			queryClient.invalidateQueries({ queryKey: ["capaian-al-provinsi"] })
			setIsConfirmOpen(false)
		} catch (error) {
			toast.error("Gagal menghapus data capaian!")
		}
	}
	const handleInputMode = () => {
		setInputMode(true)
	}
	const handleEditMode = (id: any) => {
		setEditId(id)
		setEditMode(true)
	}

	const handleDownload = () => {
		const transformedData = getDataCapaianALProv.map((item: any, index: any) => ([
			{ v: index + 1, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.nama_spald_regional, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.wilayah.nama_kabkota, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.wilayah.nama_kecamatan, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.wilayah.nama_desa, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_total, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_sudah_terlayani, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_belum_terlayani, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
		]));

		const header = [
			{ v: "No", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Nama Spald Regional", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Wilayah Pelayanan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
		];

		const header1 = [
			{ v: "Kabupaten / Kota", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Kecamatan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Desa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
		];

		const totalRow = [
			{ v: total.rumah, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.sudah_terlayani, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.belum_terlayani, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
		];

		const persentaseLayananRow = [
			{ v: `${persentaseLayanan.sudah_terlayani.toFixed(2)}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: `${persentaseLayanan.belum_terlayani.toFixed(2)}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(ws, [[{ v: "REKAPITULASI AKSES PELAYANAN AIR LIMBAH", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A1" })
		utils.sheet_add_aoa(ws, [[{ v: "SPM PERKERJAAN UMUM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A2" })
		utils.sheet_add_aoa(ws, [[{ v: `PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A3" })
		utils.sheet_add_aoa(ws, [[{ v: `REGIONAL LINTAS KABUPATEN / KOTA`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A4" })

		utils.sheet_add_aoa(ws, [header], { origin: "A5" })
		utils.sheet_add_aoa(ws, [[{ v: `Target`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "F5" })
		utils.sheet_add_aoa(ws, [[{ v: `Realisasi`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "G5" })
		utils.sheet_add_aoa(ws, [header1], { origin: "C6" })
		utils.sheet_add_aoa(ws, [[{ v: `Total`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "F6" })
		utils.sheet_add_aoa(ws, [[{ v: `Sudah Terlayani`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "G6" })
		utils.sheet_add_aoa(ws, [[{ v: `Belum Terlayani`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "H6" })
		utils.sheet_add_aoa(ws, [[{ v: `(Rumah Tangga)`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "F7" })
		utils.sheet_add_aoa(ws, [[{ v: `(Rumah Tangga)`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "G7" })
		utils.sheet_add_aoa(ws, [[{ v: `(Rumah Tangga)`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "H7" })
		utils.sheet_add_aoa(ws, transformedData, { origin: "A8" })

		utils.sheet_add_aoa(ws, [[{ v: "Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 8}` })
		utils.sheet_add_aoa(ws, [totalRow], { origin: `F${transformedData.length + 8}` })

		utils.sheet_add_aoa(ws, [[{ v: "Persentase Layanan", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [persentaseLayananRow], { origin: `G${transformedData.length + 9}` })

		utils.sheet_add_aoa(ws, [[{ v: "Capaian SPM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 10}` })
		utils.sheet_add_aoa(ws, [[{ v: `${persentaseLayanan.capaian.toFixed(2)}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `G${transformedData.length + 10}` })

		ws['!cols'] = [
			{ wpx: 50 },
			{ wpx: 135 },
			{ wpx: 120 },
			{ wpx: 105 },
			{ wpx: 120 },
			{ wpx: 105 },
			{ wpx: 105 },
			{ wpx: 105 },
		]

		ws['!merges'] = [
			// COLSPANS
			utils.decode_range("A1:H1"),
			utils.decode_range("A2:H2"),
			utils.decode_range("A3:H3"),
			utils.decode_range("A4:H4"),
			utils.decode_range("C5:E5"),
			utils.decode_range("G5:H5"),
			utils.decode_range(`A${transformedData.length + 8}:E${transformedData.length + 8}`),
			utils.decode_range(`A${transformedData.length + 9}:F${transformedData.length + 9}`),
			utils.decode_range(`A${transformedData.length + 10}:F${transformedData.length + 10}`),
			utils.decode_range(`G${transformedData.length + 10}:H${transformedData.length + 10}`),

			// ROWSPANS
			utils.decode_range("A5:A7"),
			utils.decode_range("B5:B7"),
			utils.decode_range("C6:C7"),
			utils.decode_range("D6:D7"),
			utils.decode_range("E6:E7"),
		]

		utils.book_append_sheet(wb, ws, "Kumulatif Air Limbah Provinsi")
		writeFile(wb, `Data Capaian Kumulatif Air Limbah ${nama_provinsi_to_use}  - ${tahun}.xlsx`)
	}

	useEffect(() => {
		if (!loadGetDataCapaianALProv) {
			const totalTargetRumah = getDataCapaianALProv.reduce((acc: any, curr: any) => acc + Number(curr.tgt_total), 0)
			const totalSudahTerlayani = getDataCapaianALProv.reduce((acc: any, curr: any) => acc + Number(curr.real_sudah_terlayani), 0)
			const totalBelumTerlayani = getDataCapaianALProv.reduce((acc: any, curr: any) => acc + Number(curr.real_belum_terlayani), 0)

			const persentaseSudahTerlayani = (totalSudahTerlayani / totalTargetRumah) * 100
			const persentaseBelumTerlayani = (totalBelumTerlayani / totalTargetRumah) * 100

			setTotal({ rumah: totalTargetRumah, sudah_terlayani: totalSudahTerlayani, belum_terlayani: totalBelumTerlayani })

			setPersentaseLayanan({ sudah_terlayani: Number(persentaseSudahTerlayani.toFixed(2)), belum_terlayani: Number(persentaseBelumTerlayani.toFixed(2)), capaian: Number(persentaseSudahTerlayani.toFixed(2)) })
		}
	}, [loadGetDataCapaianALProv, getDataCapaianALProv, tahun, kd_provinsi])

	if (loadGetDataCapaianALProv || pendingDeleteCapaianProv) return <CompLoader />

	if (inputMode) return <FormDataCapaianALProvinsi setInputMode={setInputMode} />
	if (editMode) return <EditDataCapaianALProvinsi id={editId} setEditMode={setEditMode} />

	return (
		<>
			<div className="flex justify-end pb-4">
				{privilege === 4 && getDataCapaianALProv.length !== 0 &&
					< Button
						className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
						onClick={() => handleDownload()}
					>
						<FiDownload />
						Download Data
					</Button>
				}
				{privilege !== 4 && privilege !== 8 && privilege !== 10 && (
					<div className="flex gap-4">
						{getDataCapaianALProv.length !== 0 &&
							< Button
								className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
								onClick={() => handleDownload()}
							>
								<FiDownload />
								Download Data
							</Button>
						}
						<Button
							className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
							onClick={handleInputMode}
						>
							<FiPlusCircle />
							Input Data Capaian
						</Button>
					</div>
				)}
			</div>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 sm:grid-cols-3">
					<div
						className={`py-4 text-center ${tabId === 1 ? 'bg-[#fab23d]' : 'bg-[#364878]'
							} flex cursor-pointer flex-col items-center justify-center rounded-md text-white hover:bg-graydark`}
						onClick={() => handleTabChange(1)}
					>
						<p>Capaian SPALD</p>
						<p>Regional</p>
					</div>
					<div
						className={`py-4 text-center ${tabId === 2 ? 'bg-[#fab23d]' : 'bg-[#364878]'
							} cursor-pointer rounded-md text-white hover:bg-graydark`}
						onClick={() => handleTabChange(2)}
					>
						Rekapitulasi Capaian SPM <br />
						Kabupaten / Kota
					</div>
					<div
						className={`py-4 text-center ${tabId === 3 ? 'bg-[#fab23d]' : 'bg-[#364878]'
							} flex cursor-pointer items-center justify-center rounded-md text-white hover:bg-graydark`}
						onClick={() => handleTabChange(3)}
					>
						<p>Bantuan Keuangan</p>
					</div>
				</div>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader cardRef={cardRef} isLoading={false} minusWidth={32}>
						REKAPITULASI AKSES PELAYANAN AIR LIMBAH <br />
						SPM PERKERJAAN UMUM <br />
						PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK <br />
						REGIONAL LINTAS KABUPATEN / KOTA
					</JudulHeader>
					<div className="sticky top-0 flex items-center justify-start py-4 text-center">
						<div className="inline-flex items-center gap-2 text-center font-bold">
							<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
							pada table dibawah ini didapat dari SIINSAN
						</div>
					</div>
					<Table className="w-full min-w-[1500px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									rowSpan={3}
									className="thead-text border-l text-center"
								>
									No
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Nama SPALD Regional
								</TableHead>
								<TableHead
									colSpan={3}
									className="thead-text border-b-0 text-center"
								>
									Wilayah Pelayanan
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Target
								</TableHead>
								<TableHead
									colSpan={2}
									className="thead-text border-b-0 text-center"
								>
									Realisasi
								</TableHead>
								{privilege !== 4 && privilege !== 8 && privilege !== 10 &&
									<TableHead rowSpan={3} className="thead-text text-center">
										Aksi
									</TableHead>
								}
							</TableRow>
							<TableRow>
								<TableHead rowSpan={2} className="thead-text text-center">
									Kabupaten / Kota
								</TableHead>
								<TableHead rowSpan={2} className="thead-text text-center">
									Kecamatan
								</TableHead>
								<TableHead rowSpan={2} className="thead-text text-center">
									Desa
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Total
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Sudah Terlayani
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Belum Terlayani
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text text-center">
									(Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah Tangga)
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{getDataCapaianALProv.map((item: any, index: any) => (
								<TableRow key={index} className={
									index % 2 === 0
										? 'bg-gray-2 dark:bg-meta-4'
										: 'bg-white dark:bg-boxdark'
								}>
									<TableCell className='tbody-text border-l border-b border-r'>{index + 1}</TableCell>
									<TableCell className='tbody-text border-b border-r'>{item.nama_spald_regional}</TableCell>
									<TableCell className='tbody-text border-b border-r'>{item.wilayah.nama_kabkota}</TableCell>
									<TableCell className='tbody-text border-b border-r'>{item.wilayah.nama_kecamatan}</TableCell>
									<TableCell className='tbody-text border-b border-r'>{item.wilayah.nama_desa}</TableCell>
									<TableCell className='tbody-text border-b border-r'>{item.tgt_total}</TableCell>
									<TableCell className='tbody-text border-b border-r'>{item.real_sudah_terlayani}</TableCell>
									<TableCell className='tbody-text border-b border-r'>{item.real_belum_terlayani}</TableCell>
									{privilege !== 4 && privilege !== 8 && privilege !== 10 &&
										<TableCell className='tbody-text border-b border-r'>
											<div className='flex flex-col items-center justify-center gap-4'>
												{Number(item.verifikasi) !== 1 &&
													<div className='flex gap-4'>
														<Button
															size={'sm'}
															className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'
															onClick={() => handleEditMode(item.id_eksis_al_prov)}
														>
															<FiEdit />
														</Button>
														<Button
															size={'sm'}
															className='inline-flex items-center justify-start gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600'
															onClick={() => {
																setIsConfirmOpen(true)
																setDeleteRowId(item.id_eksis_al_prov)
															}}
														>
															<FiTrash2 />
														</Button>
													</div>
												}
											</div>
										</TableCell>
									}
								</TableRow>
							))}
						</TableBody>
						{!loadGetDataCapaianALProv &&
							<TableFooter>
								<TableRow className='bg-black/10 dark:bg-white/10'>
									<TableCell className='text-lg tfooter-text border border-t-0' colSpan={5}>Total</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.rumah}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.sudah_terlayani}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.belum_terlayani}</TableCell>
								</TableRow>
								<TableRow className='bg-black/10 dark:bg-white/10'>
									<TableCell className='text-lg tfooter-text border border-t-0' colSpan={6}>Persentase Layanan</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{isNaN(persentaseLayanan.sudah_terlayani) ? 0 : persentaseLayanan.sudah_terlayani}%</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{isNaN(persentaseLayanan.belum_terlayani) ? 0 : persentaseLayanan.belum_terlayani}%</TableCell>
								</TableRow>
								<TableRow className='bg-black/10 dark:bg-white/10'>
									<TableCell className='text-lg tfooter-text border border-t-0' colSpan={6}>Capaian SPM</TableCell>
									<TableCell className='tfooter-text border-r border-b' colSpan={2}>{isNaN(persentaseLayanan.capaian) ? 0 : persentaseLayanan.capaian}%</TableCell>
								</TableRow>
							</TableFooter>
						}
					</Table>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
			<ConfirmationDialog
				isDialogOpen={isConfirmOpen}
				setIsDialogOpen={setIsConfirmOpen}
				confirmAction={() => handleDelete(deleteRowId)}
				title="Tunggu Dulu"
				description="Apakah Anda yakin ingin menghapus Data Capaian SPALD Regional ini?"
				confirmButtonColor="bg-red-500 text-white hover:bg-red-600 dark:bg-red-400 dark:hover:bg-red-600" />
		</>
	);
}

export default TableCapaianSPALDRegional