import React, { useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface JenisPelayananUIProps {
	getJenisPelayanan: any[];
	setJenisPelayanan: (id_bencana_pelayanan: string) => void;
}

const JenisPelananaSelectUI: React.FC<JenisPelayananUIProps> = ({
    getJenisPelayanan,
    setJenisPelayanan,
}) => {
    const [selectedValue, setSelectedValue] = useState("");

    return (
        <Select onValueChange={(value) => {
            console.log("Value:", value);
            if (value !== '') {
                const selectedPelayanan = getJenisPelayanan.find(jenisPelayanan => jenisPelayanan.id_bencana_pelayanan === value);
                console.log("Selected Pelayanan:", selectedPelayanan);
                if(selectedPelayanan){
                  console.log("Pelayanan yang dipilih:", selectedPelayanan.nama_pelayanan);
                  setSelectedValue(selectedPelayanan.nama_pelayanan || "");
                  setJenisPelayanan(value);
                }
            }
        }}>
            <SelectTrigger>
                <SelectValue placeholder="Pilih Jenis Pelayanan">{selectedValue || "Pilih Jenis Pelayanan"}</SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getJenisPelayanan ? getJenisPelayanan.map((jenisPelayanan: any, index: any) => (
                     <SelectItem key={index} value={jenisPelayanan.id_bencana_pelayanan}>
                        {jenisPelayanan.nama_pelayanan}
                    </SelectItem>
                )) : null}
            </SelectContent>
        </Select>
    );
};

export default JenisPelananaSelectUI;
