import { API } from '@/libs/API';
import { useMutation, useQuery } from '@tanstack/react-query';

export function useDeleteFile() {
	const {
		mutateAsync: deleteFile,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (id: any) => {
			const response = await API.delete(`/delete-file/?id_file=${id}`);
			return response;
		},
	});
	return { deleteFile, isPending, isSuccess };
}
