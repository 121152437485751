import { API, setAuthToken } from '../../libs/API';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { RootState } from '../../app/store';

export function usePostRencanaPemenuhan() {
	const menu_id = useSelector((state: RootState) => state.menuId.value)

	const {
		mutate: postRencanaPemenuhan,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post(`/add-rencana-pemenuhan?menu_id=${menu_id}`, data);
			return response;
		},
		onSuccess: () => {
			// navigate('/pemenuhan-spm/rencana');
			toast.success('berhasil membuat rencana pemenuhan');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal membuat rencana pemenuhan');
		},
	});
	return { postRencanaPemenuhan, isPending, isSuccess };
}

export function usePostRealisasi() {
	const navigate = useNavigate();
	const { id } = useParams();
	// console.log(id);

	const {
		mutate: postRealisasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`tambah-realisasi-pelaksanaan/?id_rencana=${id}`,
				data,
			);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil membuat realisasi pelaksanaan');
			navigate('/pemenuhan-spm/pelaksanaan/air-minum');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal membuat realisasi pelaksanaan');
		},
	});
	return { postRealisasi, isPending, isSuccess };
}

export function useGetRencanaPemenuhan(tahun: number, bidang: string, kd_provinsi: number, kd_kabkota: number, menu_id: number) {
	const {
		data: getRencanaPemenuhan,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['rencana-pemenuhan', tahun, bidang, kd_provinsi, kd_kabkota, menu_id],
		queryFn: async () => {
			const { data } = await API.get(`/rencana-pemenuhan/?tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&menu_id=${menu_id}`);
			return data.data;
		},
	});
	return { getRencanaPemenuhan, refetch, isLoading };
}

export function useGetPelaksanaanPemenuhan(tahun: number, bidang: string, kd_provinsi: number, kd_kabkota: number, menu_id: number) {
	const {
		data: getPelaksanaanPemenuhan,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['pelaksanaan-pemenuhan', tahun, bidang, bidang, kd_provinsi, kd_kabkota, menu_id],
		queryFn: async () => {
			const { data } = await API.get(`/pelaksanaan-pemenuhan/?tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&menu_id=${menu_id}`);
			return data.data;
		},
	});
	return { getPelaksanaanPemenuhan, refetch, isLoading };
}

export function useGetDetailRencana(paramId?: number) {
	const { id } = useParams();
	const id_to_use = paramId ? paramId : id
	const {
		data: getDetailRencana,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['detail-rencana-pelaksanaan', id_to_use],
		queryFn: async () => {
			const { data } = await API.get(
				`/detail-rencana-pelaksanaan/?id_rencana=${id_to_use}`,
			);
			return data.data;
		},
	});
	return { getDetailRencana, refetch, isLoading };
}

export function useDeleteRencanaPemenuhan() {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const bidang = useSelector((state: RootState) => state.bidang.value)
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota)
	const menu_id = useSelector((state: RootState) => state.menuId.value)

	const { refetch } = useGetRencanaPemenuhan(Number(tahun), bidang, kd_provinsi, kd_kabkota, menu_id);
	const {
		mutate: deleteRencanaPemenuhan,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (id_rencana: number) => {
			const response = await API.delete(
				`/del-rencana-pemenuhan/?id_rencana=${id_rencana}`,
			);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menghapus rencana pemenuhan');
			refetch();
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menghapus rencana pemenuhan');
		},
	});
	return { deleteRencanaPemenuhan, isPending, isSuccess };
}

export function useAddVerifikasiAndCatatan() {
	const { mutateAsync: asyncAddVerifikasiAndCatatan, isPending } = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/verifikasi-pelaksanaan/?id_rencana=${data.id_rpp}`,
				data
			);
			return response;
		},
	})

	return { asyncAddVerifikasiAndCatatan, isPending }
}

export function useEditRencanaPemenuhan() {
	const { mutateAsync: asyncEditRencanaPemenuhan, isPending } = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/edit-rencana-pemenuhan/?id_rencana=${data.id_rencana_pelaksanaan}`,
				data
			);
			return response;
		},
	})

	return { asyncEditRencanaPemenuhan, isPending }
}

// RPP BARU

// GET
export function useGetNewRencanaPemenuhan(tahun: number, bidang: string, kd_provinsi: number, kd_kabkota: number, menu_id: number, wilayah: string) {
	const {
		data: getRencanaPemenuhan,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['rencana-pemenuhan', tahun, bidang, kd_provinsi, kd_kabkota, menu_id, wilayah],
		queryFn: async () => {
			const { data } = await API.get(`/rencana-new/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&menu_id=${menu_id}&tahun=${tahun}`);
			return data.data;
		},
	});
	return { getRencanaPemenuhan, refetch, isLoading };
}

// POST
export function usePostNewRencanaPemenuhan() {
	const {
		mutateAsync: postRencanaPemenuhan,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post(`/add-rencana-new`, data);
			return response;
		},
		onSuccess: () => {
			toast.success('Berhasil membuat rencana pemenuhan!');
		},
		onError: (error) => {
			console.log(error);
			toast.error('Gagal membuat rencana pemenuhan!');
		},
	});
	return { postRencanaPemenuhan, isPending, isSuccess };
}

// EDIT
export function useEditNewRencanaPemenuhan() {
	const { mutateAsync: asyncEditRencanaPemenuhan, isPending } = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/edit-rencana-new/?id_rencana=${data.id_rpp}`,
				data
			);
			return response;
		},
	})

	return { asyncEditRencanaPemenuhan, isPending }
}

export function useDeleteNewRencanaPemenuhan() {
	const {
		mutateAsync: deleteNewRencanaPemenuhan,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (id: number) => {
			const response = await API.delete(
				`/del-rencana-new/?id_rencana=${id}`,
			);
			return response;
		},
	});
	return { deleteNewRencanaPemenuhan, isPending, isSuccess };
}

// GET BY ID
export function useGetByIdNewRencanaPemenuhan(paramId?: number) {
	const { id } = useParams();
	const id_to_use = paramId ? paramId : id
	const {
		data: getDetailRencana,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['rencana-pemenuhan', id_to_use],
		queryFn: async () => {
			const { data } = await API.get(
				`/rencana-new-id/?id_rencana=${id_to_use}`,
			);
			return data.data;
		},
	});
	return { getDetailRencana, refetch, isLoading };
}



// RPP KOMPONEN

// GET BY ID RPP KOMPONEN
export function useGetByIdNewRencanaPemenuhanKomponen(id?: number) {
	const {
		data: getDetailKomponen,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['detail-rencana-komponen', id],
		queryFn: async () => {
			const { data } = await API.get(
				`/rencana-komponen-id/?id_rencana_komponen=${id}`,
			);
			return data.data;
		},
	});
	return { getDetailKomponen, refetch, isLoading };
}

// POST TAMBAH RPP KOMPONEN
export function usePostNewRencanaPemenuhanKomponen(id_rpp: number) {
	const {
		mutateAsync: postKomponen,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post(`/add-rencana-komponen/?id_rencana=${id_rpp}`, data);
			return response;
		},
	});
	return { postKomponen, isPending, isSuccess };
}


// EDIT RPP KOMPONEN
export function useEditNewRencanaPemenuhanKomponen() {
	const { mutateAsync: asyncEditRencanaPemenuhanKomponen, isPending } = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/edit-rencana-komponen/?id_rencana_komponen=${data.id_rencana_komponen}`,
				data
			);
			return response;
		},
	})

	return { asyncEditRencanaPemenuhanKomponen, isPending }
}

// DELETE RPP KOMPONEN
export function useDeleteNewRencanaPemenuhanKomponen() {
	const {
		mutateAsync: deleteNewRencanaPemenuhanKomponen,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-rencana-komponen/?id_rencana_komponen=${data.id_rencana_komponen}`,
			);
			return response;
		},
	});
	return { deleteNewRencanaPemenuhanKomponen, isPending, isSuccess };
}

// RPP KOMPONEN TAMBAH REALISASI
export function useTambahRealisasi() {
	const { mutateAsync: asyncTambahRealisasi, isPending } = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/add-realisasi-komponen/?id_rencana_komponen=${data.id_rencana_komponen}`,
				data
			);
			return response;
		},
	})
	return { asyncTambahRealisasi, isPending }
}

// ADD VERIFIKASI & CATATAN
export function useAddNewVerifikasiAndCatatan() {
	const { mutateAsync: asyncAddVerifikasiAndCatatan, isPending } = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/add-verifikasi-pelaksanaan/?id_rpp=${data.id_rpp}`,
				data
			);
			return response;
		},
	})

	return { asyncAddVerifikasiAndCatatan, isPending }
}