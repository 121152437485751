import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getMenuId = (selectedBidang: string, selectedWilayah: string) => {
	if (selectedBidang === 'air-minum' && selectedWilayah === 'kabupaten')
		return 1;
	if (selectedBidang === 'air-minum' && selectedWilayah === 'provinsi')
		return 5;
	if (selectedBidang === 'air-limbah' && selectedWilayah === 'kabupaten')
		return 2;
	if (selectedBidang === 'air-limbah' && selectedWilayah === 'provinsi')
		return 6;
	if (selectedBidang === 'rumah-bencana' && selectedWilayah === 'kabupaten')
		return 3;
	if (selectedBidang === 'rumah-bencana' && selectedWilayah === 'provinsi')
		return 7;
	if (selectedBidang === 'rumah-relokasi' && selectedWilayah === 'kabupaten')
		return 4;
	if (selectedBidang === 'rumah-relokasi' && selectedWilayah === 'provinsi')
		return 8;
	return 1; // default value
};

export const menuIdSlice = createSlice({
	name: 'menuId',
	initialState: {
		value: getMenuId(
			localStorage.getItem('selectedBidang'),
			localStorage.getItem('selectedWilayah'),
		),
	},
	reducers: {
		setMenuId: (state, action) => {
			state.value = getMenuId(
				action.payload.selectedBidang,
				action.payload.selectedWilayah,
			);
		},
	},
});
export default menuIdSlice.reducer;
