import { configureStore } from '@reduxjs/toolkit';
import wilayahReducer from './wilayah/wilayahSlice';
import tahunReducer from './tahun/tahunSlice';
import bidangReducer from './bidang/bidangSlice';
import resetPasswordReducer from './password/resetPasswordSlice';
import authReducer from './auth/loginSlice';
import menuIdReducer from './menu/menuIdSlice';
import locationReducer from './lokasi/lokasiSlice';
import spamDataReducer from './spamjpData/spamjpSlice';
import spamDataBjpReducer from './spambjpData/spambjpSlice';
import fileReducer from './file/fileSlice';

export const store = configureStore({
	reducer: {
		wilayah: wilayahReducer,
		tahun: tahunReducer,
		bidang: bidangReducer,
		resetPassword: resetPasswordReducer,
		auth: authReducer,
		menuId: menuIdReducer,
		location: locationReducer,
		spamData: spamDataReducer,
		spamDataBJP: spamDataBjpReducer,
		file: fileReducer
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
