import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { API } from '../../libs/API';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

//Get
export function useGetPerumahanTerkenaRelokasi(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: [
			'identifikasi-perumahan-relokasi',
			kd_provinsi,
			kd_kabkota,
			tahun,
		],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/identifikasi-perumahan-relokasi/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetPerumahanKawasanKumuh(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['identifikasi-perumahan-kumuh', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				// `/identifikasi-perumahan-kumuh/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,

				`/identifikasi-perumahan-kumuh/?menu_id=${menu_id}&tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetPerumahanTidakRTRW(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['identifikasi-perumahan-rtrw', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/identifikasi-perumahan-rtrw/?menu_id=${menu_id}&tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetPerumahanLBP(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['identifikasi-perumahan-lbp', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/identifikasi-perumahan-lbp/?menu_id=${menu_id}&tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetPerumahanPB(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['identifikasi-perumahan-pb', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/identifikasi-perumahan-pb/?menu_id=${menu_id}&tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetPerumahanRB(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['identifikasi-perumahan-rb', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/identifikasi-perumahan-rb/?menu_id=${menu_id}&tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function usePengumpulanDataRelokasi(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['relokasi-pengumpulan', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/relokasi-pengumpulan/?menu_id=${menu_id}&tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetDataCapaianRelokasi(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['eksis-relokasi', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/eksis-relokasi/?menu_id=${menu_id}&tahun=${tahun}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}





//Post
export function usePostPerumahanTerkenaRelokasi() {
	const {
		mutate: postPerumahanTerkenaRelokasi,
		mutateAsync: asyncPostPerumahanTerkenaRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/identifikasi-perumahan-relokasi', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postPerumahanTerkenaRelokasi, asyncPostPerumahanTerkenaRelokasi, isPending, isSuccess };
}

export function usePostPerumahanKawasanKumuh() {
	const {
		mutate: postPerumahanKawasanKumuh,
		mutateAsync: asyncPostPerumahanKawasanKumuh,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/identifikasi-perumahan-kumuh', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postPerumahanKawasanKumuh, asyncPostPerumahanKawasanKumuh, isPending, isSuccess };
}

export function usePostPerumahanRTRW() {
	const {
		mutate: postPerumahanRTRW,
		mutateAsync: asyncPostPerumahanRTRW,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/identifikasi-perumahan-rtrw', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postPerumahanRTRW, asyncPostPerumahanRTRW, isPending, isSuccess };
}

export function usePostPerumahanLBP() {
	const {
		mutate: postPerumahanLBP,
		mutateAsync: asyncPostPerumahanLBP,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/identifikasi-perumahan-lbp', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postPerumahanLBP, asyncPostPerumahanLBP, isPending, isSuccess };
}

export function usePostPerumahanPB() {
	const {
		mutate: postPerumahanPB,
		mutateAsync: asyncPostPerumahanPB,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/identifikasi-perumahan-pb', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postPerumahanPB, asyncPostPerumahanPB, isPending, isSuccess };
}

	export function usePostPerumahanRB() {
		const {
			mutate: postPerumahanRB,
			mutateAsync: asyncPostPerumahanRB,
			isPending,
			isSuccess,
		} = useMutation({
			mutationFn: async (data: any) => {
				const response = await API.post('/identifikasi-perumahan-rb', data);
				return response;
			},
			onSuccess: () => {
				toast.success('berhasil menambahkan data');
			},
			onError: (error) => {
				console.log(error);
				toast.error('gagal menambahkan data');
			},
		});
		return { postPerumahanRB, asyncPostPerumahanRB, isPending, isSuccess };
	}
	
	export function usePostPengumpulanDataRumahRelokasi() {
		const {
			mutate: postPengumpulanDataRumahRelokasi,
			mutateAsync: asyncPostPengumpulanDataRumahRelokasi,
			isPending,
			isSuccess,
		} = useMutation({
			mutationFn: async (data: any) => {
				const response = await API.post('/relokasi-pengumpulan', data);
				return response;
			},
			onSuccess: () => {
				toast.success('berhasil menambahkan data');
			},
			onError: (error) => {
				console.log(error);
				toast.error('gagal menambahkan data');
			},
		});
		return { postPengumpulanDataRumahRelokasi, asyncPostPengumpulanDataRumahRelokasi, isPending, isSuccess };
	}


//Edit
export function useEditIdentifikasiPerumahanRelokasi() {
	const {
		mutateAsync: editIdentifikasiPerumahanRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/relokasi-identifikasi-perumahan/?id_relokasi=${data.id_idn_perumahan_relokasi}`,
				data,
			);
			return response;
		},
	});
	return { editIdentifikasiPerumahanRelokasi, isPending, isSuccess };
}

export function useEditIdentifikasiKawasanKumuh() {
	const {
		mutateAsync: editIdentifikasiKawasanKumuh,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/relokasi-identifikasi-perumahan-kumuh/?id_idn_perumahan_kumuh=${data.id_idn_perumahan_kumuh}`,
				data,
			);
			return response;
		},
	});
	return { editIdentifikasiKawasanKumuh, isPending, isSuccess };
}

export function useEditPerumahanRTRW() {
	const {
		mutateAsync: editPerumahanRTRW,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/relokasi-identifikasi-perumahan-rtrw/?id_idn_perumahan_rtrw=${data.id_idn_perumahan_rtrw}`,
				data,
			);
			return response;
		},
	});
	return { editPerumahanRTRW, isPending, isSuccess };
}

export function useEditPerumahanLBP() {
	const {
		mutateAsync: editPerumahanLBP,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/relokasi-identifikasi-perumahan-lbp/?id_idn_perumahan_lbp=${data.id_idn_perumahan_lbp}`,
				data,
			);
			return response;
		},
	});
	return { editPerumahanLBP, isPending, isSuccess };
}

export function useEditPerumahanPB() {
	const {
		mutateAsync: editPerumahanPB,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/relokasi-identifikasi-perumahan-pb/?id_idn_perumahan_pb=${data.id_idn_perumahan_pb}`,
				data,
			);
			return response;
		},
	});
	return { editPerumahanPB, isPending, isSuccess };
}

export function useEditPerumahanRB() {
	const {
		mutateAsync: editPerumahanRB,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/relokasi-identifikasi-perumahan-rb/?id_idn_perumahan_rb=${data.id_idn_perumahan_rb}`,
				data,
			);
			return response;
		},
	});
	return { editPerumahanRB, isPending, isSuccess };
}

export function useEditPengumpulanDataRelokasi() {
	const {
		mutateAsync: editPengumpulanDataRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`pengumpulan-relokasi/?id_relokasi_pengumpulan=${data.id_relokasi_pengumpulan}`,
				data,
			);
			return response;
		},
	});
	return { editPengumpulanDataRelokasi, isPending, isSuccess };
}

export function useGetDataAwalRumahRelokasi({
	menu_id,
	kd_provinsi,
	kd_kabkota,
	tahun,
}) {
	return useInfiniteQuery({
		queryKey: ['eksis-relokasi', menu_id, kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`eksis-relokasi/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

//Delete
export function useDeleteIdentifikasiRumahRelokasi() {
	const {
		mutateAsync: deleteIdentifikasiRumahRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-relokasi-identifikasi-perumahan/?id_relokasi=${data.id_idn_perumahan_relokasi}`,
				data,
			);
			return response;
		},
	});
	return { deleteIdentifikasiRumahRelokasi, isPending, isSuccess };
}

export function useDeletePerumahanKumuh() {
	const {
		mutateAsync: deletePerumahanKumuh,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-relokasi-identifikasi-perumahan-kumuh/?id_idn_perumahan_kumuh=${data.id_idn_perumahan_kumuh}`,
				data,
			);
			return response;
		},
	});
	return { deletePerumahanKumuh, isPending, isSuccess };
}

export function useDeletePerumahanRTRW() {
	const {
		mutateAsync: deletePerumahanRTRW,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-relokasi-identifikasi-perumahan-rtrw/?id_idn_perumahan_rtrw=${data.id_idn_perumahan_rtrw}`,
				data,
			);
			return response;
		},
	});
	return { deletePerumahanRTRW, isPending, isSuccess };
}

export function useDeletePerumahanLBP() {
	const {
		mutateAsync: deletePerumahanLBP,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-relokasi-identifikasi-perumahan-lbp/?id_idn_perumahan_lbp=${data.id_idn_perumahan_lbp}`,
				data,
			);
			return response;
		},
	});
	return { deletePerumahanLBP, isPending, isSuccess };
}

export function useDeletePerumahanPB() {
	const {
		mutateAsync: deletePerumahanPB,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-relokasi-identifikasi-perumahan-pb/?id_idn_perumahan_pb=${data.id_idn_perumahan_pb}`,
				data,
			);
			return response;
		},
	});
	return { deletePerumahanPB, isPending, isSuccess };
}

export function useDeletePerumahanRB() {
	const {
		mutateAsync: deletePerumahanRB,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-relokasi-identifikasi-perumahan-rb/?id_idn_perumahan_rb=${data.id_idn_perumahan_rb}`,
				data,
			);
			return response;
		},
	});
	return { deletePerumahanRB, isPending, isSuccess };
}

export function useDeletePengumpulanDataRelokasi() {
	const {
		mutateAsync: deletePengumpulanDataRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-pengumpulan-relokasi/?id_relokasi_pengumpulan=${data.id_relokasi_pengumpulan}`,
				data,
			);
			return response;
		},
	});
	return { deletePengumpulanDataRelokasi, isPending, isSuccess };
}


//
export function useGetDataCapaianPenetapanRelokasi(
	menu_id: number,
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
) {
	return useInfiniteQuery({
		queryKey: ['eksis-relokasi', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			try {
				const data = await API.get(
					`eksis-relokasi/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`
				);
				return data.data;
			} catch (error) {
				console.error('Error fetching data:', error);
				throw new Error('Error fetching data');
			}
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}


export function usePostPenetapanRelokasi() {
	const {
		mutate: postPenetapanRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/add-penetapan-relokasi', data);
			return response;
		},
		onSuccess: () => {
			toast.success('Penetapan Relokasi Sudah Disimpan');
		},
		onError: (error) => {
			console.log(error);
			toast.error('Gagal menyimpan Penetapan');
		},
	});
	return { postPenetapanRelokasi, isPending, isSuccess };
}

export function useDeletePenetapanRelokasi() {
	const {
		mutateAsync: deletePenetapanRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-penetapan-relokasi/?id_eksis_relokasi=${data.id_eksis_relokasi}`,
				data,
			);
			return response;
		},
	});
	return { deletePenetapanRelokasi, isPending, isSuccess };
}


export function useGetDataCapaianRumahRelokasiById(id: number) {
	const {
		data: getEksisRelokasiById,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['eksis-relokasi-id', id],
		queryFn: async () => {
			const { data } = await API.get(
				`/eksis-relokasi-id/?id_eksis_relokasi=${id}`,
			);
			return data.data;
		},
	});
	return { getEksisRelokasiById, refetch, isLoading };
}

export function useEditDataCapaianRumahRelokasiById() {
	const { mutateAsync: editEksisRelokasiById, isPending } = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/edit-eksis-relokasi/?id_eksis_relokasi=${data.id_eksis_relokasi}`,
				data,
			);
			return response;
		},
	});
	return { editEksisRelokasiById, isPending };
}

export function useDeleteRencanaPemenuhanRumahRelokasi() {
	const {
		mutateAsync: deleteRencanaPemenuhanRumahRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (id: number) => {
			const response = await API.delete(
				`/del-eksis-relokasi/?id_eksis_relokasi=${id}`,
			);
			return response;
		},
	});
	return { deleteRencanaPemenuhanRumahRelokasi, isPending, isSuccess };
}
