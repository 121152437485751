import { API } from "@/libs/API";
import { useMutation } from "@tanstack/react-query";

export function useCreateUser() {
  const {
    mutateAsync: asyncCreateUser,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await API.post('/ada-dehh', data);
      return response;
    },
  });
  return { asyncCreateUser, isPending, isSuccess };
}