import { RootState } from "@/app/store"
import { CompLoader } from "@/common/Loader"
import { Button } from "@/components/ui/Button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useEditAPBDPerProv, usePostAPBDPerProv } from "@/hooks/useAPBDPerProv"
import { formatRupiah } from "@/libs/utils"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "sonner"

type FormTotalAPBD = {
  isEdit: boolean;
  dataAPBD: any;
  setShowForm: any;
}

const FormTotalAPBD = ({ isEdit, dataAPBD, setShowForm }: FormTotalAPBD) => {
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const menu_id = useSelector((state: RootState) => state.menuId.value)
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
  const queryClient = useQueryClient()

  const [totalAPBDProv, setTotalAPBDProv] = useState(dataAPBD.total_apbd_prov)
  const [displayTotal, setDisplayTotal] = useState(formatRupiah(dataAPBD.total_apbd_prov))

  const { postAPBDPerProv, isPending: postPendingAPBD } = usePostAPBDPerProv()
  const { asyncEditAPBDPerProv, isPending: editPendingAPBD } = useEditAPBDPerProv()

  const handleAPBDChange = (e: any) => {
    const value = e.target.value;
    const numericValue = parseFloat(value.replace(/\D/g, ''));
    setTotalAPBDProv(numericValue);
    if (isNaN(numericValue)) {
      setDisplayTotal("Rp 0");
    } else {
      setDisplayTotal(formatRupiah(numericValue));
    }
  }

  const handlePostAPBD = async () => {
    const data = {
      tahun_pelaporan: tahun,
      menu_id,
      kd_provinsi,
      total_apbd_prov: totalAPBDProv
    }
    try {
      await postAPBDPerProv(data)
      toast.success("Total Alokasi APBD berhasil diinput")
      queryClient.refetchQueries({ queryKey: ["total-apbd-prov"] })
      queryClient.refetchQueries({ queryKey: ["bantuan-keuangan"] })
    } catch {
      toast.error("Total Alokasi APBD gagal diinput")
    } finally {
      setShowForm("Lihat Data")
    }
  }
  
  const handleEditAPBD = async () => {
    const data = {
      id_bk_apbd: dataAPBD.id_bk_apbd_provinsi,
      total_apbd_prov: totalAPBDProv
    }
    try {
      await asyncEditAPBDPerProv(data)
      toast.success("Total Alokasi APBD berhasil diedit")
      queryClient.refetchQueries({ queryKey: ["total-apbd-prov"] })
      queryClient.refetchQueries({ queryKey: ["bantuan-keuangan"] })
    } catch {
      toast.error("Total Alokasi APBD gagal diedit")
    } finally {
      setShowForm("Lihat Data")
    }
  }

  const handleSimpanAPBD = () => {
    if (isEdit) {
      handleEditAPBD()
    } else {
      handlePostAPBD()
    }
  }

  if (postPendingAPBD || editPendingAPBD) return <CompLoader />

  return (
    <div className="flex flex-col gap-10 flex-1 w-full justify-center items-center">
      <div className="flex flex-col gap-4">
        <Label htmlFor="Total Alokasi APBD" className="text-3xl text-black">
          Total Alokasi APBD
        </Label>
        <Input
          id="Total Alokasi APBD"
          min="0"
          placeholder="Masukan Total Alokasi APBD"
          value={displayTotal}
          onChange={handleAPBDChange}
        />
      </div>
      <Button
        className="inline-flex items-center justify-center rounded-lg bg-primary px-4 py-2 h-14 text-center text-lg font-medium text-white hover:bg-opacity-90"
        onClick={handleSimpanAPBD}
      >
        Simpan APBD
      </Button>
    </div>
  )
}

export default FormTotalAPBD