import { RootState } from "@/app/store";
import { CompLoader } from "@/common/Loader";
import JudulHeader from "@/components/Header/JudulHeader";
import { Button } from "@/components/ui/Button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useAddNewVerifikasiAndCatatan, useGetNewRencanaPemenuhan } from "@/hooks/rencanaPelaksanaan/useRencanaPemenuhan";
import { useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiDownload, FiPlusCircle, FiSave } from "react-icons/fi";
import { useSelector } from "react-redux";
import { toast } from "sonner";
import Verifikasi from "../Verifikasi";
import { utils, writeFile } from "xlsx-js-style";

const TableDataCapaianTahunanAllBidang = () => {
  const tahun = useSelector((state: RootState) => state.tahun.value);
  const bidang = useSelector((state: RootState) => state.bidang.value)
  const wilayah = useSelector((state: RootState) => state.wilayah.value)
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
  const nama_provinsi = useSelector((state: RootState) => state.auth.wilayah.nama_provinsi)
  const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota)
  const nama_kabkota = useSelector((state: RootState) => state.auth.wilayah.nama_kabkota)
  const kd_kabkota_dux = useSelector((state: RootState) => state.location.kd_kabkota_dux)
  const nama_kabkota_dux = useSelector((state: RootState) => state.location.nama_kabkota)
  const kd_provinsi_dux = useSelector((state: RootState) => state.location.kd_provinsi_dux)
  const nama_provinsi_dux = useSelector((state: RootState) => state.location.nama_provinsi)
  const menu_id = useSelector((state: RootState) => state.menuId.value)

  const kd_kabkota_to_use = privilege === 10 || privilege === 4 || privilege === 8 ? kd_kabkota_dux : kd_kabkota;
  const nama_kabkota_to_use = privilege === 10 || privilege === 4 || privilege === 8 ? nama_kabkota_dux : nama_kabkota;
  const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
  const nama_provinsi_to_use = privilege === 10 ? nama_provinsi_dux : nama_provinsi;

  const { getRencanaPemenuhan, isLoading: loadRencanaPemenuhan, refetch } = useGetNewRencanaPemenuhan(
    Number(tahun),
    bidang,
    kd_provinsi_to_use,
    kd_kabkota_to_use,
    menu_id,
    wilayah
  )

  const [editableDataCapaian, setEditableDataCapaian] = useState<any>([])
  const [uploadDataLoading, setUploadDataLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const [total, setTotal] = useState({
    volume: 0,
    realisasi: 0,
    capaianSPM: 0
  })

  const { asyncAddVerifikasiAndCatatan, isPending: pendingAddVerifikasi } = useAddNewVerifikasiAndCatatan()

  const cardRef = useRef(null)

  useEffect(() => {
    if (!loadRencanaPemenuhan) {
      const totalVolume = getRencanaPemenuhan.reduce((acc: any, item: any) => {
        const itemVolume = item.rencana_komponen.reduce((sum: any, komponen: any) => {
          return sum + parseFloat(komponen.volume);
        }, 0);
        return acc + itemVolume;
      }, 0);

      const totalRealisasi = getRencanaPemenuhan.reduce((acc: any, item: any) => {
        const itemRealisasi = item.rencana_komponen.reduce((sum: any, komponen: any) => {
          return sum + parseFloat(komponen.realisasi);
        }, 0);
        return acc + itemRealisasi;
      }, 0);

      const totalCapaianSPM = (totalRealisasi / totalVolume) * 100

      setTotal(() => ({ volume: totalVolume, realisasi: totalRealisasi, capaianSPM: totalCapaianSPM }))
    }
  }, [loadRencanaPemenuhan, tahun, bidang, wilayah])


  useEffect(() => {
    if (!loadRencanaPemenuhan) {
      setEditableDataCapaian(getRencanaPemenuhan)
    }
  }, [loadRencanaPemenuhan, getRencanaPemenuhan, wilayah, bidang, tahun])

  let rowCounter = 0

  const handleVerifikasi = (e: any, key: any) => {
    const data: any = [...editableDataCapaian]
    if (e.target.checked) {
      data[key].verifikasi = 1
    } else {
      data[key].verifikasi = 0
    }
    setEditableDataCapaian(data)
  }

  const handleCatatan = (e: any, key: any) => {
    const data: any = [...editableDataCapaian]
    data[key].catatan = e.target.value
    setEditableDataCapaian(data)
  }

  const handleSimpanData = async () => {
    setUploadDataLoading(true)
    try {
      const postPromises = editableDataCapaian.map((data: any) => asyncAddVerifikasiAndCatatan(data))
      await Promise.all(postPromises)
      toast.success("Berhasil Update Data Capaian Tahunan")
    } catch (error) {
      toast.error("Gagal Update Data Capaian Tahunan")
    } finally {
      refetch()
      setUploadDataLoading(false)
      setEditMode(false)
    }
  }


  const handleJudul = () => {
    switch (bidang) {
      case "air-minum":
        return <>DATA CAPAIAN TAHUNAN {Number(tahun)} <br />
          SPM PERKERJAAN UMUM <br />
          PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI-HARI</>
      case "air-limbah":
        return <>DATA CAPAIAN <br />
          SPM PERKERJAAN UMUM <br />
          PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK TAHUN {Number(tahun)}</>
      case "rumah-bencana":
        return <>DATA CAPAIAN <br />
          SPM PERUMAHAN RAKYAT <br />
          PENYEDIAAN DAN REHABILITASI RUMAH YANG LAYAK HUNI <br />
          BAGI KORBAN BENCANA KABUPATEN / KOTA {Number(tahun)}</>
      default:
        return <>DATA CAPAIAN <br />
          SPM PERUMAHAN RAKYAT <br />
          PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA <br />
          RELOKASI PROGRAM PEMERINTAH DAERAH KABUPATEN / KOTA {Number(tahun)}</>

    }
  }

  const handleJudulExcel = (ws: any) => {
    const cell1 = { v: "DATA CAPAIAN TAHUNAN 2024", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } };
    const cell2 = { v: "SPM PERKERJAAN UMUM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } };
    const cell3 = { v: `PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI HARI TAHUN ${tahun}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } };
    const cell4 = { v: `PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK TAHUN ${tahun}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } };
    const cell5 = { v: `PENYEDIAAN DAN REHABILITASI RUMAH YANG LAYAK HUNI <br /> BAGI KORBAN BENCANA KABUPATEN / KOTA ${tahun}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } };
    const cell6 = { v: `PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA <br /> RELOKASI PROGRAM PEMERINTAH DAERAH KABUPATEN / KOTA ${tahun}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } };
    utils.sheet_add_aoa(ws, [[cell1]], { origin: "A1" });
    utils.sheet_add_aoa(ws, [[cell2]], { origin: "A2" });
    switch (bidang) {
      case "air-minum":
        utils.sheet_add_aoa(ws, [[cell3]], { origin: "A3" });
        break;
      case "air-limbah":
        utils.sheet_add_aoa(ws, [[cell4]], { origin: "A3" });
        break;
      case "rumah-bencana":
        utils.sheet_add_aoa(ws, [[cell5]], { origin: "A3" });
        break;
      default:
        utils.sheet_add_aoa(ws, [[cell6]], { origin: "A3" });
        break;
    }
  }

  const handleDownload = () => {
    const data = getRencanaPemenuhan
    const header = [
      { v: "No", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Paket Perkerjaan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Rincian Perkerjaan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Target Tahunan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
    ];

    const header1 = [
      { v: "Realisasi Tahunan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Verifikasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Catatan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Capaian Tahunan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
    ];

    const header2 = [
      { v: "Komponen", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Satuan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Volume", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Realisasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
    ];

    let rows: any = [];

    data.forEach((item: any, index: any) => {
      if (item.rencana_komponen.length === 0) {
        let row = [
          { v: index + 1, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: item.nama_kegiatan, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: item.nomenklatur.nama_nomenklatur, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: item.verifikasi ? "SUDAH TERVERIFIKASI" : "BELUM TERVERIFIKASI", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: item.catatan || "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
          { v: "0.00%", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
        ];
        rows.push(row);
      } else {
        item.rencana_komponen.forEach((komponen: any, komponenIndex: any) => {
          let row = [
            { v: komponenIndex === 0 ? index + 1 : "", t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: komponenIndex === 0 ? item.nama_kegiatan : "", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: komponenIndex === 0 ? item.nomenklatur.nama_nomenklatur : "", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: komponen.komponen.komponen_name ?? "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: komponen.satuan.satuan_name ?? "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: komponen.volume ?? "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: komponen.realisasi ?? "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: item.verifikasi ? "SUDAH TERVERIFIKASI" : "BELUM TERVERIFIKASI", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: item.catatan || "-", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
            { v: ((komponen.realisasi / komponen.volume) * 100).toFixed(2) + "%", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
          ];
          rows.push(row);
        });
      }
    });

    const totalRow = [
      { v: total.volume.toFixed(2), t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: total.realisasi.toFixed(2), t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: "-", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: "-", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: "-", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
    ]
    let wb = utils.book_new(),
      ws = utils.aoa_to_sheet([]);

    handleJudulExcel(ws)

    utils.sheet_add_aoa(ws, [header], { origin: "A4" })
    utils.sheet_add_aoa(ws, [header1], { origin: "G4" })
    utils.sheet_add_aoa(ws, [header1], { origin: "G4" })
    utils.sheet_add_aoa(ws, [header2], { origin: "D5" })

    ws['!cols'] = [
      { wpx: 50 },
      { wpx: 107 },
      { wpx: 666 }, // WKAOKWOAKOWKAOWKAOKWAOKO
      { wpx: 100 },
      { wpx: 57 },
      { wpx: 53 },
      { wpx: 112 },
      { wpx: 140 },
      { wpx: 200 },
      { wpx: 110 },
    ]

    ws['!merges'] = [
      // COLSPAN
      utils.decode_range("A1:J1"),
      utils.decode_range("A2:J2"),
      utils.decode_range("A3:J3"),
      utils.decode_range("D4:F4"),
      utils.decode_range(`A${rows.length + 6}:E${rows.length + 6}`),
      utils.decode_range(`A${rows.length + 7}:I${rows.length + 7}`),

      // ROWSPAN
      utils.decode_range("A4:A5"),
      utils.decode_range("B4:B5"),
      utils.decode_range("C4:C5"),
      utils.decode_range("H4:H5"),
      utils.decode_range("I4:I5"),
      utils.decode_range("J4:J5"),
    ]

    utils.sheet_add_aoa(ws, rows, { origin: "A6" })

    utils.sheet_add_aoa(ws, [[{ v: "TOTAL", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${rows.length + 6}` })
    utils.sheet_add_aoa(ws, [totalRow], { origin: `F${rows.length + 6}` })

    utils.sheet_add_aoa(ws, [[{ v: "CAPAIAN SPM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${rows.length + 7}` })
    utils.sheet_add_aoa(ws, [[{ v: total.capaianSPM.toFixed(2), t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },]], { origin: `J${rows.length + 7}` })

    utils.book_append_sheet(wb, ws, "Tahunan Air Minum")
    writeFile(wb, `Data Capaian Tahunan ${bidang} ${nama_provinsi_to_use ? `- ${nama_provinsi_to_use}` : "-"} ${nama_kabkota_to_use ? `- ${nama_kabkota_to_use}` : "-"} ${tahun}.xlsx`)
  }

  if (loadRencanaPemenuhan || uploadDataLoading || pendingAddVerifikasi) return <CompLoader />

  return (
    <>
      {(privilege === 3 && wilayah === "kabupaten") || (privilege === 4 && wilayah === "provinsi") ? (
        <div className='flex justify-end pb-4'>
          <div className="flex gap-4">
            {editMode ?
              <>
                <Button
                  className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                  onClick={() => setEditMode(false)}
                >
                  <FiArrowLeft />
                  Kembali
                </Button>
                <Button
                  className="inline-flex items-center justify-center rounded-lg gap-2 bg-green-500 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                  onClick={handleSimpanData}
                >
                  <FiSave />
                  Simpan Verifikasi
                </Button>
              </>
              :
              <>
                <Button
                  className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                  onClick={() => handleDownload()}
                >
                  <FiDownload />
                  Download Data
                </Button>
                <Button
                  className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                  onClick={() => setEditMode(true)}
                >
                  <FiPlusCircle />
                  Tambah Verifikasi
                </Button>
              </>
            }
          </div>
        </div>
      ) : (
        getRencanaPemenuhan.length !== 0 &&
        <div className='flex justify-end pb-4'>
          <div className="flex gap-4">
            <Button
              className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
              onClick={() => handleDownload()}
            >
              <FiDownload />
              Download Data
            </Button>
          </div>
        </div>
      )}
      <div ref={cardRef} className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col flex-1 overflow-hidden">
        <ScrollArea className='h-full p-4 relative'>
          <JudulHeader isLoading={loadRencanaPemenuhan} cardRef={cardRef} minusWidth={32}>
            {handleJudul()}
          </JudulHeader>
          <Table className="min-w-[1500px] w-full table-auto">
            <TableHeader className=" bg-gray-2 dark:bg-meta-4 sticky top-0">
              <TableRow>
                <TableHead rowSpan={2} className="thead-text text-center border-l">
                  No
                </TableHead>
                <TableHead rowSpan={2} className="thead-text text-center">
                  Paket Perkerjaan
                </TableHead>
                <TableHead rowSpan={2} className="thead-text text-center">
                  Rincian Perkerjaan
                </TableHead>
                <TableHead colSpan={3} className="thead-text border-b-0 text-center">
                  Target Tahunan
                </TableHead>
                <TableHead className="thead-text  border-b-0 text-center">
                  Realisasi Tahunan
                </TableHead>
                <TableHead rowSpan={2} className="thead-text text-center">
                  Verifikasi
                </TableHead>
                <TableHead rowSpan={2} className="thead-text text-center">
                  Catatan
                </TableHead>
                <TableHead rowSpan={2} className="thead-text text-center">
                  Capaian Tahunan
                </TableHead>
              </TableRow>
              <TableRow>
                <TableHead className="thead-text text-center">
                  Komponen
                </TableHead>
                <TableHead className="thead-text text-center">
                  Satuan
                </TableHead>
                <TableHead className="thead-text text-center">
                  Volume
                </TableHead>
                <TableHead className="thead-text text-center">
                  Volume
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {editableDataCapaian.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map((item: any, key: any) => {
                rowCounter++
                const totalRealisasi = item.rencana_komponen.reduce((sum: any, komponen: any) => sum + parseFloat(komponen.realisasi), 0);
                const totalVolume = item.rencana_komponen.reduce((sum: any, komponen: any) => sum + parseFloat(komponen.volume), 0);
                const capaianTahunan = (totalRealisasi / totalVolume) * 100
                return (
                  <TableRow key={key} className={
                    rowCounter % 2 === 0
                      ? 'bg-gray-2 dark:bg-meta-4'
                      : 'bg-white dark:bg-boxdark'}>
                    <TableCell className="tbody-text border-l border-b border-r">{rowCounter}</TableCell>
                    <TableCell className="tbody-text border-b border-r">{item.nama_kegiatan}</TableCell>
                    <TableCell className="tbody-text border-b border-r">{item.nomenklatur.nama_nomenklatur}</TableCell>
                    <TableCell style={{ padding: 0 }} className='border-b border-r'>
                      <table className='w-full'>
                        <tbody className='w-full'>
                          {item.rencana_komponen.length !== 0 ?
                            item.rencana_komponen.map((item: any, index: any) => (
                              <TableRow key={index} className='flex items-center justify-center'>
                                <TableCell key={index} className='tbody-text flex items-center justify-center'>
                                  {item.komponen.komponen_name}
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow className='flex items-center justify-center'>
                              <TableCell className='flex items-center justify-center'>
                                -
                              </TableCell>
                            </TableRow>
                          }
                        </tbody>
                      </table>
                    </TableCell>
                    <TableCell style={{ padding: 0 }} className='border-b border-r'>
                      <table className='w-full'>
                        <tbody className='w-full'>
                          {item.rencana_komponen.length !== 0 ?
                            item.rencana_komponen.map((item: any, index: any) => (
                              <TableRow key={index} className='flex flex-col items-center justify-center'>
                                <TableCell key={index} className='tbody-text flex items-center justify-center'>
                                  {item.satuan?.satuan_name ?? "-"}
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow className='flex flex-col items-center justify-center'>
                              <TableCell className='flex items-center justify-center'>
                                -
                              </TableCell>
                            </TableRow>
                          }
                        </tbody>
                      </table>
                    </TableCell>
                    <TableCell style={{ padding: 0 }} className='border-b border-r'>
                      <table className='w-full'>
                        <tbody className='w-full'>
                          {item.rencana_komponen.length !== 0 ?
                            item.rencana_komponen.map((item: any, index: any) => (
                              <TableRow key={index} className='flex items-center justify-center'>
                                <TableCell key={index} className='tbody-text flex items-center justify-center'>
                                  {item.volume}
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow className='flex items-center justify-center'>
                              <TableCell className='flex items-center justify-center'>
                                -
                              </TableCell>
                            </TableRow>
                          }
                        </tbody>
                      </table>
                    </TableCell>
                    <TableCell style={{ padding: 0 }} className='border-b border-r'>
                      <table className='w-full'>
                        <tbody className='w-full'>
                          {item.rencana_komponen.length !== 0 ?
                            item.rencana_komponen.map((item: any, index: any) => (
                              <TableRow key={index} className='flex flex-col items-center justify-center'>
                                <TableCell key={index} className='tbody-text flex items-center justify-center'>
                                  {item.realisasi}
                                </TableCell>
                              </TableRow>
                            ))
                            :
                            <TableRow className='flex flex-col items-center justify-center'>
                              <TableCell className='flex items-center justify-center'>
                                -
                              </TableCell>
                            </TableRow>
                          }
                        </tbody>
                      </table>
                    </TableCell>
                    <TableCell className='border-b border-r'>
                      {editMode ?
                        <input type='checkbox' checked={editableDataCapaian[key].verifikasi === 1} value={editableDataCapaian[key].verifikasi} onChange={(e) => handleVerifikasi(e, key)} />
                        : <Verifikasi verifikasi={item.verifikasi} />
                      }
                    </TableCell>
                    <TableCell className='tbody-text border-b border-r'>
                      {editMode ?
                        <textarea placeholder="Catatan Tambahan" className="resize-none border border-slate-500 placeholder:text-black placeholder:opacity-50 placeholder:font-normal h-[100px]" defaultValue={item.catatan} onChange={(e) => handleCatatan(e, key)}></textarea>
                        :
                        item.catatan ? item.catatan : "-"
                      }
                    </TableCell>
                    <TableCell className="tbody-text border-b border-r">{isNaN(capaianTahunan) ? 0 : capaianTahunan.toFixed(2)}%</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className='bg-black/10 dark:bg-white/10 border-l border-b border-r text-lg tfooter-text' colSpan={5}>Total</TableCell>
                <TableCell className='bg-black/10 dark:bg-white/10 tfooter-text border-b border-r'>{total.volume.toFixed(2)}</TableCell>
                <TableCell className='bg-black/10 dark:bg-white/10 tfooter-text border-b border-r'>{total.realisasi.toFixed(2)}</TableCell>
                <TableCell className='bg-black/10 dark:bg-white/10 tfooter-text border-b border-r'>-</TableCell>
                <TableCell className='bg-black/10 dark:bg-white/10 tfooter-text border-b border-r'>-</TableCell>
                <TableCell className='bg-black/10 dark:bg-white/10 tfooter-text border-b border-r'>-</TableCell>
              </TableRow>
              <TableRow className='bg-black/10 dark:bg-white/10'>
                <TableCell className='text-lg tfooter-text border-l border-b border-r' colSpan={9}>Capaian SPM</TableCell>
                <TableCell className='tfooter-text border-b border-r'>{isNaN(total.capaianSPM) ? 0 : total.capaianSPM.toFixed(2)}%</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        <div className="dari-mk-untuk-pupr" />
      </div>
    </>
  )
}

export default TableDataCapaianTahunanAllBidang