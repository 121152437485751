import React, { useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface JenisLokasiTidakSesuaiProps {
    getJenisLokasi: any[];
    setJenisLokasi: (id: string) => void;
}


const JenisLokasiTidakSesuaiSelectUI: React.FC<JenisLokasiTidakSesuaiProps> = ({
    getJenisLokasi,
    setJenisLokasi
}) => {
    const [selectedJenisLokasi, setSelectedJenisLokasi] = useState('');

    return(
        <Select
            onValueChange={(value) => {
                    if (value !== '') {
                        const selectedJenisLokasi = getJenisLokasi.find((jenisLokasi: any) => jenisLokasi.id === value);
                        if(selectedJenisLokasi){
                            console.log("Jenis Lokasi yang dipilih:", selectedJenisLokasi.nama_jenis_lks_tdk_sesuai);
                            setSelectedJenisLokasi(selectedJenisLokasi.nama_jenis_lks_tdk_sesuai || "");
                            setJenisLokasi(value);
                        }
                    }
                }}
            >
                <SelectTrigger>
                    <SelectValue placeholder="Pilih Jenis Lokasi">
                        {selectedJenisLokasi || 'Pilih Jenis Lokasi'}
                    </SelectValue>
                </SelectTrigger>
                <SelectContent className='z-99999'>
                    {getJenisLokasi.map((jenisLokasi: any, index: any) => (
                        <SelectItem key={index} value={jenisLokasi.id}>
                            {jenisLokasi.nama_jenis_lks_tdk_sesuai}
                        </SelectItem>
                    ))}
                </SelectContent>
        </Select>
    )
}

export default JenisLokasiTidakSesuaiSelectUI;


