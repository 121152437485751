import React, { useState, useEffect } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

interface KabKotaProps {
    getKabKota: any[];
    setKabKota: (kd_kabkota: number) => void;
    selectedValue: number;
    disabledSelect: boolean;
}

const EditKabKotaSelectUI: React.FC<KabKotaProps> = ({
    getKabKota,
    setKabKota,
    selectedValue,
    disabledSelect
}) => {
    const [selectedKabKota, setSelectedKabKota] = useState<string | undefined>(undefined);

    useEffect(() => {
        const selectedKabKota = getKabKota.find((kabupaten: any) => kabupaten.kd_kabkota === selectedValue);
        if (selectedKabKota) {
            setSelectedKabKota(selectedKabKota.nama_kabkota);
        }
    }, [selectedValue, getKabKota]);

    return (
        <Select
            onValueChange={(value) => {
                if (value !== '') {
                    const kd_kabkota = parseInt(value, 10);
                    const selectedKabKota = getKabKota.find((kabupaten: any) => kabupaten.kd_kabkota === kd_kabkota);
                    if (selectedKabKota) {
                        // console.log("kabupaten yang dipilih:", selectedKabKota.nama_kabkota, "kode:", kd_kabkota);
                        setSelectedKabKota(selectedKabKota.nama_kabkota);
                        setKabKota(kd_kabkota);
                    }
                }
            }}
            value={selectedValue.toString()}
            disabled={disabledSelect}
        >
            <SelectTrigger>
                <SelectValue placeholder="Pilih Kabupaten">
                    {selectedKabKota || 'Pilih Kabupaten'}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {Array.isArray(getKabKota) ? (
                    getKabKota.map((kabupaten: any, index: any) => (
                        <SelectItem key={index} value={kabupaten.kd_kabkota.toString()}>
                            {kabupaten.nama_kabkota}
                        </SelectItem>
                    ))
                ) : (
                    <div>Error loading kecamatan</div>
                )}
            </SelectContent>
        </Select>
    );
};

export default EditKabKotaSelectUI;
