import { useSelector } from 'react-redux';
import { useState } from 'react';

import DefaultLayout from '../../../layout/DefaultLayout';
import { RootState } from '@/app/store';
import TableDataCapaianPer from '@/components/Tables/AirMinum/PengumpulanData/TableDataCapaianPer';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import TKondisiSpamJP from '@/components/Tables/AirMinum/PengumpulanData/TKondisiSpamJP';
import TKondisiSpamBJP from '@/components/Tables/AirMinum/PengumpulanData/TKondisiSpamBJP';
import TBerkasAM from '@/components/Tables/AirMinum/PengumpulanData/TBerkasAM';
import { useMenuAirMinum } from '@/hooks/menu/useMenu';
import { TKondisiSpamRegional } from '@/components/Tables/AirMinum/PengumpulanData/TKondisiSpamRegional';
import { TKebutuhanAirMinum } from '@/components/Tables/AirMinum/PengumpulanData/TKebutuhanAirMinum';
import TableDataAirMinumCurah from '@/components/Tables/AirMinum/DataCapaian/Kumulatif/TableDataAirMinumCurah';
import TableDataAirMinumCurahProv from '@/components/Tables/AirMinum/DataCapaian/Kumulatif/TableDataAirMinumCurahProv';

const PengumpulanDataAirMinum = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const [activeTab, setActiveTab] = useState('Data Capaian');
	const { isMenuAirMinumProv } = useMenuAirMinum();

	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead="Pengumpulan Data Air Minum"
				pageName="Pengumpulan Data"
			/>
			<div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 dark:bg-graydark sm:grid-cols-4">
				{isMenuAirMinumProv ? (
					<>
						<div
							className={`py-4 text-center ${
								activeTab === 'Kondisi SPAM Regional'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('Kondisi SPAM Regional')}
						>
							Kondisi SPAM
							<br />
							Regional
						</div>
						<div
							className={`py-4 text-center ${
								activeTab === 'Kebutuhan Air Minum Curah'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('Kebutuhan Air Minum Curah')}
						>
							Kebutuhan
							<br />
							Air Minum Curah
						</div>
						<div
							className={`py-4 text-center ${
								activeTab === 'Data Air Minum Curah'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('Data Air Minum Curah')}
						>
							Data
							<br />
							{`Air Minum Curah ${Number(tahun) - 1}`}
						</div>
					</>
				) : (
					<>
						<div
							className={`py-4 text-center ${
								activeTab === 'Kondisi spam jp'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('Kondisi spam jp')}
						>
							Kondisi SPAM
							<br />
							Jaringan Perpipaan
						</div>
						<div
							className={`py-4 text-center ${
								activeTab === 'Kondisi spam bjp'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('Kondisi spam bjp')}
						>
							Kondisi SPAM
							<br />
							Bukan Jaringan Perpipaan
						</div>
						<div
							className={`py-4 text-center ${
								activeTab === 'Data Capaian'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('Data Capaian')}
						>
							Rekapitulasi Akses
							<br />
							{`Air Minum ${Number(tahun) - 1}`}
						</div>
					</>
				)}
				<div
					className={`flex items-center justify-center py-4 ${
						activeTab === 'BerkasAM'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('BerkasAM')}
				>
					<p>Berkas</p>
				</div>
			</div>
			{activeTab === 'Kondisi SPAM Regional' && <TKondisiSpamRegional />}
			{activeTab === 'Kebutuhan Air Minum Curah' && <TKebutuhanAirMinum />}
			{activeTab === 'Data Air Minum Curah' && (
				<TableDataAirMinumCurahProv tahun={Number(tahun) - 1} />
			)}
			{activeTab === 'Kondisi spam jp' && <TKondisiSpamJP />}
			{activeTab === 'Kondisi spam bjp' && <TKondisiSpamBJP />}
			{activeTab === 'Data Capaian' && (
				<TableDataCapaianPer tahun={Number(tahun) - 1} />
			)}
			{activeTab === 'BerkasAM' && <TBerkasAM />}
		</DefaultLayout>
	);
};

export default PengumpulanDataAirMinum;
