import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import { Button } from '@/components/ui/Button';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
	useDeletePenetapanBencana,
	useGetDataCapaianPenetapanBencana,
	useGetFile,
	usePostFile,
	usePostPenetapanBencana,
} from '@/hooks/perumahan/useRumahBencana';
import { usePrivilegeRumahBencana } from '@/hooks/privilege/usePrivilege';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useRef, useState, useEffect } from 'react';
import {
	FiAlertCircle,
	FiCheckCircle,
	FiTrash,
	FiUpload,
} from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import PenetapanTidakAdaBencana from '../DataCapaian/Kumulatif/PenetapanTidakAdaBencana';
import TBerkasRawanBencana from './TBerkasRumahBencana';
import { Question } from './QuestionBencana';

type Props = {
	setActiveTab?: any;
	penetapanBencana?: any;
	setPenetapanBencana?: any;
	adaBerkas?: any;
	setAdaBerkas?: any;
	adaBencana?: any;
	setAdaBencana?: any;
};

const CekBencanaLimaTahun = ({
	penetapanBencana,
	setPenetapanBencana,
	adaBerkas,
	setAdaBerkas,
	adaBencana,
	setAdaBencana,
	setActiveTab,
}: Props) => {
	const inputDokumenRef = useRef<HTMLInputElement>(null);
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	const [terdampak, setTerdampak] = useState<any>(false);
	const [sk_bencana, setSk_bencana] = useState<any>(false);
	const [uploadSK, setUploadSK] = useState<any>(false);
	const [showUploadSKBencana, setShowUploadSKBencana] = useState<any>(false);

	const handleSimpan = () => {
		if (terdampak && sk_bencana && uploadSK) {
			setShowUploadSKBencana(true);
		}

		if (!terdampak || !sk_bencana || !uploadSK) {
			handleSubmitPenetapanBencana();
		}
	};

	//previlege
	const { isPrevilegeViewRumahBencana, kd_kabkota_to_use, kd_provinsi_to_use } =
		usePrivilegeRumahBencana();

	const [loading, setLoading] = useState(false);
	const [uploadDokumen, setUploadDokumen] = useState<File | null>(null);
	const jenis_up_id = 4;
	const [idPenetapanBencana, setIdPenetapanBencana] = useState('0');

	//additonalsUstate
	const cardRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const { data: dataPenetapanBencana, isLoading: loadPenetapanBencana } =
		useGetDataCapaianPenetapanBencana(
			user.kd_provinsi,
			user.kd_kabkota,
			Number(tahun),
			menuId,
		);

	//getFile
	const { data: dataBerkas, isLoading: loadingBerkas } = useGetFile(
		kd_provinsi_to_use,
		kd_kabkota_to_use,
		Number(tahun),
		menuId,
		jenis_up_id,
	);

	//Post&Delete
	const { postFile } = usePostFile();
	const { postPenetapanBencana } = usePostPenetapanBencana();
	const { deletePenetapanBencana } = useDeletePenetapanBencana();

	//kondisi mencari berkas
	const findBerkas = () => {
		const data = dataBerkas!.pages.flatMap((page) => page.data);
		const hasBerkas = data.length > 0;
		setAdaBerkas(hasBerkas);
		if (hasBerkas) {
			setAdaBencana('ya');
		} else {
			setAdaBencana('tidak');
		}
	};

	const handleOpenDokumen = () => {
		if (inputDokumenRef.current) {
			inputDokumenRef.current.click();
		}
	};

	const handleDeleteDokumen = () => {
		setUploadDokumen(null);
	};

	const handleOnChangeOpenDokumen = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			if (file.size > 50 * 1024 * 1024) {
				alert('File size must be less than 50MB');
				return;
			}
			if (
				file.type !== 'application/pdf' &&
				file.type !==
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			) {
				alert('File must be a PDF or Excel');
				return;
			}
			setUploadDokumen(file);
			e.target.value = '';
		}
	};

	// Post PenetapanBencana
	const handleSubmitPenetapanBencana = () => {
		setLoading(true);

		const data = {
			menu_id: Number(menuId),
			kd_provinsi: Number(user.kd_provinsi),
			kd_kabkota: Number(user.kd_kabkota),
			tahun_pelaporan: Number(tahun),
		};

		postPenetapanBencana(data, {
			onSuccess: () => {
				console.log('Data submitted successfully:', data);
				queryClient.invalidateQueries({ queryKey: ['eksis-rawan-bencana'] });
				setLoading(false);
				setPenetapanBencana(true);
				setActiveTab('IdentifikasiPerumahan');
			},
			onError: (error) => {
				console.error('Error submitting data:', error);
				setLoading(false); // Set loading to false
			},
		});
	};

	// Batalkan PenetapanBencana
	const handleBatalkanPenetapanBencana = async () => {
		setLoading(true);
		try {
			await deletePenetapanBencana({
				id_eksis_bencana: idPenetapanBencana,
			});
			queryClient.refetchQueries({ queryKey: ['eksis-rawan-bencana'] });
			toast.success('Berhasil melakukan penetapan bencana');
			queryClient.invalidateQueries({ queryKey: ['eksis-rawan-bencana'] });
			setLoading(false);
			setPenetapanBencana(false);
			setActiveTab('IdentifikasiPerumahan');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal melakukan penetapan bencana');
			setLoading(false);
		}
	};

	//upload dokumen
	const handleUploadFileSKBencana = () => {
		setIsLoading(true);
		const data = {
			tahun_upload: tahun,
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: user.kd_kabkota,
			jenis_up_id: jenis_up_id,
			file_upload: uploadDokumen,
		};

		postFile(data, {
			onSuccess: () => {
				console.log('File uploaded successfully');
				queryClient.invalidateQueries({ queryKey: ['get-file-bnba'] });
				setIsLoading(false);
				setActiveTab('IdentifikasiPerumahan');
			},
			onError: (error) => {
				console.log(error);
				setIsLoading(false);
			},
		});
	};

	useEffect(() => {
		if (!loadPenetapanBencana) {
			const data = dataPenetapanBencana!.pages.flatMap((page) => page.data);
			setIdPenetapanBencana(data[0]?.id_eksis_bencana ?? '0');
		}
	}, [loadPenetapanBencana, penetapanBencana]);

	useEffect(() => {
		if (!loadingBerkas) {
			findBerkas();
		}
	}, [loadingBerkas, dataBerkas]);

	if (loading) return <CompLoader />;

	if (isPrevilegeViewRumahBencana) {
		return (
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea>
					{adaBerkas ? (
						<>
							<Label className="flex items-center justify-center text-2xl font-semibold text-[#364878] dark:text-white">
								Dokumen Dibawah Ini Merupakan SK Penetapan Bencana
							</Label>
							<TBerkasRawanBencana jenis_up_id={jenis_up_id} />
						</>
					) : !penetapanBencana ? (
						<>
							<Label
								className="flex items-center justify-center text-center text-2xl font-semibold text-[#364878] dark:text-white"
								htmlFor="dokumen-verifikasi"
							>
								Belum Ada Penetapan Bencana <br /> Pada Tahun {Number(tahun)}
							</Label>
						</>
					) : (
						<>
							<PenetapanTidakAdaBencana />
						</>
					)}
				</ScrollArea>
			</div>
		);
	}

	if (penetapanBencana) {
		return (
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea>
					{!isPrevilegeViewRumahBencana && (
						<>
							<div className="flex flex-col items-center justify-center gap-10">
								<Label
									className="flex items-center justify-center text-2xl font-semibold text-[#364878] dark:text-white"
									htmlFor="dokumen-verifikasi"
								>
									Tidak ada bencana pada tahun {Number(tahun)}
								</Label>
								<Button
									className="flex items-center justify-center bg-red-500 text-white dark:text-white"
									onClick={handleBatalkanPenetapanBencana}
								>
									Batalkan Penetapan
								</Button>
							</div>
						</>
					)}
				</ScrollArea>
			</div>
		);
	}

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea>
				<div className="flex items-center justify-start text-center">
					<div className="inline-flex w-full items-center gap-2 rounded-md bg-slate-50 p-2 text-start font-bold">
						<div className="mx-4 flex items-center gap-2">
							<FiAlertCircle className="text-3xl text-red-500" /> Catatan
						</div>
						YA : Jika ada bencana pada tahun {Number(tahun) - 5} - {tahun} maka
						Diwajibkan untuk melakukan Upload SK Penetapan Bencana
						<br />
						Tidak : Jika tidak ada bencana pada tahun {Number(tahun) - 5} -{' '}
						{tahun} maka Capaian Pemenuhan SPM Menjadi 100%
					</div>
				</div>

				{/* Upload SK Bencana */}
				{adaBerkas ? (
					<>
						<>
							<Label className="flex items-center justify-center text-2xl font-semibold text-[#364878] dark:text-white">
								Dokumen Dibawah Ini Merupakan SK Penetapan Bencana
							</Label>
							<TBerkasRawanBencana jenis_up_id={jenis_up_id} />
						</>
					</>
				) : (
					<>
						{showUploadSKBencana ? (
							<div className="mt-15 flex flex-col items-center justify-center gap-8">
								<Label
									className="text-2xl font-semibold text-[#364878] dark:text-white"
									htmlFor="dokumen-verifikasi"
								>
									Upload Dokumen SK Penetapan Bencana
								</Label>
								<div className="flex items-center gap-6">
									{uploadDokumen ? (
										<>
											<FiCheckCircle className="text-2xl text-green-500" />
											<span className="text-xl font-medium text-black">
												{uploadDokumen.name}
											</span>
											<FiTrash
												className="cursor-pointer text-2xl text-red-500"
												onClick={handleDeleteDokumen}
											/>
											<br />
											<Button
												className="inline-flex h-14 w-30 items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center text-lg font-medium text-white hover:bg-opacity-90"
												onClick={handleUploadFileSKBencana}
												disabled={isLoading}
											>
												<FiUpload className="text-2xl text-white" /> Upload
											</Button>
										</>
									) : (
										<Button
											className="inline-flex h-14 w-30 items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center text-lg font-medium text-white hover:bg-opacity-90"
											onClick={handleOpenDokumen}
										>
											<FiUpload className="text-2xl text-white" /> Upload
										</Button>
									)}
								</div>
								<input
									ref={inputDokumenRef}
									onChange={handleOnChangeOpenDokumen}
									type="file"
									className="hidden"
									accept=".pdf, .xlsx"
								/>
								<div className="">
									<p className="text-center text-sm font-black text-red-500">
										*Maksimal 50 MB
									</p>
									<p className="text-center text-sm font-black text-red-500">
										*Harus Berekstensi .pdf atau .xlsx (File PDF atau Excel)
									</p>
									<div className="my-10 flex justify-center gap-10">
										{!uploadDokumen && (
											<Button
												className="w-[20%] bg-red-500 text-white hover:bg-red-700"
												onClick={() => setShowUploadSKBencana(false)}
											>
												Batal
											</Button>
										)}
									</div>
								</div>
							</div>
						) : (
							<>
								{/* Quetions-1 */}
								<h1 className="py-5 text-center text-3xl font-semibold text-[#364878] dark:text-white">
									APAKAH ADA BENCANA DARI TAHUN {Number(tahun) - 5} - {tahun}?
								</h1>

								<div className="my-5 flex justify-center gap-10">
									<Button
										className={`${terdampak ? 'bg-blue-800' : 'bg-green-500'} w-[70px] text-white hover:bg-green-700`}
										onClick={() => setTerdampak(true)}
									>
										YA
									</Button>
									<Button
										className={`${!terdampak ? 'bg-blue-800' : 'bg-red-500'} w-[70px] text-white hover:bg-red-700`}
										onClick={() => setTerdampak(false)}
									>
										Tidak
									</Button>
								</div>

								{/* Quetions-2 */}
								{terdampak && (
									<>
										<h1 className="py-5 text-center text-3xl font-semibold text-[#364878] dark:text-white">
											APAKAH ADA RUMAH TERDAMPAK?
										</h1>
										<div className="my-5 flex justify-center gap-10">
											<Button
												className={`${sk_bencana ? 'bg-blue-800' : 'bg-green-500'} w-[70px] text-white hover:bg-green-700`}
												onClick={() => setSk_bencana(true)}
											>
												YA
											</Button>
											<Button
												className={`${!sk_bencana ? 'bg-blue-800' : 'bg-red-500'} w-[70px] text-white hover:bg-red-700`}
												onClick={() => setSk_bencana(false)}
											>
												Tidak
											</Button>
										</div>
									</>
								)}

								{/* Quetions-3 */}
								{sk_bencana && terdampak && (
									<>
										<h1 className="pt-5 text-center text-3xl font-semibold text-[#364878] dark:text-white">
											APAKAH ADA SK BENCANA?
										</h1>
										<div className="flex justify-center gap-10 text-center">
											* SK Bencana yang dimaksud merupakan <br /> SK Bencana
											yang dikeluarkan oleh Kepala Daerah setempat.
										</div>
										<div className="my-5 flex justify-center gap-10">
											<Button
												className={`${uploadSK ? 'bg-blue-800' : 'bg-green-500'} w-[70px] text-white hover:bg-green-700`}
												onClick={() => setUploadSK(true)}
											>
												YA
											</Button>
											<Button
												className={`${!uploadSK ? 'bg-blue-800' : 'bg-red-500'} w-[70px] text-white hover:bg-red-700`}
												onClick={() => setUploadSK(false)}
											>
												Tidak
											</Button>
										</div>
									</>
								)}

								{/* simpan button */}
								<div className="my-10 flex justify-center gap-10">
									<Button
										className="w-[10%] bg-blue-500 text-white hover:bg-blue-700"
										onClick={handleSimpan}
									>
										Simpan
									</Button>
								</div>
							</>
						)}
					</>
				)}
			</ScrollArea>
		</div>
	);
};

export default CekBencanaLimaTahun;
