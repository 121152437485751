import { keepPreviousData, useQuery } from "@tanstack/react-query"

import { API } from "../libs/API"

export function useGetAllUsers(page: number, limit: number) {
  const { data: dataUsers, isLoading } = useQuery({
    queryKey: ["all-users", page, limit],
    queryFn: async () => {
      const { data } = await API.get(`/all-user?page=${page}&limit=${limit}`)
      return { allUsers: data.data, totalData: Number(data.pagination.total) }
    },
    placeholderData: keepPreviousData
  })

  return { dataUsers, isLoading }
}