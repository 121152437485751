import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { FormEvent, useEffect, useState } from 'react';
import {IdentifikasiPerumahanRelokasi } from '@/types/perumahan';
import EditKecamatanSelectUI from '@/components/ui/SelectEditKecamatan';
import { useEditIdentifikasiPerumahanRelokasi } from '@/hooks/perumahan/useRumahRelokasi';
import { useGetJenisProgramRelokasi } from '@/hooks/perumahan/useRumahBencana';
import EditJenisProgramRelokasi from '@/components/ui/SelectEditJenisProgramRelokasi';
import { useQueryClient } from '@tanstack/react-query';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import EditKabKotaSelectUI from '@/components/ui/SelectEditKabupatenKotaUI';

interface EditIdentifikasiRumahRelokasiProps {
    rowData: IdentifikasiPerumahanRelokasi;
}

const EIdentifikasiRumahRelokasi: React.FC<EditIdentifikasiRumahRelokasiProps> = ({
    rowData,
}) => {
    const user = useSelector((state: RootState) => state.auth);
    const menuId = useSelector((state: RootState) => state.menuId.value);

    //wilayah
    const [kd_kabkota, setKd_kabkota] = useState<number>(rowData.kd_kabkota);
    const [kd_kec, setKd_kec] = useState<number>(rowData.kd_kec);

    //get
    const {getKabKota} = useGetKabKota(user.kd_provinsi)
    const { getKecamatan } = useGetKecamatan(
        user.kd_provinsi,
        kd_kabkota,
    );
    const { editIdentifikasiPerumahanRelokasi } = useEditIdentifikasiPerumahanRelokasi();
    const { getJenisProgramRelokasi } = useGetJenisProgramRelokasi();

    //dataForm
    const [jenisProgramRelokasi, setJenisProgramRelokasi] = useState<number>(rowData.jenis_relokasi_id);
    const [jumlahKK, setJumlahKK] = useState((rowData.jml_kk ?? 0).toString());
		const [jumlahJiwa, setJumlahJiwa] = useState(
			(rowData.jml_jiwa ?? 0).toString(),
		);
		const [jumlahRumah, setJumlahRumah] = useState(
			(rowData.jml_rmh ?? 0).toString(),
		);
		const [statusLegal, setStatusLegal] = useState(
			(rowData.status_legal ?? 0).toString(),
		);
		const [statusIllegal, setStatusIllegal] = useState(
			(rowData.status_illegal ?? 0).toString(),
		);
		const [terlayaniGantiUang, setTerlayaniGantiUang] = useState(
			(rowData.tlyn_ganti_uang ?? 0).toString(),
		);
		const [terlayaniPembangunan, setTerlayaniPembangunan] = useState(
			(rowData.tlyn_pembangunan_kembali ?? 0).toString(),
		);
		const [terlayaniSubsidi, setTerlayaniSubsidi] = useState(
			(rowData.tlyn_subsidi_sewa ?? 0).toString(),
		);
		const [terlayaniAksesRumah, setTerlayaniAksesRumah] = useState(
			(rowData.tlyn_aks_rumah_sewa ?? 0).toString(),
		);
		const [tidakTerlayani, setTidakTerlayani] = useState(
			(rowData.blm_terlayani ?? 0).toString(),
		);

    //additionals
    const [modalOpen, setModalOpen] = useState(false);
    const queryClient = useQueryClient(); 


    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        const data = {
            id_idn_perumahan_relokasi: rowData.id_idn_perumahan_relokasi,
            jenis_relokasi_id: jenisProgramRelokasi,
            jml_rmh: parseInt(jumlahRumah),
            jml_kk: parseInt(jumlahKK),
            jml_jiwa: parseInt(jumlahJiwa),
            status_legal: parseInt(statusLegal),
            status_illegal: parseInt(statusIllegal),
            tlyn_ganti_uang: parseInt(terlayaniGantiUang),
            tlyn_pembangunan_kembali: parseInt(terlayaniPembangunan),
            tlyn_subsidi_sewa: parseInt(terlayaniSubsidi),
            tlyn_aks_rumah_sewa: parseInt(terlayaniAksesRumah),
            blm_terlayani: parseInt(tidakTerlayani),
        };
        
        editIdentifikasiPerumahanRelokasi(data, {
			onSuccess: () => {
				setModalOpen(false);
                queryClient.refetchQueries({queryKey:['identifikasi-perumahan-relokasi']});
			},
			onError: (error) => {
				console.log(error);
			},
		});

        console.log(data);
    };

    useEffect(() => {

    }, [kd_kec]);

    useEffect(() => {
        // setJenisProgramRelokasi(rowData.jenis_bencana_id);
        // setTingkatKerawananBencana(rowData.tingkat_rawan.nama_tingkat_rawan);
        // setKd_kec(rowData.kd_kec);
        // setKd_desa(rowData.kd_desa);
    }, [rowData]);

    return (
        <>
            <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger asChild>
                    <Button
                        size={'sm'} 
                        className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'>
                        <FiEdit />
                    </Button>
                </DialogTrigger>
                <DialogContent className="mx-auto max-w-screen-xl z-99999">
                    <DialogHeader>
                        <DialogTitle>
                            Edit Identifikasi Perumahan yang Terkena Relokasi
                        </DialogTitle>
                        <DialogDescription>
                            Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
                        </DialogDescription>
                    </DialogHeader>
                    <form action='post' onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Jenis Program Relokasi" className="mb-2">
                                    Jenis Program Relokasi
                                </Label>
                                <EditJenisProgramRelokasi
                                    getJenisProgramRelokasi={getJenisProgramRelokasi}
                                    setJenisProgramRelokasi={setJenisProgramRelokasi}
                                    selectedValue={jenisProgramRelokasi}
                                    disabledSelect={true}
                                />
                            </div>
                            { menuId === 8 && (
                                <div className="flex flex-col">
                                <Label htmlFor="Kabupaten" className="mb-2">
                                    Kabupaten
                                </Label>
                                <EditKabKotaSelectUI
                                    getKabKota={getKabKota}
                                    setKabKota={setKd_kabkota}
                                    selectedValue={kd_kabkota}
                                    disabledSelect={true}
                                />
                            </div>
                            )}
                            <div className="flex flex-col">
                                <Label htmlFor="Kecamatan" className="mb-2">
                                    Kecamatan
                                </Label>
                                <EditKecamatanSelectUI
                                    getKecamatan={getKecamatan}
                                    setKd_kec={setKd_kec}
                                    selectedValue={kd_kec}
                                    disabledSelect={true}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah Rumah" className="mb-2">
                                    Jumlah Rumah
                                </Label>
                                <Input
                                    id="Jumlah Rumah"
                                    type="number"
                                    className="col-span-1"
                                    value={jumlahRumah}
                                    onChange={(e) => setJumlahRumah(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah KK" className="mb-2">
                                    Jumlah KK
                                </Label>
                                <Input
                                    id="Jumlah KK"
                                    type="number"
                                    className="col-span-1"
                                    value={jumlahKK}
                                    onChange={(e) => setJumlahKK(e.target.value)}
                                    placeholder='Masukkan Jumlah KK'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah Jiwa" className="mb-2">
                                    Jumlah Jiwa
                                </Label>
                                <Input
                                    id="Jumlah Jiwa"
                                    type="number"
                                    className="col-span-1"
                                    value={jumlahJiwa}
                                    onChange={(e) => setJumlahJiwa(e.target.value)}
                                    placeholder='Masukkan Jumlah Jiwa'
                                />
                            </div>           
                            <div className="flex flex-col">
                                <Label htmlFor="Belum Terlayani" className="mb-2">
                                    Belum Terlayani (Unit Rumah)
                                </Label>
                                <Input
                                    id="Belum Terlayani"
                                    type="number"
                                    className="col-span-1"
                                    value={tidakTerlayani}
                                    onChange={(e) => setTidakTerlayani(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Yang Berlum Terlayani'
                                />
                            </div>           
                        </div>
                        {/* Kondisi Fisik Rumah */}
						<h1 className='text-l font-bold mt-4'>Status Kepemilikan Tanah (Unit Rumah)</h1>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Legal" className="mb-2">
                                    Legal
                                </Label>
                                <Input
                                    id="Legal"
                                    type="number"
                                    className="col-span-1"
                                    value={statusLegal}
                                    onChange={(e) => setStatusLegal(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Legal'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Ilegal" className="mb-2">
                                    Ilegal
                                </Label>
                                <Input
                                    id="Ilegal"
                                    type="number"
                                    className="col-span-1"
                                    value={statusIllegal}
                                    onChange={(e) => setStatusIllegal(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Ilegal'
                                />
                            </div>
                       	</div>
                        {/* Status Kepemilikan Rumah */}
						<h1 className='text-l font-bold mt-4'>Sudah Terlayani</h1>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Penggantian Uang" className="mb-2">
                                    Penggantian Uang
                                </Label>
                                <Input
                                    id="Penggantian Uang"
                                    type="number"
                                    className="col-span-1"
                                    value={terlayaniGantiUang}
                                    onChange={(e) => setTerlayaniGantiUang(e.target.value)}
                                    placeholder='Masukkan Jumlah Penggantian Uang'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Pembangunan Kembali" className="mb-2">
                                    Pembangunan Kembali
                                </Label>
                                <Input
                                    id="Pembangunan Kembali"
                                    type="number"
                                    className="col-span-1"
                                    value={terlayaniPembangunan}
                                    onChange={(e) => setTerlayaniPembangunan(e.target.value)}
                                    placeholder='Masukkan Jumlah Pembangunan Kembali'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Subsidi Uang Sewa" className="mb-2">
                                    Subsidi Uang Sewa
                                </Label>
                                <Input
                                    id="Subsidi Uang Sewa"
                                    type="number"
                                    className="col-span-1"
                                    value={terlayaniSubsidi}
                                    onChange={(e) => setTerlayaniSubsidi(e.target.value)}
                                    placeholder='Masukkan Jumlah Subsidi Uang Sewa'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Akses Tinggal Rumah Sewa" className="mb-2">
                                    Akses Tinggal Rumah Sewa
                                </Label>
                                <Input
                                    id="Akses Tinggal Rumah Sewa"
                                    type="number"
                                    className="col-span-1"
                                    value={terlayaniAksesRumah}
                                    onChange={(e) => setTerlayaniAksesRumah(e.target.value)}
                                    placeholder='Masukkan Jumlah Akses Tinggal Rumah Sewa'
                                />
                            </div>
                       	</div>
                        <DialogFooter>
                            <div className="flex w-full justify-between mt-5">
                                <DialogClose asChild>
                                    <Button
                                        className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
                                    >
                                        Batal
                                    </Button>
                                </DialogClose>
                                <Button
                                    className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                                    type="submit"
                                >
                                    Simpan
                                </Button>
                            </div>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EIdentifikasiRumahRelokasi;
