import { useSatuan } from "@/hooks/useSatuan";

export default function SatuanSelect({ kumpulanKomponen, setKumpulanKomponen, index, disabled }: any) {
  const { getSatuan, isLoading: loadSatuan } = useSatuan(kumpulanKomponen.komponen_id)

  if (loadSatuan) return <></>

  return (
    <select
      className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
      disabled={disabled}
      defaultValue={kumpulanKomponen.satuan_id}
      onChange={(e) => {
        const value = Number(e.target.value);
        setKumpulanKomponen((prevState: any) => {
          const newState = [...prevState];
          newState[index] = {
            ...newState[index],
            satuan_id: value
          };
          return newState;
        });
      }}
    >
      <option value="0" disabled hidden>
        Pilih Satuan
      </option>
      {!loadSatuan && getSatuan?.map((satuan: any, key: any) => (
        <option key={key} value={satuan.id_satuan}>
          {satuan.satuan_name}
        </option>
      ))}
    </select>
  )
}