import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LocationState = {
	kd_provinsi_dux: number;
	kd_kabkota_dux: number;
	nama_provinsi: string;
	nama_kabkota: string;
};

const initialState: LocationState = {
	kd_provinsi_dux: 0,
	kd_kabkota_dux: 0,
	nama_provinsi: '',
	nama_kabkota: '',
};

export const locationSlice = createSlice({
	name: 'location',
	initialState,
	reducers: {
		setKd_provinsi_dux: (state, action: PayloadAction<number>) => {
			state.kd_provinsi_dux = action.payload;
		},
		setKd_kabkota_dux: (state, action: PayloadAction<number>) => {
			state.kd_kabkota_dux = action.payload;
		},
		setNama_provinsi: (state, action: PayloadAction<string>) => {
			state.nama_provinsi = action.payload;
		},
		setNama_kabkota: (state, action: PayloadAction<string>) => {
			state.nama_kabkota = action.payload;
		},
	},
});

export const {
	setKd_provinsi_dux,
	setKd_kabkota_dux,
	setNama_provinsi,
	setNama_kabkota,
} = locationSlice.actions;

export default locationSlice.reducer;
