import { RootState } from '@/app/store';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useMenuAirLimbah } from '@/hooks/menu/useMenu';
import { SPALDTData } from '@/types/airLimbah';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TInvSPALDProv } from './TInvSPALDProv';

const TInventarisasiSPALDT = () => {
	const [data, setData] = useState<SPALDTData[] | null>(null);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;
	const formatted_kd_kabkota =
		kd_kabkota_to_use < 10 ? `0${kd_kabkota_to_use}` : kd_kabkota_to_use;
	let rowCounter = 1;
	const kd_wilayah = `${kd_provinsi_to_use}${formatted_kd_kabkota}`;
	// console.log(kd_wilayah);
	const { isMenuAirLimbahProv } = useMenuAirLimbah();

	useEffect(() => {
		fetch(`http://localhost:3000/api/v1/si-insan/spaldt`)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				const filteredData = _.filter(data.data.data, (item: SPALDTData) => {
					return (
						item.provinsiid === String(kd_provinsi_to_use) &&
						item.kabkotaid === String(kd_wilayah)
					);
				});
				setData(filteredData);
				console.log(filteredData);
			})
			.catch((error) => {
				console.error('Fetch error:', error);
			});
	}, [kd_kabkota_to_use, kd_provinsi_to_use]);

	const cardRef = useRef(null);

	if (isMenuAirLimbahProv) {
		return <TInvSPALDProv />;
	}

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader cardRef={cardRef} isLoading={false}>
					INVENTARISASI SPALD DI KABUPATEN/KOTA
					<br />
					Penyediaan Pelayanan Pengolahan Air Limbah Domestik
				</JudulHeader>
				<div className="sticky top-0 flex items-center justify-start py-4 text-center">
					<div className="inline-flex items-center gap-2 text-center font-bold">
						<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
						pada table dibawah ini didapat dari SIINSAN
					</div>
				</div>
				<Table className="w-full table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead
								rowSpan={3}
								className="thead-text border-l text-center"
							>
								No
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Lokasi SPALD-T
							</TableHead>
							{/* <TableHead rowSpan={3} className="thead-text text-center">
								Nama SPALD-T
							</TableHead> */}
							<TableHead rowSpan={3} className="thead-text text-center">
								Skala Pelayanan
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Tahun <br /> Konstruksi
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Status <br /> Keberfungsian
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Kualitas <br /> Keberfungsian
							</TableHead>
							<TableHead
								colSpan={4}
								className="thead-text border-b-0 text-center"
							>
								Akses
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead
								colSpan={2}
								className="thead-text border-b-0 text-center"
							>
								Tersedia
							</TableHead>
							<TableHead
								colSpan={2}
								className="thead-text border-b-0 text-center"
							>
								Termanfaatkan
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text text-center">
								(M³/hari)
							</TableHead>
							<TableHead className="thead-text text-center">
								Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								(M³/hari)
							</TableHead>
							<TableHead className="thead-text text-center">
								Unit Rumah
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{data?.map((item: SPALDTData, index: number) => (
							<TableRow key={index}>
								<TableCell className="thead-body text-center">
									{rowCounter++}
								</TableCell>
								<TableCell className="thead-body text-left">
									{item.alamat_lengkap}
								</TableCell>
								<TableCell className="thead-body text-center">
									{item.skalapelayanan}
								</TableCell>
								<TableCell className="thead-body text-center">
									{item.tahunkontruksi}
								</TableCell>
								<TableCell className="thead-body text-center">
									{item.status_keberfungsian}
								</TableCell>
								<TableCell className="thead-body text-center">
									{item.kualitas_keberfungsian}
								</TableCell>
								<TableCell className="thead-body text-center">
									{item.kapasitasdesain}
								</TableCell>
								<TableCell className="thead-body text-center">
									<Button className="w-full bg-red-400"></Button>
								</TableCell>
								<TableCell className="thead-body text-center">
									{item.kapasitasterpakai}
								</TableCell>
								<TableCell className="thead-body text-center">
									<Button className="w-full bg-red-400"></Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};
export default TInventarisasiSPALDT;
