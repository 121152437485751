import { RootState } from "@/app/store"
import { CompLoader } from "@/common/Loader";
import { Button } from "@/components/ui/Button";
import DesaSelectUI from "@/components/ui/SelectDesaUI";
import JenisBencanaSelectUI from "@/components/ui/SelectJenisBencanaUI";
import KecamatanSelectUI from "@/components/ui/SelectKecamatanUI";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { usePostDataCapaian } from "@/hooks/perumahan/useRumahBencana";
import { useGetJenisBencana } from "@/hooks/useJenisBencana";
import { useGetDesa } from "@/hooks/wilayah/useDesa";
import { useGetKecamatan } from "@/hooks/wilayah/useKecamatan";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi";
import { useSelector } from "react-redux"
import { toast } from "sonner";

type FormValue = {
  tahun_pelaporan: number,
  menu_id: number,
  jenis_bencana_id: number,
  tahun_kejadian: number,
  kd_provinsi: number,
  kd_kabkota: number,
  kd_kec: number,
  kd_desa: number,
  jml_rmh_rusak: number,
  tingkat_rusak_ringan: number,
  tingkat_rusak_sedang: number,
  tingkat_rusak_berat: number,
  tgt_rehabilitasi: number,
  tgt_pembangunan: number,
  tgt_relokasi: number,
  tgt_sewa: number,
  tgt_akses_tinggal: number,
  real_rehabilitasi: number,
  real_pembangunan: number,
  real_relokasi: number,
  real_sewa: number,
  real_akses_tinggal: number
}

const FormDataCapaianKumulatifRumahBencana = ({ setShowForm }: { setShowForm: any }) => {
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const user = useSelector((state: RootState) => state.auth)
  const menuId = useSelector((state: RootState) => state.menuId.value)

  const [jenisBencana, setJenisBencana] = useState('0');
  const [kd_kec, setKd_kec] = useState('0');
  const [kd_desa, setKd_desa] = useState('0');
  const queryClient = useQueryClient()

  const { asyncPostDataCapaian, isPending } = usePostDataCapaian();

  const { register, setValue, handleSubmit, reset } = useForm<FormValue>({
    defaultValues: {
      tahun_pelaporan: Number(tahun),
      tahun_kejadian: Number(tahun),
      menu_id: menuId,
      kd_provinsi: user.kd_provinsi,
      kd_kabkota: user.kd_kabkota,
      kd_kec: Number(kd_kec),
      kd_desa: Number(kd_desa),
      jenis_bencana_id: Number(jenisBencana),
      jml_rmh_rusak: 0,
      tingkat_rusak_ringan: 0,
      tingkat_rusak_sedang: 0,
      tingkat_rusak_berat: 0,
      tgt_rehabilitasi: 0,
      tgt_pembangunan: 0,
      tgt_relokasi: 0,
      tgt_sewa: 0,
      tgt_akses_tinggal: 0,
      real_rehabilitasi: 0,
      real_pembangunan: 0,
      real_relokasi: 0,
      real_sewa: 0,
      real_akses_tinggal: 0
    }
  })

  useEffect(() => {
    setValue("jenis_bencana_id", Number(jenisBencana))
    setValue("kd_kec", Number(kd_kec))
    setValue("kd_desa", Number(kd_desa))
  }, [jenisBencana, kd_kec, kd_desa])

  const { getKecamatan, isLoading: isLoadingKec } = useGetKecamatan(
    user.kd_provinsi,
    user.kd_kabkota,
  );

  const { getJenisBencana, isLoading: isLoadingBencana } = useGetJenisBencana()
  const { getDesa, isLoading: isLoadingDes } = useGetDesa(user.kd_provinsi, user.kd_kabkota, parseInt(kd_kec));

  const onSubmit = async (data: any) => {
    try {
      await asyncPostDataCapaian(data)
      queryClient.refetchQueries({ queryKey: ["eksis-bencana"] })
      reset()
      setShowForm("Pilih Data")
      toast.success('Berhasil menambahkan data capaian Rumah Bencana');
    } catch (error) {
      toast.error('Gagal menambahkan data capaian Rumah Bencana');
    }
  }

  if (isLoadingBencana || isPending) return <CompLoader />

  return (
    <>
      <div className='flex justify-end pb-4'>
        <div className="flex gap-4">
          <Button
            className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
            onClick={() => setShowForm("Pilih Data")}
          >
            <FiArrowLeft />
            Kembali
          </Button>
        </div>
      </div>
      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col flex-1 overflow-hidden">
        <ScrollArea className="h-full">
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Tahun Kejadian" className="w-1/2 text-xl text-black">
              Tahun Kejadian
            </Label>
            <Input
              id="Tahun Kejadian"
              type="number"
              min="0"
              {...register("tahun_kejadian", { valueAsNumber: true })}
              placeholder='Masukkan Tahun Kejadian' />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Jenis Bencana" className="w-1/2 text-xl text-black">
              Jenis Bencana
            </Label>
            <JenisBencanaSelectUI
              getJenisBencana={getJenisBencana}
              setJenisBencana={setJenisBencana}
            />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Kecamatan" className="w-1/2 text-xl text-black">
              Kecamatan
            </Label>
            <KecamatanSelectUI
              getKecamatan={isLoadingKec ? [] : getKecamatan}
              setKd_kec={setKd_kec}
            />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Desa" className="w-1/2 text-xl text-black">
              Desa
            </Label>
            <DesaSelectUI
              getDesa={isLoadingDes ? [] : getDesa}
              setKd_desa={setKd_desa}
            />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Jumlah Rumah Rusak" className="w-1/2 text-xl text-black">
              Jumlah Rumah Rusak
            </Label>
            <Input
              id="Jumlah Rumah Rusak"
              type="number"
              min="0"
              {...register("jml_rmh_rusak", { valueAsNumber: true })}
              placeholder='Masukkan Jumlah Rumah Rusak' />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Tingkat Kerusakan RUmah" className="w-1/2 text-2xl text-black font-black">
              Tingkat Kerusakan Rumah
            </Label>
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Tingkat Rusak Ringan" className="w-1/2 text-xl text-black">
              Rusak Ringan
            </Label>
            <Input
              id="Tingkat Rusak Ringan"
              type="number"
              min="0"
              {...register("tingkat_rusak_ringan", { valueAsNumber: true })}
              placeholder='Masukkan Tingkat Rusak Ringan' />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Tingkat Rusak Sedang" className="w-1/2 text-xl text-black">
              Rusak Sedang
            </Label>
            <Input
              id="Tingkat Rusak Sedang"
              type="number"
              min="0"
              {...register("tingkat_rusak_sedang", { valueAsNumber: true })}
              placeholder='Masukkan Tingkat Rusak Sedang' />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Tingkat Rusak Berat" className="w-1/2 text-xl text-black">
              Rusak Berat
            </Label>
            <Input
              id="Tingkat Rusak Berat"
              type="number"
              min="0"
              {...register("tingkat_rusak_berat", { valueAsNumber: true })}
              placeholder='Masukkan Tingkat Rusak Berat' />
          </div>
          <div className="flex">
            <div className="basis-1/2">
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Target" className="w-1/2 text-2xl text-black font-black">
                  Target
                </Label>
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Rehabilitasi (Target)" className="w-1/2 text-xl text-black">
                  Rehabilitasi Rumah
                </Label>
                <Input
                  id="Rehabilitasi (Target)"
                  type="number"
                  min="0"
                  {...register("tgt_rehabilitasi", { valueAsNumber: true })}
                  placeholder='Masukkan Target Rehabilitasi Rumah' />
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Pembangunan (Target)" className="w-1/2 text-xl text-black">
                  Pembangunan Kembali Unit Rumah
                </Label>
                <Input
                  id="Pembangunan (Target)"
                  type="number"
                  min="0"
                  {...register("tgt_pembangunan", { valueAsNumber: true })}
                  placeholder='Masukkan Target Pembangunan Kembali Unit Rumah' />
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Pemukiman Kembali (Target)" className="w-1/2 text-xl text-black">
                  Pemukiman Kembali
                </Label>
                <Input
                  id="Pemukiman kembali (Target)"
                  type="number"
                  min="0"
                  {...register("tgt_relokasi", { valueAsNumber: true })}
                  placeholder='Masukkan Target Pemukiman kembali' />
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Subsidi (Target)" className="w-1/2 text-xl text-black">
                  Subsidi Uang Sewa
                </Label>
                <Input
                  id="Subsidi (Target)"
                  type="number"
                  min="0"
                  {...register("tgt_sewa", { valueAsNumber: true })}
                  placeholder='Masukkan Target Subsidi Uang Sewa' />
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Akses Tinggal (Target)" className="w-1/2 text-xl text-black">
                  Akses Tinggal
                </Label>
                <Input
                  id="Akses Tinggal (Target)"
                  type="number"
                  min="0"
                  {...register("tgt_akses_tinggal", { valueAsNumber: true })}
                  placeholder='Masukkan Target Akses Tinggal' />
              </div>
            </div>
            <div className="basis-1/2 border-l border-slate-200">
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Jenis Bencana" className="w-1/2 text-2xl text-black font-black">
                  Realisasi
                </Label>
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Rehabilitasi (Realisasi)" className="w-1/2 text-xl text-black">
                  Rehabilitasi Rumah
                </Label>
                <Input
                  id="Rehabilitasi (Realisasi)"
                  type="number"
                  min="0"
                  {...register("real_rehabilitasi", { valueAsNumber: true })}
                  placeholder='Masukkan Realisasi Rehabilitasi Rumah' />
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Pembangunan (Realisasi)" className="w-1/2 text-xl text-black">
                  Pembangunan Kembali Unit Rumah
                </Label>
                <Input
                  id="Pembangunan (Realisasi)"
                  type="number"
                  min="0"
                  {...register("real_pembangunan", { valueAsNumber: true })}
                  placeholder='Masukkan Realisasi Pembangunan Kembali Unit Rumah' />
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Pemukiman Kembali (Realisasi)" className="w-1/2 text-xl text-black">
                  Pemukiman Kembali
                </Label>
                <Input
                  id="Pemukiman kembali (Realisasi)"
                  type="number"
                  min="0"
                  {...register("real_relokasi", { valueAsNumber: true })}
                  placeholder='Masukkan Realisasi Pemukiman kembali' />
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Subsidi (Realisasi)" className="w-1/2 text-xl text-black">
                  Subsidi Uang Sewa
                </Label>
                <Input
                  id="Subsidi (Realisasi)"
                  type="number"
                  min="0"
                  {...register("real_sewa", { valueAsNumber: true })}
                  placeholder='Masukkan Realisasi Subsidi Uang Sewa' />
              </div>
              <div className="flex items-center p-6 gap-4 border-b border-slate-200">
                <Label htmlFor="Akses Tinggal (Realisasi)" className="w-1/2 text-xl text-black">
                  Akses Tinggal
                </Label>
                <Input
                  id="Akses Tinggal (Realisasi)"
                  type="number"
                  min="0"
                  {...register("real_akses_tinggal", { valueAsNumber: true })}
                  placeholder='Masukkan Realisasi Akses Tinggal' />
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-5 p-4 mt-5">
            <div className="w-full">
              <button
                className="min-w-full inline-flex items-center justify-center rounded-md border border-meta-3 py-4 px-10 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
                onClick={() => handleSubmit(onSubmit)()}
              >
                Simpan Data
              </button>
            </div>
          </div>
        </ScrollArea>
      </div>
    </>
  )
}

export default FormDataCapaianKumulatifRumahBencana