import { RootState } from "@/app/store"
import { CompLoader } from "@/common/Loader"
import { Button } from "@/components/ui/Button"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useGetByIdNewRencanaPemenuhan, usePostNewRencanaPemenuhanKomponen } from "@/hooks/rencanaPelaksanaan/useRencanaPemenuhan"
import { useState } from "react"
import { FiPlus, FiX } from "react-icons/fi"
import { useSelector } from "react-redux"
import { toast } from "sonner"
import SatuanSelect from "./Komponen/SatuanSelect"
import KomponenSelect from "./Komponen/KomponenSelect"

const FormPenambahanKomponen = ({ id, setShowForm }: { id: number, setShowForm: any }) => {
  const bidang = useSelector((state: RootState) => state.bidang.value)
  const [currentKomponen, setCurrentKomponen] = useState(0)
  const [kumpulanKomponen, setKumpulanKomponen] = useState<any>([])
  const [uploadKomponen, setUploadKomponen] = useState(false)

  const { getDetailRencana } = useGetByIdNewRencanaPemenuhan(id)
  const { postKomponen } = usePostNewRencanaPemenuhanKomponen(id)

  const handleTambahKomponen = () => {
    setCurrentKomponen(currentKomponen + 1)
    setKumpulanKomponen((prevState: any) => ([...prevState, {
      komponen_id: 0,
      satuan_id: 0,
      volume: 0
    }]))
  }

  const handleHapusKomponen = (index: any) => {
    setKumpulanKomponen((prevState: any) => prevState.filter((_: any, i: any) => i !== index));
    if (currentKomponen === index && index > 0) {
      setCurrentKomponen(index - 1);
    } else if (index === 0 && kumpulanKomponen.length > 1) {
      setCurrentKomponen(0);
    } else {
      setCurrentKomponen(-1);
    }
  };

  const handleSubmit = async () => {
    setUploadKomponen(true)
    try {
      const postPromises = kumpulanKomponen.map((data: any) => postKomponen(data))
      await Promise.all(postPromises)
      toast.success("Berhasil menambahkan komponen!")
    } catch (error) {
      console.log(error)
      toast.error("Gagal menambahkan komponen!")
    } finally {
      setUploadKomponen(false)
      setShowForm("Lihat Data")
    }
  }

  if (uploadKomponen) return <CompLoader />

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden">
      <div className="flex justify-between border-b border-stroke px-6.5 py-4 dark:border-strokedark">
        <h3 className="text-2xl font-medium text-[#364878] dark:text-white">
          Form Penambahan Komponen Untuk Bidang {' '}
          {bidang === 'air-minum'
            ? 'Air Minum'
            : bidang === 'air-limbah'
              ? 'Air Limbah'
              : bidang === 'rumah-bencana'
                ? 'Rumah Bencana'
                : bidang === 'rumah-relokasi'
                  ? 'Rumah Relokasi'
                  : ''}
        </h3>
        <Button className='inline-flex items-center justify-start gap-1 rounded-lg bg-green-500 px-4 py-2 text-center font-medium text-white hover:bg-green-600'
          onClick={handleTambahKomponen}
        >
          <FiPlus />
          Tambah Komponen
        </Button>
      </div>
      <ScrollArea className="h-full">
        {kumpulanKomponen.map((item: any, index: any) => (
          <div key={index} className="flex relative items-center p-6 gap-4 border-b border-slate-200">
            <button className="absolute top-0 left-0 text-xl text-red-500 m-4" onClick={() => handleHapusKomponen(index)}>
              <FiX />
            </button>
            <Label htmlFor={`Komponen`} className="w-1/3 text-xl text-black">
              Komponen {index + 1}
            </Label>
            <div className="flex flex-col gap-4 w-full">
              <KomponenSelect nomenklatur_id={getDetailRencana.nomenklatur_id} kumpulanKomponen={kumpulanKomponen[index]} setKumpulanKomponen={setKumpulanKomponen} index={index} />
              <SatuanSelect kumpulanKomponen={kumpulanKomponen[index]} setKumpulanKomponen={setKumpulanKomponen} index={index} />
              <input
                id="volume"
                name="volume"
                value={kumpulanKomponen[index].volume}
                onChange={(e) => {
                  const volume = e.target.value;
                  setKumpulanKomponen((prevState: any) => {
                    const newState = [...prevState];
                    newState[index] = {
                      ...newState[index],
                      volume
                    };
                    return newState;
                  });
                }}
                type="number"
                step="0.01"
                placeholder="Masukan Volume"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
        ))}
      </ScrollArea>
      <div className="p-4">
        {kumpulanKomponen.length !== 0 &&
          <button
            className="inline-flex min-w-full items-center justify-center rounded-md border border-meta-3 px-10 py-4 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
            onClick={handleSubmit}
          >
            Simpan Komponen
          </button>
        }
      </div>
    </div >
  )
}

export default FormPenambahanKomponen