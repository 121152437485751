import DefaultLayout from '../../../layout/DefaultLayout';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useState } from 'react';
import TableBerandaKab from '@/components/Tables/TableBerandaKab';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import TDataCapaianALShadCn from '@/components/Tables/AirLimbah/DataCapaian/TDataCapaianAL2';
import TSrnPengankutanAL from '@/components/Tables/AirLimbah/PengumpulanData/TSrnPengangkutan';
import TInventarisasiIPLT from '@/components/Tables/AirLimbah/PengumpulanData/TInvIPLT';
import TInventarisasiSPALDT from '@/components/Tables/AirLimbah/PengumpulanData/TInvSPALDT';
import TBerkasAL from '@/components/Tables/AirLimbah/PengumpulanData/TBerkasAL';
import TableCapaianSPALDRegionalRekapitulasi from '@/components/Tables/AirLimbah/DataCapaian/TableCapaianSPALDRegionalRekapitulasi';
import { useMenuAirLimbah } from '@/hooks/menu/useMenu';

const PengumpulanDataAirLimbah = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const [activeTab, setActiveTab] = useState('inventarisasi-spald');

	const { isMenuAirLimbahProv } = useMenuAirLimbah();

	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead="Pengumpulan Data Air Limbah"
				pageName="Pengumpulan Data"
			/>
			<div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 dark:bg-graydark sm:grid-cols-5">
				<div
					className={`py-4 text-center ${
						activeTab === 'inventarisasi-spald'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('inventarisasi-spald')}
				>
					Inventarisasi
					<br />
					SPALD
				</div>
				<div
					className={`py-4 text-center ${
						activeTab === 'inventarisasi-iplt'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('inventarisasi-iplt')}
				>
					Inventarisasi
					<br />
					IPLT
				</div>
				<div
					className={`py-4 text-center ${
						activeTab === 'inventarisasi-sarana'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('inventarisasi-sarana')}
				>
					Inventarisasi Sarana
					<br />
					Pengangkutan Lumpur Tinja
				</div>
				<div
					className={`py-4 text-center ${
						activeTab === 'data-awal'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('data-awal')}
				>
					Rekapitulasi Akses
					<br /> Air Limbah {Number(tahun) - 1}
				</div>
				<div
					className={`py-4 text-center ${
						activeTab === 'BerkasAL'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} flex cursor-pointer items-center justify-center rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('BerkasAL')}
				>
					<p>Berkas</p>
				</div>
			</div>

			{activeTab === 'inventarisasi-spald' && <TInventarisasiSPALDT />}
			{activeTab === 'inventarisasi-iplt' && <TInventarisasiIPLT />}
			{activeTab === 'inventarisasi-sarana' && <TSrnPengankutanAL />}
			{activeTab === 'data-awal' &&
				(isMenuAirLimbahProv ? (
					<TableCapaianSPALDRegionalRekapitulasi tahun={Number(tahun) - 1} />
				) : (
					<TDataCapaianALShadCn tahun={Number(tahun) - 1} />
				))}
			{activeTab === 'BerkasAL' && <TBerkasAL />}
		</DefaultLayout>
	);
};

export default PengumpulanDataAirLimbah;
