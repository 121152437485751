import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogFooter,
	DialogClose,
	Dialog,
	DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import {
	useEditInvSarana,
	usePostInvSarana,
} from '@/hooks/airLimbah/useInvSarana';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddSrnInvSchema } from '@/schema';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
} from '@/components/ui/form';
import { z } from 'zod';
import { toast } from 'sonner';
import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useQueryClient } from '@tanstack/react-query';

type FInvSaranaPengangkutanProps = {
	rowData: any;
};
export const EInvSaranaPengangkutan: React.FC<FInvSaranaPengangkutanProps> = ({
	rowData,
}) => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menu_id = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);

	//additional
	const { editInvSarana } = useEditInvSarana();
	const queryClient = useQueryClient();
	const [modalOpen, setModalOpen] = useState(false);

	//form
	const form = useForm({
		resolver: zodResolver(AddSrnInvSchema),
		defaultValues: {
			id_srn_pengangkutan: rowData?.id_srn_pengangkutan || '',
			jns_srn_pengangkutan: rowData?.jns_srn_pengangkutan || '',
			nmr_polisi: rowData?.nmr_polisi || '',
			stat_kepemilikan: rowData?.stat_kepemilikan || '',
			kondisi: rowData?.kondisi || '',
			beroperasi: rowData?.beroperasi || '',
			kap_pengangkutan: (rowData?.kap_pengangkutan ?? 0).toString(),
			tot_penyedotan: (rowData?.tot_penyedotan ?? 0).toString(),
			tahun_pelaporan: tahun,
			tahun_kendaraan: (rowData?.tahun_kendaraan ?? 0).toString(),
		},
	});

	useEffect(() => {
		form.setValue('tahun_pelaporan', tahun);
	}, [tahun, form.setValue]);

	const onSubmit = async (data: z.infer<typeof AddSrnInvSchema>) => {
		try {
			const completeData = {
				id_srn_pengangkutan: rowData.id_srn_pengangkutan,
				jns_srn_pengangkutan: data.jns_srn_pengangkutan,
				nmr_polisi: data.nmr_polisi,
				stat_kepemilikan: data.stat_kepemilikan,
				kondisi: data.kondisi,
				beroperasi: data.beroperasi,
				kap_pengangkutan: Number(data.kap_pengangkutan),
				tot_penyedotan: Number(data.tot_penyedotan),
				tahun_pelaporan: Number(tahun),
				tahun_kendaraan: Number(data.tahun_kendaraan),
				menu_id: Number(menu_id),
				kd_provinsi: kd_provinsi,
				kd_kabkota: kd_kabkota,
			};
			console.log(completeData);
			await editInvSarana(completeData);
			toast.success('Data berhasil diedit');
			queryClient.refetchQueries({ queryKey: ['inv-sarana'] });
		} catch (error) {
			console.error(error);
			toast.error('Data gagal diedit');
		}
	};

	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button
						size={'sm'}
						className="inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600"
					>
						<FiEdit />
					</Button>
				</DialogTrigger>
				<DialogContent onAbortCapture={() => form.reset()}>
					<DialogHeader>
						<DialogTitle>Edit Sarana</DialogTitle>
						<DialogDescription>
							Silahkan ubah data sarana pengangkutan lumpur tinja
						</DialogDescription>
					</DialogHeader>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)}>
							<div className="grid gap-4 py-4">
								<div className="grid gap-4 py-4">
									<FormField
										control={form.control}
										name="tahun_pelaporan"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Tahun Pelaporan</FormLabel>
												<FormControl>
													<Input
														disabled
														type="text"
														className="w-64 justify-self-end text-black-2 dark:text-white"
														placeholder="shadcn"
														{...field}
													/>
												</FormControl>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="jns_srn_pengangkutan"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Sarana Pengangkutan</FormLabel>
												<FormControl>
													<Input
														className="w-64 justify-self-end text-black-2"
														placeholder="Truck, Truck Tinja, dll"
														{...field}
													/>
												</FormControl>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="nmr_polisi"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Nomor Polisi</FormLabel>
												<FormControl>
													<Input
														type="text"
														className="w-64 justify-self-end text-black-2"
														placeholder="B 1234 ABC"
														{...field}
													/>
												</FormControl>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="tahun_kendaraan"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Tahun Kendaraan</FormLabel>
												<FormControl>
													<Input
														type="text"
														className="w-64 justify-self-end text-black-2"
														placeholder="2010"
														{...field}
													/>
												</FormControl>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="stat_kepemilikan"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Status Kepemilikan</FormLabel>
												<Select
													onValueChange={field.onChange}
													defaultValue={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-64 justify-self-end text-black-2">
															<SelectValue placeholder="Pilih Kepemilikan" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														<SelectItem value="Pemda">Pemda</SelectItem>
														<SelectItem value="Swasta">Swasta</SelectItem>
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="kondisi"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Kondisi</FormLabel>
												<Select
													onValueChange={field.onChange}
													defaultValue={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-64 justify-self-end text-black-2">
															<SelectValue placeholder="Pilih Kondisi" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														<SelectItem value="Baik">Baik</SelectItem>
														<SelectItem value="Rusak">Rusak</SelectItem>
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="beroperasi"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Beroperasi</FormLabel>
												<Select
													onValueChange={field.onChange}
													defaultValue={field.value}
												>
													<FormControl>
														<SelectTrigger className="w-64 justify-self-end text-black-2">
															<SelectValue placeholder="Beroperasi?" />
														</SelectTrigger>
													</FormControl>
													<SelectContent>
														<SelectItem value="Ya">Ya</SelectItem>
														<SelectItem value="Tidak">Tidak</SelectItem>
													</SelectContent>
												</Select>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="kap_pengangkutan"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Kapasitas Pengangkutan (M3)</FormLabel>
												<FormControl>
													<Input
														className="w-64 justify-self-end text-black-2"
														placeholder="satuan meter kubik"
														{...field}
													/>
												</FormControl>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="tot_penyedotan"
										render={({ field }) => (
											<FormItem className="grid grid-cols-[auto,1fr] items-center gap-4">
												<FormLabel>Total Penyedotan per Minggu</FormLabel>
												<FormControl>
													<Input
														className="w-64 justify-self-end text-black-2"
														placeholder="satuan unit rumah"
														{...field}
													/>
												</FormControl>
											</FormItem>
										)}
									/>
								</div>
							</div>
							<DialogFooter>
								<div className="flex w-full justify-between">
									<DialogClose asChild>
										<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
											Cancel
										</Button>
									</DialogClose>
									<DialogClose asChild>
										<Button
											type="submit"
											className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
										>
											Simpan
										</Button>
									</DialogClose>
								</div>
							</DialogFooter>
						</form>
					</Form>
				</DialogContent>
			</Dialog>
		</>
	);
};
