import React, { useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import DefaultLayout from '../../layout/DefaultLayout';
import FormDataWilayahAL from '../../components/Forms/AirLimbah/DataDasar/FormDataWilayahAL';
import FormDataDasarAL from '../../components/Forms/AirLimbah/DataDasar/FormDataDasarAL';
import TableDataWilayahAL from '@/components/Tables/AirLimbah/DataDasar/TableDataWilayahAL';
import { toast } from 'sonner';
import { ScrollArea } from '@/components/ui/scroll-area';
// import AlertSuccess from '@/components/Alerts/AlertSuccess';

const DataDasarAirLimbah: React.FC = () => {
	const [showForm, setShowForm] = useState<boolean>(false);
	// const [showAlert, setShowAlert] = useState<boolean>(false);
	const handleInputClick = (): void => {
		setShowForm(true);
	};
	const handleAddSuccess = (): void => {
		// alert('Upload successful');
		// setShowAlert(true);
		toast.success('Data successfully added');
		setShowForm(false);
		// setTimeout(() => {
		// 	setShowAlert(false);
		// }, 5000);
	};
	return (
		<DefaultLayout>
			<div className="mb-5 flex">
				<div className="flex flex-grow justify-start text-3xl font-bold text-[#364878] dark:text-white">
					<span>Data Dasar Air Limbah</span>
				</div>
				<div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
					<div className="flex w-full flex-grow justify-end text-lg font-bold md:w-auto">
						<button
							className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
							onClick={handleInputClick}
						>
							<FiPlusCircle />
							Input Data
						</button>
					</div>

					<div className="mt-2 flex w-full flex-grow justify-end text-lg font-bold md:mt-0 md:w-auto">
						<button className="inline-flex items-center justify-center rounded-lg bg-[#364878] px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiPlusCircle />
							Upload SK TIM
						</button>
					</div>
				</div>
			</div>

			{/* <div className="grid grid-cols-1 gap-5"> */}
			{/* {showAlert && (
					<AlertSuccess
						title="Add Successful"
						message="Your data has been Added successfully."
					/>
				)} */}
			{/* <div className="grid grid-cols-1 gap-9 md:grid-cols-2"> */}
			{/* kolom kiri */}
			{/* <div className="flex flex-col gap-9"> */}
			{/* <!-- Data Wilayah --> */}
			<div className='flex flex-1 overflow-hidden gap-4'>
				<ScrollArea className='basis-1/2'>
					{showForm ? (
						<FormDataWilayahAL onAddSuccess={handleAddSuccess} />
					) : (
						<TableDataWilayahAL />
					)}
				</ScrollArea>
				{/* <TableDataWilayahAL /> */}
				{/* <EditDataWilayahAL onEditSuccess={handleAddSuccess} /> */}
				{/* </div> */}
				{/* kolom kanan */}
				{/* <div className="flex flex-col gap-9"> */}
				{/* <!-- Data Dasar --> */}
				<ScrollArea className='basis-1/2'>
					<FormDataDasarAL />
				</ScrollArea>
			</div>
			{/* </div>
			</div>
			</div> */}
		</DefaultLayout>
	);
};

export default DataDasarAirLimbah;
