import { useEffect, useState } from 'react';
import { DataWilayahAL } from '@/types/dataWilayah';
import EditDataWilayahAL from '@/components/Forms/AirLimbah/DataDasar/EditDataWilayahAL';
import { ScrollArea } from '@/components/ui/scroll-area';

const DataWilayah: DataWilayahAL[] = [
	{
		id: 1,
		provinsi: 'provinsi-a',
		kabKota: 'city-a',
		jmlKecamatan: 10,
		jmlKelurahan: 50,
		luasWilayah: 1000,
		jmlRumahTangga: 2000,
		jmlKK: 1800,
		jmlJiwa: 5000,
		umpUmk: 3200000,
	},
	{
		id: 2,
		provinsi: 'provinsi-b',
		kabKota: 'city-b',
		jmlKecamatan: 10,
		jmlKelurahan: 50,
		luasWilayah: 1000,
		jmlRumahTangga: 2000,
		jmlKK: 1800,
		jmlJiwa: 5000,
		umpUmk: 3100000,
	},
	{
		id: 3,
		provinsi: 'provinsi-c',
		kabKota: 'city-c',
		jmlKecamatan: 10,
		jmlKelurahan: 50,
		luasWilayah: 1000,
		jmlRumahTangga: 2000,
		jmlKK: 1800,
		jmlJiwa: 5000,
		umpUmk: 3000500,
	},
];

const TableDataWilayahAL = () => {
	const [prov, setProv] = useState('');
	const [kab, setKab] = useState('');
	const [showEdit, setShowEdit] = useState(false);
	const [editId, setEditId] = useState(null);
	const [selectedData, setSelectedData] = useState<DataWilayahAL | null>(null);
	const [isButtonClicked, setIsButtonClicked] = useState(false);

	const handleProvChange = (selectedProv: string) => {
		setProv(selectedProv);
	};

	const handleKabChange = (selectedKab: string) => {
		setKab(selectedKab);
	};
	const handleButtonClick = () => {
		setIsButtonClicked((prevState) => !prevState);
	};

	useEffect(() => {
		if (isButtonClicked) {
			const data = DataWilayah.find(
				(item) => item.provinsi === prov && item.kabKota === kab,
			);

			setSelectedData(data || null);
		}
	}, [prov, kab, isButtonClicked]);

	const handleEditClick = (id: any) => {
		setShowEdit(true);
		setEditId(id);
		console.log(id);
	};

	const handleAddSuccess = (): void => {
		// alert('Upload successful');
		// setShowAlert(true);
		// setShowForm(false);
		// setTimeout(() => {
		// 	setShowAlert(false);
		// }, 5000);
	};

	return (
		<>
			{showEdit ? (
				<EditDataWilayahAL
					onEditSuccess={handleAddSuccess}
					data={selectedData}
				/>
			) : (
				<div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col flex-1 overflow-hidden">
					<div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
						<h3 className="text-2xl font-medium text-[#364878] dark:text-white">
							Data Wilayah
						</h3>
					</div>
					<div className="flex flex-col gap-5.5 p-6.5 ">
						<div className="flex flex-col justify-between 2xl:flex-row">
							<div className="">
								<label className="text-slate mb-3 block font-bold dark:text-white">
									Provinsi
								</label>
								{/* <ComboboxProv onChange={handleProvChange} /> */}
							</div>
							<div className="">
								<label className="text-slate mb-3 block font-bold dark:text-white">
									Kabupaten / Kota
								</label>
								{/* <ComboboxKab onChange={handleKabChange} /> */}
							</div>
						</div>
						<div className="mb-5 mt-2 flex justify-between gap-5">
							<div className=" w-full">
								<button
									className="inline-flex min-w-full items-center justify-center rounded-md bg-[#10B981] px-10 py-4 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
									onClick={handleButtonClick}
								>
									Lihat Data
								</button>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label className="text-slate mb-3 block font-medium dark:text-white">
									Jumlah Kecamatan
								</label>
							</div>
							<div className="text-right">
								<span className="font-bold">
									{selectedData ? selectedData.jmlKecamatan : '-'}
								</span>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label className="text-slate mb-3 block font-medium dark:text-white">
									Jumlah Kelurahan / Desa
								</label>
							</div>
							<div className="text-right">
								<span className="font-bold">
									{selectedData ? selectedData.jmlKelurahan : '-'}
								</span>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label className="text-slate mb-3 block font-medium dark:text-white">
									Luas Wilayah (km²)
								</label>
							</div>
							<div className="text-right">
								<span className="font-bold">
									{selectedData ? selectedData.luasWilayah : '-'}
								</span>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label className="text-slate mb-3 block font-medium dark:text-white">
									Jumlah Rumah Tangga
								</label>
							</div>
							<div className="text-right">
								<span className="font-bold">
									{selectedData ? selectedData.jmlRumahTangga : '-'}
								</span>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label className="text-slate mb-3 block font-medium dark:text-white">
									Jumlah KK
								</label>
							</div>
							<div className="text-right">
								<span className="font-bold">
									{selectedData ? selectedData.jmlKK : '-'}
								</span>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label className="text-slate mb-3 block font-medium dark:text-white">
									Jumlah Jiwa
								</label>
							</div>
							<div className="text-right">
								<span className="font-bold">
									{selectedData ? selectedData.jmlJiwa : '-'}
								</span>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex-col ">
								<label className=" text-slate block font-medium dark:text-white">
									UMP / UMK
								</label>
								<p className="text-[10px] text-red-500">
									*ump untuk akun <strong>Provinsi</strong> dan umk untuk akun{' '}
									<strong>Kabupaten</strong>
								</p>
								<div className=""></div>
							</div>
							<div className="text-right">
								<span className="font-bold">
									{selectedData
										? new Intl.NumberFormat('id-ID', {
											style: 'currency',
											currency: 'IDR',
										}).format(selectedData.umpUmk)
										: '-'}
								</span>
							</div>
						</div>
						<div className="mt-5 flex justify-between gap-5">
							<div className=" w-full">
								<button
									className="inline-flex min-w-full items-center justify-center rounded-md bg-[#3C6AE0] px-10 py-4 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
									onClick={() => handleEditClick(selectedData?.id)}
								>
									Edit Data
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default TableDataWilayahAL;
