import { RootState } from '@/app/store';
import { MiniLoader } from '@/common/Loader';
import ActionButtonSrnPengangkutan from '@/components/ActionButton/AirLimbah/ABSrnPengangkutan';
import { FInvSaranaPengangkutan } from '@/components/Forms/AirLimbah/PengumpulanData/FInvSarana';
import { GInvSrnPengangkutan } from '@/components/Guides/AirLimbah/PengumpulanData/GInvSrnPengangkutan';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import {
	useDeleteInvSarana,
	useGetInvSarana,
} from '@/hooks/airLimbah/useInvSarana';
import { useMenuAirLimbah } from '@/hooks/menu/useMenu';
import { usePrivilegeAirLimbah } from '@/hooks/privilege/usePrivilege';
import { InvSaranaAL } from '@/types/airLimbah';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

const TSrnPengankutanAL = () => {
	const cardRef = useRef(null);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;
	let rowCounter = 1;
	const { getInvSarana, refetch, isLoading } = useGetInvSarana(
		kd_provinsi_to_use,
		kd_kabkota_to_use,
	);
	const { deleteInvSarana } = useDeleteInvSarana();
	const QueryClient = useQueryClient();
	const { isMenuAirLimbahProv } = useMenuAirLimbah();
	const { isPrevilegeViewAirLimbah } = usePrivilegeAirLimbah();

	const handleEdit = (rowData: any) => {
		console.log('Edit data:', rowData);
	};

	const handleDelete = async (rowData: any) => {
		try {
			await deleteInvSarana({
				id_srn_pengangkutan: rowData.id_srn_pengangkutan,
			});
			QueryClient.refetchQueries({
				queryKey: ['inv-sarana'],
			});
			QueryClient.invalidateQueries({ queryKey: ['inv-sarana'] });
			toast.success('Berhasil menghapus data');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus data');
		}
	};

	useEffect(() => {
		refetch();
	}, [getInvSarana]);

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			{isPrevilegeViewAirLimbah ? (
				<></>
			) : (
				<div className="flex justify-end gap-4 p-4">
					<GInvSrnPengangkutan />
					<FInvSaranaPengangkutan />
				</div>
			)}

			{isLoading ? (
				<MiniLoader />
			) : (
				<ScrollArea className="relative h-full p-4">
					<JudulHeader cardRef={cardRef} isLoading={isLoading}>
						INVENTARISASI SARANA PENGANGKUTAN LUMPUR TINJA
						<br />
						Penyediaan Pelayanan Pengolahan Air Limbah Domestik
					</JudulHeader>
					<Table className="w-full min-w-[1800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead className="thead-text border-l text-center">
									No
								</TableHead>
								<TableHead className="thead-text text-center">
									Tahun Pelaporan
								</TableHead>
								<TableHead className="thead-text text-center">
									Sarana Pengangkutan <br /> Lumpur Tinja
								</TableHead>
								<TableHead className="thead-text text-center">
									Nomor Polisi
								</TableHead>
								<TableHead className="thead-text text-center">
									Tahun Kendaraan
								</TableHead>
								<TableHead className="thead-text text-center">
									Status Kepemilikan <br />
									(Pemda / Swasta)
								</TableHead>
								<TableHead className="thead-text text-center">
									Kondisi <br /> (Baik / Rusak)
								</TableHead>
								<TableHead className="thead-text text-center">
									Beroperasi <br /> (Ya / Tidak)
								</TableHead>
								<TableHead className="thead-text text-center">
									Kapasitas Pengangkutan
									<br />
									(m3)
								</TableHead>
								<TableHead className="thead-text text-center">
									Total Penyedotan <br /> per Minggu <br />
									(Unit Rumah)
								</TableHead>
								{isPrevilegeViewAirLimbah ? (
									<></>
								) : (
									<TableHead className="thead-text text-center" rowSpan={2}>
										Aksi (Edit/Hapus)
									</TableHead>
								)}
							</TableRow>
						</TableHeader>
						<TableBody>
							{getInvSarana.map((sarana: any) => (
								<TableRow
									key={sarana.id_srn_pengangkutan}
									className={
										rowCounter % 2 === 0
											? 'bg-gray-2 dark:bg-meta-4'
											: 'bg-white dark:bg-boxdark'
									}
								>
									<TableCell className="tbody-text border-b border-l border-r">
										{rowCounter++}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.tahun_pelaporan ? sarana.tahun_pelaporan : '-'}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.jns_srn_pengangkutan
											? sarana.jns_srn_pengangkutan
											: '-'}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.nmr_polisi ? sarana.nmr_polisi : '-'}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.tahun_kendaraan === 0
											? '-'
											: sarana.tahun_kendaraan
												? sarana.tahun_kendaraan
												: '-'}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.stat_kepemilikan ? sarana.stat_kepemilikan : '-'}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.kondisi ? sarana.kondisi : '-'}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.beroperasi}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.kap_pengangkutan ? sarana.kap_pengangkutan : '-'}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.tot_penyedotan ? sarana.tot_penyedotan : '-'}
									</TableCell>
									{isPrevilegeViewAirLimbah ? (
										<> </>
									) : (
										<TableCell className="tbody-text flex justify-center border-b border-r">
											<ActionButtonSrnPengangkutan
												rowData={sarana}
												onEdit={handleEdit}
												onDelete={handleDelete}
											/>
										</TableCell>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			)}
		</div>
	);
};

export default TSrnPengankutanAL;
