import { useState } from 'react';

import DefaultLayout from '@/layout/DefaultLayout';
import TDataCapaianALSample from '@/components/Tables/AirLimbah/DataCapaian/TDataCapaianALSample';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import FormDataCapaianKumulatifAL from '@/components/Forms/AirLimbah/DataCapaian/FormDataCapaianAL';
import EditDataCapaianAL from '@/components/Forms/AirLimbah/DataCapaian/EditDataCapaianAL';
import { RootState } from '@/app/store';
import { useSelector } from 'react-redux';
import DataCapaianKumulatifALProv from './KumulatifProv';

const DataCapaianKumulatifAL = () => {
	const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege);
	const auth = useSelector((state: RootState) => state.auth);

	const [showForm, setShowForm] = useState("Pilih Data");
	const [sampleId, setSampleId] = useState(1)

	const handleSampleChange = (e: number) => {
		setSampleId(e)
	}

	if (privilege === 2 || privilege === 4 || privilege === 8 || privilege === 10) return <DataCapaianKumulatifALProv />

	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead="Data Capaian Kumulatif Air Limbah Kabupaten/Kota"
				pageName="Data Capaian Kumulatif Air Limbah Kabupaten/Kota"
			/>
			{showForm === "Pilih Data" && <TDataCapaianALSample setShowForm={setShowForm} sampleId={sampleId} handleSampleChange={handleSampleChange} />}
			{showForm === "Form Data" && <FormDataCapaianKumulatifAL sampleId={sampleId} handleSampleChange={handleSampleChange} setShowForm={setShowForm} />}
			{showForm === "Edit Data" && <EditDataCapaianAL sampleId={sampleId} setShowForm={setShowForm} />}
		</DefaultLayout>
	);
};

export default DataCapaianKumulatifAL;
