import { API } from '../libs/API';
import { useQuery } from '@tanstack/react-query';

export function useGetJenisLokasiTdkSesuai(){
	const {
		data: getJenisLokasiTdkSesuai,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['jenis-lokasi-tdk-sesuai'],
		queryFn: async () => {
			const { data } = await API.get(`/jenis-lokasi-tdk-sesuai`);
			return data.data;
		},
	});
	return { getJenisLokasiTdkSesuai, refetch, isLoading };
}
