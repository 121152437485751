import React from 'react';
import {
	Select,
	SelectContent,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Check } from 'lucide-react';
import { cn } from '@/libs/utils';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command';
import { provinsi } from '@/types/lokasi';

type ComboboxProvinsiProps = {
	kdProvByMenu: number;
	setKdProvByMenu: (kdProvinsi: number) => void;
	selectedValue: string;
	setSelectedValue: (namaProvinsi: string) => void;
	loadProvinsi: boolean;
	getProvinsi: Array<{ kd_provinsi: number; nama_provinsi: string }>;
};

const ComboboxProvinsi: React.FC<ComboboxProvinsiProps> = ({
	kdProvByMenu,
	setKdProvByMenu,
	selectedValue,
	setSelectedValue,
	loadProvinsi,
	getProvinsi,
}) => {
	return (
		<Select value={kdProvByMenu.toString()}>
			<SelectTrigger
				className="tbody-text m-4 w-[15%] bg-white"
				role="combobox"
			>
				<SelectValue placeholder="Pilih Provinsi" className="font-bold">
					{selectedValue || 'Pilih Provinsi'}
				</SelectValue>
			</SelectTrigger>
			<SelectContent className="z-99999">
				<Command className="overflow-y-hidden">
					<CommandInput placeholder="Cari Provinsi..." />
					<CommandList>
						<CommandEmpty>Provinsi Tidak Ditemukan.</CommandEmpty>
						<CommandGroup>
							{!loadProvinsi &&
								getProvinsi.map((provinsi: provinsi) => (
									<CommandItem
										key={provinsi.kd_provinsi}
										onSelect={() => {
											setKdProvByMenu(provinsi.kd_provinsi);
											setSelectedValue(provinsi.nama_provinsi);
										}}
									>
										<Check
											className={cn(
												'mr-2 h-4 w-4',
												kdProvByMenu === provinsi.kd_provinsi
													? 'opacity-100'
													: 'opacity-0',
											)}
										/>
										{provinsi.nama_provinsi}
									</CommandItem>
								))}
						</CommandGroup>
					</CommandList>
				</Command>
			</SelectContent>
		</Select>
	);
};

export default ComboboxProvinsi;
