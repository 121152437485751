import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { API } from "../libs/API";
import { useDispatch } from "react-redux";
import { editAuth } from "../app/auth/loginSlice";

export default function useEditProfile() {
  const dispatch = useDispatch()
  const {
    mutateAsync: asyncEditProfile,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await API.put('/edit-user-profile', data);
      return response;
    },
    onSuccess: (result) => {
      dispatch(editAuth(result.data.data))
      toast.success('User berhasil diedit');
    },
    onError: (error) => {
      console.log(error);
      toast.error('User gagal diedit');
    },
  });
  return { asyncEditProfile, isPending, isSuccess };
}