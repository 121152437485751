import React, { useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface DesaSelectProps {
	getDesa: any[];
	setKd_desa: (kd_desa: string) => void;
}

const DesaSelectUI: React.FC<DesaSelectProps> = ({
    getDesa,
    setKd_desa,
}) => {
    const [selectedValue, setSelectedValue] = useState("");

    return (
        <Select onValueChange={(value) => {
            console.log("Value:", value);
            if (value !== '') {
                const selectedDesa = getDesa.find(desa => desa.kd_desa === value);
                if(selectedDesa){
                  setSelectedValue(selectedDesa.nama_desa || "");
                  setKd_desa(value);
                }
            }
        }}>
            <SelectTrigger>
                <SelectValue placeholder="Pilih Desa">{selectedValue || "Pilih Desa"}</SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getDesa ? getDesa.map((desa: any, index: any) => (
                     <SelectItem key={index} value={desa.kd_desa}>
                        {desa.nama_desa}
                    </SelectItem>
                )) : null}
            </SelectContent>
        </Select>
    );
};

export default DesaSelectUI;


