import { useState } from 'react';

import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import TableDataCapaianKumulatifRumahRelokasi from '@/components/Tables/RumahRelokasi/DataCapaian/Kumulatif/TableDataCapaianKumulatifRumahRelokasi';
import FormDataCapaianKumulatifRumahRelokasi from '@/components/Forms/RumahRelokasi/FormDataCapaianKumulatifRumahRelokasi';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import DataCapaianKumulatifRRProv from './KumulatifProv';
import EditFormDataCapaianKumulatifRumahRelokasi from '@/components/Forms/RumahRelokasi/EditFormDataCapaianKumulatifRumahRelokasi';

const DataCapaianKumulatifRumahRelokasi = () => {
	const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege);
	const [showForm, setShowForm] = useState("Pilih Data")
	const [editableId, setEditableId] = useState(0);


	if (privilege === 2 || privilege === 4 || privilege === 8 || privilege === 10) return <DataCapaianKumulatifRRProv />

	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead="Data Capaian Kumulatif Rumah Relokasi"
				pageName="Data Capaian Kumulatif Rumah Relokasi"
			/>
			{showForm === 'Pilih Data' && (
				<TableDataCapaianKumulatifRumahRelokasi
					setEditableId={setEditableId}
					setShowForm={setShowForm}
				/>
			)}
			{showForm === 'Form Data' && (
				<FormDataCapaianKumulatifRumahRelokasi setShowForm={setShowForm} />
			)}
			{showForm === 'Edit Data' && (
				<EditFormDataCapaianKumulatifRumahRelokasi
					editableId={editableId}
					setShowForm={setShowForm}
				/>
			)}
		</DefaultLayout>
	);
};

export default DataCapaianKumulatifRumahRelokasi;
