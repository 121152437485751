import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

// utils/formatRupiah.js
export const formatRupiah = (number: number) => {
	// Ubah angka menjadi format Rupiah
	const formattedNumber = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
		maximumFractionDigits: 0,
	}).format(number);

	return formattedNumber;
};

export const hitungTotalData = (pages: any, yangDiHitung: any) => {
	return pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr[yangDiHitung], 0)
}

export const formatTanggal = (tanggalString: string) => {
	const date = new Date(tanggalString);
	const options: Intl.DateTimeFormatOptions = {
		day: '2-digit',
		month: 'long',
		year: 'numeric',
	};
	return date.toLocaleDateString('id-ID', options);
};