import { ScrollArea } from '@/components/ui/scroll-area';
import { useState, FormEvent } from 'react';


interface FormAddDataWilayahAMProps {
	onAddSuccess: () => void;
}
const FormDataWilayahAM = (props: FormAddDataWilayahAMProps) => {
	const [prov, setProv] = useState('');
	const [kab, setKab] = useState('');
	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		// Handle file upload here
		console.log(prov, kab);
		props.onAddSuccess();
		// navigate('/');
	};
	return (
		<form className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden" action="" onSubmit={handleSubmit}>
			<div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
				<h3 className="text-2xl font-medium text-[#364878] dark:text-white">
					Data Wilayah
				</h3>
			</div>
			<ScrollArea>
				<div className="flex flex-col gap-5.5 p-6.5 ">
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label className="text-slate mb-3 block font-medium dark:text-white">
								Jumlah Kecamatan
							</label>
						</div>
						<div className="text-right">
							<input
								type="text"
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-right text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label className="text-slate mb-3 block font-medium dark:text-white">
								Jumlah Kelurahan / Desa
							</label>
						</div>
						<div className="text-right">
							<input
								type="text"
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-right text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label className="text-slate mb-3 block font-medium dark:text-white">
								Luas Wilayah (km²)
							</label>
						</div>
						<div className="text-right">
							<input
								type="text"
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-right text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
						<div className="flex items-end">
							<label className="text-slate mb-3 block font-medium dark:text-white">
								Jumlah Rumah Tangga
							</label>
						</div>
						<div className="text-right">
							<input
								type="text"
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-right text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label className="text-slate mb-3 block font-medium dark:text-white">
								Jumlah Rumah Tangga
							</label>
						</div>
						<div className="text-right">
							<input
								type="text"
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-right text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label className="text-slate mb-3 block font-medium dark:text-white">
								Jumlah KK
							</label>
						</div>
						<div className="text-right">
							<input
								type="text"
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-right text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label className="text-slate mb-3 block font-medium dark:text-white">
								Jumlah Jiwa
							</label>
						</div>
						<div className="text-right">
							<input
								type="text"
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-right text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex-col ">
							<label className=" text-slate block font-medium dark:text-white">
								UMP / UMK
							</label>
							<p className="text-[10px] text-red-500">
								*ump untuk akun <strong>Provinsi</strong> dan umk untuk akun{' '}
								<strong>Kabupaten</strong>
							</p>
							<div className=""></div>
						</div>
						<div className="text-right">
							<input
								type="text"
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-right text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="mt-5 flex justify-between gap-5">
						<div className="w-full">
							<button
								className="inline-flex min-w-full items-center justify-center rounded-md border border-meta-3 px-10 py-4 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
								type="submit"
							>
								Simpan Data
							</button>
						</div>
					</div>
				</div>
			</ScrollArea>
		</form>
	);
};
export default FormDataWilayahAM;
