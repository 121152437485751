import { createSlice } from '@reduxjs/toolkit';

export const fileSlice = createSlice({
  name: "file",
  initialState: {
    file_am: null,
    file_al: null,
  },
  reducers: {
    setFileAM: (state, action) => {
      const data = action.payload
      state.file_am = data
    },
    setFileAL: (state, action) => {
      const data = action.payload
      state.file_al = data
    },
  }
})

export const { setFileAM, setFileAL } = fileSlice.actions;
export default fileSlice.reducer;