const TableDataDasarPerumahan = () => {
	return (
		<div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 flex flex-col flex-1 overflow-hidden">
			<h4 className="mb-6 text-xl font-semibold text-[#364878] dark:text-white">
				Data Dasar Perumahan
			</h4>

			<div className="flex flex-col">
				<div className="grid grid-cols-1 rounded-sm bg-gray-2 dark:bg-meta-4">
					{/* Updated to 3 columns */}
					<div className="p-2.5 text-center xl:p-5">
						<h5 className="text-sm font-medium uppercase xsm:text-base">
							Peta Rawan Bencana
						</h5>
					</div>
				</div>

				<div className="grid grid-cols-12">
					<div className="flex items-center justify-start p-2.5 xl:p-5">
						<p className="text-black dark:text-white">1</p>
					</div>
					<div className="flex items-center justify-center col-span-11 p-2.5 xl:p-5">
						<p className="text-[#10B981]">ini berisi peta rawan bencana</p>
					</div>
				</div>
			</div>

			<div className="flex flex-col">
				<div className="grid grid-cols-1 rounded-sm bg-gray-2 dark:bg-meta-4">
					{/* Updated to 3 columns */}
					<div className="p-2.5 text-center xl:p-5">
						<h5 className="text-sm font-medium uppercase xsm:text-base">
							Peta Rencana Tata Ruang Wilayah (RT/RW)
						</h5>
					</div>
				</div>

				<div className="grid grid-cols-12">
					<div className="flex items-center justify-start p-2.5 xl:p-5">
						<p className="text-black dark:text-white">1</p>
					</div>
					<div className="flex col-span-11 items-center justify-center p-2.5 xl:p-5">
						<p className="text-[#10B981]">
							ini berisi peta rencana tata ruang wilayah (RT/RW)
						</p>
					</div>
				</div>
			</div>
			<div className="flex flex-col">
				<div className="grid grid-cols-1 rounded-sm bg-gray-2 dark:bg-meta-4">
					{/* Updated to 3 columns */}
					<div className="p-2.5 text-center xl:p-5">
						<h5 className="text-sm font-medium uppercase xsm:text-base">
							Dokumen Rencana Pembangunan dan Pengembangan Perumahan dan Kawasan
							Permukiman (RP3KP)
						</h5>
					</div>
				</div>

				<div className="grid grid-cols-12">
					<div className="flex items-center justify-start p-2.5 xl:p-5">
						<p className="text-black dark:text-white">1</p>
					</div>
					<div className="flex col-span-11 items-center justify-center p-2.5 xl:p-5">
						<p className="text-[#10B981]">
							ini berisi dokumen rencana pembangunan dan pengembangan perumahan
							dan kawasan permukiman (RP3KP)
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableDataDasarPerumahan;
