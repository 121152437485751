import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { TbMapPinCheck } from 'react-icons/tb';
import { useRef, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useGetPeriode } from '@/hooks/usePeriode';
import { Button } from '@/components/ui/Button';
import { useGetPeriodeAirMinum } from '@/hooks/airMinum/useSummary';
import { toast } from 'sonner';
import { formatTanggal } from '@/libs/utils';
import { DatePickerPeriode } from '@/components/LitleComponent/DatePickerPeriode';
import { PeriodeData } from '@/types/summary';
import { ArrowUpDown } from 'lucide-react';
import _ from 'lodash';
import Fuse from 'fuse.js';
import { Input } from '@/components/ui/input';
import { FiAlertCircle } from 'react-icons/fi';

export const TCapaianPeriode = () => {
	const selectedTahun = useSelector((state: RootState) => state.tahun.value);
	const tahun = Number(selectedTahun);
	const [periodeSatu, setPeriodeSatu] = useState<string | undefined>(undefined);
	const [periodeDua, setPeriodeDua] = useState<string | undefined>(undefined);
	const [selectedPeriodeSatu, setSelectedPeriodeSatu] = useState<string>('');
	const [selectedPeriodeDua, setSelectedPeriodeDua] = useState<string>('');
	const [filterProvinsi, setFilterProvinsi] = useState<string>('');
	const [debouncedFilterProvinsi, setDebouncedFilterProvinsi] =
		useState<string>('');
	const { getPeriode } = useGetPeriode();
	const [sortConfig, setSortConfig] = useState<{
		key: string;
		direction: 'asc' | 'desc';
	} | null>(null);

	const { data, isLoading } = useGetPeriodeAirMinum(
		Number(selectedPeriodeSatu),
		Number(selectedPeriodeDua),
	);

	const handleBandingkan = () => {
		if (periodeSatu && periodeDua) {
			setSelectedPeriodeSatu(periodeSatu);
			setSelectedPeriodeDua(periodeDua);
		} else if (periodeSatu === undefined && periodeDua === undefined) {
			toast.error('Silahkan Pilih Periode terlebih dahulu!');
		} else {
			toast.error('Tidak Memiliki Record!');
		}
	};

	const pages = data?.pages || [];

	const filteredPages = useMemo(() => {
		if (!debouncedFilterProvinsi) return pages.flatMap((page) => page.data);

		const fuse = new Fuse(
			pages.flatMap((page) => page.data),
			{
				keys: ['nama_provinsi', 'nama_kabkota'],
				threshold: 0.3, // Adjust the threshold for fuzzy matching
			},
		);

		return fuse.search(debouncedFilterProvinsi).map((result) => result.item);
	}, [pages, debouncedFilterProvinsi]);

	const sortedPages = useMemo(() => {
		if (!sortConfig) return filteredPages;

		const { key, direction } = sortConfig;
		return _.orderBy(
			filteredPages,
			[
				(item) => {
					const value = item[key];
					return isNaN(value)
						? value?.toString().toLowerCase().trim()
						: Number(value);
				},
			],
			[direction],
		);
	}, [filteredPages, sortConfig]);

	const handleSort = useCallback(
		_.debounce((key: string) => {
			let direction: 'asc' | 'desc' = 'asc';
			if (
				sortConfig &&
				sortConfig.key === key &&
				sortConfig.direction === 'asc'
			) {
				direction = 'desc';
			}
			setSortConfig({ key, direction });
		}, 300),
		[sortConfig],
	);

	const handleResetSortFilter = () => {
		setSortConfig(null);
		setFilterProvinsi('');
		setDebouncedFilterProvinsi('');
	};

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilterProvinsi(e.target.value);
		debouncedFilter(e.target.value);
	};

	const debouncedFilter = useCallback(
		_.debounce((value: string) => {
			setDebouncedFilterProvinsi(value);
		}, 300),
		[],
	);

	const periodeArray = Array.isArray(getPeriode) ? getPeriode : [];

	const periodeSatuTanggal = periodeArray.find(
		(periode: any) => periode.id_periode_tanggal === selectedPeriodeSatu,
	)?.tanggal;

	const periodeDuaTanggal = periodeArray.find(
		(periode: any) => periode.id_periode_tanggal === selectedPeriodeDua,
	)?.tanggal;

	let summaryNaik = 0;
	let summaryTurun = 0;
	let summaryTetap = 0;

	let jumlahMelaporPeriode1 = 0;
	let jumlahMelaporPeriode2 = 0;

	pages.forEach((page) => {
		page.data.forEach((item: any) => {
			if (
				item.presentase_capaian_periode_2 > item.presentase_capaian_periode_1 &&
				item.keterangan_periode_2 === 'Melapor'
			) {
				summaryNaik++;
			}

			if (
				item.presentase_capaian_periode_2 < item.presentase_capaian_periode_1 &&
				item.keterangan_periode_2 === 'Melapor'
			) {
				summaryTurun++;
			}

			if (
				item.presentase_capaian_periode_2 ===
					item.presentase_capaian_periode_1 &&
				item.keterangan_periode_2 === 'Melapor'
			) {
				summaryTetap++;
			}

			if (item.keterangan_periode_1 === 'Melapor') {
				jumlahMelaporPeriode1++;
			}

			if (item.keterangan_periode_2 === 'Melapor') {
				jumlahMelaporPeriode2++;
			}
		});
	});

	let summaryMelapor = jumlahMelaporPeriode2 - jumlahMelaporPeriode1;

	const cardRef = useRef(null);

	let rowCounter = 0;
	if (isLoading) return <CompLoader />;

	return (
		<>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader isLoading={isLoading} cardRef={cardRef}>
						TABEL PERBANDINGAN <br />
						CAPAIAN PER PERIODE PADA TAHUN 2023
					</JudulHeader>
					<div className="sticky top-0 flex items-center justify-start py-4 text-center">
						<div className="inline-flex items-center gap-2 text-center font-bold">
							<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
							yang disandingkan adalah data SPM tahun 2023 dengan Lokpri DAK
							2025
						</div>
					</div>
					<JudulHeader cardRef={cardRef}>
						<div className="flex w-full justify-between gap-3 p-2">
							<div className="flex gap-3">
								<DatePickerPeriode
									setPeriode_1={setPeriodeSatu}
									setPeriode_2={setPeriodeDua}
								/>
								<Button
									size={'sm'}
									className="items-center justify-start gap-1 rounded-lg bg-green-500 px-4 py-2 text-center font-medium text-white hover:bg-green-600"
									onClick={handleBandingkan}
								>
									Bandingkan Periode
								</Button>
							</div>
							<div className="mr-15 flex gap-3">
								<Input
									type="text"
									placeholder="Filter Lokasi"
									value={filterProvinsi}
									onChange={handleFilterChange}
									className="w-[20rem] rounded border p-2"
								/>
								<Button
									size={'sm'}
									className="inline-flex items-center justify-end gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600"
									onClick={handleResetSortFilter}
								>
									Reset
								</Button>
							</div>
						</div>
					</JudulHeader>
					<Table className="w-full min-w-[1800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									className="thead-text border-l text-center"
									rowSpan={2}
								>
									No
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									<Button
										className="thead-text"
										variant="ghost"
										onClick={() => handleSort('nama_provinsi')}
									>
										Provinsi
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									<Button
										className="thead-text"
										variant="ghost"
										onClick={() => handleSort('nama_kabkota')}
									>
										Kabupaten/Kota
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									Capaian Periode <br />{' '}
									{periodeSatuTanggal ? formatTanggal(periodeSatuTanggal) : '-'}
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									Capaian Periode <br />{' '}
									{periodeDuaTanggal ? formatTanggal(periodeDuaTanggal) : '-'}
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Lokpri 2025
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-t-0 text-center">
									<Button
										className="thead-text"
										variant="ghost"
										onClick={() => handleSort('presentase_capaian_periode_1')}
									>
										Presentase
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									<Button
										className="thead-text"
										variant="ghost"
										onClick={() => handleSort('keterangan_periode_1')}
									>
										Keterangan
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									<Button
										className="thead-text"
										variant="ghost"
										onClick={() => handleSort('presentase_capaian_periode_2')}
									>
										Presentase
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									<Button
										className="thead-text"
										variant="ghost"
										onClick={() => handleSort('keterangan_periode_2')}
									>
										Keterangan
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{sortedPages.map((item: PeriodeData, index: number) => {
								const textColorClass =
									item.presentase_capaian_periode_2 >
									item.presentase_capaian_periode_1
										? 'text-green-600'
										: item.presentase_capaian_periode_2 <
											  item.presentase_capaian_periode_1
											? 'text-red-600'
											: 'text-black';

								const textColorClassKeteranganPeriode =
									item.keterangan_periode_1 === 'Melapor'
										? item.keterangan_periode_2 === 'Melapor'
											? 'text-black'
											: 'text-red-600'
										: item.keterangan_periode_2 === 'Melapor'
											? 'text-green-600'
											: 'text-black';

								const bgColorClass =
									item.presentase_capaian_periode_2 >
									item.presentase_capaian_periode_1
										? 'bg-green-600'
										: item.presentase_capaian_periode_2 <
											  item.presentase_capaian_periode_1
											? 'bg-red-600'
											: 'bg-black-600';

								const bgClassKeteranganPeriode =
									item.keterangan_periode_1 === 'Melapor'
										? item.keterangan_periode_2 === 'Melapor'
											? 'bg-black-600'
											: 'bg-red-600'
										: item.keterangan_periode_2 === 'Melapor'
											? 'bg-green-600'
											: 'bg-black-600';
								return (
									<TableRow
										key={index}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="tbody-text border-b border-l text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="tbody-text mx-0 border-b border-l px-0 text-center">
											{item.nama_provinsi}
										</TableCell>
										<TableCell className="tbody-text mx-0 border-b border-l px-0 text-center">
											{item.nama_kabkota}
										</TableCell>
										<TableCell
											className={`mx-0 border-b border-l px-0 text-center font-bold text-black`}
										>
											{item.presentase_capaian_periode_1}%
										</TableCell>
										<TableCell
											className={`mx-0 border-b border-l px-0 text-center font-bold text-black`}
										>
											{item.keterangan_periode_1}
										</TableCell>
										<TableCell
											className={`border-b border-l text-center ${textColorClass} ${bgColorClass} mx-0 bg-opacity-10 px-0 font-bold`}
										>
											{item.presentase_capaian_periode_2}%
										</TableCell>
										<TableCell
											className={`border-b border-l text-center ${textColorClassKeteranganPeriode} ${bgClassKeteranganPeriode} mx-0 bg-opacity-10 px-0 font-bold`}
										>
											{item.keterangan_periode_2}
										</TableCell>
										<TableCell
											className={`tbody-text mx-0 border-b border-l border-r px-0 text-center ${item.lokpri_2025 === 1 ? 'bg-yellow-500 bg-opacity-10' : ''}`}
										>
											{item.lokpri_2025 === 1 ? (
												<i className="mx-auto flex items-center justify-center">
													<TbMapPinCheck className="size-5 text-yellow-600" />
												</i>
											) : (
												'-'
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					<div className="my-10">
						<p className="text-center font-bold">Summary :</p>
						<div className="my-1 flex gap-4">
							<div className="h-5 w-5 bg-green-600"></div>
							<p className="text-center">
								{summaryNaik}
								<span /> Daerah Mengalami Kenaikan Presentase Pada Periode{' '}
								{periodeDuaTanggal ? formatTanggal(periodeDuaTanggal) : '-'}
							</p>
						</div>
						<div className="my-1 flex gap-4">
							<div className="h-5 w-5 bg-red-600"></div>
							<p className="text-center">
								{summaryTurun}
								<span /> Daerah Mengalami Penurunan Presentase Pada Periode{' '}
								{periodeDuaTanggal ? formatTanggal(periodeDuaTanggal) : '-'}
							</p>
						</div>
						<div className="my-1 flex gap-4">
							<div className="h-5 w-5 bg-black"></div>
							<p className="text-center">
								{summaryTetap}
								<span /> Daerah Tidak Mengalami Perubahan Presentase (Tetap)
								Pada Periode{' '}
								{periodeDuaTanggal ? formatTanggal(periodeDuaTanggal) : '-'}
							</p>
						</div>
						<div className="my-1 flex gap-4">
							<div className="h-5 w-5 bg-green-600"></div>
							<p className="text-center">
								{' '}
								{summaryMelapor}
								<span /> Daerah Melapor Pada Periode{' '}
								{periodeDuaTanggal ? formatTanggal(periodeDuaTanggal) : '-'}
							</p>
						</div>
					</div>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};
