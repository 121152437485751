import React, { useState, useEffect } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

interface KecamatanProps {
    getKecamatan: any[];
    setKd_kec: (kd_kec: number) => void;
    selectedValue: number; 
    disabledSelect: boolean;
}

const EditKecamatanSelectUI: React.FC<KecamatanProps> = ({
    getKecamatan,
    setKd_kec,
    selectedValue,
    disabledSelect,
    
}) => {
    const [selectedKecamatan, setSelectedKecamatan] = useState('');

    useEffect(() => {
        if (Array.isArray(getKecamatan)) {
            const selectedKecamatan = getKecamatan.find((kecamatan: any) => kecamatan.kd_kec === selectedValue);
            if (selectedKecamatan) {
                setSelectedKecamatan(selectedKecamatan.nama_kecamatan);
            }
        } else {
            // console.log('getKecamatan is not an array or is undefined', getKecamatan);
        }
    }, [selectedValue, getKecamatan, ]);

    return (
        <Select
            onValueChange={(value) => {
                if (value !== '') {
                    const selectedKecamatan = getKecamatan.find((kecamatan: any) => kecamatan.kd_kec === parseInt(value));
                    if (selectedKecamatan) {
                        // console.log("Desa yang dipilih:", selectedKecamatan.nama_kecamatan, "kode:", parseInt(value));
                        setSelectedKecamatan(selectedKecamatan.nama_kecamatan || "");
                        setKd_kec(parseInt(value)); // Convert to number before passing
                    }
                }
            }}
            value={selectedValue.toString()} // Convert to string for Select
            disabled={disabledSelect}
        >
            <SelectTrigger>
                <SelectValue placeholder="Pilih Kecamatan">
                    {selectedKecamatan || 'Pilih Kecamatan'}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {Array.isArray(getKecamatan) ? (
                    getKecamatan.map((kecamatan: any, index: any) => (
                        <SelectItem key={index} value={kecamatan.kd_kec.toString()}>
                            {kecamatan.nama_kecamatan}
                        </SelectItem>
                    ))
                ) : (
                    <div>Error loading desa</div>
                )}
            </SelectContent>
        </Select>
    );
};

export default EditKecamatanSelectUI;
