import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { FormEvent, useEffect, useState } from 'react';
import DesaSelectUI from '@/components/ui/SelectDesaUI';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { usePostIdentifikasiPerumahan } from '@/hooks/perumahan/useRumahBencana';
import { useGetJenisBencana } from '@/hooks/useJenisBencana';
import JenisBencanaSelectUI from '@/components/ui/SelectJenisBencanaUI';
import { useQueryClient } from '@tanstack/react-query';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import KabKotaSelectUI from '@/components/ui/SelectKabKotaUI';

const FKondisiSPAMRegional = () => {
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kec, setKd_kec] = useState('0');
	const [kd_desa, setKd_desa] = useState('0');
	const [kd_kabkota, setKd_kabkota] = useState(user.kd_kabkota.toString());

	//get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
	const { getKecamatan, refetch: fetchKecamatan } = useGetKecamatan(
		user.kd_provinsi,
		parseInt(kd_kabkota),
	);
	const { getDesa, refetch: fetchDesa } = useGetDesa(
		user.kd_provinsi,
		parseInt(kd_kabkota),
		parseInt(kd_kec),
	);
	const { postIdentifikasiPerumahan } = usePostIdentifikasiPerumahan();
	const { getJenisBencana } = useGetJenisBencana();

	//dataform
	const [namaSPAM, setNamaSPAM] = useState('');
	const [namaSumber, setNamaSumber] = useState('');
	const [lokasiUnit, setLokasiUnit] = useState('');
	const [kapasitasIntake, setKapasitasIntake] = useState('');
	const [kapasitasProduksi, setKapasitasProduksi] = useState('');
	const [kapasitasIdle, setKapasitasIdle] = useState('');
	const [jenisPipa, setJenisPipa] = useState('');
	const [panjangPipa, setPanjangPipa] = useState('');
	const [diameterPipa, setDiameterPipa] = useState('');
	const [unitPelayanan, setUnitPelayanan] = useState('0');

	//additionals
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	//resetForm
	const resetForm = () => {
		setNamaSPAM('');
		setNamaSumber('');
		setLokasiUnit('');
		setKapasitasIntake('');
		setKapasitasProduksi('');
		setKapasitasIdle('');
		setJenisPipa('');
		setPanjangPipa('');
		setDiameterPipa('');
		setUnitPelayanan('');
	};

	const unitPelayananMapping: { [key: string]: number } = {
		PDAM: 1,
		UPTD: 2,
		BadanUsaha: 3,
		KelompokPengelola: 4,
		Kosong: 5,
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		const data = {
			tahun_pelaporan: tahun,
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: kd_kabkota,
			nama_spam: namaSPAM,
			nama_submer: namaSumber,
			lokasi_unit: lokasiUnit,
			kapasitas_intake: kapasitasIntake,
			kapasitas_produksi: kapasitasProduksi,
			kapasitas_idle: kapasitasIdle,
			jenis_pipa: jenisPipa,
			panjang_pipa: panjangPipa,
			diameter_pipa: diameterPipa,
			unit_pelayanan: unitPelayananMapping[unitPelayanan],
		};

		console.log(data);

		// postIdentifikasiPerumahan(data, {
		// 	onSuccess: () => {
		// 		setModalOpen(false);
		// 		queryClient.refetchQueries({ queryKey: ['eksis-rawan-bencana'] });
		// 		resetForm();
		// 	},
		// 	onError: (error) => {
		// 		console.log(error);
		// 	},
		// });
	};

	useEffect(() => {
		fetchDesa();
		fetchKecamatan();
	}, [kd_kec, kd_kabkota]);

	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
						<FiPlusCircle />
						Input Data
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 max-h-[85%] max-w-[70%] overflow-auto">
					<DialogHeader>
						<DialogTitle>Tambah Kondisi SPAM Regional</DialogTitle>
						<DialogDescription className="my-1">
							Jika Belum Memiliki Data, Silahkan Isi Dengan Angka 0 Agar Bisa Di
							Simpan
							<br />
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>
					<form action="post" onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Nama SPAM Regional" className="mb-2">
									Nama SPAM Regional
								</Label>
								<Input
									id="Nama SPAM Regional"
									type="text"
									className="col-span-1"
									value={namaSPAM}
									onChange={(e) => setNamaSPAM(e.target.value)}
									placeholder="Masukkan Nama SPAM Regional"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kabupaten" className="mb-2">
									Kabupaten
								</Label>
								<KabKotaSelectUI
									getKabKota={getKabKota}
									setKabKota={setKd_kabkota}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Unit Pelayanan" className="mb-2">
									Unit Pelayanan
								</Label>
								<Select onValueChange={(value) => setUnitPelayanan(value)}>
									<SelectTrigger>
										<SelectValue placeholder="Pilih Unit Pelayanan" />
									</SelectTrigger>
									<SelectContent className="z-99999">
										<SelectItem value="Kosong">-</SelectItem>
										<SelectItem value="PDAM">PDAM</SelectItem>
										<SelectItem value="UPTD">UPTD</SelectItem>
										<SelectItem value="BadanUsaha">Badan Usaha</SelectItem>
										<SelectItem value="KelompokPengelola">
											Kelompok Pengelola SPAM
										</SelectItem>
									</SelectContent>
								</Select>
							</div>
						</div>
						{/* Unit Air Baku */}
						<h1 className="text-l mt-4 font-bold">Unit Air Baku</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Nama Sumber" className="mb-2">
									Nama Sumber
								</Label>
								<Input
									id="Nama Sumber"
									type="text"
									className="col-span-1"
									value={namaSumber}
									onChange={(e) => setNamaSumber(e.target.value)}
									placeholder="Masukkan Nama Sumber"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Lokasi Unit" className="mb-2">
									Lokasi Unit
								</Label>
								<Input
									id="Lokasi Unit"
									type="text"
									className="col-span-1"
									value={lokasiUnit}
									onChange={(e) => setLokasiUnit(e.target.value)}
									placeholder="Masukkan Lokasi Unit"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas Intake" className="mb-2">
									Kapasitas Intake
								</Label>
								<Input
									id="Kapasitas Intake"
									type="number"
									className="col-span-1"
									value={kapasitasIntake}
									onChange={(e) => setKapasitasIntake(e.target.value)}
									placeholder="Masukkan Jumlah Kapasitas Intake"
									required={true}
								/>
							</div>
						</div>
						{/* Unit Produksi */}
						<h1 className="text-l mt-4 font-bold">Unit Produksi</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas Produksi" className="mb-2">
									Kapasitas Produksi
								</Label>
								<Input
									id="Kapasitas Produksi"
									type="number"
									className="col-span-1"
									value={kapasitasProduksi}
									onChange={(e) => setKapasitasProduksi(e.target.value)}
									placeholder="Masukkan Jumlah Kapasitas Produksi"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kapasitas Idle" className="mb-2">
									Kapasitas Idle
								</Label>
								<Input
									id="Kapasitas Idle"
									type="number"
									className="col-span-1"
									value={kapasitasIdle}
									onChange={(e) => setKapasitasIdle(e.target.value)}
									placeholder="Masukkan Jumlah Kapasitas Idle"
									required={true}
								/>
							</div>
						</div>
						{/* Unit Distribusi */}
						<h1 className="text-l mt-4 font-bold">Unit Distribusi</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Jenis Pipa" className="mb-2">
									Jenis Pipa
								</Label>
								<Input
									id="Jenis Pipa"
									type="text"
									className="col-span-1"
									value={jenisPipa}
									onChange={(e) => setJenisPipa(e.target.value)}
									placeholder="Masukkan Jenis Pipa"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Panjang Pipa" className="mb-2">
									Panjang Pipa
								</Label>
								<Input
									id="Panjang Pipa"
									type="number"
									className="col-span-1"
									value={panjangPipa}
									onChange={(e) => setPanjangPipa(e.target.value)}
									placeholder="Masukkan Panjang Pipa"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Diameter Pipa" className="mb-2">
									Diameter Pipa
								</Label>
								<Input
									id="Diameter Pipa"
									type="number"
									className="col-span-1"
									value={diameterPipa}
									onChange={(e) => setDiameterPipa(e.target.value)}
									placeholder="Masukkan Diameter Pipa"
									required={true}
								/>
							</div>
						</div>
						<DialogFooter>
							<div className="mt-5 flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default FKondisiSPAMRegional;
