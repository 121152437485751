import { z } from 'zod';

export const AddSrnInvSchema = z.object({
	jns_srn_pengangkutan: z.string(),
	nmr_polisi: z.string(),
	stat_kepemilikan: z.string(),
	kondisi: z.string(),
	beroperasi: z.string(),
	kap_pengangkutan: z.string(),
	tot_penyedotan: z.string(),
	tahun_pelaporan: z.string(),
	tahun_kendaraan: z.string()
});
