import React, { useState, ReactNode, useEffect } from 'react';
import Header from '../components/Header/index';
import Sidebar from '../components/Sidebar/index';

const DefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [sidebarOpen, setSidebarOpen] = useState(true);
	// const [provinsi, setProvinsi] = useState("-")
	// const [kabKota, setKabKota] = useState("-")

	// useEffect(() => {
	// 	setProvinsi("-");
	// 	setKabKota("-");
	// }, []);

	// Function to check window width
	const checkWindowWidth = () => {
		const mdWidth = 960; // Width for md breakpoint in Tailwind CSS
		if (window.innerWidth <= mdWidth) {
			setSidebarOpen(false);
		} else {
			setSidebarOpen(true);
		}
	};

	// Hook to listen for window resize events
	useEffect(() => {
		checkWindowWidth();
		window.addEventListener('resize', checkWindowWidth);

		// Cleanup function
		return () => {
			window.removeEventListener('resize', checkWindowWidth);
		};
	}, []);
	return (
		<div className="dark:bg-boxdark-2 dark:text-bodydark">
			{/* <!-- ===== Page Wrapper Start ===== --> */}
			<div className="flex h-screen overflow-hidden">
				{/* <!-- ===== Sidebar Start ===== --> */}
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				{/* <!-- ===== Sidebar End ===== --> */}

				{/* <!-- ===== Content Area Start ===== --> */}
				<div
					className={`relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden transition-all duration-500 ease-in-out ${sidebarOpen ? 'pl-72' : 'pl-0'
						}`}
				>
					{/* <!-- ===== Header Start ===== --> */}
					<Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
					{/* <!-- ===== Header End ===== --> */}

					{/* <!-- ===== Main Content Start ===== --> */}
					{/* <main>
						<div className="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-10">
							{children}
						</div>
					</main> */}
					<main className='max-h-screen flex flex-col flex-1 p-4 overflow-hidden md:p-6 2xl:p-10'>{children}</main>
					{/* <!-- ===== Main Content End ===== --> */}
				</div>
				{/* <!-- ===== Content Area End ===== --> */}
			</div>
			{/* <!-- ===== Page Wrapper End ===== --> */}
		</div>
	);
};

export default DefaultLayout;
