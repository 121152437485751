import { CapProv } from '../../../types/dataCapaian';

const CapProvData: CapProv[] = [
  {
    prov: 'Jawa Barat',
    target: 50,
    realisasi: 40,
    persenCapaian: 80,
    verifikasi: true,
  },
  {
    prov: 'Bali',
    target: 50,
    realisasi: 40,
    persenCapaian: 80,
    verifikasi: false,
  },
  {
    prov: 'Bengkulu',
    target: 50,
    realisasi: 40,
    persenCapaian: 80,
    verifikasi: true,
  },
  {
    prov: 'Sumatra Barat',
    target: 50,
    realisasi: 40,
    persenCapaian: 80,
    verifikasi: false,
  },
  {
    prov: 'Kalimantan Selatan',
    target: 50,
    realisasi: 40,
    persenCapaian: 80,
    verifikasi: true,
  },
];

const TableDataKumulatifAmProv = () => {
  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <h4 className="mb-6 text-xl font-semibold text-[#364878] dark:text-white">
        Air Minum Provinsi
      </h4>

      <div className="flex flex-col">
        <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-5">
          <div className="p-2.5 xl:p-5 ">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Nama Provinsi
            </h5>
          </div>
          <div className="p-2.5 hidden sm:block text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Target
            </h5>
            <p className="text-sm font-medium hidden md:flex">
              Kebutuhan Pelayanan Air Minum Curah(L/Detik)
            </p>
          </div>
          <div className="p-2.5 hidden sm:block text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Realisasi
            </h5>
            <p className="text-sm font-medium hidden md:flex">
              Ketersediaan Air Minum Curah(L/Detik)
            </p>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Persentase Capaian
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Verifikasi Bappeda Provinsi
            </h5>
          </div>
        </div>

        {CapProvData.map((provData, key) => (
          <div
            className={`grid grid-cols-3 sm:grid-cols-5 ${
              key === CapProvData.length - 1
                ? ''
                : 'border-b border-stroke dark:border-strokedark'
            }`}
            key={key}
          >
            <div className="flex items-center gap-3 p-2.5 xl:p-5">
              <p className="text-black dark:text-white sm:block">
                {provData.prov}
              </p>
            </div>

            <div className="hidden sm:flex items-center justify-center p-2.5 xl:p-5">
              <p className="text-black dark:text-white ">{provData.target}</p>
            </div>

            <div className="hidden sm:flex items-center justify-center p-2.5 xl:p-5">
              <p className="text-[#10B981] ">{provData.realisasi}%</p>
            </div>

            <div className="flex items-center justify-center p-2.5 xl:p-5">
              <p className="text-[#259AE6]">{provData.persenCapaian}%</p>
            </div>

            <div className="flex items-center rounded-10 justify-center p-1 ">
              <p
                className={`inline-flex rounded-lg w-full items-center justify-center bg-opacity-10 py-1 px-3 h-full text-sm font-medium uppercase ${
                  provData.verifikasi === true
                    ? 'bg-success text-success'
                    : 'bg-danger text-danger'
                }`}
              >
                {provData.verifikasi === true
                  ? 'terverifikasi'
                  : 'belum terverifikasi'}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableDataKumulatifAmProv;
