import TableDataKumulatifRBKab from '@/components/Tables/RumahBencana/TableDataKumulatifRBKab';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../../layout/DefaultLayout';

const CapaianRBKab = () => {
  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead="Rekapitulasi Capaian SPM Rumah Bencana"
        pageName="Rumah Bencana"
      />
      <TableDataKumulatifRBKab />
    </DefaultLayout>
  );
};
export default CapaianRBKab;
