import * as React from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';

import { cn } from '@/libs/utils';
import { Button } from '@/components/ui/Button';
import { Calendar } from '@/components/ui/calendar';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { useGetPeriode } from '@/hooks/usePeriode';
import { periode } from '@/types/summary';
import { useEffect, useState } from 'react';

interface DatePickerPeriodeProps extends React.HTMLAttributes<HTMLDivElement> {
	setPeriode_1: (value: string | undefined) => void;
	setPeriode_2: (value: string | undefined) => void;
}

export function DatePickerPeriode({
	className,
	setPeriode_1,
	setPeriode_2,
}: DatePickerPeriodeProps) {
	const [date, setDate] = useState<DateRange | undefined>(undefined);
	// const [periode_1, setPeriode_1] = useState<number | undefined>(undefined);
	// const [periode_2, setPeriode_2] = useState<number | undefined>(undefined);
	// console.log(date);
	const { getPeriode } = useGetPeriode();
	// Convert periods to a list of individual dates
	const listPeriode = getPeriode.flatMap((periode: periode) => ({
		tanggal: new Date(periode.tanggal),
		id: periode.id_periode_tanggal,
	}));
	// console.log(listPeriode);
	// console.log(periode_1);
	// console.log(periode_2);

	const setToMidnight = (date: Date) => {
		const newDate = new Date(date);
		newDate.setHours(0, 0, 0, 0);
		return newDate;
	};
	
	useEffect(() => {
		if (date) {
			const from = date.from ? setToMidnight(date.from) : undefined;
			const to = date.to ? setToMidnight(date.to) : undefined;

			const periode1 = from
				? listPeriode.find(
						(p: periode) =>
							setToMidnight(p.tanggal).getTime() === from.getTime(),
					)?.id ?? 0
				: undefined;
			const periode2 = to
				? listPeriode.find(
						(p: periode) => setToMidnight(p.tanggal).getTime() === to.getTime(),
					)?.id ?? 0
				: undefined;

			setPeriode_1(periode1);
			setPeriode_2(periode2);
		}
	}, [date, listPeriode]);

	const markedDates = listPeriode.map((dates: periode) => dates.tanggal);
	return (
		<div className={cn('grid gap-2', className)}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={'outline'}
						className={cn(
							'w-[300px] justify-start border border-slate-500 text-left font-normal',
							!date && 'text-muted-foreground',
						)}
					>
						<CalendarIcon className="tbody-text mr-2 h-4 w-4" />
						{date?.from ? (
							date.to ? (
								<>
									{format(date.from, 'LLL dd, y')} -{' '}
									{format(date.to, 'LLL dd, y')}
								</>
							) : (
								format(date.from, 'LLL dd, y')
							)
						) : (
							<span>Pick a date</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent
					className="w-auto border border-slate-500 p-0"
					align="start"
				>
					<Calendar
						initialFocus
						mode="range"
						defaultMonth={new Date()}
						selected={date}
						onSelect={setDate}
						numberOfMonths={2}
						modifiers={{
							highlighted: markedDates,
							// selectedHighlighted: (date) =>
							// 	markedDates.includes(date) && date === date,
						}}
						modifiersClassNames={{
							highlighted: 'bg-blue-200 text-accent',
							// selectedHighlighted: 'bg-green-200 text-accent',
						}}
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
}
