import { useRef } from 'react';
import JudulHeader from '../Header/JudulHeader';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../ui/table';
import { RootState } from '@/app/store';
import { useSelector } from 'react-redux';
import { usePrivilegeAirLimbah } from '@/hooks/privilege/usePrivilege';
import { MiniLoader } from '@/common/Loader';

interface TableBerandaKabProps {
	useGetDataHook: (
		kd_provinsi: number,
		tahun: number,
	) => { getJumlahDesaLapor: any[]; isLoading: boolean };
	headerTitle: string;
}
const TableBerandaKab: React.FC<TableBerandaKabProps> = ({
	useGetDataHook,
	headerTitle,
}) => {
	const { kd_provinsi_to_use } = usePrivilegeAirLimbah();
	const defKd_provinsi = 11;
	const kd_provinsi =
		kd_provinsi_to_use === 0 ? defKd_provinsi : kd_provinsi_to_use;
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const { getJumlahDesaLapor, isLoading } = useGetDataHook(
		kd_provinsi,
		Number(tahun),
	);

	const cardRef = useRef(null);

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader cardRef={cardRef} isLoading={false}>
					{headerTitle} Tahun {tahun}
				</JudulHeader>
				{isLoading ? (
					<MiniLoader />
				) : (
					<Table className="w-full table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead className="thead-text border-l">No</TableHead>
								<TableHead className="thead-text">Nama Provinsi</TableHead>
								<TableHead className="thead-text text-center">
									Nama Kabupaten / Kota
								</TableHead>
								<TableHead className="thead-text max-w-20 text-center">
									Jumlah Desa
								</TableHead>
								<TableHead className="thead-text max-w-30 text-center">
									Jumlah Desa dilaporkan
								</TableHead>
								<TableHead className="thead-text max-w-30 text-center">
									Presentase Desa dilaporkan
								</TableHead>
								<TableHead className="thead-text max-w-30 text-center">
									Persentase Capaian
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{!isLoading &&
								getJumlahDesaLapor.map((kabData: any, key: number) => {
									const persenDesaLapor =
										kabData.jumlah_desa !== 0
											? (kabData.jumlah_desa_melapor / kabData.jumlah_desa) *
												100
											: 0;
									return (
										<TableRow key={key}>
											<TableCell className="tbody-text border-b border-l border-r text-center">
												{key + 1}
											</TableCell>
											<TableCell className="tbody-text border-b border-r text-start">
												{kabData.nama_provinsi}
											</TableCell>
											<TableCell className="tbody-text border-b border-r text-start">
												{kabData.nama_kabkota}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{kabData.jumlah_desa}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												{kabData.jumlah_desa_melapor}
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												<p className="text-[#10B981]">
													{persenDesaLapor.toFixed(2)}%
												</p>
											</TableCell>
											<TableCell className="tbody-text border-b border-r">
												<p className="text-[#10B981]">
													{kabData.presentase_capaian.toFixed(2)}%
												</p>
											</TableCell>
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				)}
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};

export default TableBerandaKab;
