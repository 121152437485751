import React, { useState } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

interface KabKotaProps {
    getKabKota: any[];
    setKabKota: (kd_kabkota: string) => void;
}

const KabKotaSelectUI: React.FC<KabKotaProps> = ({
    getKabKota,
    setKabKota,
}) => {
    const [selectedValue, setSelectedValue] = useState("");

    return (
        <Select onValueChange={(value) => {
            if (value !== '') {
                const selectedKabKota = getKabKota.find(kabupaten => kabupaten.kd_kabkota === value);
                if(selectedKabKota){
                  console.log("kabupaten yang dipilih:", selectedKabKota.nama_kabkota);
                  setSelectedValue(selectedKabKota.nama_kabkota || "");
                  setKabKota(value);
                }
            }
        }}>
            <SelectTrigger>
                <SelectValue placeholder="Pilih Kabupaten/Kota">{selectedValue || "Pilih Kabupaten/Kota"}</SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getKabKota.map((kabupaten: any, index: any) => (
                    <SelectItem key={index} value={kabupaten.kd_kabkota}>{kabupaten.nama_kabkota}</SelectItem>
                ))}
            </SelectContent>
        </Select>

        
    );
};

export default KabKotaSelectUI;
