import { RootState } from "@/app/store";
import Breadcrumb from "@/components/Breadcrumbs/Breadcrumb";
import TableDataCapaianKumulatifRumahBencanaProv from "@/components/Tables/RumahBencana/DataCapaian/Kumulatif/TableDataCapaianKumulatifRumahBencanaProv";
import TableDataCapaianKumulatifRumahBencanaBappedaProv from "@/components/Tables/RumahBencana/DataCapaian/Kumulatif/TableDataCapaianKumulatifRumahBencanaBappedaProv";
import DefaultLayout from "@/layout/DefaultLayout";
import { useSelector } from "react-redux";
import { useState } from "react";
import FormDataCapaianKumulatifRumahBencanaProv from "@/components/Forms/RumahBencana/FormDataCapaianKumulatifRumahBencanaProv";
import EditFormDataCapaianKumulatifRumahBencanaProv from "@/components/Forms/RumahBencana/EditFormDataCapaianKumulatifRumahBencanaProv";

const DataCapaianKumulatifRBProv = () => {
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
  const wilayah = useSelector((state: RootState) => state.wilayah.value)

  const [showForm, setShowForm] = useState("Lihat Data")
  const [editableId, setEditableId] = useState(0)

  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead={`Data Capaian Kumulatif Rumah Bencana ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
        pageName={`Data Capaian Kumulatif Rumah Bencana ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
      />
      {showForm === "Lihat Data" ? (privilege === 4 || privilege === 8 || privilege === 10) ? wilayah === "provinsi" ? <TableDataCapaianKumulatifRumahBencanaProv setEditableId={setEditableId} setShowForm={setShowForm} /> : <TableDataCapaianKumulatifRumahBencanaBappedaProv /> : <></> : <></>}
      {showForm === "Lihat Data" ? privilege === 2 && <TableDataCapaianKumulatifRumahBencanaProv setEditableId={setEditableId} setShowForm={setShowForm} /> : <></>}
      {showForm === "Input Data" && <FormDataCapaianKumulatifRumahBencanaProv setShowForm={setShowForm} />}
      {showForm === "Edit Data" && <EditFormDataCapaianKumulatifRumahBencanaProv editableId={editableId} setShowForm={setShowForm} />}
    </DefaultLayout>
  );
};

export default DataCapaianKumulatifRBProv;