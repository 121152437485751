import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { API } from '../../libs/API';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

//Get
export function useGetRawanBencana(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['eksis-rawan-bencana', kd_provinsi, kd_kabkota, tahun, menu_id],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/eksis-rawan-bencana/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetIdentifikasiLahanPotensial(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['eksis-lahan-potensial', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/eksis-lahan-potensial/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: true,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetPengumpulanDataRumah(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['eksis-bencana-pengumpulan', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/eksis-bencana-pengumpulan/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetTargetPenyelesaian(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['eksis-bencana-target', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`eksis-bencana-target/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetDataCapaian(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['eksis-bencana', kd_provinsi, kd_kabkota, menu_id, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`eksis-bencana/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&menu_id=${menu_id}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetDataCapaianRumahBencanaById(id: number) {
	const {
		data: getEksisBencanaById,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['eksis-bencana-id', id],
		queryFn: async () => {
			const { data } = await API.get(`/eksis-bencana-id?id_eksis_bencana=${id}`);
			return data.data;
		},
	});
	return { getEksisBencanaById, refetch, isLoading };
}

export function useEditDataCapaianRumahBencanaById() {
	const {
		mutateAsync: editEksisBencanaById,
		isPending
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(`/edit-capaian-rumah-bencana?id_eksis_bencana=${data.id_eksis_bencana}`, data);
			return response;
		},
	});
	return { editEksisBencanaById, isPending };
}


export function useDeleteDataCapaianRumahBencana() {
	const {
		mutateAsync: deleteDataCapaianRumahBencana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (id: any) => {
			const response = await API.delete(
				`/del-capaian-rumah-bencana/?id_eksis_bencana=${id}`
			);
			return response;
		},
	});
	return { deleteDataCapaianRumahBencana, isPending, isSuccess };
}

export function useGetDataCapaianPenetapanBencana(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['eksis-bencana', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				// `eksis-bencana/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,

				`/eksis-bencana/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetDataCapaianRumahRelokasi({
	menu_id,
	kd_provinsi,
	kd_kabkota,
	tahun,
}) {
	return useInfiniteQuery({
		queryKey: ['eksis-relokasi', menu_id, kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`eksis-relokasi/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useGetJenisProgramRelokasi() {
	const {
		data: getJenisProgramRelokasi,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['jenis-program-relokasi'],
		queryFn: async () => {
			const { data } = await API.get(`/jenis-relokasi`);
			return data.data;
		},
	});
	return { getJenisProgramRelokasi, refetch, isLoading };
}

export function usePostDataCapaianRumahRelokasi() {
	const {
		mutateAsync: asyncPostDataCapaianRumahRelokasi,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/capaian-rumah-relokasi', data);
			return response;
		},
	});
	return { asyncPostDataCapaianRumahRelokasi, isPending, isSuccess };
}

//Post
export function usePostIdentifikasiPerumahan() {
	const {
		mutate: postIdentifikasiPerumahan,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/add-rawan-bencana', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil membuat menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postIdentifikasiPerumahan, isPending, isSuccess };
}

export function usePostIdentifikasiLahanPotensial() {
	const {
		mutate: postIdentifikasiLahanPotensial,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/add-lahan-potensial', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postIdentifikasiLahanPotensial, isPending, isSuccess };
}

export function usePostPengumpulanDataRumah() {
	const {
		mutate: postPengumpulanDataRumah,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/add-bencana-pengumpulan', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postPengumpulanDataRumah, isPending, isSuccess };
}

export function usePostTargetPenyelesaian() {
	const {
		mutate: postTargetPenyelesaian,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/add-bencana-target', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postTargetPenyelesaian, isPending, isSuccess };
}

export function usePostDataCapaian() {
	const {
		mutate: postDataCapaian,
		mutateAsync: asyncPostDataCapaian,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/capaian-rumah-bencana', data);
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postDataCapaian, asyncPostDataCapaian, isPending, isSuccess };
}

export function usePostPenetapanBencana() {
	const {
		mutate: postPenetapanBencana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/add-penetapan-bencana', data);
			return response;
		},
		onSuccess: () => {
			toast.success('Penetapan Bencana Sudah Disimpan');
		},
		onError: (error) => {
			console.log(error);
			toast.error('Gagal menyimpan Penetapan');
		},
	});
	return { postPenetapanBencana, isPending, isSuccess };
}


//Edit
export function useEditIdentifikasiPerumahanRawanBencana() {
	const {
		mutateAsync: editIdentifikasiPerumahanRawanBencana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/edit-rawan-bencana/?id_rawan_bencana=${data.id_rawan_bencana}`,
				data,
			);
			return response;
		},
	});
	return { editIdentifikasiPerumahanRawanBencana, isPending, isSuccess };
}

export function useEditIdentifikasiLahanPotensialBencana() {
	const {
		mutateAsync: editIdentifikasiLahanPotensialBencana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/edit-lahan-potensial/?id_lhn_potensial=${data.id_lhn_potensial}`,
				data,
			);
			return response;
		},
	});
	return { editIdentifikasiLahanPotensialBencana, isPending, isSuccess };
}
export function useEditIdentifikasiPengumpulanData() {
	const {
		mutateAsync: editIdentifikasiPengumpulanData,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/edit-bencana-pengumpulan/?id_bencana_pengumpulan=${data.id_bencana_pengumpulan}`,
				data,
			);
			return response;
		},
	});
	return { editIdentifikasiPengumpulanData, isPending, isSuccess };
}

export function useEditTargetPenyelesaian() {
	const {
		mutateAsync: editTargetPenyelesaian,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`/edit-bencana-target/?id_bencana_target=${data.id_bencana_target}`,
				data,
			);
			return response;
		},
	});
	return { editTargetPenyelesaian, isPending, isSuccess };
}

//Delete

export function useDeleteIdentifikasiPerumahanRawanBencana() {
	const {
		mutateAsync: deleteIdentifikasiPerumahanRawanBencana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-rawan-bencana/?id_rawan_bencana=${data.id_rawan_bencana}`,
				data,
			);
			return response;
		},
	});
	return { deleteIdentifikasiPerumahanRawanBencana, isPending, isSuccess };
}

export function useDeleteIdentifikasiLahanPotensial() {
	const {
		mutateAsync: deleteIdentifikasiLahanPotensial,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-lahan-potensial?id_lhn_potensial=${data.id_lhn_potensial}`,
				data,
			);
			return response;
		},
	});
	return { deleteIdentifikasiLahanPotensial, isPending, isSuccess };
}

export function useDeletePengumpulanDataRumahBencana() {
	const {
		mutateAsync: deletePengumpulanDataRumahBencana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-bencana-pengumpulan?id_bencana_pengumpulan=${data.id_bencana_pengumpulan}`,
				data,
			);
			return response;
		},
	});
	return { deletePengumpulanDataRumahBencana, isPending, isSuccess };
}

export function useDeleteTargetPenyelesaianRumahBencana() {
	const {
		mutateAsync: deleteTargetPenyelesaian,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-bencana-target/?id_bencana_target=${data.id_bencana_target}`,
				data,
			);
			return response;
		},
	});
	return { deleteTargetPenyelesaian, isPending, isSuccess };
}

export function useDeletePenetapanBencana() {
	const {
		mutateAsync: deletePenetapanBencana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-penetapan-bencana/?id_eksis_bencana=${data.id_eksis_bencana}`,
				data,
			);
			return response;
		},
	});
	return { deletePenetapanBencana, isPending, isSuccess };
}

export function usePostFile() {
	const {
		mutate: postFile,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const formData = new FormData();
			formData.append('tahun_upload', data.tahun_upload);
			formData.append('menu_id', data.menu_id);
			formData.append('kd_provinsi', data.kd_provinsi);
			formData.append('kd_kabkota', data.kd_kabkota);
			formData.append('jenis_up_id', data.jenis_up_id);
			formData.append('file_upload', data.file_upload);

			const response = await API.post('/upload-file', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			return response;
		},
		onSuccess: () => {
			toast.success('berhasil menambahkan data');
		},
		onError: (error) => {
			console.log(error);
			toast.error('gagal menambahkan data');
		},
	});
	return { postFile, isPending, isSuccess };
}

export function useGetFile(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
	jenis_up_id: number,
) {
	return useInfiniteQuery({
		queryKey: ['get-file-bnba', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				`/get-filename/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&menu_id=${menu_id}&tahun_upload=${tahun}&jenis_up_id=${jenis_up_id}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: true,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useDownloadFileBNBA(
	kd_provinsi: number,
	kd_kabkota: number,
	tahun: number,
	menu_id: number,
	user_id: number,
	fileName: string,
) {
	return useInfiniteQuery({
		queryKey: ['get-download-bnba', kd_provinsi, kd_kabkota, tahun],
		queryFn: async ({ pageParam = 1 }) => {
			const data = await API.get(
				// `/eksis-lahan-potensial/?menu_id=${menu_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,

				`download-bnba/${fileName}?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&menu_id=${menu_id}&tahun_up_bnba=${tahun}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: true,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}

export function useDeleteRencanaPemenuhanRumahBencana() {
	const {
		mutateAsync: deleteRencanaPemenuhanRumahBencana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (id: number) => {
			const response = await API.delete(
				`/del-capaian-rumah-bencana/?id_eksis_bencana=${id}`,
			);
			return response;
		},
	});
	return { deleteRencanaPemenuhanRumahBencana, isPending, isSuccess };
}


