import { useSelector } from 'react-redux';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';

import { RootState } from '@/app/store';
import { useGetDashKumulatifKab } from '@/hooks/airLimbah/useBeranda';
import { useEffect, useRef, useState } from 'react';
import { CompLoader, MiniLoader } from '@/common/Loader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import JudulHeader from '@/components/Header/JudulHeader';
import { FiAlertCircle, FiDownload } from 'react-icons/fi';
import { useGetProvinsi } from '@/hooks/wilayah/useLokasi';
import { Button } from '@/components/ui/Button';
import { utils, writeFile } from 'xlsx-js-style';
import ComboboxProvinsi from '@/components/ui/ComboboxProvinsi';
import { DashKumulatifALKab } from '@/types/airLimbah';

export const TableDataKumulatifAlKab = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const user = useSelector((state: RootState) => state.auth);
	const { getProvinsi, isLoading: loadProvinsi } = useGetProvinsi();
	const cardRef = useRef(null);
	const [kdProvByMenu, setKdProvByMenu] = useState(user.kd_provinsi);
	const [selectedValue, setSelectedValue] = useState(
		user.wilayah.nama_provinsi,
	);
	// console.log(user);
	// console.log(kdProvByMenu);
	// console.log(selectedValue);

	const { getDashKumulatifKab, refetch, isLoading } = useGetDashKumulatifKab(
		kdProvByMenu,
		Number(tahun),
	);

	useEffect(() => {
		selectedValue;
	}, [selectedValue, selectedValue, refetch]);

	const handleDownload = () => {
		const transformedData = getDashKumulatifKab.map((item: any, index: any) => [
			{
				v: index + 1,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.nama_kabkota,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.total_jumlah_rumah,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.total_aks_layak,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.total_aks_aman_spald_s,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.total_aks_aman_spald_t,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.real_tanpa_akses,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.real_aks_aman_spald_s,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.real_aks_aman_spald_t,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_akses_layak.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_aman_spald_s.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_aman_spald_t.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_tanpa_akses.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_capaian.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.keterangan}`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		]);

		const header = [
			{
				v: 'No',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Nama Kabupaten/Kota',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Jumlah Rumah',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const header1 = [
			{
				v: 'Total Akses Layak',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Akses Aman SPALD-S',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Akses Aman SPALD-T',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Tanpa Akses',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Akses Aman SPALD-S',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Akses Aman SPALD-T',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const header2 = [
			{
				v: 'Persentase Akses Layak',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Akses Aman SPALD-S',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Akses Aman SPALD-T',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Tanpa Akses',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Capaian',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Keterangan',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: `CAPAIAN AIR LIMBAH KABUPATEN/KOTA \n ${getDashKumulatifKab[0].nama_provinsi}`,
						t: 's',
						s: {
							font: { bold: true },
							alignment: {
								horizontal: 'center',
								vertical: 'center',
								wraptext: true,
							},
						},
					},
				],
			],
			{ origin: 'A1' },
		);
		utils.sheet_add_aoa(ws, [header], { origin: 'A2' });
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Target',
						t: 's',
						s: { alignment: { horizontal: 'center', vertical: 'center' } },
					},
				],
			],
			{ origin: 'D2' },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Realisasi',
						t: 's',
						s: { alignment: { horizontal: 'center', vertical: 'center' } },
					},
				],
			],
			{ origin: 'G2' },
		);
		utils.sheet_add_aoa(ws, [header1], { origin: 'D3' });
		utils.sheet_add_aoa(ws, [header2], { origin: 'J2' });
		utils.sheet_add_aoa(ws, transformedData, { origin: 'A4' });

		ws['!cols'] = [
			{ wpx: 50 },
			{ wpx: 145 },
			{ wpx: 130 },
			{ wpx: 110 },
			{ wpx: 165 },
			{ wpx: 165 },
			{ wpx: 115 },
			{ wpx: 165 },
			{ wpx: 165 },
			{ wpx: 150 },
			{ wpx: 200 },
			{ wpx: 200 },
			{ wpx: 150 },
			{ wpx: 125 },
			{ wpx: 125 },
		];
		ws['!merges'] = [
			// COLSPANS
			utils.decode_range('A1:O1'),
			utils.decode_range('D2:F2'),
			utils.decode_range('G2:I2'),

			// ROWSPANS
			utils.decode_range('A2:A3'),
			utils.decode_range('B2:B3'),
			utils.decode_range('C2:C3'),
			utils.decode_range('J2:J3'),
			utils.decode_range('K2:K3'),
			utils.decode_range('L2:L3'),
			utils.decode_range('M2:M3'),
			utils.decode_range('N2:N3'),
			utils.decode_range('O2:O3'),
		];
		ws['!rows'] = [{ hpx: 40 }];

		utils.book_append_sheet(wb, ws, 'Rekapitulasi Capaian Air Limbah');
		writeFile(
			wb,
			`Rekapitulasi Capaian SPM Air Limbah ${selectedValue}  - ${tahun}.xlsx`,
		);
	};

	if (isLoading || loadProvinsi) return <CompLoader />;

	return (
		<>
			<div className="flex justify-end p-4">
				<Button
					className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
					onClick={() => handleDownload()}
				>
					<FiDownload />
					Download Data
				</Button>
			</div>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				{isLoading ? (
					<MiniLoader />
				) : (
					<>
						<div className="flex items-center justify-start p-4 text-center">
							<div className="inline-flex items-center gap-2 text-center font-bold">
								<FiAlertCircle className="text-3xl text-red-500" /> Catatan :
								Silahkan Pilih Provinsi Dibawah Ini Untuk Melihat Data Dari
								Kabupaten Lain
							</div>
						</div>
						<ComboboxProvinsi
							kdProvByMenu={kdProvByMenu}
							setKdProvByMenu={setKdProvByMenu}
							selectedValue={selectedValue}
							setSelectedValue={setSelectedValue}
							loadProvinsi={loadProvinsi}
							getProvinsi={getProvinsi}
						/>
						<ScrollArea className="relative h-full p-4">
							<JudulHeader
								cardRef={cardRef}
								isLoading={isLoading}
								minusWidth={32}
							>
								Capaian Air Limbah Kabupaten/Kota
							</JudulHeader>
							<Table className="w-full min-w-[1800px] table-auto">
								<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
									<TableRow>
										<TableHead
											className="thead-text border-l p-2 text-center"
											rowSpan={2}
										>
											No
										</TableHead>
										<TableHead
											className="thead-text p-2 text-center"
											rowSpan={2}
										>
											Nama <br /> Kabupaten/Kota
										</TableHead>
										<TableHead
											className="thead-text p-2 text-center"
											rowSpan={2}
										>
											Total <br /> Jumlah Rumah
										</TableHead>
										<TableHead
											className="thead-text border-b-0 p-2 text-center"
											colSpan={3}
										>
											Target
										</TableHead>
										<TableHead
											className="thead-text border-b-0 p-2 text-center"
											colSpan={3}
										>
											Realisasi
										</TableHead>
										<TableHead
											className="thead-text p-2 text-center"
											rowSpan={2}
										>
											Persentase <br /> Akses Layak
										</TableHead>
										<TableHead
											className="thead-text p-2 text-center"
											rowSpan={2}
										>
											Persentase Akses <br /> Aman SPALD-S
										</TableHead>
										<TableHead
											className="thead-text p-2 text-center"
											rowSpan={2}
										>
											Persentase Akses <br /> Aman SPALD-T
										</TableHead>
										<TableHead
											className="thead-text p-2 text-center"
											rowSpan={2}
										>
											Persentase <br /> Tanpa Akses
										</TableHead>
										<TableHead
											className="thead-text p-2 text-center"
											rowSpan={2}
										>
											Persentase <br /> Capaian
										</TableHead>
										<TableHead
											className="thead-text p-2 text-center"
											rowSpan={2}
										>
											Keterangan
										</TableHead>
									</TableRow>
									<TableRow>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Akses Layak
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total Akses <br /> Aman SPALD-S
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total Akses <br /> Aman SPALD-T
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Tanpa Akses
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total Akses <br /> Aman SPALD-S
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total Akses <br /> Aman SPALD-T
										</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{getDashKumulatifKab.map(
										(item: DashKumulatifALKab, index: number) => {
											let rowClass = 'bg-white dark:bg-boxdark';
											if (item.keterangan === 'Tidak Melapor') {
												rowClass =
													'bg-red-600 bg-opacity-20 dark:bg-red-600 dark:bg-opacity-10';
											} else if (index % 2 === 0) {
												rowClass = 'bg-gray-2 dark:bg-meta-4';
											}
											return (
												<TableRow key={index} className={rowClass}>
													<TableCell className="tbody-text border-b border-l border-r">
														{index + 1}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.nama_kabkota ? item.nama_kabkota : '-'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.total_jumlah_rumah
															? item.total_jumlah_rumah.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.total_aks_layak
															? item.total_aks_layak.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.total_aks_aman_spald_s
															? item.total_aks_aman_spald_s.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.total_aks_aman_spald_t
															? item.total_aks_aman_spald_t.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_tanpa_akses
															? item.real_tanpa_akses.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_aks_aman_spald_s
															? item.real_aks_aman_spald_s.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_aks_aman_spald_t
															? item.real_aks_aman_spald_t.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_akses_layak
															? `${item.presentase_akses_layak.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_aman_spald_s
															? `${item.presentase_aman_spald_s.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_aman_spald_t
															? `${item.presentase_aman_spald_t.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_tanpa_akses
															? `${item.presentase_tanpa_akses.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_capaian
															? `${item.presentase_capaian.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.keterangan ? item.keterangan : '-'}
													</TableCell>
												</TableRow>
											);
										},
									)}
								</TableBody>
							</Table>
							<ScrollBar orientation="horizontal" />
						</ScrollArea>
					</>
				)}
			</div>
		</>
	);
};
