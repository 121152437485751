import { RootState } from "@/app/store";
import { CompLoader } from "@/common/Loader";
import JudulHeader from "@/components/Header/JudulHeader";
import { Button } from "@/components/ui/Button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useEditDataCapaianAL, useGetDataEksisAirLimbah, usePostDataCapaianAL } from "@/hooks/airLimbah/useDataCapaianAL";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiSave } from "react-icons/fi";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { toast } from "sonner";

type EditDataCapaianALProps = {
  sampleId?: number
  setShowForm?: any
}

const EditDataCapaianAL = ({ sampleId, setShowForm }: EditDataCapaianALProps) => {
  let rowCounter = 0;
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
  const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
  const privilege = useSelector((state: RootState) => state.auth.privilege_id);
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const queryClient = useQueryClient()

  const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
    (state: RootState) => state.location,
  );

  const [editableDataCapaian, setEditableDataCapaian] = useState<any>([])
  const [uploadDataLoading, setUploadDataLoading] = useState(false)
  const { asyncEditCapaianAL } = useEditDataCapaianAL()
  const cardRef = useRef(null)

  const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
  const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;

  const formatDataLokasi = () => {
    const newObj = (obj: any): any => {
      return obj.flatMap((item: any) => (
        item.data.map((item: any) => ({
          id_eksis_al: item.id_eksis_al,
          tgt_total: item.tgt_total,
          tgt_aks_layak: item.tgt_aks_layak,
          tgt_aks_aman_spald_s: item.tgt_aks_aman_spald_s,
          tgt_aks_aman_spald_t: item.tgt_aks_aman_spald_t,
          real_tnp_aks: item.real_tnp_aks,
          new_real_aks_layak: item.new_real_aks_layak,
          real_aks_aman_spald_s: item.real_aks_aman_spald_s,
          real_aks_aman_spald_t: item.real_aks_aman_spald_t,
          tahun_pelaporan: Number(tahun)
        }))
      ))
    };
    const data = newObj(editableDataCapaian)
    return data
  }

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useGetDataEksisAirLimbah({
    sample_id: sampleId,
    kd_provinsi: kd_provinsi_to_use,
    kd_kabkota: kd_kabkota_to_use,
    tahun: tahun,
  });

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (!isLoading) {
      setEditableDataCapaian(data?.pages)
    }
  }, [isLoading, isFetchingNextPage])

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const handleChange = (page: number, key: number, name: string, value: string) => {
    const data = [...editableDataCapaian]
    data[page].data[key][name] = Number(value)
    setEditableDataCapaian(data)
  }

  const handleSimpanData = async () => {
    setUploadDataLoading(true)
    try {
      const data = formatDataLokasi()
      const postPromises = data.map((data: any) => asyncEditCapaianAL(data))
      await Promise.all(postPromises)
      queryClient.invalidateQueries({
        queryKey: ['eksis-air-limbah', kd_provinsi, kd_kabkota, tahun, sampleId]
      })
      toast.success("Berhasil Update Data Capaian Air Limbah")
    } catch (error) {
      toast.error("Gagal Update Data Capaian Air Limbah")
    } finally {
      refetch()
      setUploadDataLoading(false)
      setShowForm("Pilih Data")
    }
  }

  if (uploadDataLoading) return <CompLoader />;

  return (
    <>
      <div className='flex justify-end pb-4'>
        <div className="flex gap-4">
          <Button
            className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
            onClick={() => setShowForm("Pilih Data")}
          >
            <FiArrowLeft />
            Kembali
          </Button>
          <Button
            className="inline-flex items-center justify-center rounded-lg gap-2 bg-green-500 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
            onClick={handleSimpanData}
          >
            <FiSave />
            Simpan Data
          </Button>
        </div>
      </div>
      <div ref={cardRef} className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark overflow-hidden flex flex-1 flex-col">
        <ScrollArea className='h-full p-4 relative'>
          <JudulHeader isLoading={isLoading} cardRef={cardRef} minusWidth={32}>
            EDIT DATA CAPAIAN <br />
            SPM PERKERJAAN UMUM <br />
            PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK TAHUN {Number(tahun)}
          </JudulHeader>
          <Table className="min-w-[1800px] w-full table-auto">
            <TableHeader className=" bg-gray-2 dark:bg-meta-4 sticky top-0">
              <TableRow>
                <TableHead rowSpan={3} className="border-l thead-text text-center">No</TableHead>
                <TableHead rowSpan={3} className="thead-text text-center">Kecamatan</TableHead>
                <TableHead rowSpan={3} className="thead-text text-center">Desa</TableHead>
                <TableHead colSpan={4} className="thead-text text-center border-b-0">Target</TableHead>
                <TableHead colSpan={4} className="thead-text text-center border-b-0">Realisasi</TableHead>
              </TableRow>
              <TableRow>
                <TableHead className='thead-text text-center border-b'>Total</TableHead>
                <TableHead className='thead-text text-center border-b'>Akses Layak</TableHead>
                <TableHead className='thead-text text-center border-b'>Akses Aman SPALD-S</TableHead>
                <TableHead className='thead-text text-center border-b'>Akses Aman SPALD-T</TableHead>
                <TableHead className='thead-text text-center border-b'>Akses Layak</TableHead>
                <TableHead className='thead-text text-center border-b'>Akses Aman SPALD-S</TableHead>
                <TableHead className='thead-text text-center border-b'>Akses Aman SPALD-T</TableHead>
                <TableHead className='thead-text text-center border-b'>Tanpa Akses</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {editableDataCapaian.map((page: any, i: any) => {
                return (
                  <React.Fragment key={i}>
                    {page.data.map((item: any, key: number) => {
                      rowCounter++;
                      return (
                        <TableRow key={key} className={
                          rowCounter % 2 === 0
                            ? 'bg-gray-2 dark:bg-meta-4'
                            : 'bg-white dark:bg-boxdark'
                        }>
                          <TableCell className='tbody-text border-l border-b border-r'>{rowCounter}</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.nama_kecamatan}</TableCell>
                          <TableCell className='tbody-text border-b border-r'>{item.nama_desa}</TableCell>
                          <TableCell className='tbody-text border-b border-r'><input type="number" value={String(item.tgt_total)} onChange={(e) => handleChange(i, key, "tgt_total", e.target.value)} className="w-full p-1 rounded-md placeholder:text-black border-[1px]" /></TableCell>
                          <TableCell className='tbody-text border-b border-r'><input type="number" value={String(item.tgt_aks_layak)} onChange={(e) => handleChange(i, key, "tgt_aks_layak", e.target.value)} className="w-full p-1 rounded-md placeholder:text-black border-[1px]" /></TableCell>
                          <TableCell className='tbody-text border-b border-r'><input type="number" value={String(item.tgt_aks_aman_spald_s)} onChange={(e) => handleChange(i, key, "tgt_aks_aman_spald_s", e.target.value)} className="w-full p-1 rounded-md placeholder:text-black border-[1px]" /></TableCell>
                          <TableCell className='tbody-text border-b border-r'><input type="number" value={String(item.tgt_aks_aman_spald_t)} onChange={(e) => handleChange(i, key, "tgt_aks_aman_spald_t", e.target.value)} className="w-full p-1 rounded-md placeholder:text-black border-[1px]" /></TableCell>
                          <TableCell className='tbody-text border-b border-r'><input type="number" value={String(item.new_real_aks_layak)} onChange={(e) => handleChange(i, key, "new_real_aks_layak", e.target.value)} className="w-full p-1 rounded-md placeholder:text-black border-[1px]" /></TableCell>
                          <TableCell className='tbody-text border-b border-r'><input type="number" value={String(item.real_aks_aman_spald_s)} onChange={(e) => handleChange(i, key, "real_aks_aman_spald_s", e.target.value)} className="w-full p-1 rounded-md placeholder:text-black border-[1px]" /></TableCell>
                          <TableCell className='tbody-text border-b border-r'><input type="number" value={String(item.real_aks_aman_spald_t)} onChange={(e) => handleChange(i, key, "real_aks_aman_spald_t", e.target.value)} className="w-full p-1 rounded-md placeholder:text-black border-[1px]" /></TableCell>
                          <TableCell className='tbody-text border-b border-r'><input type="number" value={String(item.real_tnp_aks)} onChange={(e) => handleChange(i, key, "real_tnp_aks", e.target.value)} className="w-full p-1 rounded-md placeholder:text-black border-[1px]" /></TableCell>
                        </TableRow>
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
          <div className="h-1" ref={ref}>
            {isFetchingNextPage &&
              <div className="flex justify-center items-center p-4">
                <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
              </div>
            }
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </>
  )
}

export default EditDataCapaianAL