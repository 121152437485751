import { API } from '../../libs/API';
import { useQuery } from '@tanstack/react-query';

export function useGetKecamatan(kd_provinsi: number, kd_kabupaten: number) {
	const {
		data: getKecamatan,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['kecamatan', kd_provinsi, kd_kabupaten],
		queryFn: async () => {
			const { data } = await API.get(
				`/get-kec/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabupaten}`,
			);
			return data.data;
		},
	});
	return { getKecamatan, refetch, isLoading };
}
