import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import TableDataKumulatifAmProv from '../../components/Tables/AirMinum/TableDataKumulatifAmProv';
import DefaultLayout from '../../layout/DefaultLayout';

const CapaianAmProv = () => {
  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead="Rekapitulasi Capaian SPM pekerjaan Umum"
        pageName="Data Kumulatif"
      />
      <div className="container">
        <TableDataKumulatifAmProv />
      </div>
    </DefaultLayout>
  );
};
export default CapaianAmProv;
