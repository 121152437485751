import React, { useState } from 'react';
import useColorMode from '../../hooks/useColorMode';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { toast } from 'sonner';
import { useResetPassword } from '@/hooks/forgotPassword/useResetPassword';
import { useNavigate } from 'react-router-dom';

const ResetPassword: React.FC = () => {
	const navigate = useNavigate();
	const [colorMode] = useColorMode();
	const isDark = colorMode === 'dark'; // Check if the current color mode is dark
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const { mutate } = useResetPassword();
	const togglePassword = () => {
		setShowPassword(!showPassword);
	};
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			toast.error('Passwords do not match');
			return;
		}
		// Submit form
		mutate(
			{ password: confirmPassword },
			{
				onSuccess: () => {
					navigate('/auth/login');
					toast.success('Password berhasil direset');
				},
				onError: (error: any) => {
					toast.error(error.message);
				},
			},
		);
	};
	return (
		<div
			className={`mx-auto flex h-screen flex-col content-center items-center justify-center bg-[#364878] ${
				isDark ? 'dark-mode-class' : 'light-mode-class'
			}`}
		>
			<div className="w-100 self-center rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
				<div className="flex flex-wrap items-center">
					<div className="w-full border-stroke dark:border-strokedark xl:border-l-2">
						<div className="w-full p-4 sm:p-5 xl:p-7">
							<h2 className="mb-0 text-2xl font-bold text-[#364878] dark:text-white sm:text-title-xl2">
								RESET PASSWORD
							</h2>
							<span className="mb-9 block font-medium dark:text-white">
								Silahkan masukkan password anda yang baru
							</span>

							<form onSubmit={handleSubmit}>
								<div className="mb-10">
									<label className="text-slate mb-2.5 block font-medium dark:text-white">
										Password :
									</label>
									<div className="relative">
										<input
											type={showPassword ? 'text' : 'password'}
											placeholder="Masukkan password baru anda"
											onChange={(e) => setPassword(e.target.value)}
											className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-[#3C6AE0] focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
										/>
										<span
											className="absolute right-4 top-5 cursor-pointer "
											onClick={togglePassword} // Toggle password visibility
										>
											{showPassword ? (
												<FiEye
													className="text-slate-400"
													// style={{ height: '20', width: '20' }}
												/>
											) : (
												<FiEyeOff
													className="text-slate-400"
													// style={{ height: '20', width: '20' }}
												/>
											)}
										</span>
									</div>
								</div>
								<div className="mb-10">
									<label className="text-slate mb-2.5 block font-medium dark:text-white">
										Confirm Password :
									</label>
									<div className="relative">
										<input
											type="password"
											placeholder="Konfirmasi password anda"
											onChange={(e) => setConfirmPassword(e.target.value)}
											className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-[#3C6AE0] focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
										/>
									</div>
								</div>
								<div className="mb-5 flex justify-center">
									<button
										type="submit"
										className="w-35 cursor-pointer rounded-lg border border-primary bg-[#3C6AE0] p-4 text-white transition hover:bg-opacity-90"
									>
										Kirim
									</button>
								</div>
							</form>
							<div className="mt-6 text-center dark:text-white">
								<br />
								<br />
								<p>Layanan Informasi</p>
								<a
									href="mailto:perkim.pfid@pu.go.id"
									className="font-bold text-[#364878] dark:text-white"
								>
									perkim.pfid@pu.go.id
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
