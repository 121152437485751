import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useColorMode from '../../hooks/useColorMode';
import { usePostEmailForOtp } from '@/hooks/forgotPassword/useSendOtp';
import { toast } from 'sonner';
import { setUsername } from '@/app/password/resetPasswordSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';

const ForgotPassword: React.FC = () => {
	const [colorMode] = useColorMode();
	const isDark = colorMode === 'dark'; // Check if the current color mode is dark
	const dispatch = useDispatch();
	const username = useSelector(
		(state: RootState) => state.resetPassword.username,
	);
	const mutation = usePostEmailForOtp();

	const navigate = useNavigate();

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// Handle the form submission here
		mutation.mutate(
			{ username },
			{
				onSuccess: () => {
					// console.log(data);
					toast.success('Email berhasil dikirim');
					navigate('/auth/cek-otp');
				},
				onError: (error: any) => {
					// Handle the error here
					if (error.message === 'Email not found for this username') {
						toast.error(
							'Tidak ada e-mail yang terdaftar untuk username ini hubungi admin',
						);
					} else {
						toast.error('Username tidak terdaftar');
					}
				},
			},
		);
	};
	return (
		<div
			className={`mx-auto flex h-screen flex-col content-center items-center justify-center bg-[#364878] ${
				isDark ? 'dark-mode-class' : 'light-mode-class'
			}`}
		>
			<div className="w-100 self-center rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
				<div className="flex flex-wrap items-center">
					<div className="w-full border-stroke dark:border-strokedark xl:border-l-2">
						<div className="w-full p-4 sm:p-5 xl:p-7">
							<h2 className="mb-0 text-2xl font-bold text-[#364878] dark:text-white sm:text-title-xl2">
								LUPA PASSWORD
							</h2>
							<span className="mb-9 block font-medium dark:text-white">
								Silahkan masukkan username anda yang sudah terdaftar di
								SICALMERS
							</span>

							<form onSubmit={handleSubmit}>
								<div className="mb-10">
									<label className="text-slate mb-2.5 block font-medium dark:text-white">
										Username :
									</label>
									<div className="relative">
										<input
											type="text"
											// autoComplete="username"
											placeholder="Masukkan username anda"
											value={username}
											className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
											onChange={(e) => dispatch(setUsername(e.target.value))}
										/>
									</div>
								</div>
								<div className="mb-3 text-center">
									link untuk reset password akan dikirimkan ke email anda
								</div>
								<div className="mb-5 flex justify-center">
									<button
										type="submit"
										className="w-35 cursor-pointer rounded-lg border border-primary bg-[#3C6AE0] p-4 text-white transition hover:bg-opacity-90"
									>
										Kirim
									</button>
								</div>
								<div className="mt-6 text-center dark:text-white">
									<br />
									<br />
									<p>Layanan Informasi</p>
									<a
										href="mailto:perkim.pfid@pu.go.id"
										className="font-bold text-[#364878] dark:text-white"
									>
										perkim.pfid@pu.go.id
									</a>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
