import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	useTransition,
} from 'react';
import JudulHeader from '../Header/JudulHeader';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../ui/table';
import { useGetAllUsers } from '@/hooks/useManageUsers';
import { CompLoader, MiniLoader } from '@/common/Loader';
import _ from 'lodash';
import { API } from '@/libs/API';
import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from '../ui/pagination';
import { ChevronFirst, ChevronLast } from 'lucide-react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';
import { Button } from '../ui/Button';
import { GrPowerReset } from 'react-icons/gr';

function generateQueryString(searchData: any) {
	const params = [];

	if (searchData.username) {
		params.push(`username=${searchData.username}`);
	}
	if (searchData.kabkota) {
		params.push(`nama_kabkota=${searchData.kabkota}`);
	}
	if (searchData.provinsi) {
		params.push(`nama_provinsi=${searchData.provinsi}`);
	}
	if (searchData.menu) {
		params.push(`nama_menu=${searchData.menu}`);
	}

	return params.join('&');
}

const TableUsers = () => {
	const cardRef = useRef(null);
	const paginationRef = useRef<any>(null);
	const [searchValues, setSearchValues] = useState({
		username: '',
		kabkota: '',
		provinsi: '',
		menu: '',
	});
	const [debouncedSearchValues, setDebouncedSearchValues] = useState({
		username: '',
		kabkota: '',
		provinsi: '',
		menu: '',
	});
	const [searchableUsers, setSearchableUsers] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [limit, setLimit] = useState(10);
	const [page, setPage] = useState(1);
	const { dataUsers, isLoading: loadingUsers } = useGetAllUsers(page, limit);
	const [isPending, startTransition] = useTransition();

	const limits = [10, 50, 100, 1000];

	const debouncedSearch = useCallback(
		_.debounce((values) => {
			setDebouncedSearchValues(values);
			setIsLoading(false);
		}, 750),
		[],
	);

	const searchUsers = async (searchData: any) => {
		const { data } = await API.get(
			`/search-user?${generateQueryString(searchData)}`,
		);
		startTransition(() => {
			setSearchableUsers(data.data);
		});
	};

	const handleFirstPage = () => {
		if (page !== 1) {
			setPage(1);
			if (paginationRef.current) {
				paginationRef.current.scrollLeft -= paginationRef.current.scrollWidth;
			}
		} else {
			return;
		}
	};

	const handlePreviousPage = () => {
		if (page !== 1) {
			setPage((prev) => Math.max(prev - 1, 1));
			if (paginationRef.current) {
				paginationRef.current.scrollLeft -= 40;
			}
		} else {
			return;
		}
	};

	const handleNextPage = () => {
		if (page !== Math.min(page + 1, Math.ceil(dataUsers?.totalData! / limit))) {
			setPage((prev) =>
				Math.min(prev + 1, Math.ceil(dataUsers?.totalData! / limit)),
			);
			if (paginationRef.current) {
				paginationRef.current.scrollLeft += 40;
			}
		} else {
			return;
		}
	};

	const handleLastPage = () => {
		if (page !== Math.ceil(dataUsers?.totalData! / limit)) {
			setPage(Math.ceil(dataUsers?.totalData! / limit));
			if (paginationRef.current) {
				paginationRef.current.scrollLeft += paginationRef.current.scrollWidth;
			}
		} else {
			return;
		}
	};

	const memoizedUsers = useMemo(() => {
		return (
			!loadingUsers &&
			searchableUsers.map((item: any, index: any) => (
				<TableRow
					key={index}
					className={
						index % 2 === 0
							? 'bg-gray-2 dark:bg-meta-4'
							: 'bg-white dark:bg-boxdark'
					}
				>
					<TableCell className="tbody-text border-b border-l border-r">
						{index + 1}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.id_user ? item.id_user : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.kd_provinsi ? item.kd_provinsi : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.nama_provinsi ? item.nama_provinsi : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.kd_kabkota ? item.kd_kabkota : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.nama_kabkota ? item.nama_kabkota : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.username ? item.username : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.nama_menu ? item.nama_menu : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.privilege ? item.privilege : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.fullname ? item.fullname : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.email ? item.email : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.no_hp ? item.no_hp : '-'}
					</TableCell>
					<TableCell className="tbody-text border-b border-r">
						{item.nama_opd ? item.nama_opd : '-'}
					</TableCell>
				</TableRow>
			))
		);
	}, [searchableUsers]);

	useEffect(() => {
		setIsLoading(true);
		debouncedSearch(searchValues);
	}, [searchValues, debouncedSearch]);

	useEffect(() => {
		if (!loadingUsers) {
			startTransition(() => {
				setSearchableUsers(dataUsers?.allUsers);
			});
		}
	}, [loadingUsers, dataUsers?.allUsers]);

	useEffect(() => {
		if (
			debouncedSearchValues.username ||
			debouncedSearchValues.kabkota ||
			debouncedSearchValues.provinsi ||
			debouncedSearchValues.menu
		) {
			searchUsers(debouncedSearchValues);
		} else {
			startTransition(() => {
				setSearchableUsers(dataUsers?.allUsers);
			});
		}
	}, [debouncedSearchValues, dataUsers?.allUsers]);

	if (loadingUsers) return <CompLoader />;

	return (
		<>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<div className="flex flex-row items-center justify-between p-4">
					<div className="flex flex-grow flex-row items-center gap-4">
						<div className="flex flex-col">
							<label className="mb-3 block font-medium text-black dark:text-white">
								Cari Berdasarkan Username
							</label>
							<input
								type="text"
								placeholder="Masukan Username"
								value={searchValues.username}
								onChange={(e) =>
									setSearchValues({ ...searchValues, username: e.target.value })
								}
								className="rounded-lg border-[1.5px] px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
						<div className="flex flex-col">
							<label className="mb-3 block font-medium text-black dark:text-white">
								Cari Berdasarkan Nama Kabupaten
							</label>
							<input
								type="text"
								placeholder="Masukan Nama Kabupaten"
								value={searchValues.kabkota}
								onChange={(e) =>
									setSearchValues({ ...searchValues, kabkota: e.target.value })
								}
								className="rounded-lg border-[1.5px] px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
						<div className="flex flex-col">
							<label className="mb-3 block font-medium text-black dark:text-white">
								Cari Berdasarkan Nama Provinsi
							</label>
							<input
								type="text"
								placeholder="Masukan Nama Provinsi"
								value={searchValues.provinsi}
								onChange={(e) =>
									setSearchValues({ ...searchValues, provinsi: e.target.value })
								}
								className="rounded-lg border-[1.5px] px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
						<div className="flex flex-col">
							<label className="mb-3 block font-medium text-black dark:text-white">
								Cari Berdasarkan Nama Menu
							</label>
							<input
								type="text"
								placeholder="Masukan Nama Menu"
								value={searchValues.menu}
								onChange={(e) =>
									setSearchValues({ ...searchValues, menu: e.target.value })
								}
								className="rounded-lg border-[1.5px] px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
						<div className="flex flex-col">
							<label className="mb-3 block font-medium text-black opacity-0 dark:text-white">
								Cari Berdasarkan Nama Menu
							</label>
							<Button
								className="inline-flex size-12 items-center justify-center rounded-lg bg-red-500 p-0 text-center font-medium text-white hover:bg-opacity-90"
								onClick={() =>
									setSearchValues({
										username: '',
										kabkota: '',
										provinsi: '',
										menu: '',
									})
								}
							>
								<GrPowerReset className="size-6" />
							</Button>
						</div>
					</div>
					<div className="w-[15%]">
						<label className="mb-3 block font-medium text-black dark:text-white">
							Limit Users
						</label>
						<Select
							value={String(limit)}
							onValueChange={(value) => setLimit(Number(value))}
							disabled={Object.values(debouncedSearchValues).some(
								(value) => value !== '',
							)}
						>
							<SelectTrigger className="bg-white font-bold">
								<SelectValue placeholder="Pilih Provinsi" className="font-bold">
									{String(limit)}
								</SelectValue>
							</SelectTrigger>
							<SelectContent className="z-99999 bg-white">
								{limits.map((item, index) => (
									<SelectItem
										key={index}
										value={String(item)}
										className="font-bold"
									>
										{item}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
					</div>
				</div>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader
						cardRef={cardRef}
						isLoading={loadingUsers}
						minusWidth={32}
					>
						Semua Users
					</JudulHeader>
					{isLoading || isPending ? (
						<MiniLoader />
					) : (
						<Table className="mt-4 w-full min-w-[1800px] table-auto">
							<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
								<TableRow>
									<TableHead className="thead-text border-l p-2 text-center">
										No
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										ID User
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Kode Provinsi
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Nama Provinsi
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Kode Kabupaten
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Nama Kabupaten
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Username
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Menu / Bidang
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Privilege
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Nama Lengkap
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Email
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										No HP
									</TableHead>
									<TableHead className="thead-text p-2 text-center">
										Nama OPD
									</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{searchableUsers && searchableUsers.length !== 0 ? (
									memoizedUsers
								) : (
									<TableRow>
										<TableCell
											className="tbody-text border-b border-l border-r"
											colSpan={13}
										>
											Tidak ada data
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					)}
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
				{Object.values(debouncedSearchValues).every(
					(value) => value === '',
				) && (
					<Pagination className="max-w-[800px] p-4 pb-4">
						<PaginationContent>
							<PaginationItem
								className={`${page === 1 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
							>
								<PaginationLink
									isActive={page === 1}
									size="default"
									className={`gap-1 pl-2.5 ${page === 1 ? 'opacity-50' : 'opacity-100'}`}
									onClick={handleFirstPage}
								>
									<ChevronFirst className="h-4 w-4" />
									<span>First</span>
								</PaginationLink>
							</PaginationItem>
							<PaginationItem
								className={`${page === 1 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'}`}
							>
								<PaginationPrevious onClick={handlePreviousPage} />
							</PaginationItem>
						</PaginationContent>
						<ScrollArea viewportRef={paginationRef}>
							<PaginationContent>
								{[
									...Array(Math.ceil(dataUsers?.totalData! / limit)).keys(),
								].map((item) => (
									<PaginationItem className="cursor-pointer" key={item}>
										<PaginationLink
											isActive={item + 1 === page}
											onClick={() => setPage(item + 1)}
										>
											{item + 1}
										</PaginationLink>
									</PaginationItem>
								))}
							</PaginationContent>
							<ScrollBar orientation="horizontal" />
						</ScrollArea>
						<PaginationContent>
							<PaginationItem
								className={`${page === Math.min(page + 1, Math.ceil(dataUsers?.totalData! / limit)) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-100'}`}
							>
								<PaginationNext onClick={handleNextPage} />
							</PaginationItem>
							<PaginationItem
								className={`${page === Math.ceil(dataUsers?.totalData! / limit) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
							>
								<PaginationLink
									isActive={page === Math.ceil(dataUsers?.totalData! / limit)}
									size="default"
									className={`gap-1 pl-2.5 ${page === Math.ceil(dataUsers?.totalData! / limit) ? 'opacity-50' : 'opacity-100'}`}
									onClick={handleLastPage}
								>
									<ChevronLast className="h-4 w-4" />
									<span>Last</span>
								</PaginationLink>
							</PaginationItem>
						</PaginationContent>
					</Pagination>
				)}
			</div>
		</>
	);
};

export default TableUsers;
