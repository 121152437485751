import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useGetDataEksisAMBySample } from '@/hooks/airMinum/useDataCapaianAM';
import { hitungTotalData } from '@/libs/utils';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle, FiDownload } from 'react-icons/fi';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { utils, writeFile } from 'xlsx-js-style';

const TableDataCapaianKumulatifAMBappedaProv = () => {
	const [sampleId, setSampleId] = useState(1);

	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const nama_provinsi = useSelector(
		(state: RootState) => state.auth.wilayah.nama_provinsi,
	);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const nama_kabkota = useSelector(
		(state: RootState) => state.auth.wilayah.nama_kabkota,
	);
	const kd_provinsi_dux = useSelector(
		(state: RootState) => state.location.kd_provinsi_dux,
	);
	const nama_provinsi_dux = useSelector(
		(state: RootState) => state.location.nama_provinsi,
	);
	const kd_kabkota_dux = useSelector(
		(state: RootState) => state.location.kd_kabkota_dux,
	);
	const nama_kabkota_dux = useSelector(
		(state: RootState) => state.location.nama_kabkota,
	);
	const tahun = useSelector((state: RootState) => state.tahun.value);

	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use =
		privilege === 10 || privilege === 4 || privilege === 8
			? kd_kabkota_dux
			: kd_kabkota;
	const nama_kabkota_to_use =
		privilege === 10 || privilege === 4 || privilege == 8
			? nama_kabkota_dux
			: nama_kabkota;
	const nama_provinsi_to_use =
		privilege === 10 ? nama_provinsi_dux : nama_provinsi;

	const [total, setTotal] = useState({
		jumlah_rumah: 0,
		terlayani_jp: 0,
		terlayani_bjp: 0,
		belum_terlayani: 0,
	});
	const [persentaseLayanan, setPersentaseLayanan] = useState({
		terlayani_jp: 0,
		terlayani_bjp: 0,
		terlayani_air_minum: 0,
		belum_terlayani: 0,
		belum_terlayani_air_minum: 0,
		gapPelayanan: 0,
		capaian: 0,
	});

	const handleSampleChange = (e: number) => {
		setSampleId(e);
	};

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetDataEksisAMBySample({
			sample_id: sampleId,
			kd_provinsi: kd_provinsi_to_use,
			kd_kabkota: kd_kabkota_to_use,
			tahun,
		});

	const cardRef = useRef(null);

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	const pages = data?.pages || [];

	const calculateTerlayaniAirMinum = (
		jp: number,
		bjp: number,
		totalRumah: number,
	) => {
		const total = ((jp + bjp) / totalRumah) * 100;
		return isNaN(total) ? 0 : total;
	};

	const calculateBelumTerlayaniAirMinum = (
		belumTerlayani: number,
		totalRumah: number,
	) => {
		const total = (belumTerlayani / totalRumah) * 100;
		return isNaN(total) ? 0 : total;
	};

	const handleDownload = () => {
		const allDataAM = pages.flatMap((page) => page.data);
		const transformedData = allDataAM.map((item, index) => [
			{
				v: index + 1,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.nama_kecamatan,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.nama_desa,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.jumlah_rumah,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.layak_jp,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.layak_bjp,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${calculateTerlayaniAirMinum(item.layak_jp, item.layak_bjp, item.jumlah_rumah).toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.belum_terlayani,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${calculateBelumTerlayaniAirMinum(item.belum_terlayani, item.jumlah_rumah).toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${calculateBelumTerlayaniAirMinum(item.belum_terlayani, item.jumlah_rumah).toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		]);

		const header = [
			{
				v: 'No',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Kecamatan',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Kelurahan/Desa',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Target',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Realisasi',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const header1 = [
			{
				v: 'Total',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Terlayani JP',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Terlayani BJP',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Terlayani Air Minum',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Belum Terlayani',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Belum Terlayani Air Minum',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const header2 = [
			{
				v: '(Rumah)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: '(Rumah)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: '(Rumah)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: '(%)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: '(Rumah)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: '(%)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const totalRow = [
			{
				v: total.jumlah_rumah,
				t: 'n',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: total.terlayani_jp,
				t: 'n',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: total.terlayani_bjp,
				t: 'n',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: '-',
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: total.belum_terlayani,
				t: 'n',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: '-',
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: '-',
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
		];

		const persentaseRow = [
			{
				v: '-',
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: `${persentaseLayanan.terlayani_jp}%`,
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: `${persentaseLayanan.terlayani_bjp}%`,
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: `${persentaseLayanan.terlayani_air_minum}%`,
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: `${persentaseLayanan.belum_terlayani}%`,
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: `${persentaseLayanan.belum_terlayani_air_minum}%`,
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: `${persentaseLayanan.gapPelayanan}%`,
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
		];

		const capaianRow = [
			{
				v: `${persentaseLayanan.capaian}%`,
				t: 's',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'PERHITUNGAN KEBUTUHAN',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A1' },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'SPM PERKERJAAN UMUM',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A2' },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: `PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI HARI TAHUN ${tahun}`,
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A3' },
		);
		utils.sheet_add_aoa(ws, [header], { origin: 'A4' });
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Gap Pelayanan',
						t: 's',
						s: { alignment: { horizontal: 'center', vertical: 'center' } },
					},
				],
			],
			{ origin: 'J4' },
		);
		utils.sheet_add_aoa(ws, [header1], { origin: 'D5' });
		utils.sheet_add_aoa(ws, [header2], { origin: 'D6' });
		utils.sheet_add_aoa(ws, transformedData, { origin: 'A7' });

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Total',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `A${transformedData.length + 7}` },
		);
		utils.sheet_add_aoa(ws, [totalRow], {
			origin: `D${transformedData.length + 7}`,
		});

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Persentase Layanan',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `A${transformedData.length + 8}` },
		);
		utils.sheet_add_aoa(ws, [persentaseRow], {
			origin: `D${transformedData.length + 8}`,
		});

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Capaian SPM',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `A${transformedData.length + 9}` },
		);
		utils.sheet_add_aoa(ws, [capaianRow], {
			origin: `D${transformedData.length + 9}`,
		});

		ws['!cols'] = [
			{ wpx: 50 },
			{ wpx: 125 },
			{ wpx: 138 },
			{ wpx: 58 },
			{ wpx: 76 },
			{ wpx: 84 },
			{ wpx: 127 },
			{ wpx: 101 },
			{ wpx: 168 },
			{ wpx: 95 },
		];
		ws['!merges'] = [
			// COLSPANS
			utils.decode_range('A1:J1'),
			utils.decode_range('A2:J2'),
			utils.decode_range('A3:J3'),
			utils.decode_range('E4:I4'),
			utils.decode_range(
				`A${transformedData.length + 7}:C${transformedData.length + 7}`,
			),
			utils.decode_range(
				`A${transformedData.length + 8}:C${transformedData.length + 8}`,
			),
			utils.decode_range(
				`A${transformedData.length + 9}:C${transformedData.length + 9}`,
			),
			utils.decode_range(
				`D${transformedData.length + 9}:J${transformedData.length + 9}`,
			),

			// ROWSPANS
			utils.decode_range('A4:A6'),
			utils.decode_range('B4:B6'),
			utils.decode_range('C4:C6'),
			utils.decode_range('J4:J6'),
		];
		utils.book_append_sheet(wb, ws, 'Kumulatif Air Minum');
		writeFile(
			wb,
			`Data Capaian Kumulatif Air Minum KabKota ${nama_provinsi_to_use} - ${nama_kabkota_to_use} - ${tahun}.xlsx`,
		);
	};

	useEffect(() => {
		if (!isLoading) {
			if (!hasNextPage) {
				const totalJumlahRumah = hitungTotalData(pages, 'jumlah_rumah');
				const totalTerlayaniJp = hitungTotalData(pages, 'layak_jp');
				const totalTerlayaniBjp = hitungTotalData(pages, 'layak_bjp');
				const totalBelumTerlayani = hitungTotalData(pages, 'belum_terlayani');

				const persentaseLayananTerlayaniJp =
					(totalTerlayaniJp / totalJumlahRumah) * 100;
				const persentaseLayananTerlayaniBjp =
					(totalTerlayaniBjp / totalJumlahRumah) * 100;
				const persentaseLayananTerlayaniAirMinum =
					persentaseLayananTerlayaniJp + persentaseLayananTerlayaniBjp;
				const persentaseBelumTerlayani =
					(totalBelumTerlayani / totalJumlahRumah) * 100;
				const persentaseCapaian =
					persentaseLayananTerlayaniJp + persentaseLayananTerlayaniBjp;

				setTotal((prevState: any) => ({
					...prevState,
					jumlah_rumah: totalJumlahRumah,
					terlayani_jp: totalTerlayaniJp,
					terlayani_bjp: totalTerlayaniBjp,
					belum_terlayani: totalBelumTerlayani,
				}));

				setPersentaseLayanan((prevState: any) => ({
					...prevState,
					terlayani_jp: persentaseLayananTerlayaniJp.toFixed(2),
					terlayani_bjp: persentaseLayananTerlayaniBjp.toFixed(2),
					terlayani_air_minum: persentaseLayananTerlayaniAirMinum.toFixed(2),
					belum_terlayani: persentaseBelumTerlayani.toFixed(2),
					belum_terlayani_air_minum: persentaseBelumTerlayani.toFixed(2),
					gapPelayanan: persentaseBelumTerlayani.toFixed(2),
					capaian: persentaseCapaian.toFixed(2),
				}));
			}
		}
	}, [isLoading, hasNextPage, tahun, sampleId]);

	let rowCounter = 0;

	if (isLoading) return <CompLoader />;

	return (
		<>
			<div className="flex justify-between pb-4">
				<div className="sticky top-0 flex items-center justify-start py-4 text-center">
					<div className="inline-flex items-center gap-2 text-center font-bold">
						<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
						di bawah ini bisa didownload, scroll terlebih dahulu sampai melihat
						kolom Total
					</div>
				</div>
				<div className="flex gap-4">
					{!hasNextPage && (
						<Button
							className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
							onClick={() => handleDownload()}
						>
							<FiDownload />
							Download Data
						</Button>
					)}
				</div>
			</div>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 dark:bg-graydark sm:grid-cols-3">
					<div
						className={`py-4 text-center ${
							sampleId === 1 ? 'bg-[#fab23d]' : 'bg-[#364878] dark:bg-graydark'
						} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark`}
						onClick={() => handleSampleChange(1)}
					>
						Primer BNBA
					</div>
					<div
						className={`py-4 text-center ${
							sampleId === 2 ? 'bg-[#fab23d]' : 'bg-[#364878] dark:bg-graydark'
						} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark`}
						onClick={() => handleSampleChange(2)}
					>
						Primer Sampling
					</div>
					<div
						className={`py-4 text-center ${
							sampleId === 3 ? 'bg-[#fab23d]' : 'bg-[#364878] dark:bg-graydark'
						} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark`}
						onClick={() => handleSampleChange(3)}
					>
						Sekunder
					</div>
				</div>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader isLoading={isLoading} cardRef={cardRef}>
						PENGHITUNGAN KEBUTUHAN <br />
						SPM PERKERJAAN UMUM <br />
						PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI HARI TAHUN{' '}
						{Number(tahun)}
					</JudulHeader>
					<Table className="w-full table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									rowSpan={3}
									className="thead-text border-l text-center"
								>
									No
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Kecamatan
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Kelurahan / Desa
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Target
								</TableHead>
								<TableHead
									colSpan={5}
									className="thead-text border-b-0 text-center"
								>
									Realisasi
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Gap Pelayanan
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-b-0 text-center">
									Total
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Terlayani JP
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Terlayani BJP
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Terlayani Air Minum
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Belum Terlayani
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Belum Terlayani Air Minum
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">(%)</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">(%)</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{pages.map((page, i) => {
								return (
									<React.Fragment key={i}>
										{page.data.map((eksisAmData: any, key: number) => {
											rowCounter++;
											const terlayaniAirMinum = calculateTerlayaniAirMinum(
												eksisAmData.layak_jp,
												eksisAmData.layak_bjp,
												eksisAmData.jumlah_rumah,
											);
											const belumTerlayaniAirMinum =
												calculateBelumTerlayaniAirMinum(
													eksisAmData.belum_terlayani,
													eksisAmData.jumlah_rumah,
												);
											return (
												<TableRow
													key={key}
													className={
														rowCounter % 2 === 0
															? 'bg-gray-2 dark:bg-meta-4'
															: 'bg-white dark:bg-boxdark'
													}
												>
													<TableCell className="tbody-text border-b border-l border-r">
														{rowCounter}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{eksisAmData.nama_kecamatan}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{eksisAmData.nama_desa}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{eksisAmData.jumlah_rumah}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{eksisAmData.layak_jp}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{eksisAmData.layak_bjp}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{terlayaniAirMinum.toFixed(2)}%
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{eksisAmData.belum_terlayani}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{belumTerlayaniAirMinum.toFixed(2)}%
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{belumTerlayaniAirMinum.toFixed(2)}%
													</TableCell>
												</TableRow>
											);
										})}
									</React.Fragment>
								);
							})}
							{!hasNextPage && (
								<>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={3}
										>
											Total
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{total.jumlah_rumah}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{total.terlayani_jp}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{total.terlayani_bjp}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											-
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{total.belum_terlayani}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											-
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											-
										</TableCell>
									</TableRow>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={3}
										>
											Persentase Layanan
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											-
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{isNaN(persentaseLayanan.terlayani_jp)
												? 0
												: persentaseLayanan.terlayani_jp}
											%
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{isNaN(persentaseLayanan.terlayani_bjp)
												? 0
												: persentaseLayanan.terlayani_bjp}
											%
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{isNaN(persentaseLayanan.terlayani_air_minum)
												? 0
												: persentaseLayanan.terlayani_air_minum}
											%
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{isNaN(persentaseLayanan.belum_terlayani)
												? 0
												: persentaseLayanan.belum_terlayani}
											%
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{isNaN(persentaseLayanan.belum_terlayani_air_minum)
												? 0
												: persentaseLayanan.belum_terlayani_air_minum}
											%
										</TableCell>
										<TableCell className="tfooter-text border-b border-r">
											{isNaN(persentaseLayanan.gapPelayanan)
												? 0
												: persentaseLayanan.gapPelayanan}
											%
										</TableCell>
									</TableRow>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={3}
										>
											Capaian SPM
										</TableCell>
										<TableCell
											className="tfooter-text border-b border-r"
											colSpan={7}
										>
											{isNaN(persentaseLayanan.capaian)
												? 0
												: persentaseLayanan.capaian}
											%
										</TableCell>
									</TableRow>
								</>
							)}
						</TableBody>
					</Table>
					<div className="h-1" ref={ref}>
						{isFetchingNextPage && (
							<div className="flex items-center justify-center p-4">
								<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
							</div>
						)}
					</div>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};

export default TableDataCapaianKumulatifAMBappedaProv;
