import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useLogin } from '@/hooks/useLogin';
import { Loader } from '@/common/Loader';

interface AutoLoginProps {
	onAutoLoginDone: () => void;
}
const AutoLogin: React.FC<AutoLoginProps> = ({ onAutoLoginDone }) => {
	const { username: paramUsername, password: paramPassword } = useParams<{
		username: string;
		password: string;
	}>();
	const [username, setUsername] = useState<string | null>(null);
	const [password, setPassword] = useState<string | null>(null);
	const { userLogin, isSuccess } = useLogin();
	const navigate = useNavigate();

	//fungsi untuk mendekripsi username
	const decryptUsername = (encryptedUsername: string): string => {
		let decusername = '';
		for (let i = 0; i < encryptedUsername.length; i++) {
			if (i % 2 === 0) {
				decusername += encryptedUsername[i];
			}
		}
		return decusername;
	};

	// Fungsi untuk mendekripsi password
	const decryptPassword = (encryptedPassword: string): string => {
		let decpassword = '';
		for (let i = 0; i < encryptedPassword.length; i++) {
			if (i % 2 === 0) {
				decpassword += encryptedPassword[i];
			}
		}
		return decpassword;
	};

	useEffect(() => {
		if (paramUsername && paramPassword) {
			setUsername(decryptUsername(paramUsername));
			setPassword(decryptPassword(paramPassword));
		}
	}, [paramUsername, paramPassword]);

	useEffect(() => {
		if (username && password) {
			// console.log('Attempting login with:', { username, password });
			userLogin({ username, password });
		}
	}, [username, password, userLogin]);

	useEffect(() => {
		if (isSuccess) {
			console.log('Login successful');
			navigate('/'); // Redirect ke halaman utama atau halaman lain setelah login
		}
	}, [isSuccess, navigate, onAutoLoginDone]);

	return (
		<>
			<Loader />
		</>
	);
};

export default AutoLogin;
