import React, { useEffect, useState } from 'react';
const Loader = () => {
	return (
		<div className="flex h-screen items-center justify-center bg-white">
			<div className="h-32 w-32 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
		</div>
	);
};

const CompLoader: React.FC = () => {
	const [dots, setDots] = useState('');

	useEffect(() => {
		const interval = setInterval(() => {
			setDots((prev) => (prev.length >= 3 ? '' : prev + '.'));
		}, 500);

		return () => {
			clearInterval(interval);
		};
	}, []);
	return (
		<div className="flex items-center justify-center pt-60">
			<div className="flex items-center justify-center gap-2">
				<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent"></div>
				<span className="text-4xl font-bold italic text-[#364878] dark:text-white">
					Loading
				</span>
				<span className="w-12 text-left text-4xl font-bold italic text-[#364878] dark:text-white">
					{dots}
				</span>
			</div>
		</div>
	);
};

const MiniLoader: React.FC = () => {
	const [dots, setDots] = useState('');

	useEffect(() => {
		const interval = setInterval(() => {
			setDots((prev) => (prev.length >= 3 ? '' : prev + '.'));
		}, 500);

		return () => {
			clearInterval(interval);
		};
	}, []);
	return (
		<div className="flex h-64 w-full items-center justify-center">
			<div className="flex items-center justify-center gap-2">
				<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent"></div>
				<span className="text-4xl font-bold italic text-[#364878] dark:text-white">
					Loading
				</span>
				<span className="w-12 text-left text-4xl font-bold italic text-[#364878] dark:text-white">
					{dots}
				</span>
			</div>
		</div>
	);
};

export { Loader, CompLoader, MiniLoader };
