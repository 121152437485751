import { useMutation } from '@tanstack/react-query';
import { API } from '../libs/API'; 
import { toast } from 'sonner';
import { AxiosError } from 'axios';

export function useChangePassword() {
    const {
        mutateAsync: changePassword,
        isPending,
        isSuccess,
    } = useMutation({
        mutationFn: async (data: any) => {
            const response = await API.put(
                `/update-password-in-login/`,
                data,
            );
            return response.data; 
        },
        onSuccess: (data) => {
            toast.success(data.message); 
        },
        onError: (error: AxiosError<any>) => {
            if (error.response) {
                const { data } = error.response;
                toast.error(data.message);
            } else {
                toast.error("Gagal mengganti password");
            }
            console.log(error);
        },
    });

    return { changePassword, isPending, isSuccess };
}
