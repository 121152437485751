import Breadcrumb from "@/components/Breadcrumbs/Breadcrumb"
import BikinUser from "@/components/Forms/BikinUser"
import TableUsers from "@/components/Tables/TableUsers"
import { Button } from "@/components/ui/Button"
import DefaultLayout from "@/layout/DefaultLayout"
import { User } from "lucide-react"
import { useState } from "react"
import { FiArrowLeft } from "react-icons/fi"

const ManageUsers = () => {
  const [showForm, setShowForm] = useState<string>("Lihat User")
  return (
    <DefaultLayout>
      <Breadcrumb pageHead="Manage Users" pageName="Manage Users" />
      <div className='flex justify-end pb-4'>
        <div className="flex gap-4">
          {showForm !== "Lihat User" &&
            <Button
              className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
              onClick={() => setShowForm("Lihat User")}
            >
              <FiArrowLeft />
              Kembali
            </Button>
          }
          {showForm !== "Bikin User" &&
            <Button
              className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
              onClick={() => setShowForm("Bikin User")}
            >
              <User />
              Bikin User
            </Button>
          }
        </div>
      </div>
      {showForm === "Bikin User" && <BikinUser />}
      {showForm === "Lihat User" && <TableUsers />}
    </DefaultLayout>
  )
}

export default ManageUsers