import { createSlice } from '@reduxjs/toolkit';
import { SpamJpData } from '../../types/airMinum';

const initialState: { data: SpamJpData[] | null } = {
	data: null,
};

const spamjpSlice = createSlice({
	name: 'spamData',
	initialState,
	reducers: {
		setSpamData: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setSpamData } = spamjpSlice.actions;

export default spamjpSlice.reducer;
