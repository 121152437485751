import React, { useState } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

interface KecamatanSelectProps {
    getKecamatan: any[];
    setKd_kec: (kd_kec: string) => void;
}

const KecamatanSelectUI: React.FC<KecamatanSelectProps> = ({
    getKecamatan,
    setKd_kec,
}) => {
    const [selectedValue, setSelectedValue] = useState("");

    return (
        <Select onValueChange={(value) => {
            if (value !== '') {
                const selectedKecamatan = getKecamatan.find(kecamatan => kecamatan.kd_kec === value);
                if(selectedKecamatan){
                  setSelectedValue(selectedKecamatan.nama_kecamatan || "");
                  setKd_kec(value);
                }
            }
        }}>
            <SelectTrigger>
                <SelectValue placeholder="Pilih Kecamatan">{selectedValue || "Pilih Kecamatan"}</SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getKecamatan ?getKecamatan.map((kecamatan: any, index: any) => (
                    <SelectItem key={index} value={kecamatan.kd_kec}>
                        {kecamatan.nama_kecamatan}
                    </SelectItem>
                )) : null}
            </SelectContent>
        </Select>
    );
};

export default KecamatanSelectUI;
