// ConfirmationDialog.tsx
import React from 'react';
import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogCancel,
	AlertDialogAction,
} from '@/components/ui/alert-dialog'; // replace 'your-library' with the actual library you're using

type ConfirmationDialogProps = {
	isDialogOpen: boolean;
	setIsDialogOpen: (isOpen: boolean) => void;
	confirmAction: () => void;
	title: string;
	description: string;
	confirmButtonColor: string;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	isDialogOpen,
	setIsDialogOpen,
	confirmAction,
	title,
	description,
	confirmButtonColor,
}) => {
	const descriptionLines = description.split('\n');

	return (
		<AlertDialog open={isDialogOpen}>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{title}</AlertDialogTitle>
					{descriptionLines.map((line, index) => (
						<AlertDialogDescription key={index}>{line}</AlertDialogDescription>
					))}
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel onClick={() => setIsDialogOpen(false)}>
						Cancel
					</AlertDialogCancel>
					<AlertDialogAction
						className={`${confirmButtonColor} `}
						onClick={confirmAction}
					>
						Confirm
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export default ConfirmationDialog;
