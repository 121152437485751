import { useSelector } from 'react-redux';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { RootState } from '@/app/store';
import { useGetDashKumulatifKab } from '@/hooks/airMinum/useBeranda';
import { useRef, useState } from 'react';
import { CompLoader, MiniLoader } from '@/common/Loader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import JudulHeader from '@/components/Header/JudulHeader';
import { FiAlertCircle, FiDownload } from 'react-icons/fi';
import { useGetProvinsi } from '@/hooks/wilayah/useLokasi';
import { Button } from '@/components/ui/Button';
import { utils, writeFile } from 'xlsx-js-style';
import ComboboxProvinsi from '@/components/ui/ComboboxProvinsi';
import { DashKumulatifAMKab } from '@/types/airMinum';

const TableDataKumulatifAmKab = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const user = useSelector((state: RootState) => state.auth);
	const cardRef = useRef(null);
	const { getProvinsi, isLoading: loadProvinsi } = useGetProvinsi();
	const [kdProvByMenu, setKdProvByMenu] = useState(user.kd_provinsi);
	const [selectedValue, setSelectedValue] = useState(
		user.wilayah.nama_provinsi,
	);

	const { getDashKumulatifKab, isLoading } = useGetDashKumulatifKab(
		kdProvByMenu,
		Number(tahun),
	);
	// console.log(user);

	const handleDownload = () => {
		const transformedData = getDashKumulatifKab.map((item: any, index: any) => [
			{
				v: index + 1,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.nama_kabkota,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.total_jumlah_rumah,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.total_layak_jp,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.total_layak_bjp,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.total_belum_terlayani,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_terlayani_jp.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_terlayani_bjp.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_belum_terlayani.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.presentase_capaian.toFixed(2)}%`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: `${item.keterangan}`,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		]);

		const header = [
			{
				v: 'No',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Nama Kabupaten/Kota',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Jumlah Rumah',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Layak JP',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Layak BJP',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Layak Belum Terlayani',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Terlayani JP',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Terlayani BJP',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Belum Terlayani',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Capaian',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Keterangan',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: `CAPAIAN AIR MINUM KABUPATEN/KOTA \n ${getDashKumulatifKab[0].nama_provinsi}`,
						t: 's',
						s: {
							font: { bold: true },
							alignment: {
								horizontal: 'center',
								vertical: 'center',
								wrapText: true,
							},
						},
					},
				],
			],
			{ origin: 'A1' },
		);
		utils.sheet_add_aoa(ws, [header], { origin: 'A2' });
		utils.sheet_add_aoa(ws, transformedData, { origin: 'A3' });

		ws['!cols'] = [
			{ wpx: 50 },
			{ wpx: 145 },
			{ wpx: 130 },
			{ wpx: 90 },
			{ wpx: 100 },
			{ wpx: 170 },
			{ wpx: 145 },
			{ wpx: 150 },
			{ wpx: 170 },
			{ wpx: 125 },
			{ wpx: 125 },
		];
		ws['!merges'] = [
			// COLSPANS
			utils.decode_range('A1:K1'),
		];
		ws['!rows'] = [{ hpx: 40 }];

		utils.book_append_sheet(wb, ws, 'Rekapitulasi Capaian Air Minum');
		writeFile(
			wb,
			`Rekapitulasi Capaian SPM Air Minum ${selectedValue}  - ${tahun}.xlsx`,
		);
	};

	if (isLoading || loadProvinsi) return <CompLoader />;

	return (
		<>
			<div className="flex justify-end p-4">
				<Button
					className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
					onClick={() => handleDownload()}
				>
					<FiDownload />
					Download Data
				</Button>
			</div>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				{isLoading ? (
					<MiniLoader />
				) : (
					<>
						<div className="flex items-center justify-start p-4 text-center">
							<div className="inline-flex items-center gap-2 text-center font-bold">
								<FiAlertCircle className="text-3xl text-red-500" /> Catatan :
								Silahkan Pilih Provinsi Dibawah Ini Untuk Melihat Data Dari
								Kabupaten Lain
							</div>
						</div>
						<ComboboxProvinsi
							kdProvByMenu={kdProvByMenu}
							setKdProvByMenu={setKdProvByMenu}
							selectedValue={selectedValue}
							setSelectedValue={setSelectedValue}
							loadProvinsi={loadProvinsi}
							getProvinsi={getProvinsi}
						/>
						<ScrollArea className="relative h-full p-4">
							<JudulHeader
								cardRef={cardRef}
								isLoading={isLoading}
								minusWidth={32}
							>
								Capaian Air Minum Kabupaten/Kota
							</JudulHeader>
							<Table className="mt-4 w-full min-w-[1800px] table-auto">
								<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
									<TableRow>
										<TableHead className="thead-text border-l p-2 text-center">
											No
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Nama <br /> Kabupaten/Kota
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Jumlah Rumah
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Layak JP
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Layak BJP
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Belum Terlayani
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Persentase <br /> Terlayani JP
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Persentase <br /> Terlayani BJP
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Persentase <br /> Belum Terlayani
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Persentase <br /> Capaian
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Keterangan
										</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{getDashKumulatifKab.map(
										(item: DashKumulatifAMKab, index: number) => {
											let rowClass = 'bg-white dark:bg-boxdark';
											if (item.keterangan === 'Tidak Melapor') {
												rowClass =
													'bg-red-600 bg-opacity-20 dark:bg-red-600 dark:bg-opacity-10';
											} else if (index % 2 === 0) {
												rowClass = 'bg-gray-2 dark:bg-meta-4';
											}
											return (
												<TableRow key={index} className={rowClass}>
													<TableCell className="tbody-text border-b border-l border-r">
														{index + 1}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.nama_kabkota ? item.nama_kabkota : '-'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.total_jumlah_rumah
															? item.total_jumlah_rumah.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.total_layak_jp
															? item.total_layak_jp.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.total_layak_bjp
															? item.total_layak_bjp.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.total_belum_terlayani
															? item.total_belum_terlayani.toLocaleString()
															: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_terlayani_jp
															? `${item.presentase_terlayani_jp.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_terlayani_bjp
															? `${item.presentase_terlayani_bjp.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_belum_terlayani
															? `${item.presentase_belum_terlayani.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.presentase_capaian
															? `${item.presentase_capaian.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.keterangan ? item.keterangan : '-'}
													</TableCell>
												</TableRow>
											);
										},
									)}
								</TableBody>
							</Table>
							<ScrollBar orientation="horizontal" />
						</ScrollArea>
					</>
				)}
			</div>
		</>
	);
};

export default TableDataKumulatifAmKab;
