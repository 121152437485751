import { createSlice } from '@reduxjs/toolkit';
type Menu = {
	id_menu: number;
	kd_menu: number;
	jenis_menu: string;
	nama_menu: string;
};

type Privilege = {
	id_privilege: number;
	kd_privilege: number;
	nama_privilege: string;
};

type wilayah = {
	nama_provinsi: string;
	nama_kabkota: string;
};

type CheckUsername = {
	id_user: number;
	username: string;
	unit_id: number;
	bidang_id: number;
	menu_id: number;
	kd_provinsi: number;
	kd_kabkota: number;
	privilege_id: number;
	images: null;
	fullname: string;
	email: string;
	no_hp: string;
	nama_opd: string;
	status: number;
	updated_at: string;
	menu: Menu;
	privilege: Privilege;
	wilayah: wilayah;
};

type Response = {
	code: number;
	status: string;
	message: string;
	checkUsername: CheckUsername;
	token: string;
};

const initialState = {
	id_user: 0,
	username: '',
	unit_id: 0,
	bidang_id: 0,
	menu_id: 0,
	kd_provinsi: 0,
	kd_kabkota: 0,
	privilege_id: 0,
	images: null,
	fullname: '',
	email: '',
	no_hp: '',
	nama_opd: '',
	status: 0,
	updated_at: '',
	menu: {
		id_menu: 0,
		kd_menu: 0,
		jenis_menu: '',
		nama_menu: '',
	},
	privilege: {
		id_privilege: 0,
		kd_privilege: 0,
		nama_privilege: '',
	},
	wilayah: {
		nama_provinsi: '',
		nama_kabkota: '',
	},
} as CheckUsername;
export const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		setAuth: (state, action) => {
			// state = action.payload;
			state.id_user = action.payload.id_user;
			state.username = action.payload.username;
			state.unit_id = action.payload.unit_id;
			state.bidang_id = action.payload.bidang_id;
			state.menu_id = action.payload.menu_id;
			state.kd_provinsi = action.payload.kd_provinsi;
			state.kd_kabkota = action.payload.kd_kabkota;
			state.privilege_id = action.payload.privilege_id;
			state.images = action.payload.images;
			state.fullname = action.payload.fullname;
			state.email = action.payload.email;
			state.no_hp = action.payload.no_hp;
			state.nama_opd = action.payload.nama_opd;
			state.status = action.payload.status;
			state.updated_at = action.payload.updated_at;
			state.menu = action.payload.menu;
			state.privilege = action.payload.privilege;
			state.wilayah = action.payload.wilayah;
			// localStorage.setItem('auth', action.payload);
		},
		editAuth: (state, action) => {
			state.username = action.payload.username;
			state.fullname = action.payload.fullname;
			state.email = action.payload.email;
			state.no_hp = action.payload.no_hp;
			state.nama_opd = action.payload.nama_opd;
		},
		logout: () => {
			const colorTheme = localStorage.getItem('color-theme');
			localStorage.clear();
			if (colorTheme) {
				localStorage.setItem('color-theme', colorTheme);
			}
		},
		auth_error: () => {
			const colorTheme = localStorage.getItem('color-theme');
			localStorage.clear();
			if (colorTheme) {
				localStorage.setItem('color-theme', colorTheme);
			}
		},
	},
});

export const { setAuth, editAuth, logout, auth_error } = authSlice.actions;
export default authSlice.reducer;
