import DefaultLayout from '../../../layout/DefaultLayout';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useEffect, useRef, useState } from 'react';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import TIdentifikasiRumahRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/TIdentifikasiRumahRelokasi';
import TIdentifikasiKawasanKumuhRumahRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/TIdentifikasiKawasanKumuhRumahRelokasi';
import TIdentifikasiRumahTidakSesuaiRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/TIdentifikasiRumahRTRWRelokasi';
import TIdentifikasiPerumahanLPRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/TIdentifikasiPerumahanLPRelokasi';
import TIdentifikasiPerumahanPBRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/TIdentifikasiPerumahanPBRelokasi';
import TIdentifikasiPerumahanRBRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/TIdentifikasiPerumahanRBRelokasi';
import TPengumpulanDataRumahRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/TPengumpulanDataRumahRelokasi';
// import TDataCapaianRumahRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/TDataCapaianRumahRelokasi';
// import SelectUI from '@/components/ui/SelectUI';
import { Popover } from '@/components/ui/popover';
import { PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import { FiArrowDown, FiChevronDown, FiSidebar } from 'react-icons/fi';
// import { BsFillArrowDownLeftCircleFill } from 'react-icons/bs';
import BNBARumahRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/BNBARumahRelokasi';
import PenetapanRelokasi from '@/components/Tables/RumahRelokasi/PengumpulanData/PenetapanRelokasi';
import { usePrivilegeRumahRelokasi } from '@/hooks/privilege/usePrivilege';
import { useGetDataCapaianPenetapanRelokasi } from '@/hooks/perumahan/useRumahRelokasi';

const PengumpulanDataRumahRelokasi = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const user = useSelector((state: RootState) => state.auth);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const [activeTab, setActiveTab] = useState('IdentifikasiPerumahan');
	const [adaRelokasi, setAdaRelokasi] = useState('tidak');
	const [penetapanRelokasi, setPenetapanRelokasi] = useState<any>(null);
	const { isPrevilegeViewRumahRelokasi } = usePrivilegeRumahRelokasi();

	const { data: dataPenetapanRelokasi, isLoading: loadPenetapanRelokasi } =
		useGetDataCapaianPenetapanRelokasi(
			menuId,
			user.kd_provinsi,
			user.kd_kabkota,
			Number(tahun),
		);

	useEffect(() => {
		if (!loadPenetapanRelokasi) {
			const data = dataPenetapanRelokasi!.pages.flatMap((page) => page.data);
			const haveTidakAdaRelokasi = data.some(
				(item) => item.jenis_relokasi_id === 14,
			);

			console.log("asdasdaasdsad " + haveTidakAdaRelokasi)

			if (haveTidakAdaRelokasi) {
				setPenetapanRelokasi(true);
			} else {
				setPenetapanRelokasi(false);
			}
		}
	}, [loadPenetapanRelokasi]);

	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead="Pengumpulan Data Rumah Relokasi"
				pageName="Pengumpulan Data"
			/>
			<div
				className={`grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 ${!penetapanRelokasi || isPrevilegeViewRumahRelokasi ? 'sm:grid-cols-4' : 'sm:grid-cols-2'} `}
			>
				{/* ================================ Tab Popover ================================ */}
				<div
					className={`flex text-center ${activeTab === 'IdentifikasiPerumahan' ||
						activeTab === 'IdentifikasiKawasanKumuh' ||
						activeTab === 'IdentifikasiRumahTidakSesuai' ||
						activeTab === 'IdentifikasiLahanBukanFungsi' ||
						activeTab === 'IdentifikasiPerumahanPBRelokasi' ||
						activeTab === 'IdentifikasiPerumahanRBRelokasi'
						? 'bg-[#fab23d]'
						: 'bg-[#364878]'
						} cursor-pointer rounded-md text-white hover:bg-graydark`}
				>
					<Popover>
						<PopoverTrigger
							className={`flex h-full w-full items-center justify-center rounded-md ${activeTab === 'IdentifikasiPerumahan' ||
								activeTab === 'IdentifikasiKawasanKumuh' ||
								activeTab === 'IdentifikasiRumahTidakSesuai' ||
								activeTab === 'IdentifikasiLahanBukanFungsi' ||
								activeTab === 'IdentifikasiPerumahanPBRelokasi' ||
								activeTab === 'IdentifikasiPerumahanRBRelokasi'
								? 'border-transparent bg-[#fab23d]'
								: 'border-transparent bg-[#364878]'
								} hover:bg-graydark`}
						>
							<div className="inline-flex items-center font-bold">
								Pilih Identifikasi
								<br />
								Perumahan <FiChevronDown size={25} className="ml-3" />
							</div>
						</PopoverTrigger>
						<PopoverContent className="z-999999 ml-1 w-[95%] max-w-[95%] items-center justify-center gap-2 rounded-b-md bg-[#364878] py-2">
							<div
								className={`py-4 text-center ${activeTab === 'IdentifikasiPerumahan'
									? 'bg-[#fab23d]'
									: 'bg-[#364878]'
									} cursor-pointer rounded-md text-white hover:bg-graydark`}
								onClick={() => setActiveTab('IdentifikasiPerumahan')}
							>
								Identifikasi Perumahan
								<br />
								Terkena Relokasi
							</div>
							<div
								className={`py-4 text-center ${activeTab === 'IdentifikasiKawasanKumuh'
									? 'bg-[#fab23d]'
									: 'bg-[#364878]'
									} cursor-pointer rounded-md text-white hover:bg-graydark`}
								onClick={() => setActiveTab('IdentifikasiKawasanKumuh')}
							>
								Identifikasi
								<br />
								Perumahan Kumuh
							</div>
							<div
								className={`p-3 py-4 text-center ${activeTab === 'IdentifikasiRumahTidakSesuai'
									? 'bg-[#fab23d]'
									: 'bg-[#364878]'
									} cursor-pointer rounded-md text-white hover:bg-graydark`}
								onClick={() => setActiveTab('IdentifikasiRumahTidakSesuai')}
							>
								Identifikasi Perumahan
								<br />
								Tidak Sesuai Rencana Tata Rencana Wilayah
							</div>
							<div
								className={`py-4 text-center ${activeTab === 'IdentifikasiLahanBukanFungsi'
									? 'bg-[#fab23d]'
									: 'bg-[#364878]'
									} cursor-pointer rounded-md text-white hover:bg-graydark`}
								onClick={() => setActiveTab('IdentifikasiLahanBukanFungsi')}
							>
								Identifikasi Perumahan
								<br />
								Di Atas Lahan Bukan Fungsi Permukiman
							</div>
							<div
								className={`py-4 text-center ${activeTab === 'IdentifikasiPerumahanPBRelokasi'
									? 'bg-[#fab23d]'
									: 'bg-[#364878]'
									} cursor-pointer rounded-md text-white hover:bg-graydark`}
								onClick={() => setActiveTab('IdentifikasiPerumahanPBRelokasi')}
							>
								Identifikasi Perumahan
								<br />
								Di Lokasi Yang Menimbulkan Bahaya
							</div>
							<div
								className={`py-4 text-center ${activeTab === 'IdentifikasiPerumahanRBRelokasi'
									? 'bg-[#fab23d]'
									: 'bg-[#364878]'
									} cursor-pointer rounded-md text-white hover:bg-graydark`}
								onClick={() => setActiveTab('IdentifikasiPerumahanRBRelokasi')}
							>
								Identifikasi Perumahan
								<br />
								Di Daerah Rawan Bencana
							</div>
						</PopoverContent>
					</Popover>
				</div>

				<div
					className={`py-4 text-center ${activeTab === 'PenetapanRelokasi' ? 'bg-[#fab23d]' : 'bg-[#364878]'
						} cursor-pointer rounded-md text-white hover:bg-graydark`}
					onClick={() => setActiveTab('PenetapanRelokasi')}
				>
					Penetapan <br />
					Relokasi
				</div>
				{(!penetapanRelokasi || isPrevilegeViewRumahRelokasi) && (
					<>
						<div
							className={`py-4 text-center ${activeTab === 'PengumpulanDataRumahRelokasi'
								? 'bg-[#fab23d]'
								: 'bg-[#364878]'
								} cursor-pointer rounded-md text-white hover:bg-graydark`}
							onClick={() => setActiveTab('PengumpulanDataRumahRelokasi')}
						>
							Pengumpulan
							<br />
							Data Rumah
						</div>
						<div
							className={`flex items-center justify-center text-center ${activeTab === 'BNBA' ? 'bg-[#fab23d]' : 'bg-[#364878]'
								} cursor-pointer rounded-md text-white hover:bg-graydark`}
							onClick={() => setActiveTab('BNBA')}
						>
							BNBA
						</div>
					</>
				)}
			</div>

			{/* ============================================== */}
			{activeTab === 'IdentifikasiPerumahan' && (
				<>
					<TIdentifikasiRumahRelokasi />
				</>
			)}
			{activeTab === 'IdentifikasiKawasanKumuh' && (
				<TIdentifikasiKawasanKumuhRumahRelokasi />
			)}
			{activeTab === 'IdentifikasiRumahTidakSesuai' && (
				<TIdentifikasiRumahTidakSesuaiRelokasi />
			)}
			{activeTab === 'IdentifikasiLahanBukanFungsi' && (
				<TIdentifikasiPerumahanLPRelokasi />
			)}
			{activeTab === 'IdentifikasiPerumahanPBRelokasi' && (
				<TIdentifikasiPerumahanPBRelokasi />
			)}
			{activeTab === 'IdentifikasiPerumahanRBRelokasi' && (
				<TIdentifikasiPerumahanRBRelokasi />
			)}
			{activeTab === 'PenetapanRelokasi' && (
				<PenetapanRelokasi
					adaRelokasi={adaRelokasi}
					setAdaRelokasi={setAdaRelokasi}
					setActiveTab={setActiveTab}
					penetapanRelokasi={penetapanRelokasi}
					setPenetapanRelokasi={setPenetapanRelokasi}
				/>
			)}
			{activeTab === 'PengumpulanDataRumahRelokasi' && (
				<TPengumpulanDataRumahRelokasi />
			)}
			{activeTab === 'BNBA' && <BNBARumahRelokasi />}
			{/* {activeTab === 'DataAwal' && <TDataCapaianRumahRelokasi />} */}
		</DefaultLayout>
	);
};

export default PengumpulanDataRumahRelokasi;






