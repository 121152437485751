import { API } from '../libs/API';
import { useQuery } from '@tanstack/react-query';

export function useGetJenisBencana(){
	const {
		data: getJenisBencana,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['jenis-bencana'],
		queryFn: async () => {
			const { data } = await API.get(`/jenis-bencana`);
			return data.data;
		},
	});
	return { getJenisBencana, refetch, isLoading };
}
