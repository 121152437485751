import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useGetByIdNewRencanaPemenuhan, } from '@/hooks/rencanaPelaksanaan/useRencanaPemenuhan';
import { useSumberAnggaran } from '@/hooks/useKomponen';
import { useGetNomen } from '@/hooks/useNomenklatur';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import { formatRupiah } from '@/libs/utils';
import { IFormValuesRencanaPemenuhan } from '@/types/RencanaPemenuhan';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import KomponenSelect from './Komponen/KomponenSelect';
import SatuanSelect from './Komponen/SatuanSelect';

const DetailRencana = ({ id }: { id: any }) => {
	const bidang = useSelector((state: RootState) => state.bidang.value)
	const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)

	const { getDetailRencana, isLoading: loadDetailRencana } = useGetByIdNewRencanaPemenuhan(id)
	const [kumpulanKomponen, setKumpulanKomponen] = useState<any>([])

	const { watch, setValue, reset } = useForm<IFormValuesRencanaPemenuhan>({
		defaultValues: {
			tahun_rencana: 0,
			menu_id: 0,
			kd_provinsi: 0,
			kd_kabkota: 0,
			kd_kec: 0,
			kd_desa: 0,
			nomenklatur_id: 0,
			sumber_anggaran_id: 0,
			rencana_anggaran: 0,
			jenis_kegiatan: "",
			nama_kegiatan: "",
			catatan: "",
		}
	})

	const { getKabKota, isLoading: loadKabKota } = useGetKabKota(watch("kd_provinsi")!)

	const { getKecamatan, isLoading: loadKec } = useGetKecamatan(
		watch("kd_provinsi")!,
		watch("kd_kabkota")!
	);
	const {
		getDesa,
		isLoading: loadDesa,
	} = useGetDesa(watch("kd_provinsi")!, watch("kd_kabkota")!, watch("kd_kec")!);

	// const { getNomen, isLoading: loadNomen } = useGetNomen(watch("menu_id")!);
	const { getNomen, isLoading: loadNomen } = useGetNomen(1);
	const { getSumberAnggaran, isLoading: loadSumber } = useSumberAnggaran();



	useEffect(() => {
		if (!loadDetailRencana) {
			reset({
				tahun_rencana: getDetailRencana.tahun_rencana,
				kd_provinsi: getDetailRencana.kd_provinsi,
				kd_kabkota: getDetailRencana.kd_kabkota,
				kd_kec: getDetailRencana.kd_kec,
				kd_desa: getDetailRencana.kd_desa,
				menu_id: getDetailRencana.menu_id,
				nomenklatur_id: getDetailRencana.nomenklatur_id,
				jenis_kegiatan: getDetailRencana.nomenklatur.jenis_kegiatan,
				nama_kegiatan: getDetailRencana.nama_kegiatan,
				sumber_anggaran_id: getDetailRencana.sumber_anggaran_id,
				sumber_anggaran: getDetailRencana.sumber_anggaran.sumber_anggaran,
				rencana_anggaran: getDetailRencana.rencana_anggaran
			})
			setKumpulanKomponen(getDetailRencana.rencana_komponen)
		}
	}, [loadDetailRencana, getDetailRencana])

	if (loadDetailRencana || loadKabKota || loadKec) return <CompLoader />

	return (
		<div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden">
			<div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
				<h3 className="text-2xl font-medium text-[#364878] dark:text-white">
					Detail Rencana Pemenuhan Bidang {' '}
					{bidang === 'air-minum'
						? 'Air Minum'
						: bidang === 'air-limbah'
							? 'Air Limbah'
							: bidang === 'rumah-bencana'
								? 'Rumah Bencana'
								: bidang === 'rumah-relokasi'
									? 'Rumah Relokasi'
									: ''}
				</h3>
			</div>
			<ScrollArea>
				<div className="flex flex-col gap-5.5 p-6.5">
					{/* <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label
								htmlFor="tahun"
								className="text-slate mb-3 block font-medium dark:text-white"
							>
								Tahun
							</label>
						</div>
						<div className="text-right">
							<input
								id="tahun"
								disabled
								name="tahun_rencana"
								type="text"
								value={watch("tahun_rencana")}
								placeholder="Masukan Tahun rencana"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div> */}
					{privilege === 2 &&
						<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
							<div className="flex items-end">
								<label
									htmlFor="kecamatan"
									className="text-slate mb-3 block font-medium dark:text-white"
								>
									Kabupaten / Kota
								</label>
							</div>
							<div className="text-right">
								<select
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
									disabled
									value={watch("kd_kabkota")}
								>
									<option value="0" disabled hidden>
										Pilih Kabupaten / Kota
									</option>
									{!loadKabKota && getKabKota.map((kabkota: any, index: any) => (
										<option key={index} value={kabkota.kd_kabkota}>
											{kabkota.nama_kabkota}
										</option>
									))}
								</select>
							</div>
						</div>
					}
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label
								htmlFor="kecamatan"
								className="text-slate mb-3 block font-medium dark:text-white"
							>
								Kecamatan
							</label>
						</div>
						<div className="text-right">
							<select
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								disabled
								value={String(watch("kd_kec"))}
							>
								<option value="0" disabled hidden>
									Pilih Kecamatan
								</option>
								{!loadKec && getKecamatan.map((kecamatan: any, index: any) => (
									<option key={index} value={kecamatan.kd_kec}>
										{kecamatan.nama_kecamatan}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label
								htmlFor="desa"
								className="text-slate mb-3 block font-medium dark:text-white"
							>
								Kelurahan/desa
							</label>
						</div>
						<div className="text-right">
							<select
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								disabled
								value={String(watch("kd_desa"))}
							>
								<option value="0" disabled hidden>
									Pilih Kelurahan / Desa
								</option>
								{!loadDesa && getDesa.map((desa: any, index: any) => (
									<option key={index} value={desa.kd_desa}>
										{desa.nama_desa}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label
								htmlFor="nomenklatur"
								className="text-slate mb-3 block font-medium dark:text-white"
							>
								Nomenklatur
							</label>
						</div>
						<div className="text-right">
							<select
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								disabled
								value={String(watch("nomenklatur_id"))}
							>
								<option value="0" disabled hidden>
									Pilih Nomenklatur
								</option>
								{!loadNomen && getNomen.map((nomen: any, index: any) => (
									<option key={index} value={nomen.id_nomenklatur}>
										{nomen.nama_nomenklatur}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label
								htmlFor="jenis-kegiatan"
								className="text-slate mb-3 block font-medium dark:text-white"
							>
								Jenis Kegiatan
							</label>
						</div>
						<div className="text-right">
							<input
								disabled
								id="jenis-kegiatan"
								name="jenis-kegiatan"
								type="text"
								value={watch("jenis_kegiatan")}
								placeholder="New Value"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
						<div className="flex items-end">
							<label
								htmlFor="nama-kegiatan"
								className="text-slate mb-3 block font-medium dark:text-white"
							>
								Nama Kegiatan
							</label>
						</div>
						<div className="text-right">
							<input
								id="nama-kegiatan"
								name="nama-kegiatan"
								disabled
								value={watch("nama_kegiatan")}
								type="text"
								placeholder="Masukkan nama kegiatan"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label
								htmlFor="sumber-anggaran"
								className="text-slate mb-3 block font-medium dark:text-white"
							>
								Sumber Anggaran
							</label>
						</div>
						<div className="text-right">
							<select
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								disabled
								value={watch("sumber_anggaran_id")}
							>
								<option value="0" disabled hidden>
									Pilih Sumber Anggaran
								</option>
								{!loadSumber && getSumberAnggaran.map((sumber: any, index: any) => (
									<option key={index} value={sumber.id_sumber_anggaran}>
										{sumber.sumber_anggaran}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
						<div className="flex items-end">
							<label
								htmlFor="anggaran"
								className="text-slate mb-3 block font-medium dark:text-white"
							>
								Anggaran
							</label>
						</div>
						<div className="text-right">
							<input
								disabled
								id="anggaran"
								name="anggaran"
								value={formatRupiah(Number(watch("rencana_anggaran")))}
								type="text"
								placeholder="Masukkan jumlah anggaran"
								className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
							/>
						</div>
					</div>
					<div className="flex flex-col border-b border-slate-200 pb-3">
						<div className="flex w-full items-center justify-between">
							<label
								htmlFor="komponen"
								className="text-slate block font-medium dark:text-white"
							>
								Komponen
							</label>
						</div>
						{kumpulanKomponen.map((item: any, index: any) => (
							<div key={index} className="flex relative items-center p-6 gap-4 border-b border-slate-200">
								<label
									htmlFor="komponen"
									className="w-1/3 text-slate block font-medium dark:text-white"
								>
									Komponen {index + 1}
								</label>
								<div className="flex flex-col gap-4 w-full">
									<KomponenSelect disabled nomenklatur_id={watch("nomenklatur_id")} kumpulanKomponen={kumpulanKomponen[index]} setKumpulanKomponen={setKumpulanKomponen} index={index} />
									<SatuanSelect disabled kumpulanKomponen={kumpulanKomponen[index]} setKumpulanKomponen={setKumpulanKomponen} index={index} />
									<input
										disabled
										id="volume"
										name="volume"
										value={kumpulanKomponen[index].volume}
										type="number"
										step="0.01"
										placeholder="Masukan Volume"
										className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</ScrollArea>
		</div>
	);
};
export default DetailRencana;
