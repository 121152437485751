import { RootState } from "@/app/store"
import { Button } from "@/components/ui/Button"
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogTrigger } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useEditDataCapaianKumulatifAMCurahProv } from "@/hooks/airMinum/useDataCapaianAM"
import { useGetKabKota } from "@/hooks/wilayah/useLokasi"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { FiEdit } from "react-icons/fi"
import { useSelector } from "react-redux"
import { toast } from "sonner"

type FormValue = {
  kd_kabkota: number
  nama_kabkota: string
  tgt_curah: number
  real_curah: number
  id_eksis_am_prov: number
}

const EditFormDataAirMinumCurah = ({ data }: { data: any }) => {
  const user = useSelector((state: RootState) => state.auth);
  const { getKabKota, isLoading: isLoadingKabkota } = useGetKabKota(user.kd_provinsi)
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const queryClient = useQueryClient()
  const [modalOpen, setModalOpen] = useState(false)
  const { register, setValue, handleSubmit, reset, watch } = useForm<FormValue>({
    defaultValues: {
      kd_kabkota: data.kd_kabkota,
      nama_kabkota: data.nama_kabkota,
      tgt_curah: data.tgt_curah,
      real_curah: data.real_curah,
      id_eksis_am_prov: data.id_eksis_am_prov
    }
  })
  const { asyncEditCapaianKumulatifAMCurahProv } = useEditDataCapaianKumulatifAMCurahProv()

  const handleKabKota = (val: any) => {
    const kabkota = getKabKota.find((item: any) => item.kd_kabkota === val)
    setValue('kd_kabkota', Number(val));
    setValue("nama_kabkota", kabkota.nama_kabkota)
  };

  const onSubmit = async (data: any) => {
    try {
      const dataAirMinumCurah = {
        id_eksis_am_prov: data.id_eksis_am_prov,
        user_id: user.id_user,
        menu_id: user.menu_id,
        tahun_pelaporan: tahun,
        kd_provinsi: user.kd_provinsi,
        kd_kabkota: data.kd_kabkota,
        nama_kabkota: data.nama_kabkota,
        tgt_curah: data.tgt_curah,
        real_curah: data.real_curah,
      }
      await asyncEditCapaianKumulatifAMCurahProv(dataAirMinumCurah)
      queryClient.refetchQueries({ queryKey: ["eksis-air-minum-curah-prov", Number(user.kd_provinsi), Number(tahun)] })
      reset()
      toast.success('Berhasil menambahkan data capaian Air Minum Provinsi');
      setModalOpen(false)
    } catch (error) {
      toast.error('Gagal menambahkan data capaian Air Minum Provinsi');
    }
  }

  return (
    <Dialog open={modalOpen} onOpenChange={setModalOpen}>
      <DialogTrigger asChild>
        <Button
          size="sm"
          className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'
        >
          <FiEdit />
        </Button>
      </DialogTrigger>
      <DialogContent className="z-[999] max-h-[85%] max-w-[70%] overflow-auto">
        <div className="flex items-center p-6 gap-4 border-b border-slate-200">
          <Label htmlFor="Kabupaten" className="w-1/2 text-xl text-black">
            Kabupaten
          </Label>
          <Select disabled value={String(watch("kd_kabkota"))} onValueChange={(val) => handleKabKota(val)}>
            <SelectTrigger>
              <SelectValue placeholder="Pilih Kabupaten">
                {watch("kd_kabkota") === 0 ? "Pilih Kabupaten" : watch("nama_kabkota")}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="z-999">
              {!isLoadingKabkota && getKabKota.map((kabkota: any, index: any) => (
                <SelectGroup key={index}>
                  <SelectItem value={kabkota.kd_kabkota}>
                    {kabkota.nama_kabkota}
                  </SelectItem>
                </SelectGroup>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex">
          <div className="basis-1/2">
            <div className="flex items-center p-6 gap-4 border-b border-slate-200">
              <Label htmlFor="Target" className="w-1/2 text-2xl text-black font-black">
                Target
              </Label>
            </div>
            <div className="flex items-center p-6 gap-4 border-b border-slate-200">
              <Label htmlFor="Target Curah" className="w-1/2 text-xl text-black">
                Curah
              </Label>
              <Input
                id="Target Curah"
                type="number"
                min="0"
                {...register("tgt_curah", { valueAsNumber: true })}
                placeholder='Masukkan Target Rehabilitasi Rumah' />
            </div>
          </div>
          <div className="basis-1/2 border-l border-slate-200">
            <div className="flex items-center p-6 gap-4 border-b border-slate-200">
              <Label htmlFor="Jenis Bencana" className="w-1/2 text-2xl text-black font-black">
                Realisasi
              </Label>
            </div>
            <div className="flex items-center p-6 gap-4 border-b border-slate-200">
              <Label htmlFor="Realisasi Curah" className="w-1/2 text-xl text-black">
                Curah
              </Label>
              <Input
                id="Realisasi Curah"
                type="number"
                min="0"
                {...register("real_curah", { valueAsNumber: true })}
                placeholder='Masukkan Realisasi Curah' />
            </div>
          </div>
        </div>
        <DialogFooter>
          <div className="mt-5 flex w-full justify-between">
            <DialogClose asChild>
              <Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
                Batal
              </Button>
            </DialogClose>
            <Button
              className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
              onClick={() => handleSubmit(onSubmit)()}
            >
              Simpan
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditFormDataAirMinumCurah