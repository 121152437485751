import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import { useQuery } from '@tanstack/react-query';
import { API } from '@/libs/API';
import { MiniLoader } from '@/common/Loader';

const options: ApexOptions = {
	colors: ['#3C50E0', '#80CAEE', '#FF0000', '#00FF00'], // Add more colors for each category
	chart: {
		fontFamily: 'Satoshi, sans-serif',
		type: 'bar',
		height: 335,
		stacked: true,
		toolbar: {
			show: false,
		},
		zoom: {
			enabled: false,
		},
	},
	responsive: [
		{
			breakpoint: 1536,
			options: {
				plotOptions: {
					bar: {
						borderRadius: 0,
						columnWidth: '15%',
					},
				},
			},
		},
	],
	plotOptions: {
		bar: {
			horizontal: false,
			borderRadius: 0,
			columnWidth: '15%',
			borderRadiusApplication: 'end',
			borderRadiusWhenStacked: 'last',
		},
	},
	dataLabels: {
		enabled: false,
	},
	xaxis: {
		categories: ['air minum', 'air limbah', 'rumah bencana', 'rumah relokasi'],
	},
	legend: {
		position: 'top',
		horizontalAlign: 'left',
		fontFamily: 'Satoshi',
		fontWeight: 500,
		fontSize: '14px',
		markers: {
			radius: 99,
		},
	},
	fill: {
		opacity: 1,
	},
};

interface ChartPelaporan {
  series: {
    name: string;
    data: number[];
  }[];
}

const ChartBeranda: React.FC = () => {
	//Kabupaten
	const [statekab, setStatekab] = useState<ChartPelaporan>({
		series: [],
	});

	//Provinsi
	const [stateprov, setStateprov] = useState<ChartPelaporan>({
		series: [],
	});

	const wilayah = useSelector((state: RootState) => state.wilayah.value);
	const tahun = useSelector((state: RootState) => state.tahun.value);

	const { data, isLoading } = useQuery({
		queryKey: ['jumlah-lapor-tahun', tahun],
		queryFn: async () => {
			const res = await API.get(`dash-jumlah-lapor/?tahun=${tahun}`);
			return res.data.data;
		},
	});

	const { data: dataProv, isLoading: isLoadingProv } = useQuery({
		queryKey: ['jumlah-lapor-tahun-prov', tahun],
		queryFn: async () => {
			const res = await API.get(`dash-jumlah-lapor-prov/?tahun=${tahun}`);
			return res.data.data;
		},
	});

	const handleReset = () => {
		setStatekab((prevState) => ({
			...prevState,
		}));
		setStateprov((prevState) => ({
			...prevState,
		}));
	};
	handleReset;

	useEffect(() => {
		if (!isLoading && !isLoadingProv && data && dataProv) {
			setStatekab((prevState) => ({
				series: [
					{
						name: 'air minum',
						data: [data.jumlah_lapor_am ?? 0, NaN, NaN, NaN],
					},
					{
						name: 'air limbah',
						data: [NaN, data.jumlah_lapor_al ?? 0, NaN, NaN],
					},
					{
						name: 'rumah bencana',
						data: [NaN, NaN, data.jumlah_lapor_bencana ?? 0, NaN],
					},
					{
						name: 'rumah relokasi',
						data: [NaN, NaN, NaN, data.jumlah_lapor_relokasi ?? 0],
					},
				],
			}));

			setStateprov((prevState) => ({
				series: [
					{
						name: 'air minum',
						data: [
							dataProv.jumlah_lapor_am_prov ?? dataProv.jumlah_lapor_am ?? 0,
							NaN,
							NaN,
							NaN,
						],
					},
					{
						name: 'air limbah',
						data: [
							NaN,
							dataProv.jumlah_lapor_al_prov ?? dataProv.jumlah_lapor_al ?? 0,
							NaN,
							NaN,
						],
					},
					{
						name: 'rumah bencana',
						data: [
							NaN,
							NaN,
							dataProv.jumlah_lapor_bencana_prov ??
								dataProv.jumlah_lapor_bencana ??
								0,
							NaN,
						],
					},
					{
						name: 'rumah relokasi',
						data: [
							NaN,
							NaN,
							NaN,
							dataProv.jumlah_lapor_relokasi_prov ??
								dataProv.jumlah_lapor_relokasi ??
								0,
						],
					},
				],
			}));
		}
	}, [isLoading, isLoadingProv, data, dataProv, tahun]);

	if (isLoading || isLoadingProv) return <MiniLoader />;

	return (
		<div className="col-span-12 flex h-full flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-7.5 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4">
			<div className="mb-4 justify-between gap-4 sm:flex">
				<div>
					{wilayah === 'provinsi' ? (
						<h4 className="text-xl font-semibold text-[#364878] dark:text-white">
							Jumlah Pelaporan SPM Provinsi {`${tahun}`}
						</h4>
					) : (
						<h4 className="text-xl font-semibold text-[#364878] dark:text-white">
							Jumlah Pelaporan SPM Kabupaten / Kota
						</h4>
					)}
				</div>
			</div>
			<ScrollArea className="h-full">
				<div id="chartTwo">
					<ReactApexChart
						options={options}
						series={wilayah === 'provinsi' ? stateprov.series : statekab.series}
						type="bar"
						height={350}
					/>
				</div>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};

export default ChartBeranda;
