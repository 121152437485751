import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import { Button } from '@/components/ui/Button';
import JenisProgramRelokasiSelectUI from '@/components/ui/SelectJenisProgramRelokasiUI';
import KabKotaSelectUI from '@/components/ui/SelectKabKotaUI';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
	useGetJenisProgramRelokasi,
	usePostDataCapaianRumahRelokasi,
} from '@/hooks/perumahan/useRumahBencana';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiArrowLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

type FormDataCapaianKumulatifRumahRelokasiProps = {
	setShowForm: any;
};

type FormValue = {
	tahun_pelaporan: number;
	menu_id: number;
	kd_provinsi: number;
	kd_kabkota: number;
	kd_kec: number;
	kd_desa: number;
	jenis_relokasi_id: number;
	jml_rmh_relokasi: number;
	tgt_ganti_uang: number;
	tgt_bgn_kembali: number;
	tgt_subsidi: number;
	tgt_akses_rmh: number;
	real_ganti_uang: number;
	real_bgn_kembali: number;
	real_subsidi: number;
	real_akses_rmh: number;
};

const FormDataCapaianKumulatifRumahRelokasiProv = ({
	setShowForm,
}: FormDataCapaianKumulatifRumahRelokasiProps) => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const user = useSelector((state: RootState) => state.auth);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	const [jenisProgramRelokasi, setJenisProgramRelokasi] = useState('0');
	const [kd_kec, setKd_kec] = useState('0');
	const [kd_desa, setKd_desa] = useState('0');
	const [kd_kabkota, setKd_kabkota] = useState('0');
	const queryClient = useQueryClient();

	const { register, setValue, handleSubmit, watch, reset } = useForm<FormValue>(
		{
			defaultValues: {
				tahun_pelaporan: Number(tahun),
				menu_id: menuId,
				kd_provinsi: user.kd_provinsi,
				kd_kabkota: Number(kd_kabkota),
				kd_kec: Number(kd_kec),
				kd_desa: Number(kd_desa),
				jenis_relokasi_id: Number(jenisProgramRelokasi),
				jml_rmh_relokasi: 0,
				tgt_ganti_uang: 0,
				tgt_bgn_kembali: 0,
				tgt_subsidi: 0,
				tgt_akses_rmh: 0,
				real_ganti_uang: 0,
				real_bgn_kembali: 0,
				real_subsidi: 0,
				real_akses_rmh: 0,
			},
		},
	);

	const { asyncPostDataCapaianRumahRelokasi, isPending } =
		usePostDataCapaianRumahRelokasi();

	useEffect(() => {
		setValue('jenis_relokasi_id', Number(jenisProgramRelokasi));
		setValue('kd_kabkota', Number(kd_kabkota));
		setValue('kd_kec', Number(kd_kec));
	}, [jenisProgramRelokasi, kd_kabkota, kd_kec]);

	const { getKabKota, isLoading: isLoadingKabKota } = useGetKabKota(
		user.kd_provinsi,
	);
	const { getKecamatan, isLoading: isLoadingKecamatan } = useGetKecamatan(
		user.kd_provinsi,
		watch('kd_kabkota'),
	);
	const { getJenisProgramRelokasi, isLoading: isLoadingRelokasi } =
		useGetJenisProgramRelokasi();

	const onSubmit = async (data: any) => {
		try {
			await asyncPostDataCapaianRumahRelokasi(data);
			queryClient.refetchQueries({ queryKey: ['eksis-relokasi'] });
			reset();
			setShowForm('Lihat Data');
			toast.success('Berhasil menambahkan data capaian Rumah Bencana');
		} catch (error) {
			toast.error('Gagal menambahkan data capaian Rumah Bencana');
		}
	};

	if (isLoadingRelokasi || isLoadingKabKota || isPending) return <CompLoader />;

	return (
		<>
			<div className="flex justify-end pb-4">
				<div className="flex gap-4">
					<Button
						className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
						onClick={() => setShowForm('Lihat Data')}
					>
						<FiArrowLeft />
						Kembali
					</Button>
				</div>
			</div>
			<div className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
				<ScrollArea className="h-full">
					<div className="flex items-center gap-4 border-b border-slate-200 p-6">
						<Label
							htmlFor="Jenis Program Relokasi"
							className="w-1/2 text-xl text-black"
						>
							Jenis Program Relokasi
						</Label>
						<JenisProgramRelokasiSelectUI
							getJenisProgramRelokasi={
								isLoadingRelokasi ? [] : getJenisProgramRelokasi
							}
							setJenisProgramRelokasi={setJenisProgramRelokasi}
						/>
					</div>
					<div className="flex items-center gap-4 border-b border-slate-200 p-6">
						<Label
							htmlFor="Kabupaten/Kota"
							className="w-1/2 text-xl text-black"
						>
							Kabupaten/Kota
						</Label>
						<KabKotaSelectUI
							getKabKota={isLoadingKabKota ? [] : getKabKota}
							setKabKota={setKd_kabkota}
						/>
					</div>
					<div className="flex items-center gap-4 border-b border-slate-200 p-6">
						<Label
							htmlFor="Kabupaten/Kota"
							className="w-1/2 text-xl text-black"
						>
							Kecamatan
						</Label>
						<KecamatanSelectUI
							getKecamatan={isLoadingKecamatan ? [] : getKecamatan}
							setKd_kec={setKd_kec}
						/>
					</div>
					<div className="flex items-center gap-4 border-b border-slate-200 p-6">
						<Label
							htmlFor="Jenis Rumah Terkena Relokasi"
							className="w-1/2 text-xl text-black"
						>
							Jumlah Rumah Terkena Relokasi
						</Label>
						<Input
							id="Jumlah Rumah Terkena Relokasi"
							type="number"
							min="0"
							{...register('jml_rmh_relokasi', { valueAsNumber: true })}
							placeholder="Masukkan Jumlah Rumah Terkena Relokasi"
						/>
					</div>
					<div className="flex">
						<div className="basis-1/2">
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Target"
									className="w-1/2 text-2xl font-black text-black"
								>
									Target
								</Label>
							</div>
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Penggantian Uang (Target)"
									className="w-1/2 text-xl text-black"
								>
									Penggantian Uang
								</Label>
								<Input
									id="Penggantiang Uang (Target)`"
									type="number"
									min="0"
									{...register('tgt_ganti_uang', { valueAsNumber: true })}
									placeholder="Masukkan Penggantiang Uang (Target)"
								/>
							</div>
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Pembangunan Kembali (Target)"
									className="w-1/2 text-xl text-black"
								>
									Pembangunan Kembali
								</Label>
								<Input
									id="Pembangunan Kembali (Target)"
									type="number"
									min="0"
									{...register('tgt_bgn_kembali', { valueAsNumber: true })}
									placeholder="Masukkan Pembangunan Kembali (Target)"
								/>
							</div>
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Subsidi Uang Sewa (Target)"
									className="w-1/2 text-xl text-black"
								>
									Subsidi Uang Sewa
								</Label>
								<Input
									id="Subsidi Uang Sewa (Target)"
									type="number"
									min="0"
									{...register('tgt_subsidi', { valueAsNumber: true })}
									placeholder="Masukkan Subsidi Uang Sewa (Target)"
								/>
							</div>
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Akses Tinggal Rumah Sewa (Target)"
									className="w-1/2 text-xl text-black"
								>
									Akses Tinggal Rumah Sewa
								</Label>
								<Input
									id="Akses Tinggal Rumah Sewa (Target)"
									type="number"
									min="0"
									{...register('tgt_akses_rmh', { valueAsNumber: true })}
									placeholder="Masukkan Akses Tinggal Rumah Sewa (Target)"
								/>
							</div>
						</div>
						<div className="basis-1/2">
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Jenis Bencana"
									className="w-1/2 text-2xl font-black text-black"
								>
									Realisasi
								</Label>
							</div>
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Penggantian Uang (Realisasi)"
									className="w-1/2 text-xl text-black"
								>
									Penggantian Uang
								</Label>
								<Input
									id="Penggantiang Uang (Realisasi)`"
									type="number"
									min="0"
									{...register('real_ganti_uang', { valueAsNumber: true })}
									placeholder="Masukkan Penggantiang Uang (Realisasi)"
								/>
							</div>
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Pembangunan Kembali (Realisasi)"
									className="w-1/2 text-xl text-black"
								>
									Pembangunan Kembali
								</Label>
								<Input
									id="Pembangunan Kembali (Realisasi)"
									type="number"
									min="0"
									{...register('real_bgn_kembali', { valueAsNumber: true })}
									placeholder="Masukkan Pembangunan Kembali (Realisasi)"
								/>
							</div>
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Subsidi Uang Sewa (Realisasi)"
									className="w-1/2 text-xl text-black"
								>
									Subsidi Uang Sewa
								</Label>
								<Input
									id="Subsidi Uang Sewa (Realisasi)"
									type="number"
									min="0"
									{...register('real_subsidi', { valueAsNumber: true })}
									placeholder="Masukkan Subsidi Uang Sewa (Realisasi)"
								/>
							</div>
							<div className="flex items-center gap-4 border-b border-slate-200 p-6">
								<Label
									htmlFor="Akses Tinggal Rumah Sewa (Realisasi)"
									className="w-1/2 text-xl text-black"
								>
									Akses Tinggal Rumah Sewa
								</Label>
								<Input
									id="Akses Tinggal Rumah Sewa (Realisasi)"
									type="number"
									min="0"
									{...register('real_akses_rmh', { valueAsNumber: true })}
									placeholder="Masukkan Akses Tinggal Rumah Sewa (Realisasi)"
								/>
							</div>
						</div>
					</div>
					<div className="mt-5 flex justify-between gap-5 p-4">
						<div className="w-full">
							<button
								className="inline-flex min-w-full items-center justify-center rounded-md border border-meta-3 px-10 py-4 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
								onClick={() => handleSubmit(onSubmit)()}
							>
								Simpan Data
							</button>
						</div>
					</div>
				</ScrollArea>
			</div>
		</>
	);
};

export default FormDataCapaianKumulatifRumahRelokasiProv;
