import { API } from '../libs/API';
import { useQuery } from '@tanstack/react-query';

export function useGetJenisPotensiBahaya(){
	const {
		data: getJenisPotensiBahaya,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['jenis-potensi-bahaya'],
		queryFn: async () => {
			const { data } = await API.get(`/jenis-potensi-bahaya`);
			return data.data;
		},
	});
	return { getJenisPotensiBahaya, refetch, isLoading };
}
