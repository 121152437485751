import { setAuth } from '../app/auth/loginSlice';
import { API } from '../libs/API';
import { useQuery } from '@tanstack/react-query';

export function useGetNomen(menu_id: number) {
	const {
		data: getNomen,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['nomen', menu_id],
		queryFn: async () => {
			const { data } = await API.get(`/nomenklatur/?menu_id=${menu_id}`);
			// setAuth(data.data.user);
			return data.data;
		},
	});
	return { getNomen, refetch, isLoading };
}
