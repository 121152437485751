import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import TableDataCapaianKumulatifALProv from '@/components/Tables/AirLimbah/DataCapaian/TableDataCapaianKumulatifALProv';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import TableDataCapaianKumulatifALBappedaProv from '@/components/Tables/AirLimbah/DataCapaian/TableDataCapaianKumulatifBappedaProv';

const DataCapaianKumulatifALProv = () => {
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
  const wilayah = useSelector((state: RootState) => state.wilayah.value)
  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead={`Data Capaian Kumulatif Air Limbah ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
        pageName={`Data Capaian Kumulatif Air Limbah ${wilayah === "provinsi" ? "Provinsi" : "Kabupaten/Kota"}`}
      />
      {privilege === 4 || privilege === 8 || privilege === 10 ? wilayah === "provinsi" ? <TableDataCapaianKumulatifALProv /> : <TableDataCapaianKumulatifALBappedaProv /> : <></>}
      {privilege === 2 && <TableDataCapaianKumulatifALProv />}
    </DefaultLayout>
  );
};

export default DataCapaianKumulatifALProv;
