import { API } from '../../libs/API';
import { useQuery } from '@tanstack/react-query';

export function useGetDesa(
	kd_provinsi: number,
	kd_kabkota: number,
	kd_kec: number,
) {
	const {
		data: getDesa,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['desa', kd_provinsi, kd_kabkota, kd_kec],
		queryFn: async () => {
			const { data } = await API.get(
				`/get-desa/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&kd_kec=${kd_kec}`,
			);
			return data.data;
		},
	});
	return { getDesa, refetch, isLoading };
}
