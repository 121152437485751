import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

export const usePrivilegeRumahBencana = () => {
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);

	//list privilage
	const privilegePerkim = 10;
	const privilegeBappedaKab = 3;
	const privilegeBappedaProv = 4;
	const privilegeBP2P = 8;
	const privilegeRumahBencanaProv = 2;

	//kondisional previlage
	const isPrevilegeViewRumahBencana =
		privilege === privilegeBappedaKab ||
		privilege === privilegeBappedaProv ||
		privilege === privilegePerkim ||
		privilege === privilegeBP2P;

	//kondisional kab/kota
	const kd_kabkota_to_use =
		privilege === privilegeBappedaProv ||
		privilege === privilegeBappedaKab ||
		privilege === privilegePerkim ||
		privilege === privilegeBP2P
			? kd_kabkota_dux
			: kd_kabkota;

	//kondisional provinsi
	const kd_provinsi_to_use =
		// privilege === privilegeBappedaKab ||
		// privilege === privilegeBappedaProv ||
		privilege === privilegePerkim ? kd_provinsi_dux : kd_provinsi;

	return { isPrevilegeViewRumahBencana, kd_kabkota_to_use, kd_provinsi_to_use };
};

export const usePrivilegeRumahRelokasi = () => {
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);
	// console.log(kd_provinsi_dux, kd_kabkota_dux);

	//list privilage
	const privilegePerkim = 10;
	const privilegeBappedaKab = 3;
	const privilegeBappedaProv = 4;
	const privilegeBP2P = 8;

	//kondisional previlage
	const isPrevilegeViewRumahRelokasi =
		privilege === privilegeBappedaKab ||
		privilege === privilegeBappedaProv ||
		privilege === privilegePerkim ||
		privilege === privilegeBP2P;

	//kondisional kab/kota
	const kd_kabkota_to_use =
		privilege === privilegeBappedaProv ||
		privilege === privilegeBappedaKab ||
		privilege === privilegePerkim ||
		privilege === privilegeBP2P
			? kd_kabkota_dux
			: kd_kabkota;

	//kondisional provinsi
	const kd_provinsi_to_use =
		// privilege === privilegeBappedaKab ||
		// privilege === privilegeBappedaProv ||
		privilege === privilegePerkim ? kd_provinsi_dux : kd_provinsi;

	return {
		isPrevilegeViewRumahRelokasi,
		kd_kabkota_to_use,
		kd_provinsi_to_use,
		privilege,
	};
};

export const usePrivilegeAirLimbah = () => {
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);

	//list privilage
	const privilegePerkim = 10;
	const privilegeBappedaKab = 3;
	const privilegeBappedaProv = 4;
	const privilegeBP2P = 8;
	const privilegeAirLimbahProv = 2;

	const isPrivillageBappedaBPWP =
		privilege === privilegeBappedaProv ||
		privilege === privilegeBappedaKab ||
		privilege === privilegeBP2P ||
		privilege === privilegePerkim;

	//kondisional previlage
	const isPrevilegeViewAirLimbah =
		privilege === privilegeBappedaKab ||
		privilege === privilegeBappedaProv ||
		privilege === privilegePerkim ||
		privilege === privilegeBP2P ||
		privilege === privilegeAirLimbahProv;

	//kondisional kab/kota
	const kd_kabkota_to_use =
		privilege === privilegeBappedaProv ||
		privilege === privilegeBappedaKab ||
		privilege === privilegePerkim ||
		privilege === privilegeBP2P
			? kd_kabkota_dux
			: kd_kabkota;

	//kondisional provinsi
	const kd_provinsi_to_use =
		// privilege === privilegeBappedaKab ||
		// privilege === privilegeBappedaProv ||
		privilege === privilegePerkim ? kd_provinsi_dux : kd_provinsi;

	return {
		isPrevilegeViewAirLimbah,
		kd_kabkota_to_use,
		kd_provinsi_to_use,
		privilege,
		isPrivillageBappedaBPWP,
	};
};

export const useBidang = () => {
	const bidang = useSelector((state: RootState) => state.bidang.value);
	return {
		bidang,
	};
};
