import React, { useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface JenisBencanaSelectProps {
    getJenisBencana: any[];
    setJenisBencana: (id_jenis_bencana: string) => void;
}


const JenisBencanaSelectUI: React.FC<JenisBencanaSelectProps> = ({
    getJenisBencana,
    setJenisBencana
}) => {
    const [selectedJenisBencana, setSelectedJenisBencana] = useState('');

    return(
        <Select
            onValueChange={(value) => {
                    if (value !== '') {
                        const selectedJenisBencana = getJenisBencana.find((jenisBencana: any) => jenisBencana.id_jenis_bencana === value);
                        if(selectedJenisBencana){
                            console.log("Jenis Bencana yang dipilih:", selectedJenisBencana.nama_jenis_bencana);
                            setSelectedJenisBencana(selectedJenisBencana.nama_jenis_bencana || "");
                            setJenisBencana(value);
                        }
                    }
                }}
            >
                <SelectTrigger>
                    <SelectValue placeholder="Pilih Jenis Bencana">
                        {selectedJenisBencana || 'Pilih Jenis Bencana'}
                    </SelectValue>
                </SelectTrigger>
                <SelectContent className='z-99999'>
                    {getJenisBencana.map((jenisBencana: any, index: any) => (
                        <SelectItem key={index} value={jenisBencana.id_jenis_bencana}>
                            {jenisBencana.nama_jenis_bencana}
                        </SelectItem>
                    ))}
                </SelectContent>
        </Select>
    )
}

export default JenisBencanaSelectUI;


