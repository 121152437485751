import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setWilayah } from '../../app/wilayah/wilayahSlice';
import { useEffect } from 'react';
import { menuIdSlice } from '@/app/menu/menuIdSlice';

const WilayahSelection = () => {
	const wilayah = useSelector((state: RootState) => state.wilayah.value);
	const bidang = useSelector((state: RootState) => state.bidang.value);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			menuIdSlice.actions.setMenuId({
				selectedBidang: bidang,
				selectedWilayah: wilayah,
			}),
		);
	}, [dispatch, bidang, wilayah]);

	useEffect(() => {
		if (privilege === 1 || privilege === 3) {
			dispatch(setWilayah('kabupaten'));
		}
		if (privilege === 2) {
			dispatch(setWilayah('provinsi'));
		}
	}, [privilege]);

	const handleWilayahChange = (wilayah: string) => {
		if ([4, 5, 6, 7, 8, 10].includes(privilege)) {
			dispatch(setWilayah(wilayah));
		}
	};

	return (
		<div className="flex w-full flex-grow justify-end text-lg font-bold text-[#364878]">
			<span className="my-auto mr-1 align-bottom text-lg font-bold dark:text-white">
				Wilayah
			</span>
			<div className="inline-flex items-center gap-1 rounded-md border-stroke bg-white p-1.5 pl-2 shadow-default dark:bg-boxdark-2">
				<button
					// className={`rounded-md p-2 ${wilayah === 'provinsi' ? 'bg-slate-200 dark:bg-meta-4' : ''
					// 	} text-xs font-semibold text-[#364878] hover:bg-slate-300 hover:shadow-card dark:text-white dark:hover:bg-meta-4`}
					className={`rounded-md p-2 text-xs font-semibold ${wilayah === 'provinsi' ? 'bg-[#fab23d] text-[#364878]' : 'dark:text-white'}`}
					onClick={() => handleWilayahChange('provinsi')}
					disabled={privilege === 1}
				>
					Provinsi
				</button>
				<button
					// className={`rounded-md p-2 ${wilayah === 'kabupaten' ? 'bg-slate-200 dark:bg-meta-4' : ''
					// 	} text-xs font-semibold text-[#364878] hover:bg-slate-300 hover:shadow-card dark:bg-boxdark dark:text-white dark:hover:bg-meta-4`}
					className={`rounded-md p-2 text-xs font-semibold ${wilayah === 'kabupaten' ? 'bg-[#fab23d] text-[#364878]' : 'dark:text-white'} `}
					onClick={() => handleWilayahChange('kabupaten')}
					disabled={privilege === 2}
				>
					Kabupaten/Kota
				</button>
			</div>
		</div>
	);
};
export default WilayahSelection;
