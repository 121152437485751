import * as React from 'react';
import { Check, ChevronDown } from 'lucide-react';
import { cn } from '@/libs/utils';
import { Button } from '@/components/ui/Button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { setKd_provinsi_dux, setNama_provinsi } from '@/app/lokasi/lokasiSlice';
import { useGetProvinsi } from '@/hooks/wilayah/useLokasi'; // Import the hook
import { provinsi } from '@/types/lokasi';

export function SelectProvGlobal() {
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	// const [value, setValue] = React.useState('');
	const value = useSelector((state: RootState) => state.location.nama_provinsi);

	const { getProvinsi, isLoading } = useGetProvinsi(); // Call the hook

	const handleSelect = (value: string) => {
		const selectedProvinsi = getProvinsi.find(
			(provinsi: provinsi) => provinsi.nama_provinsi === value,
		);
		if (selectedProvinsi) {
			dispatch(setNama_provinsi(selectedProvinsi.nama_provinsi));
			dispatch(setKd_provinsi_dux(selectedProvinsi.kd_provinsi));
		}
	};

	if (isLoading) {
		return <p>Loading...</p>;
	}

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="w-full justify-between truncate border-slate-500 dark:bg-boxdark-2 dark:hover:bg-boxdark"
				>
					<span className="truncate">
						{value
							? getProvinsi
									.find(
										(provinsi: provinsi) => provinsi.nama_provinsi === value,
									)
									?.nama_provinsi.replace('Provinsi ', '')
							: 'Pilih provinsi...'}
					</span>
					<ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				align="start"
				className="z-999 w-[200px] border border-slate-500 p-0"
			>
				<Command>
					<CommandInput placeholder="Cari provinsi..." />
					<CommandList>
						<CommandEmpty>No provinsi found.</CommandEmpty>
						<CommandGroup>
							{getProvinsi.map((provinsi: provinsi) => (
								<CommandItem
									key={provinsi.kd_provinsi}
									value={provinsi.nama_provinsi.toString()}
									onSelect={(currentValue) => {
										handleSelect(currentValue); // Call handleSelect here
										setOpen(false);
									}}
								>
									<Check
										className={cn(
											'mr-2 h-4 w-4',
											value === provinsi.kd_provinsi.toString()
												? 'opacity-100'
												: 'opacity-0',
										)}
									/>
									{provinsi.nama_provinsi}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
