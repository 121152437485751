import { RootState } from "@/app/store";
import { CompLoader } from "@/common/Loader";
import FormBantuanKeuangan from "@/components/Forms/FormBantuanKeuangan";
import FormTotalAPBD from "@/components/Forms/FormTotalAPBD";
import JudulHeader from "@/components/Header/JudulHeader";
import { Button } from "@/components/ui/Button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { useGetAPBDPerProv } from "@/hooks/useAPBDPerProv";
import { useEffect, useRef, useState } from "react"
import { FiArrowLeft, FiDownload, FiEdit, FiPlusCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import TableBantuanKeuanganReal from "../../TableBantuanKeuanganReal";
import FormBantuanKeuanganEdit from "@/components/Forms/FormBantuanKeuanganEdit";
import { useGetBantuanKeuangan } from "@/hooks/useBantuanKeuangan";
import { formatRupiah } from "@/libs/utils";
import { utils, writeFile } from "xlsx-js-style";

type TableDataBantuanKeuanganProps = {
  tabId: number;
  handleTabChange: (e: number) => void;
}

const TableDataBantuanKeuanganAL = ({ tabId, handleTabChange }: TableDataBantuanKeuanganProps) => {
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const menu_id = useSelector((state: RootState) => state.menuId.value)
  const bidang = useSelector((state: RootState) => state.bidang.value)
  const wilayah = useSelector((state: RootState) => state.wilayah.value)
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
  const nama_provinsi = useSelector((state: RootState) => state.auth.wilayah.nama_provinsi)
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
  const cardRef = useRef(null)

  const { getAPBDPerProv, isLoading: loadAPBDProv } = useGetAPBDPerProv(Number(tahun), kd_provinsi, menu_id)
  const { getBantuanKeuangan, isLoading: isLoadGetBantuanKeuangan } = useGetBantuanKeuangan(Number(tahun), kd_provinsi, menu_id, bidang, wilayah, privilege)

  const [dataAPBD, setDataAPBD] = useState({
    id_bk_apbd_provinsi: 0,
    total_apbd_prov: 0
  })
  const [showForm, setShowForm] = useState("Lihat Data")
  const [isEditAPBD, setIsEditAPBD] = useState(false)
  const [idBantuanKeuangan, setIdBantuanKeuangan] = useState(0)
  const [total, setTotal] = useState(0)
  const [capaian, setCapaian] = useState(0)

  const handleDownload = () => {
    const transformedData = getBantuanKeuangan.map((item: any, index: any) => ([
      { v: index + 1, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.tahun_pelaporan, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: formatRupiah(Number(item.anggaran_bk)), t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: formatRupiah(Number(item.bkApbdProvinsi.total_apbd_prov)), t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.jenis_bantuan_keuangan, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.komponen.komponen_name, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.satuan.satuan_name, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.volume, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
    ]));

    const header = [
      { v: "No", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Tahun", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Bantuan Keuangan\n(Rupiah)", t: "s", s: { alignment: { wrapText: true, horizontal: "center", vertical: "center" } } },
    ];

    const totalRow = [
      { v: formatRupiah(total), t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: formatRupiah(dataAPBD.total_apbd_prov), t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
    ];

    let wb = utils.book_new(),
      ws = utils.aoa_to_sheet([]);

    utils.sheet_add_aoa(ws, [[{ v: "BANTUAN KEUANGAN", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A1" })
    utils.sheet_add_aoa(ws, [[{ v: "SPM PERKERJAAN UMUM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A2" })
    utils.sheet_add_aoa(ws, [[{ v: `    PEMENUHAN KEBUTUHAN AIR MINUM SEHARI-HARI ${Number(tahun)}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A3" })

    utils.sheet_add_aoa(ws, [header], { origin: "A4" })
    utils.sheet_add_aoa(ws, [[{ v: "Anggaran", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "C5" })
    utils.sheet_add_aoa(ws, [[{ v: "Total Alokasi APBD Provinsi untuk Bidang Air Minum", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "D5" })

    utils.sheet_add_aoa(ws, [[{ v: "Jenis Bantuan Keuangan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "E4" })
    utils.sheet_add_aoa(ws, [[{ v: "Output", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "F4" })
    utils.sheet_add_aoa(ws, [[{ v: "Komponen", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "F5" })
    utils.sheet_add_aoa(ws, [[{ v: "Satuan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "G5" })
    utils.sheet_add_aoa(ws, [[{ v: "Volume", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "H5" })
    utils.sheet_add_aoa(ws, transformedData, { origin: "A6" })

    utils.sheet_add_aoa(ws, [[{ v: "Total Anggaran", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 6}` })
    utils.sheet_add_aoa(ws, [totalRow], { origin: `C${transformedData.length + 6}` })

    utils.sheet_add_aoa(ws, [[{ v: "Capaian Kinerja Bantuan Keuangan Provinsi (%)", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 7}` })
    utils.sheet_add_aoa(ws, [[{ v: `${capaian.toFixed(2)}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `D${transformedData.length + 7}` })

    ws['!cols'] = [
      { wpx: 50 },
      { wpx: 100 },
      { wpx: 120 },
      { wpx: 320 },
      { wpx: 205 },
      { wpx: 60 },
      { wpx: 60 },
      { wpx: 60 },
    ]

    ws['!merges'] = [
      // COLSPANS
      utils.decode_range("A1:H1"),
      utils.decode_range("A2:H2"),
      utils.decode_range("A3:H3"),
      utils.decode_range("C4:D4"),
      utils.decode_range("F4:H4"),
      utils.decode_range(`A${transformedData.length + 6}:B${transformedData.length + 6}`),
      utils.decode_range(`A${transformedData.length + 7}:C${transformedData.length + 7}`),

      // ROWSPANS
      utils.decode_range("A4:A5"),
      utils.decode_range("B4:B5"),
      utils.decode_range("E4:E5"),
    ]
    utils.book_append_sheet(wb, ws, "Bantuan Keuangan")
    writeFile(wb, `Data Bantuan Keuangan ${nama_provinsi}  - ${tahun}.xlsx`)
  }

  useEffect(() => {
    if (!isLoadGetBantuanKeuangan && !loadAPBDProv) {
      if (getAPBDPerProv.length !== 0) {
        const apbd = getAPBDPerProv.find((item: any) => item.tahun_pelaporan === Number(tahun) && item.kd_provinsi === kd_provinsi)
        const totalAnggaran = getBantuanKeuangan.reduce((acc: any, curr: any) => acc + Number(curr["anggaran_bk"]), 0)

        const capaian = Math.min((totalAnggaran / Number(apbd.total_apbd_prov)) * 100)

        setTotal(totalAnggaran)
        setCapaian(capaian)
      }
    }
  }, [loadAPBDProv, getAPBDPerProv, getBantuanKeuangan, isLoadGetBantuanKeuangan])

  useEffect(() => {
    if (!loadAPBDProv) {
      if (getAPBDPerProv.length !== 0) {
        const apbd = getAPBDPerProv.find((item: any) => item.tahun_pelaporan === Number(tahun) && item.kd_provinsi === kd_provinsi)
        setDataAPBD({
          id_bk_apbd_provinsi: apbd.id_bk_apbd_provinsi,
          total_apbd_prov: Number(apbd.total_apbd_prov)
        })
      }
    }
  }, [loadAPBDProv, getAPBDPerProv])

  if (loadAPBDProv || isLoadGetBantuanKeuangan) return <CompLoader />
  if (loadAPBDProv) return <CompLoader />

  return (
    <>
      {privilege !== 4 && privilege !== 10 && privilege !== 8 &&
        <div className='flex justify-end pb-4'>
          <div className="flex gap-4">
            {getBantuanKeuangan.length !== 0 &&
              <Button
                className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                onClick={() => handleDownload()}
              >
                <FiDownload />
                Download Data
              </Button>
            }
            {showForm !== "Lihat Data" &&
              <Button
                className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                onClick={() => {
                  setShowForm("Lihat Data")
                  setIsEditAPBD(false)
                }}
              >
                <FiArrowLeft />
                Kembali
              </Button>
            }
            {showForm.includes("APBD") || showForm.includes("Bantuan Keuangan") ?
              <></>
              :
              dataAPBD.total_apbd_prov === 0 ?
                <Button
                  className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                  onClick={() => {
                    setShowForm("Masukan Total Alokasi APBD");
                    setIsEditAPBD(false)
                  }}
                >
                  <FiPlusCircle />
                  Masukan Total Alokasi APBD
                </Button>
                :
                <Button
                  className="inline-flex items-center justify-center rounded-lg gap-2 bg-[#fab23d] px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                  onClick={() => {
                    setShowForm("Masukan Total Alokasi APBD");
                    setIsEditAPBD(true)
                  }}
                >
                  <FiEdit />
                  Edit Total Alokasi APBD
                </Button>
            }
            {showForm.includes("APBD") ?
              <></>
              :
              showForm === "Masukan Bantuan Keuangan" ?
                <></>
                :
                <Button
                  className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                  onClick={() => setShowForm("Masukan Bantuan Keuangan")}
                >
                  <FiPlusCircle />
                  Masukan Bantuan Keuangan
                </Button>
            }
          </div>
        </div>
      }
      <div ref={cardRef} className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col flex-1 overflow-hidden">
        <div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 sm:grid-cols-3">
          <div
            className={`py-4 text-center ${tabId === 1 ? 'bg-[#fab23d]' : 'bg-[#364878]'
              } cursor-pointer rounded-md flex flex-col items-center justify-center text-white hover:bg-graydark`}
            onClick={() => handleTabChange(1)}
          >
            <p>Capaian SPALD</p>
            <p>Regional</p>
          </div>
          <div
            className={`py-4 text-center ${tabId === 2 ? 'bg-[#fab23d]' : 'bg-[#364878]'
              } cursor-pointer rounded-md text-white hover:bg-graydark`}
            onClick={() => handleTabChange(2)}
          >
            Rekapitulasi Capaian SPM <br />
            Kabupaten / Kota
          </div>
          <div
            className={`py-4 text-center ${tabId === 3 ? 'bg-[#fab23d]' : 'bg-[#364878]'
              } cursor-pointer rounded-md text-white flex items-center justify-center hover:bg-graydark`}
            onClick={() => handleTabChange(3)}
          >
            <p>Bantuan Keuangan</p>
          </div>
        </div>
        {showForm === "Lihat Data" &&
          <ScrollArea className="h-full relative p-4">
            <JudulHeader cardRef={cardRef} isLoading={false} minusWidth={32}>
              BANTUAN KEUANGAN <br />
              SPM PERKERJAAN UMUM <br />
              PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK TAHUN {Number(tahun)}
            </JudulHeader>
            <TableBantuanKeuanganReal getBantuanKeuangan={getBantuanKeuangan} isLoadGetBantuanKeuangan={isLoadGetBantuanKeuangan} setShowForm={setShowForm} setIdBantuanKeuangan={setIdBantuanKeuangan} />
            <ScrollBar orientation="horizontal" />
          </ScrollArea>}
        {showForm === "Masukan Total Alokasi APBD" &&
          <FormTotalAPBD isEdit={isEditAPBD} dataAPBD={dataAPBD} setShowForm={setShowForm} />
        }
        {showForm === "Masukan Bantuan Keuangan" &&
          <FormBantuanKeuangan APBD={dataAPBD} setShowForm={setShowForm} />
        }
        {showForm === "Edit Bantuan Keuangan" &&
          <FormBantuanKeuanganEdit setShowForm={setShowForm} idBantuanKeuangan={idBantuanKeuangan} />
        }
      </div>
    </>
  )
}

export default TableDataBantuanKeuanganAL