import React, { useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface JenisProgramRelokasiSelectUIProps {
  getJenisProgramRelokasi: any[];
  setJenisProgramRelokasi: (id_jenis_relokasi: string) => void;
}


const JenisProgramRelokasiSelectUI: React.FC<JenisProgramRelokasiSelectUIProps> = ({
  getJenisProgramRelokasi,
  setJenisProgramRelokasi
}) => {
  const [selectedJenisProgramRelokasi, setSelectedJenisProgramRelokasi] = useState('');

  return (
    <Select
      onValueChange={(value) => {
        if (value !== '') {
          const selectedJenisProgramRelokasi = getJenisProgramRelokasi.find((jenisProgramRelokasi: any) => jenisProgramRelokasi.id_jenis_relokasi === value);
          if (selectedJenisProgramRelokasi) {
            setSelectedJenisProgramRelokasi(selectedJenisProgramRelokasi.nama_jenis_relokasi || "");
            setJenisProgramRelokasi(value);
          }
        }
      }}
    >
      <SelectTrigger>
        <SelectValue placeholder="Pilih Jenis Program Relokasi">
          {selectedJenisProgramRelokasi || 'Pilih Jenis Program Relokasi'}
        </SelectValue>
      </SelectTrigger>
      <SelectContent className='z-99999'>
        {getJenisProgramRelokasi.map((jenisProgramRelokasi: any, index: any) => (
          <SelectItem key={index} value={jenisProgramRelokasi.id_jenis_relokasi}>
            {jenisProgramRelokasi.nama_jenis_relokasi}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default JenisProgramRelokasiSelectUI;


