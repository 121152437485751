import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useColorMode from '../../hooks/useColorMode';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { setOtp } from '@/app/password/resetPasswordSlice';
import { usePostOtpForCheck } from '@/hooks/forgotPassword/useCheckOtp';
import { toast } from 'sonner';
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from '@/components/ui/input-otp';

const CekOtp: React.FC = () => {
	const [colorMode] = useColorMode();
	const isDark = colorMode === 'dark'; // Check if the current color mode is dark
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const otp = useSelector((state: RootState) => state.resetPassword.otp); // Initialize an array of 6 empty strings
	// const username = useSelector(
	// 	(state: RootState) => state.resetPassword.username,
	// );
	// console.log(username);
	const { mutate } = usePostOtpForCheck();

	// const [otpNumber, setOtpNumber] = useState('');
	console.log(otp);
	// const handleChange = (element: any, index: any) => {
	// 	if (isNaN(element.value)) return; // Return if the input is not a number
	// 	const newOtp = [...otp]; // Create a new array that's a copy of the otp array
	// 	newOtp[index] = element.value; // Update the new array at the index with the input value
	// 	dispatch(setOtp(newOtp)); // Dispatch the setOtp action with the new array
	// 	// Focus on the next input field if the current input field has a value
	// 	if (element.nextSibling) {
	// 		element.nextSibling.focus();
	// 	}
	// };
	const handleSubmit = (e: any) => {
		e.preventDefault();
		// Convert the otp array to a number
		mutate(
			{ otp: Number(otp) },
			{
				onSuccess: () => {
					// console.log(data);
					toast.success('OTP berhasil diverifikasi');
					navigate('/auth/reset-password');
				},
				onError: (error: any) => {
					// Handle the error here
					if (error.message === 'OTP has expired') {
						toast.error(
							'OTP kadaluarsa, silahkan kirim ulang OTP ke email anda',
						);
					} else {
						toast.error('OTP yang anda masukkan salah');
					}
				},
			},
		);
		// Perform your action with the OTP here
		// console.log(otpNumber);  This will log the OTP to the console
	};

	return (
		<div
			className={`mx-auto flex h-screen flex-col content-center items-center justify-center bg-[#364878] ${
				isDark ? 'dark-mode-class' : 'light-mode-class'
			}`}
		>
			<div className="w-100 self-center rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
				<div className="flex flex-wrap items-center">
					<div className="w-full border-stroke drop-shadow-lg dark:border-strokedark">
						<div className="m-auto w-full p-4 sm:p-5 xl:p-7">
							<h2 className="mb-0 text-2xl font-bold text-[#364878] dark:text-white sm:text-title-xl2">
								CEK OTP
							</h2>
							<span className="mb-9 block font-medium dark:text-white">
								Silahkan Masukkan OTP yang anda dapatkan dari email
							</span>
							<div className="flex justify-center">
								<form action="" onSubmit={handleSubmit}>
									<label className="text-slate mb-2.5 block font-medium dark:text-white">
										OTP:
									</label>
									<div className="mb-10">
										<InputOTP
											maxLength={6}
											className="m-auto"
											value={otp}
											onChange={(value) => dispatch(setOtp(value))}
										>
											<InputOTPGroup>
												<InputOTPSlot index={0} />
												<InputOTPSlot index={1} />
											</InputOTPGroup>
											<InputOTPSeparator />
											<InputOTPGroup>
												<InputOTPSlot index={2} />
												<InputOTPSlot index={3} />
											</InputOTPGroup>
											<InputOTPSeparator />
											<InputOTPGroup>
												<InputOTPSlot index={4} />
												<InputOTPSlot index={5} />
											</InputOTPGroup>
										</InputOTP>
									</div>
									<div className="mb-5 flex justify-center">
										<button
											type="submit"
											className="w-35 cursor-pointer rounded-lg border border-primary bg-[#3C6AE0] p-4 text-white transition hover:bg-opacity-90"
										>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CekOtp;
