import { RootState } from "@/app/store";
import { CompLoader } from "@/common/Loader";
import { Button } from "@/components/ui/Button"
import DesaSelectUI from "@/components/ui/SelectDesaUI";
import KecamatanSelectUI from "@/components/ui/SelectKecamatanUI";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "@/components/ui/select";
import { usePostDataCapaianALProv } from "@/hooks/airLimbah/useDataCapaianAL";
import { useGetDesa } from "@/hooks/wilayah/useDesa";
import { useGetKecamatan } from "@/hooks/wilayah/useKecamatan";
import { useGetKabKota } from "@/hooks/wilayah/useLokasi";
import { SelectValue } from "@radix-ui/react-select";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FiArrowLeft } from "react-icons/fi"
import { useSelector } from "react-redux";
import { toast } from "sonner";

type FormDataCapaianALProvinsiProps = {
  setInputMode: any
}

const FormDataCapaianALProvinsi = ({ setInputMode }: FormDataCapaianALProvinsiProps) => {
  const cardRef = useRef(null)
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
  const menu_id = useSelector((state: RootState) => state.menuId.value)
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const queryClient = useQueryClient()

  const [kd_kabkota, setKd_kabkota] = useState("0")
  const [kd_kec, setKd_kec] = useState('0');
  const [kd_desa, setKd_desa] = useState('0');

  const { getKabKota, isLoading: loadKabkota } = useGetKabKota(kd_provinsi);
  const { getKecamatan, isLoading: isLoadingKec } = useGetKecamatan(kd_provinsi, Number(kd_kabkota));
  const { getDesa, isLoading: isLoadingDes } = useGetDesa(kd_provinsi, Number(kd_kabkota), Number(kd_kec));

  const { asyncPostCapaianALProv, isPending: postPendingCapaianAL } = usePostDataCapaianALProv()

  const handleSelectKab = (value: string) => {
    setKd_kabkota(value)
  }

  const { register, setValue, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      menu_id: menu_id,
      tahun_pelaporan: Number(tahun),
      nama_spald_regional: "-",
      kd_provinsi: Number(kd_provinsi),
      kd_kabkota: 0,
      kd_kec: 0,
      kd_desa: 0,
      tgt_total: 0,
      real_sudah_terlayani: 0,
      real_belum_terlayani: 0
    }
  })

  useEffect(() => {
    setValue("kd_kabkota", Number(kd_kabkota))
    setValue("kd_kec", Number(kd_kec))
    setValue("kd_desa", Number(kd_desa))
  }, [kd_kabkota, kd_kec, kd_desa])

  const onSubmit = async (data: any) => {
    try {
      await asyncPostCapaianALProv(data)
      toast.success('Berhasil menambahkan data capaian Air Limbah');
      reset()
      setInputMode(false)
      queryClient.invalidateQueries({ queryKey: ["capaian-al-provinsi"] })
    } catch (error) {
      toast.error('Gagal menambahkan data capaian Air Limbah');
    }
  }

  if (postPendingCapaianAL || loadKabkota) return <CompLoader />

  return (
    <>
      <div className='flex justify-end pb-4'>
        <div className="flex gap-4">
          <Button
            className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
            onClick={() => setInputMode(false)}
          >
            <FiArrowLeft />
            Kembali
          </Button>
        </div>
      </div>
      <div ref={cardRef} className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col flex-1 overflow-hidden">
        <div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
          <h3 className="text-2xl font-medium text-[#364878] dark:text-white">
            Form Tambah Data Capaian Kumulatif Air Limbah Provinsi
          </h3>
        </div>
        <ScrollArea className="h-full">
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Nama SPALD Regional" className="w-1/2 text-xl text-black">
              Nama SPALD Regional
            </Label>
            <Input
              id="Nama SPALD Regional"
              min="0"
              {...register("nama_spald_regional")}
              placeholder='Masukkan Nama SPALD Regional' />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Nama SPALD Regional" className="w-1/2 text-xl text-black">
              Kabupaten / Kota
            </Label>
            <Select
              onValueChange={handleSelectKab}
              //@ts-ignore
              value={Number(kd_kabkota) === 0 ? "" : Number(kd_kabkota)}
            >
              <SelectTrigger className="">
                <SelectValue placeholder="Pilih Kabupaten" />
              </SelectTrigger>
              <SelectContent className="z-999">
                {getKabKota.map((kabkota: any, index: any) => (
                  <SelectGroup key={index}>
                    <SelectItem value={kabkota.kd_kabkota}>
                      {kabkota.nama_kabkota}
                    </SelectItem>
                  </SelectGroup>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Kecamatan" className="w-1/2 text-xl text-black">
              Kecamatan
            </Label>
            <KecamatanSelectUI
              getKecamatan={isLoadingKec ? [] : getKecamatan}
              setKd_kec={setKd_kec}
            />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Desa" className="w-1/2 text-xl text-black">
              Desa
            </Label>
            <DesaSelectUI
              getDesa={isLoadingDes ? [] : getDesa}
              setKd_desa={setKd_desa}
            />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Target Total Rumah" className="w-1/2 text-xl text-black">
              Target Total Rumah
            </Label>
            <Input
              id="Target Total Rumah"
              type="number"
              min="0"
              {...register("tgt_total", { valueAsNumber: true })}
              placeholder='Masukkan Target Total Rumah' />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Realisasi Sudah Terlayani" className="w-1/2 text-xl text-black">
              Realisasi Sudah Terlayani
            </Label>
            <Input
              id="Realisasi Sudah Terlayani"
              type="number"
              min="0"
              {...register("real_sudah_terlayani", { valueAsNumber: true })}
              placeholder='Masukkan Realisasi Sudah Terlayani' />
          </div>
          <div className="flex items-center p-6 gap-4 border-b border-slate-200">
            <Label htmlFor="Realisasi Belum Terlayani" className="w-1/2 text-xl text-black">
              Realisasi Belum Terlayani
            </Label>
            <Input
              id="Realisasi Belum Terlayani"
              type="number"
              min="0"
              {...register("real_belum_terlayani", { valueAsNumber: true })}
              placeholder='Masukkan Realisasi Belum Terlayani' />
          </div>
          <div className="flex justify-between gap-5 p-4 mt-5">
            <div className="w-full">
              <button
                className="min-w-full inline-flex items-center justify-center rounded-md border border-meta-3 py-4 px-10 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
                onClick={() => handleSubmit(onSubmit)()}
              >
                Simpan Data
              </button>
            </div>
          </div>
        </ScrollArea>
      </div>
    </>
  )
}

export default FormDataCapaianALProvinsi