import { useState } from 'react';
import { API } from '../../libs/API';

const useExportAM = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	const exportAMTemplate = async (kd_provinsi: number, kd_kabkota: number) => {
		setIsLoading(true);
		setError(null);

		try {
			const response = await API.get(
				`/export-am/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
				{
					responseType: 'blob',
				},
			);

			const contentDisposition = response.headers['content-disposition'];
			console.log(response.headers);
			const fileNameMatch =
				contentDisposition &&
				contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
			const fileName = fileNameMatch
				? fileNameMatch[1].replace(/['"]/g, '')
				: 'download.xlsx';

			const blob = new Blob([response.data], {
				type: response.headers['content-type'],
			});
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = fileName; // Change the file name and extension as needed
			document.body.appendChild(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(url);
		} catch (err) {
			setError(err as Error);
		} finally {
			setIsLoading(false);
		}
	};

	return { exportAMTemplate, isLoading, error };
};

export default useExportAM;
