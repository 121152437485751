import { RootState } from "@/app/store"
import { CompLoader } from "@/common/Loader"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Select, SelectContent, SelectGroup, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useKomponen } from "@/hooks/useKomponen"
import { useSatuan } from "@/hooks/useSatuan"
import { formatRupiah } from "@/libs/utils"
import { SelectItem } from "@radix-ui/react-select"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button } from "../ui/Button"
import { useForm } from "react-hook-form"
import { usePostBantuanKeuangan } from "@/hooks/useBantuanKeuangan"
import { toast } from "sonner"
import { useQueryClient } from "@tanstack/react-query"

type FormBantuanKeuanganProps = {
  APBD: any;
  setShowForm: any
}

const FormBantuanKeuangan = ({ APBD, setShowForm }: FormBantuanKeuanganProps) => {
  const menu_id = useSelector((state: RootState) => state.menuId.value)
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const queryClient = useQueryClient()

  const [komponen, setKomponen] = useState("0")
  const [namaKomponen, setNamaKomponen] = useState("")
  const [satuan, setSatuan] = useState("0")
  const [namaSatuan, setNamaSatuan] = useState("")
  const [anggaran, setAnggaran] = useState(0)
  const [displayAnggaran, setDisplayAnggaran] = useState("")
  const [displayTotalAPBD, setDisplayTotalAPBD] = useState(formatRupiah(APBD.total_apbd_prov))

  const { getKomponen, isLoading: isLoadingKomponen } = useKomponen(menu_id)
  const { getSatuan, isLoading: isLoadingSatuan } = useSatuan(Number(komponen))
  const { postBantuanKeuangan } = usePostBantuanKeuangan()

  const handleKomponenChange = (val: any) => {
    setKomponen(val)
    const komponen = getKomponen.find((item: any) => item.id_komponen === val)
    setNamaKomponen(komponen.komponen_name)
  }

  const handleSatuanChange = (val: any) => {
    setSatuan(val)
    const satuan = getSatuan.find((item: any) => item.id_satuan === val)
    setNamaSatuan(satuan.satuan_name)
  }

  const handleAnggaranChange = (e: any) => {
    const value = e.target.value;
    const numericValue = parseFloat(value.replace(/\D/g, ''));
    setAnggaran(numericValue);
    if (isNaN(numericValue)) {
      setDisplayAnggaran("Rp 0");
    } else {
      setDisplayAnggaran(formatRupiah(numericValue));
    }
  }

  const { register, setValue, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      tahun_pelaporan: Number(tahun),
      anggaran_bk: 0,
      menu_id: 0,
      jenis_bantuan_keuangan: "",
      kd_provinsi: 0,
      bk_apbd_prov_id: 0,
      komponen_id: 0,
      volume: 0,
      satuan_id: 0
    }
  })

  const onSubmit = async (data: any) => {
    try {
      await postBantuanKeuangan(data)
      reset()
      queryClient.invalidateQueries({ queryKey: ["bantuan-keuangan"] })
      toast.success("Berhasil menambahkan Bantuan Keuangan")
      setShowForm("Lihat Data")
    } catch {
      toast.error("Gagal menambahkan Bantuan Keuangan")
    }
  }

  useEffect(() => {
    setValue("menu_id", menu_id)
    setValue("bk_apbd_prov_id", APBD.id_bk_apbd_provinsi)
    setValue("total_apbd_prov", APBD.total_apbd_prov)
    setValue("kd_provinsi", kd_provinsi)
  }, [])

  useEffect(() => {
    setValue("anggaran_bk", anggaran)
    setValue("komponen_id", komponen)
    setValue("satuan_id", satuan)
  }, [anggaran, komponen, satuan, APBD])

  if (isLoadingKomponen) return <CompLoader />

  return (
    <ScrollArea className="h-full">
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Tahun" className="w-1/2 text-xl text-black">
          Tahun
        </Label>
        <Input
          id="Tahun"
          type="number"
          min="0"
          {...register("tahun_pelaporan", { valueAsNumber: true })}
          placeholder='Masukkan Tahun' />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Anggaran" className="w-1/2 text-xl text-black">
          Anggaran
        </Label>
        <Input
          id="Anggaran"
          min="0"
          value={displayAnggaran}
          onChange={handleAnggaranChange}
          placeholder='Masukkan Anggaran' />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Total Alokasi APBD" className="w-1/2 text-xl text-black">
          Total Alokasi APBD Provinsi
        </Label>
        <Input
          id="Total Alokasi APBD"
          min="0"
          className="disabled:opacity-100 font-black"
          disabled
          value={displayTotalAPBD} />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Jenis Bantuan Keuangan" className="w-1/2 text-xl text-black">
          Jenis Bantuan Keuangan
        </Label>
        <Input
          id="Jenis Bantuan Keuangan"
          {...register("jenis_bantuan_keuangan")}
          placeholder='Masukkan Jenis Bantuan Keuangan' />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Komponen" className="w-1/2 text-xl text-black">
          Komponen
        </Label>
        <Select onValueChange={handleKomponenChange}>
          <SelectTrigger>
            <SelectValue placeholder="Pilih Komponen">{namaKomponen}</SelectValue>
          </SelectTrigger>
          <SelectContent className="z-999">
            {getKomponen.map((item: any, index: any) => (
              <SelectGroup key={index}>
                <SelectItem className="cursor-pointer p-1" value={item.id_komponen}>
                  {item.komponen_name}
                </SelectItem>
              </SelectGroup>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        {isLoadingSatuan ? <></> :
          <>
            <Label htmlFor="Satuan" className="w-1/2 text-xl text-black">
              Satuan
            </Label>
            <Select onValueChange={handleSatuanChange}>
              <SelectTrigger>
                <SelectValue placeholder="Pilih Satuan">{namaSatuan}</SelectValue>
              </SelectTrigger>
              <SelectContent className="z-999">
                {getSatuan.map((item: any, index: any) => (
                  <SelectGroup key={index}>
                    <SelectItem className="cursor-pointer p-1" value={item.id_satuan}>
                      {item.satuan_name}
                    </SelectItem>
                  </SelectGroup>
                ))}
              </SelectContent>
            </Select>
          </>
        }
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Volume" className="w-1/2 text-xl text-black">
          Volume
        </Label>
        <Input
          id="Volume"
          type="number"
          min={0}
          {...register("volume", { valueAsNumber: true })}
          placeholder='Masukkan Volume' />
      </div>
      <div className="mt-10 mb-8 mx-4">
        <Button className="text-primary hover:bg-transparent text-xl h-[60px] bg-transparent font-bold border-2 w-full border-primary" onClick={() => handleSubmit(onSubmit)()}>
          Simpan Bantuan Keuangan
        </Button>
      </div>
    </ScrollArea>
  )
}

export default FormBantuanKeuangan