import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useGetNomen } from '@/hooks/useNomenklatur';
import { useDeleteNewRencanaPemenuhanKomponen, useEditNewRencanaPemenuhan, useEditNewRencanaPemenuhanKomponen, useGetByIdNewRencanaPemenuhan, usePostNewRencanaPemenuhanKomponen } from '@/hooks/rencanaPelaksanaan/useRencanaPemenuhan';
import { useSumberAnggaran } from '@/hooks/useKomponen';
import { formatRupiah } from '@/libs/utils';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import { CompLoader } from '@/common/Loader';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { Button } from '@/components/ui/Button';
import { FiPlus, FiX } from 'react-icons/fi';
import KomponenSelect from './Komponen/KomponenSelect';
import SatuanSelect from './Komponen/SatuanSelect';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog';

type FormValues = {
  id_rpp: number
  tahun_rencana: number
  kd_provinsi: number
  kd_kabkota: number
  kd_kec: number
  kd_desa: number
  nomenklatur_id: number
  jenis_kegiatan: string
  nama_kegiatan: string
  sumber_anggaran_id: number
  rencana_anggaran: number
}

const FormRencanaPemenuhanEdit = ({ id, setShowForm }: { id: number, setShowForm: any }) => {
  const bidang = useSelector((state: RootState) => state.bidang.value);
  const menu_id = useSelector((state: RootState) => state.menuId.value);
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)

  const [kumpulanKomponen, setKumpulanKomponen] = useState<any>([])
  const [kumpulanKomponenDelete, setKumpulanKomponenDelete] = useState<any>([])

  const [currentKomponen, setCurrentKomponen] = useState(0)
  const [uploadData, setUploadData] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [selectedNomen, setSelectedNomen] = useState<any>(null)

  const { getDetailRencana, isLoading: loadDetailRencana } = useGetByIdNewRencanaPemenuhan(id);
  const { asyncEditRencanaPemenuhan } = useEditNewRencanaPemenuhan()
  const { asyncEditRencanaPemenuhanKomponen } = useEditNewRencanaPemenuhanKomponen()
  const { postKomponen } = usePostNewRencanaPemenuhanKomponen(id)
  const { deleteNewRencanaPemenuhanKomponen } = useDeleteNewRencanaPemenuhanKomponen()

  const { reset, watch, setValue, handleSubmit } = useForm<FormValues>()

  const { getKabKota, isLoading: loadKabKota } = useGetKabKota(watch("kd_provinsi"))
  const { getKecamatan, isLoading: loadKec } = useGetKecamatan(
    watch("kd_provinsi"),
    watch("kd_kabkota")
  );
  const {
    getDesa,
    isLoading: loadDesa,
  } = useGetDesa(watch("kd_provinsi"), watch("kd_kabkota"), watch("kd_kec"));

  const { getNomen, isLoading: loadNomen } = useGetNomen(menu_id);
  const { getSumberAnggaran, isLoading: loadSumber } = useSumberAnggaran();

  const handleAnggaranChange = (e: any) => {
    const value = e.target.value;
    const numericValue = parseFloat(value.replace(/\D/g, ''));
    setValue("rencana_anggaran", numericValue)
  };

  const handleTambahKomponen = () => {
    setCurrentKomponen(currentKomponen + 1)
    setKumpulanKomponen((prevState: any) => ([...prevState, {
      komponen_id: 0,
      satuan_id: 0,
      volume: 0
    }]))
  }

  const handleHapusKomponen = (index: any, id: any) => {
    setKumpulanKomponen((prevState: any) => prevState.filter((_: any, i: any) => i !== index));
    setKumpulanKomponenDelete((prevState: any) => ([...prevState, { id_rencana_komponen: id }]))
    if (currentKomponen === index && index > 0) {
      setCurrentKomponen(index - 1);
    } else if (index === 0 && kumpulanKomponen.length > 1) {
      setCurrentKomponen(0);
    } else {
      setCurrentKomponen(-1);
    }
  };

  const onSubmit = async (data: any) => {
    setUploadData(true)
    try {
      const komponenTambah = kumpulanKomponen.filter((item: any) => !item.id_rencana_komponen)
      const komponenEdit = kumpulanKomponen.filter((item: any) => item.id_rencana_komponen)
      if (komponenTambah) {
        const tambahPromises = komponenTambah.map((data: any) => postKomponen(data))
        await Promise.all(tambahPromises)
      }
      if (komponenEdit) {
        const editPromises = komponenEdit.map((data: any) => asyncEditRencanaPemenuhanKomponen(data))
        await Promise.all(editPromises)
      }
      if (kumpulanKomponenDelete.length !== 0) {
        const deletePromises = kumpulanKomponenDelete.map((data: any) => deleteNewRencanaPemenuhanKomponen(data))
        await Promise.all(deletePromises)
      }
      await asyncEditRencanaPemenuhan(data)
      toast.success("Berhasil menambahkan komponen!")
    } catch (error) {
      console.log(error)
      toast.error("Gagal menambahkan komponen!")
    } finally {
      setShowForm("Lihat Data")
      setUploadData(false)
    }
  };

  useEffect(() => {
    if (!loadDetailRencana) {
      reset({
        id_rpp: getDetailRencana.id_rpp,
        tahun_rencana: getDetailRencana.tahun_rencana,
        kd_provinsi: getDetailRencana.kd_provinsi,
        kd_kabkota: getDetailRencana.kd_kabkota,
        kd_kec: getDetailRencana.kd_kec,
        kd_desa: getDetailRencana.kd_desa,
        nomenklatur_id: getDetailRencana.nomenklatur_id,
        jenis_kegiatan: getDetailRencana.nomenklatur.jenis_kegiatan,
        nama_kegiatan: getDetailRencana.nama_kegiatan,
        sumber_anggaran_id: getDetailRencana.sumber_anggaran_id,
        rencana_anggaran: getDetailRencana.rencana_anggaran,
      })
      setKumpulanKomponen(getDetailRencana.rencana_komponen)
      setCurrentKomponen(getDetailRencana.rencana_komponen.length)
    }
  }, [loadDetailRencana, getDetailRencana])

  if (loadDetailRencana || loadKabKota || loadKec || loadDesa || loadNomen || uploadData) return <CompLoader />

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden">
      <div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
        <h3 className="text-2xl font-medium text-[#364878] dark:text-white">
          Form Tambah Rencana Pemenuhan{' '}
          {bidang === 'air-minum'
            ? 'Air Minum'
            : bidang === 'air-limbah'
              ? 'Air Limbah'
              : bidang === 'rumah-bencana'
                ? 'Rumah Bencana'
                : bidang === 'rumah-relokasi'
                  ? 'Rumah Relokasi'
                  : ''}
        </h3>
      </div>
      <ScrollArea>
        <div className="flex flex-col gap-5.5 p-6.5 ">
          {/* <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="tahun"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Tahun
              </label>
            </div>
            <div className="text-right">
              <input
                id="tahun"
                name="tahun_rencana"
                type="text"
                value={watch("tahun_rencana")}
                onChange={(e) => setValue("tahun_rencana", Number(e.target.value))}
                placeholder="Masukan Tahun Rencana"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div> */}
          {privilege === 2 &&
            <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
              <div className="flex items-end">
                <label
                  htmlFor="kecamatan"
                  className="text-slate mb-3 block font-medium dark:text-white"
                >
                  Kabupaten / Kota
                </label>
              </div>
              <div className="text-right">
                <select
                  className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                  onChange={(e) => {
                    const selected = e.target.value;
                    setValue("kd_kabkota", Number(selected));
                  }}
                  value={String(watch("kd_kabkota"))}
                >
                  <option value="0" disabled hidden>
                    Pilih Kabupaten / Kota
                  </option>
                  {!loadKabKota && getKabKota.map((kabkota: any, index: any) => (
                    <option key={index} value={kabkota.kd_kabkota}>
                      {kabkota.nama_kabkota}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          }
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="kecamatan"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Kecamatan
              </label>
            </div>
            <div className="text-right">
              <select
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                onChange={(e) => {
                  const selected = e.target.value;
                  setValue("kd_kec", Number(selected));
                }}
                value={String(watch("kd_kec"))}
              >
                <option value="0" disabled hidden>
                  Pilih Kecamatan
                </option>
                {!loadKec && getKecamatan.map((kecamatan: any, index: any) => (
                  <option key={index} value={kecamatan.kd_kec}>
                    {kecamatan.nama_kecamatan}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="desa"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Kelurahan/desa
              </label>
            </div>
            <div className="text-right">
              <select
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                value={String(watch("kd_desa"))}
                onChange={(e) => {
                  const selectedDesa = e.target.value;
                  setValue("kd_desa", Number(selectedDesa))
                }}
              >
                <option value="0" disabled hidden>
                  Pilih Kelurahan / Desa
                </option>
                {!loadDesa && getDesa.map((desa: any, index: any) => (
                  <option key={index} value={desa.kd_desa}>
                    {desa.nama_desa}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="nomenklatur"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Nomenklatur
              </label>
            </div>
            <div className="text-right">
              <select
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                value={String(watch("nomenklatur_id"))}
                onChange={(e) => {
                  const selectedNomen = getNomen.find(
                    (nomen: any) =>
                      String(nomen.id_nomenklatur) === e.target.value,
                  );
                  if (kumpulanKomponen.length !== 0) {
                    setIsConfirmOpen(true)
                    setSelectedNomen(selectedNomen)
                  } else {
                    setValue("jenis_kegiatan", selectedNomen ? selectedNomen.jenis_kegiatan : '')
                    setValue("nomenklatur_id", selectedNomen.id_nomenklatur)
                  }
                }}
              >
                <option value="0" disabled hidden>
                  Pilih Nomenklatur
                </option>
                {!loadNomen && getNomen.map((nomen: any, index: any) => (
                  <option key={index} value={nomen.id_nomenklatur}>
                    {nomen.nama_nomenklatur}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="jenis-kegiatan"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Jenis Kegiatan
              </label>
            </div>
            <div className="text-right">
              <input
                disabled
                id="jenis-kegiatan"
                name="jenis-kegiatan"
                type="text"
                value={watch("jenis_kegiatan")}
                onChange={(e) => setValue("jenis_kegiatan", String(e.target.value))}
                placeholder="New Value"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3 ">
            <div className="flex items-end">
              <label
                htmlFor="nama-kegiatan"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Nama Kegiatan
              </label>
            </div>
            <div className="text-right">
              <input
                id="nama-kegiatan"
                name="nama-kegiatan"
                value={watch("nama_kegiatan")}
                onChange={(e) => setValue("nama_kegiatan", e.target.value)}
                type="text"
                placeholder="Masukkan nama kegiatan"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="sumber-anggaran"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Sumber Anggaran
              </label>
            </div>
            <div className="text-right">
              <select
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                onChange={(e) => {
                  setValue("sumber_anggaran_id", Number(e.target.value))
                }}
                value={watch("sumber_anggaran_id")}
              >
                <option value="0" disabled hidden>
                  Pilih Sumber Anggaran
                </option>
                {!loadSumber && getSumberAnggaran.map((sumber: any, index: any) => (
                  <option key={index} value={sumber.id_sumber_anggaran}>
                    {sumber.sumber_anggaran}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-5 border-b border-slate-200 pb-3">
            <div className="flex items-end">
              <label
                htmlFor="anggaran"
                className="text-slate mb-3 block font-medium dark:text-white"
              >
                Anggaran
              </label>
            </div>
            <div className="text-right">
              <input
                id="anggaran"
                name="anggaran"
                value={formatRupiah(Number(watch("rencana_anggaran")))}
                onChange={handleAnggaranChange}
                type="text"
                placeholder="Masukkan jumlah anggaran"
                className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
              />
            </div>
          </div>
          <div className="flex flex-col border-b border-slate-200 pb-3">
            <div className="flex w-full items-center justify-between">
              <label
                htmlFor="komponen"
                className="text-slate block font-medium dark:text-white"
              >
                Komponen
              </label>
              <Button className='inline-flex items-center justify-start gap-1 rounded-lg bg-green-500 px-4 py-2 text-center font-medium text-white hover:bg-green-600'
                onClick={handleTambahKomponen}
              >
                <FiPlus />
                Tambah Komponen
              </Button>
            </div>
            {kumpulanKomponen.map((item: any, index: any) => (
              <div key={index} className="flex relative items-center p-6 gap-4 border-b border-slate-200">
                <button className="absolute top-0 left-0 text-xl text-red-500 m-4" onClick={() => handleHapusKomponen(index, item.id_rencana_komponen)}>
                  <FiX />
                </button>
                <label
                  htmlFor="komponen"
                  className="w-1/3 text-slate block font-medium dark:text-white"
                >
                  Komponen {index + 1}
                </label>
                <div className="flex flex-col gap-4 w-full">
                  <KomponenSelect nomenklatur_id={watch("nomenklatur_id")} kumpulanKomponen={kumpulanKomponen[index]} setKumpulanKomponen={setKumpulanKomponen} index={index} />
                  <SatuanSelect kumpulanKomponen={kumpulanKomponen[index]} setKumpulanKomponen={setKumpulanKomponen} index={index} />
                  <input
                    id="volume"
                    name="volume"
                    value={kumpulanKomponen[index].volume}
                    onChange={(e) => {
                      const volume = e.target.value;
                      setKumpulanKomponen((prevState: any) => {
                        const newState = [...prevState];
                        newState[index] = {
                          ...newState[index],
                          volume
                        };
                        return newState;
                      });
                    }}
                    type="number"
                    step="0.01"
                    placeholder="Masukan Volume"
                    className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-left text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-5 flex justify-between gap-5">
            <div className="w-full">
              <button
                className="inline-flex min-w-full items-center justify-center rounded-md border border-meta-3 px-10 py-4 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
                onClick={() => handleSubmit(onSubmit)()}
              >
                Simpan Data
              </button>
            </div>
          </div>
        </div>
      </ScrollArea>
      <ConfirmationDialog
        isDialogOpen={isConfirmOpen}
        setIsDialogOpen={setIsConfirmOpen}
        confirmAction={() => {
          const deletedId = kumpulanKomponen.map((item: any) => ({ id_rencana_komponen: item.id_rencana_komponen }))
          setValue("jenis_kegiatan", selectedNomen ? selectedNomen.jenis_kegiatan : '')
          setValue("nomenklatur_id", selectedNomen.id_nomenklatur)
          setKumpulanKomponenDelete(deletedId)
          setKumpulanKomponen([])
          setIsConfirmOpen(false)
        }}
        title="Tunggu Dulu"
        description="Apakah Anda yakin ingin mengganti Nomenklatur? Semua komponen akan terhapus jika anda mengganti Nomenklatur"
        confirmButtonColor="bg-red-500 text-white hover:bg-red-600 dark:bg-red-400 dark:hover:bg-red-600" />
    </div>
  );
};
export default FormRencanaPemenuhanEdit;
