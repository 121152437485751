import { createSlice } from '@reduxjs/toolkit';

export const wilayahSlice = createSlice({
	name: 'wilayah',
	initialState: {
		value: localStorage.getItem('selectedWilayah') || 'provinsi',
	},
	reducers: {
		setWilayah: (state, action) => {
			state.value = action.payload;
			localStorage.setItem('selectedWilayah', action.payload);
			// return action.payload;
		},
	},
});

export const { setWilayah } = wilayahSlice.actions;
export default wilayahSlice.reducer;
