import { RootState } from '@/app/store';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import JudulHeader from '@/components/Header/JudulHeader';
import { FiCheckCircle } from 'react-icons/fi';
import { useMenuRumahRelokasi } from '@/hooks/menu/useMenu';

const PenetapanTidakAdaRelokasi = () => {
	const cardRef = useRef(null);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const { isMenuRumahRelokasiProv } = useMenuRumahRelokasi();

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader cardRef={cardRef}>
					DATA CAPAIAN SPM PERUMAHAN RAKYAT PENYEDIAAN RUMAH YANG LAYAK HUNI
					<br />
					BAGI MASYARAKAT YANG TERKENA RELOKASI PROGRAM PEMERINTAH DAERAH <br />
					{isMenuRumahRelokasiProv ? (
						<>PROVINSI {Number(tahun)}</>
					) : (
						<>KABUPATEN / KOTA {Number(tahun)}</>
					)}
				</JudulHeader>

				<div className="mt-15 flex flex-col items-center justify-center gap-8">
					<FiCheckCircle size={100} color="green" />
					<h1 className="item-center flex justify-center text-center text-3xl font-bold text-[#364878] dark:text-white">
						Tidak Ada Kegiatan Relokasi Pada Tahun {Number(tahun)}
						<br />
						Capaian Pemenuhan SPM 100%
					</h1>
				</div>
			</ScrollArea>
		</div>
	);
};

export default PenetapanTidakAdaRelokasi;
