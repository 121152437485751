import React, { useState, useEffect } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

interface DesaProps {
    getDesa: any[];
    setKd_desa: (kd_desa: number) => void;
    selectedValue: number; 
    kd_kec: number | null; // Add type for kd_kec
    disabledSelect: boolean;
}

const EditDesaSelectUI: React.FC<DesaProps> = ({
    getDesa,
    setKd_desa,
    selectedValue,
    kd_kec,
    disabledSelect
}) => {
    const [selectedDesa, setSelectedDesa] = useState('');

    useEffect(() => {
        if (!kd_kec) {
            // console.log('kd_kec is not defined');
            return;
        }

        // console.log('Fetching desa for kd_kec:', kd_kec);

        // Check if getDesa is an array
        if (Array.isArray(getDesa)) {
            const selectedDesa = getDesa.find((desa: any) => desa.kd_desa === selectedValue);
            if (selectedDesa) {
                setSelectedDesa(selectedDesa.nama_desa);
            }
        } else {
            // console.log('getDesa is not an array or is undefined', getDesa);
        }
    }, [selectedValue, getDesa, kd_kec]);

    return (
        <Select
            onValueChange={(value) => {
                if (value !== '') {
                    const selectedDesa = getDesa.find((desa: any) => desa.kd_desa === parseInt(value));
                    if (selectedDesa) {
                        // console.log("Desa yang dipilih:", selectedDesa.nama_desa);
                        setSelectedDesa(selectedDesa.nama_desa || "");
                        setKd_desa(parseInt(value)); // Convert to number before passing
                    }
                }
            }}
            value={selectedValue.toString()} // Convert to string for Select
            disabled={disabledSelect}
        >
            <SelectTrigger>
                <SelectValue placeholder="Pilih Desa">
                    {selectedDesa || 'Pilih Desa'}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {Array.isArray(getDesa) ? (
                    getDesa.map((desa: any, index: any) => (
                        <SelectItem key={index} value={desa.kd_desa.toString()}>
                            {desa.nama_desa}
                        </SelectItem>
                    ))
                ) : (
                    <div>Error loading desa</div>
                )}
            </SelectContent>
        </Select>
    );
};

export default EditDesaSelectUI;
