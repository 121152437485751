import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { FormEvent, useEffect, useState } from 'react';
import DesaSelectUI from '@/components/ui/SelectDesaUI';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useQueryClient } from '@tanstack/react-query';
import { usePostPengumpulanDataRumahRelokasi } from '@/hooks/perumahan/useRumahRelokasi';
import JenisProgramRelokasiSelectUI from '@/components/ui/SelectJenisProgramRelokasiUI';
import { useGetJenisProgramRelokasi } from '@/hooks/perumahan/useRumahBencana';
import KabKotaSelectUI from '@/components/ui/SelectKabKotaUI';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';


const FPengumpulanDataRumahRelokasi = () => {
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kabkota, setKd_kabkota] = useState(user.kd_kabkota.toString());
	const [kd_kec, setKd_kec] = useState('0');
	const [kd_desa, setKd_desa] = useState('0');

	//DataForm
	const [jumlahRumah, setJumlahRumah] = useState('');
	const [jumlahKK, setJumlahKK] = useState('');
	const [statusLegal, setStatusLegal] = useState('');
	const [statusIllegal, setStatusIllegal] = useState('');
	const [jenisProgramRelokasi, setJenisProgramRelokasi] = useState('');
	const [jumlahJiwa, setJumlahJiwa] = useState('');
	const [statusMilik, setStatusMilik] = useState('');
	const [statusSewa, setStatusSewa] = useState('');
	const [statusLainnya, setStatusLainnya] = useState('');

	//Get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
	const { getKecamatan } = useGetKecamatan(
		user.kd_provinsi,
		parseInt(kd_kabkota),
	);
	const { getDesa, refetch: fetchDesa } = useGetDesa(
		user.kd_provinsi,
		parseInt(kd_kabkota),
		parseInt(kd_kec),
	);
	const { postPengumpulanDataRumahRelokasi } =
		usePostPengumpulanDataRumahRelokasi();
	const { getJenisProgramRelokasi } = useGetJenisProgramRelokasi();

	//additionals
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	//resetform
	const resetForm = () => {
		setJumlahRumah('');
		setJumlahKK('');
		setStatusLegal('');
		setStatusIllegal('');
		setJenisProgramRelokasi('');
		setJumlahJiwa('');
		setStatusMilik('');
		setStatusSewa('');
		setStatusLainnya('');
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		const data = {
			tahun_pelaporan: parseInt(tahun),
			menu_id: menuId,
			kd_provinsi: user.kd_provinsi,
			kd_kabkota: parseInt(kd_kabkota),
			kd_kec: parseInt(kd_kec),
			kd_desa: parseInt(kd_desa),
			jml_rumah: parseInt(jumlahRumah),
			jml_kk: parseInt(jumlahKK),
			jml_jiwa: parseInt(jumlahJiwa),
			legal: parseInt(statusLegal),
			ilegal: parseInt(statusIllegal),
			jenis_relokasi_id: parseInt(jenisProgramRelokasi),
			status_milik: parseInt(statusMilik),
			status_sewa: parseInt(statusSewa),
			status_lainnya: parseInt(statusLainnya),
		};
		postPengumpulanDataRumahRelokasi(data, {
			onSuccess: () => {
				setModalOpen(false);
				queryClient.refetchQueries({ queryKey: ['relokasi-pengumpulan'] });
				resetForm();
			},
			onError: (error) => {
				console.log(error);
			},
		});

		console.log(data);
	};

	useEffect(() => {
		fetchDesa();
	}, [kd_kec]);

	console.log(tahun);
	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
				<DialogTrigger asChild>
					<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
						<FiPlusCircle />
						Input Data
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 mx-auto max-w-screen-xl">
					<DialogHeader>
						<DialogTitle>Tambah Data Rumah Yang Terkena Bencana</DialogTitle>
						<DialogDescription className="my-1">
							Jika Belum Memiliki Data, Silahkan Isi Dengan Angka 0 Agar Bisa Di
							Simpan
							<br />
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>
					<form action="post" onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Jenis Lahan" className="mb-2">
									Jenis Relokasi Program
								</Label>
								<JenisProgramRelokasiSelectUI
									getJenisProgramRelokasi={getJenisProgramRelokasi}
									setJenisProgramRelokasi={setJenisProgramRelokasi}
								/>
							</div>
							{menuId === 8 ? (
								<>
									<div className="flex flex-col">
										<Label htmlFor="Kabupaten/Kota" className="mb-2">
											Kabupaten/Kota
										</Label>
										<KabKotaSelectUI
											getKabKota={getKabKota}
											setKabKota={setKd_kabkota}
										/>
									</div>
									<div className="flex flex-col">
										<Label htmlFor="Kecamatan" className="mb-2">
											Kecamatan
										</Label>
										<KecamatanSelectUI
											getKecamatan={getKecamatan}
											setKd_kec={setKd_kec}
										/>
									</div>
								</>
							) : (
								<>
									<div className="flex flex-col">
										<Label htmlFor="Kecamatan" className="mb-2">
											Kecamatan
										</Label>
										<KecamatanSelectUI
											getKecamatan={getKecamatan}
											setKd_kec={setKd_kec}
										/>
									</div>
									<div className="flex flex-col">
										<Label htmlFor="Kelurahan/Desa" className="mb-2">
											Kelurahan/Desa
										</Label>
										<DesaSelectUI getDesa={getDesa} setKd_desa={setKd_desa} />
									</div>
								</>
							)}
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah" className="mb-2">
									Jumlah Rumah (Unit)
								</Label>
								<Input
									id="Jumlah Rumah"
									type="number"
									className="col-span-1"
									value={jumlahRumah}
									onChange={(e) => setJumlahRumah(e.target.value)}
									placeholder="Masukkan Jumlah Rumah"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Jumlah KK" className="mb-2">
									Jumlah KK
								</Label>
								<Input
									id="Jumlah KK"
									type="number"
									className="col-span-1"
									value={jumlahKK}
									onChange={(e) => setJumlahKK(e.target.value)}
									placeholder="Masukkan Jumlah KK"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Jiwa" className="mb-2">
									Jumlah Jiwa
								</Label>
								<Input
									id="Jumlah KK"
									type="number"
									className="col-span-1"
									value={jumlahJiwa}
									onChange={(e) => setJumlahJiwa(e.target.value)}
									placeholder="Masukkan Jumlah Jiwa"
									required={true}
								/>
							</div>
						</div>
						{/* Legalitas Tanah */}
						<h1 className="text-l mt-4 font-bold">Legalitas Tanah</h1>
						<div className="mt-4 grid grid-cols-2 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Legal" className="mb-2">
									Legal
								</Label>
								<Input
									id="Legal"
									type="number"
									className="col-span-1"
									value={statusLegal}
									onChange={(e) => setStatusLegal(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Legal"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Illegal" className="mb-2">
									Illegal
								</Label>
								<Input
									id="Illegal"
									type="number"
									className="col-span-1"
									value={statusIllegal}
									onChange={(e) => setStatusIllegal(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Illegal"
									required={true}
								/>
							</div>
						</div>
						{/* Status Kepemilikan Rumah */}
						<h1 className="text-l mt-4 font-bold">Status Kepemilikan Rumah</h1>
						<div className="mt-4 grid grid-cols-3 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Milik" className="mb-2">
									Milik
								</Label>
								<Input
									id="Milik"
									type="number"
									className="col-span-1"
									value={statusMilik}
									onChange={(e) => setStatusMilik(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Milik"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Sewa" className="mb-2">
									Sewa
								</Label>
								<Input
									id="Sewa"
									type="number"
									className="col-span-1"
									value={statusSewa}
									onChange={(e) => setStatusSewa(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Sewa"
									required={true}
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Lainnya" className="mb-2">
									Lainnya
								</Label>
								<Input
									id="Lainnya"
									type="number"
									className="col-span-1"
									value={statusLainnya}
									onChange={(e) => setStatusLainnya(e.target.value)}
									placeholder="Masukkan Jumlah Rumah Lainnya"
									required={true}
								/>
							</div>
						</div>
						<DialogFooter>
							<div className="mt-5 flex w-full justify-between">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default FPengumpulanDataRumahRelokasi;