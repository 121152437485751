import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FormEvent } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useChangePassword } from '@/hooks/useChangePassword';

const GantiPassword = () => {
    const user = useSelector((state: RootState) => state.auth);
    const tahun = useSelector((state: RootState) => state.tahun.value);
    const menuId = useSelector((state: RootState) => state.menuId.value);

    const [passwordLama, setPasswordLama] = useState("");
    const [passwordBaru, setPasswordBaru] = useState("");
    const [konfirmasiPassword, setKonfirmasiPassword] = useState("");

    const { changePassword } = useChangePassword();
    const [modalOpen, setModalOpen] = useState(false);
    const queryClient = useQueryClient();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        const data = {
            tahun_pelaporan: tahun,
            menu_id: menuId,
            old_password: passwordLama,
            new_password: passwordBaru,
            confirm_password: konfirmasiPassword
        }

        changePassword(data, {
            onSuccess: () => {
                setModalOpen(false);
            },
        });
    }

    return (
        <>
            <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger asChild>
                    <Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
                        Ganti Password
                    </Button>
                </DialogTrigger>
                <DialogContent className="mx-auto max-w-screen-l z-99999">
                    <DialogHeader>
                        <DialogTitle>
                            Ganti Password
                        </DialogTitle>
                        <DialogDescription>
                            Pastikan Anda Ingat Password Lama Anda
                        </DialogDescription>
                    </DialogHeader>
                    <form action='post' onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Password Lama" className="mb-2">
                                    Password Lama
                                </Label>
                                <div className="relative">
                                    <Input
                                        id="Password Lama"
                                        type={showPassword ? "text" : "password"}
                                        className="col-span-1"
                                        value={passwordLama}
                                        onChange={(e) => setPasswordLama(e.target.value)}
                                        placeholder='Masukkan Password Lama'
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 px-3 py-2"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <FiEyeOff /> : <FiEye />}
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Password Baru" className="mb-2">
                                    Password Baru
                                </Label>
                                <div className="relative">
                                    <Input
                                        id="Password Baru"
                                        type={showPassword ? "text" : "password"}
                                        className="col-span-1"
                                        value={passwordBaru}
                                        onChange={(e) => setPasswordBaru(e.target.value)}
                                        placeholder='Masukkan Password Baru'
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 px-3 py-2"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <FiEyeOff /> : <FiEye />}
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Konfirmasi Password Baru" className="mb-2">
                                    Konfirmasi Password Baru
                                </Label>
                                <div className="relative">
                                    <Input
                                        id="Konfirmasi Password Baru"
                                        type={showPassword ? "text" : "password"}
                                        className="col-span-1"
                                        value={konfirmasiPassword}
                                        onChange={(e) => setKonfirmasiPassword(e.target.value)}
                                        placeholder='Masukkan Konfirmasi Password Baru'
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 px-3 py-2"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? <FiEyeOff /> : <FiEye />}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <DialogFooter>
                            <div className="flex w-full justify-between mt-5">
                                <DialogClose asChild>
                                    <Button
                                        className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
                                    >
                                        Batal
                                    </Button>
                                </DialogClose>
                                <Button
                                    className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                                    type="submit"
                                >
                                    Simpan
                                </Button>
                            </div>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GantiPassword;
