import React, { useEffect, useState } from 'react';
import CardDataStats from '../../components/CardDataStats';
import TableBerandaProv from '../../components/Tables/TableBerandaProv';
import DefaultLayout from '../../layout/DefaultLayout';
import IconAirMinum from '../../images/icon/air-minum.svg';
import IconAirLimbah from '../../images/icon/air-limbah.svg';
import IconRumahBencana from '../../images/icon/rumah-bencana.svg';
import IconRumahRelokasi from '../../images/icon/rumah-relokasi.svg';
import IconArrowRight from '../../images/icon/arrow-right.svg';
import TableBerandaKab from '../../components/Tables/TableBerandaKab';
import ChartBeranda from '../../components/Charts/ChartBeranda';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useGetJumlahDesaLaporAM } from '@/hooks/airMinum/useBeranda';
import { useBidang } from '@/hooks/privilege/usePrivilege';
import { useGetJumlahDesaLaporAL } from '@/hooks/airLimbah/useBeranda';

const Beranda: React.FC = () => {
	const wilayah = useSelector((state: RootState) => state.wilayah.value);
	const { bidang } = useBidang();

	const [useGetDataHook, setUseGetDataHook] = useState(
		() => useGetJumlahDesaLaporAM,
	);
	const [headerTitle, setHeaderTitle] = useState(
		'Jumlah Desa dilaporkan Bidang Air Minum',
	);
	useEffect(() => {
		switch (bidang) {
			case 'air-minum':
				setUseGetDataHook(() => useGetJumlahDesaLaporAM);
				setHeaderTitle('Jumlah Desa dilaporkan Bidang Air Minum');
				break;
			case 'air-limbah':
				setUseGetDataHook(() => useGetJumlahDesaLaporAL);
				setHeaderTitle('Jumlah Desa dilaporkan Bidang Air Limbah');
				break;
		}
	}, [bidang]);
	//TODO : tambahkan tempat untuk menghitung berapa desa yang melapor di suatu kabupaten/kota
	return (
		<DefaultLayout>
			<div className="flex flex-1 flex-col overflow-hidden">
				<div className="mb-5 flex">
					<div className="flex flex-grow justify-start text-3xl font-bold text-[#364878] dark:text-white">
						<span>Data Capaian</span>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
					<CardDataStats
						title="Air Minum"
						total="air-minum"
						rate="0.43%"
						levelUp
						children1={
							<div className="flex w-full flex-col gap-3">
								{wilayah === 'provinsi' ? (
									<div className="flex">
										<button className="flex w-1/2 items-center justify-between font-medium">
											<Link to="beranda/capaian-air-minum-provinsi">
												Data Kumulatif
											</Link>
											<img src={IconArrowRight} alt="" />
										</button>
									</div>
								) : (
									<div className="flex">
										<button className="flex w-1/2 items-center justify-between font-medium">
											<Link to="beranda/capaian-air-minum-kabupaten-kota">
												Data Kumulatif
											</Link>
											<img src={IconArrowRight} alt="" />
										</button>
									</div>
								)}
								{wilayah !== 'provinsi' && (
									<div className="flex">
										<button className="flex w-1/2 items-center justify-between font-medium">
											Data Tahunan
											<img src={IconArrowRight} alt="" />
										</button>
									</div>
								)}
							</div>
						}
						children2={
							<img
								className="h-30 w-30"
								src={IconAirMinum}
								alt="icon-air-minum"
							/>
						}
					></CardDataStats>
					<CardDataStats
						title="Air Limbah"
						total="$45,2K"
						rate="4.35%"
						levelUp
						children1={
							<div className="flex w-full flex-col gap-3">
								<div className="flex">
									<button className="flex w-1/2 items-center justify-between font-medium">
										<Link to="beranda/capaian-air-limbah-kabupaten-kota">
											Data Kumulatif
										</Link>
										<img src={IconArrowRight} alt="" />
									</button>
								</div>
								{wilayah !== 'provinsi' && (
									<div className="flex">
										<button className="flex w-1/2 items-center justify-between font-medium">
											Data Tahunan
											<img src={IconArrowRight} alt="" />
										</button>
									</div>
								)}
							</div>
						}
						children2={
							<img
								className="h-30 w-30"
								src={IconAirLimbah}
								alt="icon-air-limbah"
							/>
						}
					></CardDataStats>
					<CardDataStats
						title="Rumah Bencana"
						total="2.450"
						rate="2.59%"
						levelUp
						children1={
							<div className="flex w-full flex-col gap-3">
								<div className="flex">
									<button className="flex w-1/2 items-center justify-between font-medium">
										<Link to="beranda/capaian-rumah-bencana-kabupaten-kota">
											Data Kumulatif
										</Link>
										<img src={IconArrowRight} alt="" />
									</button>
								</div>
								{wilayah !== 'provinsi' && (
									<div className="flex">
										<button className="flex w-1/2 items-center justify-between font-medium">
											Data Tahunan
											<img src={IconArrowRight} alt="" />
										</button>
									</div>
								)}
							</div>
						}
						children2={
							<img
								className="h-30 w-30"
								src={IconRumahBencana}
								alt="icon-rumah-bencana"
							/>
						}
					></CardDataStats>
					<CardDataStats
						title="Rumah Relokasi"
						total="3.456"
						rate="0.95%"
						levelDown
						children1={
							<div className="flex w-full flex-col gap-3">
								<div className="flex">
									<button className="flex w-1/2 items-center justify-between font-medium">
										<Link to="beranda/capaian-rumah-relokasi-kabupaten-kota">
											Data Kumulatif
										</Link>
										<img src={IconArrowRight} alt="" />
									</button>
								</div>
								{wilayah !== 'provinsi' && (
									<div className="flex">
										<button className="flex w-1/2 items-center justify-between font-medium">
											Data Tahunan
											<img src={IconArrowRight} alt="" />
										</button>
									</div>
								)}
							</div>
						}
						children2={
							<img
								className="h-30 w-30"
								src={IconRumahRelokasi}
								alt="icon-rumah-relokasi"
							/>
						}
					></CardDataStats>
				</div>

				<div className="mt-4 flex flex-1 gap-4 overflow-hidden">
					<div className="flex basis-3/5">
						{wilayah === 'provinsi' ? (
							<TableBerandaProv />
						) : (
							<TableBerandaKab
								useGetDataHook={useGetDataHook}
								headerTitle={headerTitle}
							/>
						)}
					</div>
					<div className="flex basis-2/5">
						<ChartBeranda />
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default Beranda;
