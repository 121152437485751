import { RootState } from "@/app/store";
import { CompLoader } from "@/common/Loader";
import JudulHeader from "@/components/Header/JudulHeader";
import { Button } from "@/components/ui/Button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useGetDataCapaianKumulatifAMProv } from "@/hooks/airMinum/useDataCapaianAM";
import { useEffect, useRef, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { useSelector } from "react-redux";
import { utils, writeFile } from "xlsx-js-style"

type TableDataAirKumulatifKabKotaProvProps = {
  tabId: number;
  handleTabChange: (e: number) => void
}

const TableDataKumulatifKabKotaProv = ({ tabId, handleTabChange }: TableDataAirKumulatifKabKotaProvProps) => {
  const cardRef = useRef(null)
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege)
  const tahun = useSelector((state: RootState) => state.tahun.value)
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi)
  const { kd_provinsi_dux, nama_provinsi: nama_provinsi_dux } = useSelector((state: RootState) => state.location)
  const nama_provinsi = useSelector((state: RootState) => state.auth.wilayah.nama_provinsi)

  const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi
  const nama_provinsi_to_use = privilege === 10 ? nama_provinsi_dux : nama_provinsi

  const { getDataCapaianKumulatifAMProv, isLoading } = useGetDataCapaianKumulatifAMProv(kd_provinsi_to_use, Number(tahun))

  const [allDataAMProv, setAllDataAMProv] = useState<any>([])
  const [total, setTotal] = useState({
    jumlah_rumah: 0,
    layak_jp: 0,
    layak_bjp: 0,
    belum_terlayani: 0,
  })
  const [persentaseLayanan, setPersentaseLayanan] = useState({
    terlayani_jp: 0,
    terlayani_bjp: 0,
    terlayani_air_minum: 0,
    belum_terlayani: 0,
    belum_terlayani_air_minum: 0,
    gapPelayanan: 0,
    capaian: 0
  })

  const calculateTerlayaniAirMinum = (jp: number, bjp: number, totalRumah: number) => {
    const total = ((jp + bjp) / totalRumah) * 100
    return isNaN(total) ? 0 : total
  }

  const calculateBelumTerlayaniAirMinum = (belumTerlayani: number, totalRumah: number) => {
    const total = belumTerlayani / totalRumah * 100
    return isNaN(total) ? 0 : total
  }

  useEffect(() => {
    if (!isLoading) {
      const allDataAMProv = getDataCapaianKumulatifAMProv.data
      setAllDataAMProv(allDataAMProv)
      const totalJumlahRumah = getDataCapaianKumulatifAMProv.data.reduce((acc: any, curr: any) => acc + curr["total_jumlah_rumah"], 0)
      const totalLayakJP = getDataCapaianKumulatifAMProv.data.reduce((acc: any, curr: any) => acc + curr["total_layak_jp"], 0)
      const totalLayakBJP = getDataCapaianKumulatifAMProv.data.reduce((acc: any, curr: any) => acc + curr["total_layak_bjp"], 0)
      const totalBelumTerlayani = totalJumlahRumah - (totalLayakJP + totalLayakBJP)

      const persentaseLayananTerlayaniJp = (totalLayakJP / totalJumlahRumah) * 100
      const persentaseLayananTerlayaniBjp = (totalLayakBJP / totalJumlahRumah) * 100
      const persentaseLayananTerlayaniAirMinum = persentaseLayananTerlayaniJp + persentaseLayananTerlayaniBjp
      const persentaseBelumTerlayani = (totalBelumTerlayani / totalJumlahRumah) * 100
      const persentaseCapaian = persentaseLayananTerlayaniJp + persentaseLayananTerlayaniBjp

      setTotal((prevState: any) => ({ ...prevState, jumlah_rumah: totalJumlahRumah, layak_jp: totalLayakJP, layak_bjp: totalLayakBJP, belum_terlayani: totalBelumTerlayani }))

      setPersentaseLayanan((prevState: any) => ({ ...prevState, terlayani_jp: persentaseLayananTerlayaniJp.toFixed(2), terlayani_bjp: persentaseLayananTerlayaniBjp.toFixed(2), terlayani_air_minum: persentaseLayananTerlayaniAirMinum.toFixed(2), belum_terlayani: persentaseBelumTerlayani.toFixed(2), belum_terlayani_air_minum: persentaseBelumTerlayani.toFixed(2), gapPelayanan: persentaseBelumTerlayani.toFixed(2), capaian: persentaseCapaian.toFixed(2) }))
    }
  }, [isLoading, tahun])

  const handleDownload = () => {
    const transformedData = allDataAMProv.map((item: any, index: any) => ([
      { v: index + 1, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.nama_provinsi, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.nama_kabkota, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.total_jumlah_rumah, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.total_layak_jp, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.total_layak_bjp, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${calculateTerlayaniAirMinum(item.total_layak_jp, item.total_layak_bjp, item.total_jumlah_rumah).toFixed(2)}%`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: item.total_jumlah_rumah - (item.total_layak_jp + item.total_layak_bjp), t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${calculateBelumTerlayaniAirMinum(item.total_jumlah_rumah - (item.total_layak_jp + item.total_layak_bjp), item.total_jumlah_rumah).toFixed(2)}%`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${calculateBelumTerlayaniAirMinum(item.total_jumlah_rumah - (item.total_layak_jp + item.total_layak_bjp), item.total_jumlah_rumah).toFixed(2)}%`, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
    ]));


    const header = [
      { v: "No", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Provinsi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Kabupaten", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Target", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Realisasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
    ];

    const header1 = [
      { v: "Total", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Terlayani JP", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Terlayani BJP", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Terlayani Air Minum", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Belum Terlayani", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "Belum Terlayani Air Minum", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
    ];

    const header2 = [
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(%)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
      { v: "(%)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
    ];

    const totalRow = [
      { v: total.jumlah_rumah, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: total.layak_jp, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: total.layak_bjp, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: "-", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: total.belum_terlayani, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: "-", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: "-", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }
    ];

    const persentaseRow = [
      { v: "-", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${persentaseLayanan.terlayani_jp}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${persentaseLayanan.terlayani_bjp}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${persentaseLayanan.terlayani_air_minum}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${persentaseLayanan.belum_terlayani}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${persentaseLayanan.belum_terlayani_air_minum}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
      { v: `${persentaseLayanan.gapPelayanan}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }
    ];

    const capaianRow = [
      { v: `${persentaseLayanan.capaian}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }
    ];

    let wb = utils.book_new(),
      ws = utils.aoa_to_sheet([]);

    utils.sheet_add_aoa(ws, [[{ v: "PERHITUNGAN KEBUTUHAN", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A1" })
    utils.sheet_add_aoa(ws, [[{ v: "SPM PERKERJAAN UMUM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A2" })
    utils.sheet_add_aoa(ws, [[{ v: `PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI HARI TAHUN ${tahun}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A3" })

    utils.sheet_add_aoa(ws, [header], { origin: "A4" })
    utils.sheet_add_aoa(ws, [[{ v: "Gap Pelayanan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "J4" })
    utils.sheet_add_aoa(ws, [header1], { origin: "D5" })
    utils.sheet_add_aoa(ws, [header2], { origin: "D6" })
    utils.sheet_add_aoa(ws, transformedData, { origin: "A7" })

    utils.sheet_add_aoa(ws, [[{ v: "Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 7}` })
    utils.sheet_add_aoa(ws, [totalRow], { origin: `D${transformedData.length + 7}` })

    utils.sheet_add_aoa(ws, [[{ v: "Persentase Layanan", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 8}` })
    utils.sheet_add_aoa(ws, [persentaseRow], { origin: `D${transformedData.length + 8}` })

    utils.sheet_add_aoa(ws, [[{ v: "Capaian SPM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 9}` })
    utils.sheet_add_aoa(ws, [capaianRow], { origin: `D${transformedData.length + 9}` })

    ws['!cols'] = [
      { wpx: 50 },
      { wpx: 125 },
      { wpx: 138 },
      { wpx: 58 },
      { wpx: 76 },
      { wpx: 84 },
      { wpx: 127 },
      { wpx: 101 },
      { wpx: 168 },
      { wpx: 95 },
    ]
    ws['!merges'] = [
      // COLSPANS
      utils.decode_range("A1:J1"),
      utils.decode_range("A2:J2"),
      utils.decode_range("A3:J3"),
      utils.decode_range("E4:I4"),
      utils.decode_range(`A${transformedData.length + 7}:C${transformedData.length + 7}`),
      utils.decode_range(`A${transformedData.length + 8}:C${transformedData.length + 8}`),
      utils.decode_range(`A${transformedData.length + 9}:C${transformedData.length + 9}`),
      utils.decode_range(`D${transformedData.length + 9}:J${transformedData.length + 9}`),

      // ROWSPANS
      utils.decode_range("A4:A6"),
      utils.decode_range("B4:B6"),
      utils.decode_range("C4:C6"),
      utils.decode_range("J4:J6"),
    ]
    utils.book_append_sheet(wb, ws, "Kumulatif Air Minum")
    writeFile(wb, `Data Capaian Kumulatif Air Minum ${nama_provinsi_to_use}  - ${tahun}.xlsx`)
  }

  if (isLoading) return <CompLoader />

  let rowCounter = 0

  return (
    <>
      <div className='flex justify-end pb-4'>
        <Button
          className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
          onClick={() => handleDownload()}
        >
          <FiDownload />
          Download Data
        </Button>
      </div>
      <div ref={cardRef} className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col flex-1 overflow-hidden">
        <div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 sm:grid-cols-3">
          <div
            className={`py-4 text-center ${tabId === 1 ? 'bg-[#fab23d]' : 'bg-[#364878]'
              } cursor-pointer rounded-md text-white flex items-center justify-center hover:bg-graydark`}
            onClick={() => handleTabChange(1)}
          >
            <p>Data Air Minum Curah</p>
          </div>
          <div
            className={`py-4 text-center ${tabId === 2 ? 'bg-[#fab23d]' : 'bg-[#364878]'
              } cursor-pointer rounded-md text-white hover:bg-graydark`}
            onClick={() => handleTabChange(2)}
          >
            Rekapitulasi Capaian SPM <br />
            Kabupaten / Kota
          </div>
          <div
            className={`py-4 text-center ${tabId === 3 ? 'bg-[#fab23d]' : 'bg-[#364878]'
              } cursor-pointer rounded-md text-white flex items-center justify-center hover:bg-graydark`}
            onClick={() => handleTabChange(3)}
          >
            <p>Bantuan Keuangan</p>
          </div>
        </div>
        <ScrollArea className='h-full p-4 relative'>
          <JudulHeader isLoading={false} cardRef={cardRef} minusWidth={32}>
            PENGHITUNGAN KEBUTUHAN <br />
            SPM PERKERJAAN UMUM <br />
            PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI HARI TAHUN {Number(tahun)}
          </JudulHeader>
          <Table className="w-full table-auto">
            <TableHeader className=" bg-gray-2 dark:bg-meta-4 sticky top-0">
              <TableRow>
                <TableHead rowSpan={3} className="border-l thead-text text-center">No</TableHead>
                <TableHead rowSpan={3} className="thead-text text-center">Provinsi</TableHead>
                <TableHead rowSpan={3} className="thead-text text-center">Kabupaten</TableHead>
                <TableHead className="thead-text text-center border-b-0">Target</TableHead>
                <TableHead colSpan={5} className="thead-text text-center border-b-0">Realisasi</TableHead>
                <TableHead rowSpan={3} className="thead-text text-center">Gap Pelayanan</TableHead>
              </TableRow>
              <TableRow>
                <TableHead className='thead-text text-center border-b-0'>Total</TableHead>
                <TableHead className='thead-text text-center border-b-0'>Terlayani JP</TableHead>
                <TableHead className='thead-text text-center border-b-0'>Terlayani BJP</TableHead>
                <TableHead className='thead-text text-center border-b-0'>Terlayani Air Minum</TableHead>
                <TableHead className='thead-text text-center border-b-0'>Belum Terlayani</TableHead>
                <TableHead className='thead-text text-center border-b-0'>Belum Terlayani Air Minum</TableHead>
              </TableRow>
              <TableRow>
                <TableHead className='thead-text text-center'>(Rumah)</TableHead>
                <TableHead className='thead-text text-center'>(Rumah)</TableHead>
                <TableHead className='thead-text text-center'>(Rumah)</TableHead>
                <TableHead className='thead-text text-center'>(%)</TableHead>
                <TableHead className='thead-text text-center'>(Rumah)</TableHead>
                <TableHead className='thead-text text-center'>(%)</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {getDataCapaianKumulatifAMProv.data.map((item: any, key: any) => {
                rowCounter++
                const belumTerlayani = item.total_jumlah_rumah - (item.total_layak_jp + item.total_layak_bjp)
                const terlayaniAirMinum = calculateTerlayaniAirMinum(item.total_layak_jp, item.total_layak_bjp, item.total_jumlah_rumah)
                const belumTerlayaniAirMinum = calculateBelumTerlayaniAirMinum(belumTerlayani, item.total_jumlah_rumah)
                return (
                  <TableRow key={key} className={
                    rowCounter % 2 === 0
                      ? 'bg-gray-2 dark:bg-meta-4'
                      : 'bg-white dark:bg-boxdark'
                  }>
                    <TableCell className='tbody-text border-l border-b border-r'>{rowCounter}</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{item.nama_provinsi}</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{item.nama_kabkota}</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{item.total_jumlah_rumah}</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{item.total_layak_jp}</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{item.total_layak_bjp}</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{terlayaniAirMinum.toFixed(2)}%</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{belumTerlayani}</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{belumTerlayaniAirMinum.toFixed(2)}%</TableCell>
                    <TableCell className='tbody-text border-b border-r'>{belumTerlayaniAirMinum.toFixed(2)}%</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            {!isLoading &&
              <TableFooter>
                <TableRow className='bg-black/10 dark:bg-white/10'>
                  <TableCell className='text-lg tfooter-text border-l border-b border-r' colSpan={3}>Total</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{total.jumlah_rumah}</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{total.layak_jp}</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{total.layak_bjp}</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>-</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{total.belum_terlayani}</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>-</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>-</TableCell>
                </TableRow>
                <TableRow className='bg-black/10 dark:bg-white/10'>
                  <TableCell className='text-lg tfooter-text border-l border-b border-r' colSpan={3}>Persentase Layanan</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>-</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{isNaN(persentaseLayanan.terlayani_jp) ? 0 : persentaseLayanan.terlayani_jp}%</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{isNaN(persentaseLayanan.terlayani_bjp) ? 0 : persentaseLayanan.terlayani_bjp}%</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{isNaN(persentaseLayanan.terlayani_air_minum) ? 0 : persentaseLayanan.terlayani_air_minum}%</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{isNaN(persentaseLayanan.belum_terlayani) ? 0 : persentaseLayanan.belum_terlayani}%</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{isNaN(persentaseLayanan.belum_terlayani_air_minum) ? 0 : persentaseLayanan.belum_terlayani_air_minum}%</TableCell>
                  <TableCell className='tfooter-text border-b border-r'>{isNaN(persentaseLayanan.gapPelayanan) ? 0 : persentaseLayanan.gapPelayanan}%</TableCell>
                </TableRow>
                <TableRow className='bg-black/10 dark:bg-white/10'>
                  <TableCell className='text-lg tfooter-text border-l border-b border-r' colSpan={3}>Capaian SPM</TableCell>
                  <TableCell className='tfooter-text border-b border-r' colSpan={7}>{isNaN(persentaseLayanan.capaian) ? 0 : persentaseLayanan.capaian}%
                  </TableCell>
                </TableRow>
              </TableFooter>
            }
          </Table>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </>
  )
}

export default TableDataKumulatifKabKotaProv