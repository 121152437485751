import { useQuery } from '@tanstack/react-query';
import { API } from '../../libs/API';

export function useGetDashKumulatifRBKab(kd_provinsi: number, tahun: number) {
  const {
    data: getDashKumulatifKab,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['dash-kumulatif-RB-kab', kd_provinsi, tahun],
    queryFn: async () => {
      const { data } = await API.get(
        `/dash-eksis-bencana-prov/?kd_provinsi=${kd_provinsi}&tahun=${tahun}`,
      );
      return data.data;
    },
  });
  return { getDashKumulatifKab, refetch, isLoading };
}

export function useGetDashKumulatifRLKab(kd_provinsi: number, tahun: number) {
  const {
    data: getDashKumulatifKab,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['dash-kumulatif-RL-kab', kd_provinsi, tahun],
    queryFn: async () => {
      const { data } = await API.get(
        `/dash-eksis-relokasi-prov/?kd_provinsi=${kd_provinsi}&tahun=${tahun}`,
      );
      return data.data;
    },
  });
  return { getDashKumulatifKab, refetch, isLoading };
}
