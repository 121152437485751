import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import ActionButtonRawanBencana from '@/components/ActionButton/RumahBencana/PengumpulanData/ABIdentifikasiRwnBencana';
import FIdentifikasiRwnBencana from '@/components/Forms/RumahBencana/FIdentifikasiRwnBencana';
import GIdentifikasiRwnBencana from '@/components/Guides/RumahBencana/PengumpulanData/GIdentifikasiRwnBencana';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useMenuRumahBencana } from '@/hooks/menu/useMenu';
import {
	useDeleteIdentifikasiPerumahanRawanBencana,
	useGetRawanBencana,
} from '@/hooks/perumahan/useRumahBencana';
import { usePrivilegeRumahBencana } from '@/hooks/privilege/usePrivilege';
import { EksisRawanBencana } from '@/types/perumahan';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { GKebutuhanAirMinum } from '@/components/Guides/AirMinum/GKebutuhanAirMinum';
import FKebutuhanAirMinum from '@/components/Forms/AirMinum/PengumpulanData/FKebutuhanAirMinum';

export const TKebutuhanAirMinum = () => {
	const selectedTahun = useSelector((state: RootState) => state.tahun.value);
	const menu = useSelector((state: RootState) => state.menuId.value);
	const tahun = Number(selectedTahun);
	const QueryClient = useQueryClient();

	//hook previlege
	const { isPrevilegeViewRumahBencana, kd_kabkota_to_use, kd_provinsi_to_use } =
		usePrivilegeRumahBencana();

	//hook menu
	const { isMenuRumahBencanaProv, isMenuRumahBencanaKab } =
		useMenuRumahBencana();

	//getData
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetRawanBencana(kd_provinsi_to_use, kd_kabkota_to_use, tahun, menu);

	//Delete
	const { deleteIdentifikasiPerumahanRawanBencana, isPending, isSuccess } =
		useDeleteIdentifikasiPerumahanRawanBencana();

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	const pages = data?.pages || [];

	const handleEdit = (rowData: EksisRawanBencana) => {};

	const handleDelete = async (rowData: EksisRawanBencana) => {
		try {
			await deleteIdentifikasiPerumahanRawanBencana({
				id_rawan_bencana: rowData.id_rawan_bencana,
			});
			QueryClient.refetchQueries({ queryKey: ['eksis-rawan-bencana'] });
			toast.success('Berhasil menghapus data Identifikasi Rawan Bencana');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus data Identifikasi Rawan Bencana');
		}
	};

	const cardRef = useRef(null);

	let rowCounter = 0;
	if (isLoading) return <CompLoader />;
	return (
		<>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<div className="my-2 mr-4 flex justify-end gap-4">
					<GKebutuhanAirMinum />
					<FKebutuhanAirMinum />
				</div>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader isLoading={isLoading} cardRef={cardRef}>
						KEBUTUHAN PELAYANAN AIR MINUM CURAH <br />
						SPM PEKERJAAN UMUM <br />
						<>PEMENUHAN KEBUTUHAN AIR MINUM CURAH LINTAS KABUPATEN/KOTA</>
					</JudulHeader>
					<Table className="w-full min-w-[1800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead className="thead-text border-l" rowSpan={2}>
									No
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Kabupaten/Kota
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Total
									<br />
									(Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Terlayani Akses
									<br />
									Air Minum <br /> (Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Tidak Terlayani Akses
									<br />
									Air Minum <br /> (Rumah Tangga)
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									Kebutuhan Pelayanan <br /> Air Minum Curah
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									Potensi Air Baku untuk <br /> SPAM Lintas Kabupaten/Kota
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									Kemampuan Penyerapan <br /> Akses Pelayanan Air Minum
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={3}
								>
									Prasarana SPAM <br /> Lintas Kabupaten/Kota
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-t-0 text-center">
									Kapasitas <br />
									(Liter/Detik)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Unit <br /> (Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Sumber Air Baku
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Kapasitas <br />
									(Liter/Detik)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Kapasitas <br />
									(Liter/Detik)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Unit <br /> (Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Nama Prasarana
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Kapasitas <br /> (Liter/Detik)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Lokasi SPAM
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{pages.map((page) =>
								page.data.map((item: EksisRawanBencana) => (
									<TableRow
										key={item.id_rawan_bencana}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="tbody-text border-b border-l border-r text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jenis_bencana.nama_jenis_bencana}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.tingkat_rawan.nama_tingkat_rawan}
										</TableCell>
										{isMenuRumahBencanaProv && (
											<TableCell className="tbody-text border-b border-r text-center">
												{item.wilayah.nama_kabkota}
											</TableCell>
										)}
										<TableCell className="tbody-text border-b border-r text-center">
											{item.wilayah.nama_kecamatan}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.wilayah.nama_desa}
										</TableCell>
										{isMenuRumahBencanaKab && (
											<>
												<TableCell className="tbody-text border-b border-r text-center">
													{item.no_rt ?? '-'}
												</TableCell>
												<TableCell className="tbody-text border-b border-r text-center">
													{item.no_rw || '-'}
												</TableCell>
											</>
										)}
										<TableCell className="tbody-text border-b border-r text-center">
											{item.luas_perumahan || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_rumah}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_kk || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.jml_jiwa || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.fisik_rlh || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.fisik_rtlh || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_hak_milik || '-'}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.status_sewa || '-'}
										</TableCell>
										{isPrevilegeViewRumahBencana ? (
											<></>
										) : (
											<TableCell className="tbody-text flex justify-center border-b border-r text-center">
												<ActionButtonRawanBencana
													rowData={item}
													onEdit={handleEdit}
													onDelete={() => handleDelete(item)}
												/>
											</TableCell>
										)}
									</TableRow>
								)),
							)}
						</TableBody>
					</Table>
					<div className="h-1" ref={ref}>
						{isFetchingNextPage && (
							<div className="flex items-center justify-center p-4">
								<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
							</div>
						)}
					</div>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};
