import { RootState } from "@/app/store"
import { CompLoader } from "@/common/Loader"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Select, SelectContent, SelectGroup, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useEditBantuanKeuangan, useGetBantuanKeuanganById } from "@/hooks/useBantuanKeuangan"
import { useKomponen } from "@/hooks/useKomponen"
import { useSatuan } from "@/hooks/useSatuan"
import { formatRupiah } from "@/libs/utils"
import { SelectItem } from "@radix-ui/react-select"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { Button } from "../ui/Button"
import { toast } from "sonner"

type FormBantuanKeuanganProps = {
  idBantuanKeuangan: any;
  setShowForm: any
}

const FormBantuanKeuanganEdit = ({ idBantuanKeuangan, setShowForm }: FormBantuanKeuanganProps) => {
  const menu_id = useSelector((state: RootState) => state.menuId.value)
  const { getBantuanKeuanganById, isLoading } = useGetBantuanKeuanganById(idBantuanKeuangan)
  const { asyncEditBantuanKeuangan, isPending } = useEditBantuanKeuangan()

  const { register, setValue, watch, handleSubmit } = useForm<any>({
    defaultValues: {
      tahun_pelaporan: 0,
      anggaran_bk: 0,
      menu_id: 0,
      jenis_bantuan_keuangan: "",
      kd_provinsi: 0,
      bk_apbd_prov_id: 0,
      komponen_id: 0,
      komponen_name: "",
      volume: 0,
      satuan_id: 0
    }
  })

  const [anggaran, setAnggaran] = useState(0)
  const [displayAnggaran, setDisplayAnggaran] = useState("")
  const [displayTotalAPBD, setDisplayTotalAPBD] = useState("")



  const { getKomponen, isLoading: isLoadingKomponen } = useKomponen(menu_id)
  const { getSatuan, isLoading: isLoadingSatuan } = useSatuan(watch("komponen_id"))

  const handleKomponenChange = (val: any) => {
    setValue("komponen_id", val)
    const komponen = getKomponen.find((item: any) => item.id_komponen === val)
    setValue("komponen_name", komponen.komponen_name)
    setValue("satuan_id", 0)
    setValue("satuan_name", "")
  }

  const handleSatuanChange = (val: any) => {
    setValue("satuan_id", val)
    const satuan = getSatuan.find((item: any) => item.id_satuan === val)
    setValue("satuan_name", satuan.satuan_name)
  }

  const handleAnggaranChange = (e: any) => {
    const value = e.target.value;
    const numericValue = parseFloat(value.replace(/\D/g, ''));
    setAnggaran(numericValue);
    if (isNaN(numericValue)) {
      setDisplayAnggaran("Rp 0");
    } else {
      setDisplayAnggaran(formatRupiah(numericValue));
      setValue("anggaran_bk", numericValue)
    }
  }

  useEffect(() => {
    if (!isLoading) {
      const data = getBantuanKeuanganById
      setDisplayTotalAPBD(formatRupiah(Number(data.bkApbdProvinsi.total_apbd_prov)))
      setDisplayAnggaran(formatRupiah(data.anggaran_bk))
      setValue("tahun_pelaporan", data.tahun_pelaporan)
      setValue("total_apbd_prov", data.bkApbdProvinsi.total_apbd_prov)
      setValue("bk_apbd_prov_id", data.bkApbdProvinsi.id_bk_apbd_provinsi)
      setValue("menu_id", data.menu_id)
      setValue("volume", data.volume)
      setValue("kd_provinsi", data.kd_provinsi)
      setValue("anggaran_bk", data.anggaran_bk)
      setValue("komponen_id", data.komponen_id)
      setValue("komponen_name", data.komponen.komponen_name)
      setValue("satuan_id", data.satuan_id)
      setValue("satuan_name", data.satuan.satuan_name)
      setValue("jenis_bantuan_keuangan", data.jenis_bantuan_keuangan)
    }
  }, [isLoading, getBantuanKeuanganById])

  const onSubmit = async (data: any) => {
    const newData = {
      id_bk: idBantuanKeuangan,
      kd_provinsi: getBantuanKeuanganById.kd_provinsi,
      anggaran_bk: data.anggaran_bk,
      bk_apbd_prov_id: data.bk_apbd_prov_id,
      jenis_bantuan_keuangan: data.jenis_bantuan_keuangan,
      komponen_id: data.komponen_id,
      volume: data.volume,
      satuan: data.satuan_id
    }
    try {
      await asyncEditBantuanKeuangan(newData)
      toast.success("Berhasil Edit Bantuan Keuangan")
    } catch (error) {
      toast.error("Gagal Edit Bantuan Keuangan")
    } finally {
      setShowForm("Lihat Data")
    }
  }

  if (isLoadingKomponen || isPending) return <CompLoader />

  return (
    <ScrollArea className="h-full">
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Tahun" className="w-1/2 text-xl text-black">
          Tahun
        </Label>
        <Input
          id="Tahun"
          type="number"
          min="0"
          value={watch("tahun_pelaporan")}
          {...register("tahun_pelaporan", { valueAsNumber: true })}
          placeholder='Masukkan Tahun' />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Anggaran" className="w-1/2 text-xl text-black">
          Anggaran
        </Label>
        <Input
          id="Anggaran"
          min="0"
          value={displayAnggaran}
          onChange={handleAnggaranChange}
          placeholder='Masukkan Anggaran' />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Total Alokasi APBD" className="w-1/2 text-xl text-black">
          Total Alokasi APBD Provinsi
        </Label>
        <Input
          id="Total Alokasi APBD"
          min="0"
          className="disabled:opacity-100 font-black"
          disabled
          value={displayTotalAPBD} />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Jenis Bantuan Keuangan" className="w-1/2 text-xl text-black">
          Jenis Bantuan Keuangan
        </Label>
        <Input
          id="Jenis Bantuan Keuangan"
          value={watch("jenis_bantuan_keuangan")}
          {...register("jenis_bantuan_keuangan")}
          placeholder='Masukkan Jenis Bantuan Keuangan' />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Komponen" className="w-1/2 text-xl text-black">
          Komponen
        </Label>
        <Select value={watch("komponen_id")} onValueChange={(val) => handleKomponenChange(val)}>
          <SelectTrigger>
            <SelectValue placeholder="Pilih Komponen">{watch("komponen_name")}</SelectValue>
          </SelectTrigger>
          <SelectContent className="z-999">
            {!isLoadingKomponen && getKomponen.map((item: any, index: any) => (
              <SelectGroup key={index}>
                <SelectItem className="cursor-pointer p-1" value={item.id_komponen}>
                  {item.komponen_name}
                </SelectItem>
              </SelectGroup>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Volume" className="w-1/2 text-xl text-black">
          Volume
        </Label>
        <Input
          id="Volume"
          type="number"
          min={0}
          value={watch("volume")}
          {...register("volume", { valueAsNumber: true })}
          placeholder='Masukkan Volume' />
      </div>
      <div className="flex items-center p-6 gap-4 border-b border-slate-200">
        <Label htmlFor="Satuan" className="w-1/2 text-xl text-black">
          Satuan
        </Label>
        <Select value={watch("satuan_id")} onValueChange={handleSatuanChange}>
          <SelectTrigger>
            <SelectValue placeholder="Pilih Satuan">{watch("satuan_name")}</SelectValue>
          </SelectTrigger>
          <SelectContent className="z-999">
            {!isLoadingSatuan && getSatuan.map((item: any, index: any) => (
              <SelectGroup key={index}>
                <SelectItem className="cursor-pointer p-1" value={item.id_satuan}>
                  {item.satuan_name}
                </SelectItem>
              </SelectGroup>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="mt-10 mb-8 mx-4">
        <Button className="text-primary hover:bg-transparent text-xl h-[60px] bg-transparent font-bold border-2 w-full border-primary" onClick={() => handleSubmit(onSubmit)()}>
          Simpan Bantuan Keuangan
        </Button>
      </div>
    </ScrollArea>
  )
}

export default FormBantuanKeuanganEdit