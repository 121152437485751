import { RootState } from "@/app/store"
import { CompLoader } from "@/common/Loader"
import { Button } from "@/components/ui/Button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useGetJenisProgramRelokasi } from "@/hooks/perumahan/useRumahBencana"
import {
  useEditDataCapaianRumahRelokasiById,
  useGetDataCapaianRumahRelokasiById,
} from '@/hooks/perumahan/useRumahRelokasi';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FiArrowLeft } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useGetKabKota } from "@/hooks/wilayah/useLokasi"

type FormValue = {
  id_eksis_relokasi: number;
  tahun_pelaporan: number;
  menu_id: number;
  kd_provinsi: number;
  kd_kabkota: number;
  kd_kec: number;
  kd_desa: number;
  jenis_relokasi_id: number;
  jml_rmh_relokasi: number;
  tgt_ganti_uang: number;
  tgt_bgn_kembali: number;
  tgt_subsidi: number;
  tgt_akses_rmh: number;
  real_ganti_uang: number;
  real_bgn_kembali: number;
  real_subsidi: number;
  real_akses_rmh: number;
};

const EditFormDataCapaianKumulatifRumahRelokasiProv = ({
  setShowForm,
  editableId,
}: {
  setShowForm: any;
  editableId: number;
}) => {
  const tahun = useSelector((state: RootState) => state.tahun.value);
  const user = useSelector((state: RootState) => state.auth);
  const menuId = useSelector((state: RootState) => state.menuId.value);

  const { getEksisRelokasiById, isLoading: loadEksisRelokasiById } =
    useGetDataCapaianRumahRelokasiById(editableId);
  const { editEksisRelokasiById, isPending: pendingEditEksisRelokasiById } =
    useEditDataCapaianRumahRelokasiById();

  const queryClient = useQueryClient();

  const { register, setValue, handleSubmit, watch, reset } = useForm<FormValue>(
    {
      defaultValues: {
        id_eksis_relokasi: 0,
        tahun_pelaporan: Number(tahun),
        menu_id: menuId,
        kd_provinsi: user.kd_provinsi,
        kd_kabkota: 0,
        kd_kec: 0,
        kd_desa: 0,
        jenis_relokasi_id: 0,
        jml_rmh_relokasi: 0,
        tgt_ganti_uang: 0,
        tgt_bgn_kembali: 0,
        tgt_subsidi: 0,
        tgt_akses_rmh: 0,
        real_ganti_uang: 0,
        real_bgn_kembali: 0,
        real_subsidi: 0,
        real_akses_rmh: 0,
      },
    },
  );

  const handleJenisRelokasi = (val: any) => {
    setValue('jenis_relokasi_id', Number(val));
  };

  const handleKecamatan = (val: any) => {
    setValue('kd_kec', Number(val));
  };

  const handleKabKota = (val: any) => {
    setValue('kd_kabkota', Number(val));
  };

  const { getKabKota, isLoading: isLoadingKabkota } = useGetKabKota(user.kd_provinsi)
  const { getKecamatan, isLoading: isLoadingKec } = useGetKecamatan(
    user.kd_provinsi,
    watch("kd_kabkota"),
  );

  const { getJenisProgramRelokasi, isLoading: isLoadingRelokasi } =
    useGetJenisProgramRelokasi();

  useEffect(() => {
    if (!loadEksisRelokasiById) {
      setValue('id_eksis_relokasi', getEksisRelokasiById.id_eksis_relokasi);
      setValue('jenis_relokasi_id', getEksisRelokasiById.jenis_relokasi_id);
      setValue('kd_kabkota', getEksisRelokasiById.kd_kabkota);
      setValue('kd_kec', getEksisRelokasiById.kd_kec);
      setValue('kd_desa', getEksisRelokasiById.kd_desa);
      setValue('jml_rmh_relokasi', getEksisRelokasiById.jml_rmh_relokasi);
      setValue('tgt_ganti_uang', getEksisRelokasiById.tgt_ganti_uang);
      setValue('tgt_bgn_kembali', getEksisRelokasiById.tgt_bgn_kembali);
      setValue('tgt_subsidi', getEksisRelokasiById.tgt_subsidi);
      setValue('tgt_akses_rmh', getEksisRelokasiById.tgt_akses_rmh);
      setValue('real_ganti_uang', getEksisRelokasiById.real_ganti_uang);
      setValue('real_bgn_kembali', getEksisRelokasiById.real_bgn_kembali);
      setValue('real_subsidi', getEksisRelokasiById.real_subsidi);
      setValue('real_akses_rmh', getEksisRelokasiById.real_akses_rmh);
    }
  }, [loadEksisRelokasiById]);

  const onSubmit = async (data: any) => {
    try {
      await editEksisRelokasiById(data);
      queryClient.refetchQueries({ queryKey: ['eksis-relokasi'] });
      reset();
      setShowForm('Lihat Data');
      toast.success('Berhasil menambahkan data capaian Rumah Relokasi');
    } catch (error) {
      toast.error('Gagal menambahkan data capaian Rumah Relokasi');
    }
  };

  if (
    isLoadingRelokasi ||
    isLoadingKabkota ||
    isLoadingKec ||
    pendingEditEksisRelokasiById
  )
    return <CompLoader />;

  return (
    <>
      <div className="flex justify-end pb-4">
        <div className="flex gap-4">
          <Button
            className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
            onClick={() => setShowForm('Lihat Data')}
          >
            <FiArrowLeft />
            Kembali
          </Button>
        </div>
      </div>
      {/* <p>TODO: Get Rencana Relokasi By Id</p>
      <p>TODO: Edit Rencana Relokasi By Id </p> */}
      <div className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <ScrollArea className="h-full">
          <div className="flex items-center gap-4 border-b border-slate-200 p-6">
            <Label
              htmlFor="Jenis Program Relokasi"
              className="w-1/2 text-xl text-black"
            >
              Jenis Program Relokasi
            </Label>
            <Select
              value={watch('jenis_relokasi_id')}
              onValueChange={handleJenisRelokasi}
            >
              <SelectTrigger>
                <SelectValue placeholder="Pilih Jenis Bencana" />
              </SelectTrigger>
              <SelectContent className="z-99999">
                {!isLoadingRelokasi &&
                  getJenisProgramRelokasi.map(
                    (jenis_relokasi: any, index: any) => (
                      <SelectItem
                        key={index}
                        value={jenis_relokasi.id_jenis_relokasi}
                      >
                        {jenis_relokasi.nama_jenis_relokasi}
                      </SelectItem>
                    ),
                  )}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center gap-4 border-b border-slate-200 p-6">
            <Label htmlFor="Kecamatan" className="w-1/2 text-xl text-black">
              Kabupaten/Kota
            </Label>
            <Select value={watch('kd_kabkota')} onValueChange={handleKabKota}>
              <SelectTrigger>
                <SelectValue placeholder="Pilih Kabupaten/Kota" />
              </SelectTrigger>
              <SelectContent className="z-99999">
                {!isLoadingKabkota &&
                  getKabKota.map((kabkota: any, index: any) => (
                    <SelectItem key={index} value={kabkota.kd_kabkota}>
                      {kabkota.nama_kabkota}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center gap-4 border-b border-slate-200 p-6">
            <Label htmlFor="Kecamatan" className="w-1/2 text-xl text-black">
              Kecamatan
            </Label>
            <Select value={watch('kd_kec')} onValueChange={handleKecamatan}>
              <SelectTrigger>
                <SelectValue placeholder="Pilih Kecamatan" />
              </SelectTrigger>
              <SelectContent className="z-99999">
                {!isLoadingKec &&
                  getKecamatan.map((kecamatan: any, index: any) => (
                    <SelectItem key={index} value={kecamatan.kd_kec}>
                      {kecamatan.nama_kecamatan}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center gap-4 border-b border-slate-200 p-6">
            <Label
              htmlFor="Jenis Rumah Terkena Relokasi"
              className="w-1/2 text-xl text-black"
            >
              Jumlah Rumah Terkena Relokasi
            </Label>
            <Input
              id="Jumlah Rumah Terkena Relokasi"
              type="number"
              min="0"
              {...register('jml_rmh_relokasi', { valueAsNumber: true })}
              placeholder="Masukkan Jumlah Rumah Terkena Relokasi"
            />
          </div>
          <div className="flex">
            <div className="basis-1/2">
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Target"
                  className="w-1/2 text-2xl font-black text-black"
                >
                  Target
                </Label>
              </div>
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Penggantian Uang (Target)"
                  className="w-1/2 text-xl text-black"
                >
                  Penggantian Uang
                </Label>
                <Input
                  id="Penggantiang Uang (Target)`"
                  type="number"
                  min="0"
                  {...register('tgt_ganti_uang', { valueAsNumber: true })}
                  placeholder="Masukkan Penggantiang Uang (Target)"
                />
              </div>
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Pembangunan Kembali (Target)"
                  className="w-1/2 text-xl text-black"
                >
                  Pembangunan Kembali
                </Label>
                <Input
                  id="Pembangunan Kembali (Target)"
                  type="number"
                  min="0"
                  {...register('tgt_bgn_kembali', { valueAsNumber: true })}
                  placeholder="Masukkan Pembangunan Kembali (Target)"
                />
              </div>
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Subsidi Uang Sewa (Target)"
                  className="w-1/2 text-xl text-black"
                >
                  Subsidi Uang Sewa
                </Label>
                <Input
                  id="Subsidi Uang Sewa (Target)"
                  type="number"
                  min="0"
                  {...register('tgt_subsidi', { valueAsNumber: true })}
                  placeholder="Masukkan Subsidi Uang Sewa (Target)"
                />
              </div>
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Akses Tinggal Rumah Sewa (Target)"
                  className="w-1/2 text-xl text-black"
                >
                  Akses Tinggal Rumah Sewa
                </Label>
                <Input
                  id="Akses Tinggal Rumah Sewa (Target)"
                  type="number"
                  min="0"
                  {...register('tgt_akses_rmh', { valueAsNumber: true })}
                  placeholder="Masukkan Akses Tinggal Rumah Sewa (Target)"
                />
              </div>
            </div>
            <div className="basis-1/2">
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Jenis Bencana"
                  className="w-1/2 text-2xl font-black text-black"
                >
                  Realisasi
                </Label>
              </div>
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Penggantian Uang (Realisasi)"
                  className="w-1/2 text-xl text-black"
                >
                  Penggantian Uang
                </Label>
                <Input
                  id="Penggantiang Uang (Realisasi)`"
                  type="number"
                  min="0"
                  {...register('real_ganti_uang', { valueAsNumber: true })}
                  placeholder="Masukkan Penggantiang Uang (Realisasi)"
                />
              </div>
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Pembangunan Kembali (Realisasi)"
                  className="w-1/2 text-xl text-black"
                >
                  Pembangunan Kembali
                </Label>
                <Input
                  id="Pembangunan Kembali (Realisasi)"
                  type="number"
                  min="0"
                  {...register('real_bgn_kembali', { valueAsNumber: true })}
                  placeholder="Masukkan Pembangunan Kembali (Realisasi)"
                />
              </div>
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Subsidi Uang Sewa (Realisasi)"
                  className="w-1/2 text-xl text-black"
                >
                  Subsidi Uang Sewa
                </Label>
                <Input
                  id="Subsidi Uang Sewa (Realisasi)"
                  type="number"
                  min="0"
                  {...register('real_subsidi', { valueAsNumber: true })}
                  placeholder="Masukkan Subsidi Uang Sewa (Realisasi)"
                />
              </div>
              <div className="flex items-center gap-4 border-b border-slate-200 p-6">
                <Label
                  htmlFor="Akses Tinggal Rumah Sewa (Realisasi)"
                  className="w-1/2 text-xl text-black"
                >
                  Akses Tinggal Rumah Sewa
                </Label>
                <Input
                  id="Akses Tinggal Rumah Sewa (Realisasi)"
                  type="number"
                  min="0"
                  {...register('real_akses_rmh', { valueAsNumber: true })}
                  placeholder="Masukkan Akses Tinggal Rumah Sewa (Realisasi)"
                />
              </div>
            </div>
          </div>
          <div className="mt-5 flex justify-between gap-5 p-4">
            <div className="w-full">
              <button
                className="inline-flex min-w-full items-center justify-center rounded-md border border-meta-3 px-10 py-4 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
                onClick={() => handleSubmit(onSubmit)()}
              >
                Simpan Data
              </button>
            </div>
          </div>
        </ScrollArea>
      </div>
    </>
  );
};

export default EditFormDataCapaianKumulatifRumahRelokasiProv