import { API } from '../libs/API';
import { useQuery } from '@tanstack/react-query';

export function useSumberAnggaran(){
	const {
		data: getSumberAnggaran,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['sumber-anggaran'],
		queryFn: async () => {
			const { data } = await API.get(`/sumber-anggaran`);
			return data.data;
		},
	});
	return { getSumberAnggaran, refetch, isLoading };
}
