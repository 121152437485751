import { useState } from 'react';
import { useSelector } from 'react-redux';

import DefaultLayout from '@/layout/DefaultLayout';
import FormDataCapaianAM from '@/components/Forms/AirMinum/DataCapaian/FormDataCapaianAM';
import EditDataCapaianAM from '@/components/Forms/AirMinum/DataCapaian/EditDataCapaianAM';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import { RootState } from '@/app/store';
import TableDataCapaianKumulatif from '@/components/Tables/AirMinum/DataCapaian/Kumulatif/KabKota/TableCapaianKumulatif';
import DataCapaianKumulatifAMProv from './KumulatifProv';

const DataCapaianKumulatifAM = () => {
  const privilege = useSelector((state: RootState) => state.auth.privilege.id_privilege);

  const [sampleId, setSampleId] = useState(1)
  const [showForm, setShowForm] = useState("Pilih Data")

  const handleSampleChange = (e: number) => {
    setSampleId(e)
  }

  if (privilege === 2 || privilege === 4 || privilege === 8 || privilege === 10) return <DataCapaianKumulatifAMProv />

  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead="Data Capaian Kumulatif Air Minum Kabupaten/Kota"
        pageName="Data Capaian Kumulatif Air Minum Kabupaten/Kota"
      />
      {showForm === "Pilih Data" &&
        <TableDataCapaianKumulatif sampleId={sampleId} setShowForm={setShowForm} handleSampleChange={handleSampleChange} />}
      {showForm === "Form Data" &&
        <FormDataCapaianAM
          sampleId={sampleId}
          handleSampleChange={handleSampleChange}
          setShowForm={setShowForm}
        />}
      {showForm === "Edit Data" && <EditDataCapaianAM setShowForm={setShowForm} sampleId={sampleId} />}
    </DefaultLayout>
  );
};

export default DataCapaianKumulatifAM;
