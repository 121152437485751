import { useDispatch } from 'react-redux';
import { API, setAuthToken } from '../libs/API';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { setAuth } from '../app/auth/loginSlice';

export function useLogin() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		mutate: userLogin,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/login', data);
			// console.log(response.data);
			return response;
		},
		onSuccess: (result) => {
			dispatch(setAuth(result.data.login));
			const token = result.headers['authorization'].split(' ')[1];
			setAuthToken(token);
			if (token) {
				localStorage.setItem('token', token);
			}
			navigate('/');
			toast.success('Selamat Datang');
			//* set dengan response */
			// dispatch(setAuth(result.data.login));
			// setAuthToken(result.data.token);
			// if (result.data.token) {
			// 	localStorage.setItem('token', result.data.token);
			// }
			// navigate('/');
			// toast.success('Selamat Datang');
			//* */
		},
		onError: (error) => {
			console.log(error);
			toast.error('Username atau password salah');
			navigate('/auth/login');
		},
	});
	return { userLogin, isPending, isSuccess };
}
