import { API } from "@/libs/API";
import { useMutation, useQuery } from "@tanstack/react-query";

export function usePostBantuanKeuangan() {
  const {
    mutateAsync: postBantuanKeuangan,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await API.post(`/bantuan-keuangan/`, data);
      return response;
    },
  });
  return { postBantuanKeuangan, isPending, isSuccess };
}

export function useGetBantuanKeuangan(tahun: number, kd_provinsi: number, menu_id: number, bidang: any, wilayah: any, privilege: any) {
  const { data: getBantuanKeuangan, isLoading } = useQuery({
    queryKey: ["bantuan-keuangan", tahun, menu_id, kd_provinsi, bidang, wilayah, privilege],
    queryFn: async () => {
      const { data } = await API.get(`/bantuan-keuangan/?kd_provinsi=${kd_provinsi}&tahun=${tahun}&menu_id=${menu_id}`)
      return data.data
    }
  })

  return { getBantuanKeuangan, isLoading }
}

export function useGetBantuanKeuanganById(id: number) {
  const { data: getBantuanKeuanganById, isLoading } = useQuery({
    queryKey: ["bantuan-keuangan-id", id],
    queryFn: async () => {
      const { data } = await API.get(`/bantuan-keuangan-id/?id_bk=${id}`)
      return data.data
    }
  })

  return { getBantuanKeuanganById, isLoading }
}

export function useEditBantuanKeuangan() {
  const {
    mutateAsync: asyncEditBantuanKeuangan,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await API.put(`/bantuan-keuangan/?id_bk=${data.id_bk}`, data);
      return response;
    },
  });
  return { asyncEditBantuanKeuangan, isPending, isSuccess };
}

export function useDeleteBantuanKeuangan() {
  const {
    mutateAsync: deleteBantuanKeuangan,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async (id_bk: number) => {
      const response = await API.delete(
        `/bantuan-keuangan/?id_bk=${id_bk}`,
      );
      return response;
    }
  });
  return { deleteBantuanKeuangan, isPending, isSuccess };
}