import React, { useState } from 'react';
import { FiPlusCircle } from 'react-icons/fi';
import DefaultLayout from '../../layout/DefaultLayout';
import AlertSuccess from '@/components/Alerts/AlertSuccess';
import FormUploadPerumahan from '@/components/Forms/RumahRelokasi/DataDasar/FormUploadPerumahan';
import TableDataDasarPerumahan from '@/components/Tables/TableDataDasarPerumahan';
import { useNavigate } from 'react-router-dom';

const DataDasarPerumahan: React.FC = () => {
	const [showForm, setShowForm] = useState<boolean>(false);
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const navigate = useNavigate();
	const handleInputClick = (): void => {
		setShowForm(true);
	};
	const handleNavigate = (): void => {
		navigate('/data-dasar/');
		setShowForm(false);
	};
	const handleUploadSuccess = (): void => {
		// alert('Upload successful');
		setShowAlert(true);
		setShowForm(false);
		setTimeout(() => {
			setShowAlert(false);
		}, 5000);
	};
	return (
		<DefaultLayout>
			<div className="mb-5 flex">
				<div className="flex flex-grow justify-start text-3xl font-bold text-[#364878] dark:text-white">
					<span>Data Dasar Perumahan</span>
				</div>
				<div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
					<div className="flex w-full flex-grow justify-end text-lg font-bold md:w-auto">
						{showForm ? (
							<button
								className="inline-flex items-center justify-center rounded-lg bg-primary p-2 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
								onClick={handleNavigate}
							>
								kembali
							</button>
						) : (
							<button
								onClick={handleInputClick}
								className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
							>
								<FiPlusCircle />
								Input Data
							</button>
						)}
					</div>

					<div className="mt-2 flex w-full flex-grow justify-end text-lg font-bold md:mt-0 md:w-auto">
						<button className="inline-flex items-center justify-center gap-1 rounded-lg bg-[#364878] px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiPlusCircle />
							Upload SK TIM
						</button>
					</div>
				</div>
			</div>

			{/* <div className="grid grid-cols-1 gap-9">
				<div className="flex flex-col gap-9"> */}
			{/* <!-- File upload --> */}
			{showAlert && (
				<AlertSuccess
					title="Upload Successful"
					message="Your file has been uploaded successfully."
				/>
			)}
			{showForm ? (
				<FormUploadPerumahan onUploadSuccess={handleUploadSuccess} />
			) : (
				<TableDataDasarPerumahan />
			)}
			{/* </div>
			</div> */}
		</DefaultLayout>
	);
};

export default DataDasarPerumahan;
