import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { FiInfo } from 'react-icons/fi';

const GLahanPotensial = () => {
    let rowCounter = 0;

    const panduanData = [
        {
            id: 1,
            title: "Kecamatan",
            description: "Diisi nama Kecamatan"
        },
        {
            id: 2,
            title: "Kelurahan/Desa",
            description: "Diisi nama Kelurahan/Desa"
        },
        {
            id: 3,
            title: "Luas Lahan",
            description: "Diisi luas lahan yang potensial sebagai lokasi relokasi perumahan"
        },
        {
            id: 4,
            title: "Pemilik",
            description: "Diisi pemilik lahan yang potensial sebagai lokasi relokasi perumahan"
        },
        {
            id: 5,
            title: "Keterangan",
            description: "Diisi keterangan yang diperlukan"
        }

    ];

    return (
			<>
				<Dialog>
					<DialogTrigger asChild>
						<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-green-600 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiInfo />
							Panduan
						</Button>
					</DialogTrigger>
					<DialogContent className="z-99999 max-h-[85%] max-w-[65%] overflow-auto rounded-lg">
						<DialogHeader>
							<DialogTitle>
								Panduan Pengisian Form Identifikasi Lahan Potensial Sebagai
								Lokasi Relokasi Perumahan
							</DialogTitle>
							<DialogDescription>
								Silahkan ikuti panduan dibawah ini untuk melakukan pengisian
								form atau input data
							</DialogDescription>
						</DialogHeader>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="border-l text-center">No</TableHead>
									<TableHead className="text-center">Kolom</TableHead>
									<TableHead className="text-center">Keterangan</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{panduanData.map((data) => (
									<TableRow
										key={data.id}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="text-start">{data.title}</TableCell>
										<TableCell className="text-start">
											{data.description}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<DialogFooter>
							<div className="mt-5 flex w-full items-center justify-end">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Tutup
									</Button>
								</DialogClose>
							</div>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</>
		);
};

export default GLahanPotensial;
