import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiEdit, FiPlusCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import KecamatanSelectUI from '@/components/ui/SelectKecamatanUI';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { FormEvent, useEffect, useState } from 'react';
import DesaSelectUI from '@/components/ui/SelectDesaUI';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useQueryClient } from '@tanstack/react-query';
import { useEditPerumahanRTRW, usePostPerumahanRTRW } from '@/hooks/perumahan/useRumahRelokasi';
import { useGetJenisLokasiTdkSesuai } from '@/hooks/useJenisLokasiTdkSesuai';
import { IdentifikasiDataPerumahanRTRW } from '@/types/perumahan';
import EditDesaSelectUI from '@/components/ui/SelectEditDesa';
import EditKecamatanSelectUI from '@/components/ui/SelectEditKecamatan';
import EditJenisLokasiTidakSesuaiSelectUI from '@/components/ui/SelectEditJenisLksTdkSesuaiUI';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import EditKabKotaSelectUI from '@/components/ui/SelectEditKabupatenKotaUI';

interface EditIdentifikasiRumahRelokasiProps {
    rowData: IdentifikasiDataPerumahanRTRW;
}

const EIdentifikasiPerumahanRTRW : React.FC<EditIdentifikasiRumahRelokasiProps> = ({
    rowData,
}) => {
    const user = useSelector((state: RootState) => state.auth);
    const menuId = useSelector((state: RootState) => state.menuId.value);

    //wilayah
    const [kd_kabkota, setKd_kabkota] = useState<number>(rowData.kd_kabkota);
    const [kd_kec, setKd_kec] = useState<number>(rowData.kd_kec);
    const [kd_desa, setKd_desa] = useState<number>(rowData.kd_desa);

    //get
    const { getKabKota } = useGetKabKota(user.kd_provinsi);
    const { getKecamatan } = useGetKecamatan(
        user.kd_provinsi,
        kd_kabkota,
    );
    const {
        getDesa,
        refetch: fetchDesa,
    } = useGetDesa(user.kd_provinsi, kd_kabkota, kd_kec);
    const { getJenisLokasiTdkSesuai } = useGetJenisLokasiTdkSesuai();
    const { editPerumahanRTRW } = useEditPerumahanRTRW();

    //dataForm
    const [luasLahan, setLuasLahan] = useState(rowData.luas_lahan);
    const [jumlahRumah, setJumlahRumah] = useState(
			(rowData.jml_rumah ?? 0).toString(),
		);
		const [jumlahKK, setJumlahKK] = useState((rowData.jml_kk ?? 0).toString());
		const [statusLegal, setStatusLegal] = useState(
			(rowData.status_legal ?? 0).toString(),
		);
		const [statusIllegal, setStatusIllegal] = useState(
			(rowData.status_illegal ?? 0).toString(),
		);
    const [ jenisLokasiTdkSesuai, setJenisLokasiTdkSesuai] = useState<number>(rowData.jenis_lks_tdk_sesuai_id);

    //additionals
    const [modalOpen, setModalOpen] = useState(false);
    const queryClient = useQueryClient(); 

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        const data = {
            id_idn_perumahan_rtrw: rowData.id_idn_perumahan_rtrw,
            // kd_kec: parseInt(kd_kec),
            // kd_desa: parseInt(kd_desa),
            luas_lahan: luasLahan,
            jml_rumah: parseInt(jumlahRumah),
            jml_kk: parseInt(jumlahKK),
            status_legal: parseInt(statusLegal),
            status_illegal: parseInt(statusIllegal),
            jenis_lks_tdk_sesuai_id: jenisLokasiTdkSesuai,
        }
        editPerumahanRTRW(data,
            {
				onSuccess: () => {
					setModalOpen(false);
                    queryClient.refetchQueries({queryKey:['identifikasi-perumahan-rtrw']});
				},
				onError: (error) => {
					console.log(error);
				},
			}
        )
    }

    useEffect(() => {
        fetchDesa();
    }, [kd_kec]);

    return (
        <>
            <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger asChild>
                    <Button
                        size={'sm'} 
                        className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'>
                        <FiEdit />
                    </Button>
                </DialogTrigger>
                <DialogContent className="mx-auto max-w-screen-xl z-99999">
                    <DialogHeader>
                        <DialogTitle>
                            Edit Data Identifikasi Perumahan Di Lokasi Yang Tidak Sesuai Rencana Tata Ruang Wilayah
                        </DialogTitle>
                        <DialogDescription>
                            Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
                        </DialogDescription>
                    </DialogHeader>
                    <form action='post' onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Jenis Lahan" className="mb-2">
                                    Jenis Lokasi yang Tidak Sesuai Rencana Tata Ruang Wilayah
                                </Label>
                                <EditJenisLokasiTidakSesuaiSelectUI
                                    getJenisLokasi={getJenisLokasiTdkSesuai}
                                    setJenisLokasi={setJenisLokasiTdkSesuai}
                                    selectedValue={jenisLokasiTdkSesuai}
                                    disabledSelect={false}

                                />
                            </div>
                            { menuId === 8 && (
                                <div className="flex flex-col">
                                <Label htmlFor="Kabupaten" className="mb-2">
                                    Kabupaten
                                </Label>
                                <EditKabKotaSelectUI
                                    getKabKota={getKabKota}
                                    setKabKota={setKd_kabkota}
                                    selectedValue={kd_kabkota}
                                    disabledSelect={true}
                                />
                            </div>
                            )}
                            <div className="flex flex-col">
                                <Label htmlFor="Kecamatan" className="mb-2">
                                    Kecamatan
                                </Label>
                                <EditKecamatanSelectUI
                                    getKecamatan={getKecamatan}
                                    setKd_kec={setKd_kec}
                                    selectedValue={kd_kec}
                                    disabledSelect={true}
                                />
                            </div>
                            { menuId !== 8 && (
                                <div className="flex flex-col">
                                    <Label htmlFor="Kelurahan/Desa" className="mb-2">
                                        Kelurahan/Desa
                                    </Label>
                                    <EditDesaSelectUI
                                        getDesa={getDesa}
                                        setKd_desa={setKd_desa}
                                        selectedValue={kd_desa}
                                        kd_kec={kd_kec}
                                        disabledSelect={true}
                                    />
                                </div> 
                            )}
                            <div className="flex flex-col">
                                <Label htmlFor="Luas Lahan" className="mb-2">
                                    Luas Lahan (Ha)
                                </Label>
                                <Input
                                    id="Luas Lahan"
                                    type="number"
                                    className="col-span-1"
                                    value={luasLahan}
                                    onChange={(e) => setLuasLahan(e.target.value)}
                                    placeholder='Masukkan Luas Lahan'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah Rumah" className="mb-2">
                                    Jumlah Rumah (Unit)
                                </Label>
                                <Input
                                    id="Jumlah Rumah"
                                    type="number"
                                    className="col-span-1"
                                    value={jumlahRumah}
                                    onChange={(e) => setJumlahRumah(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah KK" className="mb-2">
                                    Jumlah KK
                                </Label>
                                <Input
                                    id="Jumlah KK"
                                    type="number"
                                    className="col-span-1"
                                    value={jumlahKK}
                                    onChange={(e) => setJumlahKK(e.target.value)}
                                    placeholder='Masukkan Jumlah KK'
                                />
                            </div>
                       	</div>
                        {/* Status Kepemilikan Tanah */}
                        <h1 className='text-l font-bold mt-4'>Status Kepemilikan Tanah (Unit Rumah)</h1>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Legal" className="mb-2">
                                    Legal
                                </Label>
                                <Input
                                    id="Legal"
                                    type="number"
                                    className="col-span-1"
                                    value={statusLegal}
                                    onChange={(e) => setStatusLegal(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Legal'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Illegal" className="mb-2">
                                    Illegal
                                </Label>
                                <Input
                                    id="Illegal"
                                    type="number"
                                    className="col-span-1"
                                    value={statusIllegal}
                                    onChange={(e) => setStatusIllegal(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Illegal'
                                />
                            </div>
                        </div>
                        <DialogFooter>
                            <div className="flex w-full justify-between mt-5">
                                <DialogClose asChild>
                                    <Button
                                        className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
                                    >
                                        Batal
                                    </Button>
                                </DialogClose>
                                <Button
                                    className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                                    type="submit"
                                >
                                    Simpan
                                </Button>
                            </div>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EIdentifikasiPerumahanRTRW;

