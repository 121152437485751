import { API } from "@/libs/API";
import { useMutation, useQuery } from "@tanstack/react-query";

export function usePostAPBDPerProv() {
  const {
    mutateAsync: postAPBDPerProv,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await API.post(`/total-apbd-prov/`, data);
      return response;
    },
  });
  return { postAPBDPerProv, isPending, isSuccess };
}

export function useGetAPBDPerProv(tahun: number, kd_provinsi: number, menu_id: number) {
  const { data: getAPBDPerProv, isLoading } = useQuery({
    queryKey: ["total-apbd-prov", tahun, menu_id, kd_provinsi],
    queryFn: async () => {
      const { data } = await API.get(`/total-apbd-prov/?tahun=${tahun}&menu_id=${menu_id}&kd_provinsi=${kd_provinsi}`)
      return data.data
    }
  })

  return { getAPBDPerProv, isLoading }
}

export function useEditAPBDPerProv() {
  const {
    mutateAsync: asyncEditAPBDPerProv,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await API.put(`/total-apbd-prov/?id_bk_apbd=${data.id_bk_apbd}`, data);
      return response;
    },
  });
  return { asyncEditAPBDPerProv, isPending, isSuccess };
}