import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { FormEvent, useState, useEffect } from 'react';
import { useSumberAnggaran } from '@/hooks/useSumberAnggaran';
import { useJenisPelayanan } from '@/hooks/useJenisPelayanan';
import { useEditTargetPenyelesaian } from '@/hooks/perumahan/useRumahBencana';
import { useQueryClient } from '@tanstack/react-query';
import { EksisTargetPenyelesaianBencana } from '@/types/perumahan';
import EditJenisPelayananUI from '@/components/ui/SelectEditJenisPelayanan';
import EditSumberAnggaranSelectUI from '@/components/ui/SelectEditSumberAnggaran';
import { formatRupiah } from '@/libs/utils';

interface ETargetPenyelesaianProps {
    rowData: EksisTargetPenyelesaianBencana
}

const ETargetPenyelesaian: React.FC<ETargetPenyelesaianProps> = ({
    rowData
}) => {
	const user = useSelector((state: RootState) => state.auth);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//dataform
	const [jenisPelayanan, setJenisPelayanan] = useState<number>(
		rowData.jenis_pelayanan_id ?? 0,
	);
	const [jumlahRumahTangga, setJumlahRumahTangga] = useState(
		(rowData.tgt_jml_rmh ?? 0).toString(),
	);
	const [jumlahRumahTangga1, setJumlahRumahTangga1] = useState(
		(rowData.jml_rmh_1 ?? 0).toString(),
	);
	const [jumlahRumahTangga2, setJumlahRumahTangga2] = useState(
		(rowData.jml_rmh_2 ?? 0).toString(),
	);
	const [jumlahRumahTangga3, setJumlahRumahTangga3] = useState(
		(rowData.jml_rmh_3 ?? 0).toString(),
	);
	const [kebutuhanBiaya, setKebutuhanBiaya] = useState(
		(rowData.kebutuhan_biaya ?? 0).toString(),
	);
	const [kebutuhanBiaya1, setKebutuhanBiaya1] = useState(
		(rowData.kebutuhan_biaya_1 ?? 0).toString(),
	);
	const [kebutuhanBiaya2, setKebutuhanBiaya2] = useState(
		(rowData.kebutuhan_biaya_2 ?? 0).toString(),
	);
	const [kebutuhanBiaya3, setKebutuhanBiaya3] = useState(
		(rowData.kebutuhan_biaya_3 ?? 0).toString(),
	);

	const [kebutuhanBiayaDisplay, setKebutuhanBiayaDisplay] = useState(
		formatRupiah(rowData.kebutuhan_biaya ?? 0),
	);
	const [kebutuhanBiaya1Display, setKebutuhanBiaya1Display] = useState(
		formatRupiah(rowData.kebutuhan_biaya_1 ?? 0),
	);
	const [kebutuhanBiaya2Display, setKebutuhanBiaya2Display] = useState(
		formatRupiah(rowData.kebutuhan_biaya_2 ?? 0),
	);
	const [kebutuhanBiaya3Display, setKebutuhanBiaya3Display] = useState(
		formatRupiah(rowData.kebutuhan_biaya_3 ?? 0),
	);
	
	const [sumberAnggaran1, setSumberAnggaran1] = useState<number>(
		rowData.sumber_dana_id_1 ?? 0,
	);
	const [sumberAnggaran2, setSumberAnggaran2] = useState<number>(
		rowData.sumber_dana_id_2 ?? 0,
	);
	const [sumberAnggaran3, setSumberAnggaran3] = useState<number>(
		rowData.sumber_dana_id_3 ?? 0,
	);

	//get
	const { getSumberAnggaran } = useSumberAnggaran();
	const { getJenisPelayanan } = useJenisPelayanan();
	const { editTargetPenyelesaian } = useEditTargetPenyelesaian();

	//additionals
	const queryClient = useQueryClient();
	const [modalOpen, setModalOpen] = useState(false);

	const handleChangeKebutuhanBiaya = (
		setter: any,
		displaySetter: any
	) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const numericValue = parseInt(value.replace(/[^0-9]/g, ''), 10);
	
		if (isNaN(numericValue)) {
			setter(0);
			displaySetter('Rp 0');
		} else {
			setter(numericValue);
			displaySetter(formatRupiah(numericValue));
		}
	};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();

		const data = {
            id_bencana_target: rowData.id_bencana_target,
			kd_kabkota: user.kd_kabkota,
			jenis_pelayanan_id: jenisPelayanan,
			tgt_jml_rmh: parseInt(jumlahRumahTangga),
			jml_rmh_1: parseInt(jumlahRumahTangga1),
			jml_rmh_2: parseInt(jumlahRumahTangga2),
			jml_rmh_3: parseInt(jumlahRumahTangga3),
			kebutuhan_biaya: parseInt(kebutuhanBiaya),
			kebutuhan_biaya_1: parseInt(kebutuhanBiaya1),
			kebutuhan_biaya_2: parseInt(kebutuhanBiaya2),
			kebutuhan_biaya_3: parseInt(kebutuhanBiaya3),
			sumber_dana_id_1: sumberAnggaran1,
			sumber_dana_id_2: sumberAnggaran2,
			sumber_dana_id_3: sumberAnggaran3
		};

		// console.log(data);

		editTargetPenyelesaian(data,
            {
				onSuccess: () => {
					setModalOpen(false);
					queryClient.refetchQueries({queryKey:['eksis-bencana-target']});
				},
				onError: (error) => {
					console.log(error);
				},
			}
        )
	}
	
	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger asChild>
                    <Button
                        size={'sm'} 
                        className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'>
                        <FiEdit />
                    </Button>
                </DialogTrigger>
				<DialogContent className="mx-auto max-w-screen-xl my-2 z-99999">
					<DialogHeader>
						<DialogTitle>
							Edit Data Target Penyelesaian Rumah Korban Bencana
						</DialogTitle>
						<DialogDescription>
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>
					<form action='post' onSubmit={handleSubmit}>
						<div className="grid grid-cols-3 gap-2">
							<div className="flex flex-col">
								<Label htmlFor="Jenis Pelayanan" className="mb-2">
									Jenis Pelayanan
								</Label>
								<EditJenisPelayananUI
                                    getJenisPelayanan={getJenisPelayanan}
                                    setJenisPelayanan={setJenisPelayanan}
                                    selectedValue={jenisPelayanan}
                                />
                                
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah Tangga" className="mb-2">
									Jumlah Rumah Tangga
								</Label>
                                <Input 
									id="Jumlah Rumah Tangga" 
									type="number" 
									className="col-span-1"
									value={jumlahRumahTangga}
									onChange={(e) => setJumlahRumahTangga(e.target.value)}
									placeholder='Masukkan Jumlah Rumah Tangga'
									/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kebutuhan Biaya" className="mb-2">
									Kebutuhan Biaya
								</Label>
								<Input 
									id="Kebutuhan Biaya" 
									type="text" 
									className="col-span-1"
									value={kebutuhanBiayaDisplay}
									onChange={handleChangeKebutuhanBiaya(setKebutuhanBiaya, setKebutuhanBiayaDisplay)}	
									placeholder='Masukkan Kebutuhan Biaya'
								/>
							</div>
						</div>
						{/* Target Tahun ke-1 */}
						<h1 className='text-l font-bold mt-4'>Target Tahun ke-1</h1>
                        <div className="grid grid-cols-3 gap-4 mt-4">
							<div className="flex flex-col">
									<Label htmlFor="Jumlah Rumah Tangga" className="mb-2">
										Jumlah Rumah Tangga ke-1
									</Label>
									<Input 
										id="Jumlah Rumah Tangga" 
										type="number" 
										className="col-span-1"
										value={jumlahRumahTangga1}
										onChange={(e) => setJumlahRumahTangga1(e.target.value)}
										placeholder='Masukkan Jumlah Rumah Tangga'
										/>
								</div>
								<div className="flex flex-col">
									<Label htmlFor="Kebutuhan Biaya" className="mb-2">
										Kebutuhan Biaya ke-1
									</Label>
									<Input 
										id="Kebutuhan Biaya" 
										type="text" 
										className="col-span-1"
										value={kebutuhanBiaya1Display}
										onChange={handleChangeKebutuhanBiaya(setKebutuhanBiaya1, setKebutuhanBiaya1Display)}
										placeholder='Masukkan Kebutuhan Biaya'
										/>
								</div>
								<div className="flex flex-col">
									<Label htmlFor="Sumber Pembiayaan" className="mb-2">
										Sumber Pembiayaan Tahun ke-1
									</Label>
									<EditSumberAnggaranSelectUI
                                        getSumberAnggaran={getSumberAnggaran}
                                        setSumberAnggaran={setSumberAnggaran1}
                                        selectedValue={sumberAnggaran1}
                                    />
								</div>
                        </div>
						{/* Target Tahun ke-2 */}
						<h1 className='text-l font-bold mt-4'>Target Tahun ke-2</h1>
                        <div className="grid grid-cols-3 gap-4 mt-4">
								<div className="flex flex-col">
									<Label htmlFor="Jumlah Rumah Tangga" className="mb-2">
										Jumlah Rumah Tangga ke-2
									</Label>
									<Input 
										id="Jumlah Rumah Tangga" 
										type="number" 
										className="col-span-1"
										value={jumlahRumahTangga2}
										onChange={(e) => setJumlahRumahTangga2(e.target.value)}
										placeholder='Masukkan Jumlah Rumah Tangga'
										/>
								</div>
								<div className="flex flex-col">
									<Label htmlFor="Kebutuhan Biaya" className="mb-2">
										Kebutuhan Biaya ke-2
									</Label>
									<Input 
										id="Kebutuhan Biaya" 
										type="text" 
										className="col-span-1" 
										value={kebutuhanBiaya2Display}
										onChange={handleChangeKebutuhanBiaya(setKebutuhanBiaya2, setKebutuhanBiaya2Display)}
										placeholder='Masukkan Kebutuhan Biaya'
										/>
								</div>
								<div className="flex flex-col">
									<Label htmlFor="Sumber Pembiayaan" className="mb-2">
										Sumber Pembiayaan Tahun ke-2
									</Label>
									<EditSumberAnggaranSelectUI
                                        getSumberAnggaran={getSumberAnggaran}
                                        setSumberAnggaran={setSumberAnggaran2}
                                        selectedValue={sumberAnggaran2}
                                    />
								</div>
                        </div>
						{/* Target Tahun ke-3 */}
						<h1 className='text-l font-bold mt-4'>Target Tahun ke-3</h1>
                        <div className="grid grid-cols-3 gap-4 mt-4">
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah Tangga" className="mb-2">
									Jumlah Rumah Tangga ke-3
								</Label>
								<Input 
									id="Jumlah Rumah Tangga" 
									type="number" 
									className="col-span-1"
									value={jumlahRumahTangga3}
									onChange={(e) => setJumlahRumahTangga3(e.target.value)}
									placeholder='Masukkan Jumlah Rumah Tangga'
									/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kebutuhan Biaya" className="mb-2">
									Kebutuhan Biaya ke-3
								</Label>
								<Input 
									id="Kebutuhan Biaya" 
									type="text" 
									className="col-span-1" 
									value={kebutuhanBiaya3Display}
									onChange={handleChangeKebutuhanBiaya(setKebutuhanBiaya3, setKebutuhanBiaya3Display)}
									placeholder='Masukkan Kebutuhan Biaya'
									/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Sumber Pembiayaan" className="mb-2">
									Sumber Pembiayaan Tahun ke-3
								</Label>
								<EditSumberAnggaranSelectUI
                                        getSumberAnggaran={getSumberAnggaran}
                                        setSumberAnggaran={setSumberAnggaran3}
                                        selectedValue={sumberAnggaran3}
                                    />
							</div>
                       	</div>
						<DialogFooter>
							<div className="flex w-full justify-between mt-5">
								<DialogClose asChild>
									<Button
										className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
									>
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
				</DialogContent>
			</Dialog>
		</>
	);
};
export default ETargetPenyelesaian;
