import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import { Button } from '@/components/ui/Button';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useGetDataEksisAirLimbah, usePostDataCapaianAL } from '@/hooks/airLimbah/useDataCapaianAL';
import { useUploadFile } from '@/hooks/useUpload';
import { useGetLokasi } from '@/hooks/wilayah/useLokasi';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FiArrowLeft, FiCheckCircle, FiTrash } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

type FormDataCapaianALProps = {
	sampleId: number
	handleSampleChange: (e: number) => void
	setShowForm: any
};
const FormDataCapaianKumulatifAL = ({ sampleId, handleSampleChange, setShowForm }: FormDataCapaianALProps) => {
	const [uploadDataLoading, setUploadDataLoading] = useState(false)
	const [file, setFile] = useState<any>(null)
	const { getLokasi, isLoading: loadingLokasi } = useGetLokasi();
	const [pagesLength, setPagesLength] = useState(0)
	const queryClient = useQueryClient()

	const tahun = useSelector((state: RootState) => state.tahun.value);
	const user = useSelector((state: RootState) => state.auth);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	const inputDokumenRef = useRef<HTMLInputElement>(null)
	const { asyncPostCapaianAL } = usePostDataCapaianAL()
	const { asyncUploadFile, isPending: pendingUploadFile } = useUploadFile()
	const {
		data,
		fetchNextPage,
		isFetchingNextPage,
		isLoading: isLoadingAL,
	} = useGetDataEksisAirLimbah({
		kd_provinsi: user.kd_provinsi,
		kd_kabkota: user.kd_kabkota,
		tahun: (Number(tahun) - 1).toString(),
		sample_id: sampleId,
	})

	const inputDokumenAcceptFile = `application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, ${sampleId === 3 && "application/pdf"}`

	const formatDataLokasi = () => {
		const newObj = (obj: any): any => {
			return obj.map((item: any) => ({
				user_id: user.id_user,
				menu_id: menuId,
				sample_id: sampleId,
				kd_provinsi: user.kd_provinsi,
				kd_kabkota: user.kd_kabkota,
				kd_kec: item.kd_kec,
				kd_desa: item.kd_desa,
				tgt_total: 0,
				tgt_aks_layak: 0,
				tgt_aks_aman_spald_s: 0,
				tgt_aks_aman_spald_t: 0,
				real_tnp_aks: 0,
				new_real_aks_layak: 0,
				real_aks_aman_spald_s: 0,
				real_aks_aman_spald_t: 0,
				tahun_pelaporan: Number(tahun)
			}));
		};
		const data = newObj(getLokasi)
		return data
	}

	const formatDataCapaianSebelumnya = (obj: any): any => {
		return obj.map((item: any) => ({
			user_id: item.user_id,
			menu_id: item.menu_id,
			sample_id: item.sample_id,
			kd_provinsi: item.kd_provinsi,
			kd_kabkota: item.kd_kabkota,
			kd_kec: item.kd_kec,
			kd_desa: item.kd_desa,
			tgt_total: item.tgt_total,
			tgt_aks_layak: item.tgt_aks_layak,
			tgt_aks_aman_spald_s: item.tgt_aks_aman_spald_s,
			tgt_aks_aman_spald_t: item.tgt_aks_aman_spald_t,
			real_tnp_aks: item.real_tnp_aks,
			new_real_aks_layak: item.new_real_aks_layak,
			real_aks_aman_spald_s: item.real_aks_aman_spald_s,
			real_aks_aman_spald_t: item.real_aks_aman_spald_t,
			tahun_pelaporan: Number(tahun)
		}));
	};

	const uploadFile = async () => {
		const uploadData = new FormData();
		uploadData.append('menu_id', String(menuId));
		uploadData.append('kd_provinsi', String(user.kd_provinsi));
		uploadData.append('kd_kabkota', String(user.kd_kabkota));
		uploadData.append('jenis_up_id', String(sampleId));
		uploadData.append('tahun_upload', tahun);
		uploadData.append('file_upload', file);
		try {
			await asyncUploadFile(uploadData)
			toast.success("Berhasil upload File!")
		} catch (error) {
			toast.error("Gagal upload File!")
		}
	}

	const onSubmit = async () => {
		setUploadDataLoading(true)
		try {
			await handleCapaianKumulatif()
			await uploadFile()
			queryClient.invalidateQueries({ queryKey: ["get-file"] })
			toast.success('Berhasil menambahkan data capaian Air Minum');
		} catch (error) {
			toast.error('Gagal menambahkan data capaian Air Minum');
		} finally {
			setShowForm("Pilih Data")
			setUploadDataLoading(false)
		}
	};

	const handleOpenDokumen = () => {
		if (inputDokumenRef.current) {
			inputDokumenRef.current.click()
		}
	}

	const handleLihatSampel = () => {
		onSubmit()
	}

	const handleDeleteDokumen = () => {
		setFile(null)
	}

	const handleOnChangeOpenDokumen = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files
		if (files) {
			setFile(files[0])
			e.target.value = ""
		}
	}

	const handleCapaianKumulatif = async () => {
		const kumulatifAL = data!.pages.flatMap((page) => page.data)
		if (kumulatifAL.length !== 0) {
			const dataCapaianSebelumnya = formatDataCapaianSebelumnya(kumulatifAL)
			const postPromises = dataCapaianSebelumnya.map((data: any) => asyncPostCapaianAL(data))
			await Promise.all(postPromises)
		} else {
			const data = formatDataLokasi()
			const postPromises = data.map((data: any) => asyncPostCapaianAL(data))
			await Promise.all(postPromises)
		}
	}

	useEffect(() => {
		if (!loadingLokasi) {
			const pages = Math.ceil(getLokasi.length / 10) + 1

			setPagesLength(pages)
		}
	}, [loadingLokasi])

	useEffect(() => {
		async function getDataCapaianSebelumnya() {
			for (let i = 0; i < pagesLength; i++) {
				await fetchNextPage();
			}
		}

		if (pagesLength !== 0) {
			getDataCapaianSebelumnya()
		}
	}, [pagesLength])

	if (uploadDataLoading || pendingUploadFile || isFetchingNextPage || isLoadingAL) return <CompLoader />

	return (
		<>
			<div className="flex justify-end pb-4">
				<div className="flex gap-4">
					<Button
						className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
						onClick={() => setShowForm('Pilih Data')}
					>
						<FiArrowLeft />
						Kembali
					</Button>
				</div>
			</div>
			<div className="flex h-full flex-col justify-evenly rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark">
				<h4 className="pb-14 text-center text-3xl font-semibold text-[#364878] dark:text-white">
					Upload Dokumen Data Capaian Kumulatif
					<br />
					Penyediaan Pelayanan Pengolahan Air Limbah Domestik
				</h4>
				<RadioGroup
					className="flex items-center justify-center gap-14 pb-16"
					defaultValue={String(sampleId)}
					onValueChange={(e) => handleSampleChange(Number(e))}
				>
					<div className="flex items-center gap-4">
						<RadioGroupItem id="primer-bnba" value="1" />
						<Label
							className="text-2xl font-medium text-black"
							htmlFor="primer-bnba"
						>
							Primer BNBA
						</Label>
					</div>
					<div className="flex items-center gap-4">
						<RadioGroupItem id="primer-sampling" value="2" />
						<Label
							className="text-2xl font-medium text-black"
							htmlFor="primer-sampling"
						>
							Primer Sampling
						</Label>
					</div>
					<div className="flex items-center gap-4">
						<RadioGroupItem id="sekunder" value="3" />
						<Label
							className="text-2xl font-medium text-black"
							htmlFor="sekunder"
						>
							Sekunder
						</Label>
					</div>
				</RadioGroup>
				<div className="flex flex-col items-center justify-center gap-8">
					<Label
						className="text-3xl font-semibold text-[#364878] dark:text-white"
						htmlFor="dokumen-verifikasi"
					>
						Verifikasi Dokumen
					</Label>
					<div className="flex items-center gap-6">
						{file && <FiCheckCircle className="text-2xl text-green-500" />}
						{file && (
							<span className="text-xl font-medium text-black">
								{file.name}
							</span>
						)}
						{file ? (
							<FiTrash
								className="cursor-pointer text-2xl text-red-500"
								onClick={handleDeleteDokumen}
							/>
						) : (
							<Button
								className="inline-flex h-14 w-30 items-center justify-center rounded-lg bg-primary px-4 py-2 text-center text-lg font-medium text-white hover:bg-opacity-90"
								onClick={handleOpenDokumen}
							>
								Upload
							</Button>
						)}
					</div>
					{file && (
						<Button
							disabled={uploadDataLoading}
							className="inline-flex h-14 items-center justify-center rounded-lg bg-primary px-4 py-2 text-center text-lg font-medium text-white hover:bg-opacity-90"
							onClick={handleLihatSampel}
						>
							Lihat Capaian
						</Button>
					)}
					<input
						ref={inputDokumenRef}
						onChange={(e) => handleOnChangeOpenDokumen(e)}
						type="file"
						className="hidden"
						accept={inputDokumenAcceptFile}
					/>
					<div className="">
						<p className="text-center text-sm font-black text-red-500">
							*Maksimal 50 MB
						</p>
						<p className="text-center text-sm font-black text-red-500">
							*Harus Berekstensi .xlsx .xls {sampleId === 3 && '.pdf'}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};
export default FormDataCapaianKumulatifAL;
