import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import CardInfo from '@/components/Cards/CardsInfo';
import DefaultLayout from '@/layout/DefaultLayout';
import { FiAlertCircle } from 'react-icons/fi';
const Informasi = () => {
	return (
		<DefaultLayout>
			<Breadcrumb pageHead="Informasi" pageName="Informasi" />
			<div className="sticky top-0 flex items-center justify-start py-4 text-center">
				<div className="inline-flex items-center gap-2 text-center font-bold">
					<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Klik
					cards untuk melihat informasi lebih lanjut
				</div>
			</div>
			<div className="flex justify-center">
				<div className="flex w-full flex-col items-center gap-4">
					<div className="w-full max-w-180">
						<CardInfo
							nama="Panduan Penggunaan Aplikasi"
							keterangan="Panduan penggunaan aplikasi Sicalmers"
							href="https://bit.ly/PanduanSICALMERS"
						/>
					</div>
					<div className="w-full max-w-180">
						<CardInfo
							nama="Rekap Pelaporan SPM Bidang PUPR Tahun 2023 (Kabupaten / kota)"
							keterangan="Download Rekapitulasi"
							href="https://bit.ly/DataSICALMERS240926"
						/>
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default Informasi;
