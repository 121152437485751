// bidangSlice.ts
import { createSlice } from '@reduxjs/toolkit';

export const bidangSlice = createSlice({
	name: 'bidang',
	initialState: {
		value: localStorage.getItem('selectedBidang') || 'air-minum',
	},
	reducers: {
		setBidang: (state, action) => {
			state.value = action.payload;
			localStorage.setItem('selectedBidang', action.payload);
			// return action.payload;
		},
	},
});

export const { setBidang } = bidangSlice.actions;
export default bidangSlice.reducer;
