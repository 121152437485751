import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useGetRawanBencana } from '@/hooks/perumahan/useRumahBencana';
import { usePrivilegeRumahBencana } from '@/hooks/privilege/usePrivilege';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

export const TGrowthCapaian = () => {
	const selectedTahun = useSelector((state: RootState) => state.tahun.value);
	const menu = useSelector((state: RootState) => state.menuId.value);
	const tahun = Number(selectedTahun);
	const QueryClient = useQueryClient();

	//hook previlege
	const { kd_kabkota_to_use, kd_provinsi_to_use } = usePrivilegeRumahBencana();

	//getData
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetRawanBencana(kd_provinsi_to_use, kd_kabkota_to_use, tahun, menu);

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	const cardRef = useRef(null);

	let rowCounter = 0;
	if (isLoading) return <CompLoader />;
	return (
		<>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader isLoading={isLoading} cardRef={cardRef}>
						TABEL PERTUMBUHAN <br />
						CAPAIAN PER PER TAHUN
					</JudulHeader>
					<Table className="w-full min-w-[1800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									className="thead-text border-l text-center"
									rowSpan={2}
								>
									No
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Provinsi
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Kabupaten/Kota
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									2021
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									2022
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									2023
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={2}
								>
									2024
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={2}>
									Pertumbuhan
									<br />
									Rata-Rata
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-t-0 text-center">
									Capaian
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Pertumbuhan
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Capaian
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Pertumbuhan
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Capaian
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Pertumbuhan
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Capaian
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Pertumbuhan
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							<TableRow>
								<TableCell className="tbody-text border-b border-l text-center">
									{++rowCounter}
								</TableCell>
								<TableCell className="tbody-text border-b border-l text-center">
									Sumatera Selatan
								</TableCell>
								<TableCell className="tbody-text border-b border-l text-center">
									Banyuasin
								</TableCell>
								<TableCell className="tbody-text border-b border-l text-center">
									10%
								</TableCell>
								<TableCell className="border-b border-l text-center font-bold">
									0%
								</TableCell>
								<TableCell className="tbody-text border-b border-l text-center">
									20%
								</TableCell>
								<TableCell className="border-b border-l text-center font-bold text-green-500">
									100%
								</TableCell>
								<TableCell className="tbody-text border-b border-l text-center">
									35%
								</TableCell>
								<TableCell className="border-b border-l text-center font-bold text-green-500">
									75%
								</TableCell>
								<TableCell className="tbody-text border-b border-l text-center">
									10%
								</TableCell>
								<TableCell className="border-b border-l text-center font-bold text-red-500">
									-71.43%%
								</TableCell>
								<TableCell className="tbody-text border-b border-l border-r text-center">
									34.52%
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<div className="h-1" ref={ref}>
						{isFetchingNextPage && (
							<div className="flex items-center justify-center p-4">
								<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
							</div>
						)}
					</div>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};
