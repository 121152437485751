import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { API } from '../../libs/API';

const fetchEksisAirMinumPer = async ({
	pageParam = 1,
	kd_provinsi,
	kd_kabkota,
	tahun,
}) => {
	const data = await API.get(
		`/eksis-air-minum-with-query/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
	);
	return data.data;
};

export const useGetDataEksisAirMinumPer = ({
	kd_provinsi,
	kd_kabkota,
	tahun,
}) => {
	return useInfiniteQuery({
		queryKey: ['eksis-air-minum-per', kd_provinsi, kd_kabkota, tahun],
		queryFn: ({ pageParam = 1 }) =>
			fetchEksisAirMinumPer({ pageParam, kd_provinsi, kd_kabkota, tahun }),
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}

			return undefined;
		},
	});
};

const fetchEksisAirMinumBySample = async ({
	pageParam = 1,
	kd_provinsi,
	kd_kabkota,
	tahun,
	sample_id,
}) => {
	const data = await API.get(
		`/eksis-air-minum-by-sample/?sample_id=${sample_id}&kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`,
	);
	return data.data;
};

export const useGetDataEksisAMBySample = ({
	kd_provinsi,
	kd_kabkota,
	tahun,
	sample_id,
}) => {
	return useInfiniteQuery({
		queryKey: [
			'eksis-air-minum-sample',
			sample_id,
			kd_provinsi,
			kd_kabkota,
			tahun,
		],
		queryFn: ({ pageParam = 1 }) =>
			fetchEksisAirMinumBySample({
				pageParam,
				kd_provinsi,
				kd_kabkota,
				tahun,
				sample_id,
			}),
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}

			return undefined;
		},
	});
};

export function usePostDataCapaianAM() {
	const {
		mutateAsync: asyncPostCapaianAm,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/capaian-air-minum/', data);
			return response;
		},
	});
	return { asyncPostCapaianAm, isPending, isSuccess };
}

export function useEditDataCapaianAM() {
	const {
		mutateAsync: asyncEditCapaianAm,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(`/capaian-air-minum/?id_eksis_am=${data.id_eksis_am}`, data);
			return response;
		},
	});
	return { asyncEditCapaianAm, isPending, isSuccess };
}

export function useDeleteCapaianAM() {
	const {
		mutateAsync: deleteCapaianAM,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (tahun: number) => {
			const response = await API.delete(
				`/del-capaian-air-minum/?tahun=${tahun}`,
			);
			return response;
		}
	});
	return { deleteCapaianAM, isPending, isSuccess };
}

const fetchDataCapaianTahunanAM = async ({
	pageParam = 1,
	tahun,
}) => {
	const data = await API.get(
		`/pelaksanaan-pemenuhan/?tahun=${tahun}&page=${pageParam}&limit=10`,
	);
	return data.data;
};


export function useGetDataCapaianTahunanAM({ tahun }) {
	return useInfiniteQuery({
		queryKey: [
			'data-capaian-am-tahunan',
		],
		queryFn: ({ pageParam = 1 }: any) =>
			fetchDataCapaianTahunanAM({
				pageParam,
				tahun,
			}),
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}

			return undefined;
		},
	});
}

export function useGetDataCapaianKumulatifAMProv(kd_provinsi: number, tahun: number) {
	const { data: getDataCapaianKumulatifAMProv, isLoading } = useQuery({
		queryKey: ["eksis-air-minum-prov", kd_provinsi, tahun],
		queryFn: async () => {
			const { data } = await API.get(`/eksis-air-minum-prov/?kd_provinsi=${kd_provinsi}&tahun=${tahun}`)
			return data
		}
	})

	return { getDataCapaianKumulatifAMProv, isLoading }
}

export function useGetDataCapaianKumulatifAMCurahProv(kd_provinsi: number, tahun: number) {
	const { data: getDataCapaianKumulatifAMCurahProv, isLoading, isRefetching } = useQuery({
		queryKey: ["eksis-air-minum-curah-prov", kd_provinsi, tahun],
		queryFn: async () => {
			const { data } = await API.get(`/eksis-air-minum-curah/?kd_provinsi=${kd_provinsi}&tahun=${tahun}`)
			return data
		}
	})

	return { getDataCapaianKumulatifAMCurahProv, isLoading, isRefetching }
}

export function usePostDataCapaianKumulatifAMCurahProv() {
	const {
		mutateAsync: asyncPostCapaianKumulatifAMCurahProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/capaian-air-minum-curah-prov/', data);
			return response;
		},
	});
	return { asyncPostCapaianKumulatifAMCurahProv, isPending, isSuccess };
}

export function useEditDataCapaianKumulatifAMCurahProv() {
	const {
		mutateAsync: asyncEditCapaianKumulatifAMCurahProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(`/capaian-air-minum-curah-prov/?id_eksis_curah=${data.id_eksis_am_prov}`, data);
			return response;
		},
	});
	return { asyncEditCapaianKumulatifAMCurahProv, isPending, isSuccess };
}

export function useDeleteDataCapaianKumulatifAMCurahProv() {
	const {
		mutateAsync: deleteCapaianAMProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (id: string) => {
			const response = await API.delete(
				`/del-eksis-am-curah/?id_eksis_curah=${id}`,
			);
			return response;
		}
	});
	return { deleteCapaianAMProv, isPending, isSuccess };
}