import { useRef } from 'react';
import { CapProv } from '../../types/beranda';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import JudulHeader from '../Header/JudulHeader';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '../ui/table';

const CapProvData: CapProv[] = [
	{
		prov: 'Jawa Barat',
		totalKabKota: 36,
		persenCapaian: 4.8,
	},
	{
		prov: 'Bali',
		totalKabKota: 10,
		persenCapaian: 4.8,
	},
	{
		prov: 'Bengkulu',
		totalKabKota: 11,
		persenCapaian: 4.8,
	},
	{
		prov: 'Sumatra Barat',
		totalKabKota: 20,
		persenCapaian: 4.8,
	},
	{
		prov: 'Kalimantan Selatan',
		totalKabKota: 14,
		persenCapaian: 4.8,
	},
];

const TableBerandaProv = () => {
	const cardRef = useRef(null);

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader cardRef={cardRef} isLoading={false}>
					Capaian SPM Pekerjaan Umum Provinsi
				</JudulHeader>
				<Table className="w-full table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead className="thead-text border-l">
								Nama Provinsi
							</TableHead>
							<TableHead className="thead-text text-center">
								Jumlah Kabupaten/Kota
							</TableHead>
							<TableHead className="thead-text text-center">
								Persentase Capaian
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{CapProvData.map((provData, key) => (
							<TableRow key={key}>
								<TableCell className="tbody-text border-b border-l border-r text-start">
									{provData.prov}
								</TableCell>
								<TableCell className="tbody-text border-b border-r">
									{provData.totalKabKota}
								</TableCell>
								<TableCell className="tbody-text border-b border-r">
									{provData.persenCapaian}%
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};

export default TableBerandaProv;
