import React, { useEffect, useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface JenisPelayananUIProps {
	getJenisPelayanan: any[];
	setJenisPelayanan: (id_bencana_pelayanan: number) => void;
    selectedValue: number;
}

const EditJenisPelayananUI: React.FC<JenisPelayananUIProps> = ({
    getJenisPelayanan,
    setJenisPelayanan,
    selectedValue
}) => {
    const [selectedJenisPelayanan, setSelectedJenisPelayanan] = useState('');

    useEffect(() => {
        const selectedJenis = getJenisPelayanan.find((jenisPelayanan: any) => jenisPelayanan.id_bencana_pelayanan === selectedValue);
        if (selectedJenis) {
            setSelectedJenisPelayanan(selectedJenis.nama_pelayanan);
        }
    }, [selectedValue, getJenisPelayanan]);

    return (
        <Select
            onValueChange={(value) => {
                if (value !== '') {
                    const selectedJenisPelayanan = getJenisPelayanan.find((jenisPelayanan: any) => jenisPelayanan.id_bencana_pelayanan === parseInt(value));
                    if (selectedJenisPelayanan) {
                        console.log("Jenis Pelayanan yang dipilih:", selectedJenisPelayanan.nama_pelayanan);
                        setSelectedJenisPelayanan(selectedJenisPelayanan.nama_pelayanan || "");
                        setJenisPelayanan(parseInt(value)); 
                    }
                }
            }}
            value={selectedValue.toString()} 
        >
            <SelectTrigger>
                <SelectValue placeholder="Pilih Jenis Pelayanan">
                    {selectedJenisPelayanan || 'Pilih Jenis Pelayanan'}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getJenisPelayanan.map((jenisPelayanan: any, index: any) => (
                    <SelectItem key={index} value={jenisPelayanan.id_bencana_pelayanan.toString()}>
                        {jenisPelayanan.nama_pelayanan}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
};

export default EditJenisPelayananUI;
