import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from '../../libs/API';

export function useGetInvSarana(kd_provinsi: number, kd_kabkota: number) {
	const {
		data: getInvSarana,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['inv-sarana', kd_provinsi, kd_kabkota],
		queryFn: async () => {
			const { data } = await API.get(
				`/inv-pengangkutan/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
	});
	return { getInvSarana, refetch, isLoading };
}

export function usePostInvSarana() {
	const {
		mutate: PostInvSarana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/inv-pengangkutan', data);
			return response.data;
		},
	});
	return { PostInvSarana, isPending, isSuccess };
}


export function useEditInvSarana() {
	const {
		mutateAsync: editInvSarana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`inv-pengangkutan/?id_srn_pengangkutan=${data.id_srn_pengangkutan}`,
				data,
			);
			return response;
		},
	});
	return { editInvSarana, isPending, isSuccess };
}

export function useDeleteInvSarana() {
	const {
		mutateAsync: deleteInvSarana,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/inv-pengangkutan/?id_srn_pengangkutan=${data.id_srn_pengangkutan}`,
				data,
			);
			return response;
		},
	});
	return { deleteInvSarana, isPending, isSuccess };
}

//Sarana SPALD
export function useGetSaranaSPALD(
	kd_provinsi: number,
	tahun: number,
	menu: number,
) {
	const {
		data: getSaranaSPALD,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['inv-spald', kd_provinsi, tahun, menu],
		queryFn: async () => {
			const { data } = await API.get(
				`/prov-spald/?kd_provinsi=${kd_provinsi}&tahun=${tahun}&menu_id=${menu}`,
			);
			return data.data;
		},
	});
	return { getSaranaSPALD, refetch, isLoading };
}

export function usePostInvSPALDProv() {
	const {
		mutate: postInvSPALDProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/add-prov-spald', data);
			return response.data;
		},
	});
	return { postInvSPALDProv, isPending, isSuccess };
}

export function useEditSPALDProv() {
	const {
		mutateAsync: editSPALDProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`edit-prov-spald/?id_prov_spald=${data.id_prov_spald}`,
				data,
			);
			return response;
		},
	});
	return { editSPALDProv, isPending, isSuccess };
}

export function useDeleteSPALDProv() {
	const {
		mutateAsync: deleteSPALDProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`/del-prov-spald/?id_prov_spald=${data.id_prov_spald}`,
				data,
			);
			return response;
		},
	});
	return { deleteSPALDProv, isPending, isSuccess };
}

//IPLT

export function useGetIPLTProv(
	kd_provinsi: number,
	tahun: number,
	menu: number,
) {
	const {
		data: getSaranaIPLT,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['inv-iplt', kd_provinsi, tahun, menu],
		queryFn: async () => {
			const { data } = await API.get(
				`/prov-iplt/?kd_provinsi=${kd_provinsi}&tahun=${tahun}&menu_id=${menu}`,
			);
			return data.data;
		},
	});
	return { getSaranaIPLT, refetch, isLoading };
}

export function usePostIPLTProv() {
	const {
		mutate: postIPLTProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/add-prov-iplt', data);
			return response.data;
		},
	});
	return { postIPLTProv, isPending, isSuccess };
}

export function useEditIPLTProv() {
	const {
		mutateAsync: editIPLTProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(
				`edit-prov-iplt/?id_prov_iplt=${data.id_prov_iplt}`,
				data,
			);
			return response;
		},
	});
	return { editIPLTProv, isPending, isSuccess };
}

export function useDeleteIPLTProv() {
	const {
		mutateAsync: deleteIPLTProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.delete(
				`del-prov-iplt/?id_prov_iplt=${data.id_prov_iplt}`,
				data,
			);
			return response;
		},
	});
	return { deleteIPLTProv, isPending, isSuccess };
}
