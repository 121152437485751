import { useSelector } from 'react-redux';
import { API } from '../../libs/API';
import { useQuery } from '@tanstack/react-query';
import { RootState } from '../../app/store';

export function useGetLokasi() {
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	
	const {
		data: getLokasi,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['lokasi', kd_provinsi, kd_kabkota],
		queryFn: async () => {
			const { data } = await API.get(
				`/get-wilayah/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}`,
			);
			return data.data;
		},
	});
	return { getLokasi, refetch, isLoading };
}
export function useGetProvinsi() {
	const {
		data: getProvinsi,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['provinsi'],
		queryFn: async () => {
			const { data } = await API.get(`/get-provinsi`);
			return data.data;
		},
	});
	return { getProvinsi, refetch, isLoading };
}
export function useGetKabKota(kd_provinsi: number) {
	const {
		data: getKabKota,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['KabKota', kd_provinsi],
		queryFn: async () => {
			const { data } = await API.get(
				`/get-kabkota/?kd_provinsi=${kd_provinsi}`,
			);
			return data.data;
		},
	});
	return { getKabKota, refetch, isLoading };
}
