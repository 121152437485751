import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface JenisProgramRelokasiSelectUIProps {
  getJenisProgramRelokasi: any[];
  setJenisProgramRelokasi: (id_jenis_relokasi: number) => void;
  selectedValue: number
  disabledSelect: boolean
}


const EditJenisProgramRelokasi: React.FC<JenisProgramRelokasiSelectUIProps> = ({
  getJenisProgramRelokasi,
  setJenisProgramRelokasi,
  selectedValue,
  disabledSelect
}) => {

  const [selectedJenisRelokasi, setSelectedJenisRelokasi] = useState('');

    useEffect(() => {
        const selectedJenis = getJenisProgramRelokasi.find((jenisRelokasi: any) => jenisRelokasi.id_jenis_relokasi === selectedValue);
        if (selectedJenis) {
            setSelectedJenisRelokasi(selectedJenis.nama_jenis_relokasi);
        }
    }, [selectedValue, getJenisProgramRelokasi]);

    return (
        <Select
            onValueChange={(value) => {
                if (value !== '') {
                    const id_jenis_relokasi = parseInt(value, 10);
                    const selectedJenisRelokasi = getJenisProgramRelokasi.find((jenisRelokasi: any) => jenisRelokasi.id_jenis_relokasi === parseInt(value));
                    if (selectedJenisRelokasi) {
                        console.log("Jenis Relokasi yang dipilih:", selectedJenisRelokasi.nama_jenis_relokasi);
                        setSelectedJenisRelokasi(selectedJenisRelokasi.nama_jenis_relokasi || "");
                        setJenisProgramRelokasi(id_jenis_relokasi); // Konversi ke number sebelum diteruskan
                    }
                }
            }}
            value={selectedValue.toString()} // Konversi ke string untuk Select
            disabled={disabledSelect}
        >
            <SelectTrigger>
                <SelectValue placeholder="Pilih Jenis Relokasi">
                    {selectedJenisRelokasi || 'Pilih Jenis Relokasi'}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getJenisProgramRelokasi.map((jenisRelokasi: any, index: any) => (
                    <SelectItem 
                        key={index} 
                        value={jenisRelokasi.id_jenis_relokasi.toString()}
                        >
                        {jenisRelokasi.nama_jenis_relokasi}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
}

export default EditJenisProgramRelokasi;


