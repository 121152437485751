import { ReactNode, useEffect, useState } from "react";

type JudulHeaderProps = {
  isLoading?: boolean;
  children?: ReactNode;
  cardRef?: any;
  minusWidth?: any;
}

const JudulHeader = ({ cardRef, children, isLoading, minusWidth = 0 }: JudulHeaderProps) => {
  const [headerWidth, setHeaderWidth] = useState<any>()
  const [isSidebar, setIsSidebar] = useState(true)
  useEffect(() => {
    const sidebar = document.getElementById('sidebar');

    if (!sidebar) return;

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'class') {
          setIsSidebar(sidebar.classList.contains('translate-x-0'));
        }
      });
    });

    observer.observe(sidebar, { attributes: true });

    setIsSidebar(sidebar.classList.contains('translate-x-0'));

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (isSidebar) {
        if (!isLoading) (
          //@ts-ignore
          setHeaderWidth(cardRef.current.offsetWidth - 32)
        )
      } else {
        if (!isLoading) (
          //@ts-ignore
          setHeaderWidth(cardRef.current.offsetWidth - 32)
        )
      }
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])


  useEffect(() => {
    const sidebar = document.getElementById("sidebar")
    if (!isLoading) {
      if (isSidebar) {
        //@ts-ignore
        setHeaderWidth(cardRef.current.offsetWidth - sidebar?.offsetWidth - 32)
      } else {
        //@ts-ignore
        setHeaderWidth(cardRef.current.offsetWidth + sidebar?.offsetWidth - 32)
      }
    }
  }, [isSidebar, isLoading])

  useEffect(() => {
    if (!isLoading) {
      setHeaderWidth(cardRef.current.offsetWidth - minusWidth)
    }
  }, [isLoading])

  return (
    <div className='transition-width duration-300 sticky top-0 left-0' style={{ width: headerWidth }}>
      <h4 className="py-4 text-center text-xl font-semibold text-[#364878] dark:text-white">
        {children}
      </h4>
    </div>
  )
}

export default JudulHeader