import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setBidang } from '../../app/bidang/bidangSlice';
import { useEffect } from 'react';

const BidangSelection = () => {
	const bidang = useSelector((state: RootState) => state.bidang.value);
	const menu = useSelector((state: RootState) => state.auth.menu_id);
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);
	const dispatch = useDispatch();

	useEffect(() => {
		const selectedBidang = localStorage.getItem('selectedBidang');
		if (
			!selectedBidang &&
			(menu === 3 || menu === 4 || menu === 7 || menu === 8 || menu === 17)
		) {
			dispatch(setBidang('rumah-bencana'));
		}
		if (
			!selectedBidang &&
			(menu === 1 ||
				menu === 5 ||
				menu === 10 ||
				menu === 11 ||
				menu === 9 ||
				menu === 16 ||
				menu === 333)
		) {
			dispatch(setBidang('air-minum'));
		}
		if (!selectedBidang && (menu === 2 || menu === 6 || menu === 16)) {
			dispatch(setBidang('air-limbah'));
		}
	}, [dispatch, menu]);

	return (
		<div className="mt-4 flex flex-grow justify-end rounded text-lg font-bold text-[#364878] md:mt-0">
			<span className="my-auto mr-1 align-bottom text-lg font-bold dark:text-white">
				Bidang:
			</span>
			<select
				className="rounded-md bg-slate-200 p-2 pl-2 text-[#364878] shadow-default hover:bg-slate-300 dark:bg-boxdark-2 dark:text-white"
				value={bidang}
				onChange={(e) => {
					dispatch(setBidang(e.target.value));
				}}
			>
				{/* <option value="">Select Year</option> */}
				{(menu === 1 || menu === 5) && (
					<option value="air-minum">Air Minum</option>
				)}
				{(menu === 2 || menu === 6) && (
					<option value="air-limbah">Air Limbah</option>
				)}
				{(menu === 3 ||
					menu === 4 ||
					menu === 7 ||
					menu === 8 ||
					menu === 17) && (
					<>
						<option value="rumah-bencana">Rumah Bencana</option>
						<option value="rumah-relokasi">Rumah Relokasi</option>
					</>
				)}
				{(menu === 10 || menu === 11 || menu === 9 || menu === 333) && (
					<>
						<option value="air-minum">Air Minum</option>
						<option value="air-limbah">Air Limbah</option>
						<option value="rumah-bencana">Rumah Bencana</option>
						<option value="rumah-relokasi">Rumah Relokasi</option>
					</>
				)}
				{menu === 16 && privilege === 8 && (
					<>
						<option value="air-minum">Air Minum</option>
						<option value="air-limbah">Air Limbah</option>
					</>
				)}
				{/* <option value="air-minum">Air Minum</option>
				<option value="air-limbah">Air Limbah</option>
				<option value="rumah-bencana">Rumah Bencana</option>
				<option value="rumah-relokasi">Rumah Relokasi</option> */}
				{/* Add more years as needed */}
			</select>
		</div>
	);
};
export default BidangSelection;
