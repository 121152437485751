import DefaultLayout from '../../../layout/DefaultLayout';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useEffect, useState } from 'react';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import TIdentifikasiRawanBencana from '@/components/Tables/RumahBencana/PengumpulanData/TIdentifikasiRawanBencana';
import TIndentifikasiLahanPotensialRumahBencana from '@/components/Tables/RumahBencana/PengumpulanData/TIndentifikasiLahanPotensialRumahBencana';
import TTargetPenyelesaianRumahBencana from '@/components/Tables/RumahBencana/PengumpulanData/TTargetPenyelesaianRumahBencana';
import TPengumpulanDataRumahBencana from '@/components/Tables/RumahBencana/PengumpulanData/TPengumpulanDataRumahBencana';
import CekBencanaLimaTahun from '@/components/Tables/RumahBencana/PengumpulanData/CekBencanaLimaTahun';
import BNBARawanBencana from '@/components/Tables/RumahBencana/PengumpulanData/BNBARawanBencana';
import { useGetDataCapaianPenetapanBencana } from '@/hooks/perumahan/useRumahBencana';
import { usePrivilegeRumahBencana } from '@/hooks/privilege/usePrivilege';

const PengumpulanDataRumahBencana = () => {
	const [activeTab, setActiveTab] = useState('IdentifikasiPerumahan');
	const user = useSelector((state: RootState) => state.auth);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	const [penetapanBencana, setPenetapanBencana] = useState<any>(false);
	const [adaBerkas, setAdaBerkas] = useState(false);
	const [adaBencana, setAdaBencana] = useState('tidak');

	const { isPrevilegeViewRumahBencana } = usePrivilegeRumahBencana();

	const { data: dataPenetapanBencana, isLoading: loadPenetapanBencana } =
		useGetDataCapaianPenetapanBencana(
			user.kd_provinsi,
			user.kd_kabkota,
			Number(tahun),
			menuId,
		);

	useEffect(() => {
		if (!loadPenetapanBencana) {
			const data = dataPenetapanBencana!.pages.flatMap((page) => page.data);
			const haveTidakAdabencana = data.some(
				(item) => item.jenis_bencana_id === 10,
			);

			if (haveTidakAdabencana) {
				setPenetapanBencana(true);
			} else {
				setPenetapanBencana(false);
			}
		}
	}, [loadPenetapanBencana]);

	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead="Pengumpulan Data Rumah Bencana"
				pageName="Pengumpulan Data"
			/>
			<div
				className={`grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 ${!penetapanBencana || isPrevilegeViewRumahBencana ? 'sm:grid-cols-6' : 'sm:grid-cols-3'} dark:bg-graydark`}
			>
				<div
					className={`py-4 text-center ${
						activeTab === 'IdentifikasiPerumahan'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('IdentifikasiPerumahan')}
				>
					Identifikasi Perumahan
					<br />
					Rawan Bencana
				</div>
				<div
					className={`py-4 text-center ${
						activeTab === 'IdentifikasiLahanPotensial'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('IdentifikasiLahanPotensial')}
				>
					Identifikasi
					<br />
					Lahan Potensial
				</div>
				<div
					className={`py-4 text-center ${
						activeTab === 'CekBencanaLimaTahun'
							? 'bg-[#fab23d]'
							: 'bg-[#364878] dark:bg-graydark'
					} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
					onClick={() => setActiveTab('CekBencanaLimaTahun')}
				>
					Penetapan <br />
					Bencana
				</div>
				{(!penetapanBencana || isPrevilegeViewRumahBencana) && (
					<>
						<div
							className={`py-4 text-center ${
								activeTab === 'TargetPenyelesaian'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('TargetPenyelesaian')}
						>
							Target Penyelesaian
							<br />
							Rumah Korban Bencana
						</div>
						<div
							className={`py-4 text-center ${
								activeTab === 'PengumpulanDataRumah'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('PengumpulanDataRumah')}
						>
							Pengumpulan Data Rumah
							<br />
							Yang Terkena Bencana
						</div>
						<div
							className={`flex items-center justify-center text-center ${
								activeTab === 'BNBA'
									? 'bg-[#fab23d]'
									: 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
							onClick={() => setActiveTab('BNBA')}
						>
							BNBA
						</div>
						{/* <div
							className={`py-4 text-center ${activeTab === 'DataAwal' ? 'bg-[#fab23d]' : 'bg-[#364878]'
								} cursor-pointer rounded-md text-white hover:bg-graydark`}
							onClick={() => setActiveTab('DataAwal')}
						>
							Data Awal
							<br />
							{`${Number(tahun) - 1}`}
						</div> */}
					</>
				)}
			</div>
			{activeTab === 'IdentifikasiPerumahan' && <TIdentifikasiRawanBencana />}
			{activeTab === 'IdentifikasiLahanPotensial' && (
				<TIndentifikasiLahanPotensialRumahBencana />
			)}
			{activeTab === 'TargetPenyelesaian' && (
				<TTargetPenyelesaianRumahBencana />
			)}
			{activeTab === 'CekBencanaLimaTahun' && (
				<CekBencanaLimaTahun
					setActiveTab={setActiveTab}
					penetapanBencana={penetapanBencana}
					setPenetapanBencana={setPenetapanBencana}
					adaBerkas={adaBerkas}
					setAdaBerkas={setAdaBerkas}
					adaBencana={adaBencana}
					setAdaBencana={setAdaBencana}
				/>
			)}
			{/* {activeTab === 'CekBencanaLimaTahun' && (
				<CekBencanaLimaTahun
					setActiveTab={setActiveTab}
					penetapanBencana={penetapanBencana}
					setPenetapanBencana={setPenetapanBencana}
					adaBerkas={adaBerkas}
					setAdaBerkas={setAdaBerkas}
					adaBencana={adaBencana}
					setAdaBencana={setAdaBencana}
				/>
			)} */}
			{activeTab === 'PengumpulanDataRumah' && <TPengumpulanDataRumahBencana />}
			{activeTab === 'BNBA' && <BNBARawanBencana />}
			{/* {activeTab === 'DataAwal' && <TDataCapaianRumahBencana/>} */}
		</DefaultLayout>
	);
};

export default PengumpulanDataRumahBencana;
