import { useState, FormEvent } from 'react';
import { DataWilayahAL } from '@/types/dataWilayah';

interface EditDataWilayahALProps {
	onEditSuccess: () => void;
	data?: DataWilayahAL | null;
}

const EditDataWilayahAL = (props: EditDataWilayahALProps) => {
	const [prov] = useState(props.data?.provinsi || '');
	const [kab] = useState(props.data?.kabKota || '');
	const [jmlKecamatan, setjmlKecamatan] = useState(
		props.data?.jmlKecamatan || '',
	);
	const [jmlKelurahan, setjmlKelurahan] = useState(
		props.data?.jmlKelurahan || '',
	);
	const [luasWilayah, setLuasWilayah] = useState(props.data?.luasWilayah || '');
	const [jmlRumahTangga, setRumahTangga] = useState(
		props.data?.jmlRumahTangga || '',
	);
	const [jmlKK, setJumlahKK] = useState(props.data?.jmlKK || '');
	const [jmlJiwa, setJumlahJiwa] = useState(props.data?.jmlJiwa || '');
	const [umpUmk, setUmpUmk] = useState(props.data?.umpUmk || '');

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		console.log({
			prov,
			kab,
			jmlKecamatan,
			jmlKelurahan,
			luasWilayah,
			jmlRumahTangga,
			jmlKK,
			jmlJiwa,
			umpUmk,
		});
		props.onEditSuccess();
	};

	return (
		<form action="" onSubmit={handleSubmit}>
			<div>
				<div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
					<div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
						<h3 className="font-medium text-[#364878] text-2xl dark:text-white">
							Ubah Data Wilayah {props.data?.provinsi} - {props.data?.kabKota}
						</h3>
					</div>
					<div className="flex flex-col gap-5.5 p-6.5 ">
						<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
							<div className="flex items-end">
								<label className="mb-3 block font-medium text-slate dark:text-white">
									Jumlah Kecamatan
								</label>
							</div>
							<div className="text-right">
								<input
									type="text"
									placeholder="New Value"
									value={jmlKecamatan}
									onChange={(e) => setjmlKecamatan(e.target.value)}
									className="text-right w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
							<div className="flex items-end">
								<label className="mb-3 block font-medium text-slate dark:text-white">
									Jumlah Kelurahan / Desa
								</label>
							</div>
							<div className="text-right">
								<input
									type="text"
									placeholder="New Value"
									value={jmlKelurahan}
									onChange={(e) => setjmlKelurahan(e.target.value)}
									className="text-right w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
							<div className="flex items-end">
								<label className="mb-3 block font-medium text-slate dark:text-white">
									Luas Wilayah (km²)
								</label>
							</div>
							<div className="text-right">
								<input
									type="text"
									placeholder="New Value"
									value={luasWilayah}
									onChange={(e) => setLuasWilayah(e.target.value)}
									className="text-right w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5 ">
							<div className="flex items-end">
								<label className="mb-3 block font-medium text-slate dark:text-white">
									Jumlah Rumah Tangga
								</label>
							</div>
							<div className="text-right">
								<input
									type="text"
									placeholder="New Value"
									value={jmlRumahTangga}
									onChange={(e) => setRumahTangga(e.target.value)}
									className="text-right w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
							<div className="flex items-end">
								<label className="mb-3 block font-medium text-slate dark:text-white">
									Jumlah KK
								</label>
							</div>
							<div className="text-right">
								<input
									type="text"
									placeholder="New Value"
									value={jmlKK}
									onChange={(e) => setJumlahKK(e.target.value)}
									className="text-right w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
							<div className="flex items-end">
								<label className="mb-3 block font-medium text-slate dark:text-white">
									Jumlah Jiwa
								</label>
							</div>
							<div className="text-right">
								<input
									type="text"
									placeholder="New Value"
									value={jmlJiwa}
									onChange={(e) => setJumlahJiwa(e.target.value)}
									className="text-right w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 justify-between border-b border-slate-200 pb-3 gap-5">
							<div className="flex-col ">
								<label className=" block font-medium text-slate dark:text-white">
									UMP / UMK
								</label>
								<p className="text-[10px] text-red-500">
									*ump untuk akun <strong>Provinsi</strong> dan umk untuk akun{' '}
									<strong>Kabupaten</strong>
								</p>
								<div className=""></div>
							</div>
							<div className="text-right">
								<input
									type="text"
									placeholder="New Value"
									value={umpUmk}
									onChange={(e) => setUmpUmk(e.target.value)}
									className="text-right w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary dark:disabled:bg-black"
								/>
							</div>
						</div>
						<div className="flex justify-between gap-5 mt-5">
							<div className="w-full">
								<button
									className="min-w-full inline-flex items-center justify-center rounded-md border border-meta-3 py-4 px-10 text-center font-medium text-meta-3 hover:bg-green-400 hover:text-white lg:px-8 xl:px-10"
									type="submit"
								>
									Simpan Data
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default EditDataWilayahAL;
