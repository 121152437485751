import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useForm } from 'react-hook-form';
import useEditProfile from '@/hooks/useEditProfile';
import { CompLoader } from '@/common/Loader';
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
  const user = useSelector((state: RootState) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate()

  const { register, handleSubmit } = useForm({
    defaultValues: {
      username: user.username,
      fullname: user.fullname,
      email: user.email,
      no_hp: user.no_hp,
      nama_opd: user.nama_opd,
    }
  })

  const { asyncEditProfile, isPending } = useEditProfile()

  const onSubmit = async (data: any) => {
    try {
      await asyncEditProfile(data)
    } catch (error) {
      console.error(error)
    } finally {
      setModalOpen(false)
    }
  }

  if (isPending) return <CompLoader />

  return (
    <>
      <Dialog open={modalOpen} onOpenChange={setModalOpen}>
        <DialogTrigger asChild>
          <Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-[#fab23d] px-4 py-2 text-center font-medium text-white hover:bg-[#fab23d] hover:bg-opacity-90">
            Edit Profile
          </Button>
        </DialogTrigger>
        <DialogContent className="mx-auto max-w-screen-l z-99999">
          <DialogHeader>
            <DialogTitle>
              Edit Profile
            </DialogTitle>
          </DialogHeader>
          <div>
            <div className="grid grid-cols-1 gap-4">
              <div className='flex justify-between gap-4'>
                <div className="flex basis-1/2 flex-col">
                  <Label htmlFor="Nama Lengkap" className="mb-2">
                    Nama Lengkap
                  </Label>
                  <div className="relative">
                    <Input
                      id="Nama Lengkap"
                      className="col-span-1"
                      placeholder='John Doe'
                      {...register("fullname")}
                    />
                  </div>
                </div>
                <div className="flex basis-1/2 flex-col">
                  <Label htmlFor="Nomor Handphone" className="mb-2">
                    Nomor Handphone
                  </Label>
                  <div className="relative">
                    <Input
                      id="Nomor Handphone"
                      className="col-span-1"
                      placeholder='08218562282'
                      {...register("no_hp")}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <Label htmlFor="Username" className="mb-2">
                  Username
                </Label>
                <div className="relative">
                  <Input
                    id="Username"
                    className="col-span-1"
                    placeholder='JohnDoe'
                    {...register("username")}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <Label htmlFor="Nama OPD" className="mb-2">
                  Nama OPD
                </Label>
                <div className="relative">
                  <Input
                    id="Nama OPD"
                    className="col-span-1"
                    placeholder='johndoe'
                    {...register("nama_opd")}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <Label htmlFor="Email" className="mb-2">
                  Email
                </Label>
                <div className="relative">
                  <Input
                    id="Email"
                    className="col-span-1"
                    placeholder='johndoe@sicalmers.com'
                    {...register("email")}
                  />
                </div>
              </div>
            </div>
            <DialogFooter>
              <div className="flex w-full justify-between mt-5">
                <DialogClose asChild>
                  <Button
                    disabled={isPending}
                    className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
                    onClick={() => setModalOpen(false)}
                  >
                    Batal
                  </Button>
                </DialogClose>
                <Button
                  disabled={isPending}
                  className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                  onClick={() => handleSubmit(onSubmit)()}
                >
                  Simpan
                </Button>
              </div>
            </DialogFooter>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditProfile;
