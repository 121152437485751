import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { FormEvent, useEffect, useState } from 'react';
import {IdentifikasiDataPengumpulanRumahRelokasi } from '@/types/perumahan';
import EditKecamatanSelectUI from '@/components/ui/SelectEditKecamatan';
import { useEditPengumpulanDataRelokasi } from '@/hooks/perumahan/useRumahRelokasi';
import { useGetJenisProgramRelokasi } from '@/hooks/perumahan/useRumahBencana';
import EditJenisProgramRelokasi from '@/components/ui/SelectEditJenisProgramRelokasi';
import { useQueryClient } from '@tanstack/react-query';
import EditDesaSelectUI from '@/components/ui/SelectEditDesa';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import EditKabKotaSelectUI from '@/components/ui/SelectEditKabupatenKotaUI';

interface PengumpulanDataRumahRelokasiProps {
    rowData: IdentifikasiDataPengumpulanRumahRelokasi;
}

const EPengumpulanDataRumahRelokasi: React.FC<PengumpulanDataRumahRelokasiProps> = ({
    rowData,
}) => {
    const user = useSelector((state: RootState) => state.auth);
    const menuId = useSelector((state: RootState) => state.menuId.value);

    //wilayah
    const [kd_kabkota, setKd_kabkota] = useState<number>(rowData.kd_kabkota);
    const [kd_kec, setKd_kec] = useState<number>(rowData.kd_kec);
    const [kd_desa, setKd_desa] = useState<number>(rowData.kd_desa);

    //get
    const { getKabKota } = useGetKabKota(user.kd_provinsi);
    const { getKecamatan } = useGetKecamatan(
        user.kd_provinsi,
        kd_kabkota,
    );
    const {
        getDesa,
        refetch: fetchDesa,
    } = useGetDesa(user.kd_provinsi, kd_kabkota, kd_kec);
    const { getJenisProgramRelokasi } = useGetJenisProgramRelokasi();
    const { editPengumpulanDataRelokasi } = useEditPengumpulanDataRelokasi();

    //dataForm
    const [jenisProgramRelokasi, setJenisProgramRelokasi] = useState<number>(rowData.jenis_relokasi_id);
    const [jumlahKK, setJumlahKK] = useState((rowData.jml_kk ?? 0).toString());
		const [jumlahJiwa, setJumlahJiwa] = useState(
			(rowData.jml_jiwa ?? 0).toString(),
		);
		const [jumlahRumah, setJumlahRumah] = useState(
			(rowData.jml_rumah ?? 0).toString(),
		);
		const [statusLegal, setStatusLegal] = useState(
			(rowData.legal ?? 0).toString(),
		);
		const [statusIllegal, setStatusIllegal] = useState(
			(rowData.ilegal ?? 0).toString(),
		);
		const [statusMilik, setStatusMilik] = useState(
			(rowData.status_milik ?? 0).toString(),
		);
		const [statusSewa, setStatusSewa] = useState(
			(rowData.status_sewa ?? 0).toString(),
		);
		const [statusLainnya, setStatusLainnya] = useState(
			(rowData.status_lainnya ?? 0).toString(),
		);

    //additionals
    const [modalOpen, setModalOpen] = useState(false);
    const queryClient = useQueryClient(); 

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        const data = {
            id_relokasi_pengumpulan: rowData.id_relokasi_pengumpulan,
            jenis_relokasi_id: jenisProgramRelokasi,
            jml_rumah: parseInt(jumlahRumah),
            jml_kk: parseInt(jumlahKK),
            jml_jiwa: parseInt(jumlahJiwa),
            legal: parseInt(statusLegal),
            ilegal: parseInt(statusIllegal),
            status_milik: parseInt(statusMilik),
            status_sewa: parseInt(statusSewa),
            status_lainnya: parseInt(statusLainnya),
        };
        
        editPengumpulanDataRelokasi(data, {
			onSuccess: () => {
				setModalOpen(false);
                queryClient.refetchQueries({queryKey:['relokasi-pengumpulan']});
			},
			onError: (error) => {
				console.log(error);
			},
		});

    };

    useEffect(() => {

    }, [kd_kec]);

    useEffect(() => {
        // setJenisProgramRelokasi(rowData.jenis_bencana_id);
        // setTingkatKerawananBencana(rowData.tingkat_rawan.nama_tingkat_rawan);
        // setKd_kec(rowData.kd_kec);
        // setKd_desa(rowData.kd_desa);
    }, [rowData]);

    return (
        <>
            <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger asChild>
                    <Button
                        size={'sm'} 
                        className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'>
                        <FiEdit />
                    </Button>
                </DialogTrigger>
                <DialogContent className="mx-auto max-w-screen-xl z-99999">
                    <DialogHeader>
                        <DialogTitle>
                            Edit Identifikasi Perumahan yang Terkena Relokasi
                        </DialogTitle>
                        <DialogDescription>
                            Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
                        </DialogDescription>
                    </DialogHeader>
                    <form action='post' onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Jenis Lahan" className="mb-2">
                                    Jenis Relokasi Program
                                </Label>
                                <EditJenisProgramRelokasi
                                    getJenisProgramRelokasi={getJenisProgramRelokasi}
                                    setJenisProgramRelokasi={setJenisProgramRelokasi}
                                    selectedValue={jenisProgramRelokasi}
                                    disabledSelect={false}
                                />
                            </div>
                            { menuId === 8 && (
                                <div className="flex flex-col">
                                <Label htmlFor="Kabupaten" className="mb-2">
                                    Kabupaten
                                </Label>
                                <EditKabKotaSelectUI
                                    getKabKota={getKabKota}
                                    setKabKota={setKd_kabkota}
                                    selectedValue={kd_kabkota}
                                    disabledSelect={true}
                                />
                            </div>
                            )}
                            <div className="flex flex-col">
                                <Label htmlFor="Kecamatan" className="mb-2">
                                    Kecamatan
                                </Label>
                                <EditKecamatanSelectUI
                                    getKecamatan={getKecamatan}
                                    setKd_kec={setKd_kec}
                                    selectedValue={kd_kec}
                                    disabledSelect={true}
                                />
                            </div>
                            { menuId !== 8 && (
                                <div className="flex flex-col">
                                    <Label htmlFor="Kelurahan/Desa" className="mb-2">
                                        Kelurahan/Desa
                                    </Label>
                                    <EditDesaSelectUI
                                        getDesa={getDesa}
                                        setKd_desa={setKd_desa}
                                        selectedValue={kd_desa}
                                        kd_kec={kd_kec}
                                        disabledSelect={true}
                                    />
                                </div> 
                            )}
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah Rumah" className="mb-2">
                                    Jumlah Rumah (Unit)
                                </Label>
                                <Input
                                    id="Jumlah Rumah"
                                    type="number"
                                    className="col-span-1"
                                    value={jumlahRumah}
                                    onChange={(e) => setJumlahRumah(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah KK" className="mb-2">
                                    Jumlah KK
                                </Label>
                                <Input
                                    id="Jumlah KK"
                                    type="number"
                                    className="col-span-1"
                                    value={jumlahKK}
                                    onChange={(e) => setJumlahKK(e.target.value)}
                                    placeholder='Masukkan Jumlah KK'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Jumlah Jiwa" className="mb-2">
                                    Jumlah Jiwa
                                </Label>
                                <Input
                                    id="Jumlah KK"
                                    type="number"
                                    className="col-span-1"
                                    value={jumlahJiwa}
                                    onChange={(e) => setJumlahJiwa(e.target.value)}
                                    placeholder='Masukkan Jumlah Jiwa'
                                />
                            </div>
                       	</div>
                        {/* Legalitas Tanah */}
                        <h1 className='text-l font-bold mt-4'>Legalitas Tanah</h1>
                        <div className="grid grid-cols-2 gap-4 mt-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Legal" className="mb-2">
                                    Legal
                                </Label>
                                <Input
                                    id="Legal"
                                    type="number"
                                    className="col-span-1"
                                    value={statusLegal}
                                    onChange={(e) => setStatusLegal(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Legal'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Illegal" className="mb-2">
                                    Illegal
                                </Label>
                                <Input
                                    id="Illegal"
                                    type="number"
                                    className="col-span-1"
                                    value={statusIllegal}
                                    onChange={(e) => setStatusIllegal(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Illegal'
                                />
                            </div>
                        </div>
                        {/* Status Kepemilikan Rumah */}
                        <h1 className='text-l font-bold mt-4'>Status Kepemilikan Rumah</h1>
                        <div className="grid grid-cols-3 gap-4 mt-4">
                            <div className="flex flex-col">
                                <Label htmlFor="Milik" className="mb-2">
                                    Milik
                                </Label>
                                <Input
                                    id="Milik"
                                    type="number"
                                    className="col-span-1"
                                    value={statusMilik}
                                    onChange={(e) => setStatusMilik(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Milik'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Sewa" className="mb-2">
                                    Sewa
                                </Label>
                                <Input
                                    id="Sewa"
                                    type="number"
                                    className="col-span-1"
                                    value={statusSewa}
                                    onChange={(e) => setStatusSewa(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Sewa'
                                />
                            </div>
                            <div className="flex flex-col">
                                <Label htmlFor="Lainnya" className="mb-2">
                                    Lainnya
                                </Label>
                                <Input
                                    id="Lainnya"
                                    type="number"
                                    className="col-span-1"
                                    value={statusLainnya}
                                    onChange={(e) => setStatusLainnya(e.target.value)}
                                    placeholder='Masukkan Jumlah Rumah Lainnya'
                                />
                            </div>
                        </div>
                        <DialogFooter>
                            <div className="flex w-full justify-between mt-5">
                                <DialogClose asChild>
                                    <Button
                                        className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
                                    >
                                        Batal
                                    </Button>
                                </DialogClose>
                                <Button
                                    className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
                                    type="submit"
                                >
                                    Simpan
                                </Button>
                            </div>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default EPengumpulanDataRumahRelokasi;
