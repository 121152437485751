import Breadcrumb from "@/components/Breadcrumbs/Breadcrumb"
import TableDataCapaianTahunanAllBidang from "@/components/Tables/TableDataCapaianTahunanAllBidang"
import DefaultLayout from "@/layout/DefaultLayout"

const DataCapaianRumahRelokasiTahunan = () => {
  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead="Data Capaian Tahunan Rumah Relokasi"
        pageName="Data Capaian Tahunan Rumah Relokasi"
      />
      <TableDataCapaianTahunanAllBidang />
    </DefaultLayout>
  )
}

export default DataCapaianRumahRelokasiTahunan