import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../../layout/DefaultLayout';
import TableDataKumulatifRLKab from '@/components/Tables/RumahRelokasi/TableDataKumulatifRLKab';

const CapaianRLKab = () => {
  return (
    <DefaultLayout>
      <Breadcrumb
        pageHead="Rekapitulasi Capaian SPM Rumah Relokasi"
        pageName="Rumah Relokasi"
      />
      <TableDataKumulatifRLKab />
    </DefaultLayout>
  );
};
export default CapaianRLKab;
