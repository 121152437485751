import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import FIdentifikasiLahanBencana from '@/components/Forms/RumahBencana/FIdentifikasiLahanBencana';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import {
	useDeleteIdentifikasiLahanPotensial,
	useGetIdentifikasiLahanPotensial,
} from '@/hooks/perumahan/useRumahBencana';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { useEffect, useRef } from 'react';
import GLahanPotensial from '@/components/Guides/RumahBencana/PengumpulanData/GLahanPotensial';
import { EksisLahanPotensialRumahBencana } from '@/types/perumahan';
import ActionButtonLahanPotensial from '@/components/ActionButton/RumahBencana/PengumpulanData/ABLahanPotensial';
import JudulHeader from '@/components/Header/JudulHeader';
import { usePrivilegeRumahBencana } from '@/hooks/privilege/usePrivilege';
import { useMenuRumahBencana } from '@/hooks/menu/useMenu';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

const TIndentifikasiLahanPotensialRawanBencana = () => {
	const selectedTahun = useSelector((state: RootState) => state.tahun.value);
	const tahun = Number(selectedTahun);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const QueryClient = useQueryClient();

	//hook previlege
	const { isPrevilegeViewRumahBencana, kd_kabkota_to_use, kd_provinsi_to_use } =
		usePrivilegeRumahBencana();

	//hook menu
	const { isMenuRumahBencanaProv, isMenuRumahBencanaKab } =
		useMenuRumahBencana();

	//getData
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isLoading,
		refetch,
	} = useGetIdentifikasiLahanPotensial(
		kd_provinsi_to_use,
		kd_kabkota_to_use,
		tahun,
		menuId,
	);

	console.log(kd_kabkota_to_use, kd_provinsi_to_use, tahun, menuId);

	//Delete
	const { deleteIdentifikasiLahanPotensial, isPending, isSuccess } =
		useDeleteIdentifikasiLahanPotensial();

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	// Access the data
	const pages = data?.pages || [];

	useEffect(() => {
		refetch();
	}, []);

	const handleEdit = (rowData: EksisLahanPotensialRumahBencana) => {
		console.log('Edit data:', rowData);
	};

	const handleDelete = async (rowData: EksisLahanPotensialRumahBencana) => {
		try {
			await deleteIdentifikasiLahanPotensial({
				id_lhn_potensial: rowData.id_lhn_potensial,
			});
			QueryClient.refetchQueries({ queryKey: ['eksis-lahan-potensial'] });
			toast.success('Berhasil menghapus data Identifikasi Lahan Potensial');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus data Identifikasi Lahan Potensial');
		}
	};

	const cardRef = useRef(null);

	let rowCounter = 0;
	if (isLoading) return <CompLoader />;
	return (
		<>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				{isPrevilegeViewRumahBencana ? (
					<></>
				) : (
					<div className="my-2 mr-4 flex justify-end gap-4">
						<GLahanPotensial />
						<FIdentifikasiLahanBencana />
					</div>
				)}
				<ScrollArea className="relative h-full p-4">
					<JudulHeader isLoading={isLoading} cardRef={cardRef}>
						IDENTIFIKASI LAHAN POTENSIAL SEBAGAI LOKASI RELOKASI PERUMAHAN
						<br />
						SPM PERUMAHAN RAKYAT <br />
						{isMenuRumahBencanaProv ? (
							<>
								Penyediaan dan Rehabilitasi Rumah yang Layak Huni Bagi Korban
								Bencana Provinsi
							</>
						) : (
							<>
								Penyediaan dan Rehabilitasi Rumah yang Layak Huni Bagi Korban
								Bencana Kabupaten/Kota
							</>
						)}
					</JudulHeader>
					<Table className="w-full min-w-[1800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead className="thead-text border-l">No</TableHead>
								{isMenuRumahBencanaProv && (
									<TableHead className="thead-text text-center" rowSpan={2}>
										Kabupaten/Kota
									</TableHead>
								)}
								<TableHead className="thead-text text-center">
									Kecamatan
								</TableHead>
								<TableHead className="thead-text text-center">
									Kelurahan/Desa
								</TableHead>
								<TableHead className="thead-text text-center">
									Luas Lahan (Ha)
								</TableHead>
								<TableHead className="thead-text text-center">
									Pemilik
								</TableHead>
								<TableHead className="thead-text text-center">
									Keterangan
								</TableHead>
								{isPrevilegeViewRumahBencana ? (
									<></>
								) : (
									<TableHead className="thead-text text-center" rowSpan={2}>
										Aksi (Edit/Hapus)
									</TableHead>
								)}
							</TableRow>
						</TableHeader>
						<TableBody>
							{pages.map((page) =>
								page.data.map((item: EksisLahanPotensialRumahBencana) => (
									<TableRow
										key={item.id_lhn_potensial}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="tbody-text border-b border-l border-r text-center">
											{++rowCounter}
										</TableCell>
										{isMenuRumahBencanaProv && (
											<TableCell className="tbody-text border-b border-r text-center">
												{item.wilayah.nama_kabkota}
											</TableCell>
										)}
										<TableCell className="tbody-text border-b border-r text-center">
											{item.wilayah.nama_kecamatan}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.wilayah.nama_desa}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.luas_lhn}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.pemilik}
										</TableCell>
										<TableCell className="tbody-text border-b border-r text-center">
											{item.keterangan}
										</TableCell>
										{isPrevilegeViewRumahBencana ? (
											<></>
										) : (
											<TableCell className="tbody-text flex justify-center border-b border-r text-center">
												<ActionButtonLahanPotensial
													rowData={item}
													onEdit={handleEdit}
													onDelete={() => handleDelete(item)}
												/>
											</TableCell>
										)}
									</TableRow>
								)),
							)}
						</TableBody>
					</Table>
					<ScrollBar orientation="horizontal" />
					<div className="my-4 text-center" ref={ref}>
						{isFetchingNextPage
							? 'Muat Lebih Banyak Data...'
							: hasNextPage
								? 'Muat Lebih Banyak Data...'
								: 'Tidak Ada Data Lagi'}
					</div>
				</ScrollArea>
			</div>
		</>
	);
};

export default TIndentifikasiLahanPotensialRawanBencana;
