import { useState } from 'react';
import { useSelector } from 'react-redux';
import DefaultLayout from '../../../layout/DefaultLayout';

import TablePelaksanaan from '@/components/Tables/PemenuhanSPM/TablePelaksanaan';
import FormTambahRealisasi from '@/components/Forms/RencanaPelaksanaan/FormTambahRealisasi';
import { Button } from '@/components/ui/Button';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import { RootState } from '@/app/store';
import { FiArrowLeft } from 'react-icons/fi';

const PelaksanaanPemenuhanRumahRelokasi = () => {
	const [showForm, setShowForm] = useState("Lihat Data")
	const [editableId, setEditableId] = useState(0)
	const wilayah = useSelector((state: RootState) => state.wilayah.value)
	const tahun = useSelector((state: RootState) => state.tahun.value);

	return (
		<DefaultLayout>
			<Breadcrumb
				pageHead={`Pelaksanaan Pemenuhan ${tahun} Bidang Rumah Relokasi ${wilayah === 'provinsi' ? 'Provinsi' : 'Kabupaten/Kota'}`}
				pageName={`Pelaksanaan Pemenuhan ${tahun}`}
			/>
			<div className="mb-5 flex justify-end">
				<div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
					<div className="flex w-full flex-grow justify-end gap-2 text-lg font-bold md:w-auto">
						{showForm !== 'Lihat Data' && (
							<Button
								className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
								onClick={() => setShowForm('Lihat Data')}
							>
								<FiArrowLeft />
								Kembali
							</Button>
						)}
					</div>
				</div>
			</div>
			{showForm === 'Lihat Data' && (
				<TablePelaksanaan
					setEditableId={setEditableId}
					setShowForm={setShowForm}
				/>
			)}
			{showForm === 'Tambah Realisasi' && (
				<FormTambahRealisasi setShowForm={setShowForm} id={editableId} />
			)}
		</DefaultLayout>
	);
};

export default PelaksanaanPemenuhanRumahRelokasi;
