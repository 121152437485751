import React, { useState, useEffect } from 'react';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";

interface JenisBencanaSelectProps {
    getJenisBencana: any[];
    setJenisBencana: (id_jenis_bencana: number) => void; 
    selectedValue: number;
    disableSelect: boolean; 
}

const EditJenisBencanaSelectUI: React.FC<JenisBencanaSelectProps> = ({
    getJenisBencana,
    setJenisBencana,
    selectedValue,
    disableSelect
}) => {
    const [selectedJenisBencana, setSelectedJenisBencana] = useState('');

    useEffect(() => {
        const selectedJenis = getJenisBencana.find((jenisBencana: any) => jenisBencana.id_jenis_bencana === selectedValue);
        if (selectedJenis) {
            setSelectedJenisBencana(selectedJenis.nama_jenis_bencana);
        }
    }, [selectedValue, getJenisBencana]);

    return (
        <Select
            onValueChange={(value) => {
                if (value !== '') {
                    const selectedJenisBencana = getJenisBencana.find((jenisBencana: any) => jenisBencana.id_jenis_bencana === parseInt(value));
                    if (selectedJenisBencana) {
                        console.log("Jenis Bencana yang dipilih:", selectedJenisBencana.nama_jenis_bencana);
                        setSelectedJenisBencana(selectedJenisBencana.nama_jenis_bencana || "");
                        setJenisBencana(parseInt(value)); // Konversi ke number sebelum diteruskan
                    }
                }
            }}
            value={selectedValue.toString()}
            disabled={disableSelect}
        >
            <SelectTrigger>
                <SelectValue placeholder="Pilih Jenis Bencana">
                    {selectedJenisBencana || 'Pilih Jenis Bencana'}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getJenisBencana.map((jenisBencana: any, index: any) => (
                    <SelectItem key={index} value={jenisBencana.id_jenis_bencana.toString()}>
                        {jenisBencana.nama_jenis_bencana}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
};

export default EditJenisBencanaSelectUI;
