import { RootState } from "@/app/store";
import { useGetKabKota, useGetProvinsi } from "@/hooks/wilayah/useLokasi"
import { useEffect, useState, } from "react"
import { useSelector } from "react-redux";

const WilayahSidebar = () => {
  const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
  const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
  const privilege = useSelector((state: RootState) => state.auth.privilege_id);
  const wilayah = useSelector((state: RootState) => state.wilayah.value)

  const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
    (state: RootState) => state.location,
  );

  const [provinsi, setProvinsi] = useState("-")
  const [kabKota, setKabKota] = useState("-")

  const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
  const kd_kabkota_to_use =
    privilege === 10 || privilege === 4 || privilege === 8
      ? kd_kabkota_dux
      : kd_kabkota;

  const { getProvinsi, isLoading: isLoadingProvinsi } = useGetProvinsi()
  const { getKabKota, isLoading: isLoadingKabKota } = useGetKabKota(kd_provinsi_to_use)

  useEffect(() => {
    if (!isLoadingProvinsi && !isLoadingKabKota && kd_provinsi_to_use) {
      const filterProvinsi = getProvinsi.find((item: any) => item.kd_provinsi === kd_provinsi_to_use);
      setProvinsi(filterProvinsi ? filterProvinsi.nama_provinsi : "-");
      setKabKota("-");
    }
  }, [isLoadingProvinsi, isLoadingKabKota, kd_provinsi_to_use]);

  useEffect(() => {
    if (!isLoadingKabKota && kd_kabkota_to_use) {
      const filterKabKota = getKabKota.find((item: any) => item.kd_kabkota === kd_kabkota_to_use);
      setKabKota(filterKabKota ? filterKabKota.nama_kabkota : "-");
    }
  }, [isLoadingKabKota, kd_kabkota_to_use]);

  return (
    <div className='pb-10 flex flex-col gap-4'>
      {
        isLoadingKabKota || isLoadingProvinsi ?
          <p className="text-white font-medium">Loading...</p>
          :
          <>
            <p className='text-white font-medium tracking-wide flex flex-col'>
              <span>Provinsi: </span>
              <span className='font-black'>{provinsi}</span>
            </p>
            {wilayah !== "provinsi" &&
              <p className='text-white font-medium tracking-wide flex flex-col'>
                <span>Kabupaten/Kota:</span>
                <span className='font-black break-words'>{kabKota}</span>
              </p>
            }
          </>
      }
    </div>
  )
}

export default WilayahSidebar