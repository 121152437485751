import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from '../../libs/API';

export function useGetPeriodeAirMinum(periodeSatu: number, periodeDua: number) {
	return useInfiniteQuery({
		queryKey: ['periode-air-minum', periodeSatu, periodeDua],
		queryFn: async () => {
			const data = await API.get(
				`/periode-compare-am/?periode_1=${periodeSatu}&periode_2=${periodeDua}`,
			);
			return data.data;
		},
		initialPageParam: 1,
		refetchOnWindowFocus: true,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}
			return undefined;
		},
	});
}
