import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { API } from '../../libs/API';
import { AxiosResponse } from 'axios';

// Definisikan tipe data untuk OTP
interface OtpData {
	otp: number;
}

// Fungsi untuk melakukan post OTP dan mengembalikan data
const postOtpForCheck = async (otpData: OtpData): Promise<any> => {
	try {
		const token = localStorage.getItem('reset-password');
		const data: AxiosResponse = await API.post('/check-otp', otpData, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data;
	} catch (error) {
		// Tangani kesalahan di sini
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message);
		} else {
			throw new Error('Gagal memeriksa OTP.');
		}
		return null;
	}
};

// Hook untuk menggunakan mutasi post OTP untuk pengecekan
export const usePostOtpForCheck = (): UseMutationResult<
	any,
	unknown,
	OtpData,
	unknown
> => {
	return useMutation({
		mutationFn: postOtpForCheck,
		onError: (error: any) => {
			console.error('Error:', error);
		},
		onSuccess: (data) => {
			const token = data.headers['authorization'].split(' ')[1];
			// Lakukan sesuatu dengan respons dari server, misalnya menyimpan token
			if (token) {
				localStorage.setItem('reset-password', token);
			}
		},
	});
};
