import { RootState } from "@/app/store";
import { CompLoader } from "@/common/Loader";
import JudulHeader from "@/components/Header/JudulHeader";
import { Button } from "@/components/ui/Button"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useGetDataCapaianRumahRelokasi } from "@/hooks/perumahan/useRumahBencana";
import { hitungTotalData } from "@/libs/utils";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { FiDownload, FiEdit, FiPlus, FiTrash2 } from "react-icons/fi"
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import PenetapanRelokasi from '../../PengumpulanData/PenetapanRelokasi';
import PenetapanTidakAdaRelokasi from './PenetapanTidakAdaRelokasi';
import { usePrivilegeRumahRelokasi } from '@/hooks/privilege/usePrivilege';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteRencanaPemenuhanRumahRelokasi } from '@/hooks/perumahan/useRumahRelokasi';
import { toast } from 'sonner';
import ConfirmationDialog from '@/components/ui/ConfirmationDialog';
import { utils, writeFile } from "xlsx-js-style";

type TableDataCapaianKumulatifRumahRelokasiProps = {
	setShowForm: Dispatch<SetStateAction<string>>;
	setEditableId: any;
};

const TableDataCapaianKumulatifRumahRelokasi = ({
	setShowForm,
	setEditableId,
}: TableDataCapaianKumulatifRumahRelokasiProps) => {
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const nama_kabkota = useSelector((state: RootState) => state.auth.wilayah.nama_kabkota);
	const nama_provinsi = useSelector((state: RootState) => state.auth.wilayah.nama_provinsi);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);

	const { kd_provinsi_dux, kd_kabkota_dux, nama_kabkota: nama_kabkota_dux, nama_provinsi: nama_provinsi_dux } = useSelector(
		(state: RootState) => state.location,
	);

	const menu_id = useSelector((state: RootState) => state.menuId.value);
	const tahun = useSelector((state: RootState) => state.tahun.value);

	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const nama_provinsi_to_use = privilege === 10 ? nama_provinsi_dux : nama_provinsi
	const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;
	const nama_kabkota_to_use = privilege === 10 ? nama_kabkota_dux : nama_kabkota

	const [penetapanRelokasi, setPenetapanRelokasi] = useState<any>(null);
	const { isPrevilegeViewRumahRelokasi } = usePrivilegeRumahRelokasi();


	const [dataRL, setDataRL] = useState<any>([])

	const [subTotal, setSubTotal] = useState({
		jml_rmh_relokasi: 0,
		tgt_ganti_uang: 0,
		tgt_bgn_kembali: 0,
		tgt_subsidi: 0,
		tgt_akses_rmh: 0,
		real_ganti_uang: 0,
		real_bgn_kembali: 0,
		real_subsidi: 0,
		real_akses_rmh: 0,
	});

	const [total, setTotal] = useState({
		jml_rmh_relokasi: 0,
		target: 0,
		realisasi: 0,
		capaian: 0,
	});

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetDataCapaianRumahRelokasi({
			menu_id,
			kd_provinsi: kd_provinsi_to_use,
			kd_kabkota: kd_kabkota_to_use,
			tahun,
		});

	const { ref, inView } = useInView({
		threshold: 0,
	});

	const cardRef = useRef(null);

	useEffect(() => {
		if (!isLoading) {
			if (!hasNextPage) {
				const subTotalJumlahRumahRelokasi = hitungTotalData(
					pages,
					'jml_rmh_relokasi',
				);
				const subTotalTargetGantiUang = hitungTotalData(
					pages,
					'tgt_ganti_uang',
				);
				const subTotalTargetPembangunanKembali = hitungTotalData(
					pages,
					'tgt_bgn_kembali',
				);
				const subTotalTargetSubsidi = hitungTotalData(pages, 'tgt_subsidi');
				const subTotalTargetAksesRumah = hitungTotalData(
					pages,
					'tgt_akses_rmh',
				);
				const subTotalRealisasiGantiUang = hitungTotalData(
					pages,
					'real_ganti_uang',
				);
				const subTotalRealisasiPembangunanKembali = hitungTotalData(
					pages,
					'real_bgn_kembali',
				);
				const subTotalRealisasiSubsidi = hitungTotalData(pages, 'real_subsidi');
				const subTotalRealisasiAksesRumah = hitungTotalData(
					pages,
					'real_akses_rmh',
				);

				const totalJumlahRumahRelokasi = subTotalJumlahRumahRelokasi;
				const totalTarget =
					subTotalTargetGantiUang +
					subTotalTargetPembangunanKembali +
					subTotalTargetSubsidi +
					subTotalTargetAksesRumah;
				const totalRealisasi =
					subTotalRealisasiGantiUang +
					subTotalRealisasiPembangunanKembali +
					subTotalRealisasiSubsidi +
					subTotalRealisasiAksesRumah;
				const totalCapaian = (totalRealisasi / totalTarget) * 100;

				setSubTotal((prevState: any) => ({
					...prevState,
					jml_rmh_relokasi: subTotalJumlahRumahRelokasi,
					tgt_ganti_uang: subTotalTargetGantiUang,
					tgt_bgn_kembali: subTotalTargetPembangunanKembali,
					tgt_subsidi: subTotalTargetSubsidi,
					tgt_akses_rmh: subTotalTargetAksesRumah,
					real_ganti_uang: subTotalRealisasiGantiUang,
					real_bgn_kembali: subTotalRealisasiPembangunanKembali,
					real_subsidi: subTotalRealisasiSubsidi,
					real_akses_rmh: subTotalRealisasiAksesRumah,
				}));

				setTotal((prevState: any) => ({
					...prevState,
					jml_rmh_relokasi: totalJumlahRumahRelokasi,
					target: totalTarget,
					realisasi: totalRealisasi,
					capaian: totalCapaian.toFixed(2),
				}));
			}
		}
	}, [hasNextPage, data, isLoading, tahun]);

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
			checkPenetapan();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	const handleDownload = () => {
		const transformedData = dataRL.map((item: any, index: any) => ([
			{ v: index + 1, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.jenis_relokasi.nama_jenis_relokasi, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.wilayah.nama_kecamatan, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.wilayah.nama_desa, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.jml_rmh_relokasi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_ganti_uang, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_bgn_kembali, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_subsidi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_akses_rmh, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_ganti_uang, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_bgn_kembali, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_subsidi, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_akses_rmh, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
		]));

		const header = [
			{ v: "No", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Jenis Program Relokasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Kecamatan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Kelurahan/Desa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Jumlah Rumah Terkena Relokasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
		];

		const header1 = [
			{ v: "Penggantian Uang", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Pembangunan Kembali", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Subsidi Uang Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Tinggal Rumah Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Penggantian Uang", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Pembangunan Kembali", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Subsidi Uang Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Tinggal Rumah Sewa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
		];

		const header2 = [
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
		];

		const subTotalRow = [
			{ v: subTotal.jml_rmh_relokasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_ganti_uang, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_bgn_kembali, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_subsidi, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.tgt_akses_rmh, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_ganti_uang, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_bgn_kembali, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_subsidi, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: subTotal.real_akses_rmh, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(ws, [[{ v: "DATA CAPAIAN", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A1" })
		utils.sheet_add_aoa(ws, [[{ v: "SPM PERUMAHAN RAKYAT", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A2" })
		utils.sheet_add_aoa(ws, [[{ v: `PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A3" })
		utils.sheet_add_aoa(ws, [[{ v: `RELOKASI PROGRAM PEMERINTAH DAERAH KABUPATEN/KOTA ${Number(tahun)}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A4" })

		utils.sheet_add_aoa(ws, [header], { origin: "A5" })
		utils.sheet_add_aoa(ws, [[{ v: "Target", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "F5" })
		utils.sheet_add_aoa(ws, [[{ v: "Realisasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "J5" })
		utils.sheet_add_aoa(ws, [header1], { origin: "F6" })
		utils.sheet_add_aoa(ws, [header2], { origin: "E7" })
		utils.sheet_add_aoa(ws, transformedData, { origin: "A8" })

		utils.sheet_add_aoa(ws, [[{ v: "Sub Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 8}` })
		utils.sheet_add_aoa(ws, [subTotalRow], { origin: `E${transformedData.length + 8}` })

		utils.sheet_add_aoa(ws, [[{ v: "Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [[{ v: total.jml_rmh_relokasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `E${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [[{ v: total.target, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `F${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [[{ v: total.realisasi, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `J${transformedData.length + 9}` })



		utils.sheet_add_aoa(ws, [[{ v: "Capaian SPM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 10}` })
		utils.sheet_add_aoa(ws, [[{ v: `${isNaN(total.capaian) ? 0 : total.capaian}%`, t: "", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `J${transformedData.length + 10}` })

		ws['!cols'] = [
			{ wpx: 50 },
			{ wpx: 576 },
			{ wpx: 104 },
			{ wpx: 144 },
			{ wpx: 197 },
			{ wpx: 115 },
			{ wpx: 144 },
			{ wpx: 118 },
			{ wpx: 167 },
			{ wpx: 115 },
			{ wpx: 144 },
			{ wpx: 118 },
			{ wpx: 167 },
		]

		ws['!merges'] = [
			// 	// COLSPANS
			utils.decode_range("A1:M1"),
			utils.decode_range("A2:M2"),
			utils.decode_range("A3:M3"),
			utils.decode_range("A4:M4"),
			utils.decode_range("F5:I5"),
			utils.decode_range("J5:M5"),
			utils.decode_range(`A${transformedData.length + 8}:D${transformedData.length + 8}`),
			utils.decode_range(`A${transformedData.length + 9}:D${transformedData.length + 9}`),
			utils.decode_range(`F${transformedData.length + 9}:I${transformedData.length + 9}`),
			utils.decode_range(`J${transformedData.length + 9}:M${transformedData.length + 9}`),
			utils.decode_range(`A${transformedData.length + 10}:I${transformedData.length + 10}`),
			utils.decode_range(`J${transformedData.length + 10}:M${transformedData.length + 10}`),

			// ROWSPANS
			utils.decode_range("A5:A7"),
			utils.decode_range("B5:B7"),
			utils.decode_range("C5:C7"),
			utils.decode_range("D5:D7"),
			utils.decode_range("E5:E6"),
		]
		utils.book_append_sheet(wb, ws, "Kumulatif Rumah Relokasi")
		writeFile(wb, `Data Capaian Kumulatif Rumah Relokasi ${nama_provinsi_to_use} - ${nama_kabkota_to_use} - ${tahun}.xlsx`)
	}

	// CheckpenetapanRelokasi
	const checkPenetapan = () => {
		for (const page of pages) {
			for (const jenis_relokasi of page.data) {
				if (jenis_relokasi.jenis_relokasi_id === 14) {
					setPenetapanRelokasi(true);
					return;
				}
			}
		}
		setPenetapanRelokasi(false);
	};

	//delete
	const [deleteRowId, setDeleteRowId] = useState(0);
	const queryClient = useQueryClient();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const { deleteRencanaPemenuhanRumahRelokasi, isPending } =
		useDeleteRencanaPemenuhanRumahRelokasi();
	const confirmDelete = async (id: number) => {
		try {
			await deleteRencanaPemenuhanRumahRelokasi(id);
			toast.success('Berhasil menghapus rencana pemenuhan!');
		} catch (error) {
			toast.error('Gagal menghapus rencana pemenuhan!');
		} finally {
			queryClient.invalidateQueries({ queryKey: ['eksis-relokasi'] });
			setIsDialogOpen(false);
		}
	};

	const handleEditMode = (id: any) => {
		setShowForm('Edit Data');
		setEditableId(id);
	};

	const pages = data?.pages || [];

	useEffect(() => {
		checkPenetapan();
	}, [pages]);

	useEffect(() => {
		if (!isLoading) {
			const allDataRL = pages.flatMap((page) => page.data)
			setDataRL(allDataRL)
		}
	}, [isLoading, data])

	let rowCounter = 0;

	if (penetapanRelokasi) return <PenetapanTidakAdaRelokasi />;

	if (isLoading || isPending) return <CompLoader />;

	return (
		<>
			<div className="flex justify-end pb-4">
				<div className="flex gap-4">
					{dataRL.length !== 0 &&
						<Button
							className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
							onClick={() => handleDownload()}
						>
							<FiDownload />
							Download Data
						</Button>
					}
					{isPrevilegeViewRumahRelokasi ? (
						<></>
					) : (
						<>
							<Button
								className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
								onClick={() => setShowForm('Form Data')}
							>
								<FiPlus />
								Input Data
							</Button>
						</>
					)}
				</div>
			</div>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				<ScrollArea className="relative h-full p-4">
					<JudulHeader isLoading={isLoading} cardRef={cardRef} minusWidth={32}>
						DATA CAPAIAN <br />
						SPM PERUMAHAN RAKYAT <br />
						PENYEDIAAN RUMAH YANG LAYAK HUNI BAGI MASYARAKAT YANG TERKENA <br />
						RELOKASI PROGRAM PEMERINTAH DAERAH KABUPATEN/KOTA {Number(tahun)}
					</JudulHeader>
					<Table className="w-full min-w-[2800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									rowSpan={3}
									className="thead-text border-l text-center"
								>
									No
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Jenis Program Relokasi
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Kecamatan
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Kelurahan / Desa
								</TableHead>
								<TableHead
									rowSpan={2}
									className="thead-text border-b-0 text-center"
								>
									Jumlah Rumah Terkena Relokasi
								</TableHead>
								<TableHead
									colSpan={4}
									className="thead-text border-b-0 text-center"
								>
									Target
								</TableHead>
								<TableHead
									colSpan={4}
									className="thead-text border-b-0 text-center"
								>
									Realisasi
								</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">
									Aksi
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-b-0 text-center">
									Penggantian Uang
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Pembangunan Kembali
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Subsidi Uang Sewa
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Akses Tinggal Rumah Sewa
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Penggantian Uang
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Pembangunan Kembali
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Subsidi Uang Sewa
								</TableHead>
								<TableHead className="thead-text border-b-0 text-center">
									Akses Tinggal Rumah Sewa
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
								<TableHead className="thead-text text-center">
									(Rumah)
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{pages.map((page, i) => {
								return (
									<React.Fragment key={i}>
										{page.data.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map((item: any, key: number) => {
											rowCounter++;
											return (
												<TableRow
													key={key}
													className={
														rowCounter % 2 === 0
															? 'bg-gray-2 dark:bg-meta-4'
															: 'bg-white dark:bg-boxdark'
													}
												>
													<TableCell className="tbody-text border-b border-l border-r">
														{rowCounter}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.jenis_relokasi?.nama_jenis_relokasi ?? '-'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.wilayah?.nama_kecamatan ?? '-'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.wilayah?.nama_desa ?? '-'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.jml_rmh_relokasi}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_ganti_uang}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_bgn_kembali}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_subsidi}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.tgt_akses_rmh}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_ganti_uang}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_bgn_kembali}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_subsidi}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.real_akses_rmh}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														<div className="flex flex-col items-center justify-center gap-4">
															{Number(item.verifikasi) !== 1 && (
																<div className="flex gap-4">
																	<Button
																		size={'sm'}
																		className="inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600"
																		onClick={() =>
																			handleEditMode(item.id_eksis_relokasi)
																		}
																	>
																		<FiEdit />
																	</Button>
																	<Button
																		size={'sm'}
																		className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600"
																		onClick={() => {
																			setIsDialogOpen(true);
																			setDeleteRowId(item.id_eksis_relokasi);
																		}}
																	>
																		<FiTrash2 />
																	</Button>
																</div>
															)}
														</div>
													</TableCell>
												</TableRow>
											);
										})}
									</React.Fragment>
								);
							})}
							{!hasNextPage && (
								<>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={4}
										>
											Sub Total
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.jml_rmh_relokasi}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_ganti_uang}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_bgn_kembali}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_subsidi}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.tgt_akses_rmh}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_ganti_uang}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_bgn_kembali}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_subsidi}
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{subTotal.real_akses_rmh}
										</TableCell>
									</TableRow>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={4}
										>
											Total
										</TableCell>
										<TableCell className="tfooter-text border-b border-r text-lg">
											{total.jml_rmh_relokasi}
										</TableCell>
										<TableCell
											className="tfooter-text border-b border-r text-lg"
											colSpan={4}
										>
											{total.target}
										</TableCell>
										<TableCell
											className="tfooter-text border-b border-r text-lg"
											colSpan={4}
										>
											{total.realisasi}
										</TableCell>
									</TableRow>
									<TableRow className="bg-black/10 dark:bg-white/10">
										<TableCell
											className="tfooter-text border-b border-l border-r text-lg"
											colSpan={9}
										>
											Capaian SPM
										</TableCell>
										<TableCell
											className="tfooter-text border-b border-r text-lg"
											colSpan={4}
										>
											{isNaN(total.capaian) ? 0 : total.capaian}%
										</TableCell>
									</TableRow>
								</>
							)}
						</TableBody>
					</Table>
					<div className="h-1" ref={ref}>
						{isFetchingNextPage && (
							<div className="flex items-center justify-center p-4">
								<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
							</div>
						)}
					</div>
					<ConfirmationDialog
						isDialogOpen={isDialogOpen}
						setIsDialogOpen={setIsDialogOpen}
						confirmAction={() => confirmDelete(deleteRowId)}
						title="Apakah anda yakin?"
						description="Aksi ini tidak bisa diurungkan, data rencana yang telah dihapus tidak bisa dikembalikan."
						confirmButtonColor="bg-red-500 text-white hover:bg-red-600 dark:bg-red-400 dark:hover:bg-red-600"
					/>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};

export default TableDataCapaianKumulatifRumahRelokasi