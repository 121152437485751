import { API } from "@/libs/API";
import { useMutation, useQuery } from "@tanstack/react-query";

export function useUploadFile() {
  const {
    mutateAsync: asyncUploadFile,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await API.post('/upload-file/', data);
      return response;
    },
  });
  return { asyncUploadFile, isPending, isSuccess };
}

export function useGetFile(kd_provinsi: number, kd_kabkota: number, menu_id: number, tahun_upload: number) {
  const { data: getFiles, isLoading } = useQuery({
    queryKey: ["get-file", kd_provinsi, kd_kabkota, menu_id, tahun_upload],
    queryFn: async () => {
      const { data } = await API.get(`/get-filename/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&menu_id=${menu_id}&tahun_upload=${tahun_upload}`)
      return data.data
    }
  })

  return { getFiles, isLoading }
}

export function useDownloadFile(kd_provinsi: number, kd_kabkota: number, menu_id: number, tahun_upload: number) {
  const { data: downloadFile, isLoading } = useQuery({
    queryKey: ["download-file", kd_provinsi, kd_kabkota, menu_id, tahun_upload],
    queryFn: async (filename: any) => {
      const { data } = await API.get(`/download-file/${filename}/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&menu_id=${menu_id}&tahun_upload=${tahun_upload}`)
      return data.data
    }
  })

  return { downloadFile, isLoading }
}
