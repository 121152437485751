import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { CompLoader } from '@/common/Loader';
import { useInView } from 'react-intersection-observer';
import { useGetDataEksisAirLimbah } from '@/hooks/airLimbah/useDataCapaianAL';
import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/Button';
import { FiAlertCircle, FiDownload, FiEdit, FiUpload } from 'react-icons/fi';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import JudulHeader from '@/components/Header/JudulHeader';
import { useGetFile } from '@/hooks/useUpload';
import { utils, writeFile } from 'xlsx-js-style';

type TDataCapaianALProps = {
	setShowForm: Dispatch<SetStateAction<string>>;
	sampleId: number;
	handleSampleChange: (e: number) => void
};

const TDataCapaianALSample: React.FC<TDataCapaianALProps> = ({
	setShowForm,
	sampleId,
	handleSampleChange,
}) => {
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const nama_provinsi = useSelector((state: RootState) => state.auth.wilayah.nama_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const nama_kabkota = useSelector((state: RootState) => state.auth.wilayah.nama_kabkota);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const tahun = useSelector((state: RootState) => state.tahun.value)
	const menu_id = useSelector((state: RootState) => state.menuId.value)
	const wilayah = useSelector((state: RootState) => state.wilayah.value)

	const { kd_provinsi_dux, kd_kabkota_dux, nama_kabkota: nama_kabkota_dux, nama_provinsi: nama_provinsi_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const cardRef = useRef(null)

	const [file, setFile] = useState<any>(null)

	const [total, setTotal] = useState({
		target: {
			total: 0,
			akses_layak: 0,
			akses_aman_spald_s: 0,
			akses_aman_spald_t: 0
		},
		realisasi: {
			akses_layak: 0,
			akses_aman_spald_s: 0,
			akses_aman_spald_t: 0,
			tanpa_akses: 0
		}
	})

	const [persentaseLayanan, setPersentaseLayanan] = useState({
		real_akses_layak: 0,
		real_akses_aman_spald_s: 0,
		real_akses_aman_spald_t: 0,
		real_tanpa_akses: 0,
		capaian: 0
	})

	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const nama_provinsi_to_use = privilege === 10 ? nama_provinsi_dux : nama_provinsi
	const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;
	const nama_kabkota_to_use = privilege === 10 ? nama_kabkota_dux : nama_kabkota

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isLoading,
	} = useGetDataEksisAirLimbah({
		kd_provinsi: kd_provinsi_to_use,
		kd_kabkota: kd_kabkota_to_use,
		tahun,
		sample_id: sampleId,
	})

	const { getFiles, isLoading: loadFile } = useGetFile(kd_provinsi, kd_kabkota, menu_id, Number(tahun))

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	useEffect(() => {
		if (!isLoading) {
			if (!hasNextPage) {

				const totalTargetRumah = pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr.tgt_total, 0)
				const totalTargetAksesLayak = pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr.tgt_aks_layak, 0)
				const totalTargetSPALDS = pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr.tgt_aks_aman_spald_s, 0)
				const totalTargetSPALDT = pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr.tgt_aks_aman_spald_t, 0)

				const totalRealisasiAksesLayak = pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr.new_real_aks_layak, 0)
				const totalRealisasiSPALDS = pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr.real_aks_aman_spald_s, 0)
				const totalRealisasiSPALDT = pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr.real_aks_aman_spald_t, 0)
				const totalRealisasiTanpaAkses = pages.flatMap((page) => page.data).reduce((acc: any, curr: any) => acc + curr.real_tnp_aks, 0)

				const persentaseRealisasiAksesLayak = (totalRealisasiAksesLayak / totalTargetRumah) * 100
				const persentaseRealisasiAksesAmanSPALDS = (totalRealisasiSPALDS / totalTargetRumah) * 100
				const persentaseRealisasiAksesAmanSPALDT = (totalRealisasiSPALDT / totalTargetRumah) * 100
				const persentaseRealisasiTanpaAkses = (totalRealisasiTanpaAkses / totalTargetRumah) * 100
				const persentaseCapaian = ((totalRealisasiAksesLayak + totalRealisasiSPALDS + totalRealisasiSPALDT) / totalTargetRumah) * 100

				setTotal((prevState: any) => ({ ...prevState, target: { total: totalTargetRumah, akses_layak: totalTargetAksesLayak, akses_aman_spald_s: totalTargetSPALDS, akses_aman_spald_t: totalTargetSPALDT }, realisasi: { akses_layak: totalRealisasiAksesLayak, akses_aman_spald_s: totalRealisasiSPALDS, akses_aman_spald_t: totalRealisasiSPALDT, tanpa_akses: totalRealisasiTanpaAkses } }))

				setPersentaseLayanan((prevState: any) => ({ ...prevState, real_akses_layak: persentaseRealisasiAksesLayak.toFixed(2), real_akses_aman_spald_s: persentaseRealisasiAksesAmanSPALDS.toFixed(2), real_akses_aman_spald_t: persentaseRealisasiAksesAmanSPALDT.toFixed(2), real_tanpa_akses: persentaseRealisasiTanpaAkses.toFixed(2), capaian: persentaseCapaian.toFixed(2) }))
			}
		}
	}, [data, isLoading, hasNextPage, tahun, sampleId])

	const handleDownload = () => {
		const allDataAL = pages.flatMap((page) => page.data)
		const transformedData = allDataAL.map((item, index) => ([
			{ v: index + 1, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.nama_kecamatan, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.nama_desa, t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_total, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_aks_layak, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_aks_aman_spald_s, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.tgt_aks_aman_spald_t, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.new_real_aks_layak, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_aks_aman_spald_s, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_aks_aman_spald_t, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: item.real_tnp_aks, t: "n", s: { alignment: { horizontal: "center", vertical: "center" } } }
		]));

		const header = [
			{ v: "No", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Kecamatan", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Desa", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
		];

		const header1 = [
			{ v: "Total", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Layak", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Aman SPALD-S", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Aman SPALD-T", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Layak", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Aman SPALD-S", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Akses Aman SPALD-T", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Tanpa Akses", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
		];

		const header2 = [
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "(Rumah)", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } },
			{ v: "Rumah", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }
		];

		// Assuming `total` and `persentaseLayanan` are defined elsewhere
		const totalRow = [
			{ v: total.target.total, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.target.akses_layak, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.target.akses_aman_spald_s, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.target.akses_aman_spald_t, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.realisasi.akses_layak, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.realisasi.akses_aman_spald_s, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.realisasi.akses_aman_spald_t, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: total.realisasi.tanpa_akses, t: "n", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }
		];

		const persentaseRow = [
			{ v: `${isNaN(persentaseLayanan.real_akses_layak) ? 0 : persentaseLayanan.real_akses_layak}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: `${isNaN(persentaseLayanan.real_akses_aman_spald_s) ? 0 : persentaseLayanan.real_akses_aman_spald_s}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: `${isNaN(persentaseLayanan.real_akses_aman_spald_t) ? 0 : persentaseLayanan.real_akses_aman_spald_t}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } },
			{ v: `${isNaN(persentaseLayanan.real_tanpa_akses) ? 0 : persentaseLayanan.real_tanpa_akses}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }
		];

		const capaianRow = [
			{ v: `${isNaN(persentaseLayanan.capaian) ? 0 : persentaseLayanan.capaian}%`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }
		];
		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(ws, [[{ v: "DATA CAPAIAN", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A1" })
		utils.sheet_add_aoa(ws, [[{ v: "SPM PERKERJAAN UMUM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A2" })
		utils.sheet_add_aoa(ws, [[{ v: `PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK TAHUN ${tahun}`, t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "A3" })
		utils.sheet_add_aoa(ws, [header], { origin: "A4" })
		utils.sheet_add_aoa(ws, [[{ v: "Target", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "D4" })
		utils.sheet_add_aoa(ws, [[{ v: "Realisasi", t: "s", s: { alignment: { horizontal: "center", vertical: "center" } } }]], { origin: "H4" })
		utils.sheet_add_aoa(ws, [header1], { origin: "D5" })
		utils.sheet_add_aoa(ws, [header2], { origin: "D6" })
		utils.sheet_add_aoa(ws, transformedData, { origin: "A7" })

		utils.sheet_add_aoa(ws, [[{ v: "Total", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 7}` })
		utils.sheet_add_aoa(ws, [totalRow], { origin: `D${transformedData.length + 7}` })

		utils.sheet_add_aoa(ws, [[{ v: "Persentase Layanan", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 8}` })
		utils.sheet_add_aoa(ws, [persentaseRow], { origin: `H${transformedData.length + 8}` })

		utils.sheet_add_aoa(ws, [[{ v: "Capaian SPM", t: "s", s: { font: { bold: true }, alignment: { horizontal: "center", vertical: "center" } } }]], { origin: `A${transformedData.length + 9}` })
		utils.sheet_add_aoa(ws, [capaianRow], { origin: `H${transformedData.length + 9}` })

		ws['!cols'] = [
			{ wpx: 50 },
			{ wpx: 132 },
			{ wpx: 162 },
			{ wpx: 58 },
			{ wpx: 77 },
			{ wpx: 131 },
			{ wpx: 131 },
			{ wpx: 77 },
			{ wpx: 131 },
			{ wpx: 131 },
			{ wpx: 80 },
		]

		ws['!merges'] = [
			// COLSPANS
			utils.decode_range("A1:K1"),
			utils.decode_range("A2:K2"),
			utils.decode_range("A3:K3"),
			utils.decode_range("D4:G4"),
			utils.decode_range("H4:K4"),
			utils.decode_range(`A${transformedData.length + 7}:C${transformedData.length + 7}`),
			utils.decode_range(`A${transformedData.length + 8}:G${transformedData.length + 8}`),
			utils.decode_range(`A${transformedData.length + 9}:G${transformedData.length + 9}`),
			utils.decode_range(`H${transformedData.length + 9}:K${transformedData.length + 9}`),

			//ROWSPANS
			utils.decode_range("A4:A6"),
			utils.decode_range("B4:B6"),
			utils.decode_range("C4:C6"),
		]
		utils.book_append_sheet(wb, ws, "Kumulatif Air Limbah")
		writeFile(wb, `Data Capaian Kumulatif Air Limbah ${nama_provinsi_to_use} - ${nama_kabkota_to_use} - ${tahun}.xlsx`)
	}

	useEffect(() => {
		if (!loadFile) {
			setFile(getFiles[0])
		}
	}, [loadFile, sampleId])

	const pages = data?.pages || [];

	let rowCounter = 0;

	if (isLoading) return <CompLoader />

	return (
		<>
			<div className='flex justify-between pb-4'>
				<div className="sticky top-0 flex items-center justify-start py-4 text-center">
					<div className="inline-flex items-center gap-2 text-center font-bold">
						<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data di bawah ini bisa didownload, scroll terlebih dahulu sampai melihat kolom Total
					</div>
				</div>
				<div className="flex gap-4">
					{file || data!.pages[0].data.length !== 0 ?
						<>
							{!hasNextPage &&
								<Button
									className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									onClick={() => handleDownload()}
								>
									<FiDownload />
									Download Data
								</Button>
							}
							<Button
								className="inline-flex items-center justify-center rounded-lg gap-2 bg-[#fab23d] px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
								onClick={() => setShowForm("Edit Data")}
							>
								<FiEdit />
								Edit Data
							</Button>
						</>
						:
						<Button
							className="inline-flex items-center justify-center rounded-lg gap-2 bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
							onClick={() => setShowForm("Form Data")}
						>
							<FiUpload />
							Pilih Data
						</Button>
					}
				</div>
			</div>
			<div ref={cardRef} className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-1 flex-col overflow-hidden">
				<div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 sm:grid-cols-3">
					<div
						className={`py-4 text-center ${sampleId === 1 ? 'bg-[#fab23d]' : 'bg-[#364878]'
							} cursor-pointer rounded-md text-white hover:bg-graydark`}
						onClick={() => handleSampleChange(1)}
					>
						Primer BNBA
					</div>
					<div
						className={`py-4 text-center ${sampleId === 2 ? 'bg-[#fab23d]' : 'bg-[#364878]'
							} cursor-pointer rounded-md text-white hover:bg-graydark`}
						onClick={() => handleSampleChange(2)}
					>
						Primer Sampling
					</div>
					<div
						className={`py-4 text-center ${sampleId === 3 ? 'bg-[#fab23d]' : 'bg-[#364878]'
							} cursor-pointer rounded-md text-white hover:bg-graydark`}
						onClick={() => handleSampleChange(3)}
					>
						Sekunder
					</div>
				</div>
				<ScrollArea className='h-full p-4 relative'>
					<JudulHeader isLoading={isLoading} cardRef={cardRef}>
						DATA CAPAIAN <br />
						SPM PERKERJAAN UMUM <br />
						PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK TAHUN {Number(tahun)}
					</JudulHeader>
					<Table className="min-w-[1600px] w-full table-auto">
						<TableHeader className=" bg-gray-2 dark:bg-meta-4 sticky top-0">
							<TableRow>
								<TableHead rowSpan={3} className="border-l thead-text text-center">No</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">Kecamatan</TableHead>
								<TableHead rowSpan={3} className="thead-text text-center">Desa</TableHead>
								<TableHead colSpan={4} className="thead-text text-center border-b-0">Target</TableHead>
								<TableHead colSpan={4} className="thead-text text-center border-b-0">Realisasi</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className='thead-text text-center border-b-0'>Total</TableHead>
								<TableHead className='thead-text text-center border-b-0'>Akses Layak</TableHead>
								<TableHead className='thead-text text-center border-b-0'>Akses Aman SPALD-S</TableHead>
								<TableHead className='thead-text text-center border-b-0'>Akses Aman SPALD-T</TableHead>
								<TableHead className='thead-text text-center border-b-0'>Akses Layak</TableHead>
								<TableHead className='thead-text text-center border-b-0'>Akses Aman SPALD-S</TableHead>
								<TableHead className='thead-text text-center border-b-0'>Akses Aman SPALD-T</TableHead>
								<TableHead className='thead-text text-center border-b-0'>Tanpa Akses</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className='thead-text text-center'>(Rumah Tangga)</TableHead>
								<TableHead className='thead-text text-center'>(Rumah Tangga)</TableHead>
								<TableHead className='thead-text text-center'>(Rumah Tangga)</TableHead>
								<TableHead className='thead-text text-center'>(Rumah Tangga)</TableHead>
								<TableHead className='thead-text text-center'>(Rumah Tangga)</TableHead>
								<TableHead className='thead-text text-center'>(Rumah Tangga)</TableHead>
								<TableHead className='thead-text text-center'>(Rumah Tangga)</TableHead>
								<TableHead className='thead-text text-center'>(Rumah Tangga)</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{pages.map((page: any, key: any) => {
								return (
									<React.Fragment key={key}>
										{page.data.map((item: any, i: any) => {
											rowCounter++;
											return (
												<TableRow key={i}>
													<TableCell className='tbody-text border-b border-l border-r'>{rowCounter}</TableCell>
													<TableCell className='tbody-text border-b border-l-0 border-r'>{item.nama_kecamatan}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.nama_desa}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.tgt_total}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.tgt_aks_layak}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.tgt_aks_aman_spald_s}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.tgt_aks_aman_spald_t}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.new_real_aks_layak}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.real_aks_aman_spald_s}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.real_aks_aman_spald_t}</TableCell>
													<TableCell className="tbody-text border-b border-r">{item.real_tnp_aks}</TableCell>
												</TableRow>
											)
										})}
									</React.Fragment>
								)
							})}
						</TableBody>
						{!hasNextPage &&
							<TableFooter>
								<TableRow className='bg-black/10 dark:bg-white/10'>
									<TableCell className='text-lg tfooter-text border border-t-0' colSpan={3}>Total</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.target.total}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.target.akses_layak}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.target.akses_aman_spald_s}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.target.akses_aman_spald_t}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.realisasi.akses_layak}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.realisasi.akses_aman_spald_s}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.realisasi.akses_aman_spald_t}</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{total.realisasi.tanpa_akses}</TableCell>
								</TableRow>
								<TableRow className='bg-black/10 dark:bg-white/10'>
									<TableCell className='text-lg tfooter-text border border-t-0' colSpan={7}>Persentase Layanan</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{isNaN(persentaseLayanan.real_akses_layak) ? 0 : persentaseLayanan.real_akses_layak}%</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{isNaN(persentaseLayanan.real_akses_aman_spald_s) ? 0 : persentaseLayanan.real_akses_aman_spald_s}%</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{isNaN(persentaseLayanan.real_akses_aman_spald_t) ? 0 : persentaseLayanan.real_akses_aman_spald_t}%</TableCell>
									<TableCell className='tfooter-text border-r border-b'>{isNaN(persentaseLayanan.real_tanpa_akses) ? 0 : persentaseLayanan.real_tanpa_akses}%</TableCell>
								</TableRow>
								<TableRow className='bg-black/10 dark:bg-white/10'>
									<TableCell className='text-lg tfooter-text border border-t-0' colSpan={7}>Capaian SPM</TableCell>
									<TableCell className='tfooter-text border-r border-b' colSpan={4}>{isNaN(persentaseLayanan.capaian) ? 0 : persentaseLayanan.capaian}%</TableCell>
								</TableRow>
							</TableFooter>
						}
					</Table>
					<div className='h-1' ref={ref}>
						{isFetchingNextPage &&
							<div className="flex justify-center items-center p-4">
								<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
							</div>
						}
					</div>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			</div>
		</>
	);
};

export default TDataCapaianALSample;
