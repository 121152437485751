import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import EditFormDataAirMinumCurah from '@/components/Forms/AirMinum/EditFormDataAirMinumCurah';
import FormDataAirMinumCurah from '@/components/Forms/AirMinum/FormDataAirMinumCurah';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import {
	useDeleteDataCapaianKumulatifAMCurahProv,
	useGetDataCapaianKumulatifAMCurahProv,
} from '@/hooks/airMinum/useDataCapaianAM';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import {
	FiArrowLeft,
	FiDownload,
	FiEdit,
	FiPlusCircle,
	FiTrash2,
} from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import { utils, writeFile } from 'xlsx-js-style';

type TableDataAirMinumCurahProps = {
	tabId: number;
	handleTabChange: (e: number) => void;
};

const TableDataAirMinumCurah = ({
	tabId,
	handleTabChange,
}: TableDataAirMinumCurahProps) => {
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_provinsi_dux = useSelector(
		(state: RootState) => state.location.kd_provinsi_dux,
	);
	const nama_provinsi_dux = useSelector(
		(state: RootState) => state.location.nama_provinsi,
	);
	const nama_provinsi = useSelector(
		(state: RootState) => state.auth.wilayah.nama_provinsi,
	);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);

	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const nama_provinsi_to_use =
		privilege === 10 ? nama_provinsi_dux : nama_provinsi;

	const [total, setTotal] = useState({
		target: 0,
		realisasi: 0,
	});
	const [capaian, setCapaian] = useState(0);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [allDataAMCurah, setAllDataAMCurah] = useState<any>([]);
	const [showForm, setShowForm] = useState('Lihat Data');
	const [deleteID, setDeleteID] = useState('');
	const queryClient = useQueryClient();

	const { getDataCapaianKumulatifAMCurahProv, isLoading: isLoadingAMCurah } =
		useGetDataCapaianKumulatifAMCurahProv(
			Number(kd_provinsi_to_use),
			Number(tahun),
		);
	const { deleteCapaianAMProv, isPending: pendingDeleteCapaianAMProv } =
		useDeleteDataCapaianKumulatifAMCurahProv();

	const cardRef = useRef(null);

	let rowCounter = 0;

	const handleDeleteClick = () => {
		setIsDialogOpen(true);
	};

	const handleConfirmDelete = async (id: string) => {
		try {
			await deleteCapaianAMProv(id);
			queryClient.invalidateQueries({
				queryKey: ['eksis-air-minum-curah-prov'],
			});
			toast.success('Berhasil menghapus Capaian');
			setIsDialogOpen(false);
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus Capaian');
		}
	};

	const handleDownload = () => {
		const transformedData = allDataAMCurah.map((item: any, index: any) => [
			{
				v: index + 1,
				t: 'n',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.nama_kabkota,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.tgt_curah,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: item.real_curah,
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		]);

		const header = [
			{
				v: 'No',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Kabupaten/Kota',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Target',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Realisasi',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const header1 = [
			{
				v: 'Kebutuhan Pelayanan Air Minum Curah',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Air Minum Curah yang Telah Tersedia',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const header2 = [
			{
				v: '(Liter/Detik)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: '(Liter/Detik)',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		const totalRow = [
			{
				v: total.target,
				t: 'n',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
			{
				v: total.realisasi,
				t: 'n',
				s: {
					font: { bold: true },
					alignment: { horizontal: 'center', vertical: 'center' },
				},
			},
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'DATA AWAL',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A1' },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'SPM PERKERJAAN UMUM',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A2' },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: `PEMENUHAN KEBUTUHAN AIR MINUM CURAH LINTAS KABUPATEN/KOTA TAHUN ${tahun}`,
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A3' },
		);

		utils.sheet_add_aoa(ws, [header], { origin: 'A4' });
		utils.sheet_add_aoa(ws, [header1], { origin: 'C5' });
		utils.sheet_add_aoa(ws, [header2], { origin: 'C6' });
		utils.sheet_add_aoa(ws, transformedData, { origin: 'A7' });

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Total',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `A${transformedData.length + 7}` },
		);
		utils.sheet_add_aoa(ws, [totalRow], {
			origin: `C${transformedData.length + 7}`,
		});

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'Capaian SPM',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `A${transformedData.length + 8}` },
		);
		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: capaian,
						t: 'n',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: `D${transformedData.length + 8}` },
		);

		ws['!cols'] = [{ wpx: 50 }, { wpx: 166 }, { wpx: 241 }, { wpx: 227 }];

		ws['!merges'] = [
			// COLSPANS
			utils.decode_range('A1:D1'),
			utils.decode_range('A2:D2'),
			utils.decode_range('A3:D3'),
			utils.decode_range(
				`A${transformedData.length + 7}:B${transformedData.length + 7}`,
			),
			utils.decode_range(
				`A${transformedData.length + 8}:C${transformedData.length + 8}`,
			),

			// ROWSPANS
			utils.decode_range('A4:A6'),
			utils.decode_range('B4:B6'),
		];
		utils.book_append_sheet(wb, ws, 'Kumulatif Air Minum Provinsi');
		writeFile(
			wb,
			`Data Capaian Kumulatif Air Minum ${nama_provinsi_to_use}  - ${tahun}.xlsx`,
		);
	};

	useEffect(() => {
		if (!isLoadingAMCurah) {
			const totalTarget = getDataCapaianKumulatifAMCurahProv.data.reduce(
				(acc: any, curr: any) => acc + curr['tgt_curah'],
				0,
			);
			const totalRealisasi = getDataCapaianKumulatifAMCurahProv.data.reduce(
				(acc: any, curr: any) => acc + curr['real_curah'],
				0,
			);

			const capaian = (totalRealisasi / totalTarget) * 100;

			setTotal((prevState) => ({
				...prevState,
				target: totalTarget,
				realisasi: totalRealisasi,
			}));
			setCapaian(capaian);
		}
	}, [isLoadingAMCurah, getDataCapaianKumulatifAMCurahProv, tahun, menuId]);

	useEffect(() => {
		if (!isLoadingAMCurah) {
			const dataAMCurah = getDataCapaianKumulatifAMCurahProv.data;
			setAllDataAMCurah(dataAMCurah);
		}
	}, [isLoadingAMCurah]);

	if (isLoadingAMCurah) return <CompLoader />;

	return (
		<>
			<div className="flex justify-end pb-4">
				{(privilege === 4 || privilege === 8 || privilege === 10) &&
					allDataAMCurah.length !== 0 && (
						<Button
							className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
							onClick={() => handleDownload()}
						>
							<FiDownload />
							Download Data
						</Button>
					)}
				{privilege !== 4 && privilege !== 10 && privilege !== 8 && (
					<div className="flex gap-4">
						{showForm !== 'Lihat Data' ? (
							<Button
								className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
								onClick={() => setShowForm('Lihat Data')}
							>
								<FiArrowLeft />
								Kembali
							</Button>
						) : (
							<>
								{allDataAMCurah.length !== 0 && (
									<Button
										className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
										onClick={() => handleDownload()}
									>
										<FiDownload />
										Download Data
									</Button>
								)}
								<FormDataAirMinumCurah />
							</>
						)}
					</div>
				)}
			</div>
			{showForm === 'Lihat Data' && (
				<div
					ref={cardRef}
					className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
				>
					<div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 dark:bg-graydark sm:grid-cols-3">
						<div
							className={`py-4 text-center ${
								tabId === 1 ? 'bg-[#fab23d]' : 'bg-[#364878] dark:bg-graydark'
							} flex cursor-pointer items-center justify-center rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark`}
							onClick={() => handleTabChange(1)}
						>
							<p>Data Air Minum Curah</p>
						</div>
						<div
							className={`py-4 text-center ${
								tabId === 2 ? 'bg-[#fab23d]' : 'bg-[#364878] dark:bg-graydark'
							} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark`}
							onClick={() => handleTabChange(2)}
						>
							Rekapitulasi Capaian SPM <br />
							Kabupaten / Kota
						</div>
						<div
							className={`py-4 text-center ${
								tabId === 3 ? 'bg-[#fab23d]' : 'bg-[#364878] dark:bg-graydark'
							} flex cursor-pointer items-center justify-center rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark`}
							onClick={() => handleTabChange(3)}
						>
							<p>Bantuan Keuangan</p>
						</div>
					</div>
					<ScrollArea className="relative h-full p-4">
						<JudulHeader
							cardRef={cardRef}
							isLoading={isLoadingAMCurah}
							minusWidth={32}
						>
							DATA AWAL <br />
							SPM PERKERJAAN UMUM <br />
							PEMENUHAN KEBUTUHAN AIR MINUM CURAH LINTAS KABUPATEN/KOTA TAHUN{' '}
							{tahun}
						</JudulHeader>
						<Table className="w-full table-auto">
							<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
								<TableRow>
									<TableHead
										rowSpan={3}
										className="thead-text border-l text-center"
									>
										No
									</TableHead>
									<TableHead rowSpan={3} className="thead-text text-center">
										Kabupaten / Kota
									</TableHead>
									<TableHead className="thead-text border-b-0 text-center">
										Target
									</TableHead>
									<TableHead className="thead-text border-b-0 text-center">
										Realisasi
									</TableHead>
									<TableHead rowSpan={3} className="thead-text text-center">
										Aksi
									</TableHead>
								</TableRow>
								<TableRow>
									<TableHead className="thead-text border-b-0 text-center">
										Kebutuhan Pelayanan Air Minum Curah
									</TableHead>
									<TableHead className="thead-text border-b-0 text-center">
										Air Minum Curah yang Telah Tersedia
									</TableHead>
								</TableRow>
								<TableRow>
									<TableHead className="thead-text text-center">
										(Liter/Detik)
									</TableHead>
									<TableHead className="thead-text text-center">
										(Liter/Detik)
									</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{getDataCapaianKumulatifAMCurahProv.data.map(
									(item: any, key: any) => {
										rowCounter++;
										return (
											<TableRow
												key={key}
												className={
													rowCounter % 2 === 0
														? 'bg-gray-2 dark:bg-meta-4'
														: 'bg-white dark:bg-boxdark'
												}
											>
												<TableCell className="tbody-text border-b border-l border-r">
													{rowCounter}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.nama_kabkota}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.tgt_curah}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.real_curah}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													<div className="flex flex-row items-center justify-center gap-4">
														<EditFormDataAirMinumCurah data={item} />
														<Button
															className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600"
															onClick={() => {
																handleDeleteClick();
																setDeleteID(item.id_eksis_am_prov);
															}}
														>
															<FiTrash2 />
														</Button>
													</div>
												</TableCell>
											</TableRow>
										);
									},
								)}
							</TableBody>
							<TableFooter>
								<TableRow className="bg-black/10 dark:bg-white/10">
									<TableCell
										colSpan={2}
										className="tfooter-text border-b border-l border-r"
									>
										Total
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.target}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.realisasi}
									</TableCell>
								</TableRow>
								<TableRow className="bg-black/10 dark:bg-white/10">
									<TableCell
										colSpan={3}
										className="tfooter-text border-b border-l border-r"
									>
										Capaian SPM
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{isNaN(capaian) ? 0 : capaian.toFixed(2)}%
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
						<ScrollBar orientation="horizontal" />
					</ScrollArea>
				</div>
			)}
			<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Konfirmasi Penghapusan</DialogTitle>
						<DialogDescription>
							Apakah Anda yakin ingin menghapus data air minum curah ini ini?
						</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<div className="mt-5 flex w-full justify-between">
							<Button
								disabled={pendingDeleteCapaianAMProv}
								size={'sm'}
								className="bg-red-500 text-white"
								onClick={() => setIsDialogOpen(false)}
							>
								Batal
							</Button>
							<Button
								disabled={pendingDeleteCapaianAMProv}
								size={'sm'}
								className="ml-auto bg-green-500 text-white hover:bg-green-600"
								onClick={() => handleConfirmDelete(deleteID)}
							>
								Hapus Flie
							</Button>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default TableDataAirMinumCurah;
