import React, { useState } from 'react';
import { Button } from '@/components/ui/Button';
import { FiTrash2 } from 'react-icons/fi';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
} from '@/components/ui/dialog';
import { useDeleteFile } from '@/hooks/useDeleteFile';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';

interface DeleteFileButtonProps {
	rowData: any;
	onDelete: (data: any) => void;
}

export const DeleteFileButton: React.FC<DeleteFileButtonProps> = ({
	rowData,
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	// console.log(rowData);
	// console.log(rowData.id_upload_file);

	//Delete
	const { deleteFile, isPending } = useDeleteFile();
	const QueryClient = useQueryClient();

	const handleDeleteClick = () => {
		setIsDialogOpen(true);
	};

	const handleConfirmDelete = async () => {
		try {
			await deleteFile(rowData.id_upload_file);
			QueryClient.refetchQueries({ queryKey: ['get-file'] });
			toast.success('Berhasil menghapus File');
			setIsDialogOpen(false);
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus File');
		}
	};

	const handleCancelDelete = () => {
		setIsDialogOpen(false);
	};

	return (
		<>
			<Button
				className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600"
				onClick={handleDeleteClick}
			>
				<FiTrash2 />
			</Button>
			<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Konfirmasi Penghapusan</DialogTitle>
						<DialogDescription>
							Apakah Anda yakin ingin menghapus file ini?
						</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<div className="mt-5 flex w-full justify-between">
							<Button
								disabled={isPending}
								size={'sm'}
								className="bg-red-500 text-white"
								onClick={handleCancelDelete}
							>
								Batal
							</Button>
							<Button
								disabled={isPending}
								size={'sm'}
								className="ml-auto bg-green-500 text-white hover:bg-green-600"
								onClick={handleConfirmDelete}
							>
								Hapus Flie
							</Button>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
};
