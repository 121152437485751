import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import { DeleteFileButton } from '@/components/ui/DeleteFileButton';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useGetFile } from '@/hooks/perumahan/useRumahBencana';
import { usePrivilegeRumahBencana } from '@/hooks/privilege/usePrivilege';
import { API } from '@/libs/API';
import { useEffect, useRef, useState } from 'react';
import { FiDownload, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';

interface TBerkasRawanBencanaProps {
	jenis_up_id: number;
}

const TBerkasRawanBencana: React.FC<TBerkasRawanBencanaProps> = ({
	jenis_up_id,
}) => {
	const cardRef = useRef(null);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const user = useSelector((state: RootState) => state.auth);
	const { wilayah } = useSelector((state: RootState) => state.auth);
	const { isPrevilegeViewRumahBencana, kd_kabkota_to_use, kd_provinsi_to_use } =
		usePrivilegeRumahBencana();

	const { data } = useGetFile(
		kd_provinsi_to_use,
		kd_kabkota_to_use,
		Number(tahun),
		menuId,
		jenis_up_id,
	);

	const [adaBerkas, setAdaBerkas] = useState(false);
	let rowCounter = 0;

	const pages = data?.pages || [];

	const findBerkas = () => {
		pages.map((page) => {
			if (page.data.length > 0) {
				setAdaBerkas(true);
			}
		});
	};

	useEffect(() => {
		findBerkas();
	}, [data]);

	const handleDownload = async (filename: string, jenis_up_id: string) => {
		try {
			const { data } = await API.get(
				`/download-file/${filename}/?kd_provinsi=${kd_provinsi_to_use}&kd_kabkota=${kd_kabkota_to_use}&menu_id=${menuId}&tahun_upload=${Number(tahun)}&jenis_up_id=${jenis_up_id}`,
				{
					responseType: 'blob',
				},
			);
			const blob = new Blob([data], { type: data.type });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error('Error downloading the file', error);
		}
	};

	const handleDelete = async (rowData: any) => {};

	return (
		<>
			<div className="my-5 flex w-full items-center justify-center">
				<Table className="w-[100%] px-10">
					<TableHeader>
						<TableRow>
							<TableHead className="border-l text-center">No</TableHead>
							<TableHead className="text-center">Nama File</TableHead>
							<TableHead className="text-center">Tanggal Upload</TableHead>
							<TableHead className="text-center">Aksi(Download)</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{pages.map((page) =>
							page.data.length === 0 ? (
								<TableRow key="no-data">
									<TableCell colSpan={4} className="border-b border-l border-r">
										Belum Ada Dokumen Yang Diupload {wilayah.nama_provinsi}
									</TableCell>
								</TableRow>
							) : (
								page.data.map((file: any, index: number) => {
									const fileNameParts = file.nama_file
										.split('-')
										.slice(3)
										.join('-');
									const uploadDate = new Date(file.created_at);
									const formattedDate = `${uploadDate.getDate()} ${uploadDate.toLocaleString('id-ID', { month: 'long' })} ${uploadDate.getFullYear()}`;
									return (
										<TableRow
											key={index}
											className={
												rowCounter % 2 === 0
													? 'bg-gray-2 dark:bg-meta-4'
													: 'bg-white dark:bg-boxdark'
											}
										>
											<TableCell className="border-b border-l border-r">
												{++rowCounter}
											</TableCell>
											<TableCell className="border-b border-r">
												{fileNameParts}
											</TableCell>
											<TableCell className="border-b border-r">
												{formattedDate}
											</TableCell>
											<TableCell className="mx-4 gap-5 border-b border-r">
												<div className="flex justify-center gap-4">
													<Button
														onClick={() =>
															handleDownload(file.nama_file, file.jenis_up_id)
														}
														className="inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600"
													>
														<FiDownload color="white" />
													</Button>

													<DeleteFileButton
														rowData={file}
														onDelete={handleDelete}
													/>
												</div>
											</TableCell>
										</TableRow>
									);
								})
							),
						)}
					</TableBody>
				</Table>
			</div>
		</>
		// <div
		// 	ref={cardRef}
		// 	className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
		// >
		// 	<ScrollArea>

		// 	</ScrollArea>
		// </div>
	);
};

export default TBerkasRawanBencana;
