import * as React from 'react';
import { Check, ChevronDown } from 'lucide-react';
import { cn } from '@/libs/utils';
import { Button } from '@/components/ui/Button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from '@/components/ui/command';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { setKd_kabkota_dux, setNama_kabkota } from '@/app/lokasi/lokasiSlice';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi'; // Import the hook
import { kabkota } from '@/types/lokasi';

export function SelectKabGlobal() {
	const [open, setOpen] = React.useState(false);
	const userKdProvinsi = useSelector(
		(state: RootState) => state.auth.kd_provinsi,
	); //Ambil kd_provinsi dari user
	const dispatch = useDispatch();
	// const [value, setValue] = React.useState('');
	const value = useSelector((state: RootState) => state.location.nama_kabkota);
	const kd_provinsi_dux = useSelector(
		(state: RootState) => state.location.kd_provinsi_dux,
	);
	const kdProvToUse = userKdProvinsi || kd_provinsi_dux;

	const { getKabKota, isLoading } = useGetKabKota(kdProvToUse); // Call the hook

	const handleSelect = (value: string) => {
		const selectedKabkota = getKabKota.find(
			(kabkota: kabkota) => kabkota.nama_kabkota === value,
		);
		if (selectedKabkota) {
			dispatch(setNama_kabkota(selectedKabkota.nama_kabkota));
			dispatch(setKd_kabkota_dux(selectedKabkota.kd_kabkota));
		}
	};

	if (isLoading) {
		return <p>Loading...</p>;
	}

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					role="combobox"
					aria-expanded={open}
					className="w-full justify-between truncate border-slate-500 dark:bg-boxdark-2 dark:hover:bg-boxdark"
				>
					<span className="truncate">
						{value
							? (() => {
									const foundKabKota = getKabKota.find(
										(kabkota: kabkota) => kabkota.nama_kabkota === value,
									);
									if (!foundKabKota) {
										dispatch(setKd_kabkota_dux(0));
										return 'Pilih Kabupaten...';
									}
									return foundKabKota.nama_kabkota || 'Pilih Kabupaten...';
								})()
							: 'Pilih Kabupaten...'}
					</span>
					<ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				align="start"
				className="z-999 w-[200px] border border-slate-500 p-0"
			>
				<Command>
					<CommandInput placeholder="Cari kabkota..." />
					<CommandList>
						<CommandEmpty>No kabkota found.</CommandEmpty>
						<CommandGroup>
							{getKabKota.map((kabkota: kabkota) => (
								<CommandItem
									key={kabkota.kd_kabkota}
									value={kabkota.nama_kabkota.toString()}
									onSelect={(currentValue) => {
										handleSelect(currentValue); // Call handleSelect here
										setOpen(false);
									}}
								>
									<Check
										className={cn(
											'mr-2 h-4 w-4',
											value === kabkota.kd_kabkota.toString()
												? 'opacity-100'
												: 'opacity-0',
										)}
									/>
									{kabkota.nama_kabkota}
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
