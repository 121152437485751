import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import Logo from '../../images/logo/logo-sicalmers.svg';
import IconList from '../../images/icon/icon-list.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import WilayahSidebar from './WilayahSidebar';
import { User } from 'lucide-react';
import { IoLogoWhatsapp } from 'react-icons/io5';
import { IoFolderOutline } from 'react-icons/io5';
import { GoLightBulb } from 'react-icons/go';
import { SiXdadevelopers } from 'react-icons/si';

interface SidebarProps {
	sidebarOpen: boolean;
	setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
	const bidang = useSelector((state: RootState) => state.bidang.value);
	const menuId = useSelector((state: RootState) => state.auth.menu_id);
	const privilege = useSelector(
		(state: RootState) => state.auth.privilege.id_privilege,
	);

	const location = useLocation();
	const { pathname } = location;

	const trigger = useRef<any>(null);
	const sidebar = useRef<any>(null);

	const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
	const [sidebarExpanded, setSidebarExpanded] = useState(
		storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true',
	);

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }: MouseEvent) => {
			// console.log(target);
			// If the screen size is larger than 991px, do not close the sidebar
			if (window.innerWidth >= 991) {
				return;
			}
			if (!sidebar.current || !trigger.current) return;
			if (
				!sidebarOpen ||
				sidebar.current.contains(target) ||
				trigger.current.contains(target)
			)
				return;
			setSidebarOpen(false);
		};
		document.addEventListener('click', clickHandler);
		return () => document.removeEventListener('click', clickHandler);
	});

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }: KeyboardEvent) => {
			if (!sidebarOpen || keyCode !== 27) return;
			setSidebarOpen(false);
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	});

	useEffect(() => {
		localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
		if (sidebarExpanded) {
			document.querySelector('body')?.classList.add('sidebar-expanded');
		} else {
			document.querySelector('body')?.classList.remove('sidebar-expanded');
		}
	}, [sidebarExpanded]);

	// // Inside your component
	// useEffect(() => {
	// 	// This will run whenever `bidang` changes
	// 	window.location.href = `/data-dasar/${bidang}`;
	// }, [bidang]);

	const navigate = useNavigate();
	const bidangLocation = useLocation();

	useEffect(() => {
		if (
			bidangLocation.pathname.startsWith('/data-dasar/') ||
			bidangLocation.pathname.startsWith('/pengumpulan-data/') ||
			bidangLocation.pathname.startsWith('/pemenuhan-spm/pelaksanaan') ||
			bidangLocation.pathname.startsWith('/pemenuhan-spm/rencana') ||
			bidangLocation.pathname.startsWith('/data-capaian')
		) {
			let segments = bidangLocation.pathname.split('/');
			segments.pop(); // remove the last segment
			segments.push(bidang); // append the new bidang value
			navigate(segments.join('/')); // navigate to the new URL
		}
	}, [bidang, bidangLocation.pathname]);

	return (
		<aside
			id="sidebar"
			ref={sidebar}
			className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-[#364878] duration-500 ease-in-out dark:bg-boxdark ${
				sidebarOpen
					? 'translate-x-0 lg:translate-x-0'
					: '-translate-x-full lg:-translate-x-full'
			} `}
		>
			{/* <div> */}
			{/* <!-- SIDEBAR HEADER --> */}
			<div className="flex items-center justify-between gap-2 px-6 py-6">
				<NavLink to="/">
					<img src={Logo} alt="Logo" />
				</NavLink>

				<button
					ref={trigger}
					onClick={() => setSidebarOpen(!sidebarOpen)}
					aria-controls="sidebar"
					aria-expanded={sidebarOpen}
					className="block lg:hidden"
				>
					<svg
						className="fill-current text-white"
						width="20"
						height="18"
						viewBox="0 0 20 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
							fill=""
						/>
					</svg>
				</button>
			</div>
			{/* <!-- SIDEBAR HEADER --> */}

			<div className="no-scrollbar flex flex-1 flex-col overflow-y-auto duration-300 ease-linear">
				{/* <!-- Sidebar Menu --> */}
				<nav className="p-6">
					<WilayahSidebar />
					{/* <!-- Menu Group --> */}
					<div>
						<h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
							MENU UTAMA
						</h3>

						<ul className="mb-6 flex flex-col gap-1.5">
							{/* <!-- Menu Item Dashboard --> */}
							<li>
								<NavLink
									to="/"
									className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
										pathname === '/' || pathname.includes('beranda')
											? 'bg-[#fab23d] dark:bg-meta-4'
											: ''
									}`}
								>
									<svg
										className="fill-current"
										width="18"
										height="18"
										viewBox="0 0 18 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6.10322 0.956299H2.53135C1.5751 0.956299 0.787598 1.7438 0.787598 2.70005V6.27192C0.787598 7.22817 1.5751 8.01567 2.53135 8.01567H6.10322C7.05947 8.01567 7.84697 7.22817 7.84697 6.27192V2.72817C7.8751 1.7438 7.0876 0.956299 6.10322 0.956299ZM6.60947 6.30005C6.60947 6.5813 6.38447 6.8063 6.10322 6.8063H2.53135C2.2501 6.8063 2.0251 6.5813 2.0251 6.30005V2.72817C2.0251 2.44692 2.2501 2.22192 2.53135 2.22192H6.10322C6.38447 2.22192 6.60947 2.44692 6.60947 2.72817V6.30005Z"
											fill=""
										/>
										<path
											d="M15.4689 0.956299H11.8971C10.9408 0.956299 10.1533 1.7438 10.1533 2.70005V6.27192C10.1533 7.22817 10.9408 8.01567 11.8971 8.01567H15.4689C16.4252 8.01567 17.2127 7.22817 17.2127 6.27192V2.72817C17.2127 1.7438 16.4252 0.956299 15.4689 0.956299ZM15.9752 6.30005C15.9752 6.5813 15.7502 6.8063 15.4689 6.8063H11.8971C11.6158 6.8063 11.3908 6.5813 11.3908 6.30005V2.72817C11.3908 2.44692 11.6158 2.22192 11.8971 2.22192H15.4689C15.7502 2.22192 15.9752 2.44692 15.9752 2.72817V6.30005Z"
											fill=""
										/>
										<path
											d="M6.10322 9.92822H2.53135C1.5751 9.92822 0.787598 10.7157 0.787598 11.672V15.2438C0.787598 16.2001 1.5751 16.9876 2.53135 16.9876H6.10322C7.05947 16.9876 7.84697 16.2001 7.84697 15.2438V11.7001C7.8751 10.7157 7.0876 9.92822 6.10322 9.92822ZM6.60947 15.272C6.60947 15.5532 6.38447 15.7782 6.10322 15.7782H2.53135C2.2501 15.7782 2.0251 15.5532 2.0251 15.272V11.7001C2.0251 11.4188 2.2501 11.1938 2.53135 11.1938H6.10322C6.38447 11.1938 6.60947 11.4188 6.60947 11.7001V15.272Z"
											fill=""
										/>
										<path
											d="M15.4689 9.92822H11.8971C10.9408 9.92822 10.1533 10.7157 10.1533 11.672V15.2438C10.1533 16.2001 10.9408 16.9876 11.8971 16.9876H15.4689C16.4252 16.9876 17.2127 16.2001 17.2127 15.2438V11.7001C17.2127 10.7157 16.4252 9.92822 15.4689 9.92822ZM15.9752 15.272C15.9752 15.5532 15.7502 15.7782 15.4689 15.7782H11.8971C11.6158 15.7782 11.3908 15.5532 11.3908 15.272V11.7001C11.3908 11.4188 11.6158 11.1938 11.8971 11.1938H15.4689C15.7502 11.1938 15.9752 11.4188 15.9752 11.7001V15.272Z"
											fill=""
										/>
									</svg>
									Beranda
								</NavLink>
							</li>
							{/* <!-- Menu Item Dashboard --> */}
							{/* menu Data Dasar  */}
							{/* <li>
								<NavLink
									to={`/data-dasar/${bidang}`}
									// to="#"
									// to="/data-dasar"
									className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname === '/data-dasar' ||
										pathname.includes('data-dasar')
										? 'bg-[#fab23d] dark:bg-meta-4'
										: ''
										}`}
								>
									<img src={IconList} alt="icon" />
									Data Dasar
								</NavLink>
							</li> */}
							{/* End Menu Data Dasar */}
							{/* menu Pengumpulan Data  */}
							<li>
								<NavLink
									to={`/pengumpulan-data/${bidang}`}
									// to="/data-dasar"
									className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
										pathname === '/pengumpulan-data' ||
										pathname.includes('pengumpulan-data')
											? 'bg-[#fab23d] dark:bg-meta-4'
											: ''
									}`}
								>
									<img src={IconList} alt="icon" />
									Pengumpulan Data
								</NavLink>
							</li>
							{/* End Menu DPengumpulan Data*/}
							{/* <!-- Menu Item Pemenuhan SPM --> */}
							<SidebarLinkGroup
								activeCondition={
									pathname === '/pemenuhan-spm' ||
									pathname.includes('pemenuhan-spm')
								}
							>
								{(handleClick, open) => {
									return (
										<React.Fragment>
											<NavLink
												to="#"
												className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
													(pathname === '/pemenuhan-spm' ||
														pathname.includes('pemenuhan-spm')) &&
													'bg-[#fab23d] dark:bg-meta-4'
												}`}
												onClick={(e) => {
													e.preventDefault();
													sidebarExpanded
														? handleClick()
														: setSidebarExpanded(true);
												}}
											>
												<img src={IconList} alt="icon" />
												Pemenuhan SPM
												<svg
													className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
														open && 'rotate-180'
													}`}
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
														fill=""
													/>
												</svg>
											</NavLink>
											{/* <!-- Dropdown Menu Start --> */}
											<div
												className={`translate transform overflow-hidden ${
													!open && 'hidden'
												}`}
											>
												<ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
													<li>
														<NavLink
															to={`/pemenuhan-spm/rencana/${bidang}`}
															className={({ isActive }) =>
																'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
																(isActive && '!text-white')
															}
														>
															Rencana Pemenuhan
														</NavLink>
													</li>
													<li>
														<NavLink
															to={`/pemenuhan-spm/pelaksanaan/${bidang}`}
															className={({ isActive }) =>
																'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
																(isActive && '!text-white')
															}
														>
															Pelaksanaan Pemenuhan
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <!-- Dropdown Menu End --> */}
										</React.Fragment>
									);
								}}
							</SidebarLinkGroup>
							{/* <!-- End of Item Pemenuhan SPM --> */}
							{/* <!-- Menu Item Data Capaian --> */}
							<SidebarLinkGroup
								activeCondition={
									pathname === '/data-capaian' ||
									pathname.includes('data-capaian')
								}
							>
								{(handleClick, open) => {
									return (
										<React.Fragment>
											<NavLink
												to="#"
												className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
													(pathname === '/data-capaian' ||
														pathname.includes('data-capaian')) &&
													'bg-[#fab23d] dark:bg-meta-4'
												}`}
												onClick={(e) => {
													e.preventDefault();
													sidebarExpanded
														? handleClick()
														: setSidebarExpanded(true);
												}}
											>
												<img src={IconList} alt="icon" />
												Data Capaian
												<svg
													className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
														open && 'rotate-180'
													}`}
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
														fill=""
													/>
												</svg>
											</NavLink>
											{/* <!-- Dropdown Menu Start --> */}
											<div
												className={`translate transform overflow-hidden ${
													!open && 'hidden'
												}`}
											>
												<ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
													<li>
														<NavLink
															to={`/data-capaian/tahunan/${bidang}`}
															className={({ isActive }) =>
																'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
																(isActive && '!text-white')
															}
														>
															Capaian Tahunan
														</NavLink>
													</li>
													<li>
														<NavLink
															to={`/data-capaian/kumulatif/${bidang}`}
															className={({ isActive }) =>
																'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
																(isActive && '!text-white')
															}
														>
															Capaian Kumulatif
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <!-- Dropdown Menu End --> */}
										</React.Fragment>
									);
								}}
							</SidebarLinkGroup>
						</ul>
					</div>
					{/* Summary Group */}
					<div>
						<h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
							LAPORAN
						</h3>
						<ul className="mb-6 flex flex-col gap-1.5">
							{/* {(privilege === 10 && menuId === 333) ||
							(privilege === 10 && menuId === 9) ? ( */}
							<li>
								<NavLink
									to="/summary"
									className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
										pathname.includes('summary') &&
										'bg-[#fab23d] dark:bg-meta-4'
									}`}
								>
									<IoFolderOutline size={18} />
									Summary
								</NavLink>
							</li>
						</ul>
					</div>
					{/* End of Summary Group */}
					{/* Other Group */}
					<div>
						<h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
							LAINNYA
						</h3>
						<ul className="mb-6 flex flex-col gap-1.5">
							{/* {(privilege === 10 && menuId === 333) ||
							(privilege === 10 && menuId === 9) ? ( */}
							<li>
								<NavLink
									to="/informasi"
									className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
										pathname.includes('informasi') &&
										'bg-[#fab23d] dark:bg-meta-4'
									}`}
								>
									<GoLightBulb size={18} />
									Infomasi
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/kontak"
									className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
										pathname.includes('kontak') && 'bg-[#fab23d] dark:bg-meta-4'
									}`}
								>
									<IoLogoWhatsapp size={18} />
									Kontak
								</NavLink>
							</li>
						</ul>
					</div>
					{/* end of Other Group */}
					{/* PANEL ADMIN Group */}
					{privilege === 10 && menuId === 333 && (
						<div>
							<h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
								PANEL ADMIN
							</h3>
							<ul className="mb-6 flex flex-col gap-1.5">
								<li>
									<NavLink
										to="/manage-users"
										className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
											pathname.includes('manage-users') &&
											'bg-[#fab23d] dark:bg-meta-4'
										}`}
									>
										<User size={18} />
										Manage Users
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/development"
										className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
											pathname.includes('development') &&
											'bg-[#fab23d] dark:bg-meta-4'
										}`}
									>
										<SiXdadevelopers />
										Development
									</NavLink>
								</li>
							</ul>
						</div>
					)}
					{/* end of PANEL ADMIN Group */}
				</nav>
				{/* <!-- Sidebar Menu --> */}
			</div>
			{/* </div> */}
		</aside>
	);
};

export default Sidebar;
