import DetailRencana from '@/components/Forms/RencanaPelaksanaan/DetailRencana';
import { Button } from '@/components/ui/Button';
import DefaultLayout from '@/layout/DefaultLayout';
import { useNavigate } from 'react-router-dom';

const DetailRencanaPelaksanaanAM = () => {
	const navigate = useNavigate()
	return (
		<DefaultLayout>
			<div className="mb-5 flex">
				<div className="flex flex-grow justify-start text-3xl font-bold text-[#364878] dark:text-white">
					<span>Detail Rencana Pelaksanaan Bidang Air Minum</span>
				</div>
				<div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
					<div className="flex w-full flex-grow justify-end text-lg font-bold md:w-auto">
						<Button
							className="inline-flex items-center justify-center rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
							onClick={() => navigate("/pemenuhan-spm/rencana/air-minum")}
						>
							Kembali
						</Button>
					</div>
				</div>
			</div>

			<div className="grid grid-cols-1 gap-9">
				<DetailRencana />
			</div>
		</DefaultLayout>
	);
};
export default DetailRencanaPelaksanaanAM;
