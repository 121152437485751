export default function Verifikasi({ verifikasi }: { verifikasi: any }) {
  return (
    <div className="flex flex-1 items-center justify-center">
      <p
        className={`inline-flex rounded-lg w-full items-center justify-center bg-opacity-10 py-1 px-3 h-full text-sm font-medium uppercase ${verifikasi === 1
          ? 'bg-success text-success'
          : 'bg-danger text-danger'
          }`}
      >
        {verifikasi === 1
          ? 'terverifikasi'
          : 'belum terverifikasi'}
      </p>
    </div>
  )
}