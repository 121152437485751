// resetPasswordSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { setAuthToken } from '../../libs/API';
import Cookies from 'js-cookie';

export const resetPasswordSlice = createSlice({
	name: 'password',
	initialState: {
		username: '',
		otp: '',
		password: '',
	},
	reducers: {
		setUsername: (state, action) => {
			state.username = action.payload;
		},
		setOtp: (state, action) => {
			state.otp = action.payload;
		},
		setPassword: (state, action) => {
			state.password = action.payload;
		},
	},
});

export const { setUsername, setOtp, setPassword } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
