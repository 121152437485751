import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/Button';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import { FiInfo } from 'react-icons/fi';

export const GInvIPLTProv = () => {
	let rowCounter = 0;

	const panduanData = [
		{
			id: 1,
			title: 'Nama IPLT',
			description: 'Diisi nama IPLT terbangun.',
		},
		{
			id: 2,
			title: 'Lokasi IPLT',
			description: 'Diisi lokasi IPLT terbangun.',
		},
		{
			id: 3,
			title: 'Kondisi IPLT',
			description: 'Diisi kondisi pelayanan IPLT terbangun.',
			dropdownOptions: ['Beroperasi', 'Tidak Beroperasi'],
		},
		{
			id: 4,
			title: 'Kabupaten/Kota',
			description:
				'Diisi nama Kabupaten/Kota yang termasuk wilayah pelayanan IPLT.',
		},
		{
			id: 5,
			title: 'Kecamatan',
			description: 'Diisi nama Kecamatan yang termasuk wilayah pelayanan IPLT.',
		},
		{
			id: 6,
			title: 'Kelurahan/Desa',
			description:
				'Diisi nama Kelurahan/Desa yang termasuk wilayah pelayanan IPLT.',
		},
		{
			id: 7,
			title: 'Kapasitas Tersedia',
			description:
				'Diisi besaran kapasitas pelayanan IPLT, dalam satuan m³/hari.',
		},
		{
			id: 8,
			title: 'Unit Rumah Tangga Tersedia',
			description:
				'Diisi jumlah unit rumah tangga yang dapat terlayani oleh IPLT.',
		},
		{
			id: 9,
			title: 'Kapasitas Termanfaatkan',
			description:
				'Diisi besaran kapasitas IPLT yang termanfaatkan, dalam satuan m³/hari.',
		},
		{
			id: 10,
			title: 'Unit Rumah Tangga Termanfaatkan',
			description:
				'Diisi jumlah unit rumah tangga yang telah terlayani oleh IPLT.',
		},
	];

	return (
		<>
			<Dialog>
				<DialogTrigger asChild>
					<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-green-600 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
						<FiInfo />
						Panduan
					</Button>
				</DialogTrigger>
				<DialogContent className="z-99999 max-h-[85%] max-w-[65%] overflow-auto rounded-lg">
					<DialogHeader>
						<DialogTitle>Panduan Pengisian Form Inventarisasi IPLT</DialogTitle>
						<DialogDescription>
							Silahkan ikuti panduan dibawah ini untuk melakukan pengisian form
							atau input data
						</DialogDescription>
					</DialogHeader>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead className="border-l text-center">No</TableHead>
								<TableHead className="text-center">Kolom</TableHead>
								<TableHead className="text-center">Keterangan</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{panduanData.map((data) => (
								<TableRow
									key={data.id}
									className={
										rowCounter % 2 === 0
											? 'bg-gray-2 dark:bg-meta-4'
											: 'bg-white dark:bg-boxdark'
									}
								>
									<TableCell className="text-center">{++rowCounter}</TableCell>
									<TableCell className="text-start">{data.title}</TableCell>
									<TableCell className="text-start">
										{data.description}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<DialogFooter>
						<div className="mt-5 flex w-full items-center justify-end">
							<DialogClose asChild>
								<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
									Tutup
								</Button>
							</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
};
