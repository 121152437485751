import React, { useState, ChangeEvent, FormEvent } from 'react';
import { LuUploadCloud } from 'react-icons/lu';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { RootState } from '@/app/store';

interface FormUploadPerumahanProps {
	onUploadSuccess: () => void;
}

const FormUploadPerumahan = (props: FormUploadPerumahanProps) => {
	const [file1, setFile1] = useState<File | null>(null);
	const [file2, setFile2] = useState<File | null>(null);
	const [file3, setFile3] = useState<File | null>(null);

	// const navigate = useNavigate();
	// const bidang = useSelector((state: RootState) => state.bidang.value);
	const [prov, setProv] = useState('');
	const [kab, setKab] = useState('');
	const handleFileChange =
		(setFile: React.Dispatch<React.SetStateAction<File | null>>) =>
			(event: ChangeEvent<HTMLInputElement>) => {
				if (event.target.files) {
					const file = event.target.files[0];
					const fileType = file.type;
					const acceptedFileTypes = [
						'application/pdf',
						'image/jpeg',
						'image/png',
					];
					if (acceptedFileTypes.includes(fileType)) {
						setFile(file);
					} else {
						event.target.value = ''; // Reset the input field
						alert(
							'Invalid file type. Only PDF, PNG, and JPG files are accepted.',
						);
					}
				} else {
					setFile(null);
				}
			};

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		// Handle file upload here
		console.log(file1, file2, file3);
		console.log(prov, kab);
		props.onUploadSuccess();
		// navigate('/');
	};
	return (
		<form onSubmit={handleSubmit} className='rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark flex flex-col flex-1 overflow-hidden'>
			{/* <!-- File upload --> */}
			<div className="border-b border-stroke px-6.5 py-4 dark:border-strokedark">
				<h3 className="text-2xl font-medium text-[#364878] dark:text-white">
					Upload File Data Dasar
				</h3>
			</div>
			<div className="flex flex-col  gap-5.5 p-6.5">
				<div>
					<label className="text-slate mb-3 block font-medium dark:text-white">
						Upload File Peta Rawan Bencana
					</label>
					<input
						type="file"
						accept=".pdf,.png,.jpg"
						onChange={handleFileChange(setFile1)}
						className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:px-5 file:py-3 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
					/>
				</div>
				<div>
					<label className="text-slate mb-3 block font-medium dark:text-white">
						Upload File Peta Rencana Tata Ruang Wilayah (RT / RW)
					</label>
					<input
						type="file"
						accept=".pdf,.png,.jpg"
						onChange={handleFileChange(setFile2)}
						className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:px-5 file:py-3 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
					/>
				</div>
				<div>
					<label className="text-slate mb-3 block font-medium dark:text-white">
						Upload File Dokumen Rencana Pembangunan dan Pengembangan
						Perumahan dan Kawasan Permukiman (RP3KP)
					</label>
					<input
						type="file"
						accept=".pdf,.png,.jpg"
						onChange={handleFileChange(setFile3)}
						className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:px-5 file:py-3 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
					/>
				</div>
				<div className="mt-4 flex items-center justify-center">
					<button
						className="inline-flex w-1/2  items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
						type="submit"
					>
						<LuUploadCloud />
						Upload
					</button>
				</div>
			</div>
		</form>
	);
};
export default FormUploadPerumahan;
