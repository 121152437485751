import { useState } from "react";
import TableCapaianSPALDRegional from "./TableCapaianSPALDRegional";
import TableDataKumulatifALKabKotaProv from "./TableDataKumulatifALKabKotaProv";
import TableDataBantuanKeuanganAL from "./TableBantuanKeuanganAL";

const TableDataCapaianKumulatifALProv = () => {
  const [tabId, setTabId] = useState(1)
  const handleTabChange = (e: number) => {
    setTabId(e)
  }

  return (
    <>
      {tabId === 1 && <TableCapaianSPALDRegional tabId={tabId} handleTabChange={handleTabChange} />}
      {tabId === 2 && <TableDataKumulatifALKabKotaProv tabId={tabId} handleTabChange={handleTabChange} />}
      {tabId === 3 && <TableDataBantuanKeuanganAL tabId={tabId} handleTabChange={handleTabChange} />}
    </>
  )
}

export default TableDataCapaianKumulatifALProv

