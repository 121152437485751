import { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Loader } from './common/Loader';
import PageTitle from './components/PageTitle';
import Login from './pages/Authentication/Login';
import SignUp from './pages/Authentication/SignUp';
import Calendar from './pages/Calendar';
import Chart from './pages/Chart';
import AutoLogin from './features/AutoLogin';
//============= BERANDA ================//
import Beranda from './pages/MenuUtama/Beranda';
import CapaianAmProv from './pages/MenuUtama/CapaianAmProv';
import CapaianAmKab from './pages/MenuUtama/CapaianAmKab';
import CapaianAlKab from './pages/MenuUtama/CapaianAlKab';

//============= DATA DASAR ================//
import DataDasarAirMinum from './pages/DataDasar/AirMinum';
import DataDasarAirLimbah from './pages/DataDasar/AirLimbah';
import DataDasarPerumahan from './pages/DataDasar/Perumahan';
import DataDasar from './pages/DataDasar';

//============= AIR MINUM ================//
import PengumpulanDataAirMinum from './pages/PengumpulanData/AirMinum/PengumpulanData';
import RencanaPemenuhanAirMinum from './pages/PemenuhanSPM/AirMinum/RencanaPemenuhan';
import PelaksanaanPemenuhanAirMinum from './pages/PemenuhanSPM/AirMinum/PelaksanaanPemenuhan';
import DataCapaianAMKumulatif from './pages/DataCapaian/AirMinum/Kumulatif';
import DataCapaianAMTahunan from './pages/DataCapaian/AirMinum/Tahunan';
import DetailRencanaPelaksanaanAM from './pages/PemenuhanSPM/AirMinum/DetailRencanaPelaksanaan';
import TambahRealisasiAM from './pages/PemenuhanSPM/AirMinum/TambahRealisasiAM';

//============= AIR LIMBAH ================//
import PengumpulanDataAirLimbah from './pages/PengumpulanData/AirLimbah/PengumpulanData';
import RencanaPemenuhanAirLimbah from './pages/PemenuhanSPM/AirLimbah/RencanaPemenuhan';
import PelaksanaanPemenuhanAirLimbah from './pages/PemenuhanSPM/AirLimbah/PelaksanaanPemenuhan';
import TambahRealisasiAL from './pages/PemenuhanSPM/AirLimbah/TambahRealisasiAL';
import DataCapaianKumulatifAL from './pages/DataCapaian/AirLimbah/Kumulatif';
import DataCapaianTahunanAL from './pages/DataCapaian/AirLimbah/Tahunan';

//============= RUMAH BENCANA ================//
import PengumpulanDataRumahBencana from './pages/PengumpulanData/RumahBencana/PengumpulanData';
import RencanaPemenuhanBencana from './pages/PemenuhanSPM/RumahBencana/RencanaPemenuhan';
import PelaksanaanPemenuhanBencana from './pages/PemenuhanSPM/RumahBencana/PelaksanaanPemenuhan';
import TambahRealisasiBencana from './pages/PemenuhanSPM/RumahBencana/TambahRealisasiBencana';
import DataCapaianKumulatifRumahBencana from './pages/DataCapaian/RumahBencana/Kumulatif';
import DataCapaianRumahBencanaTahunan from './pages/DataCapaian/RumahBencana/Tahunan';

//============= RUMAH RELOKASI ================//
import RencanaPemenuhanRelokasi from './pages/PemenuhanSPM/RumahRelokasi/RencanaPemenuhan';
import PelaksanaanPemenuhanRelokasi from './pages/PemenuhanSPM/RumahRelokasi/PelaksanaanPemenuhan';
import TambahRealisasiRelokasi from './pages/PemenuhanSPM/RumahRelokasi/TambahRealisasiRelokasi';
import DataCapaianKumulatifRumahRelokasi from './pages/DataCapaian/RumahRelokasi/Kumulatif';
import DataCapaianRumahRelokasiTahunan from './pages/DataCapaian/RumahRelokasi/Tahunan';
import PengumpulanDataRumahRelokasi from './pages/PengumpulanData/RumahRelokasi/PengumpulanData';

//============= FORMS ================//
import FormElements from './pages/Form/FormElements';
import FormLayout from './pages/Form/FormLayout';
import Tables from './pages/Tables';

//============= UI & COMPONENTS ================//
import Alerts from './pages/UiElements/Alerts';
import Buttons from './pages/UiElements/Buttons';

//============= AUTH ================//
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import ForgotPassword from './pages/Authentication/ForgotPassword';
import CekOtp from './pages/Authentication/CekOtp';
import ResetPassword from './pages/Authentication/ResetPassword';

import { API, setAuthToken } from '@/libs/API';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth, auth_error } from '@/app/auth/loginSlice';

import Download from './pages/Development';
import ManageUsers from './pages/ManageUsers';
import CapaianRBKab from './pages/MenuUtama/CapaianRBKab';
import CapaianRLKab from './pages/MenuUtama/CapaianRLKab';
import SummaryPage from './pages/Summary/SummaryPage';
import Logout from '@/features/Logout';
import Kontak from './pages/Kontak';
import Informasi from './pages/Informasi';
import Developments from './pages/Development';

function App() {
	const [loading, setLoading] = useState<boolean>(true);
	const [autoLoginDone, setAutoLoginDone] = useState<boolean>(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	async function AUTH_CHECK() {
		try {
			const token = localStorage.getItem('token') || '';
			setAuthToken(token);
			const data = await API.get('/check-auth');
			dispatch(setAuth(data.data.user));
			// console.log(data);
		} catch (error) {
			console.log(error);
			dispatch(auth_error());
			setLoading(false);
			localStorage.removeItem('token');
			navigate('/auth/login', { replace: true });
		} finally {
			setLoading(false);
		}
	}
	useEffect(() => {
		if (location.pathname.startsWith('/auth/login-url')) {
			setAutoLoginDone(false);
		} else if (!location.pathname.startsWith('/auth/forgot-password')) {
			AUTH_CHECK();
		}
	}, [location.pathname]);

	useEffect(() => {
		if (autoLoginDone) {
			AUTH_CHECK();
		}
	}, [autoLoginDone]);

	useEffect(() => {
		setTimeout(() => setLoading(false), 1000);
	}, []);

	return loading ? (
		<Loader />
	) : (
		<>
			<Routes>
				{/* ============= BERANDA ================ */}
				<Route
					index
					element={
						<>
							<PageTitle title="Beranda | Sicalmers" />
							<Beranda />
						</>
					}
				/>
				<Route
					path="beranda/capaian-air-minum-provinsi"
					element={
						<>
							<PageTitle title="Beranda | Capaian Air Minum Provinsi" />
							<CapaianAmProv />
						</>
					}
				/>
				<Route
					path="beranda/capaian-air-minum-kabupaten-kota"
					element={
						<>
							<PageTitle title="Beranda | Capaian Air Minum Kabupaten Kota" />
							<CapaianAmKab />
						</>
					}
				/>
				<Route
					path="beranda/capaian-air-limbah-kabupaten-kota"
					element={
						<>
							<PageTitle title="Beranda | Capaian Air Limbah Kabupaten Kota" />
							<CapaianAlKab />
						</>
					}
				/>
				<Route
					path="beranda/capaian-rumah-bencana-kabupaten-kota"
					element={
						<>
							<PageTitle title="Beranda | Capaian Rumah Bencana Kabupaten Kota" />
							<CapaianRBKab />
						</>
					}
				/>
				<Route
					path="beranda/capaian-rumah-relokasi-kabupaten-kota"
					element={
						<>
							<PageTitle title="Beranda | Capaian Rumah Relokasi Kabupaten Kota" />
							<CapaianRLKab />
						</>
					}
				/>
				<Route
					path="data-dasar"
					element={
						<>
							<PageTitle title="Beranda | Capaian Air Minum Kabupaten Kota" />
							<DataDasar />
						</>
					}
				/>
				{/* ============= DATA DASAR ================ */}
				<Route
					path="data-dasar/air-minum"
					element={
						<>
							<PageTitle title="Data Dasar | Air Minum" />
							<DataDasarAirMinum />
						</>
					}
				/>
				<Route
					path="data-dasar/air-limbah"
					element={
						<>
							<PageTitle title="Data Dasar | Air Limbah" />
							<DataDasarAirLimbah />
						</>
					}
				/>
				<Route
					path="data-dasar/perumahan"
					element={
						<>
							<PageTitle title="Data Dasar | perumahan" />
							<DataDasarPerumahan />
						</>
					}
				/>
				<Route
					path="data-dasar/rumah-bencana"
					element={
						<>
							<PageTitle title="Data Dasar | Rumah Bencana" />
							<DataDasarPerumahan />
						</>
					}
				/>
				<Route
					path="data-dasar/rumah-relokasi"
					element={
						<>
							<PageTitle title="Data Dasar | Rumah Relokasi" />
							<DataDasarPerumahan />
						</>
					}
				/>
				{/* ============= AIR MINUM ================ */}
				<Route
					path="pengumpulan-data/air-minum"
					element={
						<>
							<PageTitle title="Pengumpulan Data | Air Minum" />
							<PengumpulanDataAirMinum />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/rencana/air-minum"
					element={
						<>
							<PageTitle title="Rencana Pemenuhan | Air Minum" />
							<RencanaPemenuhanAirMinum />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/detail/rencana/air-minum/:id"
					element={
						<>
							<PageTitle title="Detail Rencana | Air Minum" />
							<DetailRencanaPelaksanaanAM />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/realisasi/air-minum/:id"
					element={
						<>
							<PageTitle title="Realisasi Pelaksanaan | Air Minum" />
							<TambahRealisasiAM />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/pelaksanaan/air-minum"
					element={
						<>
							<PageTitle title="Pelaksanaan Pemenuhan | Air Minum" />
							<PelaksanaanPemenuhanAirMinum />
						</>
					}
				/>
				<Route
					path="data-capaian/tahunan/air-minum"
					element={
						<>
							<PageTitle title="Data Capaian Tahunan | Air Minum" />
							<DataCapaianAMTahunan />
						</>
					}
				/>
				<Route
					path="data-capaian/kumulatif/air-minum"
					element={
						<>
							<PageTitle title="Data Capaian Kumulatif | Air Minum" />
							<DataCapaianAMKumulatif />
						</>
					}
				/>
				{/* ============= AIR LIMBAH ================ */}
				<Route
					path="pengumpulan-data/air-limbah"
					element={
						<>
							<PageTitle title="Pengumpulan Data | Air Limbah" />
							<PengumpulanDataAirLimbah />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/rencana/air-limbah"
					element={
						<>
							<PageTitle title="Rencana Pemenuhan | Air Limbah" />
							<RencanaPemenuhanAirLimbah />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/pelaksanaan/air-limbah"
					element={
						<>
							<PageTitle title="Pelaksanaan Pemenuhan | Air Limbah" />
							<PelaksanaanPemenuhanAirLimbah />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/realisasi/air-limbah/:id"
					element={
						<>
							<PageTitle title="Realisasi Pelaksanaan | Air Limbah" />
							<TambahRealisasiAL />
						</>
					}
				/>
				<Route
					path="data-capaian/tahunan/air-limbah"
					element={
						<>
							<PageTitle title="Data Capaian Kumulatif | Air Limbah" />
							<DataCapaianTahunanAL />
						</>
					}
				/>
				<Route
					path="data-capaian/kumulatif/air-limbah"
					element={
						<>
							<PageTitle title="Data Capaian Kumulatif | Air Limbah" />
							<DataCapaianKumulatifAL />
						</>
					}
				/>
				{/* ============= RUMAH BENCANA ================ */}
				<Route
					path="pengumpulan-data/rumah-bencana"
					element={
						<>
							<PageTitle title="Pengumpulan Data | Rumah Bencana" />
							<PengumpulanDataRumahBencana />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/rencana/rumah-bencana"
					element={
						<>
							<PageTitle title="Rencana Pemenuhan | Rumah Bencana" />
							<RencanaPemenuhanBencana />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/pelaksanaan/rumah-bencana"
					element={
						<>
							<PageTitle title="Pelaksanaan Pemenuhan | Rumah Bencana" />
							<PelaksanaanPemenuhanBencana />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/realisasi/rumah-bencana/:id"
					element={
						<>
							<PageTitle title="Realisasi Pelaksanaan | Rumah Bencana" />
							<TambahRealisasiBencana />
						</>
					}
				/>
				<Route
					path="data-capaian/kumulatif/rumah-bencana"
					element={
						<>
							<PageTitle title="Data Capaian Kumulatif | Rumah Bencana" />
							<DataCapaianKumulatifRumahBencana />
						</>
					}
				/>
				<Route
					path="data-capaian/tahunan/rumah-bencana"
					element={
						<>
							<PageTitle title="Data Capaian Tahunan | Rumah Bencana" />
							<DataCapaianRumahBencanaTahunan />
						</>
					}
				/>
				{/* ============= RELOKASI ================ */}
				<Route
					path="pemenuhan-spm/rencana/rumah-relokasi"
					element={
						<>
							<PageTitle title="Rencana Pemenuhan | Rumah Relokasi" />
							<RencanaPemenuhanRelokasi />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/pelaksanaan/rumah-relokasi"
					element={
						<>
							<PageTitle title="Pelaksanaan Pemenuhan | Rumah Relokasi" />
							<PelaksanaanPemenuhanRelokasi />
						</>
					}
				/>
				<Route
					path="pemenuhan-spm/realisasi/rumah-relokasi/:id"
					element={
						<>
							<PageTitle title="Realisasi Pelaksanaan | Rumah Relokasi" />
							<TambahRealisasiRelokasi />
						</>
					}
				/>
				<Route
					path="data-capaian/kumulatif/rumah-relokasi"
					element={
						<>
							<PageTitle title="Data Capaian Kumulatif | Rumah Relokasi" />
							<DataCapaianKumulatifRumahRelokasi />
						</>
					}
				/>
				<Route
					path="data-capaian/tahunan/rumah-relokasi"
					element={
						<>
							<PageTitle title="Data Capaian Tahunan | Rumah Relokasi" />
							<DataCapaianRumahRelokasiTahunan />
						</>
					}
				/>
				<Route
					path="pengumpulan-data/rumah-relokasi"
					element={
						<>
							<PageTitle title="Pengumpulan Data | Rumah Relokasi" />
							<PengumpulanDataRumahRelokasi />
						</>
					}
				/>
				{/* ============= PROFILE DLL ================ */}
				<Route
					path="/calendar"
					element={
						<>
							<PageTitle title="Calendar | " />
							<Calendar />
						</>
					}
				/>
				<Route
					path="/profile"
					element={
						<>
							<PageTitle title="Profile | " />
							<Profile />
						</>
					}
				/>
				<Route
					path="/development"
					element={
						<>
							<PageTitle title="Developments | SICALMERS" />
							<Developments />
						</>
					}
				/>
				<Route
					path="/summary"
					element={
						<>
							<PageTitle title="Summary | SICALMERS" />
							<SummaryPage />
						</>
					}
				/>
				<Route
					path="/manage-users"
					element={
						<>
							<PageTitle title="Manage Users | SICALMERS" />
							<ManageUsers />
						</>
					}
				/>
				<Route
					path="/settings"
					element={
						<>
							<PageTitle title="Settings | " />
							<Settings />
						</>
					}
				/>
				{/* ============= FORMS ================ */}
				<Route
					path="/forms/form-elements"
					element={
						<>
							<PageTitle title="Form Elements | " />
							<FormElements />
						</>
					}
				/>
				<Route
					path="/forms/form-layout"
					element={
						<>
							<PageTitle title="Form Layout | " />
							<FormLayout />
						</>
					}
				/>
				{/* ============= UI & COMPONENTS ================ */}
				<Route
					path="/tables"
					element={
						<>
							<PageTitle title="Tables | " />
							<Tables />
						</>
					}
				/>
				<Route
					path="/chart"
					element={
						<>
							<PageTitle title="Basic Chart | " />
							<Chart />
						</>
					}
				/>
				<Route
					path="/ui/alerts"
					element={
						<>
							<PageTitle title="Alerts | " />
							<Alerts />
						</>
					}
				/>
				<Route
					path="/ui/buttons"
					element={
						<>
							<PageTitle title="Buttons | " />
							<Buttons />
						</>
					}
				/>

				{/* ============= AUTH ================ */}
				<Route
					path="/auth/login"
					element={
						<>
							<PageTitle title="Login | SICALMERS" />
							<Login />
						</>
					}
				/>
				<Route
					path="auth/login-url/:username/:password"
					element={
						<>
							<AutoLogin onAutoLoginDone={() => setAutoLoginDone(true)} />
						</>
					}
				/>
				<Route
					path="/auth/forgot-password"
					element={
						<>
							<PageTitle title="Lupa Password | SICALMERS" />
							<ForgotPassword />
						</>
					}
				/>
				<Route
					path="/auth/cek-otp"
					element={
						localStorage.getItem('reset-password') ? (
							<>
								<PageTitle title="Cek OTP | SICALMERS" />
								<CekOtp />
							</>
						) : (
							<Navigate to="/auth/login" />
						)
					}
				/>
				<Route
					path="/auth/reset-password"
					element={
						localStorage.getItem('reset-password') ? (
							<>
								<PageTitle title="Reset Password | SICALMERS" />
								<ResetPassword />
							</>
						) : (
							<Navigate to="/auth/login" />
						)
					}
				/>
				<Route
					path="/auth/signup"
					element={
						<>
							<PageTitle title="Signup | " />
							<SignUp />
						</>
					}
				/>
				<Route path="/auth/logout" element={<Logout />} />
				{/* ============= Lainnya ================ */}
				<Route
					path="/informasi"
					element={
						<>
							<PageTitle title="Informasi | SICALMERS" />
							<Informasi />
						</>
					}
				/>
				<Route
					path="/kontak"
					element={
						<>
							<PageTitle title="Kontak | SICALMERS" />
							<Kontak />
						</>
					}
				/>
			</Routes>
		</>
	);
}

export default App;
