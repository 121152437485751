import { RootState } from '@/app/store';
import { MiniLoader } from '@/common/Loader';
import { ActionButtonSPALD } from '@/components/ActionButton/AirLimbah/ABInvSPALDProv';
import { FInvSPALDProv } from '@/components/Forms/AirLimbah/PengumpulanData/FInvSPALDProv';
import { GInvSPALDProv } from '@/components/Guides/AirLimbah/PengumpulanData/GInvSPALDProv';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import {
	useDeleteSPALDProv,
	useGetSaranaSPALD,
} from '@/hooks/airLimbah/useInvSarana';
import { useMenuAirLimbah } from '@/hooks/menu/useMenu';
import { usePrivilegeAirLimbah } from '@/hooks/privilege/usePrivilege';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

export const TInvSPALDProv = () => {
	const cardRef = useRef(null);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const menu = useSelector((state: RootState) => state.menuId.value);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;
	let rowCounter = 1;
	const { getSaranaSPALD, isLoading } = useGetSaranaSPALD(
		kd_provinsi_to_use,
		Number(tahun),
		menu,
	);
	const { deleteSPALDProv } = useDeleteSPALDProv();
	const queryClient = useQueryClient();
	const { isMenuAirLimbahProv } = useMenuAirLimbah();
	const { isPrevilegeViewAirLimbah, isPrivillageBappedaBPWP } =
		usePrivilegeAirLimbah();

	const handleEdit = (rowData: any) => {
		console.log('Edit data:', rowData);
	};

	const handleDelete = async (rowData: any) => {
		console.log('Delete data:', rowData);

		try {
			await deleteSPALDProv({
				id_prov_spald: rowData.id_prov_spald,
			});
			queryClient.refetchQueries({ queryKey: ['inv-spald'] });
			toast.success('Berhasil menghapus data SPALD');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus data SPALD');
		}
	};

	useEffect(() => {
		queryClient.refetchQueries({ queryKey: ['inv-spald'] });
	}, [tahun, getSaranaSPALD]);

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			{isPrivillageBappedaBPWP && (
				<div className="flex justify-end gap-4 p-4">
					<GInvSPALDProv />
					<FInvSPALDProv />
				</div>
			)}
			{isLoading ? (
				<MiniLoader />
			) : (
				<ScrollArea className="relative h-full p-4">
					<JudulHeader cardRef={cardRef} isLoading={isLoading}>
						INVENTARISASI SPALD DI PROVINSI
						<br />
						Penyediaan Pelayanan Pengolahan Air Limbah Domestik
					</JudulHeader>
					<Table className="w-full min-w-[1800px] table-auto">
						<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
							<TableRow>
								<TableHead
									className="thead-text border-l text-center"
									rowSpan={3}
								>
									No
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={3}>
									Nama SPALD Regional
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={3}>
									Lokasi SPALD Regional
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={3}>
									Kondisi SPALD Regional <br /> (Beroperasi/Tidak)
								</TableHead>
								<TableHead
									className="thead-text text-center"
									rowSpan={1}
									colSpan={3}
								>
									Wilayah Pelayanan
								</TableHead>
								<TableHead className="thead-text text-center" colSpan={4}>
									Akses
								</TableHead>
								<TableHead className="thead-text text-center" rowSpan={3}>
									Aksi (Edit/Hapus)
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead
									className="thead-text border-t-0 text-center"
									rowSpan={2}
								>
									Kabupaten/Kota
								</TableHead>
								<TableHead
									className="thead-text border-t-0 text-center"
									rowSpan={2}
								>
									Kecamatan
								</TableHead>
								<TableHead
									className="thead-text border-t-0 text-center"
									rowSpan={2}
								>
									Kelurahan/Desa
								</TableHead>
								<TableHead
									className="thead-text col-span-3 border-t-0 text-center"
									colSpan={2}
								>
									Tersedia
								</TableHead>
								<TableHead
									className="thead-text col-span-3 border-t-0 text-center"
									colSpan={2}
								>
									Termanfaatkan
								</TableHead>
							</TableRow>
							<TableRow>
								<TableHead className="thead-text border-t-0 text-center">
									Kapasitas (m3/hari)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Unit (Rumah Tangga)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Kapasitas (m3/hari)
								</TableHead>
								<TableHead className="thead-text border-t-0 text-center">
									Unit (Rumah Tangga)
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{getSaranaSPALD.map((sarana: any) => (
								<TableRow
									key={sarana.id_prov_spald}
									className={
										rowCounter % 2 === 0
											? 'bg-gray-2 dark:bg-meta-4'
											: 'bg-white dark:bg-boxdark'
									}
								>
									<TableCell className="tbody-text border-b border-l border-r">
										{rowCounter++}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.nama_spald_regional}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.lokasi_spald_regional}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.kondisi}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.wilayah.nama_kabkota}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.wilayah.nama_kecamatan}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.wilayah.nama_desa}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.tersedia_m3}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.tersedia_rumah}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.termanfaatkan_m3}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{sarana.termanfaatkan_rumah}
									</TableCell>
									{isPrevilegeViewAirLimbah ? (
										<TableCell className="tbody-text flex justify-center border-b border-r">
											<ActionButtonSPALD
												rowData={sarana}
												onEdit={handleEdit}
												onDelete={() => handleDelete(sarana)}
											/>
										</TableCell>
									) : (
										<></>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
					<ScrollBar orientation="horizontal" />
				</ScrollArea>
			)}
		</div>
	);
};
