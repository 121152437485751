import { useSelector } from 'react-redux';
import { useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import { RootState } from '@/app/store';
import Breadcrumb from '@/components/Breadcrumbs/Breadcrumb';
import { TCapaianPeriode } from '@/components/Tables/Summary/TCapaianPeriode';
import { TGrowthCapaian } from '@/components/Tables/Summary/TGrowthCapaian';

const SummaryPage = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const [activeTab, setActiveTab] = useState('Tabel Capaian Per Periode');

	return (
		<DefaultLayout>
			<Breadcrumb pageHead="Summary" pageName="Summary Data" />
			<div className="grid grid-cols-1 justify-between gap-1 bg-[#364878] p-1 dark:bg-graydark sm:grid-cols-2">
				<>
					<div
						className={`py-4 text-center ${
							activeTab === 'Tabel Capaian Per Periode'
								? 'bg-[#fab23d]'
								: 'bg-[#364878] dark:bg-graydark'
						} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
						onClick={() => setActiveTab('Tabel Capaian Per Periode')}
					>
						Tabel Capaian
						<br />
						Per Periode
					</div>
					<div
						className={`py-4 text-center ${
							activeTab === 'Table Pertumbuhan Capaian Pertahun'
								? 'bg-[#fab23d]'
								: 'bg-[#364878] dark:bg-graydark'
						} cursor-pointer rounded-md text-white hover:bg-graydark dark:hover:bg-boxdark-2`}
						onClick={() => setActiveTab('Table Pertumbuhan Capaian Pertahun')}
					>
						Tabel Pertumbuhan
						<br />
						Capaian Pertahun
					</div>
				</>
			</div>
			{activeTab === 'Tabel Capaian Per Periode' && <TCapaianPeriode />}
			{activeTab === 'Table Pertumbuhan Capaian Pertahun' && <TGrowthCapaian />}
		</DefaultLayout>
	);
};

export default SummaryPage;
