import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBidang } from '../app/bidang/bidangSlice';
import { logout } from '../app/auth/loginSlice';
import { toast } from 'sonner';
import {
	setKd_kabkota_dux,
	setKd_provinsi_dux,
} from '../app/lokasi/lokasiSlice';

const Logout: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const hasLoggedOut = useRef(false);

	const handleLogout = (dispatch: any, navigate: any) => {
		dispatch(setBidang(''));
		dispatch(logout());
		dispatch(setKd_kabkota_dux(0));
		dispatch(setKd_provinsi_dux(0));
		toast.success('Berhasil Logout');
		navigate(-1);
	};

	useEffect(() => {
		if (!hasLoggedOut.current) {
			handleLogout(dispatch, navigate);
			hasLoggedOut.current = true;
		}
	}, [dispatch, navigate]);

	return null;
};

export default Logout;
