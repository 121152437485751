// tahunSlice.ts
import { createSlice } from '@reduxjs/toolkit';

export const tahunSlice = createSlice({
	name: 'tahun',
	initialState: {
		value: localStorage.getItem('selectedTahun') || '2022',
	},
	reducers: {
		setTahun: (state, action) => {
			state.value = action.payload;
			localStorage.setItem('selectedTahun', action.payload);
		},
	},
});

export const { setTahun } = tahunSlice.actions;
export default tahunSlice.reducer;
