import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useGetDataEksisAirLimbah } from '@/hooks/airLimbah/useDataCapaianAL';
import { EksisALData } from '@/types/airLimbah';
import React, { useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

type TDataCapaianALShadCnProps = {
	tahun?: number;
};
const TDataCapaianALShadCn: React.FC<TDataCapaianALShadCnProps> = ({
	tahun,
}) => {
	const cardRef = useRef(null);
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);
	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use = privilege === 10 ? kd_kabkota_dux : kd_kabkota;

	const [total, setTotal] = useState({
		target: {
			total: 0,
			akses_layak: 0,
			akses_aman_spald_s: 0,
			akses_aman_spald_t: 0,
		},
		realisasi: {
			akses_layak: 0,
			akses_aman_spald_s: 0,
			akses_aman_spald_t: 0,
			tanpa_akses: 0,
		},
	});

	const [persentaseLayanan, setPersentaseLayanan] = useState({
		real_akses_layak: 0,
		real_akses_aman_spald_s: 0,
		real_akses_aman_spald_t: 0,
		real_tanpa_akses: 0,
		capaian: 0,
	});

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		refetch,
		isLoading,
	} = useGetDataEksisAirLimbah({
		kd_provinsi: kd_provinsi_to_use,
		kd_kabkota: kd_kabkota_to_use,
		tahun,
		sample_id: null,
	});
	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	useEffect(() => {
		if (!isLoading) {
			if (!hasNextPage) {
				const totalTargetRumah = pages
					.flatMap((page) => page.data)
					.reduce((acc: any, curr: any) => acc + curr.tgt_total, 0);
				const totalTargetAksesLayak = pages
					.flatMap((page) => page.data)
					.reduce((acc: any, curr: any) => acc + curr.tgt_aks_layak, 0);
				const totalTargetSPALDS = pages
					.flatMap((page) => page.data)
					.reduce((acc: any, curr: any) => acc + curr.tgt_aks_aman_spald_s, 0);
				const totalTargetSPALDT = pages
					.flatMap((page) => page.data)
					.reduce((acc: any, curr: any) => acc + curr.tgt_aks_aman_spald_t, 0);

				const totalRealisasiAksesLayak = pages
					.flatMap((page) => page.data)
					.reduce((acc: any, curr: any) => acc + curr.new_real_aks_layak, 0);
				const totalRealisasiSPALDS = pages
					.flatMap((page) => page.data)
					.reduce((acc: any, curr: any) => acc + curr.real_aks_aman_spald_s, 0);
				const totalRealisasiSPALDT = pages
					.flatMap((page) => page.data)
					.reduce((acc: any, curr: any) => acc + curr.real_aks_aman_spald_t, 0);
				const totalRealisasiTanpaAkses = pages
					.flatMap((page) => page.data)
					.reduce((acc: any, curr: any) => acc + curr.real_tnp_aks, 0);

				const persentaseRealisasiAksesLayak =
					(totalRealisasiAksesLayak / totalTargetRumah) * 100;
				const persentaseRealisasiAksesAmanSPALDS =
					(totalRealisasiSPALDS / totalTargetRumah) * 100;
				const persentaseRealisasiAksesAmanSPALDT =
					(totalRealisasiSPALDT / totalTargetRumah) * 100;
				const persentaseRealisasiTanpaAkses =
					(totalRealisasiTanpaAkses / totalTargetRumah) * 100;
				const persentaseCapaian =
					((totalRealisasiAksesLayak +
						totalRealisasiSPALDS +
						totalRealisasiSPALDT) /
						totalTargetRumah) *
					100;

				setTotal((prevState: any) => ({
					...prevState,
					target: {
						total: totalTargetRumah,
						akses_layak: totalTargetAksesLayak,
						akses_aman_spald_s: totalTargetSPALDS,
						akses_aman_spald_t: totalTargetSPALDT,
					},
					realisasi: {
						akses_layak: totalRealisasiAksesLayak,
						akses_aman_spald_s: totalRealisasiSPALDS,
						akses_aman_spald_t: totalRealisasiSPALDT,
						tanpa_akses: totalRealisasiTanpaAkses,
					},
				}));

				setPersentaseLayanan((prevState: any) => ({
					...prevState,
					real_akses_layak: persentaseRealisasiAksesLayak.toFixed(2),
					real_akses_aman_spald_s:
						persentaseRealisasiAksesAmanSPALDS.toFixed(2),
					real_akses_aman_spald_t:
						persentaseRealisasiAksesAmanSPALDT.toFixed(2),
					real_tanpa_akses: persentaseRealisasiTanpaAkses.toFixed(2),
					capaian: persentaseCapaian.toFixed(2),
				}));
			}
		}
	}, [hasNextPage]);

	useEffect(() => {
		refetch();
	}, []);

	const pages = data?.pages || [];

	let rowCounter = 0;

	if (isLoading) return <CompLoader />;

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader isLoading={isLoading} cardRef={cardRef}>
					DATA CAPAIAN <br />
					SPM PERKERJAAN UMUM <br />
					PENYEDIAAN PELAYANAN PENGOLAHAN AIR LIMBAH DOMESTIK TAHUN{' '}
					{Number(tahun)}
				</JudulHeader>
				<div className="sticky top-0 flex items-center justify-start py-4 text-center">
					<div className="inline-flex items-center gap-2 text-center font-bold">
						<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
						pada table dibawah ini merupakan data dari tahun sebelumnya.
					</div>
				</div>
				<Table className="w-full min-w-[1600px] table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead
								rowSpan={3}
								className="thead-text border-l text-center"
							>
								No
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Kecamatan
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Desa
							</TableHead>
							<TableHead
								colSpan={4}
								className="thead-text border-b-0 text-center"
							>
								Target
							</TableHead>
							<TableHead
								colSpan={4}
								className="thead-text border-b-0 text-center"
							>
								Realisasi
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text border-b-0 text-center">
								Total
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Akses Layak
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Akses Aman SPALD-S
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Akses Aman SPALD-T
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Akses Layak
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Akses Aman SPALD-S
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Akses Aman SPALD-T
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Tanpa Akses
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text text-center">
								(Rumah Tangga)
							</TableHead>
							<TableHead className="thead-text text-center">
								(Rumah Tangga)
							</TableHead>
							<TableHead className="thead-text text-center">
								(Rumah Tangga)
							</TableHead>
							<TableHead className="thead-text text-center">
								(Rumah Tangga)
							</TableHead>
							<TableHead className="thead-text text-center">
								(Rumah Tangga)
							</TableHead>
							<TableHead className="thead-text text-center">
								(Rumah Tangga)
							</TableHead>
							<TableHead className="thead-text text-center">
								(Rumah Tangga)
							</TableHead>
							<TableHead className="thead-text text-center">
								(Rumah Tangga)
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{pages.map((page: any, key: any) => {
							return (
								<React.Fragment key={key}>
									{page.data.map((item: any, i: any) => {
										rowCounter++;
										return (
											<TableRow key={i}>
												<TableCell className="tbody-text border-b border-l border-r">
													{rowCounter}
												</TableCell>
												<TableCell className="tbody-text border-b border-l-0 border-r">
													{item.nama_kecamatan}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.nama_desa}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.tgt_total}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.tgt_aks_layak}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.tgt_aks_aman_spald_s}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.tgt_aks_aman_spald_t}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.new_real_aks_layak}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.real_aks_aman_spald_s}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.real_aks_aman_spald_t}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{item.real_tnp_aks}
												</TableCell>
											</TableRow>
										);
									})}
								</React.Fragment>
							);
						})}
						{!hasNextPage && (
							<>
								<TableRow className="bg-black/10 dark:bg-white/10">
									<TableCell
										className="tfooter-text border border-t-0 text-lg"
										colSpan={3}
									>
										Total
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.target.total}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.target.akses_layak}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.target.akses_aman_spald_s}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.target.akses_aman_spald_t}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.realisasi.akses_layak}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.realisasi.akses_aman_spald_s}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.realisasi.akses_aman_spald_t}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.realisasi.tanpa_akses}
									</TableCell>
								</TableRow>
								<TableRow className="bg-black/10 dark:bg-white/10">
									<TableCell
										className="tfooter-text border border-t-0 text-lg"
										colSpan={7}
									>
										Persentase Layanan
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.real_akses_layak}%
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.real_akses_aman_spald_s}%
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.real_akses_aman_spald_t}%
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.real_tanpa_akses}%
									</TableCell>
								</TableRow>
								<TableRow className="bg-black/10 dark:bg-white/10">
									<TableCell
										className="tfooter-text border border-t-0 text-lg"
										colSpan={7}
									>
										Capaian SPM
									</TableCell>
									<TableCell
										className="tfooter-text border-b border-r"
										colSpan={4}
									>
										{persentaseLayanan.capaian}%
									</TableCell>
								</TableRow>
							</>
						)}
					</TableBody>
				</Table>
				<div className="h-1" ref={ref}>
					{isFetchingNextPage && (
						<div className="flex items-center justify-center p-4">
							<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
						</div>
					)}
				</div>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
			{/* <ScrollArea className='h-full relative p-4'>
				<JudulHeader cardRef={cardRef} isLoading={false}>
					SPM PEKERJAAN UMUM TAHUN {Number(tahun)}
					<br />
					Penyediaan Pelayanan Pengolahan Air Limbah Domestik
				</JudulHeader>
				<Table className='w-full min-w-[2000px] table-auto'>
					<TableHeader className=" bg-gray-2 dark:bg-meta-4 sticky top-0">
						<TableRow>
							<TableHead rowSpan={2} className="thead-text text-center border-l">
								No
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Kecamatan
							</TableHead>
							<TableHead rowSpan={2} className="thead-text text-center">
								Kelurahan / Desa
							</TableHead>
							<TableHead colSpan={4} className="thead-text text-center border-b-0">
								Target
							</TableHead>
							<TableHead colSpan={5} className="thead-text text-center border-b-0">
								Realisasi
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text text-center">
								Total <br />
								Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								Akses Dasar Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								Akses Aman SPALD-T <br /> Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								Akses Aman SPALD-S <br /> Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								Tanpa Akses <br /> Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								Akses Dasar <br /> Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								Akses Layak <br />
								Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								Akses Aman <br /> SPALD-T <br /> Unit Rumah
							</TableHead>
							<TableHead className="thead-text text-center">
								Akses Aman <br /> SPALD-S <br /> Unit Rumah
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{pages.map((page) =>
							page.data.map((item: EksisALData, key: number) => {
								rowCounter++;
								return (
									<TableRow
										key={key}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className='tbody-text border-l border-b border-r'>{rowCounter}</TableCell>
										<TableCell className='tbody-text border-b border-r'>{item.nama_kecamatan}</TableCell>
										<TableCell className='tbody-text border-b border-r'>{item.nama_desa}</TableCell>
										<TableCell className='tbody-text border-b border-r'>{item.tgt_total}</TableCell>
										<TableCell className='tbody-text border-b border-r'>{item.tgt_aks_dasar}</TableCell>
										<TableCell className='tbody-text border-b border-r'>
											{item.tgt_aks_aman_spald_s}
										</TableCell>
										<TableCell className='tbody-text border-b border-r'>
											{item.tgt_aks_aman_spald_t}
										</TableCell>
										<TableCell className='tbody-text border-b border-r'>{item.real_tnp_aks}</TableCell>
										<TableCell className='tbody-text border-b border-r'>{item.real_aks_dasar}</TableCell>
										<TableCell className='tbody-text border-b border-r'>{item.real_aks_layak}</TableCell>
										<TableCell className='tbody-text border-b border-r'>
											{item.real_aks_aman_spald_s}
										</TableCell>
										<TableCell className='tbody-text border-b border-r'>
											{item.real_aks_aman_spald_t}
										</TableCell>
									</TableRow>
								);
							}),
						)}
					</TableBody>
				</Table>
				<div className='h-1' ref={ref}>
					{isFetchingNextPage &&
						<div className="flex justify-center items-center p-4">
							<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
						</div>
					}
				</div>
				<ScrollBar orientation="horizontal" />
			</ScrollArea> */}
		</div>
	);
};
export default TDataCapaianALShadCn;
