// import { useGetKabKota, useGetProvinsi } from '@/hooks/wilayah/useLokasi';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { Button } from '@/components/ui/Button';
import { Label } from '@/components/ui/label';
// import ProvinsiSelect from '@/components/ui/SelectProvinsi';
// import KabkotaSelect from '@/components/ui/SelectKabKota';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/app/store';
// import {
// 	setKd_provinsi_dux,
// 	setKd_kabkota_dux,
// } from '@/app/lokasi/lokasiSlice';
import { SelectProvGlobal } from '../ui/SelectProvGlobal';
import { SelectKabGlobal } from '../ui/SelectKabGlobal';

const LokasiSelection = () => {
	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	// const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const wilayah = useSelector((state: RootState) => state.wilayah.value);

	const lokasi = useSelector((state: RootState) => state.location);

	// console.log(lokasi);

	// const dispatch = useDispatch();
	// const kd_provinsi_dux = useSelector(
	// 	(state: RootState) => state.location.kd_provinsi_dux,
	// );
	// const kd_provinsi_to_use =
	// 	privilege === 4 || privilege === 8 ? kd_provinsi : kd_provinsi_dux;

	// const { getProvinsi, isLoading: loadProvinsi } = useGetProvinsi();
	// const { getKabKota, isLoading: loadKabkota } =
	// 	useGetKabKota(kd_provinsi_to_use);

	// const updatedProvinsi = _.map(getProvinsi, (provinsi) => ({
	// 	...provinsi,
	// 	nama_provinsi: provinsi.nama_provinsi.replace('Provinsi ', ''),
	// }));

	return (
		<>
			<div className="flex w-full flex-grow justify-end text-lg font-bold text-[#364878]">
				<span className="my-auto mr-1 align-bottom text-lg font-bold dark:text-white">
					Lokasi:
				</span>
				<Popover>
					<PopoverTrigger asChild>
						<Button
							variant="outline"
							className="bg-slate-200 text-lg font-bold text-[#364878] shadow-default hover:bg-slate-300 dark:bg-boxdark-2 dark:text-white"
						>
							Pilih Lokasi
						</Button>
					</PopoverTrigger>
					<PopoverContent className="z-999 w-80" align="start">
						<div className="grid gap-4">
							<div className="space-y-2">
								<h4 className="font-medium leading-none">Lokasi</h4>
								<p className="text-sm text-muted-foreground">
									Silahkan Pilih Lokasi Untuk Melanjutkan
								</p>
							</div>
							<div className="grid gap-2">
								{privilege !== 4 && privilege !== 8 && (
									<div className="grid grid-cols-3 items-center gap-4">
										<Label htmlFor="width">Provinsi</Label>
										<div className="col-span-2">
											{/* <ProvinsiSelect
												isLoading={loadProvinsi}
												getProvinsi={updatedProvinsi}
												setKd_provinsi={(value) =>
													dispatch(setKd_provinsi_dux(value))
												}
											/> */}
											<SelectProvGlobal />
										</div>
									</div>
								)}
								{wilayah === 'kabupaten' && (
									<div className="grid grid-cols-3 items-center gap-4">
										<Label htmlFor="width">Kabupaten</Label>
										<div className="col-span-2">
											{/* <KabkotaSelect
												isLoading={loadKabkota}
												getKabkota={getKabKota}
												setKd_kabkota={(value) =>
													dispatch(setKd_kabkota_dux(value))
												}
											/> */}
											<SelectKabGlobal />
										</div>
									</div>
								)}
							</div>
						</div>
					</PopoverContent>
				</Popover>
			</div>
		</>
	);
};
export default LokasiSelection;
