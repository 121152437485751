import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from '../../libs/API';

export function useGetDashKumulatifKab(kd_provinsi: number, tahun: number) {
	const {
		data: getDashKumulatifKab,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['dash-kumulatif-AL-kab', kd_provinsi, tahun],
		queryFn: async () => {
			const { data } = await API.get(
				`/dash-eksis-al-prov/?kd_provinsi=${kd_provinsi}&tahun=${tahun}`,
			);
			return data.data;
		},
	});
	return { getDashKumulatifKab, refetch, isLoading };
}
export function useGetJumlahDesaLaporAL(kd_provinsi: number, tahun: number) {
	const {
		data: getJumlahDesaLapor,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ['dash-jumlah-lapor-al', kd_provinsi, tahun],
		queryFn: async () => {
			const { data } = await API.get(
				`/dash-jumlah-lapor-al/?kd_provinsi=${kd_provinsi}&tahun=${tahun}`,
			);
			return data.data;
		},
	});
	return { getJumlahDesaLapor, refetch, isLoading };
}
