import { RootState } from '@/app/store';
import { Button } from '@/components/ui/Button';
import {
	Dialog,
	DialogContent,
	DialogTrigger,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
	DialogClose,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FiEdit } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useGetJenisBencana } from '@/hooks/useJenisBencana';
import { FormEvent, useEffect, useState } from 'react';
import { useGetKecamatan } from '@/hooks/wilayah/useKecamatan';
import { useGetDesa } from '@/hooks/wilayah/useDesa';
import { useEditIdentifikasiPengumpulanData } from '@/hooks/perumahan/useRumahBencana';
import { useQueryClient } from '@tanstack/react-query';
import { EditEksisPengumpulanDataRumahBencana, EksisPengumpulanDataRumahBencana } from '@/types/perumahan';
import EditJenisBencanaSelectUI from '@/components/ui/SelectEditJenisBencana';
import EditKecamatanSelectUI from '@/components/ui/SelectEditKecamatan';
import EditDesaSelectUI from '@/components/ui/SelectEditDesa';
import { useGetKabKota } from '@/hooks/wilayah/useLokasi';
import EditKabKotaSelectUI from '@/components/ui/SelectEditKabupatenKotaUI';

interface EIdentifikasiPengumpulanDataProps {
    rowData: EditEksisPengumpulanDataRumahBencana;
}

const EPengumpulanDataRumahBencana: React.FC<EIdentifikasiPengumpulanDataProps> = ({
    rowData,
}) => {
	const user = useSelector((state: RootState) => state.auth);
	const menuId = useSelector((state: RootState) => state.menuId.value);

	//wilayah
	const [kd_kabkota, setKd_kabkota] = useState<number>(rowData.kd_kabkota);
	const [kd_kec, setKd_kec] = useState<number>(rowData.kd_kec);
    const [kd_desa, setKd_desa] = useState<number>(rowData.kd_desa);


	//get
	const { getKabKota } = useGetKabKota(user.kd_provinsi);
    const { 
		getKecamatan,
		refetch: fetchKec,
	 } = useGetKecamatan(
        user.kd_provinsi,
        kd_kabkota,
    );
    const {
        getDesa,
        isLoading: loadDesa,
        refetch: fetchDesa,
    } = useGetDesa(user.kd_provinsi, kd_kabkota, kd_kec);
	const { getJenisBencana } = useGetJenisBencana();
	const { editIdentifikasiPengumpulanData } = useEditIdentifikasiPengumpulanData();



	//dataform
    const [jenisBencana, setJenisBencana] = useState<number>(rowData.jenis_bencana_id);
	const [tahunTerjadiBencana, setTahunTerjadiBencana] = useState(
		(rowData.tahun_kejadian ?? 0).toString(),
	);
	const [jumlahRumahRusak, setJumlahRumahRusak] = useState(
		(rowData.jml_rmh_rusak ?? 0).toString(),
	);
	const [rusakRingan, setRusakRingan] = useState(
		(rowData.rusak_ringan ?? 0).toString(),
	);
	const [rusakSedang, setRusakSedang] = useState(
		(rowData.rusak_sedang ?? 0).toString(),
	);
	const [rusakBerat, setRusakBerat] = useState(
		(rowData.rusak_berat ?? 0).toString(),
	);
	const [statusHakMilik, setStatusHakMilik] = useState(
		(rowData.status_milik ?? 0).toString(),
	);
	const [statusSewa, setStatusSewa] = useState(
		(rowData.status_sewa ?? 0).toString(),
	);
	const [statusLainnya, setStatusLainnya] = useState(
		(rowData.status_lainnya ?? 0).toString(),
	);

	//additionals
	const [modalOpen, setModalOpen] = useState(false);
	const queryClient = useQueryClient();

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		const data = {
            id_bencana_pengumpulan: rowData.id_bencana_pengumpulan,
            jenis_bencana_id: jenisBencana,
			kd_kec:kd_kec,
			kd_desa:kd_desa,
			tahun_kejadian: parseInt(tahunTerjadiBencana),
			jml_rmh_rusak:parseInt(jumlahRumahRusak),
			rusak_ringan:parseInt(rusakRingan),
			rusak_sedang:parseInt(rusakSedang),
			rusak_berat: parseInt(rusakBerat),
			status_milik:parseInt(statusHakMilik),
			status_sewa:parseInt(statusSewa),
			status_lainnya:parseInt(statusLainnya)	
		}

        // console.log(data);

		editIdentifikasiPengumpulanData(data,
			{
				onSuccess: () => {
					setModalOpen(false);
					queryClient.refetchQueries({queryKey:['eksis-bencana-pengumpulan']});
				},
				onError: (error) => {
					console.log(error);
				},
			}
		)
	};

	useEffect(() => {
        fetchDesa();
        fetchKec();
    }, [kd_kec, kd_kabkota]);

    useEffect(() => {
        setJenisBencana(rowData.jenis_bencana_id);
        setKd_kec(rowData.kd_kec);
        setKd_desa(rowData.kd_desa);
    }, [rowData]);
	
	
	return (
		<>
			<Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger asChild>
                    <Button
                        size={'sm'} 
                        className='inline-flex items-center justify-start gap-1 rounded-lg bg-blue-500 px-4 py-2 text-center font-medium text-white hover:bg-blue-600'>
                        <FiEdit />
                    </Button>
                </DialogTrigger>
				<DialogContent className="mx-auto max-w-screen-xl  z-99999">
					<DialogHeader>
						<DialogTitle>
							Edit Data Rumah Yang Terkena Bencana
						</DialogTitle>
						<DialogDescription>
							Pastikan Data yang Akan Di Input Sudah Benar, Lalu Klik Simpan
						</DialogDescription>
					</DialogHeader>
					
					<form action='post' onSubmit={handleSubmit}>
						<div className="grid grid-cols-2 gap-4">
							<div className="flex flex-col">
								<Label htmlFor="Jenis Bencana" className="mb-2">
									Jenis Bencana
								</Label>
                                <EditJenisBencanaSelectUI
                                    getJenisBencana={getJenisBencana}
                                    setJenisBencana={setJenisBencana}
                                    selectedValue={jenisBencana}
									disableSelect={true}
                                />
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Tahun Terjadinya Bencana" className="mb-2">
									Tahun Terjadinya Bencana
								</Label>
                                <Input 
									id="Tahun Terjadinya Bencana" 
									type="number" 
									className="col-span-1"
									value={tahunTerjadiBencana}
									onChange={(e) => setTahunTerjadiBencana(e.target.value)}
									placeholder='Masukkan Tahun Terjadinya Bencana'
									/>
							</div>
							{ menuId === 7 && (
                                <div className="flex flex-col">
                                <Label htmlFor="Kabupaten" className="mb-2">
                                    Kabupaten
                                </Label>
                                <EditKabKotaSelectUI
                                    getKabKota={getKabKota}
                                    setKabKota={setKd_kabkota}
                                    selectedValue={kd_kabkota}
                                    disabledSelect={true}
                                />
                            </div>
                            )}
							<div className="flex flex-col">
								<Label htmlFor="Kecamatan" className="mb-2">
									Kecamatan
								</Label>
								<EditKecamatanSelectUI
                                    setKd_kec={setKd_kec}
                                    getKecamatan={getKecamatan}
                                    selectedValue={kd_kec}
									disabledSelect={true}
                                />
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Kelurahan/Desa" className="mb-2">
									Kelurahan/Desa
								</Label>
								<EditDesaSelectUI
                                    getDesa={getDesa}
                                    setKd_desa={setKd_desa}
                                    selectedValue={kd_desa}
                                    kd_kec={kd_kec}
									disabledSelect={true}
                                />
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Jumlah Rumah Rusak" className="mb-2">
									Jumlah Rumah Rusak
								</Label>
								<Input 
									id="Jumlah Rumah Rusak" 
									type="text" 
									className="col-span-1"
									value={jumlahRumahRusak}
									onChange={(e) => setJumlahRumahRusak(e.target.value)}
									placeholder='Masukkan Jumlah Rumah Rusak'
								/>
							</div>					
						</div>
						{/* Tingkat Kerusakan Rumah */}
						<h1 className='text-l font-bold mt-4'>Tingkat Kerusakan Rumah</h1>
                        <div className="grid grid-cols-3 gap-4 mt-4">
							<div className="flex flex-col">
								<Label htmlFor="Rusak Ringan" className="mb-2">
									Rusak Ringan
								</Label>
								<Input 
									id="Rusak Ringan" 
									type="text"
									className="col-span-1"
									value={rusakRingan}
									onChange={(e) => setRusakRingan(e.target.value)}
									placeholder='Masukkan Jumlah Rumah Rusak Ringan'
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Rusak Sedang" className="mb-2">
									Rusak Sedang
								</Label>
								<Input 
									id="Rusak Sedang" 
									type="text" 
									className="col-span-1"
									value={rusakSedang}
									onChange={(e) => setRusakSedang(e.target.value)}
									placeholder='Masukkan Jumlah Rumah Rusak Sedang'
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Rusak Berat" className="mb-2">
									Rusak Berat
								</Label>
								<Input 
									id="Rusak Berat" 
									type="text"
									className="col-span-1"
									value={rusakBerat}
									onChange={(e) => setRusakBerat(e.target.value)}
									placeholder='Masukkan Jumlah Rumah Rusak Berat'
								/>
							</div>
                       	</div>
						{/* Status Kepemilikan Rumah */}
						<h1 className='text-l font-bold mt-4'>Status Kepemilikan Rumah</h1>
                        <div className="grid grid-cols-3 gap-4 mt-4">
							<div className="flex flex-col">
								<Label htmlFor="Milik" className="mb-2">
									Milik
								</Label>
								<Input 
									id="Milik" 
									type="text" 
									className="col-span-1"
									value={statusHakMilik}
									onChange={(e) => setStatusHakMilik(e.target.value)}
									placeholder='Masukkan Status Hak Milik'
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Sewa" className="mb-2">
									Sewa
								</Label>
								<Input 
									id="Sewa" 
									type="text"
									className="col-span-1"
									value={statusSewa}
									onChange={(e) => setStatusSewa(e.target.value)}
									placeholder='Masukkan Status Sewa'
								/>
							</div>
							<div className="flex flex-col">
								<Label htmlFor="Lainnya" className="mb-2">
									Lainnya
								</Label>
								<Input 
									id="Lainnya" 
									type="text" 
									className="col-span-1"
									value={statusLainnya}
									onChange={(e) => setStatusLainnya(e.target.value)}
									placeholder='Masukkan Status Lainnya'
									/>
							</div>	
                       	</div>
						<DialogFooter>
							<div className="flex w-full justify-between mt-5">
								<DialogClose asChild>
									<Button
										className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500"
									>
										Batal
									</Button>
								</DialogClose>
								<Button
									className="inline-flex items-center justify-center gap-1 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
									type="submit"
								>
									Simpan
								</Button>
							</div>
						</DialogFooter>
					</form>
					
				</DialogContent>
			</Dialog>
		</>
	);
};
export default EPengumpulanDataRumahBencana;
