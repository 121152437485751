import { RootState } from '@/app/store';
import { CompLoader, MiniLoader } from '@/common/Loader';
import JudulHeader from '@/components/Header/JudulHeader';
import { Button } from '@/components/ui/Button';
import ComboboxProvinsi from '@/components/ui/ComboboxProvinsi';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useGetDashKumulatifRBKab } from '@/hooks/perumahan/useBeranda';
import { useGetProvinsi } from '@/hooks/wilayah/useLokasi';
import { DashKumulatifRBKab } from '@/types/perumahan';
import { useRef, useState } from 'react';
import { FiAlertCircle, FiDownload } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { utils, writeFile } from 'xlsx-js-style';

const TableDataKumulatifRBKab = () => {
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const user = useSelector((state: RootState) => state.auth);
	const cardRef = useRef(null);
	const { getProvinsi, isLoading: loadProvinsi } = useGetProvinsi();
	const [kdProvByMenu, setKdProvByMenu] = useState(user.kd_provinsi);
	const [selectedValue, setSelectedValue] = useState(
		user.wilayah.nama_provinsi,
	);

	const { getDashKumulatifKab, isLoading } = useGetDashKumulatifRBKab(
		kdProvByMenu,
		Number(tahun),
	);

	const handleDownload = () => {
		const transformedData = getDashKumulatifKab.map((item: any, index: any) => {
			const checkTidakAdaBencana = item.keterangan === 'tidak ada bencana';
			return [
				{
					v: index + 1,
					t: 'n',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: item.nama_kabkota,
					t: 's',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: checkTidakAdaBencana ? '-' : item.total_jml_rmh_rusak,
					t: 'n',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: checkTidakAdaBencana ? '-' : item.total_rusak_ringan,
					t: 'n',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: checkTidakAdaBencana ? '-' : item.total_rusak_sedang,
					t: 'n',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: checkTidakAdaBencana ? '-' : item.total_rusak_berat,
					t: 'n',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: checkTidakAdaBencana ? '-' : item.total_target,
					t: 's',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: checkTidakAdaBencana ? '-' : item.total_realisasi,
					t: 's',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: `${item.persen_capaian.toFixed(2)}%`,
					t: 's',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
				{
					v: item.keterangan,
					t: 's',
					s: { alignment: { horizontal: 'center', vertical: 'center' } },
				},
			];
		});

		const header = [
			{
				v: 'No',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Nama Kabupaten/Kota',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Jumlah Rumah Rusak',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Rumah Rusak Ringan',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Rumah Rusak Sedang',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Rumah Rusak Berat',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Target',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Total Realisasi',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Persentase Capaian',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
			{
				v: 'Keterangan',
				t: 's',
				s: { alignment: { horizontal: 'center', vertical: 'center' } },
			},
		];

		let wb = utils.book_new(),
			ws = utils.aoa_to_sheet([]);

		utils.sheet_add_aoa(
			ws,
			[
				[
					{
						v: 'CAPAIAN RUMAH BENCANA KABUPATEN/KOTA',
						t: 's',
						s: {
							font: { bold: true },
							alignment: { horizontal: 'center', vertical: 'center' },
						},
					},
				],
			],
			{ origin: 'A1' },
		);
		utils.sheet_add_aoa(ws, [header], { origin: 'A2' });
		utils.sheet_add_aoa(ws, transformedData, { origin: 'A3' });

		ws['!cols'] = [
			{ wpx: 50 },
			{ wpx: 145 },
			{ wpx: 130 },
			{ wpx: 90 },
			{ wpx: 100 },
			{ wpx: 170 },
			{ wpx: 145 },
			{ wpx: 150 },
			{ wpx: 170 },
			{ wpx: 125 },
		];
		ws['!merges'] = [
			// COLSPANS
			utils.decode_range('A1:J1'),
		];

		utils.book_append_sheet(wb, ws, 'Rekapitulasi Capaian Bencana');
		writeFile(
			wb,
			`Rekapitulasi Capaian SPM Rumah Bencana ${selectedValue}  - ${tahun}.xlsx`,
		);
	};

	if (isLoading || loadProvinsi) return <CompLoader />;

	return (
		<>
			<div className="flex justify-end p-4">
				<Button
					className="inline-flex items-center justify-center gap-2 rounded-lg bg-primary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90"
					onClick={() => handleDownload()}
				>
					<FiDownload />
					Download Data
				</Button>
			</div>
			<div
				ref={cardRef}
				className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			>
				{isLoading ? (
					<MiniLoader />
				) : (
					<>
						<div className="flex items-center justify-start p-4 text-center">
							<div className="inline-flex items-center gap-2 text-center font-bold">
								<FiAlertCircle className="text-3xl text-red-500" /> Catatan :
								Silahkan Pilih Provinsi Dibawah Ini Untuk Melihat Data Dari
								Kabupaten Lain
							</div>
						</div>
						<ComboboxProvinsi
							kdProvByMenu={kdProvByMenu}
							setKdProvByMenu={setKdProvByMenu}
							selectedValue={selectedValue}
							setSelectedValue={setSelectedValue}
							loadProvinsi={loadProvinsi}
							getProvinsi={getProvinsi}
						/>
						<ScrollArea className="relative h-full p-4">
							<JudulHeader
								cardRef={cardRef}
								isLoading={isLoading}
								minusWidth={32}
							>
								Capaian Rumah Bencana Kabupaten/Kota
							</JudulHeader>
							<Table className="mt-4 w-full table-auto">
								<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
									<TableRow>
										<TableHead className="thead-text border-l p-2 text-center">
											No
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Nama <br /> Kabupaten/Kota
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Jumlah Rumah Rusak
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total Rumah <br /> Rusak Ringan
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total Rumah <br /> Rusak Sedang
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total Rumah <br /> Rusak Berat
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Target
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Total <br /> Realisasi
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Persentase <br /> Capaian
										</TableHead>
										<TableHead className="thead-text p-2 text-center">
											Keterangan
										</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{getDashKumulatifKab.map(
										(item: DashKumulatifRBKab, index: number) => {
											const checkTidakAdaBencana =
												item.keterangan === 'tidak ada bencana';
											return (
												<TableRow
													key={index}
													className={
														index % 2 === 0
															? 'bg-gray-2 dark:bg-meta-4'
															: 'bg-white dark:bg-boxdark'
													}
												>
													<TableCell className="tbody-text border-b border-l border-r">
														{index + 1}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.nama_kabkota ? item.nama_kabkota : '-'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{checkTidakAdaBencana
															? '-'
															: item.total_jml_rmh_rusak
																? item.total_jml_rmh_rusak.toLocaleString()
																: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{checkTidakAdaBencana
															? '-'
															: item.total_rusak_ringan
																? item.total_rusak_ringan.toLocaleString()
																: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{checkTidakAdaBencana
															? '-'
															: item.total_rusak_sedang
																? item.total_rusak_sedang.toLocaleString()
																: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{checkTidakAdaBencana
															? '-'
															: item.total_rusak_berat
																? item.total_rusak_berat.toLocaleString()
																: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{checkTidakAdaBencana
															? '-'
															: item.total_target
																? `${item.total_target.toLocaleString()}`
																: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{checkTidakAdaBencana
															? '-'
															: item.total_realisasi
																? `${item.total_realisasi.toLocaleString()}`
																: '0'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.persen_capaian
															? `${item.persen_capaian.toFixed(2)}%`
															: '0%'}
													</TableCell>
													<TableCell className="tbody-text border-b border-r">
														{item.keterangan ? `${item.keterangan}` : '-'}
													</TableCell>
												</TableRow>
											);
										},
									)}
								</TableBody>
							</Table>
							<ScrollBar orientation="horizontal" />
						</ScrollArea>
					</>
				)}
			</div>
		</>
	);
};

export default TableDataKumulatifRBKab;
