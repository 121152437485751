import { RootState } from '@/app/store';
import { CompLoader } from '@/common/Loader';
import ActionButtonPengumpulanDataRumah from '@/components/ActionButton/RumahBencana/PengumpulanData/ABPengumpulanDataRumah';
import FPengumpulanDataRumahBencana from '@/components/Forms/RumahBencana/FPengumpulanDataRumahBencana';
import GPengumpulanDataRumah from '@/components/Guides/RumahBencana/PengumpulanData/GPengumpulanDataRumah';
import JudulHeader from '@/components/Header/JudulHeader';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { useMenuRumahBencana } from '@/hooks/menu/useMenu';
import {
	useDeletePengumpulanDataRumahBencana,
	useGetPengumpulanDataRumah,
} from '@/hooks/perumahan/useRumahBencana';
import { usePrivilegeRumahBencana } from '@/hooks/privilege/usePrivilege';
import { EksisPengumpulanDataRumahBencana } from '@/types/perumahan';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';

const TPengumpulanDataRumahBencana = () => {
	const selectedTahun = useSelector((state: RootState) => state.tahun.value);
	const tahun = Number(selectedTahun);
	const menuId = useSelector((state: RootState) => state.menuId.value);
	const QueryClient = useQueryClient();

	//hook previlege
	const { isPrevilegeViewRumahBencana, kd_kabkota_to_use, kd_provinsi_to_use } =
		usePrivilegeRumahBencana();

	//hook menu
	const { isMenuRumahBencanaProv, isMenuRumahBencanaKab } =
		useMenuRumahBencana();

	//getData
	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetPengumpulanDataRumah(
			kd_provinsi_to_use,
			kd_kabkota_to_use,
			tahun,
			menuId,
		);

	//Delete
	const { deletePengumpulanDataRumahBencana, isPending, isSuccess } =
		useDeletePengumpulanDataRumahBencana();

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

	// Access the data
	const pages = data?.pages || [];

	const handleEdit = (rowData: EksisPengumpulanDataRumahBencana) => {
		console.log('Edit data:', rowData);
	};

	const handleDelete = async (rowData: EksisPengumpulanDataRumahBencana) => {
		try {
			await deletePengumpulanDataRumahBencana({
				id_bencana_pengumpulan: rowData.id_bencana_pengumpulan,
			});
			QueryClient.refetchQueries({ queryKey: ['eksis-bencana-pengumpulan'] });
			toast.success('Berhasil menghapus data');
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus data');
		}
	};

	const cardRef = useRef(null);

	let rowCounter = 0;
	if (isLoading) return <CompLoader />;
	return (
		<div
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
			ref={cardRef}
		>
			{isPrevilegeViewRumahBencana ? (
				<></>
			) : (
				<div className="my-2 mr-4 flex justify-end gap-4">
					<GPengumpulanDataRumah />
					<FPengumpulanDataRumahBencana />
				</div>
			)}
			<ScrollArea className="relative h-full p-4">
				<JudulHeader cardRef={cardRef}>
					PENGUMPULAN DATA RUMAH YANG TERKENA BENCANA KABUPATEN/KOTA
					<br />
					SPM PERUMAHAN RAKYAT
					<br />
					{isMenuRumahBencanaProv ? (
						<>
							Penyediaan dan Rehabilitasi Rumah yang Layak Huni Bagi Korban
							Bencana Provinsi
						</>
					) : (
						<>
							Penyediaan dan Rehabilitasi Rumah yang Layak Huni Bagi Korban
							Bencana Kabupaten/Kota
						</>
					)}
				</JudulHeader>
				<Table className="w-full min-w-[1800px] table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead className="thead-text border-l" rowSpan={2}>
								No
							</TableHead>
							<TableHead className="thead-text text-center" rowSpan={2}>
								Jenis Bencana
							</TableHead>
							<TableHead className="thead-text text-center" rowSpan={2}>
								Tahun Terjadinya Bencana
							</TableHead>
							{isMenuRumahBencanaProv && (
								<TableHead className="thead-text text-center" rowSpan={2}>
									Kabupaten/Kota
								</TableHead>
							)}
							<TableHead className="thead-text text-center" rowSpan={2}>
								Kecamatan
							</TableHead>
							<TableHead className="thead-text text-center" rowSpan={2}>
								Kelurahan/Desa
							</TableHead>
							<TableHead className="thead-text text-center" rowSpan={2}>
								Jumlah Rumah Rusak
							</TableHead>
							<TableHead className="thead-text text-center" colSpan={3}>
								TINGKAT KERUSAKAN RUMAH
							</TableHead>
							<TableHead className="thead-text text-center" colSpan={3}>
								STATUS KEPEMILIKAN RUMAH
							</TableHead>
							{isPrevilegeViewRumahBencana ? (
								<></>
							) : (
								<TableHead className="thead-text text-center" rowSpan={2}>
									Aksi (Edit/Hapus)
								</TableHead>
							)}
						</TableRow>
						<TableRow>
							<TableHead className="thead-text border-t-0 text-center">
								Rusak Ringan (Unit)
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Rusak Sedang (Unit)
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Rusak Berat (Unit)
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Milik
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Sewa
							</TableHead>
							<TableHead className="thead-text border-t-0 text-center">
								Lainnya
							</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{pages.map((page) =>
							page.data.map((item: EksisPengumpulanDataRumahBencana) => (
								<TableRow
									className={
										rowCounter % 2 === 0
											? 'bg-gray-2 dark:bg-meta-4'
											: 'bg-white dark:bg-boxdark'
									}
								>
									<TableCell className="tbody-text border-b border-l border-r text-center">
										{++rowCounter}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.jenis_bencana.nama_jenis_bencana}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.tahun_kejadian}
									</TableCell>
									{isMenuRumahBencanaProv && (
										<TableCell className="tbody-text border-b border-r text-center">
											{item.wilayah.nama_kabkota}
										</TableCell>
									)}
									<TableCell className="tbody-text border-b border-r text-center">
										{item.wilayah.nama_kecamatan}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.wilayah.nama_desa}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.jml_rmh_rusak}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.rusak_ringan}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.rusak_sedang}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.rusak_berat}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.status_milik}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.status_sewa}
									</TableCell>
									<TableCell className="tbody-text border-b border-r text-center">
										{item.status_lainnya}
									</TableCell>
									{isPrevilegeViewRumahBencana ? (
										<></>
									) : (
										<TableCell className="tbody-text flex justify-center border-b border-r text-center">
											<ActionButtonPengumpulanDataRumah
												rowData={item}
												onEdit={handleEdit}
												onDelete={() => handleDelete(item)}
											/>
										</TableCell>
									)}
								</TableRow>
							)),
						)}
					</TableBody>
				</Table>
				<ScrollBar orientation="horizontal" />
				<div className="my-2 text-center" ref={ref}>
					{isFetchingNextPage
						? 'Muat Lebih Banyak Data...'
						: hasNextPage
							? 'Muat Lebih Banyak Data...'
							: 'Tidak Ada Data Lagi'}
				</div>
			</ScrollArea>
		</div>
	);
};

export default TPengumpulanDataRumahBencana;
