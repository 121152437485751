import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/Button';
import {
    Dialog,
    DialogContent,
    DialogTrigger,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@/components/ui/dialog';
import { FiInfo } from 'react-icons/fi';

const GIdentifikasiRumahRTRW = () => {
    let rowCounter = 0;

    const panduanData = [
        {
            id: 1,
            title: "Jenis Lokasi yang Tidak Sesuai Rencana Tata Ruang Wilayah",
            description: "Diisi jenis lokasi yang tidak sesuai dengan Rencana Tata Ruang Wilayah. Menu dropdown, dengan opsi: (i) Lokasi Hutan Lindung; (ii) Lokasi Kawasan Suaka Alam dan Cagar Budaya; serta (iii) Lahan Bukan Fungsi Permukiman Lainnya"
        },
        {
            id: 2,
            title: "Kecamatan",
            description: "Diisi nama Kecamatan"
        },
        {
            id: 3,
            title: "Kelurahan/Desa",
            description: "Diisi nama Kelurahan/Desa"
        },
        {
            id: 4,
            title: "Luas Perumahan",
            description: "Diisi luas perumahan di lokasi yang tidak sesuai dengan Rencana Tata Ruang Wilayah, dalam satuan hektare (Ha)"
        },
        {
            id: 5,
            title: "Jumlah Rumah",
            description: "Diisi jumlah rumah yang berada di lokasi yang tidak sesuai dengan Rencana Tata Ruang Wilayah"
        },
        {
            id: 6,
            title: "Jumlah KK",
            description: "Diisi jumlah KK yang berada di lokasi yang tidak sesuai dengan Rencana Tata Ruang Wilayah"
        },
        {
            id: 7,
            title: "Legal",
            description: "Diisi jumlah rumah di lokasi yang tidak sesuai dengan Rencana Tata Ruang Wilayah yang status tanahnya legal"
        },
        {
            id: 8,
            title: "Ilegal",
            description: "Diisi jumlah rumah di lokasi yang tidak sesuai dengan Rencana Tata Ruang Wilayah yang status tanahnya ilegal"
        }
    ];
    
    return (
			<>
				<Dialog>
					<DialogTrigger asChild>
						<Button className="inline-flex items-center justify-center gap-1 rounded-lg bg-green-600 px-4 py-2 text-center font-medium text-white hover:bg-opacity-90">
							<FiInfo />
							Panduan
						</Button>
					</DialogTrigger>
					<DialogContent className="z-99999 max-h-[85%] max-w-[65%] overflow-auto rounded-lg">
						<DialogHeader>
							<DialogTitle>
								Panduan Pengisian Form Identifikasi Perumahan Di Lokasi Yang
								Tidak Sesuai Rencana Tata Ruang Wilayah
							</DialogTitle>
							<DialogDescription>
								Silahkan ikuti panduan dibawah ini untuk melakukan pengisian
								form atau input data
							</DialogDescription>
						</DialogHeader>
						<Table>
							<TableHeader>
								<TableRow>
									<TableHead className="border-l text-center">No</TableHead>
									<TableHead className="text-center">Kolom</TableHead>
									<TableHead className="text-center">Keterangan</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{panduanData.map((data) => (
									<TableRow
										key={data.id}
										className={
											rowCounter % 2 === 0
												? 'bg-gray-2 dark:bg-meta-4'
												: 'bg-white dark:bg-boxdark'
										}
									>
										<TableCell className="text-center">
											{++rowCounter}
										</TableCell>
										<TableCell className="text-start">{data.title}</TableCell>
										<TableCell className="text-start">
											{data.description}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<DialogFooter>
							<div className="mt-5 flex w-full items-center justify-end">
								<DialogClose asChild>
									<Button className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-600 px-4 py-2 text-center font-medium text-white hover:bg-red-500">
										Tutup
									</Button>
								</DialogClose>
							</div>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			</>
		);
};

export default GIdentifikasiRumahRTRW;
