import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import React, { useEffect, useRef, useState } from 'react';
import { EksisAMData } from '@/types/airMinum';
import { useGetDataEksisAirMinumPer } from '@/hooks/airMinum/useDataCapaianAM';
import { CompLoader } from '@/common/Loader';
import { useInView } from 'react-intersection-observer';
import { hitungTotalData } from '@/libs/utils';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import JudulHeader from '@/components/Header/JudulHeader';
import { FiAlertCircle } from 'react-icons/fi';

type TableDataCapaianPerProps = {
	tahun: number;
};
const TableDataCapaianPer: React.FC<TableDataCapaianPerProps> = ({ tahun }) => {
	const kd_provinsi = useSelector((state: RootState) => state.auth.kd_provinsi);
	const kd_kabkota = useSelector((state: RootState) => state.auth.kd_kabkota);

	const privilege = useSelector((state: RootState) => state.auth.privilege_id);
	const { kd_provinsi_dux, kd_kabkota_dux } = useSelector(
		(state: RootState) => state.location,
	);

	const [total, setTotal] = useState({
		jumlah_rumah: 0,
		terlayani_jp: 0,
		terlayani_bjp: 0,
		belum_terlayani: 0,
	});
	const [persentaseLayanan, setPersentaseLayanan] = useState({
		terlayani_jp: 0,
		terlayani_bjp: 0,
		terlayani_air_minum: 0,
		belum_terlayani: 0,
		belum_terlayani_air_minum: 0,
		gapPelayanan: 0,
		capaian: 0,
	});

	const kd_provinsi_to_use = privilege === 10 ? kd_provinsi_dux : kd_provinsi;
	const kd_kabkota_to_use = privilege === 10 || 4 ? kd_kabkota_dux : kd_kabkota;

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetDataEksisAirMinumPer({
			kd_provinsi: kd_provinsi_to_use,
			kd_kabkota: kd_kabkota_to_use,
			tahun,
		});

	const { ref, inView } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if (inView && hasNextPage && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);
	// Access the data
	const pages = data?.pages || [];

	const calculateTerlayaniAirMinum = (
		jp: number,
		bjp: number,
		totalRumah: number,
	) => {
		const total = ((jp + bjp) / totalRumah) * 100;
		return isNaN(total) ? 0 : total;
	};

	const calculateBelumTerlayaniAirMinum = (
		belumTerlayani: number,
		totalRumah: number,
	) => {
		const total = (belumTerlayani / totalRumah) * 100;
		return isNaN(total) ? 0 : total;
	};

	// TOTAL & PERSENTASE LAYANAN
	useEffect(() => {
		if (!isLoading) {
			if (!hasNextPage) {
				const totalJumlahRumah = hitungTotalData(pages, 'jumlah_rumah');
				const totalTerlayaniJp = hitungTotalData(pages, 'layak_jp');
				const totalTerlayaniBjp = hitungTotalData(pages, 'layak_bjp');
				const totalBelumTerlayani = hitungTotalData(pages, 'belum_terlayani');

				const persentaseLayananTerlayaniJp =
					(totalTerlayaniJp / totalJumlahRumah) * 100;
				const persentaseLayananTerlayaniBjp =
					(totalTerlayaniBjp / totalJumlahRumah) * 100;
				const persentaseLayananTerlayaniAirMinum =
					persentaseLayananTerlayaniJp + persentaseLayananTerlayaniBjp;
				const persentaseBelumTerlayani =
					(totalBelumTerlayani / totalJumlahRumah) * 100;
				const persentaseCapaian =
					persentaseLayananTerlayaniJp + persentaseLayananTerlayaniBjp;

				setTotal((prevState: any) => ({
					...prevState,
					jumlah_rumah: totalJumlahRumah,
					terlayani_jp: totalTerlayaniJp,
					terlayani_bjp: totalTerlayaniBjp,
					belum_terlayani: totalBelumTerlayani,
				}));

				setPersentaseLayanan((prevState: any) => ({
					...prevState,
					terlayani_jp: persentaseLayananTerlayaniJp.toFixed(2),
					terlayani_bjp: persentaseLayananTerlayaniBjp.toFixed(2),
					terlayani_air_minum: persentaseLayananTerlayaniAirMinum.toFixed(2),
					belum_terlayani: persentaseBelumTerlayani.toFixed(2),
					belum_terlayani_air_minum: persentaseBelumTerlayani.toFixed(2),
					gapPelayanan: persentaseBelumTerlayani.toFixed(2),
					capaian: persentaseCapaian.toFixed(2),
				}));
			}
		}
	}, [hasNextPage]);

	const cardRef = useRef(null);

	let rowCounter = 0;

	if (isLoading) return <CompLoader />;

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<ScrollArea className="relative h-full p-4">
				<JudulHeader isLoading={isLoading} cardRef={cardRef}>
					KONDISI SPAM JARINGAN PERPIPAAN TAHUN {Number(tahun)}
					<br />
					PEMENUHAN KEBUTUHAN POKOK AIR MINUM SEHARI-HARI
				</JudulHeader>
				<div className="sticky top-0 flex items-center justify-start py-4 text-center">
					<div className="inline-flex items-center gap-2 text-center font-bold">
						<FiAlertCircle className="text-3xl text-red-500" /> Catatan : Data
						pada table dibawah ini merupakan data dari tahun sebelumnya.
					</div>
				</div>
				<Table className="w-full table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead
								rowSpan={3}
								className="thead-text border-l text-center"
							>
								No
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Kecamatan
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Kelurahan / Desa
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Target
							</TableHead>
							<TableHead
								colSpan={5}
								className="thead-text border-b-0 text-center"
							>
								Realisasi
							</TableHead>
							<TableHead rowSpan={3} className="thead-text text-center">
								Gap Pelayanan
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text border-b-0 text-center">
								Total
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Terlayani JP
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Terlayani BJP
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Terlayani Air Minum
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Belum Terlayani
							</TableHead>
							<TableHead className="thead-text border-b-0 text-center">
								Belum Terlayani Air Minum
							</TableHead>
						</TableRow>
						<TableRow>
							<TableHead className="thead-text text-center">(Rumah)</TableHead>
							<TableHead className="thead-text text-center">(Rumah)</TableHead>
							<TableHead className="thead-text text-center">(Rumah)</TableHead>
							<TableHead className="thead-text text-center">(%)</TableHead>
							<TableHead className="thead-text text-center">(Rumah)</TableHead>
							<TableHead className="thead-text text-center">(%)</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{pages.map((page, i) => {
							return (
								<React.Fragment key={i}>
									{page.data.map((eksisAmData: EksisAMData, key: number) => {
										rowCounter++;
										const terlayaniAirMinum = calculateTerlayaniAirMinum(
											eksisAmData.layak_jp,
											eksisAmData.layak_bjp,
											eksisAmData.jumlah_rumah,
										);
										const belumTerlayaniAirMinum =
											calculateBelumTerlayaniAirMinum(
												eksisAmData.belum_terlayani,
												eksisAmData.jumlah_rumah,
											);
										return (
											<TableRow
												key={key}
												className={
													rowCounter % 2 === 0
														? 'bg-gray-2 dark:bg-meta-4'
														: 'bg-white dark:bg-boxdark'
												}
											>
												<TableCell className="tbody-text border-b border-l border-r">
													{rowCounter}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{eksisAmData.nama_kecamatan}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{eksisAmData.nama_desa}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{eksisAmData.jumlah_rumah}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{eksisAmData.layak_jp}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{eksisAmData.layak_bjp}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{terlayaniAirMinum.toFixed(2)}%
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{eksisAmData.belum_terlayani}
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{belumTerlayaniAirMinum.toFixed(2)}%
												</TableCell>
												<TableCell className="tbody-text border-b border-r">
													{belumTerlayaniAirMinum.toFixed(2)}%
												</TableCell>
											</TableRow>
										);
									})}
								</React.Fragment>
							);
						})}
						{!hasNextPage && (
							<>
								<TableRow className="bg-black/10 dark:bg-white/10">
									<TableCell
										className="tfooter-text border-b border-l border-r text-lg"
										colSpan={3}
									>
										Total
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.jumlah_rumah}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.terlayani_jp}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.terlayani_bjp}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										-
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{total.belum_terlayani}
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										-
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										-
									</TableCell>
								</TableRow>
								<TableRow className="bg-black/10 dark:bg-white/10">
									<TableCell
										className="tfooter-text border-b border-l border-r text-lg"
										colSpan={3}
									>
										Persentase Layanan
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										-
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.terlayani_jp}%
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.terlayani_bjp}%
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.terlayani_air_minum}%
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.belum_terlayani}%
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.belum_terlayani_air_minum}%
									</TableCell>
									<TableCell className="tfooter-text border-b border-r">
										{persentaseLayanan.gapPelayanan}%
									</TableCell>
								</TableRow>
								<TableRow className="bg-black/10 dark:bg-white/10">
									<TableCell
										className="tfooter-text border-b border-l border-r text-lg"
										colSpan={3}
									>
										Capaian SPM
									</TableCell>
									<TableCell
										className="tfooter-text border-b border-r"
										colSpan={7}
									>
										{persentaseLayanan.capaian}%
									</TableCell>
								</TableRow>
							</>
						)}
					</TableBody>
				</Table>
				<div className="h-1" ref={ref}>
					{isFetchingNextPage && (
						<div className="flex items-center justify-center p-4">
							<div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-[#364878] border-t-transparent dark:border-white dark:border-t-transparent" />
						</div>
					)}
				</div>
				<ScrollBar orientation="horizontal" />
			</ScrollArea>
		</div>
	);
};

export default TableDataCapaianPer;
