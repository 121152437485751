import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { API } from '../../libs/API';

// Define the type for the password reset data
interface ResetPasswordData {
	password: string;
}

// Function to post the new password and return the data
const postResetPassword = async (
	resetPasswordData: ResetPasswordData,
): Promise<any> => {
	try {
		const token = localStorage.getItem('reset-password');
		const { data } = await API.post('/update-password', resetPasswordData, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data;
	} catch (error) {
		// Handle the error here
		if (error.response && error.response.data && error.response.data.message) {
			throw new Error(error.response.data.message);
		} else {
			throw new Error('Failed to reset password.');
		}
		return null;
	}
};

// Hook to use the mutation post for password reset
export const useResetPassword = (): UseMutationResult<
	any,
	unknown,
	ResetPasswordData,
	unknown
> => {
	return useMutation({
		mutationFn: postResetPassword,
		onError: (error: any) => {
			console.error('Error:', error);
		},
		onSuccess: () => {
			// Do something with the response from the server, for example, delete the token
			localStorage.removeItem('reset-password');
		},
	});
};
