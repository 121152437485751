import { RootState } from "@/app/store"
import { CompLoader } from "@/common/Loader"
import { Button } from "@/components/ui/Button"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { useDeleteCapaianAL } from "@/hooks/airLimbah/useDataCapaianAL"
import { usePrivilegeAirLimbah } from '@/hooks/privilege/usePrivilege';
import { useDeleteFile } from "@/hooks/useDeleteFile"
import { useGetFile } from '@/hooks/useUpload';
import { API } from '@/libs/API';
import { useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from 'react';
import { FiDownload, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { toast } from "sonner"

const TBerkasAL = () => {
	const cardRef = useRef(null);
	const tahun = useSelector((state: RootState) => state.tahun.value);
	const user = useSelector((state: RootState) => state.auth);
	const menu_id = useSelector((state: RootState) => state.menuId.value);

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [fileIDDelete, setFileIDDelete] = useState("")
	const queryClient = useQueryClient()

	const { kd_kabkota_to_use, kd_provinsi_to_use } = usePrivilegeAirLimbah();
	const { getFiles, isLoading: loadGetFiles } = useGetFile(
		kd_provinsi_to_use,
		kd_kabkota_to_use,
		menu_id,
		Number(tahun),
	);

	const { deleteFile, isPending: pendingDeleteFile } = useDeleteFile();
	const { deleteCapaianAL, isPending: pendingDeleteCapaian } = useDeleteCapaianAL()

	const handleDeleteClick = () => {
		setIsDialogOpen(true);
	};

	const handleDownload = async (filename: string, jenis_up_id: string) => {
		try {
			const { data } = await API.get(
				`/download-file/${filename}/?kd_provinsi=${kd_provinsi_to_use}&kd_kabkota=${kd_kabkota_to_use}&menu_id=${menu_id}&tahun_upload=${Number(tahun)}&jenis_up_id=${jenis_up_id}`,
				{
					responseType: 'blob',
				},
			);
			const blob = new Blob([data], { type: data.type });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error('Error downloading the file', error);
		}
	};

	const handleConfirmDelete = async (id: string) => {
		try {
			await deleteFile(id);
			await deleteCapaianAL(Number(tahun))
			queryClient.refetchQueries({ queryKey: ['get-file'] });
			queryClient.refetchQueries({ queryKey: ['eksis-air-limbah', tahun] });
			toast.success('Berhasil menghapus File');
			setIsDialogOpen(false);
		} catch (error) {
			console.error('Error deleting data:', error);
			toast.error('Gagal menghapus File');
		}
	};

	if (loadGetFiles) return <CompLoader />;

	return (
		<div
			ref={cardRef}
			className="flex flex-1 flex-col overflow-hidden rounded-sm border border-stroke bg-white p-4 shadow-default dark:border-strokedark dark:bg-boxdark"
		>
			<div>
				<h1 className="py-4 text-center text-xl font-semibold text-[#364878] dark:text-white">
					BERKAS-BERKAS UPLOAD BIDANG AIR LIMBAH <br />
					TINGKAT KABUPATEN / KOTA TAHUN {Number(tahun)}
				</h1>
			</div>
			<ScrollArea className="relative h-full p-4">
				<Table className="w-full table-auto">
					<TableHeader className="sticky top-0 bg-gray-2 dark:bg-meta-4">
						<TableRow>
							<TableHead className="thead-text border-l text-center">
								No
							</TableHead>
							<TableHead className="thead-text text-center">
								Nama File
							</TableHead>
							<TableHead className="thead-text text-center">Aksi</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{getFiles.map((item: any, index: any) => {
							return (
								<TableRow
									key={index}
									className={
										index % 2 === 0
											? 'bg-gray-2 dark:bg-meta-4'
											: 'bg-white dark:bg-boxdark'
									}
								>
									<TableCell className="tbody-text border-b border-l border-r">
										{index + 1}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										{item.nama_file}
									</TableCell>
									<TableCell className="tbody-text border-b border-r">
										<div className="flex justify-center gap-4">
											<Button
												className="text-xl"
												onClick={() =>
													handleDownload(item.nama_file, item.jenis_up_id)
												}
											>
												<FiDownload color="white" />
											</Button>
											<Button
												className="inline-flex items-center justify-start gap-1 rounded-lg bg-red-500 px-4 py-2 text-center font-medium text-white hover:bg-red-600"
												onClick={() => {
													handleDeleteClick()
													setFileIDDelete(item.id_upload_file)
												}}
											>
												<FiTrash2 />
											</Button>
										</div>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</ScrollArea>
			<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Konfirmasi Penghapusan</DialogTitle>
						<DialogDescription>
							Apakah Anda yakin ingin menghapus file ini?
						</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<div className="mt-5 flex w-full justify-between">
							<Button
								disabled={pendingDeleteFile || pendingDeleteCapaian}
								size={'sm'}
								className="bg-red-500 text-white"
							>
								Batal
							</Button>
							<Button
								disabled={pendingDeleteFile || pendingDeleteCapaian}
								size={'sm'}
								className="ml-auto bg-green-500 text-white hover:bg-green-600"
								onClick={() => handleConfirmDelete(fileIDDelete)}
							>
								Hapus Flie
							</Button>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default TBerkasAL

