import { createSlice } from '@reduxjs/toolkit';
import { SpamBjpData } from '../../types/airMinum';

const initialState: { data: SpamBjpData[] | null } = {
	data: null,
};

const spamBjpSlice = createSlice({
	name: 'spamBjpData',
	initialState,
	reducers: {
		setSpamBjpData: (state, action) => {
			state.data = action.payload;
		},
	},
});

export const { setSpamBjpData } = spamBjpSlice.actions;

export default spamBjpSlice.reducer;
