import React from 'react';

interface CardInfoProps {
	nama: string;
	keterangan: string;
	href: string;
}

const CardInfo: React.FC<CardInfoProps> = ({ nama, keterangan, href }) => {
	return (
		<>
			<a
				href={href}
				className="text-blue-600 text-inherit no-underline hover:text-blue-800"
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className="border-gray-300 relative flex min-h-30 transform items-center justify-center overflow-hidden rounded-lg border bg-white px-8 py-6 shadow-lg transition-transform hover:scale-105 dark:border-strokedark dark:bg-gradient-to-r dark:from-[#2d3748] dark:to-[#4a5568]">
					<div className="w-full text-center">
						<h5 className="mb-2 text-2xl font-bold text-[#364878] dark:text-white">
							{nama}
						</h5>
						<p className="text-gray-800 dark:text-gray-300 text-base">
							{keterangan}
						</p>
					</div>
				</div>
			</a>
		</>
	);
};

export default CardInfo;
