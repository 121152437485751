import React, { useEffect, useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface SumberAnggaranSelectUIProps {
	getSumberAnggaran: any[];
	setSumberAnggaran: (id_sumber_anggaran: number) => void;
    selectedValue: number;
}

const EditSumberAnggaranSelectUI: React.FC<SumberAnggaranSelectUIProps> = ({
    getSumberAnggaran,
    setSumberAnggaran,
    selectedValue
}) => {
    const [selectedSumberAnggaran, setSelectedSumberAnggaran] = useState('');

    useEffect(() => {
        const selectedSumberAnggaran = getSumberAnggaran.find((sumberAnggaran: any) => sumberAnggaran.id_sumber_anggaran === selectedValue);
        if (selectedSumberAnggaran) {
            setSelectedSumberAnggaran(selectedSumberAnggaran.sumber_anggaran);
        }
    }, [selectedValue, getSumberAnggaran]);

    return (
        <Select
            onValueChange={(value) => {
                if (value !== '') {
                    const selectedSumberAnggaran = getSumberAnggaran.find((sumberAnggaran: any) => sumberAnggaran.id_sumber_anggaran === parseInt(value));
                    if (selectedSumberAnggaran) {
                        console.log("Jenis Sumber Anggaran yang dipilih:", selectedSumberAnggaran.sumber_anggaran);
                        setSelectedSumberAnggaran(selectedSumberAnggaran.sumber_anggaran || "");
                        setSumberAnggaran(parseInt(value)); 
                    }
                }
            }}
            value={selectedValue.toString()} 
        >
            <SelectTrigger>
                <SelectValue placeholder="Pilih Jenis Sumber Anggaran">
                    {selectedSumberAnggaran || 'Pilih Jenis Sumber Anggaran'}
                </SelectValue>
            </SelectTrigger>
            <SelectContent className='z-99999'>
                {getSumberAnggaran.map((sumberAnggaran: any, index: any) => (
                    <SelectItem key={index} value={sumberAnggaran.id_sumber_anggaran.toString()}>
                        {sumberAnggaran.sumber_anggaran}
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    );
};

export default EditSumberAnggaranSelectUI;


