import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { API } from '../../libs/API';

const fetchEksisAirLimbah = async ({
	pageParam = 1,
	kd_provinsi,
	kd_kabkota,
	tahun,
	sample_id,
}) => {
	let endpoint = `/eksis-air-limbah/?kd_provinsi=${kd_provinsi}&kd_kabkota=${kd_kabkota}&tahun=${tahun}&page=${pageParam}&limit=10`;
	if (sample_id) {
		endpoint += `&sample_id=${sample_id}`;
	}
	const data = await API.get(endpoint);
	return data.data;
};

export const useGetDataEksisAirLimbah = ({
	kd_provinsi,
	kd_kabkota,
	tahun,
	sample_id,
}) => {
	return useInfiniteQuery({
		queryKey: ['eksis-air-limbah', kd_provinsi, kd_kabkota, tahun, sample_id],
		queryFn: ({ pageParam = 1 }) =>
			fetchEksisAirLimbah({
				pageParam,
				kd_provinsi,
				kd_kabkota,
				tahun,
				sample_id,
			}),
		initialPageParam: 1,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage, pages) => {
			if (lastPage.data.length) {
				return pages.length + 1;
			}

			return undefined;
		},
	});
};

export function usePostDataCapaianAL() {
	const {
		mutateAsync: asyncPostCapaianAL,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/capaian-air-limbah', data);
			return response.data;
		},
	});
	return { asyncPostCapaianAL, isPending, isSuccess };
}

export function useEditDataCapaianAL() {
	const {
		mutateAsync: asyncEditCapaianAL,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(`/capaian-air-limbah/?id_eksis_al=${data.id_eksis_al}`, data);
			return response;
		},
	});
	return { asyncEditCapaianAL, isPending, isSuccess };
}

export function useDeleteCapaianAL() {
	const {
		mutateAsync: deleteCapaianAL,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (tahun: number) => {
			const response = await API.delete(
				`/del-capaian-air-limbah/?tahun=${tahun}`,
			);
			return response;
		}
	});
	return { deleteCapaianAL, isPending, isSuccess };
}

export function useGetDataCapaianALProv(kd_provinsi: number, tahun: number) {
	const { data: getDataCapaianALProv, isLoading, isRefetching } = useQuery({
		queryKey: ["capaian-al-provinsi", kd_provinsi, tahun],
		queryFn: async () => {
			const { data } = await API.get(`/eksis-air-limbah-prov/?kd_provinsi=${kd_provinsi}&tahun=${tahun}`)
			return data.data
		}
	})

	return { getDataCapaianALProv, isLoading, isRefetching }
}

export function useGetRekapitulasiALProv(kd_provinsi: number, tahun: number) {
	const { data: getRekapitulasiALProv, isLoading, isRefetching } = useQuery({
		queryKey: ["rekapitulasi-air-limbah-prov", kd_provinsi, tahun],
		queryFn: async () => {
			const { data } = await API.get(`/rekapitulasi-air-limbah-prov/?kd_provinsi=${kd_provinsi}&tahun=${tahun}`)
			return data.data
		}
	})

	return { getRekapitulasiALProv, isLoading, isRefetching }
}

export function usePostDataCapaianALProv() {
	const {
		mutateAsync: asyncPostCapaianALProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.post('/capaian-air-limbah-prov', data);
			return response.data;
		},
	});
	return { asyncPostCapaianALProv, isPending, isSuccess };
}

export function useGetDataCapaianALProvById(id: number) {
	const { data: getCapaianAlProvById, isLoading, isRefetching } = useQuery({
		queryKey: ["eksis-al-provinsi-by-id", id],
		queryFn: async () => {
			const { data } = await API.get(`/eksis-air-limbah-prov-id/?id_eksis_al_prov=${id}`)
			return data.data
		}
	})

	return { getCapaianAlProvById, isLoading, isRefetching }
}

export function useEditDataCapaianALProv() {
	const {
		mutateAsync: asyncEditCapaianALProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (data: any) => {
			const response = await API.put(`/capaian-air-limbah-prov-id/?id_eksis_al_prov=${data.id_eksis_al_prov}`, data);
			return response;
		},
	});
	return { asyncEditCapaianALProv, isPending, isSuccess };
}

export function useDeleteDataCapaianALProv() {
	const {
		mutateAsync: asyncDeleteCapaianALProv,
		isPending,
		isSuccess,
	} = useMutation({
		mutationFn: async (id: any) => {
			const response = await API.delete(
				`/capaian-air-limbah-prov-id/?id_eksis_al_prov=${id}`,
			);
			return response;
		},
	});
	return { asyncDeleteCapaianALProv, isPending, isSuccess };
}